import { LANGUAGE_TAGS } from 'localization/constants/languages'

import EmailIcon from '../assets/contact-email.svg'
import FacebookIcon from '../assets/contact-facebook.svg'
import PhoneIcon from '../assets/contact-phone.svg'
import PlaneIcon from '../assets/contact-plane.svg'
import WhatsappIcon from '../assets/contact-whatsapp.svg'

const shouldShowEmailIcon = language =>
  [LANGUAGE_TAGS.ENGLISH_USA, LANGUAGE_TAGS.SPANISH_USA, LANGUAGE_TAGS.MEXICO].includes(language)

export const getContactData = (translation, language) => [
  {
    icon: PhoneIcon,
    title: translation('general.footer.phone-label'),
    subtitle: translation('general.footer.call-center-number'),
    alt: ''
  },
  {
    icon: WhatsappIcon,
    title: translation('general.footer.contact-info.whatsapp'),
    subtitle: translation('general.footer.contact-info.whatsapp-number'),
    link: translation('footer-whatsapp.url'),
    alt: ''
  },
  shouldShowEmailIcon(language)
    ? {
        icon: EmailIcon,
        title: translation('general.footer.contact-info.email'),
        __html: translation('general.footer.contact-info.email-click-here'),
        alt: ''
      }
    : {
        icon: FacebookIcon,
        title: translation('general.footer.contact-info.facebook'),
        __html: translation('general.footer.contact-info.facebook-click-here'),
        alt: ''
      },
  {
    icon: PlaneIcon,
    title: translation('general.footer.contact-info.branches-and-airports'),
    __html: translation('general.footer.contact-info.airports-enter-here'),
    alt: ''
  }
]
