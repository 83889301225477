import styled from 'styled-components'
import mediaQueries from 'components/media-queries'

export const AnimationContainer = styled.div`
  pointer-events: none;
  display: flex;
  justify-content: center;
  position: relative;
`

export const AnimationText = styled.h3`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  color: ${({ theme }) => theme.colors.blue};

  width: 1000px;
  position: absolute;
  text-align: center;
  font-size: 25px;
  z-index: 2;
  top: 60px;

  ${mediaQueries.mobile`
    width: 240px;
    top: 40px;
  `};
`
