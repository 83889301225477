import React from 'react'

import e2eClasses from 'helpers/automation'

import { Container, Title, LinksContainer, Link, Image } from './styled'

const FooterSocial = ({ socialItems, showFooterCollapsible, label }) => (
  <Container showFooterCollapsible={showFooterCollapsible}>
    <Title showFooterCollapsible={showFooterCollapsible}>{`${label}:`}</Title>
    <LinksContainer showFooterCollapsible={showFooterCollapsible}>
      {(socialItems || []).map(({ id, icon, url, alt }) => (
        <Link showFooterCollapsible={showFooterCollapsible} key={id} href={url}>
          <Image src={icon} alt={alt} e2eClass={e2eClasses('footer.social.itemIcon')} />
        </Link>
      ))}
    </LinksContainer>
  </Container>
)

export default FooterSocial
