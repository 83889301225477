import { handleActions } from 'redux-actions'

import {
  paymentMilesStart,
  paymentMilesSuccess,
  paymentMilesError,
  clearPaymentMilesState
} from 'actions/arplus/paymentMiles'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const paymentMiles = handleActions(
  {
    [paymentMilesStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [paymentMilesSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [paymentMilesError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearPaymentMilesState]: () => ({ ...defaultState })
  },
  defaultState
)

export default paymentMiles
