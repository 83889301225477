/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  changePasswordStart,
  changePasswordSuccess,
  changePasswordError,
  clearChangePasswordState
} = createActions({
  CHANGE_PASSWORD_START: () => {},
  CHANGE_PASSWORD_SUCCESS: data => ({ data }),
  CHANGE_PASSWORD_ERROR: error => ({ error }),
  CLEAR_CHANGE_PASSWORD_STATE: () => {}
})


const changePassword = (values) => async dispatch => {
  dispatch(changePasswordStart())
  try {
    const response = await api.post(apiRoutes.AR_PLUST_CHANGE_ACCOUNT_PASSWORD, values)
    dispatch(changePasswordSuccess(response.data))
  } catch (error) {
    dispatch(changePasswordError(error.response ? error.response.data : error))
  }
}

export {
  changePassword,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordError,
  clearChangePasswordState
}
