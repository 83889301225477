const CABIN_NAMES = {
  Economy: 'Economy',
  PremiumEconomy: 'Premium Economy',
  Business: 'Business'
}

const TAB_TITLES = [
  'general.footer.national-flights',
  'general.footer.international-flights',
  'general.footer.regional-flights'
]

export { TAB_TITLES, CABIN_NAMES }
