import { CATEGORIES } from 'constants/arplus/categories'
import {
  MAIN_IP_API,
  FREE_IP_API,
  PAYMENT_IP_API,
  IP_API_RESPONSE_FORMAT
} from 'constants/arplus/ipAddressApi'
import { LANGUAGE_TAGS } from 'localization/constants/languages'

export const getCategoryDescription = (category, t) =>
  (category === CATEGORIES.GOLD && t('general.category.gold')) ||
  (category === CATEGORIES.PLATINUM && t('general.category.platinum')) ||
  (category === CATEGORIES.DIAMOND && t('general.category.diamond'))

export const getStarColor = (category, theme) =>
  (category === CATEGORIES.GOLD && theme.colors.luxorGold) ||
  (category === CATEGORIES.PLATINUM && theme.colors.ironPlatinum) ||
  (category === CATEGORIES.DIAMOND && theme.colors.diamond)

export const shouldNotRenderLogin = lng =>
  lng === LANGUAGE_TAGS.FRANCE ||
  lng === LANGUAGE_TAGS.GREAT_BRITAIN ||
  lng === LANGUAGE_TAGS.SPANISH_USA ||
  lng === LANGUAGE_TAGS.ENGLISH_USA

const validateResponse = (response, api) =>
  response[api.attrIp] ? response[api.attrIp] : response?.trim()

const validateCallbackFunction = (data, api, callback) =>
  callback ? callback(validateResponse(data, api)) : validateResponse(data, api)

const getApi = (api, resolve, reject) =>
  fetch(api.url)
    .then(response =>
      api?.responseFormat === IP_API_RESPONSE_FORMAT.JSON ? response.json() : response.text()
    )
    .then(data => validateCallbackFunction(data, api, resolve))
    .catch(error => validateCallbackFunction(error, api, reject))

const createPromisesIpAddress = apiList =>
  (apiList || []).map(api => new Promise((resolve, reject) => getApi(api, resolve, reject)))

export const getIpAddressPromise = async () =>
  await getApi(MAIN_IP_API)
    .then(res => res || Promise.reject(res))
    .catch(() =>
      Promise.any(createPromisesIpAddress(FREE_IP_API))
        .then(res => res || Promise.reject(res))
        .catch(() =>
          Promise.any(createPromisesIpAddress(PAYMENT_IP_API))
            .then(res => res || Promise.reject(res))
            .catch(err => err)
        )
    )
