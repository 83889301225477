import { generatePath } from 'react-router-dom'

import CONFIG from 'config'
import { languageTag, validateField } from 'helpers/validations'
import {
  LANGUAGE_TAGS,
  LANGCODE_TO_CHANNEL,
  SUBLOS_LANGCODE_TO_CHANNEL
} from 'localization/constants/languages'

export const isValidLanguageTag = value => validateField(languageTag, value)

/**
 *
 * @param {string} url
 * @param {string} language
 * @param {object} [otherUrlParams] Should be used when url has other params. i.e, /:langId/checkout/:step
 *
 * @description Checking for isValidLanguageTag here to avoid
 * generating path with non valid lang in case it was not filtered by i18next.
 */
export const generatePathWithLang = (url, language, otherUrlParams) =>
  generatePath(
    url,
    language === LANGUAGE_TAGS.DEFAULT || !isValidLanguageTag(language)
      ? otherUrlParams
      : { langId: language.toLowerCase(), ...otherUrlParams }
  )

/**
 *
 * @param {*} url
 * @param {*} language
 */
export const setLanguageInRoute = (url, language) => {
  if (!language || language === LANGUAGE_TAGS.DEFAULT) {
    return url
  }

  const prefix = `/${language.toLowerCase()}`

  return `${prefix}${url}`
}

//improve this method
export const parseCountry = value => (value === 'cimode' ? 'AR' : value && value.substr(3, 2))

export const parseItalyOrSpain = value => value && value.substr(0, 2)

// If regex does not match it returns null
export const parseToGeneralLanguage = value => {
  const FIRST_CAPTURE_GROUP = 1
  const result = value && /^([A-z]{2})(-)?/.exec(value)
  return result && result[FIRST_CAPTURE_GROUP]
}

export const getChannelFromTag = value =>
  isValidLanguageTag(value) ? LANGCODE_TO_CHANNEL[value.toUpperCase()] : CONFIG.DEFAULT_CHANNEL_ID

export const getSublosChannelFromTag = value =>
  isValidLanguageTag(value)
    ? SUBLOS_LANGCODE_TO_CHANNEL[value.toUpperCase()]
    : CONFIG.DEFAULT_SUBLOS_CHANNEL_ID

export const parseLanguageTagFromUrl = url => {
  const regexpResultLang = /([a-zA-Z]{2}-[a-zA-Z]{2})/.exec(url)
  return regexpResultLang && regexpResultLang[0]
}

export const parseNamespaceFromUrl = url => {
  const regexpResultNamespace = /_(([a-zA-Z])+)\?/.exec(url)
  return regexpResultNamespace && regexpResultNamespace[1]
}

export const isDefaultLanguage = language => language === LANGUAGE_TAGS.DEFAULT

export const isDefaultOnlyLanguage = language => language === LANGUAGE_TAGS.DEFAULT_ONLY_LANG

export const isTaxPayer = language =>
  isDefaultLanguage(language) ||
  language === LANGUAGE_TAGS.BOLIVIA ||
  language === LANGUAGE_TAGS.PERU

export const removeFirstSlashFromUrl = url => url.replace(/^\//, '')
