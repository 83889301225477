/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect'

import IconNames from 'components/icons/iconNames'
import GDS_MESSAGES from 'constants/gdsMessages'
import LEG_SEGMENTS_STATUS from 'constants/legSegmentsStatus'
import { PASSENGER_TYPES } from 'constants/passengerTypes'
import POST_BOOKING_OPTIONS from 'constants/postBookingOptions'
import { getDayJsObject } from 'helpers/dates'
import { getFlightInformationData } from 'helpers/flightConditions'
import { capitalize, isEmpty } from 'helpers/utils'
import { getAirports } from 'selectors/airports'
import { getDocumentTypesError, isFetchingDocumentTypesRules } from 'selectors/documentTypesRules'
import {
  getPassengerDataRulesError,
  isFetchingPassengerDataRules
} from 'selectors/passengerDataRules'
import { getPaymentOptionsError } from 'selectors/paymentOptions'
import {
  getSpecialRequestsRulesError,
  isFetchingSpecialRequestsRules
} from 'selectors/specialRequestRules'

import { isFetchingBrands } from './brands'
import { isFetchingCountries } from './countries'
import { isFetchingPaymentOptionsValidations } from './paymentMethodSelection'

export const getReservation = state => state.reservation

export const getReservationData = createSelector([getReservation], state =>
  state ? state.data : null
)

export const getReservationError = createSelector([getReservation], reservation =>
  reservation ? reservation.error : null
)

export const isFetchingReservation = createSelector(
  [getReservation, getReservationData],
  (state, data) => state.isFetching || isEmpty(data)
)

export const isMobileEntryPoint = createSelector(
  [getReservationData],
  data => data && data.isMobileEntryPoint
)

export const isFetchingEmptyReservation = createSelector(
  [getReservation],
  state => state.isFetching
)

export const getReservationPostBookingOptions = createSelector([getReservationData], reservation =>
  reservation ? reservation.postBookingOptions : []
)

export const isSeatsSelectionSupported = createSelector(
  [getReservationPostBookingOptions],
  postBookingOptions => {
    if (!isEmpty(postBookingOptions)) {
      const seatsSelection = postBookingOptions.find(
        ({ option }) => option === POST_BOOKING_OPTIONS.SEAT_SELECTION
      )

      return seatsSelection ? seatsSelection.enabled : false
    }

    return false
  }
)

export const isExchangesSupported = createSelector(
  [getReservationPostBookingOptions],
  postBookingOptions => {
    if (!isEmpty(postBookingOptions)) {
      const seatsSelection = postBookingOptions.find(
        ({ option }) => option === POST_BOOKING_OPTIONS.EXCHANGE
      )

      return seatsSelection ? seatsSelection.enabled : false
    }

    return false
  }
)

export const isSSRSupported = createSelector(
  [getReservationPostBookingOptions],
  postBookingOptions => {
    if (!isEmpty(postBookingOptions)) {
      const seatsSelection = postBookingOptions.find(
        ({ option }) => option === POST_BOOKING_OPTIONS.PASSENGER
      )

      return seatsSelection ? seatsSelection.enabled : false
    }

    return false
  }
)

export const getReservationPassengers = createSelector([getReservationData], data =>
  data ? data.passengersData : []
)

const getReservationPassengersSegments = createSelector([getReservationData], data =>
  data ? data.passengerSegments : null
)

export const getReservationPassengersWithSegments = createSelector(
  [getReservationPassengers, getReservationPassengersSegments],
  (passengers, passengersSegments) => {
    if (!isEmpty(passengers) && !isEmpty(passengersSegments)) {
      return passengers.map(passengerData => {
        const segments = (passengersSegments || []).map(segmentDetails => ({
          segment: segmentDetails.segment,
          details: (segmentDetails.passengerDetails || []).filter(
            passengerDetails => passengerDetails.passengerIndex === passengerData.passengerIndex
          )
        }))
        const { firstName, lastName } = passengerData

        return {
          ...passengerData,
          firstName: capitalize(firstName),
          lastName: capitalize(lastName),
          segments
        }
      })
    }

    return null
  }
)

export const getReservationContactInformation = createSelector([getReservationData], data =>
  data ? data.contactInformation : ''
)

export const getReservationName = createSelector([getReservationPassengers], passengersData => {
  if (!isEmpty(passengersData)) {
    const adult = passengersData.find(
      ({ passengerType }) =>
        passengerType === PASSENGER_TYPES.ADT_SHORT || passengerType === PASSENGER_TYPES.NEG
    )

    return adult ? adult.lastName : ''
  }

  return ''
})

export const getReservationPayments = createSelector([getReservationData], reservation =>
  reservation ? reservation.payments : []
)

export const getReservationPayment = createSelector([getReservationPayments], payments =>
  !isEmpty(payments) ? payments[0] : {}
)

export const getPurchaseConfirmation = createSelector([getReservationData], reservationData => {
  if (!isEmpty(reservationData)) {
    const { passengersData, legs, bookingMetadata } = reservationData

    return {
      passengersData,
      legs,
      bookingMetadata
    }
  }
})

export const getReservationCabinClass = createSelector([getReservationData], reservation => {
  if (!isEmpty(reservation)) {
    const {
      bookingMetadata: { cabinClass }
    } = reservation

    return cabinClass || ''
  }

  return ''
})

export const getPassengersCountWithoutInfant = createSelector(
  [getReservationPassengers],
  passengersData =>
    !isEmpty(passengersData)
      ? passengersData.filter(passenger => passenger.passengerType !== PASSENGER_TYPES.INF_SHORT)
          .length
      : 0
)

export const getReservationRevenue = createSelector([getReservationData], reservation =>
  reservation ? reservation.revenueData : null
)

const getPostBookingOptionIcon = option => {
  switch (option) {
    case POST_BOOKING_OPTIONS.REFUND:
      return IconNames.Reload

    case POST_BOOKING_OPTIONS.EXCHANGE:
      return IconNames.AutoRenew

    case POST_BOOKING_OPTIONS.CHECK_IN:
      return IconNames.NotebookCheck

    case POST_BOOKING_OPTIONS.SEAT_SELECTION:
      return IconNames.PlainSeat

    case POST_BOOKING_OPTIONS.ADDITIONAL_BAGS:
      return IconNames.Briefcases

    case POST_BOOKING_OPTIONS.VIP_LOUNGE:
      return IconNames.VipLounge

    case POST_BOOKING_OPTIONS.SPECIAL_BAGS:
      return IconNames.SpecialBags

    default:
      return IconNames.DialogBalloonInfo
  }
}

const mapBookingOptions = options =>
  options.map(({ option, enabled }) => ({
    label: `booking.reserve-administrator.${option.toLowerCase().replace('_', '-')}-options`,
    icon: getPostBookingOptionIcon(option),
    option,
    enabled
  }))

export const getPostBookingOptionsReserveAdministator = createSelector(
  [getReservationPostBookingOptions],
  postBookingOptions => {
    if (!isEmpty(postBookingOptions)) {
      return mapBookingOptions(
        postBookingOptions
          .filter(
            ({ option }) =>
              option !== POST_BOOKING_OPTIONS.EXCHANGE && option !== POST_BOOKING_OPTIONS.REFUND
          )
          .sort()
      )
    }

    return []
  }
)

export const getPostBookingOptionsFlightItinerary = createSelector(
  [getReservationPostBookingOptions],
  postBookingOptions => {
    if (!isEmpty(postBookingOptions)) {
      return mapBookingOptions(
        postBookingOptions
          .filter(
            element =>
              element.option === POST_BOOKING_OPTIONS.EXCHANGE ||
              element.option === POST_BOOKING_OPTIONS.REFUND
          )
          .sort((firstElement, secondElement) =>
            firstElement.option > secondElement.option ? -1 : 1
          )
      )
    }

    return []
  }
)

export const getReservationLegsData = createSelector([getReservationData], reservation =>
  reservation ? reservation.legs : []
)

export const getReservationLegsQuantity = createSelector([getReservationLegsData], legs =>
  legs ? legs.length : 0
)

export const getReservationLegs = createSelector(
  [getReservationData, getAirports],
  (reservation, airports) => {
    if (!isEmpty(reservation) && reservation.legs) {
      return reservation.legs.map(leg => {
        const { origin, departure } = leg.segments[0]
        const { destination } = leg.segments[leg.segments.length - 1]

        const originAirport = (airports || []).find(({ iataCode }) => iataCode === origin)
        const destinationAirport = (airports || []).find(({ iataCode }) => iataCode === destination)

        const { futureLeg, exchangeable } = leg

        return {
          departure: getDayJsObject(departure),
          origin,
          destination,
          originCity: originAirport && originAirport.city ? originAirport.city.name : '',
          destinationCity:
            destinationAirport && destinationAirport.city ? destinationAirport.city.name : '',
          originCityCode:
            originAirport && originAirport.city ? originAirport.city.iataCode : origin,
          destinationCityCode:
            destinationAirport && destinationAirport.city
              ? destinationAirport.city.iataCode
              : destination,
          futureLeg,
          exchangeable
        }
      })
    }

    return []
  }
)

export const getReservationEmails = createSelector(
  [getReservationContactInformation],
  contactInformation => (!isEmpty(contactInformation) ? contactInformation.emails : null)
)

export const getReservationEmail = createSelector([getReservationEmails], emails =>
  !isEmpty(emails) && emails[0] ? emails[0] : ''
)

export const formatLegs = createSelector([getReservationLegsData], legs =>
  (legs || []).map(leg => {
    const segments = leg.segments.length
    return {
      ...leg.segments[0],
      exchangeable: leg.exchangeable,
      totalDuration: leg.totalDuration,
      dateDiff: leg.dateDiff,
      segments: leg.segments,
      stops: leg.stops,
      connectionsInformation: leg.connectionsInformation,
      arrival: leg.segments[segments - 1]
        ? leg.segments[segments - 1].arrival
        : leg.segments[0].arrival,
      arrivalTerminal: leg.segments[segments - 1]
        ? leg.segments[segments - 1].arrivalTerminal
        : leg.segments[0].arrivalTerminal,
      destination: leg.segments[segments - 1]
        ? leg.segments[segments - 1].destination
        : leg.segments[0].destination
    }
  })
)

export const getReservationBookingMetadata = createSelector([getReservationData], reservation =>
  reservation ? reservation.bookingMetadata : null
)

export const getReservationFlightType = createSelector(
  [getReservationBookingMetadata],
  bookingMetadata => (bookingMetadata ? bookingMetadata.flightType : '')
)

export const getIsExternalBooking = createSelector(
  [getReservationBookingMetadata],
  bookingMetadata => (bookingMetadata ? bookingMetadata.external : '')
)

export const getReservationProgramId = createSelector(
  [getReservationBookingMetadata],
  bookingMetadata => (bookingMetadata ? bookingMetadata.programId : '')
)

export const getReservationRoutes = createSelector(
  [getReservationBookingMetadata],
  bookingMetadata => (bookingMetadata ? bookingMetadata.routes : null)
)

export const getRoutesAsParams = createSelector([getReservationRoutes], routes =>
  routes
    ? routes
        .map(item => `&route=${item}`)
        .join('')
        .replace('&', '?')
    : null
)

export const getReservationLoadingError = createSelector(
  [
    getReservationError,
    getPaymentOptionsError,
    getDocumentTypesError,
    getSpecialRequestsRulesError,
    getPassengerDataRulesError
  ],
  (
    reservationError,
    paymentOptionsError,
    documentTypesError,
    specialRulesRequestsError,
    passengerDataRulesError
  ) =>
    reservationError ||
    paymentOptionsError ||
    documentTypesError ||
    specialRulesRequestsError ||
    passengerDataRulesError
)

export const getReservationCurrency = createSelector(
  [getReservationBookingMetadata],
  bookingMetadata => (bookingMetadata ? bookingMetadata.currency : null)
)

export const getReservationDocuments = createSelector([getReservationData], reservation =>
  reservation && !isEmpty(reservation.documents) ? reservation.documents : null
)

export const getReservationPaymentStatus = createSelector([getReservationData], reservation =>
  reservation &&
  reservation.payments &&
  reservation.payments[0] &&
  reservation.payments[0].afopInformation
    ? reservation.payments[0].afopInformation.status
    : null
)

export const getReservationPaymentType = createSelector([getReservationData], reservation =>
  reservation && reservation.payments && reservation.payments[0]
    ? reservation.payments[0].paymentType
    : null
)

export const getReservationTransactionId = createSelector([getReservationData], reservation =>
  reservation &&
  reservation.payments &&
  reservation.payments[0] &&
  reservation.payments[0].afopInformation
    ? reservation.payments[0].afopInformation.transactionId
    : null
)

const getEmailAddition = state => state.emailAddition

export const getEmailAdditionResult = createSelector([getEmailAddition], state =>
  state ? state.data : null
)

export const getEmailAdditionError = createSelector([getEmailAddition], state =>
  state ? state.error : null
)

export const isAddingEmail = createSelector([getEmailAddition], state =>
  state ? state.isFetching : false
)

export const getBaggageType = createSelector([getReservation], reservation =>
  reservation ? reservation.typeOfModal : null
)

export const getReservationDate = createSelector([getReservationData], reservation => {
  if (!isEmpty(reservation)) {
    const [firstLeg] = reservation.legs
    const [firstSegment] = firstLeg.segments

    return getDayJsObject(firstSegment.departure)
  }

  return getDayJsObject()
})

export const isPastBooking = createSelector([getReservationDate], reservationDate =>
  reservationDate.isBefore(getDayJsObject())
)

export const getReservationFareRules = createSelector([getReservationData], reservation =>
  reservation ? reservation.fareRules : null
)

export const getReservationFaresRulesForBrand = createSelector(
  [getReservationLegsData, getReservationFareRules, getReservationRoutes, getReservationFlightType],
  (legs, fares, routes, flightType) => getFlightInformationData(legs, routes, fares, flightType)
)

export const getReservationAncillaryInformation = createSelector(
  [getReservationData],
  reservation => (reservation ? reservation.ancillaryInformation : null)
)

export const getReservationAncillaryPassengers = createSelector(
  [getReservationAncillaryInformation],
  ancillaryInfo => (ancillaryInfo ? ancillaryInfo.ancillaryPassengers : [])
)

export const getReservationAncillaryGroups = createSelector(
  [getReservationAncillaryInformation],
  ancillaryInfo => (ancillaryInfo ? ancillaryInfo.ancillaryGroups : [])
)

export const isAncillariesPayment = createSelector(
  [getReservationPayments],
  payments => !isEmpty(payments) && payments.length > 1
)

export const getReservationCode = createSelector([getReservationBookingMetadata], bookingMetadata =>
  bookingMetadata ? bookingMetadata.reservationCode : ''
)

export const getReservationLastName = createSelector([getReservationPassengers], passengers =>
  passengers && passengers[0] ? passengers[0].lastName : null
)

export const isAncillariesSupported = createSelector(
  [getReservationPostBookingOptions],
  postBookingOptions => {
    if (!isEmpty(postBookingOptions)) {
      return postBookingOptions
        .filter(
          ({ option }) =>
            option === POST_BOOKING_OPTIONS.ADDITIONAL_BAGS ||
            option === POST_BOOKING_OPTIONS.VIP_LOUNGE ||
            option === POST_BOOKING_OPTIONS.SPECIAL_BAGS
        )
        .every(value => !value.enabled)
    }

    return true
  }
)

export const getReservationExpirationDate = createSelector(
  [getReservationBookingMetadata],
  bookingMetadata => (bookingMetadata ? bookingMetadata.expirationDate : null)
)

export const getMercadoPagoExpirationDate = createSelector([getReservationPayments], payments =>
  payments ? payments[0]?.externalInformation?.epochExpirationDate : null
)

export const getReservationPreferenceId = createSelector([getReservationPayments], payments =>
  payments ? payments[0]?.externalInformation?.preferenceId : null
)

export const getReservationRedirectUrl = createSelector([getReservationPayments], payments =>
  payments ? payments[0]?.externalInformation?.redirectUrl : null
)

export const getReservationPaymentCode = createSelector([getReservationPayment], payment =>
  payment ? payment.paymentCode : null
)

export const getReservationAuthorizationCode = createSelector([getReservationPayment], payment =>
  payment ? payment.authorizationCode : null
)

export const getAncillariesPurchaseConfirmation = createSelector(
  [getReservationAncillaryInformation],
  ancillaryInformation => (ancillaryInformation ? ancillaryInformation.ancillaryPassengers : null)
)

export const isReservationIssued = createSelector(
  [getReservationBookingMetadata],
  bookingMetadata => (bookingMetadata ? bookingMetadata.issued : false)
)

export const getReservationErrorCode = createSelector([getReservationError], error =>
  error ? error.statusCode : null
)

export const getReservationErrorDescription = createSelector([getReservationError], error =>
  error ? error.description : null
)

export const isReservationExpired = createSelector([getReservationErrorDescription], description =>
  description ? description[0] === GDS_MESSAGES.AUTH_BOOKING_SESSION_EXPIRED : false
)

export const getInfantPassenger = createSelector([getReservationPassengers], passengers =>
  (passengers || []).some(passenger => passenger.passengerType === PASSENGER_TYPES.INF_SHORT)
)

export const isReservationValidationError = createSelector(
  [getReservationErrorDescription],
  description =>
    description ? description.includes(GDS_MESSAGES.BOOKING_RESERVATION_CODE_NOT_EMPTY) : null
)

export const isReservationSegmentDelayed = createSelector(
  [getReservationBookingMetadata],
  bookingMetadata => (bookingMetadata ? bookingMetadata.delayed : false)
)

export const getReservationBookingStatus = createSelector(
  [getReservationBookingMetadata],
  bookingMetadata => (bookingMetadata ? bookingMetadata.bookingStatus : null)
)

export const getReservationPcc = createSelector([getReservationBookingMetadata], bookingMetadata =>
  bookingMetadata ? bookingMetadata.pcc : null
)

export const isFetchingMyReservationPageData = createSelector(
  [
    isFetchingReservation,
    isFetchingPaymentOptionsValidations,
    isFetchingCountries,
    isFetchingDocumentTypesRules,
    isFetchingPassengerDataRules,
    isFetchingSpecialRequestsRules,
    isFetchingBrands
  ],
  (
    fetchingReservation,
    fetchingPaymentOptionsValidations,
    fetchingCountries,
    fetchingDocumentTypesRules,
    fetchingPassengerDataRules,
    fetchingSpecialRequestsRules,
    fetchingBrands
  ) =>
    fetchingReservation ||
    fetchingPaymentOptionsValidations ||
    fetchingCountries ||
    fetchingDocumentTypesRules ||
    fetchingPassengerDataRules ||
    fetchingSpecialRequestsRules ||
    fetchingBrands
)

const getPriceBreakdown = createSelector([getReservationData], data => data && data.priceBreakdown)

export const getReservationAirBreakdown = createSelector(
  [getPriceBreakdown],
  priceBreakdown => priceBreakdown && priceBreakdown.airBreakdown
)

export const getPriceBreakdownTotal = createSelector([getPriceBreakdown], priceBreakdown =>
  priceBreakdown ? priceBreakdown.total : null
)

export const getReservationTotalAmount = createSelector(
  [getReservationData, getPriceBreakdownTotal],
  (reservation, priceBreakdownTotal) => reservation?.total || priceBreakdownTotal
)

export const getReservationCancelledSegmentStatus = createSelector([getReservationLegsData], legs =>
  !isEmpty(legs)
    ? legs.map(leg => leg.segments[0]).filter(segment => segment.status === LEG_SEGMENTS_STATUS.WK)
    : []
)

export const getReservationTotalMiles = createSelector([getReservationData], data =>
  data ? data.totalMiles : null
)

export const getReservationLastFlightDate = createSelector([getReservationLegsData], legs => {
  if (legs && legs.length) {
    const segments = legs[legs.length - 1].segments
    const lastDate = segments && getDayJsObject(segments[segments.length - 1].departure)
    return lastDate ? new Date(lastDate.year(), lastDate.month(), lastDate.date()) : null
  }
})
