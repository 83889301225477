import { createActions } from 'redux-actions'
import { cmsApi } from 'services/api'
import cmsApiRoutes from 'constants/cmsApiRoutes'

const { fetchHomeInfoStart, fetchHomeInfoSuccess, fetchHomeInfoError } = createActions({
  FETCH_HOME_INFO_START: () => {},
  FETCH_HOME_INFO_SUCCESS: data => ({ data }),
  FETCH_HOME_INFO_ERROR: error => ({ error })
})

const fetchHomeInfo = () => {
  return async dispatch => {
    dispatch(fetchHomeInfoStart())

    try {
      const info = await cmsApi.get(cmsApiRoutes.HOME_INFO)
      dispatch(fetchHomeInfoSuccess(info))
    } catch (error) {
      dispatch(fetchHomeInfoError(error))
    }
  }
}

export { fetchHomeInfoStart, fetchHomeInfoSuccess, fetchHomeInfoError, fetchHomeInfo }
