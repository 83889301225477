import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import { getDateWithFormat } from 'helpers/dates'
import { LogOut } from 'helpers/flightOffers'
import FORMATS from 'constants/formats'

const {
  promoMilesAndBonusStart,
  promoMilesAndBonusSuccess,
  promoMilesAndBonusError,
  clearPromoMilesAndBonusState
} = createActions({
  PROMO_MILES_AND_BONUS_START: () => {},
  PROMO_MILES_AND_BONUS_SUCCESS: data => ({ data }),
  PROMO_MILES_AND_BONUS_ERROR: error => ({ error }),
  CLEAR_PROMO_MILES_AND_BONUS_STATE: () => {}
})

const fetchPromoMilesAndBonus = (values, operationType) => async dispatch => {
  dispatch(promoMilesAndBonusStart())
  try {
    const response = await api.post(`${apiRoutes.PROMO_MILES_AND_BONUS_AR_PLUS}`, {
      ...values,
      operationType: operationType,
      processDate: getDateWithFormat(new Date(), FORMATS.DAY_MONTH_YEAR)
    })
    dispatch(promoMilesAndBonusSuccess(response.data))
  } catch (error) {
    const err = error.response ? error.response.data : error

    if (err?.statusCode === 401) {
      LogOut()
      dispatch(clearPromoMilesAndBonusState())
    }
    dispatch(promoMilesAndBonusError(err))
  }
}

export {
  fetchPromoMilesAndBonus,
  promoMilesAndBonusStart,
  promoMilesAndBonusSuccess,
  promoMilesAndBonusError,
  clearPromoMilesAndBonusState
}
