import dayjs from 'dayjs'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import 'dayjs/locale/es'
import 'dayjs/locale/pt'
import 'dayjs/locale/en'
import 'dayjs/locale/it'
import 'dayjs/locale/fr'

import { logError } from 'helpers/log'
import { clientOptions } from 'localization/config/client'
import NAMESPACES from 'localization/constants/namespaces'
import { parseToGeneralLanguage } from 'localization/helpers'
import staticKeys from 'services/i18n/locales/es-AR'

import { LANGUAGE_TAGS } from './constants/languages'

// This configuration is only for browser side
if (process && !process.release) {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .on('languageChanged', function(lng) {
      const generalLang = parseToGeneralLanguage(lng)
      dayjs.locale(generalLang)
    })
}

// Initialize if not already initialized on client
if (process && !process.release && !i18n.isInitialized) {
  i18n.init(clientOptions, err => {
    if (err) {
      logError('Loading static keys. Backend Connector error:', '', err)
      i18n.addResourceBundle(LANGUAGE_TAGS.DEFAULT, NAMESPACES.COMMON, staticKeys)
    }
  })
}

export default i18n
