import { handleActions } from 'redux-actions'
import { fetchChannelStart, fetchChannelSuccess, fetchChannelError } from 'actions/catalog/channels'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchChannelStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchChannelSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [fetchChannelError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    })
  },
  defaultState
)

export default reducer
