import styled from 'styled-components'
import switchfilterloader from 'assets/loader-switch-flight-filters.gif'

export const SwitchFilterLoader = styled.img.attrs(() => ({
  src: switchfilterloader,
  alt: 'Loading...'
}))`
  width: 100%;
  height: ${props => props.height || '100%'};
`

export default SwitchFilterLoader
