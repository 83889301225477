import React from 'react'

import { withTranslation } from 'react-i18next'

import SublosLogo from 'assets/sublos/sublos_logo.svg'

import { LogoContainer } from './styled'

const HeaderLogo = () => (
  <LogoContainer tabIndex={-1}>
    <img src={SublosLogo} alt={'Sublos'} />
  </LogoContainer>
)

export default withTranslation()(HeaderLogo)
