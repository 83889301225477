import { batch } from 'react-redux'

import { setSublosError } from 'actions/sublos/errors'
import { clearSublosFamilyMember } from 'actions/sublos/familyMembers'
import { RESET_SERVICES } from 'constants/sublos/errors'
import { STEPS } from 'constants/sublos/flow'
import { mapperError } from 'helpers/sublos/mapper-error'

export const passengerThunk = error => async dispatch => {
  batch(() => {
    error && dispatch(setSublosError(mapperError(error, STEPS.SELECT_PASSENGERS)))
  })
}

export const clearPassengerStateThunk = resetServices => async (dispatch, getState) => {
  const state = getState()
  const isResetAll = resetServices === RESET_SERVICES.RESET_ALL_SERVICES
  const { sublosFamilyMember } = state

  batch(() => {
    ;(sublosFamilyMember.error || isResetAll) && dispatch(clearSublosFamilyMember())
  })
}
