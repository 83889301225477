import { createActions } from 'redux-actions'
import { cmsApi } from 'services/api'
import cmsApiRoutes from 'constants/cmsApiRoutes'

const { fetchFooterStart, fetchFooterSuccess, fetchFooterError } = createActions({
  FETCH_FOOTER_START: () => {},
  FETCH_FOOTER_SUCCESS: data => ({ data }),
  FETCH_FOOTER_ERROR: error => ({ error })
})

const fetchFooter = () => {
  return async dispatch => {
    dispatch(fetchFooterStart())

    try {
      const footer = await cmsApi.get(cmsApiRoutes.FOOTER)
      dispatch(fetchFooterSuccess(footer))
    } catch (error) {
      dispatch(fetchFooterError(error))
    }
  }
}

export { fetchFooterStart, fetchFooterSuccess, fetchFooterError, fetchFooter }
