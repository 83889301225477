import { handleActions } from 'redux-actions'

import { showError, clearError } from 'actions/arplus/errorsArplus'

const defaultState = {
  show: false,
  message: null,
  redirection: null
}

const errorsArplus = handleActions(
  {
    [showError]: (state, { payload: { show, redirection, message } }) => {
      return {
        ...state,
        show,
        redirection,
        message
      }
    },
    [clearError]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default errorsArplus
