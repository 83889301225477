import { handleActions } from 'redux-actions'
import {
  creditMilesOriginStart,
  creditMilesOriginSuccess,
  creditMilesOriginError,
  clearCreditMilesOrigin
} from 'actions/arplus/creditMilesOrigin'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const creditMilesOrigin = handleActions(
  {
    [creditMilesOriginStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [creditMilesOriginSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [creditMilesOriginError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    }),
    [clearCreditMilesOrigin]: () => ({ ...defaultState })
  },
  defaultState
)

export default creditMilesOrigin
