import { LANGUAGE_TAGS } from 'localization/constants/languages'

export const DEFAULT_LANG_ARPLUS_CULTURES = [LANGUAGE_TAGS.DEFAULT]

export const ARG_CULTURES = [LANGUAGE_TAGS.DEFAULT, LANGUAGE_TAGS.ENGLISH]

export const ENGLISH_LANG_ARPLUS_CULTURES = [LANGUAGE_TAGS.ENGLISH]

export const ENABLED_ARPLUS_REDIRECTION = [
  ...DEFAULT_LANG_ARPLUS_CULTURES,
  ...ENGLISH_LANG_ARPLUS_CULTURES
]

export const SHOULD_SHOW_MODAL = [
  LANGUAGE_TAGS.BOLIVIA,
  LANGUAGE_TAGS.BRAZIL,
  LANGUAGE_TAGS.CHILE,
  LANGUAGE_TAGS.COLOMBIA,
  LANGUAGE_TAGS.ESPAÑA_EN,
  LANGUAGE_TAGS.ESPAÑA,
  LANGUAGE_TAGS.ITALY,
  LANGUAGE_TAGS.MEXICO,
  LANGUAGE_TAGS.PARAGUAY,
  LANGUAGE_TAGS.PERU,
  LANGUAGE_TAGS.URUGUAY
]
