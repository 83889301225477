import styled from 'styled-components'

import mediaQueries from 'components/media-queries'

const getStyles = (sticky, width, top, bottom, zIndex, theme) => {
  if (sticky) {
    return `
      width: ${width || '100%'};
      ${isNaN(top) ? '' : `top: ${top}px;`}
      ${isNaN(bottom) ? '' : `bottom: ${bottom}px;`}
      position: -webkit-sticky;
      position: sticky;
      ${
        theme?.root?.style.getPropertyValue('--is-arsa-keys') === 'true'
          ? ''
          : `z-index:${zIndex || '10'};`
      }
    `
  }

  return `
    position: relative;
    width: 100%;
    z-index: ${zIndex || '1'};
    top: 0;
  `
}

export const StickyContainer = styled.div`
  ${({ desktop, width, top, bottom, zIndex }) =>
    getStyles(desktop, width, top, bottom, zIndex)} ${mediaQueries.tablet`
    ${({ mobile, width, top, bottom, zIndex, theme }) =>
      getStyles(mobile, width, top, bottom, zIndex, theme)}
  `};
`
