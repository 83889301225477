import React from 'react'

import PropTypes from 'prop-types'

import Icon, { IconNames } from 'components/icons'

import {
  StyledMessage,
  IconContainer,
  MaxWidthContainer,
  MessageContainer,
  MessageButton,
  MessageButtonContainer,
  ContentSpan,
  fontColorMap
} from './styled'

const IconsMap = {
  primary: IconNames.Dialog,
  secondary: IconNames.Dialog,
  success: IconNames.Checkmark,
  error: IconNames.Alert,
  warning: IconNames.Alert,
  formInputError: IconNames.Alert,
  formInputSuccess: IconNames.Checkmark,
  reserveRate: IconNames.TagMultiple,
  onTime: IconNames.Clock,
  cancelled: IconNames.CloseCircle,
  arrived: IconNames.Check,
  delayed: IconNames.ClockAlert,
  departed: IconNames.Checkmark,
  discount: IconNames.TicketPercent,
  deflected: IconNames.ClockAlert,
  rescheduled: IconNames.ClockAlert,
  cookies: IconNames.Alert,
  paymentRetrial: IconNames.Alert
}

const Message = ({
  children,
  type = 'primary',
  messageButtonText,
  messageButtonIconName,
  onMessageButtonClick,
  alignIconTop,
  e2eClass,
  center,
  noGrow,
  isCircular,
  noIcon,
  HTMLNotificationMessage,
  id,
  acceptEssentialButtonText,
  acceptEssentialCookies,
  setHasAcceptedEssentialCookies,
  hasAcceptedEssentialCookies,
  showCookiesMessage,
  closeText
}) => {
  const IconName = IconsMap[type]

  return (
    <StyledMessage
      type={type}
      center={center}
      isCircular={isCircular}
      showCookiesMessage={showCookiesMessage}
    >
      <MaxWidthContainer center={center} type={type}>
        <IconContainer
          e2eClass={e2eClass}
          type={type}
          alignIconTop={alignIconTop || false}
          noIcon={noIcon}
        >
          <Icon name={IconName} size={20} />
        </IconContainer>
        <MessageContainer e2eClass={e2eClass} type={type} noGrow={noGrow}>
          {HTMLNotificationMessage ? (
            <ContentSpan
              type={type}
              dangerouslySetInnerHTML={{ __html: HTMLNotificationMessage }}
            />
          ) : (
            <ContentSpan type={type} id={id}>
              {children}
            </ContentSpan>
          )}
          {messageButtonText && !hasAcceptedEssentialCookies && (
            <MessageButtonContainer type={type}>
              <MessageButton e2eClass={e2eClass} type={type} onClick={onMessageButtonClick}>
                {messageButtonIconName && (
                  <Icon name={messageButtonIconName} color={fontColorMap[type]} />
                )}
                <span style={{ width: 'max-content' }}>{messageButtonText}</span>
              </MessageButton>
            </MessageButtonContainer>
          )}
          {acceptEssentialButtonText && !hasAcceptedEssentialCookies && (
            <MessageButtonContainer type={type}>
              <MessageButton
                e2eClass={e2eClass}
                type={type}
                onClick={() => {
                  setHasAcceptedEssentialCookies(true)
                  acceptEssentialCookies()
                }}
              >
                <span style={{ width: 'max-content' }}>{acceptEssentialButtonText}</span>
              </MessageButton>
            </MessageButtonContainer>
          )}
          {hasAcceptedEssentialCookies && (
            <MessageButtonContainer type={type}>
              <MessageButton e2eClass={e2eClass} type={type} onClick={onMessageButtonClick}>
                {messageButtonIconName && (
                  <Icon name={messageButtonIconName} color={fontColorMap[type]} />
                )}
                <span style={{ width: 'max-content' }}>{closeText}</span>
              </MessageButton>
            </MessageButtonContainer>
          )}
        </MessageContainer>
      </MaxWidthContainer>
    </StyledMessage>
  )
}

Message.propTypes = {
  type: PropTypes.string
}

export default Message
