import CONFIG from 'config'

export const landingWithCardsContainer = result => {
  let cardContainer = []
  const newResults = []

  result.sections.forEach(item => {
    if (item.card) {
      cardContainer.push(item)
    } else {
      if (cardContainer && cardContainer.length) {
        let newItem = { ...cardContainer[0], cardContainer }
        newResults.push(newItem)
        cardContainer = []
      }
      newResults.push(item)
    }
  })
  if (cardContainer && cardContainer.length) {
    let newItem = { ...cardContainer[0], cardContainer }
    newResults.push(newItem)
    cardContainer = []
  }

  return { ...result, sections: newResults }
}

/* Workaround because of https://aerolineas-argentinas.atlassian.net/browse/AAW-6634
 * Sliders without searchbox must have smaller height. In case of several sliders in the same landing,
 * there is no way to tell which one has the searchbox, because withSearchBox prop is for the landing,
 * not for each slider. So it should apply withSearchBox prop only to first slider. */
export const getFirstSliderIndex = sections => {
  const indexesOfAllSlidersOccurrences = sections.reduce((acc, item, i) => {
    if (item.slider) acc.push(i)
    return acc
  }, [])

  return indexesOfAllSlidersOccurrences[0]
}

export const convertPropertyTypeObjectToTypeArray = property => field =>
  !field[property] || Array.isArray(field[property])
    ? { ...field }
    : { ...field, [property]: Object.values(field[property]) }

export const parseTableRichTextContainer = richText =>
  richText
    .replace(/<table/g, '<div class="tableOverflowContainer"><table')
    .replace(/<\/table>/g, '</table></div>')

export const parseRichText = (richText = '') => {
  const src = 'src="'
  const http = 'http'

  let position = richText.indexOf(src)

  while (position !== -1) {
    position = richText.indexOf(src, position) + src.length

    if (richText.slice(position, position + http.length) !== http) {
      const replaceValue = `${src}${CONFIG.CONTENT_CDN_BASE_URL}`
      const firstPart = richText.slice(0, position - src.length)
      const secondPart = richText.slice(position - src.length)
      richText = firstPart + secondPart.replace(src, replaceValue)
      position = richText.indexOf(replaceValue, position) + replaceValue.length
    }

    position = richText.indexOf(src, position + 1)
  }

  return richText
}
