import { __SUBLOS_USER_DATA__, __ACCESS_TOKEN_SUBLOS__ } from 'constants/sublos/localStorageKeys'
import { getCookie } from 'helpers/cookies'
import { setSublosToken } from 'helpers/sublos/auth'

export function getSublosUserData() {
  if (typeof window !== 'undefined') {
    const data = localStorage?.getItem(__SUBLOS_USER_DATA__)
    return data ? JSON.parse(data) : null
  }
}

export const getSublosUserDataWithToken = () => {
  if (getSublosUserData()) return

  if (typeof window !== 'undefined') {
    const cookie = document.cookie
    const data = getCookie(cookie, __SUBLOS_USER_DATA__)
    const accessToken = getCookie(cookie, __ACCESS_TOKEN_SUBLOS__)
    const parseData = data && JSON.parse(data)

    setSublosToken(accessToken)

    parseData && setSublosUserData(JSON.parse(data))

    return parseData
  }
}

export function setSublosUserData(sublosUserData) {
  if (typeof window !== 'undefined') {
    const data = JSON.stringify(sublosUserData)
    localStorage?.setItem(__SUBLOS_USER_DATA__, data)
  }
}

export function removeSublosUserData() {
  if (typeof window !== 'undefined') {
    localStorage?.removeItem(__SUBLOS_USER_DATA__)
  }
}

export function getShoppingIdFromLogin() {
  const userData = getSublosUserData()
  return userData?.shoppingId
}
