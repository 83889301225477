import { createActions } from 'redux-actions'
import { apiBooking } from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import { generateSeatsSelection } from 'actions/seatsSelection'
import { authBookingPost } from 'actions/authBooking'
import { getBookingToken } from 'helpers/sessionStorage'

const { getFlightSeatsStart, getFlightSeatsSuccess, getFlightSeatsError } = createActions({
  GET_FLIGHT_SEATS_START: () => {},
  GET_FLIGHT_SEATS_SUCCESS: data => ({ data }),
  GET_FLIGHT_SEATS_ERROR: error => ({ error })
})

const getFlightSeats = (reservationCode, lastName) => {
  return async dispatch => {
    dispatch(getFlightSeatsStart())

    try {
      if (!getBookingToken()) {
        await dispatch(authBookingPost({ lastName, reservationCode }))
      }

      const response = await apiBooking.get(apiRoutes.SEATS)

      dispatch(getFlightSeatsSuccess(response.data))
      dispatch(generateSeatsSelection(response.data.legSeatMaps))
    } catch (error) {
      dispatch(getFlightSeatsError(error.response ? error.response.data : error))
    }
  }
}

export { getFlightSeatsStart, getFlightSeatsSuccess, getFlightSeatsError, getFlightSeats }
