import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import { modalErrors } from 'actions/arplus/errorsArplus'

const {
  evaluateMemberEligibilityFirstCallStart,
  evaluateMemberEligibilityFirstCallSuccess,
  evaluateMemberEligibilityFirstCallError,
  clearEvaluateMemberEligibilityFirstCallState
} = createActions({
  EVALUATE_MEMBER_ELIGIBILITY_FIRST_CALL_START: () => {},
  EVALUATE_MEMBER_ELIGIBILITY_FIRST_CALL__SUCCESS: data => ({ data }),
  EVALUATE_MEMBER_ELIGIBILITY_FIRST_CALL__ERROR: error => ({ error }),
  CLEAR_EVALUATE_MEMBER_ELIGIBILITY_FIRST_CALL__STATE: () => {}
})

const fetchEvaluateMemberEligibilityFirstCall = (
  values,
  operationType,
  validateCode
) => async dispatch => {
  dispatch(evaluateMemberEligibilityFirstCallStart())
  try {
    // eslint-disable-next-line max-len
    const response = await api.post(
      `${apiRoutes.EVALUATE_MEMBER_ELIGIBILITY_AR_PLUS_FIRST_CALL(validateCode)}`,
      {
        ...values,
        operationType: operationType
      }
    )
    dispatch(evaluateMemberEligibilityFirstCallSuccess(response.data))
  } catch (error) {
    const err = error.response ? error.response.data : error
    if (err?.statusCode === 401) {
      const data = {
        show: true,
        redirection: '/',
        message: 'general.session-expired.description'
      }
      dispatch(modalErrors(data))
      dispatch(clearEvaluateMemberEligibilityFirstCallState())
    } else {
      dispatch(evaluateMemberEligibilityFirstCallError(err))
    }
  }
}

export {
  fetchEvaluateMemberEligibilityFirstCall,
  evaluateMemberEligibilityFirstCallStart,
  evaluateMemberEligibilityFirstCallSuccess,
  evaluateMemberEligibilityFirstCallError,
  clearEvaluateMemberEligibilityFirstCallState
}
