import { handleActions } from 'redux-actions'

import {
  fetchDocumentTypesStart,
  fetchDocumentTypesSuccess,
  fetchDocumentTypesError,
  documentTypesClear
} from 'actions/catalog/documentTypes'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchDocumentTypesStart]: state => ({
      ...state,
      data: {},
      error: null,
      isFetching: true
    }),
    [fetchDocumentTypesSuccess]: (state, { payload: { data } }) => {
      return {
        ...state,
        data,
        error: null,
        isFetching: false
      }
    },
    [fetchDocumentTypesError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    }),
    [documentTypesClear]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default reducer
