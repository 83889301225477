import { createGlobalStyle } from 'styled-components'

import { CustomScrollMenuGlobalCSS } from 'components/re-search-box/header/scroll-menu-styled'
import { rangeSliderGlobalCSS } from 'components/slider/styled'
import { tippyGlobalCSS } from 'components/tooltip/styled'

import { chatBox } from './chatBox'
import { CssGlobalVariable } from './cssVariables'
import { DayPickerCss } from './react-day-picker'
import { ReactPhoneNumberInputCss } from './react-phone-number-input'

export default () => createGlobalStyle`
    ${CssGlobalVariable};
    ${CustomScrollMenuGlobalCSS};
    ${rangeSliderGlobalCSS};
    ${tippyGlobalCSS};
    ${DayPickerCss};
    ${chatBox};
    ${ReactPhoneNumberInputCss}
`
