import { handleActions } from 'redux-actions'

import {
  fetchCountriesStart,
  fetchCountriesSuccess,
  fetchCountriesError,
  resetCountries
} from 'actions/catalog/countries'

const stateDefault = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchCountriesStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchCountriesSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [fetchCountriesError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [resetCountries]: () => ({
      ...stateDefault
    })
  },
  stateDefault
)

export default reducer
