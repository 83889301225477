import { handleActions } from 'redux-actions'
import {
  fetchPassengersMissingInfoStart,
  fetchPassengersMissingInfoSuccess,
  fetchPassengersMissingInfoError,
  postPassengersMissingInfoStart,
  postPassengersMissingInfoSuccess,
  postPassengersMissingInfoError,
  resetPassengersMissingInfo
} from 'actions/booking/passengersMissingInfo'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const passengersMissingInfo = handleActions(
  {
    [fetchPassengersMissingInfoStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchPassengersMissingInfoSuccess]: (state, { payload: { response } }) => ({
      ...state,
      data: response,
      error: null,
      isFetching: false
    }),
    [fetchPassengersMissingInfoError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    })
  },
  defaultState
)

const passengersMissingInfoUpdate = handleActions(
  {
    [postPassengersMissingInfoStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [postPassengersMissingInfoSuccess]: (state, { payload: { response } }) => ({
      ...state,
      data: response,
      error: null,
      isFetching: false
    }),
    [postPassengersMissingInfoError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [resetPassengersMissingInfo]: () => defaultState
  },
  defaultState
)

export { passengersMissingInfo, passengersMissingInfoUpdate }
