/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api, { formDataApi } from 'services/api'

import { mapEquipamentClaim } from '../../api-data-io/bo/contactUs'

const {
  getBaggageEquipamentClaimStart,
  getBaggageEquipamentClaimSuccess,
  getBaggageEquipamentClaimError,
  getBaggageEquipamentClaimClear,
  postEquipamentClaimStart,
  postEquipamentClaimSuccess,
  postEquipamentClaimError,
  postEquipamentClaimClear
} = createActions({
  GET_BAGGAGE_EQUIPAMENT_CLAIM_START: () => {},
  GET_BAGGAGE_EQUIPAMENT_CLAIM_SUCCESS: data => ({ data }),
  GET_BAGGAGE_EQUIPAMENT_CLAIM_ERROR: error => ({ error }),
  GET_BAGGAGE_EQUIPAMENT_CLAIM_CLEAR: () => {},
  POST_EQUIPAMENT_CLAIM_START: () => {},
  POST_EQUIPAMENT_CLAIM_SUCCESS: data => ({ data }),
  POST_EQUIPAMENT_CLAIM_ERROR: error => ({ error }),
  POST_EQUIPAMENT_CLAIM_CLEAR: () => {}
})

const postEquipamentClaim = (route, formikFields) => async dispatch => {
  dispatch(postEquipamentClaimStart())
  try {
    const contactUsFormData = new FormData()
    const equipamentClaimBody = JSON.stringify(mapEquipamentClaim(formikFields))

    contactUsFormData.append(
      'baggageClaimForm',
      new Blob([equipamentClaimBody], {
        type: 'application/json'
      })
    )

    if (formikFields.attachments) {
      formikFields.attachments.forEach(attachment =>
        contactUsFormData.append('attachments', attachment)
      )
    } else {
      contactUsFormData.append('attachments', null)
    }

    const response = await formDataApi.post(route, contactUsFormData)
    dispatch(postEquipamentClaimSuccess(response.data || true))
  } catch (error) {
    dispatch(postEquipamentClaimError(error.response ? error.response.data : error))
  }
}

const getEquipamentClaim = route => async dispatch => {
  dispatch(getBaggageEquipamentClaimStart())
  try {
    const response = await api.get(route)
    dispatch(getBaggageEquipamentClaimSuccess(response.data))
  } catch (error) {
    dispatch(getBaggageEquipamentClaimError(error.response ? error.response.data : error))
  }
}

const requestEquipamentClaim = formikFields => async dispatch =>
  dispatch(postEquipamentClaim(apiRoutes.BAGGAGE, formikFields))

const fetchEquipamentClaim = () => async dispatch => dispatch(getEquipamentClaim(apiRoutes.BAGGAGE))

const clearFormik = () => async dispatch => {
  dispatch(getBaggageEquipamentClaimClear())
  dispatch(postEquipamentClaimClear())
}

export {
  requestEquipamentClaim,
  fetchEquipamentClaim,
  clearFormik,
  getBaggageEquipamentClaimStart,
  getBaggageEquipamentClaimSuccess,
  getBaggageEquipamentClaimError,
  getBaggageEquipamentClaimClear,
  postEquipamentClaimStart,
  postEquipamentClaimSuccess,
  postEquipamentClaimError,
  postEquipamentClaimClear
}
