import React, { Fragment } from 'react'

import styled from 'styled-components'

import sideBarLoader from 'assets/loader-checkout-postventa-derecha.gif'
import contentLoader from 'assets/loader-checkout-postventa-izq.gif'
import loadingResults from 'assets/loader-resultados-circulos.gif'
import { PageBlock, BlockContent } from 'components/layout'
import mediaQueries from 'components/media-queries'

const PageContentWrapper = styled.div`
  display: flex;
  margin: 50px 0;
  ${mediaQueries.tablet`
    margin: 0;
  `};
`

const Content = styled.div`
  width: 60%;
  ${mediaQueries.tablet`
    display: none;
  `};
`

const ContentLoader = styled.img.attrs(() => ({
  src: contentLoader,
  alt: 'Loading...'
}))`
  width: 100%;
  margin: 0 auto;
`

const SideBar = styled.div`
  width: 30%;
  ${mediaQueries.tablet`
    width: 80%;
  `};
`

const SideBarLoader = styled.img.attrs(() => ({
  src: sideBarLoader,
  alt: 'Loading...'
}))`
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
  height: 170px;

  ${mediaQueries.tablet`
    margin-bottom: 15px;
  `};
`

const SectionSeparator = styled.div`
  width: 10%;
`

const ResultsLoader = styled.img.attrs(() => ({
  src: loadingResults,
  alt: 'Loading...'
}))`
  align-items: center;
`

const ReservationLoader = ({ isSearchBox }) => (
  <PageBlock>
    <BlockContent>
      <PageContentWrapper>
        {!isSearchBox && (
          <Fragment>
            <Content>
              <ContentLoader />
            </Content>
            <SectionSeparator />
            <SideBar>
              <SideBarLoader />
            </SideBar>
          </Fragment>
        )}
        {isSearchBox && <ResultsLoader />}
      </PageContentWrapper>
    </BlockContent>
  </PageBlock>
)

export default ReservationLoader
