import { handleActions } from 'redux-actions'
import {
  fetchQuestionsStart,
  fetchQuestionsSuccess,
  fetchQuestionsError
} from 'actions/cms/questions'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchQuestionsStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchQuestionsSuccess]: (
      state,
      {
        payload: {
          data: { data }
        }
      }
    ) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [fetchQuestionsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    })
  },
  defaultState
)

export default reducer
