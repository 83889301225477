import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import { buildURLEncoded } from 'helpers/url'

const { fetchCitiesStart, fetchCitiesSuccess, fetchCitiesError } = createActions({
  FETCH_CITIES_START: () => {},
  FETCH_CITIES_SUCCESS: cities => cities,
  FETCH_CITIES_ERROR: error => error
})

const fetchCities = pageInfo => {
  const queryParams = buildURLEncoded('', pageInfo)

  return async dispatch => {
    dispatch(fetchCitiesStart())

    try {
      const cities = await api.get(`${apiRoutes.CITIES}${queryParams}`)
      dispatch(fetchCitiesSuccess(cities))
    } catch (error) {
      dispatch(fetchCitiesError(error))
    }
  }
}

export { fetchCitiesStart, fetchCitiesSuccess, fetchCitiesError, fetchCities }
