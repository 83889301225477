import { createSelector } from 'reselect'

const getOriginalFaresState = state => state.originalFares

export const getOriginalFaresData = createSelector([getOriginalFaresState], state =>
  state ? state.data : null
)

export const getOriginalFares = createSelector([getOriginalFaresData], originalFaresData =>
  originalFaresData ? originalFaresData.totalPrice : null
)

export const getIsFetchingOriginalFares = createSelector([getOriginalFaresState], state =>
  state ? state.isFetching : false
)

export const getOriginalFaresError = createSelector([getOriginalFaresState], state =>
  state ? state.error : null
)
