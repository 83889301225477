import { createActions } from 'redux-actions'

import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
const {
  fetchCampaignsStart,
  fetchCampaignsSuccess,
  fetchCampaignsError,
  clearCampaigns
} = createActions({
  FETCH_CAMPAIGNS_START: () => {},
  FETCH_CAMPAIGNS_SUCCESS: data => ({ data }),
  FETCH_CAMPAIGNS_ERROR: error => ({ error }),
  CLEAR_CAMPAIGNS: () => {}
})

const fetchCampaign = campaignId => async dispatch => {
  dispatch(fetchCampaignsStart())
  try {
    const response = await api.get(`${apiRoutes.CAMPAIGNS}/${campaignId}`)
    dispatch(fetchCampaignsSuccess(response.data))
  } catch (error) {
    dispatch(fetchCampaignsError(error.response ? error.response.data : error))
  }
}

export {
  fetchCampaign,
  fetchCampaignsStart,
  fetchCampaignsSuccess,
  fetchCampaignsError,
  clearCampaigns
}
