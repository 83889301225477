import styled, { css } from 'styled-components'

import { common as e2eClasses } from 'constants/e2eClasses'

export const Wrapper = styled.div.attrs(() => ({
  className: e2eClasses.stepBox
}))`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StepWrapper = styled.div`
  flex: 1;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: no-drop;
    `};
`

export const Step = styled.div.attrs(() => ({
  className: e2eClasses.step
}))`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  flex-grow: 1;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: no-drop;
    `};
`

export const IconContainer = styled.div.attrs(() => ({
  className: e2eClasses.stepIcon
}))`
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  transform: rotate(90deg);
  z-index: 30;
  cursor: pointer;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.grayLight};

      ${props =>
        props.isActive &&
        css`
          fill: ${({ theme }) => theme.colors.blue};
        `};
    }
  }
`

export const Label = styled.label.attrs(() => ({
  className: e2eClasses.stepText
}))`
  color: ${({ theme }) => theme.colors.doveGray};
  font-family: ${({ theme }) => theme.fonts.regular};
  padding: 7px 0 0;
  cursor: pointer;

  ${props =>
    props.isActive &&
    css`
      color: ${({ theme }) => theme.colors.blue};
    `};
`

export const Line = styled.div.attrs(() => ({
  className: e2eClasses.stepLine
}))`
  width: 100%;
  top: 11px;
  right: calc(50% + 18px / 2);
  height: 1px;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.grayLight};

  ${props =>
    props.isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.lochmara};
    `};
`
