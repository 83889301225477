import styled, { css } from 'styled-components'

import mediaQueries from 'components/media-queries'

export const Page = styled.div`
  ${({ withoutMaxWidth, theme }) =>
    theme?.root?.style.getPropertyValue('--show-arsa-keys-is-activated') === 'true'
      ? 'max-width: 97%;'
      : !withoutMaxWidth && 'max-width: 1200px;'}
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
`

export const PageBlock = styled.div.attrs(props => ({
  id: props.id,
  className: props.e2eClass
}))`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: ${({ modalOpen }) => modalOpen && 0};

  ${({ boxShadow, theme: { colors } }) =>
    boxShadow &&
    css`
      box-shadow: 0 ${boxShadow === 'top' ? '-1px' : '1px'} 2px 0 ${colors.black15};
    `};
`

export const BlockContent = styled.div.attrs(props => ({
  className: props.e2eClass
}))`
  max-width: ${({ theme }) =>
    theme?.root?.style.getPropertyValue('--show-arsa-keys-is-activated') === 'true'
      ? '97%'
      : theme.dimensions.container.maxWidth};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin: 0 auto;
  width: 100%;
`

/** @description Sets margin and mobile and tablet lateral padding to pages and header */
export const CommonMarginAndPadding = css`
  width: 100%;
  max-width: ${({ theme }) => theme.dimensions.container.maxWidth};
  margin: 0 auto;

  ${mediaQueries.tablet`
    padding-left: 65px;
    padding-right: 65px;
  `};
  ${mediaQueries.mobile`
    padding-left: 20px;
    padding-right: 20px;
  `};
`
