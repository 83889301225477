import { matchPath } from 'react-router-dom'

import { fetchArplusForServer } from 'actions/cms/arplus/home-data'
import { fetchDarkSiteForServer } from 'actions/cms/darkSite'
import { fetchHeaderForServer } from 'actions/cms/header'
import { fetchLandingsForServer } from 'actions/cms/landings'
import { fetchSlidersForServer } from 'actions/cms/slider'
import GDS_MESSAGES from 'constants/gdsMessages'
import { FORMTRANSLATION_KEYS_FOR_FORMS } from 'constants/reservationServiceForm'
import ROUTES from 'constants/routes'
import { getCookie } from 'helpers/cookies'
import { isConfirmationStep } from 'helpers/url/checkout'
import { isEmpty } from 'helpers/utils'
import { setLanguageInRoute } from 'localization/helpers'
import COOKIE_NAMES from 'server/utils/cookieNames'

export const getStoreForUrl = async (
  pathname,
  initialLanguage,
  staticRoute,
  { headers: { cookie }, i18n },
  purchasingChallenge
) => {
  const dataToClient = {}
  dataToClient.darkSite = await fetchDarkSiteForServer(i18n)

  /** @description Check if it is needed 3P checkout retry or checkout confirmation store setup*/

  const isGlobalRetrial = matchPath(pathname, ROUTES.CHECKOUT_RETRY.LANG_URL)
  const isExternalPayment = matchPath(pathname, ROUTES.EXTERNAL_PAYMENT.LANG_URL)
  const isExternalPaymentConfirmation =
    matchPath(pathname, ROUTES.CHECKOUT.LANG_URL) &&
    isConfirmationStep(matchPath(pathname, ROUTES.CHECKOUT.LANG_URL))

  const errorMessagePaymentFailure = getCookie(cookie, COOKIE_NAMES.PAYMENT_FAILURE_MESSAGE)

  errorMessagePaymentFailure &&
    (dataToClient.purchase = { error: { description: errorMessagePaymentFailure } })

  if (isExternalPayment) {
    dataToClient.externalPayment = mapPurchaseResult(cookie)
  }

  if (isExternalPaymentConfirmation) {
    dataToClient.reservation = mapPurchaseResult(cookie)
  }

  if(purchasingChallenge?.error){
    dataToClient.purchase = purchasingChallenge
  } else if(purchasingChallenge?.data){
    dataToClient.purchasingChallenge = purchasingChallenge
  }

  if (isGlobalRetrial) {
    const someData = mapPurchaseResult(cookie)
    if (someData) {
      dataToClient.paymentDataFormik = someData.data.paymentDataFormik
      dataToClient.purchase = { data: { reservationCode: someData.data.reservationCode } }
    }
  }

  /** @description All routes common store setup */
  if (staticRoute) {
    const homeOrPressComm =
      staticRoute.name === ROUTES.HOME.name || staticRoute.name === ROUTES.PRESS_COMMUNICATION.name

    if (staticRoute.header || homeOrPressComm) {
      const header = await fetchHeaderForServer(i18n)

      dataToClient.header = header
    }

    if (staticRoute.banner || homeOrPressComm) {
      const sliders = await fetchSlidersForServer(i18n)

      dataToClient.sliders = sliders
    }

    if (staticRoute.name === ROUTES.ARPLUS_LOGIN.name) {
      const arplusData = await fetchArplusForServer(null, i18n)
      dataToClient.arplusData = arplusData
    }
  } else {
    /** @description If there is no static route, then it is a landing page */
    const header = await fetchHeaderForServer(i18n)
    const sliders = await fetchSlidersForServer(i18n)
    const landings = await fetchLandingsForServer({ status: 1 }, i18n)

    dataToClient.header = header
    // Ensures there's no possibility of having an unnecessary endless array of landings at client side.
    dataToClient.landings = filterLandingsFromStore(pathname, landings, initialLanguage)
    dataToClient.sliders = sliders
  }
  return dataToClient
}

export const getComponentForUrl = pathname => {
  const componentMatches = Object.keys(ROUTES).map(route =>
    matchPath(pathname, { path: ROUTES[route].LANG_URL, exact: true }) ? ROUTES[route] : null
  )

  return componentMatches.filter(component => !isEmpty(component)).pop()
}

export const getFormTitleForPathname = pathname => {
  const lastIndex = pathname.lastIndexOf('/')
  const slicedLastIndex = pathname.slice(lastIndex + 1)
  return slicedLastIndex && FORMTRANSLATION_KEYS_FOR_FORMS[slicedLastIndex]
}

export const getLandingForPathName = (pathname, landings, language) =>
  ((landings && landings.data) || []).find(({ landingPageUrl }) => {
    const pathWithLanguage = setLanguageInRoute(landingPageUrl, language)
    return matchPath(pathname, { path: pathWithLanguage, exact: true })
  })

export const getBodyColor = store => {
  if (!isEmpty(store.darkSite)) {
    if ((store.darkSite.data || []).find(({ darkSiteEnabled }) => darkSiteEnabled)) {
      return 'style="filter: grayscale(100%)"'
    }
  }

  return ''
}

// Ensures there's no possibility of having an unnecessary endless array of landings
// at client side. It needs HybridLink to force landing links being catched at ssr
// instead of client side react-router.
export const filterLandingsFromStore = (matchedPath, landings, language) => {
  const foundLanding = getLandingForPathName(matchedPath, landings, language)
  if (foundLanding) {
    return { data: landings.data.filter(landingItem => landingItem.nid === foundLanding.nid) }
  }
  // if did fetch landings but path does not match any of them there's no need to have them
  // inside redux client store.
  return []
}

/**
 * Helps mobile exchanges redirection not to fail. :)
 */
export const mapExchangesDirective = (store, cookieHeaders) => {
  const cookie = getCookie(cookieHeaders, COOKIE_NAMES.MOBILE_ENTRY_EXCHANGES)
  const { lastName, pnr } = (cookie && JSON.parse(cookie)) || {}

  if (lastName && pnr) {
    store.reservation = {
      data: {
        isMobileEntryPoint: true,
        passengersData: [{ lastName }],
        bookingMetadata: { reservationCode: pnr }
      }
    }
  }
}

const parseCookieResult = (cookieHeaders, nameCookie) => {
  const cookie = getCookie(cookieHeaders, nameCookie)
  const data = (cookie && JSON.parse(cookie)) || {}
  if (cookie) {
    return { data }
  }
}

/**
 * Redirect to checkout confirmation page with purchase data OR global payment retrial page
 */
export const mapPurchaseResult = cookieHeaders =>
  parseCookieResult(cookieHeaders, COOKIE_NAMES.EXTERNAL_PAYMENT)

export const isGlobalRetrialError = error =>
  error.response &&
  error.response.status === 400 &&
  error.response.data &&
  error.response.data.reservationCode &&
  error.response.data.description &&
  error.response.data.description[0] &&
  error.response.data.description[0] === GDS_MESSAGES.CL_GLOBAL_PAYMENT_ERROR_INVALID_CARD

export const isGlobalPaymentError = error =>
  error.response &&
  error.response.status === 500 &&
  error.response.data &&
  error.response.data.statusCode === 500 &&
  error.response.data.errorMessage === GDS_MESSAGES.PAYMENT_FAILURE

export const flightSummaryInvalidRequest = error =>
  error.statusCode === 400 && error.errorMessage === GDS_MESSAGES.FLIGHT_INVALID_REQUEST
