const SEARCH_BOX_TABS = {
  SEARCH_BOX:
    'searchBox&utm_source=Website&utm_medium=Home_Caja%20de%20busqueda&utm_campaign=Vuelos&artid=aeswcaj878',
  CHECK_IN:
    'checkIn&utm_source=Website&utm_medium=Home_Caja%20de%20busqueda&utm_campaign=Check-in&artid=aeswcaj013',
  FLIGHT_STATUS:
    'flightStatus&utm_source=Website&utm_medium=Home_Caja%20de%20busqueda&utm_campaign=Estado%20de%20vuelo&artid=aeswcaj012',
  MY_RESERVATION:
    'myReservation&utm_source=Website&utm_medium=Home_Caja%20de%20busqueda&utm_campaign=Mi%20Reserva&artid=aeswcaj879'
}

const SEARCH_BOX_TABS_3P = {
  SEARCH_BOX: 'searchBox',
  CHECK_IN: 'checkIn',
  FLIGHT_STATUS: 'flightStatus',
  MY_RESERVATION: 'myReservation'
}

const SEARCH_BOX = {
  FROM_POSITION: 1,
  TO_POSITION: 2,
  DATE_FROM_POSITION: 3,
  DATE_TO_POSITION: 4,
  CONFIG_PASSENGER_POSITION: 5,
  CAMPAIGN_FIELDS_POSITION: 6,
  CONFERENCE_CODE_POSITION: 5,
  MAX_LEGS_FLIGHTS: 8,
  AUTOSUGGEST_MIN_LENGTH: 3,
  STORAGE_KEY: 'history',
  SUGGESTIONS_GROUP_FROM_KEY: 'suggestionsFrom',
  SUGGESTIONS_GROUP_TO_KEY: 'suggestionsTo'
}

export { SEARCH_BOX, SEARCH_BOX_TABS, SEARCH_BOX_TABS_3P }
