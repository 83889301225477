import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { fetchLoginForm } from 'actions/loginActions'
import { getLogin } from 'selectors/login'

import loginImage from 'assets/login-img.png'

import { generateArPlusRoute } from 'helpers/url/localization'

import Input from 'components/input'
import Button from 'components/button'
import { Title3, Title5 } from 'components/fonts'
import Icon, { IconNames } from 'components/icons'
import Message from 'components/message'

import {
  FormContainer,
  IconContainer,
  LoginBottom,
  UserContainer,
  PasswordContainer,
  RegisterContainer,
  RegisterMobile,
  StyledContainer,
  StyledForm,
  StyledLabel,
  StyledLink,
  StyledSeparator,
  Title,
  RegisterButton,
  MessageContainter,
  StyledLinkMessage
} from './styled'

class Form extends Component {
  state = { user: '', password: '' }

  componentDidUpdate(prevProps) {
    if (!prevProps.login.data && this.props.login.data) {
      this.props.history.push(generateArPlusRoute(this.props.i18n.language))
    }
  }

  static propTypes = {
    onClickRegister: PropTypes.func
  }

  static defaultProps = {
    onClickRegister: () => {}
  }

  onClickLogin = () => {
    this.props.fetchLoginForm({ ...this.state })
  }

  handleUsernameChange = ({ target: { value: user } }) => {
    this.setState({ user })
  }

  handlePasswordChange = ({ target: { value: password } }) => {
    this.setState({ password })
  }

  buildErrorMessage = statusCode => {
    return statusCode === 400 ? (
      <Message type="warning">
        {this.props.t('loyalty.login.error.membership-number')}
        <StyledLinkMessage href="#">
          {this.props.t('loyalty.login.error.enter-here')}
        </StyledLinkMessage>
        {this.props.t('loyalty.login.error.password-forgotten')}
        <StyledLinkMessage href="#">
          {this.props.t('loyalty.login.error.recover-here')}
        </StyledLinkMessage>
      </Message>
    ) : (
      <Message type="error">{this.props.t('loyalty.login.error.service-not-available')}</Message>
    )
  }

  render() {
    const { login } = this.props
    return (
      <StyledContainer>
        {login.error && (
          <MessageContainter>{this.buildErrorMessage(login.error.statusCode)}</MessageContainter>
        )}
        <RegisterContainer>
          <img src={loginImage} alt="login" />
          <br />
          <Button large>{this.props.t('loyalty.login.register')}</Button>
        </RegisterContainer>
        <RegisterMobile>
          <Title>
            <Title3>{this.props.t('loyalty.login.enjoy-arplus')}</Title3>
            <p>
              <em>{this.props.t('loyalty.login.still-not-a-member')}</em>
            </p>
          </Title>
          <RegisterButton>
            <Button white small onClick={this.props.onClickRegister} className="register">
              {this.props.t('loyalty.login.register')}
            </Button>
          </RegisterButton>
        </RegisterMobile>
        <StyledSeparator />
        <StyledForm>
          <StyledLabel>
            <IconContainer>
              <Icon name={IconNames.People} />
            </IconContainer>
            <Title5>{this.props.t('loyalty.login.my-arplus-account')}</Title5>
          </StyledLabel>
          <FormContainer>
            <UserContainer>
              <Input
                onChange={this.handleUsernameChange}
                name="user"
                label={this.props.t('loyalty.login.membership-number')}
                placeholder={this.props.t('loyalty.login.membership-number-placeholder')}
              />
              <StyledLink href="#">{this.props.t('loyalty.login.forgot-user')}</StyledLink>
            </UserContainer>
            <PasswordContainer>
              <Input
                onChange={this.handlePasswordChange}
                name="password"
                label={this.props.t('loyalty.login.password')}
                placeholder={this.props.t('loyalty.login.password-placeholder')}
                type="password"
              />
              <StyledLink href="#">{this.props.t('loyalty.login.forgot-password')}</StyledLink>
            </PasswordContainer>
          </FormContainer>
          <br />
          <LoginBottom>
            <Button small onClick={() => this.onClickLogin()}>
              {this.props.t('label.login')}
            </Button>
          </LoginBottom>
          <StyledLink firstEntry href="#">
            {this.props.t('loyalty.login.first-time')}
          </StyledLink>
        </StyledForm>
      </StyledContainer>
    )
  }
}

const mapDispatchToProps = {
  fetchLoginForm
}

const mapStateToProps = state => ({
  login: getLogin(state)
})

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Form)))
