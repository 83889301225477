import { createSelector } from 'reselect'

export const getAccountEvaluation = state => state.accountEvaluation

export const getAccountEvaluationData = createSelector([getAccountEvaluation], accountEvaluation =>
  accountEvaluation ? accountEvaluation.data : null
)

export const getAccountEvaluationError = createSelector([getAccountEvaluation], accountEvaluation =>
  accountEvaluation ? accountEvaluation.error : null
)

export const getAccountEvaluationIsFetching = createSelector(
  [getAccountEvaluation],
  accountEvaluation => (accountEvaluation ? accountEvaluation.isFetching : null)
)

export const getAccountPoints = createSelector([getAccountEvaluationData], accountEvaluationData =>
  accountEvaluationData ? accountEvaluationData.points : null
)

export const getMilesExpirationDate = createSelector(
  [getAccountEvaluationData],
  accountEvaluationData =>
    accountEvaluationData ? accountEvaluationData.milesExpirationDate : null
)

export const getMemberFullName = createSelector(
  [getAccountEvaluationData],
  accountEvaluationData =>
    accountEvaluationData ? accountEvaluationData.fullName : null
)
