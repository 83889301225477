import styled from 'styled-components'

import mediaQueries from 'components/media-queries'

export const MobileViewWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  background-color: ${({ theme }) => theme.colors.white};

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 100;
`

export const HeaderContainer = styled.div`
  justify-content: space-around;
  align-items: center;
  width: 100%;
  display: flex;

  height: 110px;
  background-color: ${({ theme }) => theme.colors.gray10};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayLight};

  ${mediaQueries.mobile`
    height: 84px;
    background-color: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.silverChalice};
  `}
`

export const SizeWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
`

export const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

export const Title = styled.h1`
  display: flex;
  margin-left: 16px;
  align-items: center;
  font-size: ${({ fontSize }) => fontSize || '30px'};
  color: ${({ theme }) => theme.colors.doveGray};
  font-family: ${({ titleSemiBold, theme }) =>
    titleSemiBold ? theme.fonts.semiBold : theme.fonts.regular};
  ${mediaQueries.mobile`
    font-size: 24px;
  `};
`

export const StyledButton = styled.button`
  border: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.transparent};
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.fonts.bold};

  &:focus {
    outline: none;
  }

  svg {
    path {
      fill: ${({ theme }) => theme.colors.blue};
    }
  }
`
