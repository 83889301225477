/* eslint-disable import/order */
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { PersistGate } from 'redux-persist/integration/react'
import { useSSR } from 'react-i18next'

import dayjs from 'dayjs'
import Loadable from 'react-loadable'

import { configAxiosInterceptors } from 'services/api'
import configureStore from 'helpers/configureStore'
import theme from 'styles/theme'

import App from './App'
import { TransitionLoader } from 'components/loaders'

import 'localization/i18n'

const { store, persistor } = configureStore(window.__PRELOADED_STATE__)

//Config global interceptors
configAxiosInterceptors(store)

//TODO: Replace 'es' with value sent by server
dayjs.locale('es')

const root = document.getElementById('root')

if (module.hot) {
  module.hot.accept('./App', () => {
    const NewApp = require('./App').default
    render(NewApp)
  })
}

render(App)

const BaseApp = props => {
  useSSR(window.initialI18nStore, window.initialLanguage)
  return (
    <Suspense fallback={<TransitionLoader />}>
      <BrowserRouter>
        <PersistGate loading={null} persistor={persistor}>
          {props.component}
        </PersistGate>
      </BrowserRouter>
    </Suspense>
  )
}

function render(Root) {
  Loadable.preloadAll().then(() => {
    ReactDOM.hydrate(
      <Provider store={store}>
        <ThemeProvider theme={{ ...theme, root: window.root }}>
          <BaseApp component={Root} />
        </ThemeProvider>
      </Provider>,
      root
    )
  })
}
