export default {
  v: '5.4.1',
  fr: 29.9700012207031,
  ip: 0,
  op: 179.000007290819,
  w: 400,
  h: 600,
  nm: 'waiting_ships',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'pierna Outlines',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p667_1_0p167_0p167'],
              t: 0,
              s: [11],
              e: [-9]
            },
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.333],
                y: [0]
              },
              n: ['0p833_0p833_0p333_0'],
              t: 22,
              s: [-9],
              e: [11]
            },
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p833_0p833_0p167_0p167'],
              t: 45,
              s: [11],
              e: [11]
            },
            {
              i: {
                x: [0.667],
                y: [1]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p667_1_0p167_0p167'],
              t: 46,
              s: [11],
              e: [-9]
            },
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.333],
                y: [0]
              },
              n: ['0p833_0p833_0p333_0'],
              t: 68,
              s: [-9],
              e: [11]
            },
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p833_0p833_0p167_0p167'],
              t: 90,
              s: [11],
              e: [11]
            },
            {
              i: {
                x: [0.667],
                y: [1]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p667_1_0p167_0p167'],
              t: 91,
              s: [11],
              e: [-9]
            },
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.333],
                y: [0]
              },
              n: ['0p833_0p833_0p333_0'],
              t: 113,
              s: [-9],
              e: [11]
            },
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p833_0p833_0p167_0p167'],
              t: 136,
              s: [11],
              e: [11]
            },
            {
              i: {
                x: [0.667],
                y: [1]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p667_1_0p167_0p167'],
              t: 137,
              s: [11],
              e: [-9]
            },
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.333],
                y: [0]
              },
              n: ['0p833_0p833_0p333_0'],
              t: 159,
              s: [-9],
              e: [11]
            },
            {
              t: 179.000007290819
            }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [251.629, 356.179, 0],
              e: [250.629, 363.179, 0],
              to: [-0.16666667163372, 1.16666662693024, 0],
              ti: [0.16666121780872, -0.0035812377464, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 22,
              s: [250.629, 363.179, 0],
              e: [250.629, 356.2, 0],
              to: [-0.16666121780872, 0.0035812377464, 0],
              ti: [-0.16666667163372, 1.16666662693024, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [250.629, 356.2, 0],
              e: [251.629, 356.179, 0],
              to: [0.16666667163372, -1.16666662693024, 0],
              ti: [0.00000545247394, -1.16308546066284, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 46,
              s: [251.629, 356.179, 0],
              e: [250.629, 363.179, 0],
              to: [-0.00000545247394, 1.16308546066284, 0],
              ti: [0.16666121780872, -0.0035812377464, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 68,
              s: [250.629, 363.179, 0],
              e: [250.629, 356.2, 0],
              to: [-0.16666121780872, 0.0035812377464, 0],
              ti: [-0.16666667163372, 1.16666662693024, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 90,
              s: [250.629, 356.2, 0],
              e: [251.629, 356.179, 0],
              to: [0.16666667163372, -1.16666662693024, 0],
              ti: [0.00000545247394, -1.16308546066284, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 91,
              s: [251.629, 356.179, 0],
              e: [250.629, 363.179, 0],
              to: [-0.00000545247394, 1.16308546066284, 0],
              ti: [0.16666121780872, -0.0035812377464, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 113,
              s: [250.629, 363.179, 0],
              e: [250.629, 356.2, 0],
              to: [-0.16666121780872, 0.0035812377464, 0],
              ti: [-0.16666667163372, 1.16666662693024, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 136,
              s: [250.629, 356.2, 0],
              e: [251.629, 356.179, 0],
              to: [0.16666667163372, -1.16666662693024, 0],
              ti: [0.00000545247394, -1.16308546066284, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 137,
              s: [251.629, 356.179, 0],
              e: [250.629, 363.179, 0],
              to: [-0.00000545247394, 1.16308546066284, 0],
              ti: [0.16666121780872, -0.0035812377464, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 159,
              s: [250.629, 363.179, 0],
              e: [250.629, 356.2, 0],
              to: [-0.16666121780872, 0.0035812377464, 0],
              ti: [-0.00000545247394, 1.16308546066284, 0]
            },
            {
              t: 179.000007290819
            }
          ],
          ix: 2
        },
        a: {
          a: 0,
          k: [9.34, 27.961, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[16.387, -5.707], [0, 0], [-7.677, 6.534], [0, 0], [-3.399, -2.808]],
                  o: [[0, 0], [0, 0], [7.678, -6.534], [0, 0], [3.397, 2.808]],
                  v: [
                    [-10.203, 21.988],
                    [-22.999, 3.883],
                    [-2.799, -10.715],
                    [10.004, -21.988],
                    [19.602, -4.851]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [55.529, 99.294],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.026, 0.006],
                    [-0.166, -0.549],
                    [-0.282, -0.989],
                    [-0.56, -1.441],
                    [-0.721, -1.801],
                    [-5.934, -10.259],
                    [-4.328, -6.456],
                    [-1.226, -1.796],
                    [-0.361, -0.533],
                    [0.022, -0.016],
                    [0.357, 0.448],
                    [0.647, 0.874],
                    [2.218, 3.198],
                    [5.932, 10.299],
                    [2.685, 7.327],
                    [0.496, 1.465],
                    [0.247, 1.061],
                    [0.14, 0.63]
                  ],
                  o: [
                    [0.026, -0.005],
                    [0.177, 0.618],
                    [0.292, 1.045],
                    [0.534, 1.446],
                    [2.803, 7.238],
                    [5.918, 10.273],
                    [2.157, 3.232],
                    [0.578, 0.853],
                    [0.316, 0.478],
                    [-0.021, 0.016],
                    [-0.355, -0.449],
                    [-1.304, -1.743],
                    [-4.45, -6.383],
                    [-5.95, -10.285],
                    [-0.691, -1.822],
                    [-0.523, -1.459],
                    [-0.226, -1.008],
                    [-0.117, -0.56]
                  ],
                  v: [
                    [-20.287, -37.624],
                    [-19.995, -36.794],
                    [-19.307, -34.389],
                    [-18.143, -30.613],
                    [-16.364, -25.691],
                    [-2.849, 1.439],
                    [13.266, 27.17],
                    [18.433, 34.789],
                    [19.839, 36.862],
                    [20.292, 37.613],
                    [19.711, 36.955],
                    [18.187, 34.967],
                    [12.808, 27.481],
                    [-3.526, 1.83],
                    [-16.88, -25.494],
                    [-18.56, -30.48],
                    [-19.601, -34.311],
                    [-20.148, -36.761]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0.536999990426, 0.57599995931, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [22.791, 65.961],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 2',
          np: 4,
          cix: 2,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-21.517, -0.162], [0, 0], [0, 0], [5.748, 28.322]],
                  o: [[4.404, 1.06], [0, 0], [0, 0], [0, 0]],
                  v: [[-10.352, -51.5], [31.87, 35.928], [10.962, 51.662], [-29.617, -23.575]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.365000017952, 0.677999997606, 0.702000038297, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [32.12, 51.912],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 3',
          np: 4,
          cix: 2,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [-0.878, -2.538], [1.082, 4.149]],
                  o: [[-11.138, 7.626], [0.878, 2.539], [-1.082, -4.148]],
                  v: [[-0.716, -3.91], [-16.603, 13.74], [16.399, -12.131]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0.536999990426, 0.57599995931, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [49.133, 89.437],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 4',
          np: 4,
          cix: 2,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 180.00000733155,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'pie Outlines',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p833_0p833_0p167_0p167'],
              t: 0,
              s: [0],
              e: [-5]
            },
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p833_0p833_0p167_0p167'],
              t: 22,
              s: [-5],
              e: [0]
            },
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p833_0p833_0p167_0p167'],
              t: 45,
              s: [0],
              e: [0]
            },
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p833_0p833_0p167_0p167'],
              t: 46,
              s: [0],
              e: [-5]
            },
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p833_0p833_0p167_0p167'],
              t: 68,
              s: [-5],
              e: [0]
            },
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p833_0p833_0p167_0p167'],
              t: 90,
              s: [0],
              e: [0]
            },
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p833_0p833_0p167_0p167'],
              t: 91,
              s: [0],
              e: [-5]
            },
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p833_0p833_0p167_0p167'],
              t: 113,
              s: [-5],
              e: [0]
            },
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p833_0p833_0p167_0p167'],
              t: 136,
              s: [0],
              e: [0]
            },
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p833_0p833_0p167_0p167'],
              t: 137,
              s: [0],
              e: [-5]
            },
            {
              i: {
                x: [0.833],
                y: [0.833]
              },
              o: {
                x: [0.167],
                y: [0.167]
              },
              n: ['0p833_0p833_0p167_0p167'],
              t: 159,
              s: [-5],
              e: [0]
            },
            {
              t: 179.000007290819
            }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p667_1_0p167_0p167',
              t: 0,
              s: [283.256, 443.14, 0],
              e: [308.756, 429.14, 0],
              to: [4.25, -2.33333325386047, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.333,
                y: 0
              },
              n: '0p833_0p833_0p333_0',
              t: 22,
              s: [308.756, 429.14, 0],
              e: [283.256, 443.14, 0],
              to: [0, 0, 0],
              ti: [4.25, -2.33333325386047, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.333,
                y: 0.333
              },
              n: '0p833_0p833_0p333_0p333',
              t: 45,
              s: [283.256, 443.14, 0],
              e: [283.256, 443.14, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.667,
                y: 1
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p667_1_0p167_0p167',
              t: 46,
              s: [283.256, 443.14, 0],
              e: [308.756, 429.14, 0],
              to: [4.25, -2.33333325386047, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.333,
                y: 0
              },
              n: '0p833_0p833_0p333_0',
              t: 68,
              s: [308.756, 429.14, 0],
              e: [283.256, 443.14, 0],
              to: [0, 0, 0],
              ti: [4.25, -2.33333325386047, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.333,
                y: 0.333
              },
              n: '0p833_0p833_0p333_0p333',
              t: 90,
              s: [283.256, 443.14, 0],
              e: [283.256, 443.14, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.667,
                y: 1
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p667_1_0p167_0p167',
              t: 91,
              s: [283.256, 443.14, 0],
              e: [308.756, 429.14, 0],
              to: [4.25, -2.33333325386047, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.333,
                y: 0
              },
              n: '0p833_0p833_0p333_0',
              t: 113,
              s: [308.756, 429.14, 0],
              e: [283.256, 443.14, 0],
              to: [0, 0, 0],
              ti: [4.25, -2.33333325386047, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 136,
              s: [283.256, 443.14, 0],
              e: [283.256, 443.14, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.667,
                y: 1
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p667_1_0p167_0p167',
              t: 137,
              s: [283.256, 443.14, 0],
              e: [308.756, 429.14, 0],
              to: [4.25, -2.33333325386047, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.333,
                y: 0
              },
              n: '0p833_0p833_0p333_0',
              t: 159,
              s: [308.756, 429.14, 0],
              e: [283.256, 443.14, 0],
              to: [0, 0, 0],
              ti: [4.25, -2.33333325386047, 0]
            },
            {
              t: 179.000007290819
            }
          ],
          ix: 2
        },
        a: {
          a: 0,
          k: [5.841, 16.241, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-0.093, -4.827], [1.544, -1.267], [0, 0], [-1.207, -0.02]],
                  o: [[-1.375, 1.269], [0, 0], [0.181, -6.115], [1.036, 0.016]],
                  v: [[2.189, 2.314], [-2.189, 6.118], [-2.17, 4.954], [0.334, -6.098]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 1, 1, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [40.218, 29.968],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.128, -5.416],
                    [0.024, -0.169],
                    [1.307, -0.995],
                    [-0.043, 1.395],
                    [-1.207, 0.002]
                  ],
                  o: [
                    [-0.003, 0.19],
                    [-1.522, 1.224],
                    [-0.107, -1.229],
                    [0.149, -5.353],
                    [1.206, -0.002]
                  ],
                  v: [
                    [2.148, 2.981],
                    [2.117, 3.531],
                    [-2.125, 6.829],
                    [-2.232, 2.87],
                    [0.208, -6.827]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 1, 1, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [33.511, 34.483],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 2',
          np: 4,
          cix: 2,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [16.754, -15.438],
                    [1.544, -1.267],
                    [0.151, -0.124],
                    [0.643, -0.497],
                    [1.307, -0.995],
                    [0, 0],
                    [0, 0],
                    [-2.602, 5.228],
                    [-5.516, 0.824]
                  ],
                  o: [
                    [-1.375, 1.269],
                    [-0.129, 0.125],
                    [-0.686, 0.561],
                    [-1.522, 1.225],
                    [-16.409, 12.376],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [5.05, -0.766]
                  ],
                  v: [
                    [3.42, 5.312],
                    [-0.958, 9.116],
                    [-1.387, 9.468],
                    [-3.359, 11.045],
                    [-7.602, 14.343],
                    [-26.186, 23.763],
                    [-26.935, 13.656],
                    [-15.561, 5.117],
                    [21.885, -25.953]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.136999990426, 0.277999997606, 0.372999991623, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [38.987, 26.969],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 3',
          np: 4,
          cix: 2,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  v: [[-12.358, -8.697], [1.005, 16.991], [12.358, 10.286], [0.167, -16.991]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.745000023935, 0.6, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [12.608, 26.185],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 180.00000733155,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'dedo derecho Outlines',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [257.393, 295.139, 0],
              e: [262.393, 287.639, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 8,
              s: [262.393, 287.639, 0],
              e: [257.393, 295.139, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 11,
              s: [257.393, 295.139, 0],
              e: [262.393, 287.639, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 28,
              s: [262.393, 287.639, 0],
              e: [257.393, 295.139, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 31,
              s: [257.393, 295.139, 0],
              e: [257.393, 295.139, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 40,
              s: [257.393, 295.139, 0],
              e: [257.393, 295.139, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 65,
              s: [257.393, 295.139, 0],
              e: [262.393, 287.639, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 73,
              s: [262.393, 287.639, 0],
              e: [257.393, 295.139, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 76,
              s: [257.393, 295.139, 0],
              e: [257.393, 295.139, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 93,
              s: [257.393, 295.139, 0],
              e: [262.393, 287.639, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 96,
              s: [262.393, 287.639, 0],
              e: [257.393, 295.139, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 105,
              s: [257.393, 295.139, 0],
              e: [257.393, 295.139, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 146,
              s: [257.393, 295.139, 0],
              e: [262.393, 287.639, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 154,
              s: [262.393, 287.639, 0],
              e: [257.393, 295.139, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 157,
              s: [257.393, 295.139, 0],
              e: [257.393, 295.139, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 172,
              s: [257.393, 295.139, 0],
              e: [262.393, 287.639, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 175,
              s: [262.393, 287.639, 0],
              e: [257.393, 295.139, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              t: 180.00000733155
            }
          ],
          ix: 2
        },
        a: {
          a: 0,
          k: [11.213, 17.382, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-0.637, 0], [0, 0], [-0.268, 3.74], [4.017, -9.351], [-0.265, -2.218]],
                  o: [[11.13, 0], [0, 0], [0.268, -3.741], [-3.45, 8.031], [0.622, 0.064]],
                  v: [
                    [-8.111, 17.132],
                    [4.268, -2.705],
                    [10.694, -13.391],
                    [-7.512, -1.637],
                    [-9.999, 17.031]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.980000035903, 0.741000007181, 0.596000043084, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [11.213, 17.382],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 180.00000733155,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'dedo izq Outlines',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [257.393, 291.139, 0],
              e: [254.393, 295.139, 0],
              to: [-0.5, 0.66666668653488, 0],
              ti: [-0.03321596980095, 0.51616787910461, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 8,
              s: [254.393, 295.139, 0],
              e: [257.592, 288.042, 0],
              to: [0.03321596980095, -0.51616787910461, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 13,
              s: [257.592, 288.042, 0],
              e: [254.393, 295.139, 0],
              to: [0, 0, 0],
              ti: [0.53321593999863, -1.18283450603485, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 17,
              s: [254.393, 295.139, 0],
              e: [254.393, 295.139, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 31,
              s: [254.393, 295.139, 0],
              e: [254.393, 295.139, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 71,
              s: [254.393, 295.139, 0],
              e: [257.592, 288.042, 0],
              to: [0.53321593999863, -1.18283450603485, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 77,
              s: [257.592, 288.042, 0],
              e: [254.393, 295.139, 0],
              to: [0, 0, 0],
              ti: [0.53321593999863, -1.18283450603485, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 80,
              s: [254.393, 295.139, 0],
              e: [254.393, 295.139, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 162,
              s: [254.393, 295.139, 0],
              e: [257.592, 288.042, 0],
              to: [0.53321593999863, -1.18283450603485, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              n: '0p833_0p833_0p167_0p167',
              t: 167,
              s: [257.592, 288.042, 0],
              e: [254.393, 295.139, 0],
              to: [0, 0, 0],
              ti: [0.53321593999863, -1.18283450603485, 0]
            },
            {
              t: 171.000006964973
            }
          ],
          ix: 2
        },
        a: {
          a: 0,
          k: [12.188, 20.362, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-4.548, 5.369],
                    [-12.577, 1.632],
                    [-0.268, 3.74],
                    [4.017, -9.351],
                    [-0.265, -2.218]
                  ],
                  o: [
                    [4.547, -5.369],
                    [1.098, -0.88],
                    [0.268, -3.741],
                    [-3.45, 8.031],
                    [0.622, 0.064]
                  ],
                  v: [
                    [-7.263, 14.448],
                    [5.117, -5.39],
                    [11.543, -16.076],
                    [-6.664, -4.322],
                    [-9.15, 14.346]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.972999961703, 0.638999968884, 0.447000002394, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [12.061, 20.067],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 180.00000733155,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'girl Outlines',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 0,
          k: [197.176, 351.533, 0],
          ix: 2
        },
        a: {
          a: 0,
          k: [111.267, 192.804, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-10.915, 0], [-0.764, 0.105], [-0.838, 2.032], [1.825, 2.687], [0, 0]],
                  o: [[0.738, 0], [3.843, -0.531], [1.498, -3.634], [0, 0], [2.23, 5.586]],
                  v: [
                    [4.723, 8.697],
                    [6.986, 8.538],
                    [14.041, 4.675],
                    [10.512, -7.552],
                    [-14.428, -8.697]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.277999997606, 0.243000000598, 0.20800000359, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [113.514, 31.407],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-7.349, 0], [-0.351, 0.036], [-0.992, 1.593], [0.636, 2.396], [0, 0]],
                  o: [[0.34, 0], [2.6, -0.265], [1.603, -2.575], [0, 0], [2.245, 4.602]],
                  v: [
                    [3.62, 6.426],
                    [4.657, 6.372],
                    [9.995, 3.609],
                    [10.373, -5.443],
                    [-11.026, -6.426]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.277999997606, 0.243000000598, 0.20800000359, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [144.99, 31.412],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 2',
          np: 4,
          cix: 2,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[1.498, -3.633], [3.844, -0.531], [0.738, 0], [2.231, 5.586], [0, 0]],
                  o: [[-0.838, 2.032], [-0.763, 0.106], [-10.915, 0], [0, 0], [1.825, 2.688]],
                  v: [
                    [10.18, 3.596],
                    [3.124, 7.459],
                    [0.861, 7.618],
                    [-18.29, -9.776],
                    [6.65, -8.631]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [-17.946, 2.479]],
                  o: [[0, 0], [0, 0], [17.947, -2.48]],
                  v: [[7.783, -10.716], [-21.363, -12.054], [3.415, 9.575]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.834999952129, 0.051000000449, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [117.375, 32.486],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 3',
          np: 6,
          cix: 2,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[1.603, -2.575], [2.6, -0.265], [0.34, 0], [2.245, 4.601], [0, 0]],
                  o: [[-0.992, 1.593], [-0.351, 0.036], [-7.349, 0], [0, 0], [0.636, 2.396]],
                  v: [
                    [8.43, 3.073],
                    [3.092, 5.836],
                    [2.055, 5.891],
                    [-12.591, -6.961],
                    [8.808, -5.978]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [-12.609, 1.29]],
                  o: [[0, 0], [0, 0], [12.608, -1.291]],
                  v: [[10.399, -8.043], [-15.918, -9.251], [3.31, 7.961]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.834999952129, 0.051000000449, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [146.555, 31.948],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 4',
          np: 6,
          cix: 2,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.112, -0.201],
                    [1.64, 0.12],
                    [0.822, 0.842],
                    [0.094, 1.374],
                    [-1.69, 2.765],
                    [0, 0],
                    [0, 0],
                    [8.389, -11.814],
                    [0.113, -4.22],
                    [-1.09, -0.37]
                  ],
                  o: [
                    [0.136, 0.092],
                    [-0.777, -0.079],
                    [-0.824, -0.856],
                    [-0.192, -2.778],
                    [1.706, -2.742],
                    [0, 0],
                    [0, 0],
                    [-4.031, 5.965],
                    [-0.139, 2.078],
                    [1.064, 0.404]
                  ],
                  v: [
                    [-6.883, 21.151],
                    [-8.996, 22.022],
                    [-11.552, 20.76],
                    [-12.965, 17.264],
                    [-10.236, 8.755],
                    [10.458, -22.142],
                    [13.158, -18.732],
                    [-2.081, 1.887],
                    [-10.889, 17.214],
                    [-8.763, 20.902]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.834999952129, 0.051000000449, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [86.054, 42.958],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 5',
          np: 4,
          cix: 2,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.381, 0.009],
                    [0.562, 0.599],
                    [1.238, -0.054],
                    [0.688, 0.422],
                    [-0.665, 1.059],
                    [-2.124, 0.116],
                    [-0.865, -0.592],
                    [-0.148, -0.664]
                  ],
                  o: [
                    [-0.406, 0.009],
                    [-0.598, -0.601],
                    [-2.317, 0.082],
                    [-0.352, -0.185],
                    [0.621, -1.029],
                    [1.006, -0.067],
                    [0.868, 0.581],
                    [0.293, 1.36]
                  ],
                  v: [
                    [4.008, 2.472],
                    [2.685, 1.248],
                    [0.291, 0.479],
                    [-4.509, 2.097],
                    [-4.194, 0.013],
                    [0.109, -2.452],
                    [3.08, -1.722],
                    [4.568, 0.374]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.834999952129, 0.051000000449, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [127.893, 21.436],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 6',
          np: 4,
          cix: 2,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.023, -0.004],
                    [0.157, -0.717],
                    [0.116, -0.513],
                    [0.253, -0.655],
                    [2.378, -4.519],
                    [10.331, -12.677],
                    [0, 0],
                    [0, 0],
                    [5.333, 0.912],
                    [11.1, 7.082],
                    [0.667, 0.414],
                    [0.593, 0.451],
                    [1.065, 0.768],
                    [0.895, 0.676],
                    [0.709, 0.598],
                    [0.777, 0.662],
                    [0.215, 0.19],
                    [-0.008, 0.01],
                    [-0.2, -0.148],
                    [-0.36, -0.275],
                    [-1.409, -1.12],
                    [-0.868, -0.699],
                    [-1.066, -0.748],
                    [-1.212, -0.847],
                    [-0.672, -0.401],
                    [-0.702, -0.425],
                    [-19.787, -3.46],
                    [-5.011, -0.125],
                    [0, 0],
                    [-4.913, 8.9],
                    [-0.909, 2.64],
                    [-0.137, 0.518],
                    [-0.101, 0.368]
                  ],
                  o: [
                    [0.023, 0.004],
                    [-0.084, 0.373],
                    [-0.116, 0.526],
                    [-0.836, 2.684],
                    [-4.821, 9.009],
                    [0, 0],
                    [0, 0],
                    [-5.101, -0.116],
                    [-19.901, -3.48],
                    [-0.701, -0.435],
                    [-0.67, -0.41],
                    [-1.204, -0.867],
                    [-1.059, -0.77],
                    [-0.855, -0.721],
                    [-1.373, -1.171],
                    [-0.339, -0.299],
                    [-0.184, -0.168],
                    [0.009, -0.009],
                    [0.228, 0.175],
                    [0.798, 0.634],
                    [0.721, 0.58],
                    [0.919, 0.643],
                    [1.073, 0.75],
                    [0.595, 0.442],
                    [0.669, 0.404],
                    [11.127, 6.931],
                    [5.292, 0.908],
                    [0, 0],
                    [10.324, -12.606],
                    [2.427, -4.467],
                    [0.272, -0.647],
                    [0.139, -0.503],
                    [0.2, -0.703]
                  ],
                  v: [
                    [54.939, -24.451],
                    [54.734, -23.356],
                    [54.434, -22.029],
                    [53.931, -20.237],
                    [49.128, -9.255],
                    [25.954, 24.315],
                    [25.838, 24.455],
                    [25.661, 24.451],
                    [9.993, 22.806],
                    [-37.964, 4.655],
                    [-40.016, 3.382],
                    [-41.929, 2.12],
                    [-45.335, -0.334],
                    [-48.287, -2.482],
                    [-50.634, -4.461],
                    [-53.864, -7.216],
                    [-54.69, -7.947],
                    [-54.954, -8.216],
                    [-54.637, -8.007],
                    [-53.761, -7.335],
                    [-50.444, -4.698],
                    [-48.06, -2.778],
                    [-45.08, -0.689],
                    [-41.652, 1.707],
                    [-39.732, 2.94],
                    [-37.674, 4.184],
                    [10.126, 22.034],
                    [25.661, 23.687],
                    [25.368, 23.823],
                    [48.694, -9.49],
                    [53.678, -20.32],
                    [54.239, -22.083],
                    [54.6, -23.387]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.972999961703, 0.447000002394, 0.054999998504, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [116.58, 179.231],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 7',
          np: 4,
          cix: 2,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.041, -0.035],
                    [0.596, -0.729],
                    [0.448, -0.498],
                    [0.568, -0.592],
                    [4.169, -3.005],
                    [2.929, -1.653],
                    [0.847, -0.411],
                    [0.024, 0.047],
                    [-0.797, 0.496],
                    [-1.329, 0.816],
                    [-4.15, 2.976],
                    [-2.453, 2.272],
                    [-0.503, 0.474],
                    [-0.36, 0.365]
                  ],
                  o: [
                    [0.041, 0.035],
                    [-0.302, 0.362],
                    [-0.482, 0.503],
                    [-2.374, 2.394],
                    [-4.177, 2.995],
                    [-1.468, 0.822],
                    [-0.844, 0.416],
                    [-0.026, -0.049],
                    [0.926, -0.569],
                    [2.845, -1.77],
                    [4.143, -2.988],
                    [0.592, -0.558],
                    [0.439, -0.444],
                    [0.663, -0.663]
                  ],
                  v: [
                    [14.779, -11.059],
                    [13.913, -9.876],
                    [12.804, -8.563],
                    [11.23, -6.921],
                    [1.15, 1.529],
                    [-9.923, 8.611],
                    [-13.439, 10.484],
                    [-14.794, 11.046],
                    [-13.587, 10.217],
                    [-10.204, 8.136],
                    [0.692, 0.895],
                    [10.846, -7.32],
                    [12.489, -8.868],
                    [13.687, -10.08]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.811999990426, 0.689999988032, 0.26699999641, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [68.989, 162.81],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 8',
          np: 4,
          cix: 2,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.02, -0.022],
                    [0.454, 0.473],
                    [0.748, 0.997],
                    [1.421, 4.237],
                    [-0.096, 5.899],
                    [-0.414, 3.161],
                    [-0.507, 3.344],
                    [-0.537, 5.82],
                    [0.706, 4.343],
                    [0.702, 1.59],
                    [0.809, 0.911],
                    [-0.064, 0.093],
                    [-0.573, -0.324],
                    [-0.378, -0.298],
                    [-0.205, -0.187],
                    [-0.223, -0.217],
                    [-0.736, -1.628],
                    [-0.389, -2.201],
                    [0.505, -5.848],
                    [1.018, -6.684],
                    [0.41, -3.156],
                    [0.075, -2.909],
                    [-1.303, -4.202],
                    [-1.377, -2.039],
                    [-0.424, -0.492]
                  ],
                  o: [
                    [-0.017, 0.019],
                    [-0.466, -0.462],
                    [-1.468, -2.008],
                    [-1.389, -4.239],
                    [0.056, -2.947],
                    [0.401, -3.169],
                    [1.02, -6.685],
                    [0.541, -5.812],
                    [-0.347, -2.169],
                    [-0.681, -1.605],
                    [-1.673, -1.799],
                    [0.014, -0.024],
                    [0.296, 0.147],
                    [0.188, 0.15],
                    [0.2, 0.196],
                    [0.864, 0.901],
                    [0.759, 1.613],
                    [0.788, 4.408],
                    [-0.5, 5.857],
                    [-0.509, 3.342],
                    [-0.425, 3.158],
                    [-0.134, 5.822],
                    [1.335, 4.199],
                    [0.701, 1.013],
                    [0.413, 0.503]
                  ],
                  v: [
                    [5.895, 46.446],
                    [5.146, 45.782],
                    [3.31, 43.58],
                    [-1.406, 34.174],
                    [-3.576, 18.723],
                    [-2.815, 9.528],
                    [-1.417, -0.253],
                    [1.128, -19.122],
                    [1.049, -34.568],
                    [-0.55, -40.247],
                    [-2.935, -43.985],
                    [-5.851, -46.442],
                    [-4.961, -45.984],
                    [-3.958, -45.299],
                    [-3.346, -44.816],
                    [-2.713, -44.195],
                    [-0.154, -40.429],
                    [1.594, -34.662],
                    [1.846, -19.058],
                    [-0.643, -0.136],
                    [-2.055, 9.624],
                    [-2.854, 18.739],
                    [-0.878, 34.005],
                    [3.56, 43.404],
                    [5.263, 45.675]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.294000004787, 0.289999988032, 0.289999988032, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [159.845, 69.647],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 9',
          np: 4,
          cix: 2,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.024, -0.012],
                    [0.243, -0.461],
                    [0.237, -0.301],
                    [0.354, -0.354],
                    [1.397, -0.339],
                    [1.758, -0.005],
                    [4.563, -0.673],
                    [1.182, -0.23],
                    [0.598, -0.143],
                    [0.573, -0.211],
                    [1.914, -1.806],
                    [1.974, -4.142],
                    [1.037, -3.338],
                    [0.454, -1.905],
                    [0.135, -0.575],
                    [0.026, 0.004],
                    [-0.069, 0.513],
                    [-0.182, 0.965],
                    [-0.959, 3.383],
                    [-1.95, 4.239],
                    [-3.971, 3.753],
                    [-2.465, 0.791],
                    [-0.607, 0.137],
                    [-0.596, 0.139],
                    [-1.155, 0.161],
                    [-3.491, -0.105],
                    [-1.342, 0.255],
                    [-0.727, 0.625],
                    [-0.258, 0.267],
                    [-0.152, 0.205]
                  ],
                  o: [
                    [0.023, 0.012],
                    [-0.133, 0.222],
                    [-0.245, 0.292],
                    [-0.727, 0.692],
                    [-1.396, 0.308],
                    [-3.512, -0.035],
                    [-1.139, 0.168],
                    [-0.586, 0.14],
                    [-0.6, 0.138],
                    [-2.337, 0.761],
                    [-3.844, 3.63],
                    [-1.964, 4.175],
                    [-1.021, 3.345],
                    [-0.209, 0.896],
                    [-0.118, 0.502],
                    [-0.027, -0.004],
                    [0.07, -0.512],
                    [0.364, -1.93],
                    [0.975, -3.376],
                    [1.962, -4.204],
                    [1.977, -1.864],
                    [0.614, -0.221],
                    [0.608, -0.142],
                    [1.2, -0.226],
                    [4.632, -0.643],
                    [1.746, 0.032],
                    [1.336, -0.285],
                    [0.353, -0.321],
                    [0.249, -0.277],
                    [0.282, -0.429]
                  ],
                  v: [
                    [25.493, -22.684],
                    [25.16, -21.965],
                    [24.674, -21.132],
                    [23.827, -20.112],
                    [20.624, -18.409],
                    [15.859, -18.042],
                    [3.619, -17.477],
                    [0.139, -16.854],
                    [-1.639, -16.43],
                    [-3.418, -15.94],
                    [-9.894, -12.003],
                    [-18.254, 0.267],
                    [-22.601, 11.732],
                    [-24.761, 19.733],
                    [-25.275, 21.931],
                    [-25.488, 22.692],
                    [-25.429, 21.902],
                    [-25.059, 19.67],
                    [-23.131, 11.575],
                    [-18.907, -0.038],
                    [-10.425, -12.578],
                    [-3.654, -16.67],
                    [-1.819, -17.166],
                    [-0.016, -17.586],
                    [3.518, -18.191],
                    [15.866, -18.596],
                    [20.53, -18.836],
                    [23.619, -20.335],
                    [24.488, -21.27],
                    [25.025, -22.046]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.004000000393, 0.004000000393, 0.004000000393, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [113.822, 54.431],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 10',
          np: 4,
          cix: 2,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.053, -0.009],
                    [1.085, 3.357],
                    [0.405, 0.955],
                    [0.486, 1.057],
                    [1.554, 2.19],
                    [1.641, 1.65],
                    [0.807, 0.65],
                    [0.706, 0.529],
                    [-0.071, 0.128],
                    [-0.893, -0.446],
                    [-0.599, -0.378],
                    [-0.718, -0.531],
                    [-0.806, -0.677],
                    [-0.875, -0.798],
                    [-1.606, -2.213],
                    [-1.019, -2.135],
                    [-0.338, -0.998],
                    [-0.246, -0.859],
                    [-0.159, -0.69],
                    [-0.061, -0.496]
                  ],
                  o: [
                    [-0.145, 0.024],
                    [-0.277, -0.838],
                    [-0.357, -0.972],
                    [-1.037, -2.082],
                    [-1.577, -2.173],
                    [-0.847, -0.797],
                    [-0.775, -0.689],
                    [-2.832, -2.102],
                    [0.024, -0.048],
                    [0.449, 0.217],
                    [0.604, 0.372],
                    [0.736, 0.508],
                    [0.836, 0.639],
                    [1.696, 1.648],
                    [1.582, 2.229],
                    [0.475, 1.084],
                    [0.384, 0.979],
                    [0.273, 0.851],
                    [0.166, 0.688],
                    [0.138, 0.988]
                  ],
                  v: [
                    [11.044, 15.388],
                    [9.477, 9.879],
                    [8.49, 7.172],
                    [7.16, 4.148],
                    [3.292, -2.344],
                    [-1.62, -8.089],
                    [-4.06, -10.316],
                    [-6.31, -12.116],
                    [-11.025, -15.364],
                    [-9.605, -14.743],
                    [-8.017, -13.864],
                    [-5.987, -12.566],
                    [-3.646, -10.815],
                    [-1.117, -8.605],
                    [3.927, -2.8],
                    [7.812, 3.837],
                    [9.096, 6.94],
                    [10.005, 9.717],
                    [10.586, 12.056],
                    [10.909, 13.844]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0.536999990426, 0.57599995931, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [59.879, 225.633],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 11',
          np: 4,
          cix: 2,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[14.752, 4.699], [0, 0], [-8.979, 0.553], [0, 0], [-0.922, -3.824]],
                  o: [[0, 0], [0, 0], [8.978, -0.554], [0, 0], [0.922, 3.823]],
                  v: [
                    [-18.983, 7.935],
                    [-18.423, -11.837],
                    [3.81, -11.443],
                    [18.983, -12.634],
                    [16.659, 4.736]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.365000017952, 0.677999997606, 0.702000038297, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [169.357, 332.459],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 12',
          np: 4,
          cix: 2,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.054, -0.036],
                    [0.301, 0.414],
                    [0.509, 0.828],
                    [1.452, 3.163],
                    [0.149, 10.668],
                    [-2.46, 6.477],
                    [-0.895, 1.723],
                    [-0.248, 0.446],
                    [-0.024, -0.012],
                    [0.806, -1.759],
                    [1.15, -3.256],
                    [-0.157, -10.545],
                    [-2.773, -6.295],
                    [-0.98, -1.667]
                  ],
                  o: [
                    [-0.022, 0.015],
                    [-0.291, -0.42],
                    [-1.064, -1.626],
                    [-2.927, -6.291],
                    [-0.159, -10.668],
                    [1.226, -3.256],
                    [0.43, -0.871],
                    [0.255, -0.443],
                    [0.055, 0.029],
                    [-0.808, 1.757],
                    [-2.311, 6.483],
                    [0.149, 10.545],
                    [1.375, 3.165],
                    [0.974, 1.669]
                  ],
                  v: [
                    [5.822, 36.435],
                    [5.33, 35.828],
                    [4.068, 33.98],
                    [0.143, 26.747],
                    [-5.717, 0.358],
                    [-1.012, -26.284],
                    [2.296, -33.815],
                    [3.361, -35.781],
                    [3.786, -36.439],
                    [2.57, -33.684],
                    [-0.492, -26.095],
                    [-4.934, 0.345],
                    [0.649, 26.522],
                    [4.329, 33.823]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.004000000393, 0.004000000393, 0.004000000393, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [63.599, 82.188],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 13',
          np: 4,
          cix: 2,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.061, -0.008],
                    [0.085, 0.49],
                    [0.106, 0.939],
                    [0.077, 3.386],
                    [-3.434, 9.81],
                    [-4.743, 4.82],
                    [-1.522, 1.126],
                    [-0.408, 0.284],
                    [-0.016, -0.023],
                    [1.452, -1.198],
                    [2.29, -2.457],
                    [3.386, -9.695],
                    [-0.024, -6.705],
                    [-0.152, -1.878]
                  ],
                  o: [
                    [-0.027, 0.003],
                    [-0.078, -0.492],
                    [-0.246, -1.875],
                    [-0.167, -6.744],
                    [3.425, -9.813],
                    [2.364, -2.433],
                    [0.743, -0.586],
                    [0.415, -0.276],
                    [0.038, 0.053],
                    [-1.455, 1.193],
                    [-4.597, 4.873],
                    [-3.394, 9.694],
                    [0.006, 3.365],
                    [0.15, 1.879]
                  ],
                  v: [
                    [-11.935, 33.206],
                    [-12.105, 32.464],
                    [-12.425, 30.31],
                    [-12.997, 22.319],
                    [-8.999, -3.736],
                    [4.7, -26.212],
                    [10.691, -31.541],
                    [12.47, -32.799],
                    [13.126, -33.186],
                    [10.88, -31.302],
                    [5.102, -25.832],
                    [-8.261, -3.477],
                    [-12.445, 22.313],
                    [-12.122, 30.279]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.004000000393, 0.004000000393, 0.004000000393, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [79.77, 65.527],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 14',
          np: 4,
          cix: 2,
          ix: 14,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.023, -0.013],
                    [0.237, 0.402],
                    [0.403, 0.717],
                    [0.892, 3.058],
                    [0.259, 4.202],
                    [-0.781, 4.788],
                    [-2.989, 5.577],
                    [-1.071, 1.415],
                    [-0.324, 0.338],
                    [-0.02, -0.018],
                    [0.988, -1.458],
                    [1.436, -2.801],
                    [1.524, -9.491],
                    [-0.231, -4.145],
                    [-0.833, -3.026],
                    [-0.668, -1.629],
                    [-0.23, -0.491]
                  ],
                  o: [
                    [-0.024, 0.013],
                    [-0.267, -0.475],
                    [-0.758, -1.598],
                    [-0.911, -3.041],
                    [-0.274, -4.197],
                    [1.542, -9.6],
                    [1.511, -2.791],
                    [0.54, -0.704],
                    [0.317, -0.344],
                    [0.05, 0.043],
                    [-0.987, 1.457],
                    [-2.842, 5.6],
                    [-0.772, 4.734],
                    [0.216, 4.149],
                    [0.812, 3.041],
                    [0.349, 0.74],
                    [0.191, 0.424]
                  ],
                  v: [
                    [-0.954, 32.898],
                    [-1.35, 32.305],
                    [-2.35, 30.524],
                    [-5.133, 23.544],
                    [-7.098, 12.57],
                    [-6.408, -1.063],
                    [1.511, -24.462],
                    [5.511, -30.818],
                    [6.809, -32.396],
                    [7.322, -32.893],
                    [5.759, -30.64],
                    [2.001, -24.206],
                    [-5.636, -0.939],
                    [-6.378, 12.525],
                    [-4.6, 23.39],
                    [-2.076, 30.393],
                    [-1.211, 32.231]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.004000000393, 0.004000000393, 0.004000000393, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [82.913, 80.574],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 15',
          np: 4,
          cix: 2,
          ix: 15,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-2.136, -6.139],
                    [-17.082, 4.538],
                    [-4.003, 8.007],
                    [36.301, -19.219],
                    [-26.425, -38.17],
                    [-19.553, -10.139],
                    [-16.015, 29.361]
                  ],
                  o: [
                    [0, 0],
                    [17.084, -4.537],
                    [4.004, -8.007],
                    [-36.301, 19.218],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-1.056, 11.077],
                    [21.631, -28.16],
                    [49.925, -34.298],
                    [-2.391, -46.577],
                    [-27.504, 58.612],
                    [4.803, 65.796],
                    [-8.797, 4.672]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.216000007181, 0.216000007181, 0.216000007181, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [89.389, 66.046],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 16',
          np: 4,
          cix: 2,
          ix: 16,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [11.611, 6.044], [0, 0], [0, 0]],
                  o: [[-2.01, -12.936], [0, 0], [0, 0], [0, 0]],
                  v: [[7.637, 15.376], [-14.432, -15.376], [11.145, -13.321], [14.432, 10.939]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [63.286, 225.645],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 17',
          np: 4,
          cix: 2,
          ix: 17,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-0.748, 0.075], [-0.117, -0.819], [0.761, -0.098], [0.103, 0.843]],
                  o: [[0.748, -0.074], [0.115, 0.821], [-0.761, 0.097], [-0.102, -0.842]],
                  v: [[-0.195, -1.517], [1.362, -0.161], [0.201, 1.494], [-1.375, 0.15]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.870999983245, 0.866999966491, 0.842999985639, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [34.035, 219.918],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 18',
          np: 4,
          cix: 2,
          ix: 18,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-0.645, -0.631], [0.499, 0.096], [0.332, 0.312]],
                  o: [[-0.426, -0.164], [-0.286, -0.397], [0.882, 0.328]],
                  v: [[4.568, -4.408], [3.18, -4.8], [2.252, -5.863]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [[0.654, -0.681], [-0.086, 0.483], [-0.312, 0.333]],
                  o: [[0.164, -0.427], [0.409, -0.303], [-0.307, 0.851]],
                  v: [[4.438, 3.771], [4.816, 2.405], [5.895, 1.447]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [[0.694, 0.677], [-0.511, -0.07], [-0.355, -0.306]],
                  o: [[0.444, 0.164], [0.311, 0.416], [-0.906, -0.27]],
                  v: [[-4.221, 4.117], [-2.788, 4.471], [-1.787, 5.551]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 3,
              ty: 'sh',
              ix: 4,
              ks: {
                a: 0,
                k: {
                  i: [[-0.7, 0.637], [0.101, -0.509], [0.329, -0.341]],
                  o: [[-0.186, 0.43], [-0.429, 0.285], [0.333, -0.91]],
                  v: [[-4.135, -4.759], [-4.57, -3.351], [-5.708, -2.41]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 4',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 4,
              ty: 'sh',
              ix: 5,
              ks: {
                a: 0,
                k: {
                  i: [[0.24, -0.005], [0.174, 0.281], [-0.379, -0.394]],
                  o: [[-0.156, -0.329], [0.419, 0.222], [-0.232, -0.01]],
                  v: [[1.019, -4.961], [0.524, -5.882], [1.729, -4.957]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 5',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 5,
              ty: 'sh',
              ix: 6,
              ks: {
                a: 0,
                k: {
                  i: [[-0.155, -0.329], [0.403, -0.046], [0.143, 0.369], [-0.355, -0.047]],
                  o: [[-0.407, 0.025], [-0.121, -0.37], [0.383, 0.005], [0.187, 0.291]],
                  v: [[3.059, -2.965], [1.835, -2.858], [1.441, -3.972], [2.546, -3.892]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 6',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 6,
              ty: 'sh',
              ix: 7,
              ks: {
                a: 0,
                k: {
                  i: [[-0.363, -0.269], [0.372, 0.003], [0.099, 0.195]],
                  o: [[-0.328, -0.037], [-0.087, -0.208], [0.524, 0.17]],
                  v: [[5.221, -2.938], [4.166, -2.997], [3.891, -3.604]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 7',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 7,
              ty: 'sh',
              ix: 8,
              ks: {
                a: 0,
                k: {
                  i: [[0.012, 0.224], [-0.28, 0.177], [0.399, -0.381]],
                  o: [[0.334, -0.165], [-0.212, 0.402], [0.004, -0.219]],
                  v: [[4.925, 0.351], [5.851, -0.163], [4.939, 1.018]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 8',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 8,
              ty: 'sh',
              ix: 9,
              ks: {
                a: 0,
                k: {
                  i: [[0.343, -0.172], [0.052, 0.386], [-0.378, 0.155], [0.042, -0.343]],
                  o: [[-0.03, -0.394], [0.383, -0.134], [0.002, 0.363], [-0.3, 0.197]],
                  v: [[2.891, 2.415], [2.766, 1.239], [3.915, 0.803], [3.854, 1.863]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 9',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 9,
              ty: 'sh',
              ix: 10,
              ks: {
                a: 0,
                k: {
                  i: [[0.002, 0.366], [-0.205, 0.11], [0.282, -0.382]],
                  o: [[0.219, -0.099], [-0.17, 0.521], [0.035, -0.329]],
                  v: [[2.938, 3.491], [3.576, 3.178], [2.89, 4.536]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 10',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 10,
              ty: 'sh',
              ix: 11,
              ks: {
                a: 0,
                k: {
                  i: [[-0.239, 0.025], [-0.189, -0.275], [0.401, 0.391]],
                  o: [[0.172, 0.332], [-0.428, -0.188], [0.234, -0.007]],
                  v: [[-0.61, 4.48], [-0.067, 5.396], [-1.321, 4.533]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 11',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 11,
              ty: 'sh',
              ix: 12,
              ks: {
                a: 0,
                k: {
                  i: [[0.169, 0.348], [-0.405, 0.063], [-0.159, -0.378], [0.362, 0.033]],
                  o: [[0.414, -0.039], [0.132, 0.384], [-0.383, 0.018], [-0.2, -0.301]],
                  v: [[-2.748, 2.476], [-1.514, 2.321], [-1.079, 3.472], [-2.195, 3.451]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 12',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 12,
              ty: 'sh',
              ix: 13,
              ks: {
                a: 0,
                k: {
                  i: [[0.384, 0.298], [-0.38, 0.005], [-0.108, -0.211]],
                  o: [[0.34, 0.04], [0.095, 0.227], [-0.541, -0.167]],
                  v: [[-4.96, 2.49], [-3.873, 2.539], [-3.57, 3.199]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 13',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 13,
              ty: 'sh',
              ix: 14,
              ks: {
                a: 0,
                k: {
                  i: [[-0.012, -0.246], [0.29, -0.182], [-0.415, 0.387]],
                  o: [[-0.345, 0.161], [0.217, -0.435], [-0.006, 0.237]],
                  v: [[-4.712, -1.14], [-5.67, -0.624], [-4.723, -1.867]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 14',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 14,
              ty: 'sh',
              ix: 15,
              ks: {
                a: 0,
                k: {
                  i: [[-0.355, 0.151], [-0.047, -0.404], [0.388, -0.145], [-0.051, 0.358]],
                  o: [[0.02, 0.41], [-0.392, 0.119], [0.001, -0.388], [0.311, -0.186]],
                  v: [[-2.594, -3.197], [-2.492, -1.97], [-3.67, -1.573], [-3.592, -2.692]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 15',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 15,
              ty: 'sh',
              ix: 16,
              ks: {
                a: 0,
                k: {
                  i: [[0.012, -0.374], [0.214, -0.093], [-0.306, 0.355]],
                  o: [[-0.226, 0.08], [0.192, -0.524], [-0.052, 0.329]],
                  v: [[-2.609, -4.306], [-3.273, -4.048], [-2.515, -5.367]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 16',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 16,
              ty: 'sh',
              ix: 17,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.407, -0.105],
                    [0.009, 0.391],
                    [-0.332, 0.063],
                    [-0.324, 0.029],
                    [-0.145, -0.445],
                    [0.344, -0.071]
                  ],
                  o: [
                    [-0.053, -0.476],
                    [0.313, -0.092],
                    [0.333, -0.063],
                    [0.148, 0.356],
                    [-0.414, 0.064],
                    [-0.345, 0.07]
                  ],
                  v: [
                    [-1.482, -2.252],
                    [-1.579, -3.563],
                    [-0.619, -3.806],
                    [0.364, -3.932],
                    [0.805, -2.72],
                    [-0.343, -2.516]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 17',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 17,
              ty: 'sh',
              ix: 18,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.076, 0.334],
                    [0.038, 0.328],
                    [-0.466, 0.15],
                    [-0.079, -0.34],
                    [-0.116, -0.402],
                    [0.401, -0.022]
                  ],
                  o: [
                    [-0.076, -0.333],
                    [0.371, -0.154],
                    [0.069, 0.413],
                    [0.078, 0.341],
                    [-0.483, 0.067],
                    [-0.107, -0.312]
                  ],
                  v: [
                    [-3.45, 0.506],
                    [-3.609, -0.484],
                    [-2.343, -0.941],
                    [-2.12, 0.202],
                    [-1.827, 1.329],
                    [-3.165, 1.467]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 18',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 18,
              ty: 'sh',
              ix: 19,
              ks: {
                a: 0,
                k: {
                  i: [[0.075, 0.165], [0, 0], [-0.917, 0.489], [-0.06, -0.258], [-0.075, -0.248]],
                  o: [[0, 0], [-0.006, -0.184], [0.04, 0.256], [0.058, 0.26], [-1.036, -0.04]],
                  v: [
                    [-5.937, 1.08],
                    [-5.937, 1.079],
                    [-4.592, -0.027],
                    [-4.469, 0.739],
                    [-4.247, 1.485]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 19',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 19,
              ty: 'sh',
              ix: 20,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.326, -0.088],
                    [0.321, -0.053],
                    [0.157, 0.452],
                    [-0.34, 0.086],
                    [-0.401, 0.123],
                    [-0.021, -0.386]
                  ],
                  o: [
                    [-0.325, 0.087],
                    [-0.161, -0.358],
                    [0.412, -0.083],
                    [0.34, -0.087],
                    [0.06, 0.465],
                    [-0.305, 0.115]
                  ],
                  v: [
                    [0.964, 3.161],
                    [-0.005, 3.361],
                    [-0.484, 2.136],
                    [0.656, 1.879],
                    [1.779, 1.562],
                    [1.904, 2.848]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 20',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 20,
              ty: 'sh',
              ix: 21,
              ks: {
                a: 0,
                k: {
                  i: [[0.154, -0.082], [0, 0], [0.5, 0.872], [-0.25, 0.07], [-0.238, 0.084]],
                  o: [[0, 0], [-0.175, 0.015], [0.247, -0.052], [0.25, -0.071], [-0.03, 1.004]],
                  v: [
                    [1.568, 5.554],
                    [1.567, 5.555],
                    [0.468, 4.301],
                    [1.208, 4.144],
                    [1.923, 3.894]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 21',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 21,
              ty: 'sh',
              ix: 22,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.095, -0.416],
                    [-0.068, -0.375],
                    [0.433, -0.105],
                    [0.389, -0.075],
                    [0.098, 0.427],
                    [0.064, 0.385],
                    [-0.435, 0.094],
                    [-0.389, 0.064]
                  ],
                  o: [
                    [0.095, 0.415],
                    [-0.379, 0.112],
                    [-0.431, 0.105],
                    [-0.109, -0.375],
                    [-0.097, -0.428],
                    [0.384, -0.103],
                    [0.434, -0.093],
                    [0.103, 0.367]
                  ],
                  v: [
                    [1.394, -0.602],
                    [1.628, 0.573],
                    [0.424, 0.9],
                    [-0.798, 1.161],
                    [-1.109, -0.029],
                    [-1.343, -1.238],
                    [-0.128, -1.535],
                    [1.097, -1.764]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 22',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 22,
              ty: 'sh',
              ix: 23,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.071, -0.309],
                    [-0.037, -0.304],
                    [0.457, -0.158],
                    [0.073, 0.325],
                    [0.108, 0.385],
                    [-0.394, 0.017]
                  ],
                  o: [
                    [0.07, 0.308],
                    [-0.363, 0.156],
                    [-0.069, -0.393],
                    [-0.074, -0.325],
                    [0.48, -0.058],
                    [0.099, 0.29]
                  ],
                  v: [
                    [3.705, -1.132],
                    [3.852, -0.214],
                    [2.614, 0.257],
                    [2.396, -0.832],
                    [2.122, -1.908],
                    [3.442, -2.023]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 23',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 23,
              ty: 'sh',
              ix: 24,
              ks: {
                a: 0,
                k: {
                  i: [[-0.07, -0.145], [0, 0], [0.894, -0.472], [0.053, 0.235], [0.067, 0.226]],
                  o: [[0, 0], [0.001, 0.163], [-0.036, -0.232], [-0.053, -0.235], [1.007, 0.037]],
                  v: [
                    [6.128, -1.689],
                    [6.128, -1.688],
                    [4.812, -0.664],
                    [4.702, -1.359],
                    [4.502, -2.035]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 24',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 24,
              ty: 'sh',
              ix: 25,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [-0.462, -0.89], [0.256, -0.046], [0.246, -0.061], [-0.16, 0.063]],
                  o: [[0.178, 0.006], [-0.251, 0.029], [-0.255, 0.046], [0.074, -1.001], [0, 0]],
                  v: [
                    [-1.115, -6.219],
                    [-0.072, -4.882],
                    [-0.825, -4.795],
                    [-1.561, -4.615],
                    [-1.118, -6.219]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 25',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 25,
              ty: 'sh',
              ix: 26,
              ks: {
                a: 0,
                k: {
                  i: [[-0.932, -4.131], [-3.733, 1.21], [0.776, 3.461], [3.91, -0.534]],
                  o: [[0.954, 4.125], [3.747, -1.22], [-0.792, -3.458], [-3.894, 0.536]],
                  v: [[-6.965, 1.312], [1.82, 6.544], [7.122, -1.912], [-1.309, -7.219]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 26',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.870999983245, 0.866999966491, 0.842999985639, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [50.286, 228.491],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 19',
          np: 30,
          cix: 2,
          ix: 19,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-11.894, 2.38], [-1.727, 0.416], [-0.011, -0.036], [-2.817, 0.396]],
                  o: [[-0.007, -0.019], [1.357, -1.579], [-2.702, 0.855], [12.13, -1.207]],
                  v: [[16.205, -18.316], [21.494, 5.081], [-12.682, 17.481], [-20.035, -14.633]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.136999990426, 0.277999997606, 0.372999991623, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [50.247, 230.64],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 20',
          np: 4,
          cix: 2,
          ix: 20,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-1.474, 1.398], [2.318, -0.583], [0.04, 0.126], [0.151, 11.198]],
                  o: [[1.622, -0.346], [-2.598, -0.305], [-3.44, -10.883], [0.008, 0.062]],
                  v: [[14.322, -18.131], [18.383, 5.951], [-15.792, 18.35], [-20.701, -14.577]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.642999985639, 0.125, 0.310000011968, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [53.359, 229.769],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 21',
          np: 4,
          cix: 2,
          ix: 21,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.509, 2.42],
                    [-6.478, 6.817],
                    [-2.919, 0.368],
                    [0, 0],
                    [6.139, -12.546],
                    [-7.206, -9.342],
                    [-6.846, 4.812]
                  ],
                  o: [
                    [-4.977, -7.98],
                    [2.079, -2.188],
                    [0, 0],
                    [0, 0],
                    [-6.14, 12.545],
                    [0, 0],
                    [-2.303, -1.656]
                  ],
                  v: [
                    [4.174, 10.145],
                    [6.587, -15.962],
                    [14.428, -20.229],
                    [14.119, -22.146],
                    [-8.288, -11.744],
                    [-2.95, 24.29],
                    [9.986, 16.282]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.811999990426, 0.689999988032, 0.26699999641, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [58.718, 148.39],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 22',
          np: 4,
          cix: 2,
          ix: 22,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [6.138, -12.546], [-7.206, -9.342], [-9.075, 10.676], [0, 0]],
                  o: [[0, 0], [-6.14, 12.545], [0, 0], [0, 0], [0, 0]],
                  v: [
                    [9.596, -22.146],
                    [-12.811, -11.744],
                    [-7.473, 24.29],
                    [18.951, 4.538],
                    [11.078, -12.924]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.834999952129, 0.051000000449, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [63.242, 148.39],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 23',
          np: 4,
          cix: 2,
          ix: 23,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-1.694, -1.503],
                    [0, 0],
                    [-3.199, 17.338],
                    [-0.927, 3.708],
                    [0, 0],
                    [0.267, 3.203],
                    [0, 0],
                    [0.266, 3.471],
                    [0, 0],
                    [-0.266, 3.737],
                    [4.004, -9.342],
                    [0, 0],
                    [13.346, -9.875],
                    [29.36, 31.497]
                  ],
                  o: [
                    [0, 0],
                    [36.394, 32.302],
                    [0, 0],
                    [0, 0],
                    [0.8, -3.203],
                    [0, 0],
                    [-0.266, -3.203],
                    [0, 0],
                    [-0.268, -3.469],
                    [0, 0],
                    [0.268, -3.737],
                    [-4.004, 9.343],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-69.326, 4.643],
                    [-64.681, 9.576],
                    [15.933, 41.861],
                    [45.211, -6.66],
                    [64.521, -9.046],
                    [62.654, -13.051],
                    [66.656, -19.456],
                    [62.919, -22.659],
                    [69.059, -30.667],
                    [49.84, -27.464],
                    [56.246, -38.141],
                    [38.096, -26.396],
                    [35.694, -7.177],
                    [8.468, 22.182],
                    [-51.855, -10.648]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.745000023935, 0.6, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [126.308, 161.44],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 24',
          np: 4,
          cix: 2,
          ix: 24,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, -1.032], [1.032, 0], [0, 1.032], [-1.031, 0]],
                  o: [[0, 1.032], [-1.031, 0], [0, -1.032], [1.032, 0]],
                  v: [[1.868, 0], [0, 1.868], [-1.868, 0], [0, -1.868]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.011999999776, 0.46699999641, 0.752999997606, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [18.667, 178.285],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 25',
          np: 4,
          cix: 2,
          ix: 25,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                  o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                  v: [
                    [0.134, -11.878],
                    [9.209, -7.341],
                    [1.468, 11.878],
                    [-3.603, 11.344],
                    [-9.209, -2.803]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.870999983245, 0.866999966491, 0.842999985639, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [23.071, 180.021],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 26',
          np: 4,
          cix: 2,
          ix: 26,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [18.951, -28.56], [0, 0], [0, 0], [-13.88, 18.951], [0, 0]],
                  o: [[0, 0], [-18.951, 28.561], [0, 0], [0, 0], [13.88, -18.951], [0, 0]],
                  v: [
                    [21.22, -43.241],
                    [17.75, 14.68],
                    [-36.701, 24.556],
                    [-31.896, 11.745],
                    [6.807, 5.872],
                    [10.01, -40.304]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.011999999776, 0.46699999641, 0.752999997606, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [60.973, 162.003],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 27',
          np: 4,
          cix: 2,
          ix: 27,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.339, 1.105],
                    [-1.432, 0.778],
                    [-1.627, 0.104],
                    [-9.213, 2.379],
                    [0, 0],
                    [2.529, 1.336],
                    [0.198, 3.612],
                    [-1.864, 2.778],
                    [4.488, 12.354],
                    [0, 0],
                    [0, 0],
                    [-16.426, 0.759],
                    [3.676, 2.416]
                  ],
                  o: [
                    [-0.477, -1.559],
                    [1.432, -0.78],
                    [9.496, -0.601],
                    [0, 0],
                    [-2.698, -0.954],
                    [-3.199, -1.691],
                    [-0.181, -3.341],
                    [5.941, -8.862],
                    [-1.839, -1.211],
                    [-49.875, 7.673],
                    [9.941, 9.766],
                    [-4.24, -1.166],
                    [-0.967, -0.635]
                  ],
                  v: [
                    [-0.386, 36.536],
                    [1.651, 32.509],
                    [6.4, 31.533],
                    [34.546, 27.037],
                    [34.034, 21.38],
                    [26.166, 17.973],
                    [19.386, 10.136],
                    [23.672, 1.287],
                    [24.107, -42.958],
                    [21.135, -45.151],
                    [-34.546, 32.21],
                    [13.908, 44.392],
                    [1.919, 39.03]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.834999952129, 0.051000000449, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [65.597, 168.184],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 28',
          np: 4,
          cix: 2,
          ix: 28,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-8.007, 0.267],
                    [-3.519, -7.519],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [15.214, 14.947],
                    [-49.875, 7.673]
                  ],
                  o: [
                    [0, 0],
                    [2.831, 6.049],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-8.275, 6.405],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [28.691, -39.481],
                    [40.173, -8.666],
                    [29.777, -0.135],
                    [25.312, 17.467],
                    [27.223, 38.57],
                    [24.154, 39.26],
                    [-43.004, 31.207],
                    [12.676, -46.154]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.811999990426, 0.689999988032, 0.26699999641, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [74.055, 169.187],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 29',
          np: 4,
          cix: 2,
          ix: 29,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.053, 0.012],
                    [-0.206, 1.165],
                    [-0.43, 2.07],
                    [-1.554, 6.259],
                    [-1.181, 4.058],
                    [-0.365, 1.125],
                    [-0.052, -0.015],
                    [0.273, -1.15],
                    [0.486, -1.949],
                    [1.565, -6.181],
                    [1.033, -4.231],
                    [0.316, -1.275]
                  ],
                  o: [
                    [-0.053, -0.011],
                    [0.204, -1.164],
                    [0.858, -4.138],
                    [1.558, -6.258],
                    [0.589, -2.029],
                    [0.363, -1.125],
                    [0.051, 0.015],
                    [-0.317, 1.275],
                    [-1.068, 4.222],
                    [-1.512, 6.193],
                    [-0.483, 1.95],
                    [-0.297, 1.144]
                  ],
                  v: [
                    [-5.631, 22.65],
                    [-5.389, 20.825],
                    [-4.427, 15.913],
                    [-0.734, -0.181],
                    [3.541, -16.131],
                    [4.991, -20.922],
                    [5.633, -22.647],
                    [5.284, -20.84],
                    [4.074, -15.986],
                    [0.026, 0.008],
                    [-3.888, 16.035],
                    [-5.092, 20.89]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.458999992819, 0.426999978458, 0.404000016755, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [189.886, 121.7],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 30',
          np: 4,
          cix: 2,
          ix: 30,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                  o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                  v: [
                    [-1.868, -22.555],
                    [-14.414, 21.754],
                    [-1.336, 24.957],
                    [14.414, -20.153],
                    [9.075, -24.957]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.277999997606, 0.243000000598, 0.20800000359, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [187.36, 121.032],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 31',
          np: 4,
          cix: 2,
          ix: 31,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0.148, -0.148], [1.588, 1.11], [-0.098, 0.199], [-1.642, -1.158]],
                  o: [[-0.154, 0.16], [-1.583, -1.117], [0.09, -0.19], [1.645, 1.151]],
                  v: [[2.885, 2.059], [-0.108, 0.134], [-2.934, -2.029], [0.341, -0.506]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.011999999776, 0.46699999641, 0.752999997606, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [51.261, 122.599],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 32',
          np: 4,
          cix: 2,
          ix: 32,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0.072, -0.21], [2.382, 1.14], [-0.115, 0.175], [-2.337, -1.11]],
                  o: [[-0.065, 0.2], [-2.385, -1.133], [0.118, -0.189], [2.334, 1.118]],
                  v: [[4.282, 2.026], [-0.253, 0.569], [-4.239, -2.038], [0.084, -0.137]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.011999999776, 0.46699999641, 0.752999997606, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [45.59, 128.282],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 33',
          np: 4,
          cix: 2,
          ix: 33,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0.109, -0.187], [1.542, 0.895], [-0.108, 0.187], [-1.542, -0.895]],
                  o: [[-0.108, 0.187], [-1.542, -0.895], [0.108, -0.187], [1.542, 0.895]],
                  v: [[2.791, 1.62], [-0.196, 0.339], [-2.792, -1.621], [0.196, -0.339]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.011999999776, 0.46699999641, 0.752999997606, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [39.648, 132.327],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 34',
          np: 4,
          cix: 2,
          ix: 34,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0.069, -0.208], [1.778, 0.77], [-0.102, 0.187], [-1.744, -0.751]],
                  o: [[-0.065, 0.202], [-1.779, -0.766], [0.104, -0.193], [1.741, 0.755]],
                  v: [[3.2, 1.37], [-0.19, 0.474], [-3.167, -1.38], [0.121, -0.243]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.011999999776, 0.46699999641, 0.752999997606, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [36.282, 138.76],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 35',
          np: 4,
          cix: 2,
          ix: 35,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0.104, -0.185], [2.108, 0.901], [-0.067, 0.211], [-2.146, -0.923]],
                  o: [[-0.107, 0.193], [-2.104, -0.906], [0.062, -0.202], [2.147, 0.919]],
                  v: [[3.825, 1.653], [-0.102, 0.197], [-3.862, -1.644], [0.207, -0.522]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.011999999776, 0.46699999641, 0.752999997606, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [33.2, 146.048],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 36',
          np: 4,
          cix: 2,
          ix: 36,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0.053, -0.209], [2.359, 0.693], [-0.069, 0.205], [-2.343, -0.688]],
                  o: [[-0.052, 0.21], [-2.36, -0.693], [0.07, -0.205], [2.342, 0.689]],
                  v: [[4.265, 1.248], [-0.102, 0.373], [-4.249, -1.253], [0.119, -0.378]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.011999999776, 0.46699999641, 0.752999997606, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [29.597, 151.754],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 37',
          np: 4,
          cix: 2,
          ix: 37,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.052, 0.016],
                    [-0.428, 1.407],
                    [-0.301, 0.968],
                    [-0.53, 1.206],
                    [-0.581, 1.425],
                    [-0.153, 0.369],
                    [-0.186, 0.368],
                    [-0.391, 0.777],
                    [-0.471, 0.782],
                    [-0.494, 0.809],
                    [-0.589, 0.784],
                    [-0.594, 0.813],
                    [-4.661, 2.449],
                    [-1.415, 0.414],
                    [-0.006, -0.053],
                    [1.382, -0.468],
                    [2.279, -1.269],
                    [4.718, -6.437],
                    [1.974, -4.835],
                    [0.366, -0.973],
                    [0.265, -0.722]
                  ],
                  o: [
                    [-0.052, -0.016],
                    [0.228, -0.736],
                    [0.329, -0.991],
                    [0.502, -1.23],
                    [0.148, -0.358],
                    [0.18, -0.359],
                    [0.372, -0.739],
                    [0.407, -0.771],
                    [0.475, -0.78],
                    [0.511, -0.799],
                    [0.58, -0.793],
                    [4.778, -6.522],
                    [2.345, -1.225],
                    [1.427, -0.384],
                    [0.007, 0.054],
                    [-1.372, 0.498],
                    [-4.526, 2.54],
                    [-4.777, 6.376],
                    [-0.56, 1.185],
                    [-0.348, 0.948],
                    [-0.515, 1.373]
                  ],
                  v: [
                    [-17.861, 21.238],
                    [-17.274, 19.032],
                    [-16.478, 16.475],
                    [-15.312, 13.122],
                    [-13.686, 9.135],
                    [-13.234, 8.044],
                    [-12.686, 6.954],
                    [-11.541, 4.679],
                    [-10.302, 2.307],
                    [-8.846, -0.079],
                    [-7.296, -2.523],
                    [-5.535, -4.932],
                    [9.862, -18.447],
                    [15.652, -20.794],
                    [17.906, -21.201],
                    [15.742, -20.503],
                    [10.123, -17.96],
                    [-4.912, -4.459],
                    [-14.798, 13.325],
                    [-16.065, 16.62],
                    [-16.985, 19.127]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.011999999776, 0.46699999641, 0.752999997606, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [45.337, 137.561],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 38',
          np: 4,
          cix: 2,
          ix: 38,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[2.478, -3.608], [1.367, -0.043], [0.511, 0], [-0.96, -0.748]],
                  o: [[-1.302, 0.043], [-0.555, 0.022], [3.822, -4.762], [0.748, 0.576]],
                  v: [[0.928, 3.833], [-3.086, 3.982], [-4.687, 4.025], [3.939, -3.277]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 1, 1, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [182.705, 368.619],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 39',
          np: 4,
          cix: 2,
          ix: 39,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[3.097, -4.058], [1.708, 0], [-0.598, 0.748], [-0.961, -0.747]],
                  o: [[-1.985, 0.043], [0.513, -0.725], [3.352, -4.227], [0.94, 0.684]],
                  v: [[0.352, 4.581], [-5.2, 4.644], [-3.555, 2.445], [4.26, -3.897]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 1, 1, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [174.569, 368.17],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 40',
          np: 4,
          cix: 2,
          ix: 40,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [23.916, -0.982],
                    [1.367, -0.043],
                    [0.511, 0],
                    [1.004, -0.022],
                    [1.708, 0],
                    [0, 0],
                    [0, 0],
                    [-5.274, 2.648],
                    [-2.926, -4.805]
                  ],
                  o: [
                    [-1.302, 0.043],
                    [-0.555, 0.022],
                    [-1.069, 0.042],
                    [-1.985, 0.043],
                    [-19.196, 0.022],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [3.545, 5.829]
                  ],
                  v: [
                    [-2.296, 6.139],
                    [-6.31, 6.288],
                    [-7.911, 6.331],
                    [-11.008, 6.438],
                    [-16.56, 6.501],
                    [-36.205, 5.754],
                    [-32.446, -4.708],
                    [-18.076, -4.708],
                    [32.66, -1.719]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.136999990426, 0.277999997606, 0.372999991623, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [185.929, 366.313],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 41',
          np: 4,
          cix: 2,
          ix: 41,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  v: [[-5.983, -15.277], [-9.148, 13.816], [4.076, 15.277], [9.148, -14.387]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.991999966491, 0.651000019148, 0.442999985639, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [162.36, 350.802],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 42',
          np: 4,
          cix: 2,
          ix: 42,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [5.796, -1.681],
                    [5.779, -2.962],
                    [15.038, 0.018],
                    [6.476, 8.126],
                    [1.023, 2.346],
                    [-0.275, 7.077],
                    [1.787, 0.854],
                    [-46.177, -10.411],
                    [-12.314, 6.73]
                  ],
                  o: [
                    [-6.236, 1.809],
                    [-13.382, 6.858],
                    [-10.39, -0.013],
                    [-1.595, -2.002],
                    [-2.829, -6.495],
                    [-1.926, -0.666],
                    [0, 0],
                    [38.746, 8.734],
                    [-4.951, -7.481]
                  ],
                  v: [
                    [46.039, -5.038],
                    [28.484, 3.314],
                    [-14.966, 13.771],
                    [-43.214, 3.346],
                    [-47.053, -3.279],
                    [-50.899, -23.992],
                    [-56.487, -26.255],
                    [-23.122, 17.521],
                    [69.299, -0.673]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.365000017952, 0.677999997606, 0.702000038297, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [91.703, 228.295],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 43',
          np: 4,
          cix: 2,
          ix: 43,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [24.022, 11.478],
                    [-46.177, -10.41],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [2.402, 10.142],
                    [0, 0]
                  ],
                  o: [[0, 0], [46.177, 10.411], [0, 0], [0, 0], [0, 0], [-2.403, -10.143], [0, 0]],
                  v: [
                    [-77.406, -59.656],
                    [-44.042, -15.882],
                    [52.049, -36.168],
                    [42.973, 63.927],
                    [64.862, 66.329],
                    [87.816, -56.186],
                    [-14.681, -54.585]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [112.622, 261.697],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 44',
          np: 4,
          cix: 2,
          ix: 44,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [-2.824, -3.452], [43.14, -19.681], [0, 0]],
                  o: [[0, 0], [2.824, 3.451], [-4.631, 6.02], [0, 0]],
                  v: [[-48.746, 5.706], [30.383, -20.619], [5.606, 18.051], [-46.264, 15.504]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.365000017952, 0.677999997606, 0.702000038297, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [144.285, 194.466],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 45',
          np: 4,
          cix: 2,
          ix: 45,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-17.599, -11.347],
                    [-8.799, 13.894],
                    [-16.209, 5.094],
                    [0, 0],
                    [0, 0],
                    [20.378, -12.505],
                    [8.799, 20.146]
                  ],
                  o: [[0, 0], [0, 0], [0, 0], [16.209, -5.095], [0, 0], [0, 0], [0, 0], [0, 0]],
                  v: [
                    [-32.302, -8.684],
                    [-11.926, 14.937],
                    [15.399, -8.452],
                    [32.072, -33.46],
                    [30.913, -21.419],
                    [26.746, 0.579],
                    [-11.694, 38.555],
                    [-48.28, 3.821]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.991999966491, 0.651000019148, 0.442999985639, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [140.049, 158.448],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 46',
          np: 4,
          cix: 2,
          ix: 46,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.207, -0.078],
                    [0.858, -1.161],
                    [0.897, -0.401],
                    [1.113, 0.234],
                    [0.633, 0.783],
                    [0.077, 0.719],
                    [-0.151, -0.085],
                    [-0.352, -1.102],
                    [-1.689, -0.329],
                    [-0.868, 0.781]
                  ],
                  o: [
                    [0.171, 0.043],
                    [-0.431, 0.563],
                    [-0.893, 0.411],
                    [-1.114, -0.237],
                    [-0.636, -0.776],
                    [-0.136, -1.483],
                    [0.212, 0.069],
                    [0.322, 1.099],
                    [1.675, 0.376],
                    [0.888, -0.78]
                  ],
                  v: [
                    [4.755, -1.014],
                    [4.054, 1.113],
                    [2.076, 2.679],
                    [-1.06, 3],
                    [-3.793, 1.38],
                    [-4.79, -0.981],
                    [-4.157, -3.149],
                    [-3.771, -1.183],
                    [-0.756, 1.564],
                    [3.289, 0.411]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.438999998803, 0.243000000598, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [138.217, 90.741],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 47',
          np: 4,
          cix: 2,
          ix: 47,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.211, -0.438],
                    [-1.091, 0.396],
                    [-1.54, -0.524],
                    [-0.918, -1.097],
                    [-0.315, 0.309],
                    [0.797, 2.014],
                    [2.173, 0.747],
                    [1.141, -1.33]
                  ],
                  o: [
                    [0.226, 0.494],
                    [1.086, -0.411],
                    [1.54, 0.53],
                    [0.927, 1.086],
                    [0.289, -0.267],
                    [-0.772, -1.985],
                    [-2.173, -0.74],
                    [-1.17, 1.341]
                  ],
                  v: [
                    [-5.929, 0.629],
                    [-3.866, 0.289],
                    [0.246, 0.094],
                    [4.013, 2.985],
                    [5.852, 4.659],
                    [5.281, 0.802],
                    [0.727, -4.228],
                    [-4.572, -2.569]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.216000007181, 0.216000007181, 0.216000007181, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [149.523, 62.729],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 48',
          np: 4,
          cix: 2,
          ix: 48,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.37, -0.438],
                    [1.912, 0.395],
                    [2.699, -0.524],
                    [1.609, -1.097],
                    [0.552, 0.31],
                    [-1.395, 2.015],
                    [-3.808, 0.747],
                    [-1.999, -1.331]
                  ],
                  o: [
                    [-0.395, 0.494],
                    [-1.904, -0.411],
                    [-2.698, 0.53],
                    [-1.623, 1.086],
                    [-0.507, -0.266],
                    [1.352, -1.985],
                    [3.807, -0.74],
                    [2.051, 1.341]
                  ],
                  v: [
                    [10.389, 0.628],
                    [6.775, 0.289],
                    [-0.428, 0.094],
                    [-7.03, 2.984],
                    [-10.251, 4.659],
                    [-9.251, 0.801],
                    [-1.272, -4.229],
                    [8.012, -2.569]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.216000007181, 0.216000007181, 0.216000007181, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [122.48, 59.599],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 49',
          np: 4,
          cix: 2,
          ix: 49,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [-4.27, -4.648], [2.67, 7.183]],
                  o: [[0, 0], [4.272, 4.648], [-2.669, -7.183]],
                  v: [[-1.201, -13.099], [-2.536, 8.451], [4.136, 3.802]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.987999949736, 0.569000004787, 0.305999995213, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [142.274, 74.628],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 50',
          np: 4,
          cix: 2,
          ix: 50,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, -1.916], [1.475, 0], [0, 1.917], [-1.474, 0]],
                  o: [[0, 1.917], [-1.474, 0], [0, -1.916], [1.475, 0]],
                  v: [[2.669, 0], [0, 3.47], [-2.669, 0], [0, -3.47]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [150.27, 75.531],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 51',
          np: 4,
          cix: 2,
          ix: 51,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, -1.917], [1.474, 0], [0, 1.917], [-1.475, 0]],
                  o: [[0, 1.917], [-1.475, 0], [0, -1.917], [1.474, 0]],
                  v: [[2.67, 0], [0.001, 3.47], [-2.67, 0], [0.001, -3.47]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [124.366, 73.386],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 52',
          np: 4,
          cix: 2,
          ix: 52,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-0.148, -0.146], [3.008, -3.683], [0.183, 0.128], [-3.039, 3.704]],
                  o: [[0.162, 0.155], [-3.018, 3.677], [-0.172, -0.117], [3.031, -3.71]],
                  v: [[5.474, -6.671], [0.078, 0.068], [-5.464, 6.689], [-0.528, -0.427]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.972999961703, 0.447000002394, 0.054999998504, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [79.961, 83.807],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 53',
          np: 4,
          cix: 2,
          ix: 53,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0.182, -0.117], [2.391, 3.712], [-0.182, 0.117], [-2.392, -3.711]],
                  o: [[-0.182, 0.117], [-2.392, -3.711], [0.182, -0.117], [2.392, 3.712]],
                  v: [[4.331, 6.72], [-0.329, 0.212], [-4.33, -6.72], [0.329, -0.212]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.972999961703, 0.447000002394, 0.054999998504, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [80.829, 83.843],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 54',
          np: 4,
          cix: 2,
          ix: 54,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.109, 2.167],
                    [-0.698, 5.776],
                    [2.637, 2.269],
                    [1.589, 3.093],
                    [-3.397, 4.517],
                    [-8.639, 3.56],
                    [-1.728, 3.026],
                    [0.07, 1.53],
                    [10.207, -5.016],
                    [-1.068, -15.749],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-3.572, -0.745],
                    [-0.835, 5.66]
                  ],
                  o: [
                    [2.651, -5.179],
                    [0.417, -3.454],
                    [-2.637, -2.268],
                    [-2.583, -5.028],
                    [5.616, -7.467],
                    [3.223, -1.328],
                    [0.787, -1.377],
                    [-9.774, -6.218],
                    [-15.748, 7.741],
                    [1.068, 15.748],
                    [2.136, 6.406],
                    [0, 0],
                    [2.307, 3.659],
                    [3.462, -4.553],
                    [0.354, -2.409]
                  ],
                  v: [
                    [1.617, 28.193],
                    [12.665, 15.164],
                    [7.992, 6.312],
                    [0.696, -1.038],
                    [3.735, -16.816],
                    [26.446, -32.658],
                    [34.933, -38.406],
                    [35.902, -42.818],
                    [1.129, -45.881],
                    [-34.904, 3.233],
                    [-22.092, 17.379],
                    [-11.415, 29.123],
                    [-15.686, 44.872],
                    [-6.617, 50.897],
                    [0.037, 35.284]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.991999966491, 0.651000019148, 0.442999985639, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [102.417, 78.162],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 55',
          np: 4,
          cix: 2,
          ix: 55,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-15.748, 7.741],
                    [-6.939, -12.011],
                    [13.88, -13.613],
                    [9.343, -21.62],
                    [3.734, 5.925],
                    [0, 0],
                    [2.136, 6.406],
                    [1.068, 15.748]
                  ],
                  o: [
                    [13.728, -6.747],
                    [6.94, 12.011],
                    [-13.879, 13.613],
                    [-6.233, -0.159],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.068, -15.748]
                  ],
                  v: [
                    [-9.475, -45.339],
                    [33.231, -34.395],
                    [32.697, 32.601],
                    [-10.277, 52.086],
                    [-26.29, 45.413],
                    [-22.02, 29.665],
                    [-32.697, 17.921],
                    [-45.51, 3.774]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.745000023935, 0.6, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [113.022, 77.62],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 56',
          np: 4,
          cix: 2,
          ix: 56,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [18.685, -33.631], [-17.696, -9.722], [-6.94, 15.482]],
                  o: [[0, 0], [-18.683, 33.632], [17.696, 9.721], [6.94, -15.481]],
                  v: [[26.905, -47.379], [-14.736, -28.961], [-23.889, 52.871], [34.646, 30.561]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.011999999776, 0.46699999641, 0.752999997606, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [60.388, 173.694],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 57',
          np: 4,
          cix: 2,
          ix: 57,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [17.884, -35.233], [-29.628, -11.211], [-10.41, 17.082]],
                  o: [[-16.549, -6.406], [-17.884, 35.233], [29.628, 11.21], [10.409, -17.083]],
                  v: [[37.235, -56.053], [-30.562, -29.094], [-25.757, 53.117], [44.976, 34.166]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.352999997606, 0.74900004069, 0.991999966491, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [55.635, 170.278],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 58',
          np: 4,
          cix: 2,
          ix: 58,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0.718, -2.241], [-1.418, 3.478]],
                  o: [[-11.761, -0.421], [-0.717, 2.24], [1.418, -3.477]],
                  v: [[1.691, -3.081], [-18.631, 1.078], [17.93, 0.025]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0.536999990426, 0.57599995931, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [169.588, 319.893],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 59',
          np: 4,
          cix: 2,
          ix: 59,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.209, 2.278],
                    [2.564, 1.758],
                    [2.371, 0.218],
                    [2.378, -0.076],
                    [-2.441, -0.611],
                    [-1.043, 1.159]
                  ],
                  o: [
                    [-0.282, -3.095],
                    [-1.965, -1.346],
                    [-2.37, -0.219],
                    [0.86, 3.509],
                    [0.489, 0.122],
                    [0.412, -2.258]
                  ],
                  v: [
                    [8.943, 0.787],
                    [4.766, -7.213],
                    [-2.021, -9.173],
                    [-9.152, -9.34],
                    [6.076, 9.294],
                    [8.451, 7.602]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.752999997606, 0.337000020345, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [68.706, 261.273],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 60',
          np: 4,
          cix: 2,
          ix: 60,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[1.434, -1.765], [-2.648, -0.662], [0.551, 2.206]],
                  o: [[-1.278, 1.574], [2.646, 0.662], [-0.552, -2.206]],
                  v: [[-14.747, -6.785], [0.475, 12.518], [15.475, -10.975]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.545000023935, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [74.308, 258.048],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 61',
          np: 4,
          cix: 2,
          ix: 61,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.118, -1.52],
                    [0.773, -0.827],
                    [-1.722, -0.434],
                    [-6.237, 4.216],
                    [1.362, 0.084]
                  ],
                  o: [
                    [-0.919, 0.66],
                    [-0.223, 5.744],
                    [2.353, 0.594],
                    [-1.23, -0.597],
                    [-2.602, -0.162]
                  ],
                  v: [
                    [-4.236, -7.997],
                    [-6.754, -5.736],
                    [-5.055, 9.884],
                    [6.978, -9.22],
                    [3.074, -10.316]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.752999997606, 0.337000020345, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [15.972, 261.589],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 62',
          np: 4,
          cix: 2,
          ix: 62,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-0.037, 0], [0.036, 0]],
                  o: [[0.036, 0], [-0.037, 0]],
                  v: [[0.001, 0.029], [0.001, -0.029]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 1, 1, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [10.588, 273.544],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 63',
          np: 4,
          cix: 2,
          ix: 63,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.228, 0.386],
                    [-0.178, 0.929],
                    [0.552, -1.535],
                    [-2.375, -0.599],
                    [-6.343, 4.049],
                    [-0.063, 0.495]
                  ],
                  o: [
                    [-0.479, -0.815],
                    [-4.169, -1.093],
                    [-0.538, 1.498],
                    [2.374, 0.599],
                    [0.419, -0.268],
                    [0.055, -0.446]
                  ],
                  v: [
                    [7.26, -9.144],
                    [7.712, -11.898],
                    [-6.738, -7.486],
                    [-5.417, 12.392],
                    [6.778, -6.836],
                    [7.738, -7.916]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.545000023935, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [16.333, 259.081],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 64',
          np: 4,
          cix: 2,
          ix: 64,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.801, 5.92],
                    [4.839, -4.973],
                    [0.148, -1.931],
                    [-5.378, -0.237],
                    [-0.759, 3.165]
                  ],
                  o: [
                    [-2.5, 2.067],
                    [-5.35, 5.498],
                    [-0.122, 1.587],
                    [2.239, -2.362],
                    [1.436, -5.997]
                  ],
                  v: [
                    [5.599, -13.393],
                    [-1.852, 3.589],
                    [-8.39, 9.384],
                    [2.456, 13.393],
                    [7.077, 4.818]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.752999997606, 0.337000020345, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [70.224, 371.194],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 65',
          np: 4,
          cix: 2,
          ix: 65,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.566, -0.297],
                    [5.349, -5.498],
                    [0.149, -1.932],
                    [-6.092, 0.892],
                    [0.148, 3.27]
                  ],
                  o: [
                    [-3.409, 0.284],
                    [-5.35, 5.498],
                    [-0.149, 1.932],
                    [6.093, -0.892],
                    [-0.15, -3.268]
                  ],
                  v: [
                    [0.818, -13.82],
                    [-7.504, 3.566],
                    [-14.043, 9.361],
                    [0.223, 13.225],
                    [14.043, 4.754]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.545000023935, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [75.875, 371.217],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 66',
          np: 4,
          cix: 2,
          ix: 66,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-3.412, 5.595],
                    [-0.81, -0.419],
                    [1.035, -4.909],
                    [0.305, -0.47],
                    [0.393, -0.261],
                    [0.786, 2.069]
                  ],
                  o: [
                    [2.955, -4.848],
                    [0.973, 0.503],
                    [-0.126, 0.597],
                    [-0.279, 0.428],
                    [-3.181, 2.126],
                    [-0.787, -2.068]
                  ],
                  v: [
                    [0.365, -1.229],
                    [4.857, -11.987],
                    [6.037, 5.061],
                    [5.459, 6.733],
                    [4.397, 7.71],
                    [-6.285, 10.338]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.545000023935, 0, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [177.643, 327.552],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 67',
          np: 4,
          cix: 2,
          ix: 67,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [5.098, 5.784],
                    [1.105, -0.573],
                    [-2.94, -4.747],
                    [-0.593, -0.417],
                    [-0.664, -0.189],
                    [-0.605, 2.229]
                  ],
                  o: [
                    [-5.097, -5.783],
                    [-1.326, 0.689],
                    [0.357, 0.577],
                    [0.54, 0.378],
                    [5.398, 1.543],
                    [0.605, -2.229]
                  ],
                  v: [
                    [-0.852, 0.578],
                    [-10.705, -12.484],
                    [-8.645, 7.826],
                    [-7.303, 9.401],
                    [-5.428, 10.185],
                    [11.426, 10.828]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.752999997606, 0.337000020345, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [23.499, 368.299],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 68',
          np: 4,
          cix: 2,
          ix: 68,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.808, -8.733],
                    [-2.016, -7.94],
                    [-7.511, -3.266],
                    [-6.799, 10.566],
                    [-2.731, 5.573],
                    [0, 0]
                  ],
                  o: [
                    [-2.804, 8.285],
                    [-0.756, 8.156],
                    [2.015, 7.939],
                    [11.524, 5.009],
                    [3.356, -5.218],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-22.337, -37.16],
                    [-27.2, -11.082],
                    [-26.456, 13.356],
                    [-11.978, 32.151],
                    [20.337, 19.681],
                    [28.471, 2.96],
                    [27.967, -36.625]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.102000000898, 0.349000010771, 0.501999978458, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [46.464, 288.8],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 69',
          np: 4,
          cix: 2,
          ix: 69,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [0, 0], [8.216, 3.286]],
                  o: [[0, 0], [0, 0], [0, 0], [-12.159, -42.392]],
                  v: [[-28.426, -64.903], [33.355, -64.246], [34.998, 64.903], [-22.839, 58.659]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.011999999776, 0.46699999641, 0.752999997606, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [41.076, 316.421],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 70',
          np: 4,
          cix: 2,
          ix: 70,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [8.215, 12.487],
                    [0, 0],
                    [0, 0],
                    [-0.157, 0.311],
                    [-6.843, 5.571],
                    [-7.856, -0.545],
                    [-4.427, -7.35],
                    [-1.137, -8.505],
                    [0.784, -10.879],
                    [1.95, -7.702],
                    [-12.215, 9.056]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0.153, -0.313],
                    [4.002, -7.863],
                    [6.107, -4.972],
                    [8.56, 0.594],
                    [4.428, 7.349],
                    [1.444, 10.811],
                    [-0.572, 7.927],
                    [14.951, -7.117],
                    [4.217, -26.548]
                  ],
                  v: [
                    [45.542, -68.106],
                    [-55.345, -36.23],
                    [-54.841, 3.355],
                    [-54.384, 2.415],
                    [-37.878, -18.032],
                    [-16.383, -26.396],
                    [4.115, -12.507],
                    [11.462, 11.969],
                    [12.643, 44.612],
                    [8.814, 68.105],
                    [51.128, 43.625]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.035000000748, 0.246999987434, 0.361000001197, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [129.776, 288.404],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 71',
          np: 4,
          cix: 2,
          ix: 71,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [4.217, -26.548], [0, 0], [0, 0]],
                  o: [[8.215, 12.487], [-38.12, 28.261], [0, 0], [0, 0]],
                  v: [[45.542, -80.512], [51.128, 31.219], [-53.702, 80.512], [-55.345, -48.636]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.128999986836, 0.395999983245, 0.57599995931, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [129.776, 300.811],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 72',
          np: 4,
          cix: 2,
          ix: 72,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [23.661, -0.986], [0, 0], [0, 0]],
                  o: [[0, 0], [-75.583, 16.103], [0, 0], [0, 0]],
                  v: [[81.334, -13.145], [39.599, -17.746], [-81.334, 18.074], [-19.553, 18.731]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0, 0.13300000359, 0.141000007181, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [93.984, 233.443],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 73',
          np: 4,
          cix: 2,
          ix: 73,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.112, -0.201],
                    [1.64, 0.12],
                    [0.822, 0.841],
                    [0.093, 1.374],
                    [-1.69, 2.765],
                    [0, 0],
                    [0, 0],
                    [8.389, -11.814],
                    [0.113, -4.22],
                    [-1.09, -0.37]
                  ],
                  o: [
                    [0.136, 0.092],
                    [-0.777, -0.079],
                    [-0.824, -0.856],
                    [-0.193, -2.778],
                    [1.706, -2.742],
                    [0, 0],
                    [0, 0],
                    [-4.031, 5.965],
                    [-0.139, 2.078],
                    [1.064, 0.404]
                  ],
                  v: [
                    [-7.224, 21.151],
                    [-9.337, 22.022],
                    [-11.892, 20.762],
                    [-13.306, 17.264],
                    [-10.577, 8.755],
                    [10.118, -22.142],
                    [13.499, -20.147],
                    [-2.421, 1.887],
                    [-11.23, 17.214],
                    [-9.103, 20.902]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.834999952129, 0.051000000449, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [143.795, 46.695],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 74',
          np: 4,
          cix: 2,
          ix: 74,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [-5.516, -1.779], [3.025, -9.075]],
                  o: [[0, 0], [5.517, 1.779], [0, 0]],
                  v: [[-8.541, -3.47], [1.246, -10.41], [5.517, 12.189]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.004000000393, 0.004000000393, 0.004000000393, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [143.14, 40.111],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 75',
          np: 4,
          cix: 2,
          ix: 75,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-10.143, -15.214],
                    [-13.879, -7.473],
                    [2.402, -22.688],
                    [0, 0],
                    [33.366, 13.345],
                    [-7.741, 17.083]
                  ],
                  o: [
                    [0, 0],
                    [13.88, 7.474],
                    [-2.402, 22.688],
                    [0, 0],
                    [-33.364, -13.347],
                    [7.74, -17.082]
                  ],
                  v: [
                    [22.155, -42.307],
                    [41.638, -53.251],
                    [44.575, 6.807],
                    [53.383, 39.637],
                    [-22.154, 47.378],
                    [-37.101, -27.359]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.216000007181, 0.216000007181, 0.216000007181, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [112.355, 76.456],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 76',
          np: 4,
          cix: 2,
          ix: 76,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 180.00000733155,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'avion Outlines',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.662,
                y: 0.828
              },
              o: {
                x: 0.333,
                y: 0
              },
              n: '0p662_0p828_0p333_0',
              t: 1,
              s: [-237.445, 346.64, 0],
              e: [532.903, 245.174, 0],
              to: [73.8637237548828, -7.65063571929932, 0],
              ti: [-116.931442260742, 13.5766334533691, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.164,
                y: 1
              },
              n: '0p833_0p833_0p164_1',
              t: 80,
              s: [532.903, 245.174, 0],
              e: [609.555, 222.64, 0],
              to: [5.98521709442139, -0.69492942094803, 0],
              ti: [-65.830078125, 3.53213024139404, 0]
            },
            {
              t: 179.000007290819
            }
          ],
          ix: 2
        },
        a: {
          a: 0,
          k: [161.31, 73.608, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [65, 65, 100],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [-28.174, 0.287], [1.49, 1.115]],
                  o: [[0, 0], [0, 0], [0, 0], [-1.03, -0.779]],
                  v: [[9.098, -2.552], [-14.754, -2.308], [14.754, 2.265], [11.803, -0.433]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [300.037, 89.022],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.301, -0.016],
                    [0.017, 1.517],
                    [0, 0],
                    [-1.3, 0.017],
                    [-0.013, -1.518]
                  ],
                  o: [
                    [0.018, 1.517],
                    [-1.3, 0.012],
                    [0, 0],
                    [-0.016, -1.517],
                    [1.3, -0.011],
                    [0, 0]
                  ],
                  v: [
                    [2.369, 1.57],
                    [0.045, 4.34],
                    [-2.34, 1.616],
                    [-2.371, -1.568],
                    [-0.044, -4.341],
                    [2.337, -1.615]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [100.757, 89.134],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 2',
          np: 4,
          cix: 2,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.301, -0.014],
                    [0.015, 1.519],
                    [0, 0],
                    [-1.302, 0.015],
                    [-0.017, -1.515]
                  ],
                  o: [
                    [0.015, 1.52],
                    [-1.3, 0.012],
                    [0, 0],
                    [-0.015, -1.518],
                    [1.301, -0.011],
                    [0, 0]
                  ],
                  v: [
                    [2.373, 1.567],
                    [0.045, 4.339],
                    [-2.34, 1.615],
                    [-2.373, -1.569],
                    [-0.044, -4.341],
                    [2.34, -1.618]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [111.031, 89.03],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 3',
          np: 4,
          cix: 2,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.302, -0.014],
                    [0.017, 1.519],
                    [0, 0],
                    [-1.299, 0.013],
                    [-0.016, -1.515]
                  ],
                  o: [
                    [0.014, 1.523],
                    [-1.298, 0.013],
                    [0, 0],
                    [-0.015, -1.516],
                    [1.301, -0.012],
                    [0, 0]
                  ],
                  v: [
                    [2.371, 1.564],
                    [0.043, 4.34],
                    [-2.337, 1.616],
                    [-2.37, -1.569],
                    [-0.046, -4.34],
                    [2.336, -1.617]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [121.307, 88.925],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 4',
          np: 4,
          cix: 2,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.305, -0.013],
                    [0.018, 1.52],
                    [0, 0],
                    [-1.299, 0.013],
                    [-0.014, -1.518]
                  ],
                  o: [
                    [0.015, 1.52],
                    [-1.297, 0.013],
                    [0, 0],
                    [-0.013, -1.516],
                    [1.305, -0.013],
                    [0, 0]
                  ],
                  v: [
                    [2.372, 1.567],
                    [0.042, 4.339],
                    [-2.34, 1.616],
                    [-2.374, -1.569],
                    [-0.047, -4.339],
                    [2.337, -1.615]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [131.585, 88.82],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 5',
          np: 4,
          cix: 2,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.303, -0.013],
                    [0.017, 1.517],
                    [0, 0],
                    [-1.3, 0.012],
                    [-0.018, -1.514]
                  ],
                  o: [
                    [0.015, 1.52],
                    [-1.299, 0.014],
                    [0, 0],
                    [-0.018, -1.514],
                    [1.305, -0.016],
                    [0, 0]
                  ],
                  v: [
                    [2.369, 1.568],
                    [0.043, 4.34],
                    [-2.335, 1.617],
                    [-2.366, -1.568],
                    [-0.046, -4.337],
                    [2.337, -1.617]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [141.858, 88.714],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 6',
          np: 4,
          cix: 2,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.301, -0.015],
                    [0.014, 1.521],
                    [0, 0],
                    [-1.3, 0.013],
                    [-0.017, -1.516]
                  ],
                  o: [
                    [0.013, 1.519],
                    [-1.3, 0.015],
                    [0, 0],
                    [-0.013, -1.518],
                    [1.298, -0.013],
                    [0, 0]
                  ],
                  v: [
                    [2.37, 1.567],
                    [0.041, 4.338],
                    [-2.335, 1.613],
                    [-2.37, -1.569],
                    [-0.046, -4.34],
                    [2.336, -1.617]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [152.136, 88.611],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 7',
          np: 4,
          cix: 2,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.301, -0.014],
                    [0.016, 1.517],
                    [0, 0],
                    [-1.304, 0.014],
                    [-0.018, -1.514]
                  ],
                  o: [
                    [0.015, 1.52],
                    [-1.301, 0.013],
                    [0, 0],
                    [-0.016, -1.517],
                    [1.297, -0.012],
                    [0, 0]
                  ],
                  v: [
                    [2.371, 1.567],
                    [0.044, 4.339],
                    [-2.337, 1.617],
                    [-2.37, -1.568],
                    [-0.042, -4.34],
                    [2.339, -1.618]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [162.41, 88.505],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 8',
          np: 4,
          cix: 2,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.301, -0.013],
                    [0.014, 1.519],
                    [0, 0],
                    [-1.304, 0.013],
                    [-0.016, -1.516]
                  ],
                  o: [
                    [0.014, 1.521],
                    [-1.304, 0.014],
                    [0, 0],
                    [-0.015, -1.513],
                    [1.304, -0.012],
                    [0, 0]
                  ],
                  v: [
                    [2.371, 1.566],
                    [0.043, 4.338],
                    [-2.337, 1.615],
                    [-2.37, -1.57],
                    [-0.047, -4.339],
                    [2.338, -1.617]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [172.687, 88.401],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 9',
          np: 4,
          cix: 2,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.299, -0.013],
                    [0.014, 1.519],
                    [0, 0],
                    [-1.307, 0.013],
                    [-0.016, -1.516]
                  ],
                  o: [
                    [0.017, 1.518],
                    [-1.309, 0.014],
                    [0, 0],
                    [-0.015, -1.518],
                    [1.299, -0.012],
                    [0, 0]
                  ],
                  v: [
                    [2.369, 1.568],
                    [0.048, 4.338],
                    [-2.339, 1.614],
                    [-2.371, -1.568],
                    [-0.041, -4.341],
                    [2.338, -1.617]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [182.961, 88.295],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 10',
          np: 4,
          cix: 2,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.301, -0.016],
                    [0.016, 1.521],
                    [0, 0],
                    [-1.299, 0.011],
                    [-0.015, -1.516]
                  ],
                  o: [
                    [0.018, 1.521],
                    [-1.3, 0.013],
                    [0, 0],
                    [-0.018, -1.515],
                    [1.3, -0.013],
                    [0, 0]
                  ],
                  v: [
                    [2.373, 1.566],
                    [0.045, 4.339],
                    [-2.342, 1.615],
                    [-2.373, -1.568],
                    [-0.042, -4.339],
                    [2.342, -1.617]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [193.234, 88.191],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 11',
          np: 4,
          cix: 2,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.298, -0.015],
                    [0.013, 1.522],
                    [0, 0],
                    [-1.304, 0.014],
                    [-0.016, -1.515]
                  ],
                  o: [
                    [0.016, 1.52],
                    [-1.307, 0.013],
                    [0, 0],
                    [-0.017, -1.518],
                    [1.298, -0.012],
                    [0, 0]
                  ],
                  v: [
                    [2.372, 1.567],
                    [0.051, 4.34],
                    [-2.338, 1.615],
                    [-2.37, -1.566],
                    [-0.038, -4.34],
                    [2.34, -1.618]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [203.509, 88.085],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 12',
          np: 4,
          cix: 2,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.303, -0.015],
                    [0.014, 1.52],
                    [0, 0],
                    [-1.301, 0.012],
                    [-0.012, -1.517]
                  ],
                  o: [
                    [0.018, 1.519],
                    [-1.302, 0.013],
                    [0, 0],
                    [-0.016, -1.513],
                    [1.306, -0.015],
                    [0, 0]
                  ],
                  v: [
                    [2.368, 1.567],
                    [0.043, 4.34],
                    [-2.337, 1.616],
                    [-2.37, -1.57],
                    [-0.047, -4.337],
                    [2.333, -1.616]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [213.789, 87.98],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 13',
          np: 4,
          cix: 2,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.304, -0.014],
                    [0.014, 1.518],
                    [0, 0],
                    [-1.3, 0.013],
                    [-0.015, -1.517]
                  ],
                  o: [
                    [0.018, 1.517],
                    [-1.3, 0.013],
                    [0, 0],
                    [-0.015, -1.518],
                    [1.303, -0.014],
                    [0, 0]
                  ],
                  v: [
                    [2.366, 1.57],
                    [0.042, 4.339],
                    [-2.335, 1.617],
                    [-2.369, -1.567],
                    [-0.048, -4.338],
                    [2.335, -1.615]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [224.063, 87.874],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 14',
          np: 4,
          cix: 2,
          ix: 14,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.305, -0.012],
                    [0.015, 1.521],
                    [0, 0],
                    [-1.301, 0.013],
                    [-0.017, -1.515]
                  ],
                  o: [
                    [0.015, 1.52],
                    [-1.3, 0.014],
                    [0, 0],
                    [-0.016, -1.515],
                    [1.306, -0.014],
                    [0, 0]
                  ],
                  v: [
                    [2.375, 1.568],
                    [0.041, 4.338],
                    [-2.341, 1.616],
                    [-2.374, -1.568],
                    [-0.047, -4.339],
                    [2.342, -1.617]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [234.34, 87.769],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 15',
          np: 4,
          cix: 2,
          ix: 15,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.3, -0.013],
                    [0.016, 1.517],
                    [0, 0],
                    [-1.3, 0.013],
                    [-0.017, -1.518]
                  ],
                  o: [
                    [0.017, 1.517],
                    [-1.3, 0.013],
                    [0, 0],
                    [-0.018, -1.518],
                    [1.303, -0.013],
                    [0, 0]
                  ],
                  v: [
                    [2.373, 1.569],
                    [0.046, 4.34],
                    [-2.34, 1.618],
                    [-2.372, -1.567],
                    [-0.044, -4.34],
                    [2.342, -1.616]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [244.611, 87.665],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 16',
          np: 4,
          cix: 2,
          ix: 16,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.304, -0.011],
                    [0.017, 1.519],
                    [0, 0],
                    [-1.298, 0.014],
                    [-0.016, -1.515]
                  ],
                  o: [
                    [0.017, 1.52],
                    [-1.3, 0.015],
                    [0, 0],
                    [-0.014, -1.516],
                    [1.305, -0.011],
                    [0, 0]
                  ],
                  v: [
                    [2.368, 1.566],
                    [0.04, 4.336],
                    [-2.339, 1.616],
                    [-2.372, -1.568],
                    [-0.05, -4.34],
                    [2.337, -1.617]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [254.893, 87.561],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 17',
          np: 4,
          cix: 2,
          ix: 17,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[27.756, -10.974], [0, 0], [0, 0], [0, 0]],
                  o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  v: [[-9.976, -17.15], [-53.309, 28.124], [-29.031, 27.877], [53.309, -17.797]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.830999995213, 0.830999995213, 0.830999995213, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [148.6, 118.767],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 18',
          np: 4,
          cix: 2,
          ix: 18,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  v: [[-115.967, -2.661], [106.415, -4.932], [115.967, 2.762], [-96.434, 4.932]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.011999999776, 0.46699999641, 0.752999997606, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [158.194, 101.146],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 19',
          np: 4,
          cix: 2,
          ix: 19,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  v: [[-9.558, -19.876], [-27.061, 20.252], [-13.528, 20.113], [27.061, -20.252]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.011999999776, 0.46699999641, 0.752999997606, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [28.521, 98.625],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 20',
          np: 4,
          cix: 2,
          ix: 20,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [37.816, -0.388],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-15.559, -3.099],
                    [-33.44, 0.341],
                    [0.11, 10.684]
                  ],
                  o: [
                    [-37.817, 0.386],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [15.557, 3.09],
                    [33.435, -0.34],
                    [0, 0]
                  ],
                  v: [
                    [102.316, -20.094],
                    [-66.051, -18.377],
                    [-109.544, -28.275],
                    [-110.137, -26.671],
                    [-156.046, -20.834],
                    [-155.913, -7.828],
                    [-67.602, 25.185],
                    [88.068, 26.844],
                    [155.936, 7.103]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.925, 0.933000033509, 0.933000033509, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [166.587, 93.628],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 21',
          np: 4,
          cix: 2,
          ix: 21,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  v: [[29.001, 30.247], [-11.891, -36.234], [-29.001, -36.058], [-11.546, 36.234]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.011999999776, 0.46699999641, 0.752999997606, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [29.25, 36.484],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 22',
          np: 2,
          cix: 2,
          ix: 22,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  v: [[-7.681, 7.961], [-29.335, -7.787], [-12.216, -7.961], [29.335, 7.583]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.830999995213, 0.830999995213, 0.830999995213, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [150.41, 67.658],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 23',
          np: 2,
          cix: 2,
          ix: 23,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 180.00000733155,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'avion 2 Outlines',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 7,
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.333,
                y: 0
              },
              n: '0p833_0p833_0p333_0',
              t: 98,
              s: [499.967, 131.569, 0],
              e: [-90.033, 49.569, 0],
              to: [-98.3333358764648, -13.6666669845581, 0],
              ti: [98.3333358764648, 13.6666669845581, 0]
            },
            {
              t: 179.000007290819
            }
          ],
          ix: 2
        },
        a: {
          a: 0,
          k: [161.31, 73.608, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [-30, 29.366, 100],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [-28.174, 0.287], [1.49, 1.115]],
                  o: [[0, 0], [0, 0], [0, 0], [-1.03, -0.779]],
                  v: [[9.098, -2.552], [-14.754, -2.308], [14.754, 2.265], [11.803, -0.433]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [300.037, 89.022],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.301, -0.016],
                    [0.017, 1.517],
                    [0, 0],
                    [-1.3, 0.017],
                    [-0.013, -1.518]
                  ],
                  o: [
                    [0.018, 1.517],
                    [-1.3, 0.012],
                    [0, 0],
                    [-0.016, -1.517],
                    [1.3, -0.011],
                    [0, 0]
                  ],
                  v: [
                    [2.369, 1.57],
                    [0.045, 4.34],
                    [-2.34, 1.616],
                    [-2.371, -1.568],
                    [-0.044, -4.341],
                    [2.337, -1.615]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [100.757, 89.134],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 2',
          np: 4,
          cix: 2,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.301, -0.014],
                    [0.015, 1.519],
                    [0, 0],
                    [-1.302, 0.015],
                    [-0.017, -1.515]
                  ],
                  o: [
                    [0.015, 1.52],
                    [-1.3, 0.012],
                    [0, 0],
                    [-0.015, -1.518],
                    [1.301, -0.011],
                    [0, 0]
                  ],
                  v: [
                    [2.373, 1.567],
                    [0.045, 4.339],
                    [-2.34, 1.615],
                    [-2.373, -1.569],
                    [-0.044, -4.341],
                    [2.34, -1.618]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [111.031, 89.03],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 3',
          np: 4,
          cix: 2,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.302, -0.014],
                    [0.017, 1.519],
                    [0, 0],
                    [-1.299, 0.013],
                    [-0.016, -1.515]
                  ],
                  o: [
                    [0.014, 1.523],
                    [-1.298, 0.013],
                    [0, 0],
                    [-0.015, -1.516],
                    [1.301, -0.012],
                    [0, 0]
                  ],
                  v: [
                    [2.371, 1.564],
                    [0.043, 4.34],
                    [-2.337, 1.616],
                    [-2.37, -1.569],
                    [-0.046, -4.34],
                    [2.336, -1.617]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [121.307, 88.925],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 4',
          np: 4,
          cix: 2,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.305, -0.013],
                    [0.018, 1.52],
                    [0, 0],
                    [-1.299, 0.013],
                    [-0.014, -1.518]
                  ],
                  o: [
                    [0.015, 1.52],
                    [-1.297, 0.013],
                    [0, 0],
                    [-0.013, -1.516],
                    [1.305, -0.013],
                    [0, 0]
                  ],
                  v: [
                    [2.372, 1.567],
                    [0.042, 4.339],
                    [-2.34, 1.616],
                    [-2.374, -1.569],
                    [-0.047, -4.339],
                    [2.337, -1.615]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [131.585, 88.82],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 5',
          np: 4,
          cix: 2,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.303, -0.013],
                    [0.017, 1.517],
                    [0, 0],
                    [-1.3, 0.012],
                    [-0.018, -1.514]
                  ],
                  o: [
                    [0.015, 1.52],
                    [-1.299, 0.014],
                    [0, 0],
                    [-0.018, -1.514],
                    [1.305, -0.016],
                    [0, 0]
                  ],
                  v: [
                    [2.369, 1.568],
                    [0.043, 4.34],
                    [-2.335, 1.617],
                    [-2.366, -1.568],
                    [-0.046, -4.337],
                    [2.337, -1.617]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [141.858, 88.714],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 6',
          np: 4,
          cix: 2,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.301, -0.015],
                    [0.014, 1.521],
                    [0, 0],
                    [-1.3, 0.013],
                    [-0.017, -1.516]
                  ],
                  o: [
                    [0.013, 1.519],
                    [-1.3, 0.015],
                    [0, 0],
                    [-0.013, -1.518],
                    [1.298, -0.013],
                    [0, 0]
                  ],
                  v: [
                    [2.37, 1.567],
                    [0.041, 4.338],
                    [-2.335, 1.613],
                    [-2.37, -1.569],
                    [-0.046, -4.34],
                    [2.336, -1.617]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [152.136, 88.611],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 7',
          np: 4,
          cix: 2,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.301, -0.014],
                    [0.016, 1.517],
                    [0, 0],
                    [-1.304, 0.014],
                    [-0.018, -1.514]
                  ],
                  o: [
                    [0.015, 1.52],
                    [-1.301, 0.013],
                    [0, 0],
                    [-0.016, -1.517],
                    [1.297, -0.012],
                    [0, 0]
                  ],
                  v: [
                    [2.371, 1.567],
                    [0.044, 4.339],
                    [-2.337, 1.617],
                    [-2.37, -1.568],
                    [-0.042, -4.34],
                    [2.339, -1.618]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [162.41, 88.505],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 8',
          np: 4,
          cix: 2,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.301, -0.013],
                    [0.014, 1.519],
                    [0, 0],
                    [-1.304, 0.013],
                    [-0.016, -1.516]
                  ],
                  o: [
                    [0.014, 1.521],
                    [-1.304, 0.014],
                    [0, 0],
                    [-0.015, -1.513],
                    [1.304, -0.012],
                    [0, 0]
                  ],
                  v: [
                    [2.371, 1.566],
                    [0.043, 4.338],
                    [-2.337, 1.615],
                    [-2.37, -1.57],
                    [-0.047, -4.339],
                    [2.338, -1.617]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [172.687, 88.401],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 9',
          np: 4,
          cix: 2,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.299, -0.013],
                    [0.014, 1.519],
                    [0, 0],
                    [-1.307, 0.013],
                    [-0.016, -1.516]
                  ],
                  o: [
                    [0.017, 1.518],
                    [-1.309, 0.014],
                    [0, 0],
                    [-0.015, -1.518],
                    [1.299, -0.012],
                    [0, 0]
                  ],
                  v: [
                    [2.369, 1.568],
                    [0.048, 4.338],
                    [-2.339, 1.614],
                    [-2.371, -1.568],
                    [-0.041, -4.341],
                    [2.338, -1.617]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [182.961, 88.295],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 10',
          np: 4,
          cix: 2,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.301, -0.016],
                    [0.016, 1.521],
                    [0, 0],
                    [-1.299, 0.011],
                    [-0.015, -1.516]
                  ],
                  o: [
                    [0.018, 1.521],
                    [-1.3, 0.013],
                    [0, 0],
                    [-0.018, -1.515],
                    [1.3, -0.013],
                    [0, 0]
                  ],
                  v: [
                    [2.373, 1.566],
                    [0.045, 4.339],
                    [-2.342, 1.615],
                    [-2.373, -1.568],
                    [-0.042, -4.339],
                    [2.342, -1.617]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [193.234, 88.191],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 11',
          np: 4,
          cix: 2,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.298, -0.015],
                    [0.013, 1.522],
                    [0, 0],
                    [-1.304, 0.014],
                    [-0.016, -1.515]
                  ],
                  o: [
                    [0.016, 1.52],
                    [-1.307, 0.013],
                    [0, 0],
                    [-0.017, -1.518],
                    [1.298, -0.012],
                    [0, 0]
                  ],
                  v: [
                    [2.372, 1.567],
                    [0.051, 4.34],
                    [-2.338, 1.615],
                    [-2.37, -1.566],
                    [-0.038, -4.34],
                    [2.34, -1.618]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [203.509, 88.085],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 12',
          np: 4,
          cix: 2,
          ix: 12,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.303, -0.015],
                    [0.014, 1.52],
                    [0, 0],
                    [-1.301, 0.012],
                    [-0.012, -1.517]
                  ],
                  o: [
                    [0.018, 1.519],
                    [-1.302, 0.013],
                    [0, 0],
                    [-0.016, -1.513],
                    [1.306, -0.015],
                    [0, 0]
                  ],
                  v: [
                    [2.368, 1.567],
                    [0.043, 4.34],
                    [-2.337, 1.616],
                    [-2.37, -1.57],
                    [-0.047, -4.337],
                    [2.333, -1.616]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [213.789, 87.98],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 13',
          np: 4,
          cix: 2,
          ix: 13,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.304, -0.014],
                    [0.014, 1.518],
                    [0, 0],
                    [-1.3, 0.013],
                    [-0.015, -1.517]
                  ],
                  o: [
                    [0.018, 1.517],
                    [-1.3, 0.013],
                    [0, 0],
                    [-0.015, -1.518],
                    [1.303, -0.014],
                    [0, 0]
                  ],
                  v: [
                    [2.366, 1.57],
                    [0.042, 4.339],
                    [-2.335, 1.617],
                    [-2.369, -1.567],
                    [-0.048, -4.338],
                    [2.335, -1.615]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [224.063, 87.874],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 14',
          np: 4,
          cix: 2,
          ix: 14,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.305, -0.012],
                    [0.015, 1.521],
                    [0, 0],
                    [-1.301, 0.013],
                    [-0.017, -1.515]
                  ],
                  o: [
                    [0.015, 1.52],
                    [-1.3, 0.014],
                    [0, 0],
                    [-0.016, -1.515],
                    [1.306, -0.014],
                    [0, 0]
                  ],
                  v: [
                    [2.375, 1.568],
                    [0.041, 4.338],
                    [-2.341, 1.616],
                    [-2.374, -1.568],
                    [-0.047, -4.339],
                    [2.342, -1.617]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [234.34, 87.769],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 15',
          np: 4,
          cix: 2,
          ix: 15,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.3, -0.013],
                    [0.016, 1.517],
                    [0, 0],
                    [-1.3, 0.013],
                    [-0.017, -1.518]
                  ],
                  o: [
                    [0.017, 1.517],
                    [-1.3, 0.013],
                    [0, 0],
                    [-0.018, -1.518],
                    [1.303, -0.013],
                    [0, 0]
                  ],
                  v: [
                    [2.373, 1.569],
                    [0.046, 4.34],
                    [-2.34, 1.618],
                    [-2.372, -1.567],
                    [-0.044, -4.34],
                    [2.342, -1.616]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [244.611, 87.665],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 16',
          np: 4,
          cix: 2,
          ix: 16,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.304, -0.011],
                    [0.017, 1.519],
                    [0, 0],
                    [-1.298, 0.014],
                    [-0.016, -1.515]
                  ],
                  o: [
                    [0.017, 1.52],
                    [-1.3, 0.015],
                    [0, 0],
                    [-0.014, -1.516],
                    [1.305, -0.011],
                    [0, 0]
                  ],
                  v: [
                    [2.368, 1.566],
                    [0.04, 4.336],
                    [-2.339, 1.616],
                    [-2.372, -1.568],
                    [-0.05, -4.34],
                    [2.337, -1.617]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.556999954523, 0.764999988032, 0.779999976065, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [254.893, 87.561],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 17',
          np: 4,
          cix: 2,
          ix: 17,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[27.756, -10.974], [0, 0], [0, 0], [0, 0]],
                  o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  v: [[-9.976, -17.15], [-53.309, 28.124], [-29.031, 27.877], [53.309, -17.797]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.830999995213, 0.830999995213, 0.830999995213, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [148.6, 118.767],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 18',
          np: 4,
          cix: 2,
          ix: 18,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  v: [[-115.967, -2.661], [106.415, -4.932], [115.967, 2.762], [-96.434, 4.932]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.011999999776, 0.46699999641, 0.752999997606, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [158.194, 101.146],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 19',
          np: 4,
          cix: 2,
          ix: 19,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  v: [[-9.558, -19.876], [-27.061, 20.252], [-13.528, 20.113], [27.061, -20.252]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.011999999776, 0.46699999641, 0.752999997606, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [28.521, 98.625],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 20',
          np: 4,
          cix: 2,
          ix: 20,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [37.816, -0.388],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-15.559, -3.099],
                    [-33.44, 0.341],
                    [0.11, 10.684]
                  ],
                  o: [
                    [-37.817, 0.386],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [15.557, 3.09],
                    [33.435, -0.34],
                    [0, 0]
                  ],
                  v: [
                    [102.316, -20.094],
                    [-66.051, -18.377],
                    [-109.544, -28.275],
                    [-110.137, -26.671],
                    [-156.046, -20.834],
                    [-155.913, -7.828],
                    [-67.602, 25.185],
                    [88.068, 26.844],
                    [155.936, 7.103]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.925, 0.933000033509, 0.933000033509, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [166.587, 93.628],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 21',
          np: 4,
          cix: 2,
          ix: 21,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  v: [[29.001, 30.247], [-11.891, -36.234], [-29.001, -36.058], [-11.546, 36.234]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.011999999776, 0.46699999641, 0.752999997606, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [29.25, 36.484],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 22',
          np: 2,
          cix: 2,
          ix: 22,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  o: [[0, 0], [0, 0], [0, 0], [0, 0]],
                  v: [[-7.681, 7.961], [-29.335, -7.787], [-12.216, -7.961], [29.335, 7.583]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.830999995213, 0.830999995213, 0.830999995213, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [150.41, 67.658],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 23',
          np: 2,
          cix: 2,
          ix: 23,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 180.00000733155,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: 'nube 3 Outlines',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 0,
          k: [323.374, 174.277, 0],
          ix: 2
        },
        a: {
          a: 0,
          k: [68.942, 43.323, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [-58.135, 60, 100],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-10.246, 3.504],
                    [0, 0.873],
                    [-18.032, 0],
                    [-5.679, -9.627],
                    [-2.383, 0],
                    [-3.168, -10.809],
                    [-0.359, 0],
                    [0, -14.382],
                    [14.384, 0],
                    [0, 0],
                    [0, 14.384]
                  ],
                  o: [
                    [-0.067, -0.855],
                    [0, -18.032],
                    [11.985, 0],
                    [2.191, -0.599],
                    [11.833, 0],
                    [0.356, -0.013],
                    [14.384, 0],
                    [0, 14.384],
                    [0, 0],
                    [-14.383, 0],
                    [0, -11.432]
                  ],
                  v: [
                    [-50.98, -7.716],
                    [-51.112, -10.301],
                    [-18.461, -42.949],
                    [9.651, -26.845],
                    [16.52, -27.79],
                    [41.497, -9.081],
                    [42.56, -9.134],
                    [68.602, 16.908],
                    [42.56, 42.949],
                    [-42.56, 42.949],
                    [-68.602, 16.908]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.948999980852, 0.948999980852, 0.948999980852, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [68.852, 43.2],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 180.00000733155,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 17,
      ty: 4,
      nm: 'nube 2 Outlines',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 0,
          k: [113.374, 100.277, 0],
          ix: 2
        },
        a: {
          a: 0,
          k: [68.942, 43.323, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [30, 30, 100],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-10.246, 3.504],
                    [0, 0.873],
                    [-18.032, 0],
                    [-5.679, -9.627],
                    [-2.383, 0],
                    [-3.168, -10.809],
                    [-0.359, 0],
                    [0, -14.382],
                    [14.384, 0],
                    [0, 0],
                    [0, 14.384]
                  ],
                  o: [
                    [-0.067, -0.855],
                    [0, -18.032],
                    [11.985, 0],
                    [2.191, -0.599],
                    [11.833, 0],
                    [0.356, -0.013],
                    [14.384, 0],
                    [0, 14.384],
                    [0, 0],
                    [-14.383, 0],
                    [0, -11.432]
                  ],
                  v: [
                    [-50.98, -7.716],
                    [-51.112, -10.301],
                    [-18.461, -42.949],
                    [9.651, -26.845],
                    [16.52, -27.79],
                    [41.497, -9.081],
                    [42.56, -9.134],
                    [68.602, 16.908],
                    [42.56, 42.949],
                    [-42.56, 42.949],
                    [-68.602, 16.908]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.948999980852, 0.948999980852, 0.948999980852, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [68.852, 43.2],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 180.00000733155,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 19,
      ty: 4,
      nm: 'nube Outlines',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 0,
          k: [47.374, 192.277, 0],
          ix: 2
        },
        a: {
          a: 0,
          k: [68.942, 43.323, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-10.246, 3.504],
                    [0, 0.873],
                    [-18.032, 0],
                    [-5.679, -9.627],
                    [-2.383, 0],
                    [-3.168, -10.809],
                    [-0.359, 0],
                    [0, -14.382],
                    [14.384, 0],
                    [0, 0],
                    [0, 14.384]
                  ],
                  o: [
                    [-0.067, -0.855],
                    [0, -18.032],
                    [11.985, 0],
                    [2.191, -0.599],
                    [11.833, 0],
                    [0.356, -0.013],
                    [14.384, 0],
                    [0, 14.384],
                    [0, 0],
                    [-14.383, 0],
                    [0, -11.432]
                  ],
                  v: [
                    [-50.98, -7.716],
                    [-51.112, -10.301],
                    [-18.461, -42.949],
                    [9.651, -26.845],
                    [16.52, -27.79],
                    [41.497, -9.081],
                    [42.56, -9.134],
                    [68.602, 16.908],
                    [42.56, 42.949],
                    [-42.56, 42.949],
                    [-68.602, 16.908]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.948999980852, 0.948999980852, 0.948999980852, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [68.852, 43.2],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 180.00000733155,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 21,
      ty: 4,
      nm: 'shadow/waiting_ships Outlines',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 0,
          k: [87, 529, 0],
          ix: 2
        },
        a: {
          a: 0,
          k: [0, 21, 0],
          ix: 1
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833]
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167]
              },
              n: ['0p833_0p833_0p167_0p167', '0p833_0p833_0p167_0p167', '0p833_0p833_0p167_0p167'],
              t: 0,
              s: [100, 100, 100],
              e: [109, 100, 100]
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833]
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167]
              },
              n: ['0p833_0p833_0p167_0p167', '0p833_0p833_0p167_0p167', '0p833_0p833_0p167_0p167'],
              t: 22,
              s: [109, 100, 100],
              e: [100, 100, 100]
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833]
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167]
              },
              n: ['0p833_0p833_0p167_0p167', '0p833_0p833_0p167_0p167', '0p833_0p833_0p167_0p167'],
              t: 45,
              s: [100, 100, 100],
              e: [109, 100, 100]
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833]
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167]
              },
              n: ['0p833_0p833_0p167_0p167', '0p833_0p833_0p167_0p167', '0p833_0p833_0p167_0p167'],
              t: 68,
              s: [109, 100, 100],
              e: [100, 100, 100]
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833]
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167]
              },
              n: ['0p833_0p833_0p167_0p167', '0p833_0p833_0p167_0p167', '0p833_0p833_0p167_0p167'],
              t: 90,
              s: [100, 100, 100],
              e: [109, 100, 100]
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833]
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167]
              },
              n: ['0p833_0p833_0p167_0p167', '0p833_0p833_0p167_0p167', '0p833_0p833_0p167_0p167'],
              t: 113,
              s: [109, 100, 100],
              e: [100, 100, 100]
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833]
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167]
              },
              n: ['0p833_0p833_0p167_0p167', '0p833_0p833_0p167_0p167', '0p833_0p833_0p167_0p167'],
              t: 136,
              s: [100, 100, 100],
              e: [109, 100, 100]
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [0.833, 0.833, 0.833]
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0.167]
              },
              n: ['0p833_0p833_0p167_0p167', '0p833_0p833_0p167_0p167', '0p833_0p833_0p167_0p167'],
              t: 159,
              s: [109, 100, 100],
              e: [100, 100, 100]
            },
            {
              t: 179.000007290819
            }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, -10.817], [74.004, 0], [0, 10.817], [-74.003, 0]],
                  o: [[0, 10.817], [-74.003, 0], [0, -10.817], [74.004, 0]],
                  v: [[133.994, 0], [-0.001, 19.586], [-133.995, 0], [-0.001, -19.586]],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.944999964097, 0.944999964097, 0.944999964097, 1],
                ix: 4
              },
              o: {
                a: 0,
                k: 100,
                ix: 5
              },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [134.245, 19.836],
                ix: 2
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3
              },
              r: {
                a: 0,
                k: 0,
                ix: 6
              },
              o: {
                a: 0,
                k: 100,
                ix: 7
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5
              },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 180.00000733155,
      st: 0,
      bm: 0
    }
  ],
  markers: []
}
