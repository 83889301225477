import { checkPropTypes } from 'prop-types'

export const getMasksArray = (maskDigit, digitCountArray, interleave, interleaveChar) =>
  digitCountArray.map(maskLength => generateMask(maskDigit, maskLength, interleave, interleaveChar))

const generateMask = (maskDigit = '9', maskDigitLength, interleave = 0, interleaveChar = '  ') => {
  let mask = ''
  const potentialOffset = interleave && maskDigitLength / interleave
  const realOffset = interleave
    ? Number.isInteger(potentialOffset)
      ? Math.floor(potentialOffset) - 1
      : Math.floor(potentialOffset)
    : 0

  for (let i = 0; i < maskDigitLength + realOffset; i++) {
    if (interleave && (i + 1) % (interleave + 1) === 0) {
      mask += interleaveChar
    } else {
      mask += maskDigit
    }
  }
  return mask
}

export const getMask = (rawValue, digitCountArray, masksArray) => {
  const newLength = rawValue.length
  const newIndex = digitCountArray.findIndex(item => item >= newLength)
  return newIndex !== -1 ? masksArray[newIndex] : masksArray[0]
}

export const isMasked = (value, maskParam) =>
  Array.isArray(maskParam)
    ? maskParam.some(item => item.length === value.length)
    : maskParam.length === value.length

export const parseMask = (maskedValue, maskChar, maskInterleaveChar) => {
  const maskRegex = new RegExp(maskChar, 'g')
  let parsedValue = maskedValue.replace(maskRegex, '')
  if (maskInterleaveChar) {
    const maskInterleaveRegex = new RegExp(maskInterleaveChar, 'g')
    parsedValue = parsedValue.replace(maskInterleaveRegex, '')
  }
  return parsedValue
}

export const doesMaskExist = (rawValueLength, digitCountArray) =>
  digitCountArray.findIndex(item => item >= rawValueLength) !== -1

export const getMaskIndex = (rawValueLength, digitCountArray) =>
  digitCountArray.findIndex(item => item >= rawValueLength)

export const getMaskConditionalPropType = (props, propName, componentName, propTypeValidation) => {
  if (props.isMasked && !props[propName]) {
    return new Error(
      `Required prop '${propName}' when 'isMasked' boolean prop is true @ ${componentName}. ` +
        `Validation failed`
    )
  }
  return props.isMasked
    ? checkPropTypes(propTypeValidation, props, 'prop', componentName)
    : undefined
}
