import { LANGUAGE_TAGS, SUPPORTED_LNGS } from 'localization/constants/languages'
import NAMESPACES from 'localization/constants/namespaces'
// eslint-disable-next-line import/no-cycle
import { getAllNamespacesToLoadOnInitForEnv } from 'server/utils/namespaces'

export const backendConnectorCommon = {
  // parse data after it has been fetched
  parse: function(data) {
    return data.translations
  },
  crossDomain: true,
  overrideMimeType: true
}

export const commonOptions = {
  // Logging Options
  debug: false,
  // Languages, namespaces, resources Options
  //Important to assert translations are loaded before rendering views.
  preload: [LANGUAGE_TAGS.DEFAULT],
  fallbackLng: LANGUAGE_TAGS.DEFAULT,
  supportedLngs: SUPPORTED_LNGS,
  nonExplicitSupportedLngs: false,
  /*load: 'currentOnly' allows to load only selected language, otherwise it would bring base language and
  default too. E.g.: for pt-BR as selected language, would also try to load /pt and /es-AR */
  load: 'currentOnly',
  ns: getAllNamespacesToLoadOnInitForEnv(),
  defaultNS: NAMESPACES.COMMON,
  fallbackNS: NAMESPACES.COMMON,
  saveMissing: false,
  // Translation defaults Options
  interpolation: {
    formatSeparator: ',',
    format: (value, format) => {
      if (format === 'uppercase') return value.toUpperCase()
      if (format === 'lowercase') return value.toLowerCase()
      return value
    }
  },
  // Miscellaneous Options
  keySeparator: false,
  pluralSeparator: '.',
  contextSeparator: '.',
  // Plugins Options: Language detection
  detection: {
    order: ['path'],
    lookupFromPathIndex: 0,
    ignoreCase: true
  }
}
