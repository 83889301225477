import { createSelector } from 'reselect'

const getLoginState = state => state.login

export const getLogin = createSelector([getLoginState], login => login)

export const getLoginIsFetching = createSelector(
  [getLoginState],
  login => login && login.isFetching
)
