import { createSelector } from 'reselect'

import CONFIG from 'config'
import { isEmpty } from 'helpers/utils'

const getDarkSite = state => state.darkSite

export const getDarkSiteData = createSelector([getDarkSite], darkSite => {
  if (darkSite && darkSite.data) {
    const darkSiteInfo = Object.values(darkSite.data)
    const darkSiteEnabled = darkSiteInfo.find(({ darkSiteEnabled }) => darkSiteEnabled)

    if (darkSiteEnabled) {
      const mappedInfo = {
        // buttonUrl should be generated where it's needed using i18n.language.
        title: darkSiteEnabled.title,
        description: darkSiteEnabled.darkSiteDescription,
        buttonName: 'general.dark-site.button.press-notice',
        enabled: darkSiteEnabled.darkSiteEnabled,
        notice: darkSiteEnabled.darkSiteNotice,
        englishNotice: darkSiteEnabled.darkSiteNoticeEnglish,
        ribbon: CONFIG.CONTENT_CDN_BASE_URL + darkSiteEnabled.darkSiteImage,
        ribbonEnabled: darkSiteEnabled.darkSiteRibbonEnabled
      }
      return [mappedInfo]
    }
  }

  return []
})

export const isEnabledDarkSite = createSelector([getDarkSite], darkSite => {
  if (darkSite && darkSite.data) {
    const foundDarkSite = darkSite.data.find(({ darkSiteEnabled }) => darkSiteEnabled)
    return (foundDarkSite && foundDarkSite.darkSiteEnabled) || false
  }
  return false
})

export const getDarkSiteRibbon = createSelector([getDarkSiteData], data => {
  if (!isEmpty(data)) {
    const darkSite = Object.values(data)[0]
    return darkSite && darkSite.ribbonEnabled ? darkSite.ribbon : null
  }
})

export const getDarkSiteNotice = createSelector([getDarkSiteData], data => {
  if (!isEmpty(data)) {
    const darkSite = Object.values(data)[0]
    return darkSite ? { notice: darkSite.notice, englishNotice: darkSite.englishNotice } : null
  }
})

export const getDarkSiteField = createSelector([getDarkSiteData], data => {
  if (!isEmpty(data)) {
    return { ...Object.values(data)[0], showTitle: true }
  }
})
