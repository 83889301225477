import styled from 'styled-components'

import HybridLink from 'components/hybrid-link'
import mediaQueries from 'components/media-queries'
import e2eClasses from 'helpers/automation'

import { headerHeightDesktop, headerHeightMobile } from '../styled'

export const StyledHybridLink = styled(HybridLink).attrs(() => ({
  className: e2eClasses('common.logo')
}))`
  display: flex;
  flex: ${({ flex }) => flex};
  outline: none;

  ${mediaQueries.tablet`
    ${({ session }) => session && `margin-left: 222.656px;`};
  `};

  ${mediaQueries.mobile`
    ${({ session }) => session && ` margin-left: 105.484px;`};
  `};

  > img {
    max-width: 100%;
  }

  &:active {
    outline: none;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  ${({ noPadding }) => !noPadding && 'padding-right: 80px;'};
  width: 100%;
  outline: none;
  ${headerHeightDesktop}

  ${mediaQueries.tablet`
    ${({ noPadding }) => !noPadding && 'padding: 0 120px 0 115px;'};
  `}
  ${mediaQueries.mobile`
   justify-content: center;
   ${({ noPadding }) => !noPadding && 'padding: 0 20px 0;'};
    ${headerHeightMobile}
    > ${StyledHybridLink}:nth-of-type(2) {
      margin-left: 5px;
      }
  `}
`
