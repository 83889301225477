import e2eClasses from 'constants/e2eClasses'

const e2eClass = path => {
  try {
    const id = path.split('.').reduce((id, path) => id[path], e2eClasses)
    return id
  } catch (e) {
    return undefined
  }
}

export default e2eClass
