import styled, { css } from 'styled-components'

import Icon from 'components/icons'
import mediaQueries from 'components/media-queries'
import e2eClasses from 'helpers/automation'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1160px;
  margin: 20px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.regular};
`

export const StyledLabel = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;

  color: ${({ theme }) => theme.colors.blue50};
`

export const LabelText = styled.span.attrs(() => ({
  className: e2eClasses('home.navbar.itemLanguageTitle')
}))``

export const ColumnsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
`

export const IconWithMargin = styled(Icon)`
  margin-right: 10px;
`

export const StyledImage = styled.img.attrs(() => ({
  className: e2eClasses('home.navbar.itemLanguageImage')
}))`
  width: 25px;
  height: 16px;
  margin-right: 6px;
`

export const someStyle = css`
  display: flex;
  width: 25%;
  padding: 10px 0;

  ${mediaQueries.tablet`
    width: 50%;
  `}
  ${mediaQueries.mobile`
    width: 100%;
  `};

  line-height: 1.25;
  color: ${({ theme }) => theme.colors.doveGray};
  text-decoration: none;
  align-items: center;

  &:hover {
    font-family: ${({ theme }) => theme.fonts.semiBold};
  }

  ${({ selected, theme }) => selected && `font-family: ${theme.fonts.semiBold};`};

  ${mediaQueries.tablet`
    margin-bottom: 5px;
  `}
`

export const StyledAnchor = styled.a.attrs(() => ({
  className: e2eClasses('home.navbar.itemLanguageOption')
}))`
  ${someStyle}
`
