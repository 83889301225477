/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import { parseDataSlider } from 'api-data-io/cms/slider'
import cmsApiRoutes from 'constants/cmsApiRoutes'
import { logError } from 'helpers/log'
import { parseUrlsInResponse } from 'helpers/url/cms'
import { isEmpty } from 'helpers/utils'
import { cmsApi, ssrCmsApi } from 'services/api'

const { fetchSlidersStart, fetchSlidersSuccess, fetchSlidersError } = createActions({
  FETCH_SLIDERS_START: () => {},
  FETCH_SLIDERS_SUCCESS: data => ({ data }),
  FETCH_SLIDERS_ERROR: error => ({ error })
})

const checkIsActiveSliderContent = (sliders, prevSliders = null) => {
  return async dispatch => {
    const newSliders = {
      activeIds: [],
      data: []
    }

    ;(parseDataSlider(sliders) || [])
      .filter(
        slider =>
          newSliders.data.push({
            ...slider,
            mainSliderReference: (slider.mainSliderReference || []).filter(
              sliderReference => sliderReference.showSlider
            )
          }) &&
          slider.showSlider &&
          !isEmpty(slider.mainSliderReference)
      )
      .forEach(slider => newSliders.activeIds.push(slider.nid))
    ;(JSON.stringify((prevSliders.activeIds || []).sort()) !==
      JSON.stringify((newSliders.activeIds || []).sort()) ||
      (prevSliders?.data?.mainSliderReference || []).length !==
        (newSliders?.data || []).reduce(
          (ac, val) => (val?.mainSliderReference || []).length + ac,
          0
        )) &&
      dispatch(fetchSlidersSuccess({ data: newSliders.data }))
  }
}

const fetchSliders = () => {
  return async dispatch => {
    dispatch(fetchSlidersStart())

    try {
      const sliders = await cmsApi.get(cmsApiRoutes.SLIDERS)
      dispatch(fetchSlidersSuccess(parseUrlsInResponse(sliders)))
    } catch (error) {
      dispatch(fetchSlidersError(error))
    }
  }
}

const fetchSlidersForServer = async i18n => {
  try {
    const { data } = await ssrCmsApi.get(cmsApiRoutes.SLIDERS, { i18n })

    return {
      data: parseDataSlider(parseUrlsInResponse(data)),
      error: null,
      isFetching: false
    }
  } catch (error) {
    error.fileName = 'slider.js'
    error.lineNumber = '62'
    logError(`Error when getting ${ssrCmsApi.defaults.baseURL + cmsApiRoutes.SLIDERS}`, '', error)
    return {
      error,
      data: null,
      isFetching: false
    }
  }
}

export {
  fetchSlidersStart,
  fetchSlidersSuccess,
  fetchSlidersError,
  fetchSliders,
  fetchSlidersForServer,
  checkIsActiveSliderContent
}
