import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { apiSublos as api } from 'services/api'

const {
  documentTypesSublosStart,
  documentTypesSublosSuccess,
  documentTypesSublosError,
  documentTypesSublosClear
} = createActions({
  DOCUMENT_TYPES_SUBLOS_START: () => {},
  DOCUMENT_TYPES_SUBLOS_SUCCESS: data => ({ data }),
  DOCUMENT_TYPES_SUBLOS_ERROR: error => ({ error }),
  DOCUMENT_TYPES_SUBLOS_CLEAR: () => {}
})

const fetchDocumentTypesSublos = () => async dispatch => {
  dispatch(documentTypesSublosStart())
  try {
    const response = await api.get(apiRoutes.SUBLOS_DOCUMENT_TYPES)
    dispatch(documentTypesSublosSuccess(response.data))
  } catch (error) {
    dispatch(documentTypesSublosError(error.response ? error.response.data : error))
  }
}

export {
  fetchDocumentTypesSublos,
  documentTypesSublosStart,
  documentTypesSublosSuccess,
  documentTypesSublosError,
  documentTypesSublosClear
}
