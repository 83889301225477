import { createActions } from 'redux-actions'

const {
  setIdentificationCodesStart,
  setIdentificationCodesSuccess,
  setIdentificationCodesError
} = createActions({
  SET_IDENTIFICATION_CODES_START: () => {},
  SET_IDENTIFICATION_CODES_SUCCESS: data => ({ data }),
  SET_IDENTIFICATION_CODES_ERROR: error => ({ error })
})

const setUserIdentificationCodes = identificationCodes => async dispatch => {
  dispatch(setIdentificationCodesStart())
  try {
    dispatch(setIdentificationCodesSuccess(identificationCodes))
  } catch (error) {
    dispatch(setIdentificationCodesError(error.response ? error.response.data : error))
  }
}

export {
  setIdentificationCodesStart,
  setIdentificationCodesSuccess,
  setIdentificationCodesError,
  setUserIdentificationCodes
}
