import { handleActions } from 'redux-actions'

import {
  sublosBenefitTypesSuccess,
  sublosBenefitTypesError,
  sublosBenefitTypesStart,
  sublosBenefitTypesClear
} from 'actions/sublos/benefitTypes'
import { TRAVELER } from 'components/sublos/main/steps/userData/Form/helper'

const defaultState = {
  data: {
    [TRAVELER.FAMILY]: null,
    [TRAVELER.NO_NAME]: null
  },
  error: null,
  isFetching: false
}

const sublosBenefitTypes = handleActions(
  {
    [sublosBenefitTypesStart]: state => ({
      ...state,
      error: null,
      isFetching: true
    }),
    [sublosBenefitTypesSuccess]: (state, { payload: { data } }) => {
      const newData = {
        ...state.data,
        [data.type]: data.benefitTypes
      }
      return {
        data: newData,
        error: null,
        isFetching: false
      }
    },
    [sublosBenefitTypesError]: (state, { payload: { error } }) => ({
      ...state,
      error,
      isFetching: false
    }),
    [sublosBenefitTypesClear]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default sublosBenefitTypes
