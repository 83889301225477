/* eslint-disable import/no-cycle */
import React, { Component, Fragment } from 'react'

import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { clearMilesLogin } from 'actions/milesLogin'
import { changeModalState } from 'actions/modalActions'
import HeaderMenu from 'components/header-menu'
import {
  shouldRenderEmptyDiv,
  getSelectedLang,
  setDeactivateTranslations,
  showComponentHelpCenterByLanguage
} from 'components/header/helpers'
import Icon, { IconNames } from 'components/icons'
import LanguageSelector from 'components/language-selector'
import LoginForm from 'components/login-form'
import StepBar from 'components/step-bar'
import theme from 'components/theme'
import CONFIG from 'config'
import {
  DEFAULT_LANG_ARPLUS_CULTURES,
  ENABLED_ARPLUS_REDIRECTION,
  ENGLISH_LANG_ARPLUS_CULTURES,
  SHOULD_SHOW_MODAL
} from 'constants/arplus/headerRedirectLanguages'
import COMPONENTS from 'constants/headerComponents'
import MODAL_NAMES from 'constants/modalNames'
import { shouldNotRenderLogin } from 'helpers/arplus'
import e2eClasses from 'helpers/automation'
import { isUserLogged, LogOut } from 'helpers/flightOffers'
import { isHomeRoute, isRouteWithDarkSiteStyle, isCheckoutRoute } from 'helpers/routeMatchers'
import {
  generateArPlusDashboardRoute,
  generateArPlusLoginRoute,
  generateFAQRoute
} from 'helpers/url/localization'
import { isMobileOrTablet, isMobile, isEmpty } from 'helpers/utils'
import { LANGUAGE_TAGS } from 'localization/constants/languages'
import i18n from 'localization/i18n'
import { getDarkSiteRibbon } from 'selectors/cms/darkSite'
import { getHeader } from 'selectors/cms/header'
import { mapMediaType } from 'selectors/mediaType'
import { getMembershipNumber } from 'selectors/milesLogin'
import { isMobileFullViewsVisible } from 'selectors/mobileFullViews'

import LoginItemMobile from './login-item'
import HeaderLogo from './logo'
import LoginModal from './modal-login'
import {
  MainContainer,
  Divider,
  MenuItem,
  MenuText,
  HelpMenu,
  LoginMenu,
  LanguageMenu,
  MenuPanel,
  FlagImage,
  FixedContainer,
  LoginText,
  LoginLabel,
  PointerCursor,
  LabelText,
  Row,
  MenuDisplayer,
  ComponentsContainer,
  ItemsContainer,
  RibbonImage,
  SelectedLanguage,
  Column,
  BoldText,
  LoginMenuDesktopContainer,
  LoginOptions,
  Options,
  MenuItemLogin,
  SkipContent
} from './styled'

const LoginPanel = () => (
  <MenuPanel>
    <LoginForm />
  </MenuPanel>
)

const LanguagePanel = props => (
  <MenuPanel id={props.id}>
    <LanguageSelector {...props} />
  </MenuPanel>
)

const menu = {
  language: 'language',
  session: 'session'
}

const MEMBERSHIPNUMBERS = CONFIG.ARPLUS_DISABLE_TRANSLATIONS_MEMBER_CODE
export var SHOW_ARSA_KEYS_IS_ACTIVATED = false

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: null,
      open: typeof window !== 'undefined' && !isMobileOrTablet(this.props.mediaType),
      showKeys: false,
      showHelpCenter: true
    }
  }

  componentDidMount() {
    this.setShowHelpCenterByLanguage()
  }

  componentDidUpdate(prevProps, prevState) {
    const { mediaType } = this.props

    this.checkUpdateShowHelpCenter(prevProps)

    this.activateTranslationsOnCloseSession(prevProps)
    this.checkUpdateShowHelpCenter(prevProps)

    this.updateIsArsaKey(prevProps)

    if (!this.state.open && !isMobileOrTablet(mediaType)) {
      this.setState({ open: true })
    }

    if (
      this.state.open &&
      !isMobileOrTablet(prevProps.mediaType) &&
      isMobileOrTablet(this.props.mediaType)
    ) {
      this.setState({ open: false })
    }

    if (!prevState.open && this.state.open && isMobileOrTablet(mediaType)) {
      document.body.style.overflow = 'hidden'
    }

    if (prevState.open && !this.state.open && isMobileOrTablet(mediaType)) {
      document.body.style.overflow = ''
    }
  }

  setShowHelpCenterByLanguage = () => {
    this.setState({
      showHelpCenter: showComponentHelpCenterByLanguage(this.props.i18n.language)
    })
  }

  checkUpdateShowHelpCenter = prevProps => {
    const { i18n: prevI18n } = prevProps
    const { i18n } = this.props

    prevI18n.language !== i18n.language && this.setShowHelpCenterByLanguage()
  }

  updateIsArsaKey = prevProps => {
    const { membershipNumber: prevMembershipNumber } = prevProps
    const { membershipNumber } = this.props

    prevMembershipNumber !== membershipNumber &&
      window.root.style.setProperty('--is-arsa-keys', this.isArsaUserKey())
  }

  goToFAQPage = () => {
    this.props.history.push(generateFAQRoute(this.props.i18n.language))
    this.closeAllSections()
  }

  isActiveComponent = component => (this.props.components || []).find(item => item === component)

  switchVisibility = (item, allowed) => {
    if (!allowed) {
      return
    }

    this.setState(prevState => ({
      open: prevState.expanded === item ? false : item,
      expanded: prevState.expanded === item ? true : item
    }))
  }

  closeAllSections = () => this.setState({ expanded: false, open: false })

  activateTranslationsOnCloseSession = prevProps =>
    prevProps.membershipNumber !== this.props.membershipNumber &&
    !this.props.membershipNumber &&
    setDeactivateTranslations(false)

  toggleDeactivateTranslations = () => {
    const result = !this.state.showKeys
    this.setState({ showKeys: result })
    SHOW_ARSA_KEYS_IS_ACTIVATED = result
    window.root.style.setProperty('--show-arsa-keys-is-activated', String(result))
    return result
  }

  renderMenuPanel = (sections, title) => {
    return (
      <MenuPanel>
        <MenuItem noDesktop>
          <MenuText>{title}</MenuText>
        </MenuItem>
        <HeaderMenu columns={sections} />
      </MenuPanel>
    )
  }

  handleLoginClick = () => {
    const isMilesEnabled = CONFIG.ENABLE_MILES

    if (isMilesEnabled) {
      if (
        (i18n.language === LANGUAGE_TAGS.BRAZIL || i18n.language === LANGUAGE_TAGS.CHILE) &&
        !isUserLogged()
      ) {
        this.props.changeModalState({
          isVisible: true,
          modalName: MODAL_NAMES.ARPLUS_LOGIN_DISCLAIMER
        })
      } else {
        this.props.history.push(generateArPlusLoginRoute(this.props.i18n.language))
      }
    } else {
      window.open(this.props.t('ar-plus.url'))
    }
  }

  isArsaUserKey = () => MEMBERSHIPNUMBERS.includes(this.props.membershipNumber)

  handleOption1Click = () => {
    this.props.history.push(generateArPlusDashboardRoute(this.props.i18n.language))
  }

  handleOption2Click = () => {
    this.props.history.push(generateArPlusLoginRoute(this.props.i18n.language))
  }

  handleOption3Click = () => {
    setDeactivateTranslations(this.toggleDeactivateTranslations())
  }

  handleOption4Click = () => {
    LogOut(this.props.clearMilesLogin)
    this.props.history.push(generateArPlusLoginRoute(this.props.i18n.language))
  }

  getLoginMenuMobile = () => {
    const option1 = {
      label: this.props.t('general.header.arplus.title.label'),
      onClick: this.handleOption1Click
    }
    const option2 = {
      label: this.props.t('general.header.arplus.title.arplus-program'),
      onClick: this.handleOption2Click
    }
    const option3 = this.isArsaUserKey()
      ? {
          label: this.props.t('general.header.arplus.title.arplus-disactivate-keys'),
          onClick: this.handleOption3Click
        }
      : null
    const option4 = {
      label: this.props.t('general.header.arplus.link.logout'),
      onClick: this.handleOption4Click
    }

    return (
      <>
        <LoginItemMobile
          onClick={this.handleLoginClick}
          options={[option1, option2, option3, option4]}
          ribbonImg={this.getRibbonImage()}
          isUserLoggedIn={isUserLogged() && !isEmpty(this.props.milesLogin)}
        />
        <LoginModal />
      </>
    )
  }

  getLoginMenuDesktop = () => {
    let linkProps = { pathTo: this.props.t('ar-plus.url') ?? '', target: '_blank' }

    if (CONFIG.ENABLE_MILES) {
      if (DEFAULT_LANG_ARPLUS_CULTURES.includes(i18n.language)) {
        linkProps.pathTo = generateArPlusLoginRoute(LANGUAGE_TAGS.DEFAULT) ?? ''
        linkProps.target = '_self'
      } else if (ENGLISH_LANG_ARPLUS_CULTURES.includes(i18n.language)) {
        linkProps.pathTo = generateArPlusLoginRoute(LANGUAGE_TAGS.ENGLISH) ?? ''
        linkProps.target = '_self'
      }
    }

    return (
      <LoginMenuDesktopContainer>
        <LoginModal />
        <MenuItemLogin {...linkProps}>
          <LoginMenu
            opened={this.state.expanded === menu.session}
            onClick={() => {
              if (CONFIG.ENABLE_MILES) {
                if (ENABLED_ARPLUS_REDIRECTION.includes(i18n.language))
                  return this.props.history.push(linkProps.pathTo)
                if (SHOULD_SHOW_MODAL.includes(i18n.language) && !isUserLogged())
                  return this.props.changeModalState({
                    isVisible: true,
                    modalName: MODAL_NAMES.ARPLUS_LOGIN_DISCLAIMER
                  })
              }
              return window.open(this.props.t('ar-plus.url'))
            }}
          >
            <Icon
              name={IconNames.Account}
              size={30}
              color={theme.colors.blue}
              e2eClass={e2eClasses('home.navbar.menuLoginIcon')}
            />
            {this.props.milesLogin && isUserLogged() ? (
              <Row>
                <Column>
                  {this.props.t('general.header.hello') + this.props.milesLogin.firstName}
                  {
                    <BoldText>
                      {this.props.milesLogin.accountBalance
                        ? this.props.t('label.miles.ffcurrency', {
                            amount: this.props.milesLogin.accountBalance
                          })
                        : ''}
                    </BoldText>
                  }
                </Column>
              </Row>
            ) : (
              <LoginText>
                <LoginLabel>{this.props.t('general.header-login')}</LoginLabel>
              </LoginText>
            )}
          </LoginMenu>
          <span />
        </MenuItemLogin>
        {isUserLogged() && !isEmpty(this.props.milesLogin) && (
          <LoginOptions id="loginOptions">
            <Options onClick={() => this.handleOption1Click()}>
              {this.props.t('general.header.arplus.title.label')}
            </Options>
            <Options onClick={() => this.handleOption2Click()}>
              {this.props.t('general.header.arplus.title.arplus-program')}
            </Options>
            {this.isArsaUserKey() && (
              <Options onClick={() => this.handleOption3Click()}>
                {this.props.t('general.header.arplus.title.arplus-disactivate-keys')}
              </Options>
            )}
            <Options onClick={() => this.handleOption4Click()}>
              {this.props.t('general.header.arplus.link.logout')}
            </Options>
          </LoginOptions>
        )}
      </LoginMenuDesktopContainer>
    )
  }

  getStepBar = () => this.isActiveComponent(COMPONENTS.STEP_BAR) && <StepBar />

  getActionIcon = () => {
    if (!this.state.expanded) {
      return IconNames.Menu
    }

    return this.state.expanded ? IconNames.Close : IconNames.ArrowLeft
  }

  getRibbonImage = () => {
    const { location, darkSiteRibbon } = this.props

    if (!isRouteWithDarkSiteStyle(location) || !darkSiteRibbon) {
      return null
    }

    return darkSiteRibbon
  }

  getMenuIcon = menuItem => {
    if (this.state.expanded === menuItem) {
      return IconNames.ChevronUp
    }

    return isMobileOrTablet(this.props.mediaType) ? IconNames.ChevronRight : IconNames.ChevronDown
  }

  getMenuDisplayer(sessionPanel, openPanel) {
    const shouldDisplayMenu = !sessionPanel && this.isActiveComponent(COMPONENTS.MENU)
    const { view: showEmptyDiv } = shouldRenderEmptyDiv(
      this.props.location.pathname,
      this.props.mediaType
    )

    return (
      (shouldDisplayMenu && (
        <MenuDisplayer onClick={() => this.switchVisibility(openPanel, true)} aria-label="Menu">
          <Icon
            name={this.getActionIcon()}
            size={isMobile(this.props.mediaType) ? 25 : 30}
            color={theme.colors.blue}
          />
        </MenuDisplayer>
      )) ||
      (showEmptyDiv && <div />)
    )
  }

  render() {
    if (this.props.mobileFullViews) {
      return null
    }

    const { header, isEnabledDarkSite } = this.props
    const languageMenuIcon = this.getMenuIcon(menu.language)
    const mobileOrTablet = isMobileOrTablet(this.props.mediaType)
    const openPanel = this.state.expanded && this.state.expanded !== menu.language ? false : true
    const sessionPanel = this.state.expanded === menu.session

    const { languages } = header
    const { language } = this.props.i18n
    const selectedLang = getSelectedLang(languages, language)

    const { showHelpCenter } = this.state

    return (
      <FixedContainer homePage={isHomeRoute(this.props.location)} ref={this.containerRef}>
        <SkipContent href="#main" className="skip-content-btn">
          {this.props.t('general.header.skip')}
        </SkipContent>
        <MainContainer
          stepBar={this.isActiveComponent(COMPONENTS.STEP_BAR)}
          darkSiteEnabled={isEnabledDarkSite}
          milesLogin={this.props.milesLogin}
          componentsLength={this.props.components.length}
          headerWithLoginOnly={
            this.props.components.length < 2 && !this.isActiveComponent(COMPONENTS.STEP_BAR)
          }
        >
          {this.getMenuDisplayer(sessionPanel, openPanel)}
          <HeaderLogo
            header={this.props.header}
            session={sessionPanel}
            noPadding={this.isActiveComponent(COMPONENTS.STEP_BAR)}
          />
          {this.getStepBar()}
          {this.isActiveComponent(COMPONENTS.LOGIN) &&
            !isCheckoutRoute(this.props.location) &&
            mobileOrTablet &&
            this.getLoginMenuMobile()}
          {this.state.open && this.isActiveComponent(COMPONENTS.MENU) && (
            <ComponentsContainer>
              <ItemsContainer
                onMouseLeave={() => this.closeAllSections()}
                onKeyDown={e => e.key == 'Escape' && this.closeAllSections()}
              >
                {((header && header.menuItems) || []).map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <MenuItem
                        onMouseEnter={() => this.switchVisibility(item.title, !mobileOrTablet)}
                        onClick={() => this.switchVisibility(item.title, true)}
                        aria-expanded={this.state.expanded}
                      >
                        <MenuText opened={this.state.expanded === item.title}>
                          {item.title}
                          <Icon name={IconNames.ChevronRight} size={30} color={theme.colors.blue} />
                        </MenuText>
                      </MenuItem>
                      {this.state.expanded === item.title &&
                        this.renderMenuPanel(item.sections, item.title)}
                    </Fragment>
                  )
                })}
              </ItemsContainer>
              {this.state.expanded === menu.session && !mobileOrTablet && <LoginPanel />}

              {showHelpCenter && this.isActiveComponent(COMPONENTS.MENU) && mobileOrTablet && (
                <Fragment>
                  <Divider />
                  <HelpMenu>
                    <Icon
                      name={IconNames.Help}
                      size={16}
                      color={theme.colors.blue}
                      e2eClass={e2eClasses('home.navbar.menuHelperIcon')}
                    />
                    <PointerCursor onClick={this.goToFAQPage}>
                      {this.props.t('general.header-help-center')}
                    </PointerCursor>
                  </HelpMenu>
                </Fragment>
              )}
              {this.isActiveComponent(COMPONENTS.LANGUAGE) && mobileOrTablet && (
                <Fragment>
                  <Divider />
                  <Row>
                    <Icon name={IconNames.Language} color={theme.colors.blue50} size={18} />
                    <LabelText>{this.props.t('general.header-choose-country')}</LabelText>
                  </Row>
                  <Row>
                    <LanguageMenu
                      onClick={() =>
                        this.switchVisibility(
                          this.state.expanded === menu.language ? true : menu.language,
                          true
                        )
                      }
                    >
                      <SelectedLanguage>
                        <FlagImage
                          src={`${CONFIG.CONTENT_CDN_BASE_URL}${selectedLang.flag}`}
                          alt="flag"
                        />
                        <PointerCursor>
                          {selectedLang.language
                            ? `${selectedLang.country} (${selectedLang.language})`
                            : `${selectedLang.country}`}
                        </PointerCursor>
                      </SelectedLanguage>
                      <Icon name={languageMenuIcon} size={30} color={theme.colors.blue50} />
                    </LanguageMenu>
                  </Row>
                </Fragment>
              )}
              {this.state.expanded === menu.language && header && header.languages && (
                <LanguagePanel
                  languages={languages}
                  onClickLanguage={() => this.closeAllSections()}
                  selectedLang={selectedLang}
                  clearMilesLogin={clearMilesLogin}
                />
              )}
            </ComponentsContainer>
          )}
          {!mobileOrTablet && (
            <ComponentsContainer
              justify={showHelpCenter ? 'space-between' : 'flex-end'}
              paddingLeft="60px"
              hideOnMobile
              onKeyDown={e => e.key == 'Escape' && this.closeAllSections()}
            >
              {showHelpCenter && this.isActiveComponent(COMPONENTS.MENU) && (
                <HelpMenu>
                  <Icon
                    name={IconNames.Help}
                    size={16}
                    color={theme.colors.blue}
                    e2eClass={e2eClasses('home.navbar.menuHelperIcon')}
                  />
                  <PointerCursor onClick={this.goToFAQPage}>
                    {this.props.t('general.header-help-center')}
                  </PointerCursor>
                </HelpMenu>
              )}
              {this.isActiveComponent(COMPONENTS.LOGIN) &&
                !shouldNotRenderLogin(language) &&
                this.getLoginMenuDesktop()}
              {this.isActiveComponent(COMPONENTS.LANGUAGE) && (
                <Row>
                  <LanguageMenu
                    onClick={() =>
                      this.switchVisibility(
                        this.state.expanded === menu.language ? true : menu.language,
                        true
                      )
                    }
                    aria-expanded={this.state.expanded === menu.language}
                    aria-controls={
                      this.state.expanded === menu.language ? 'language-panel' : undefined
                    }
                  >
                    <FlagImage
                      src={`${CONFIG.CONTENT_CDN_BASE_URL}${selectedLang.flag}`}
                      alt={selectedLang.country}
                    />
                    <Icon
                      name={languageMenuIcon}
                      size={30}
                      color={theme.colors.blue50}
                      e2eClass={e2eClasses('home.navbar.menuLanguage')}
                    />
                  </LanguageMenu>
                </Row>
              )}
              {this.getRibbonImage() && <RibbonImage src={this.getRibbonImage()} />}
              {this.state.expanded === menu.language && header && header.languages && (
                <LanguagePanel
                  languages={languages}
                  onClickLanguage={() => this.closeAllSections()}
                  selectedLang={selectedLang}
                  clearMilesLogin={this.props.clearMilesLogin}
                  id="language-panel"
                />
              )}
            </ComponentsContainer>
          )}
        </MainContainer>
      </FixedContainer>
    )
  }
}

const mapStateToProps = state => ({
  mobileFullViews: isMobileFullViewsVisible(state),
  darkSiteRibbon: getDarkSiteRibbon(state),
  mediaType: mapMediaType(state),
  header: getHeader(state),
  membershipNumber: getMembershipNumber(state)
})

export const mapDispatchToProps = {
  clearMilesLogin,
  changeModalState
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)))
