export function getQueryParamByLegs({ legs, confirmPlaced, paxQuantity, cabinClass }) {
  return (
    `confirmedPlace=${confirmPlaced}&paxQuantity=${paxQuantity}&cabinClass=${cabinClass}&` +
    legs
      .map(
        ({ originCode, destinationCode, dateCode }) =>
          `leg=${originCode}-${destinationCode}-${dateCode}`
      )
      .join('&')
  )
}
