import { handleActions } from 'redux-actions'
import {
  accountEvaluationStart,
  accountEvaluationSuccess,
  accountEvaluationError,
  clearMemberInfo
} from 'actions/arplus/account-evaluation-actions/getAccountEvaluation'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [accountEvaluationStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [accountEvaluationSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [accountEvaluationError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    }),
    [clearMemberInfo]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
