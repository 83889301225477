import { languageTag, validateField } from './validations'

const parseCookies = cookies => {
  let cookieMap = []
  cookies &&
    cookies.split(';').forEach(pair => {
      const values = pair.split('=')
      if (values.length === 2) {
        cookieMap[values[0].trim()] = values[1]
      }
    })
  return cookieMap
}

export const getCookie = (cookies, cookieName) => parseCookies(cookies)[cookieName]

export const isValidCookieLanguageTag = cookieValue => validateField(languageTag, cookieValue)

export const isCookieArgLanguageTag = cookieValue =>
  cookieValue && cookieValue.toLowerCase() === 'es-ar'

export const delete_cookie = name => {
  if (typeof window !== 'undefined') {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }
}

export const deleteCookieWithDomain = (cookieName, cookieDomain, secure = false) => {
  let cookieString = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${cookieDomain};`

  if (secure) {
    cookieString += ' secure;'
  }

  document.cookie = cookieString
}

export const acceptEssentialCookies = () => {
  const cookies = document.cookie.split(';')
  const cookieDomain = window.location.hostname
  const isSecureProtocol = window.location.protocol === 'https:'

  cookies.forEach(cookieStr => {
    const [cookieName, ...cookieAttributesArray] = cookieStr.trim().split('=')
    if (!cookieName) return

    deleteCookieWithDomain(cookieName, cookieDomain)

    const cookieAttributes = cookieAttributesArray
      .join('=')
      .trim()
      .split(';')
    cookieAttributes.forEach(attribute => {
      if (attribute.trim().toLowerCase() === 'secure' && isSecureProtocol) {
        deleteCookieWithDomain(cookieName, cookieDomain, true)
      }
    })
  })
}
