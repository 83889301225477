import { createActions } from 'redux-actions'

const { showError, clearError } = createActions({
  SHOW_ERROR: data => data,
  CLEAR_ERROR: () => {}
})

const modalErrors = data => async dispatch => {
  dispatch(showError(data))
}

export { showError, clearError, modalErrors }
