import { createSelector } from 'reselect'
import { isDesktopOrWide } from 'helpers/utils'

const getMediaType = state => (state.browser ? state.browser.mediaType : '')

// Return selected dates grouped by trip type
export const mapMediaType = createSelector([getMediaType], mediaType => mediaType)

export const isDesktopOrWideScreen = createSelector([mapMediaType], mediaType =>
  isDesktopOrWide(mediaType)
)
