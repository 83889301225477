import { handleActions } from 'redux-actions'
import { changeFlightStatusSearch, resetFlightStatusSearch } from 'actions/flightStatusSearch'

const defaultState = {
  selected: null
}

const reducer = handleActions(
  {
    [changeFlightStatusSearch]: (state, { payload: { selected } }) => {
      return {
        ...state,
        selected
      }
    },
    [resetFlightStatusSearch]: state => ({
      ...state,
      ...defaultState
    })
  },
  defaultState
)

export default reducer
