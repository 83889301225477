import {
  ClockDesktop,
  ClockMobile,
  ErrorDesktop,
  ErrorMobile,
  SessionExpiredDesktop,
  SessionExpiredMobile,
  WebsiteInProgressDesktop,
  WebsiteInProgressMobile
} from 'assets/sublos'
import MODAL_NAMES from 'constants/modalNames'

export const SUBLOS_RESERVATION_VALIDATION_BASIC_QUOTE_SOLD_OUT =
  'sublos-reservation-validation-basic-quote-sold-out' // go-selected-passenger
export const SUBLOS_RESERVATION_VALIDATION_BENEFICIARY_INSUFFICIENT_CONDITIONS =
  'sublos-reservation-validation-beneficiary-insufficient-conditions' // go-selected-passenger
export const SUBLOS_RESERVATION_SERVICE_CREATE_RESERVATION_SERVER_ERROR =
  'sublos.reservation-service.create-reservation.server-error'
export const RESERVATION_SERVICE_ERROR = 'reservation.service.error'
export const SUBLOS_PRICE_QUOTE_RESERVATION_NOT_FOUND = 'sublos.price-quote-reservation.not-found'
export const SUBLOS_PRICE_QUOTE_RESERVATION_ERROR = 'sublos.price-quote-reservation.error'
export const SUBLOS_GENERAL_SERVICE_EXCEPTION = 'sublos.general.service-exception'
export const SUBLOS_RESERVATION_SERVICE_ADD_REMARK_SERVER_ERROR =
  'sublos.reservation-service.add-remark.server-error'
export const SUBLOS_RESERVATION_SERVICE_GET_RESERVATION_SERVER_ERROR =
  'sublos.reservation-service.get-reservation.server-error'
export const GDS_RESERVATION_ERROR_NOTFOUND = 'gds.reservation.error.notFound'
export const SUBLOS_RESERVATION_SERVICE_ADD_SSR_SERVER_ERROR =
  'sublos.reservation-service.add-ssr.server-error'
export const RESERVATION_SSR_INFO_INVALID_SEGMENTS = 'reservation.ssr-info.invalid-segments'
export const SUBLOS_PAYMENT_CONNECTOR_ERROR_UNABLETOCREATECARDDEBT =
  'sublos.payment-connector.error.unableToCreateCardDebt'
export const SUBLOS_ISSUE_RESERVATION_ERROR = 'sublos.issue-reservation.error'
export const SUBLOS_CONFIRM_RESERVATION_VCR_AMOUNT = 'sublos.confirm-reservation.vcr-amount'
export const SUBLOS_CONFIRM_TICKET_ERROR = 'sublos.confirm-ticket.error'
export const SUBLOS_RESERVATION_VALIDATION_BENEFICIARY_AGE_MUST_BE_LESS_21 =
  'sublos-reservation-validation-beneficiary-age-must-be-less-21'
export const SUBLOS_RESERVATION_VALIDATION_AGE_MUST_BE_25 =
  'sublos-reservation-validation-age-must-be-25'
export const SUBLOS_RESERVATION_VALIDATION_AGE_MUST_BE_21_25 =
  'sublos-reservation-validation-age-must-be-21-25'
export const GDS_PASSENGER_SSR_PREFERENCE_NOT_ALLOWED = 'gds.passenger.ssr-preference.not-allowed'
export const SUBLOS_BOOKING_DETAILS_REQUEST_MARKET_NOT_BLANK =
  'sublos.booking-details-request.market.not-blank'
export const SUBLOS_RESERVATION_VALIDATION_HOLDER_INSUFFICIENT_ANTIQUITY =
  'sublos-reservation-validation-holder-does-not-have-necessary-antiquity-condition'

export const BUTTON_CLICK = {
  PRIMARY: 'actionType',
  SECONDARY: 'secondaryActionType'
}

export const RESET_SERVICES = {
  RESET_CORE_SERVICES: 'RESET_CORE_SERVICES',
  RESET_ALL_SERVICES: 'RESET_ALL_SERVICES',
  RESET_ALL_SERVICES_AND_KEEP_ERROR_SCREEN: 'RESET_ALL_SERVICES_AND_KEEP_ERROR_SCREEN'
}

export const ERROR_TYPE = {
  SCREEN: 'SCREEN',
  MODAL: 'MODAL'
}

export const ACTION_TYPE = {
  CANCEL: 'CANCEL',
  RESET: 'RESET',
  GO_BACK: 'GO_BACK',
  GO_HOME: 'GO_HOME',
  RETURN_TO_FLIGHT_SELECTION: 'RETURN_TO_FLIGHT_SELECTION'
}

export default {
  WEB_IN_PROGRESS: {
    type: ERROR_TYPE.SCREEN,
    actionType: ACTION_TYPE.GO_HOME,
    secondaryActionType: null,
    title: 'sublos.website-in-progress.title',
    description: 'sublos.website-in-progress.subtitle',
    image: WebsiteInProgressDesktop,
    imageMobile: WebsiteInProgressMobile,
    key: 'WEB_IN_PROGRESS'
  },
  TIME_OUT_TIMER: {
    type: ERROR_TYPE.SCREEN,
    actionType: ACTION_TYPE.RESET,
    secondaryActionType: null,
    title: 'sublos.time-out.title',
    description: 'sublos.time-out.description',
    image: ClockDesktop,
    imageMobile: ClockMobile,
    key: 'TIME_OUT_TIMER'
  },
  GENERIC_ERROR: {
    type: ERROR_TYPE.SCREEN,
    actionType: ACTION_TYPE.GO_HOME,
    secondaryActionType: null,
    title: 'sublos.form.result-error.title',
    description: 'sublos.form.result-error.subtitle',
    image: ErrorDesktop,
    imageMobile: ErrorMobile,
    key: 'GENERIC_ERROR'
  },
  GENERIC_ERROR_WITHOUT_ACTIONS: {
    //TODO remove
    type: ERROR_TYPE.SCREEN,
    actionType: null,
    secondaryActionType: null,
    title: 'sublos.form.result-error.title',
    description: 'sublos.form.result-error.subtitle',
    image: ErrorDesktop,
    imageMobile: ErrorMobile,
    key: 'GENERIC_ERROR_WITHOUT_ACTIONS',
    showPrimaryButton: false
  },
  GENERIC_LOGIN_ERROR: {
    type: ERROR_TYPE.SCREEN,
    title: 'sublos.form.result-error.title',
    description: 'sublos.form.result-error.subtitle',
    image: ErrorDesktop,
    imageMobile: ErrorMobile,
    key: 'GENERIC_LOGIN_ERROR',
    showPrimaryButton: false
  },
  GENERIC_ERROR_FLIGHT: {
    type: ERROR_TYPE.SCREEN,
    actionType: ACTION_TYPE.RETURN_TO_FLIGHT_SELECTION,
    secondaryActionType: null,
    title: 'sublos.form.result-error.title',
    description: 'sublos.form.result-error.subtitle',
    image: ErrorDesktop,
    imageMobile: ErrorMobile,
    buttonText: 'sublos.main-page.step.flight-selection.modal.button.text',
    key: 'GENERIC_ERROR_FLIGHT'
  },
  PAGE_NOT_FOUND: {
    type: ERROR_TYPE.SCREEN,
    actionType: ACTION_TYPE.GO_HOME,
    secondaryActionType: null,
    title: 'general.page-not-found.secondary.title',
    description: 'general.page-not-found.secondary.subline',
    key: 'PAGE_NOT_FOUND'
  },
  SESSION_EXPIRED: {
    type: ERROR_TYPE.SCREEN,
    actionType: ACTION_TYPE.GO_HOME,
    secondaryActionType: null,
    title: 'sublos.session-expired.title',
    description: 'sublos.session-expired.subtitle',
    image: SessionExpiredDesktop,
    imageMobile: SessionExpiredMobile,
    buttonText: 'sublos.session-expired.button',
    key: 'SESSION_EXPIRED'
  },
  NO_ASSOCIATED_FLIGHT: {
    type: ERROR_TYPE.MODAL,
    modalName: MODAL_NAMES.SUBLOS_NO_NAME_WARNING,
    actionType: ACTION_TYPE.GO_BACK,
    secondaryActionType: null,
    title: 'sublos.main-page.step.select-no-name-passanger.modal.title',
    description: 'sublos.main-page.step.select-no-name-passanger.modal.text',
    showCloseButton: false,
    closeOnClickOutside: false,
    key: 'NO_ASSOCIATED_FLIGHT'
  },
  NO_AVAILABLE_FLIGHTS: {
    type: ERROR_TYPE.MODAL,
    actionType: ACTION_TYPE.RETURN_TO_FLIGHT_SELECTION,
    secondaryActionType: null,
    modalName: MODAL_NAMES.SUBLOS_NO_AVAILABLE_FLIGHTS,
    title: 'sublos.main-page.step.flight-selection.modal.title',
    description: 'sublos.main-page.step.flight-selection.modal.text',
    showCloseButton: false,
    closeOnClickOutside: false,
    key: 'NO_AVAILABLE_FLIGHTS',
    buttonText: 'sublos.main-page.step.flight-selection.modal.button.text'
  },
  RETRY_SEND_REQUEST: {
    type: ERROR_TYPE.MODAL,
    modalName: MODAL_NAMES.SUBLOS_RETRY_SEND_REQUEST,
    actionType: ACTION_TYPE.CANCEL,
    secondaryActionType: ACTION_TYPE.GO_HOME,
    title: 'sublos.retry-send-request.modal.title',
    description: 'sublos.retry-send-request.modal.text',
    showCloseButton: false,
    closeOnClickOutside: false,
    key: 'RETRY_SEND_REQUEST',
    buttonText: 'sublos.button.retry',
    buttonSecondaryText: 'sublos.button.go-home'
  },
  SUBLOS_CANCEL_RESERVATION_REQUEST: {
    type: ERROR_TYPE.MODAL,
    actionType: ACTION_TYPE.GO_HOME,
    secondaryActionType: null,
    modalName: MODAL_NAMES.SUBLOS_CANCEL_RESERVATION_REQUEST,
    title: 'sublos.modal.cancel-reservation-generated.title',
    description: 'sublos.modal.cancel-reservation-request.description',
    showCloseButton: false,
    closeOnClickOutside: false,
    key: 'SUBLOS_CANCEL_RESERVATION_REQUEST',
    buttonText: 'sublos.button.cancel-reservation-request-and-go-home',
    buttonSecondaryText: 'sublos.button.cancel'
  }
}
