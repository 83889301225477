import { createSelector } from 'reselect'

import SUBLOS_ERRORS from 'constants/sublos/errors'

const getErrorsSublosState = state => state.sublosErrors

export const isErrorSublos = createSelector([getErrorsSublosState], errors => ({ ...errors }))

export const isSublosSessionExpiredError = createSelector(
  [getErrorsSublosState],
  ({ error }) => error && error?.key === SUBLOS_ERRORS.SESSION_EXPIRED.key
)

export const isSublosTimerOurTimerExpiredError = createSelector(
  [getErrorsSublosState],
  ({ error }) => error && error?.key === SUBLOS_ERRORS.TIME_OUT_TIMER.key
)
