import { createActions } from 'redux-actions'

import { mapPassengerMissingInfo } from 'api-data-io/gds/passengers-missing-info'
import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  postPassengersMissingInfoStart,
  postPassengersMissingInfoSuccess,
  postPassengersMissingInfoError,
  resetPassengersMissingInfo,
  fetchPassengersMissingInfoStart,
  fetchPassengersMissingInfoSuccess,
  fetchPassengersMissingInfoError
} = createActions({
  POST_PASSENGERS_MISSING_INFO_START: () => {},
  POST_PASSENGERS_MISSING_INFO_SUCCESS: response => ({ response }),
  POST_PASSENGERS_MISSING_INFO_ERROR: error => ({ error }),
  RESET_PASSENGERS_MISSING_INFO: () => {},
  FETCH_PASSENGERS_MISSING_INFO_START: () => {},
  FETCH_PASSENGERS_MISSING_INFO_SUCCESS: response => ({ response }),
  FETCH_PASSENGERS_MISSING_INFO_ERROR: error => ({ error })
})

const fetchPassengersMissingInfo = pnr => {
  return async dispatch => {
    dispatch(fetchPassengersMissingInfoStart())

    try {
      const response = await api.get(apiRoutes.PASSENGERS_MISSING_INFO(pnr))
      dispatch(fetchPassengersMissingInfoSuccess(response.data))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(fetchPassengersMissingInfoError(err))
    }
  }
}

const putPassengersMissingInfo = (pnr, formData) => {
  return async dispatch => {
    dispatch(postPassengersMissingInfoStart())
    const payload = mapPassengerMissingInfo(formData)
    try {
      const response = await api.put(apiRoutes.PASSENGERS_MISSING_INFO(pnr), payload)
      dispatch(postPassengersMissingInfoSuccess(response.data))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(postPassengersMissingInfoError(err))
    }
  }
}

export {
  fetchPassengersMissingInfoStart,
  fetchPassengersMissingInfoSuccess,
  fetchPassengersMissingInfoError,
  fetchPassengersMissingInfo,
  postPassengersMissingInfoStart,
  postPassengersMissingInfoSuccess,
  postPassengersMissingInfoError,
  resetPassengersMissingInfo,
  putPassengersMissingInfo
}
