import { createActions } from 'redux-actions'

const { setFlightSelectionSuccess, resetFlightSelection } = createActions({
  SET_FLIGHT_SELECTION_SUCCESS: selectionData => ({ selectionData }),
  RESET_FLIGHT_SELECTION: () => {}
})

const setFlightSelection = selectionData => {
  return dispatch => {
    dispatch(setFlightSelectionSuccess(selectionData))
  }
}

export { setFlightSelection, resetFlightSelection, setFlightSelectionSuccess }
