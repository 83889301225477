import React from 'react'

import PropTypes from 'prop-types'
import FocusLock from 'react-focus-lock'

import Icon, { IconNames } from 'components/icons'
import Sticky from 'components/sticky'
import theme from 'components/theme'
import e2eClasses from 'helpers/automation'

import {
  MobileViewWrapper,
  StyledButton,
  HeaderContainer,
  Title,
  TitleContainer,
  SizeWrapper
} from './styled'
/**
 * @todo Rename
 * @notation Despite its name this component is meant
 * to be used also with (non mobile) dialog views.
 * Go to Modal and PaymentBooking implementations to see it in action.
 * @param {object} props - React component props
 */
const MobileView = ({ title, titleFontSize, icon, onClose, children, titleSemiBold }) => (
  <FocusLock>
    <MobileViewWrapper>
      <Sticky mobile zIndex={100} top={0}>
        <HeaderContainer>
          <SizeWrapper className={e2eClasses('payments.pendingPaymentBox')}>
            <TitleContainer className={e2eClasses('payments.pendingPaymentTitle')}>
              {icon && <Icon name={icon} color={theme.colors.doveGray} size={26} />}
              <Title fontSize={titleFontSize} titleSemiBold={titleSemiBold}>
                {title}
              </Title>
            </TitleContainer>
            <StyledButton
              className={e2eClasses('payments.pendingPaymentCloseButton')}
              onClick={onClose}
            >
              <Icon name={IconNames.Close} size={24} />
            </StyledButton>
          </SizeWrapper>
        </HeaderContainer>
      </Sticky>
      {children}
    </MobileViewWrapper>
  </FocusLock>
)

MobileView.propTypes = {
  title: PropTypes.string,
  titleFontSize: PropTypes.string,
  icon: PropTypes.oneOf(Object.values(IconNames)),
  onClose: PropTypes.func.isRequired
}

export default MobileView
