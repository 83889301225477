import { createGlobalStyle } from 'styled-components'

export default () =>
  createGlobalStyle`
    * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;

      &::before,
      &::after {
        box-sizing: border-box;
      }

      &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.black};
      }
    }

    button {
      border: none;
      background: none;
    }

    @-ms-viewport{
      width: device-width;
    }
  `
