/* eslint-disable import/no-cycle */
import theme from 'components/theme'
import BRANDS from 'constants/brands'
import FARE_FAMILY from 'constants/fareFamilies'
import FARES_CONDITIONS_HEADERS from 'constants/faresConditions'
import MARKET_TYPE from 'constants/marketType'
import { TRIP_TYPES as FLIGHT_TYPES } from 'constants/tripTypes'
import { getLegTypeLabel } from 'helpers/flightSummaryUtils'
import { getBaggageModalAllLegsLines, getBaggageModalSomeLegsLines } from 'helpers/translations'

import { isEmpty } from './utils'

export const getDetailsValue = (condition, header, translations) => {
  if (condition.details) {
    return condition.details
  }

  return condition.enabled
    ? translations(header.defaultIncludedText)
    : translations(header.defaultNonIncludedText)
}

export const getNameForBrandId = (brands, id) => {
  const brand = (brands || []).find(({ code }) => code === id)

  return brand ? brand.name : ''
}

export const getColorForBrandId = (brands, id) => {
  const brand = (brands || []).find(({ code }) => code === id)

  return brand ? brand.color : theme.colors.white
}

export const getConditionHeader = fareCondition => {
  return FARES_CONDITIONS_HEADERS.find(({ key }) => key === fareCondition)
}

export const mapFareConditions = fareConditions => {
  if (isEmpty(fareConditions)) {
    return {}
  }

  const { id, name, ...flightConditions } = fareConditions

  return {
    id,
    name,
    conditionsKeys: Object.keys(flightConditions),
    conditionsValues: fareConditions
  }
}

export const mapFare = ({ id, fare }) => ({
  id,
  name: fare.brandDescription,
  carryOnBaggage: {
    enabled: fare.carryOnBaggageEnabled,
    details: fare.brandConfigurationDetail?.carryOnBaggageDetails
  },
  checkedBaggage: {
    enabled: fare.checkedBaggageEnabled,
    details: fare.brandConfigurationDetail?.checkedBaggageDetails
  },
  exchanges: {
    enabled: fare.exchangesEnabled,
    details: fare.brandConfigurationDetail?.exchangesDetails
  },
  miles: {
    enabled: fare.milesEnabled,
    details: fare.brandConfigurationDetail?.milesDetails
  },
  returns: {
    enabled: fare.returnsEnabled,
    details: fare.brandConfigurationDetail?.returnsDetails
  },
  seatsSelection: {
    enabled: fare.seatSelectionEnabled,
    details: fare.brandConfigurationDetail?.seatSelectionDetails
  },
  skyPriority: {
    enabled: fare.skyPriority,
    details: fare.brandConfigurationDetail?.skyPriorityDetails
  }
})

// Exchanges case when only one leg from round trip is changed,
// family fare for unchanged leg must be read from originalFareRules,
// and changed leg is read from offersSelected[0] instead of offersSelected[index]
const getOneLegExchangeOfRoundTripFares = (
  isOriginalLeg,
  originalFares,
  offersSelected,
  changedFare
) => {
  if (isOriginalLeg) {
    const originalFareId = originalFares && Object.keys(originalFares)[0]
    const originalFaresData = originalFares && originalFares[originalFareId]
    return { fare: originalFaresData, id: originalFareId }
  }

  const changedFareId = offersSelected && offersSelected[0].brandId
  const changedFaresData = changedFare && changedFare[changedFareId]

  return { fare: changedFaresData, id: changedFareId }
}

/** @todo This should be simplified when gds fareRules order is fixed
 * @legIndex index from leg that is being iterated in getFlightInformationData
 * @id brandId that is already in leg or offer selected (it may be null)
 * @brandConfigurations map with brandId as key, has brandConfiguration such as baggageEnabled, etc
 *  */
const getFares = (legIndex, brandId, fareRules) => {
  if (brandId) {
    return { id: brandId, fare: fareRules[brandId] }
  }

  // If brandId is the same for both legs, there is only one brandConfiguration but legIndex may be 0 or 1
  const indexToLookFor = legIndex === 1 && fareRules.length > 1 ? legIndex : 0
  const brandIdFromLegIndex = Object.keys(fareRules)[indexToLookFor]
  const fare = fareRules[brandIdFromLegIndex]
  return { id: brandIdFromLegIndex, fare }
}

/** @todo This should be simplified when gds fareRules order is fixed
 * This helper is used in every fare condition component (offers, purchase, reservation, exchanges)
 *  1 - For flight-offers and exchanges-offers, brands ids are red from offersSelected
 *  2 - If is an exchange from only one leg of a round trip, brands are read both from offersSelected and leg
 *  3 - For purchase, brands ids are read according to leg index and fareConditions order
 *  4 - For reservation, brands ids are red from legs
 * */
export const getFlightInformationData = (
  legs,
  routes,
  fareRules,
  flightType,
  offersSelected,
  originalFares,
  originalLeg
) => {
  if (isEmpty(legs) || isEmpty(fareRules) || isEmpty(routes) || isEmpty(flightType)) {
    return null
  }

  const result = []
  const isMultidestiny = flightType === FLIGHT_TYPES.MULTIDESTINY

  const isOneLegExchangeOfRT = !isEmpty(offersSelected) && offersSelected.length !== legs.length
  const brandConfigurations = fareRules[0].brandConfigurations

  legs.forEach((leg, index) => {
    const brandId =
      leg.brandId || (offersSelected && offersSelected[index] && offersSelected[index].brandId)
    const fareDetails = isOneLegExchangeOfRT
      ? getOneLegExchangeOfRoundTripFares(
          index === originalLeg,
          originalFares,
          offersSelected,
          brandConfigurations
        )
      : getFares(index, brandId, brandConfigurations)

    const route = routes[index]
    const title = getLegTypeLabel(
      flightType === FLIGHT_TYPES.MULTIDESTINY,
      index,
      flightType,
      legs.length
    )

    result.push({
      title,
      route,
      conditions: fareDetails && fareDetails.fare ? mapFare(fareDetails) : {}
    })
  })

  return {
    isMultidestiny,
    fareConditions: result,
    extraInfo: fareRules[0].comments
  }
}

const findTextKeysForFare = (allLegsSameFamily, textKeyId, market) => {
  if (allLegsSameFamily) return getBaggageModalAllLegsLines(textKeyId, market)
  else return getBaggageModalSomeLegsLines(textKeyId, market)
}

export const getBaggageEnabledModalMessage = (families, market, baggageEnabled) => {
  if (market == MARKET_TYPE.CABOTAGE) {
    if (families.includes(FARE_FAMILY.EE))
      return findTextKeysForFare(
        families.every(value => value === FARE_FAMILY.EE),
        BRANDS.PROMO,
        market
      )
    else if (families.includes(FARE_FAMILY.EB))
      return findTextKeysForFare(
        families.every(value => value === FARE_FAMILY.EB),
        BRANDS.BASE,
        market
      )
  } else {
    if (families.includes(FARE_FAMILY.EB))
      return findTextKeysForFare(
        families.every(value => value === FARE_FAMILY.EB),
        BRANDS.BASE,
        market
      )
    else if (families.includes(FARE_FAMILY.EE))
      return findTextKeysForFare(
        families.every(value => value === FARE_FAMILY.EE),
        BRANDS.PROMO,
        market
      )
  }

  const isOneWay = baggageEnabled.length === 1

  if (isOneWay && !baggageEnabled[0]) {
    return ['shopping.flight-offers.baggage-modal.one-way']
  }

  if (!isOneWay) {
    const outboundEnabled = baggageEnabled[0]
    const inboundEnabled = baggageEnabled[1]

    if (!outboundEnabled && inboundEnabled) {
      return ['shopping.flight-offers.baggage-modal.inbound']
    }

    if (outboundEnabled && !inboundEnabled) {
      return ['shopping.flight-offers.baggage-modal.outbound']
    }

    return [
      'shopping.flight-offers.baggage-modal.all-subline-0',
      'shopping.flight-offers.baggage-modal.all-subline-1',
      'shopping.flight-offers.baggage-modal.all-subline-2'
    ]
  }
}
