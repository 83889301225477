import products from './products'

const PAYMENT_FLOW_TYPE = {
  MERCADO_PAGO: 'mercado-pago-confirmation',
  THIRD_PARTY_CHECKOUT: 'third_party-checkout',
  THIRD_PARTY_ANCILLARIES: 'third_party-ancillaries',
  THIRD_PARTY_EXCHANGE: 'third_party-exchange',
  THIRD_PARTY_BNPL: 'third_party-bnpl',
  THIRD_PARTY: 'third_party-confirmation'
}

const HOLD_OPTIONS = {
  BOOK_NOW_PAY_NOW: 'BNPN',
  BOOK_NOW_PAY_LATER: 'BNPL'
}

const HOLD_OPTION_IDS = {
  BOOK_NOW_PAY_NOW: HOLD_OPTIONS.BOOK_NOW_PAY_NOW,
  BOOK_NOW_PAY_LATER_24: 'test_BNPL_offering_2',
  BOOK_NOW_PAY_LATER_48: 'test_bnpl_offering'
}

const HOLD_OPTION_MATCH = {
  PAYMENT_NOW: {
    CONFIG: 'PAYMENT_NOW',
    ID: HOLD_OPTIONS.BOOK_NOW_PAY_NOW
  },
  PAYMENT_24: {
    CONFIG: 'PAYMENT_24',
    ID: HOLD_OPTION_IDS.BOOK_NOW_PAY_LATER_24
  },
  PAYMENT_48: {
    CONFIG: 'PAYMENT_48',
    ID: HOLD_OPTION_IDS.BOOK_NOW_PAY_LATER_48
  }
}

const PAYMENT_METHODS = {
  OFFLINE: 'AFOP', // efectivo
  CREDIT_CARD: 'CREDIT_CARD',
  DEBIT_CARD: 'DEBIT_CARD'
}

const FOP_CODE = {
  MP: 'MP' // Mercado pago
}

// paymentMethodSelection.selectedPayment state
const PAYMENT_METHOD_TYPES = {
  OFFLINE: 'AFOP', // efectivo
  FIXED_INSTALLMENTS: 'FIXED_INSTALLMENTS',
  INTEREST_FREE_INSTALLMENTS: 'INTEREST_FREE_INSTALLMENTS',
  DEBIT_CARD: 'DEBIT_CARD'
}

const CARD_TYPES = { AMEX: 'AX', CABAL: 'CL', CABAL_24: 'CJ', DINERS: 'DC' }

const CHANNEL_PAYMENT_FLOWS = {
  THIRD_PARTY: 'THIRD_PARTY_ONLINE_PAYMENT',
  DIRECT: 'DIRECT_ONLINE_PAYMENT'
}

const CHANNEL_BOOKING_TYPES = {
  REMOTE: 'REMOTE',
  DIRECT: 'DIRECT',
  EXTERNAL: 'EXTERNAL'
}

const AUTHORIZATION_RESPONSE_CODE = {
  // INITIALIZE_IFRAME
  IFRAME: 'IFRAME',

  // CHALLENGE_FLOW
  BEGIN_CHALLENGE: 'BEGINCHALLENGE',

  // FRICTION_LESS_FLOW
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  ERROR: 'ERROR'
}

const CARDS_VALIDATION = {
  DEFAULT_DIGIT_COUNT: 16,
  DEFAULT_SECURITY_CODE_DIGIT_COUNT: 3
}

const GATEWAY_METADATA_NAMES = {
  WEBSERVICE: 'webservice'
}

const SERVICES_3P = {
  BNPL: HOLD_OPTIONS.BOOK_NOW_PAY_LATER,
  ANCILLARIES: products.ANCILLARIES_BOOKING,
  EXCHANGE: products.EXCHANGE
}

const DELIMITER = '__LAST_NAME__'

export {
  HOLD_OPTION_IDS,
  HOLD_OPTIONS,
  PAYMENT_METHODS,
  PAYMENT_METHOD_TYPES,
  CARD_TYPES,
  CHANNEL_PAYMENT_FLOWS,
  CHANNEL_BOOKING_TYPES,
  CARDS_VALIDATION,
  GATEWAY_METADATA_NAMES,
  SERVICES_3P,
  HOLD_OPTION_MATCH,
  AUTHORIZATION_RESPONSE_CODE,
  FOP_CODE,
  PAYMENT_FLOW_TYPE,
  DELIMITER
}
