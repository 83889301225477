/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect'

import { PAYMENT_METHOD_TYPES, CARDS_VALIDATION, GATEWAY_METADATA_NAMES } from 'constants/payment'
import { FIELD_OPTIONS } from 'constants/paymentMethods'
import { isEmpty } from 'helpers/utils'
import { getPaymentOptionsAmount, getPaymentOptionsData } from 'selectors/paymentOptions'

import { getDebitInstallmentOptions, isFixedInstallments } from '../helpers/paymentMethods'

export const getPaymentMethodSelectionSubState = state => state.paymentMethodSelection
export const getSecondPaymentMethodSelectionSubState = state =>
  state.paymentMethodSelection.secondCardPaymentMethod

export const isFetchingPaymentOptionsValidations = createSelector(
  [getPaymentMethodSelectionSubState],
  state => (state ? state.isFetching : false)
)

export const getPaymentOptionsValidationsError = createSelector(
  [getPaymentMethodSelectionSubState],
  state => state.error
)

export const getPaymentMethodsValidations = createSelector(
  [getPaymentMethodSelectionSubState],
  state => (state ? state.paymentValidations : null)
)

export const getPaymentMethodUriLogoByCode = createSelector(
  [getPaymentMethodsValidations],
  (_, code) => code,
  (paymentMethods, code) => {
    const paymentMethod = paymentMethods?.find(paymentMethod => paymentMethod.code === code)
    return paymentMethod?.imageLogo
  }
)

export const getSecondPaymentMethodsValidations = createSelector(
  [getSecondPaymentMethodSelectionSubState],
  state => (state ? state.paymentValidations : null)
)

export const getPaymentMethodSelected = createSelector([getPaymentMethodSelectionSubState], state =>
  state ? state.selectedPaymentMethod : null
)
export const getSecondPaymentMethodSelected = createSelector(
  [getSecondPaymentMethodSelectionSubState],
  state => (state ? state.selectedPaymentMethod : null)
)

export const getPaymentMethodSelectedType = createSelector(
  [getPaymentMethodSelectionSubState],
  state => (state ? state.selectedType : null)
)
export const getSecondPaymentMethodSelectedType = createSelector(
  [getSecondPaymentMethodSelectionSubState],
  state => (state ? state.selectedType : null)
)

export const getPaymentMethodSelectedInstallment = createSelector(
  [getPaymentMethodSelectionSubState],
  state => (state ? state.selectedInstallment : null)
)
export const getSecondPaymentMethodSelectedInstallment = createSelector(
  [getSecondPaymentMethodSelectionSubState],
  state => (state ? state.selectedInstallment : null)
)

export const getPaymentMethodCardNumberMasks = createSelector(
  [getPaymentMethodSelectionSubState],
  state => (state && state.masks ? state.masks.creditCard : [])
)
export const getSecondPaymentMethodCardNumberMasks = createSelector(
  [getSecondPaymentMethodSelectionSubState],
  state => (state && state.masks ? state.masks.creditCard : null)
)

export const getPaymentMethodSecurityCodeMasks = createSelector(
  [getPaymentMethodSelectionSubState],
  state => (state && state.masks ? state.masks.securityCode : [])
)
export const getSecondPaymentMethodSecurityCodeMasks = createSelector(
  [getSecondPaymentMethodSelectionSubState],
  state => (state && state.masks ? state.masks.securityCode : null)
)

export const getPaymentMethodSelectedTotalInterest = createSelector(
  [getPaymentMethodSelectionSubState],
  state => (state && state.totalInterest !== 0 ? state.totalInterest : null)
)

export const getSelectedIssuer = createSelector(
  [getPaymentMethodSelectionSubState],
  data => data && data.selectedIssuer
)

export const isPaymentMethodSelectedFixedInstallments = createSelector(
  [getPaymentMethodSelectedType],
  selectedType => selectedType && isFixedInstallments(selectedType)
)

/* Attempts to find payment option with a specific issuer first,
if not then tries to find with empty issuer*/
const interestFreeOption = (installmentOptions, selectedPaymentMethod, selectedIssuer) => {
  const expr1 = ({ paymentMethod, issuer }) =>
    paymentMethod === selectedPaymentMethod && selectedIssuer === issuer

  const expr2 = ({ paymentMethod, issuer }) =>
    paymentMethod === selectedPaymentMethod && isEmpty(issuer)

  return installmentOptions && (installmentOptions.find(expr1) || installmentOptions.find(expr2))
}

/* Attempts to find payment option with a specific issuer first,
if not then tries to find with empty issuer*/
const fixedInstallmentOption = (
  installmentOptions,
  selectedPaymentMethod,
  selectedIssuer,
  selectedRate
) => {
  const expr1 = ({ paymentMethod, issuer, interestRate }) =>
    paymentMethod === selectedPaymentMethod &&
    issuer === selectedIssuer &&
    interestRate === selectedRate

  const expr2 = ({ paymentMethod, issuer, interestRate }) =>
    paymentMethod === selectedPaymentMethod && isEmpty(issuer) && interestRate === selectedRate

  return installmentOptions && (installmentOptions.find(expr1) || installmentOptions.find(expr2))
}

const getPaymentOptionFunction = (paymentOptions, selectedMethod) => {
  if (!isEmpty(paymentOptions) && !isEmpty(selectedMethod)) {
    const {
      selectedType,
      selectedInstallment,
      selectedPaymentMethod,
      selectedIssuer,
      selectedRate
    } = selectedMethod

    const isOffline = selectedType === PAYMENT_METHOD_TYPES.OFFLINE
    const isFixedInstallments = selectedType === PAYMENT_METHOD_TYPES.FIXED_INSTALLMENTS
    const isInterestFreeInstallments =
      selectedType === PAYMENT_METHOD_TYPES.INTEREST_FREE_INSTALLMENTS
    const isDebitCard = selectedType === PAYMENT_METHOD_TYPES.DEBIT_CARD

    // if selectedType is PAYMENT_METHOD_TYPES.OFFLINE (cash payment) only initialAmount is needed,
    // and it doesnt have bins or blockedBins, so there's no need to look for any further data
    // -----
    // If isFixedInstallments and selectedInstallment is null
    // it shouldn't look for any more data
    // Fixed installments are first selected by Type (BA,IK) ,
    // but at that point there are no installments selected yet
    if (!selectedType || isOffline || (isFixedInstallments && !selectedInstallment)) {
      return null
    }

    const field = FIELD_OPTIONS[selectedType]

    const paymentValidationsBins = selectedMethod?.paymentValidations?.find(
      item => item.code === selectedPaymentMethod
    )?.bins

    //If selectedInstallment is empty and is not offline payment, is debit, so it defaults to '1'
    let installmentOptions = paymentOptions[field][selectedInstallment || '1']?.map(item => ({
      ...item,
      bins: [...(item.bins || []), ...(paymentValidationsBins || [])]
    }))

    if (isDebitCard) {
      installmentOptions = getDebitInstallmentOptions(
        paymentOptions,
        selectedPaymentMethod,
        installmentOptions
      )
      return interestFreeOption(installmentOptions, selectedPaymentMethod)
    } else if (isInterestFreeInstallments)
      return interestFreeOption(installmentOptions, selectedPaymentMethod, selectedIssuer)
    else if (selectedType === PAYMENT_METHOD_TYPES.FIXED_INSTALLMENTS) {
      return fixedInstallmentOption(
        installmentOptions,
        selectedPaymentMethod,
        selectedIssuer,
        selectedRate
      )
    }
  }
  return null
}

/** Payment options need to be looked into after initial user selection for additional data such
 * as bins, blockedBins and selected amounts. Except in offline payment */
const getPaymentOptionFromPaymentMethodSelected = createSelector(
  [getPaymentOptionsData, getPaymentMethodSelectionSubState],
  (paymentOptions, selectedMethod) => getPaymentOptionFunction(paymentOptions, selectedMethod)
)

const getPaymentOptionFromPaymentMethodSecondCardSelected = createSelector(
  [getPaymentOptionsData, getSecondPaymentMethodSelectionSubState],
  (paymentOptions, selectedMethod) => getPaymentOptionFunction(paymentOptions, selectedMethod)
)

/* Bins and blocked bins are for credit card (interest free or fixed) and debit card */
export const getBinForCurrentPaymentMethod = createSelector(
  [getPaymentOptionFromPaymentMethodSelected],
  selectedPaymentOption => (selectedPaymentOption ? selectedPaymentOption.bins : [])
)

export const getBinForSecondPaymentMethod = createSelector(
  [getPaymentOptionFromPaymentMethodSecondCardSelected],
  selectedPaymentOption => (selectedPaymentOption ? selectedPaymentOption.bins : [])
)

export const getBlockedBinsForCurrentPaymentMethod = createSelector(
  [getPaymentOptionFromPaymentMethodSelected],
  selectedPaymentOption => (selectedPaymentOption ? selectedPaymentOption.blockedBins : [])
)

export const getBlockedBinsForSecondPaymentMethod = createSelector(
  [getPaymentOptionFromPaymentMethodSecondCardSelected],
  selectedPaymentOption => (selectedPaymentOption ? selectedPaymentOption.blockedBins : [])
)

/* This method responds to fixed installments additional interest changing total amount,
and is the only source of truth to read the final amount of the transaction.
If there is no method selected (e.g. passengers data step), or
is a cash (offline) or debit payment (in these cases totalAmount field is empty),
this will return the original amount that was sent to paymentOptions evaluation*/
export const getPaymentOptionsSelectedAmount = createSelector(
  [getPaymentOptionFromPaymentMethodSelected, getPaymentOptionsAmount],
  (selectedPaymentOption, initialAmount) =>
    (selectedPaymentOption && selectedPaymentOption.totalAmount) || initialAmount
)

export const getPaymentOptionsSelectedInstallmentAmount = createSelector(
  [getPaymentOptionFromPaymentMethodSelected, getPaymentOptionsAmount],
  (selectedPaymentOption, initialAmount) =>
    (selectedPaymentOption && selectedPaymentOption.installmentAmount) || initialAmount
)

const getGatewayMetadataForCurrentPaymentMethod = createSelector(
  [getPaymentOptionFromPaymentMethodSelected],
  selectedPaymentOption => selectedPaymentOption && selectedPaymentOption.gatewayMetadata
)

const getWebserviceGatewayForCurrentPaymentMethod = createSelector(
  [getGatewayMetadataForCurrentPaymentMethod],
  gatewayMetadata => gatewayMetadata && gatewayMetadata[GATEWAY_METADATA_NAMES.WEBSERVICE]
)

const getMerchantIdForCurrentPaymentMethod = createSelector(
  [getWebserviceGatewayForCurrentPaymentMethod],
  wsGateway => wsGateway && wsGateway.merchantId
)

const getPaymentValidationForCurrentPaymentMethod = createSelector(
  [getPaymentMethodSelected, getPaymentMethodsValidations],
  (selectedPaymentMethod, paymentValidations) => {
    return (paymentValidations || []).find(({ code }) => code === selectedPaymentMethod)
  }
)
const getPaymentValidationForSecondPaymentMethod = createSelector(
  [getSecondPaymentMethodSelected, getPaymentMethodsValidations],
  (selectedSecondPaymentMethod, paymentValidations) => {
    return (paymentValidations || []).find(({ code }) => code === selectedSecondPaymentMethod)
  }
)

const getPspPlanForCurrentPaymentMethod = createSelector(
  [getWebserviceGatewayForCurrentPaymentMethod],
  wsGateway => wsGateway && wsGateway.psp_plan
)

/** @description only if merchantId from selected payment options is null,
 * then merchantId from general paymentmethods should be used */
export const getPaymentValidation = createSelector(
  [
    getPaymentValidationForCurrentPaymentMethod,
    getMerchantIdForCurrentPaymentMethod,
    getPspPlanForCurrentPaymentMethod
  ],
  (pValidation, selectedPaymentOptionMerchantId, pspPlan) => {
    return {
      digitCount: (pValidation && pValidation.digitCount) || [CARDS_VALIDATION.DEFAULT_DIGIT_COUNT],
      securityCodeDigitCount: (pValidation && pValidation.securityCodeDigitCount) || [
        CARDS_VALIDATION.DEFAULT_SECURITY_CODE_DIGIT_COUNT
      ],
      cardIsoCode: pValidation && pValidation.cardIsoCode,
      auxiliaryCode: pValidation && pValidation.auxiliaryCode,
      merchantId: selectedPaymentOptionMerchantId || (pValidation && pValidation.merchantId),
      binList: pValidation && pValidation.bins,
      blockedBins: pValidation && pValidation.blockedBins,
      pspPlan
    }
  }
)
export const getSecondPaymentValidation = createSelector(
  [
    getPaymentValidationForSecondPaymentMethod,
    getMerchantIdForCurrentPaymentMethod,
    getPspPlanForCurrentPaymentMethod
  ],
  (pValidation, selectedPaymentOptionMerchantId, pspPlan) => {
    return {
      secondDigitCount: (pValidation && pValidation.digitCount) || [
        CARDS_VALIDATION.DEFAULT_DIGIT_COUNT
      ],
      secondSecurityCodeDigitCount: (pValidation && pValidation.securityCodeDigitCount) || [
        CARDS_VALIDATION.DEFAULT_SECURITY_CODE_DIGIT_COUNT
      ],
      secondCardIsoCode: pValidation && pValidation.cardIsoCode,
      secondAuxiliaryCode: pValidation && pValidation.auxiliaryCode,
      secondMerchantId: selectedPaymentOptionMerchantId || (pValidation && pValidation.merchantId),
      secondBinList: pValidation && pValidation.bins,
      secondBlockedBins: pValidation && pValidation.blockedBins,
      pspPlan
    }
  }
)
