import React, { useState } from 'react'

import PropTypes from 'prop-types'

import Icon, { IconNames } from 'components/icons'
import CONFIG from 'config'

import theme from '../../theme'
import { Options, RibbonImage } from '../styled'
import { MenuItemButton, LoginMenuMobile, LoginOptions } from './styled'

const isMilesEnabled = CONFIG.ENABLE_MILES

const LoginItemMobile = ({ options, ribbonImg, isUserLoggedIn, onClick }) => {
  const [openDropdownMenu, setDropdownMenuOpen] = useState(false)

  const handleLoginClick = () => {
    if (isUserLoggedIn && isMilesEnabled) {
      setDropdownMenuOpen(prevValue => !prevValue)
    } else {
      onClick?.()
    }
  }

  const handleItemClick = itemClickHandler => {
    setDropdownMenuOpen(false)
    itemClickHandler()
  }

  return (
    <React.Fragment>
      <MenuItemButton onClick={handleLoginClick} aria-label="Login">
        <LoginMenuMobile>
          <Icon name={IconNames.AccountBorderless} size={25} color={theme.colors.blue} />
          {ribbonImg && <RibbonImage src={ribbonImg} />}
        </LoginMenuMobile>
      </MenuItemButton>
      {openDropdownMenu && options && (
        <LoginOptions id="loginOptions">
          {options
            .filter(option => option !== null && option !== undefined)
            .map(option => (
              <Options key={option.label} onClick={() => handleItemClick(option.onClick)}>
                {option.label}
              </Options>
            ))}
        </LoginOptions>
      )}
    </React.Fragment>
  )
}

export default LoginItemMobile

LoginItemMobile.propTypes = {
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func })),
  ribbonImg: PropTypes.string,
  isUserLoggedIn: PropTypes.bool
}
