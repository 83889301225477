import React, { useState, useEffect, useRef } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Icon, { IconNames } from 'components/icons'
import theme from 'components/theme'
import COMPONENTS from 'constants/headerComponents'
import { RESET_SERVICES } from 'constants/sublos/errors'
import { sublosVersion } from 'constants/sublos/version'
import { isSublosHomeRoute } from 'helpers/routeMatchers'
import { isLoggedIn, logout as sublosLogout } from 'helpers/sublos/auth'
import { getSublosUserData } from 'helpers/sublos/userData'
import { generateSublosLoginRoute } from 'helpers/url/localization'
import { withErrorHandlerThunk } from 'hoc/sublos/thunk'
import useClickOutside from 'hooks/useClickOutside'
import NAMESPACES from 'localization/constants/namespaces'
import i18n from 'localization/i18n'

import HeaderLogo from './logo'
import {
  FixedContainer,
  MainContainer,
  Container,
  UserContainer,
  InfoContainer,
  GreetingText,
  NameText,
  CloseText,
  ExpandableMenu,
  CloseContainer,
  VersionContainer,
  VersionText,
  Divider
} from './styled'

const Header = ({ showUserProfileButton = true, components = [] }) => {
  const userData = getSublosUserData()
  const dispatch = useDispatch()

  const getNamePascalCase = name => {
    if (name === undefined) return
    return name.split(',')[1].toLowerCase()
  }

  const { t } = useTranslation(NAMESPACES.SUBLOS)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const name = getNamePascalCase(userData?.fullName)
  const history = useHistory()

  const containerRef = useRef()

  useClickOutside(containerRef, () => setMenuIsOpen(false))

  const handleSecondaryMenu = () => {
    setMenuIsOpen(prev => !prev)
  }

  const logout = () => {
    dispatch(withErrorHandlerThunk(RESET_SERVICES.RESET_ALL_SERVICES))
    sublosLogout()
    history.push(generateSublosLoginRoute(i18n?.language))
  }

  useEffect(() => {
    if (isSublosHomeRoute(history?.location) && !isLoggedIn()) {
      logout()
    }
  })

  return (
    <FixedContainer>
      <MainContainer>
        <Container>
          <HeaderLogo />
        </Container>
        {showUserProfileButton && userData && (components || []).includes(COMPONENTS.LOGIN) && (
          <Container ref={containerRef} onClick={() => handleSecondaryMenu()}>
            <UserContainer>
              <Icon name={IconNames.AccountBorderless} size={30} color={theme.colors.blue50} />
            </UserContainer>
            <InfoContainer>
              <GreetingText>{t('sublos.header.greeting')}</GreetingText>
              <NameText>{name}!</NameText>
            </InfoContainer>
            {menuIsOpen && (
              <ExpandableMenu>
                <CloseContainer onClick={() => logout()}>
                  <CloseText>{t('sublos.header.close')}</CloseText>
                </CloseContainer>
                <Divider></Divider>
                <VersionContainer>
                  <VersionText>Version: {sublosVersion}</VersionText>
                </VersionContainer>
              </ExpandableMenu>
            )}
          </Container>
        )}
      </MainContainer>
    </FixedContainer>
  )
}

Header.propTypes = {
  showUserProfileButton: PropTypes.bool,
  components: PropTypes.array
}

export default Header
