/* eslint-disable import/no-cycle */
import http from 'http'
import https from 'https'

import axios from 'axios'

import { catchApiError } from 'actions/apiError'
import CONFIG from 'config'
import { getLSValue } from 'helpers/localStorage'
import { LANGUAGE_TAGS } from 'localization/constants/languages'
import { getChannelFromTag, getSublosChannelFromTag } from 'localization/helpers'
import i18n from 'localization/i18n'

import apiArPlus, { setApiArPlusInterceptors } from './apiArPlus'
import apiBooking, { setApiBookingInterceptors } from './apiBooking'
import apiBookingV2, { setApiBookingV2Interceptors } from './apiBookingV2'
import apiSublos, { setApiSublosInterceptors } from './apiSublos'
import cmsApi, { ssrCmsApi, setCmsApiInterceptors, setCmsLanguageParams } from './cmsApi'

function getCurrentToken() {
  if (typeof window !== 'undefined') {
    return window.__ACCESS_TOKEN__
  }

  return ''
}

const apiLog = axios.create({
  baseURL: CONFIG.API_BASE_URL.replace('v1', 'v2'),
  httpAgent: new http.Agent({ keepAlive: true }),
  httpsAgent: new https.Agent({ keepAlive: true }),
  headers: {
    common: {
      Authorization: `Bearer ${getCurrentToken()}`,
      'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID
    },
    get: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    },
    post: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    }
  }
})

const apiLanguageDefaultAR = axios.create({
  baseURL: CONFIG.API_BASE_URL,
  headers: {
    common: {
      Authorization: `Bearer ${getCurrentToken()}`,
      'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID
    },
    get: {
      'Accept-Language': LANGUAGE_TAGS.ONLY_ARG_LANG
    },
    post: {
      'Accept-Language': LANGUAGE_TAGS.ONLY_ARG_LANG
    }
  }
})

const api = axios.create({
  baseURL: CONFIG.API_BASE_URL,
  headers: {
    common: {
      Authorization: `Bearer ${getCurrentToken()}`,
      'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID
    },
    get: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    },
    post: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    }
  }
})

const apiV2 = axios.create({
  baseURL: CONFIG.API_BASE_URL,
  headers: {
    common: {
      Authorization: `Bearer ${getCurrentToken()}`,
      'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID
    },
    get: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    },
    post: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    }
  }
})

const ssrApi = axios.create({
  baseURL: CONFIG.API_SSR_BASE_URL,
  headers: {
    common: {
      'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID,
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    }
  }
})

/* API to be used if form involves sending files to BE */
const formDataApi = axios.create({
  baseURL: CONFIG.API_BASE_URL,
  headers: {
    common: {
      Authorization: `Bearer ${getCurrentToken()}`,
      'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID,
      'Content-Type': 'multipart/form-data'
    },
    get: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    },
    post: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    }
  }
})

var apiArPlusIdToken = null
const createARPlusAPI = () => {
  if (apiArPlusIdToken) {
    return apiArPlusIdToken
  }

  return (apiArPlusIdToken = axios.create({
    baseURL: CONFIG.API_BASE_URL,
    headers: {
      common: {
        Authorization: `Bearer ${getLSValue('access_token')}`,
        'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID
      },
      get: {
        'Accept-Language': CONFIG.DEFAULT_LOCALE
      },
      post: {
        'Accept-Language': CONFIG.DEFAULT_LOCALE
      }
    }
  }))
}

/**
 * Set authToken header based.
 * @param {object} commonHeaders - ref to defaults.headers.common
 * @param {string} accessToken
 * @todo could be reused in setApiInterceptors
 */
export const setTokenHeader = (commonHeaders, accessToken) => {
  commonHeaders['Authorization'] = `Bearer ${accessToken}`
  return commonHeaders
}

/**
 * Set language headers based on current i18next initialized instance.
 * @param {object} config
 */
const setLanguageHeaders = (config, i18nInstance = i18n) => {
  let configHeader = {
    config: {
      url: config.url,
      data: { ...config.data },
      headers: { ...config.headers }
    },
    languageI18n: i18nInstance.language,
    isInitializedI18n: i18nInstance.isInitialized
  }

  if (i18nInstance.isInitialized) {
    const newChannel =
      config?.headers?.common['X-Channel-Id'] === CONFIG.DEFAULT_SUBLOS_CHANNEL_ID
        ? getSublosChannelFromTag(i18nInstance.language.toUpperCase())
        : getChannelFromTag(i18nInstance.language.toUpperCase())
    configHeader['channelIdI18n'] = newChannel
    config.headers['X-Channel-Id'] = newChannel
    config.headers['Accept-Language'] = i18nInstance.language
  }

  // API_ROUTES.RESERVATION_CONFIRMATION_3P === config.url &&
  //   errorLogGlobalConfirmation(config.data?.reservationCode, 'interceptor-confirmReservation', {
  //     configHeader
  //   })
  return config
}

/**
 * Meant for client side reacting to 502/503 errors in a generic way. (AAW-3971)
 * @param {object} reduxStoreInstance
 * @param {number} errorStatus
 */
function specialErrorTreatment({ getState, dispatch }, error) {
  // Prevents to dispatch multiple chained actions as we only need to react to first error occurrence.
  const shouldDispatch =
    !getState().catchedApiError &&
    (error?.response?.status === 502 || error?.response?.status === 503)
  if (shouldDispatch) {
    dispatch(catchApiError(error?.response?.status))
    throw new Error('Oops http error ', error?.response?.status)
  }
}

/**
 * Implement Request Retry Using Axios Adapter
 * @param {object} axiosDefaultsAdapter
 * @param {object} optionsRequestRetry
 */
function retryAdapterEnhancer(adapter, options) {
  return async config => {
    const { delay = 300, times = 0 } = options
    const { retryTimes = times, retryDelay = delay } = config
    let __retryCount = 0

    const request = async () => {
      try {
        return await adapter(config)
      } catch (err) {
        if (!retryTimes || __retryCount >= retryTimes) {
          return Promise.reject(err)
        }

        const { message, config } = err

        if (
          config?.method === 'post' &&
          !(message.includes('timeout') || message.includes('Network Error'))
        ) {
          return Promise.reject(err)
        }

        __retryCount++

        const delayRetryRequest = new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, retryDelay)
        })

        return delayRetryRequest.then(() => {
          return request()
        })
      }
    }

    return request()
  }
}

// API instance interceptors
function setApiInterceptors(reduxStoreInstance) {
  apiV2.interceptors.request.use(config => setLanguageHeaders(config))
  api.interceptors.request.use(config => setLanguageHeaders(config))

  api.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config

      error.response && specialErrorTreatment(reduxStoreInstance, error)

      if (error.response.status === 401 && !originalRequest.skipRetry) {
        originalRequest.skipRetry = true

        const accessToken = getCurrentToken()

        api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`

        return axios(originalRequest)
      }

      return Promise.reject(error)
    }
  )
}

/**
 * (AAW-3971) When redux store instance is created, this function must be
 * called in order to capture general 502/503 errors.
 * Should be used just on client side. If needed similar behaviour for ssr api
 * instances, should be done apart replacing and NOT adding interceptors.
 * @param {object} initializedStore
 */
function configAxiosInterceptors(initializedStore) {
  const interceptorSetters = [
    setApiInterceptors,
    setApiBookingInterceptors,
    setApiBookingV2Interceptors,
    setApiArPlusInterceptors,
    setApiSublosInterceptors,
    setCmsApiInterceptors
  ]
  interceptorSetters.forEach(setter => setter(initializedStore))
}

export {
  api as default,
  apiV2,
  apiLog,
  apiLanguageDefaultAR,
  ssrApi,
  createARPlusAPI,
  cmsApi,
  ssrCmsApi,
  apiBooking,
  apiBookingV2,
  apiArPlus,
  apiSublos,
  formDataApi,
  configAxiosInterceptors,
  specialErrorTreatment,
  getCurrentToken,
  setLanguageHeaders,
  setCmsLanguageParams,
  retryAdapterEnhancer
}
