import { useCallback, useEffect } from 'react'

const useClickOutside = (ref, handler) => {
  const listener = useCallback(event => {
    if (!ref.current || ref.current.contains(event.target)) {
      return
    }
    handler(event)
  }, [])

  useEffect(() => {
    document.addEventListener('click', listener)
    return () => document.removeEventListener('click', listener)
  }, [ref, handler])

  return {}
}

export default useClickOutside
