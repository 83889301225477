import styled from 'styled-components'
import mediaQueries from '../../media-queries'

export const MenuItemButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 6;
  background: none;
  border: none;
`

export const LoginMenuMobile = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
`

export const LoginOptions = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 81px;
  z-index: 500;
  width: auto;
  padding: 16px;
  flex-direction: column;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.white};

  ${mediaQueries.mobile`
    top: 61px;
  `};
`
