import { handleActions } from 'redux-actions'

import {
  provincesArplusStart,
  provincesArplusSuccess,
  provincesArplusError
} from 'actions/arplus/provincesArplus'

const defaultState = {
  data: [],
  error: null,
  isFetching: false
}

const provincesArplus = handleActions(
  {
    [provincesArplusStart]: state => ({
      ...state,
      data: [],
      error: null,
      isFetching: true
    }),
    [provincesArplusSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [provincesArplusError]: (state, { payload: { error } }) => ({
      ...state,
      data: [],
      error,
      isFetching: false
    })
  },
  defaultState
)

export default provincesArplus
