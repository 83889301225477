import { createActions } from 'redux-actions'

import cmsApiRoutes from 'constants/cmsApiRoutes'
import { logError } from 'helpers/log'
import { buildURLEncoded } from 'helpers/url'
// eslint-disable-next-line import/no-cycle
import { cmsApi, ssrCmsApi } from 'services/api'

const { fetchArplusDataStart, fetchArplusDataSuccess, fetchArplusDataError } = createActions({
  FETCH_ARPLUS_DATA_START: () => {},
  FETCH_ARPLUS_DATA_SUCCESS: data => ({ data }),
  FETCH_ARPLUS_DATA_ERROR: error => ({ error })
})

const fetchArplusData = () => {
  return async dispatch => {
    dispatch(fetchArplusDataStart())

    try {
      const response = await cmsApi.get(cmsApiRoutes.AR_PLUS_DATA)
      dispatch(fetchArplusDataSuccess(response.data))
    } catch (error) {
      dispatch(fetchArplusDataError(error))
    }
  }
}

const fetchArplusForServer = async (pageConfig, i18n) => {
  const queryParams = buildURLEncoded('', pageConfig)

  try {
    const { data } = await ssrCmsApi.get(`${cmsApiRoutes.AR_PLUS_DATA}${queryParams}`, { i18n })

    return {
      data,
      error: null,
      isFetching: false
    }
  } catch (error) {
    error.fileName = 'home-data.js'
    error.lineNumber = '31'
    logError(
      `Error when getting ${ssrCmsApi.defaults.baseURL + cmsApiRoutes.AR_PLUS_DATA + queryParams}`,
      '',
      error
    )
    return {
      error,
      data: null,
      isFetching: false
    }
  }
}

export {
  fetchArplusDataStart,
  fetchArplusDataSuccess,
  fetchArplusDataError,
  fetchArplusData,
  fetchArplusForServer
}
