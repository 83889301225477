import CONFIG from 'config'

import locales from './locales'

//ToDo: Replace usage of window
export const getLocale = () => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem('locale') || 'es-AR'
  }

  return CONFIG.DEFAULT_LOCALE
}

// Get translation by key.
const translate = (key, ...args) => {
  // Check if key is for template translation.
  const isTemplateTranslation = args.length > 0
  const locale = getLocale()

  // If is template translation pass arguments, if not just return the value of the translation key.
  const translation = isTemplateTranslation ? locales[locale][key](...args) : locales[locale][key]

  // Return translation.
  if (translation) {
    return translation
  }
}

export default translate
