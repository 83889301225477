import { createActions } from 'redux-actions'
import { buildURLEncoded } from 'helpers/url'

import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'

const {
  getFlightScheduleStart,
  getFlightScheduleSuccess,
  getFlightScheduleError,
  setFlightScheduleModalData,
  resetFlightScheduleModalData
} = createActions({
  GET_FLIGHT_SCHEDULE_START: () => {},
  GET_FLIGHT_SCHEDULE_SUCCESS: data => ({ data }),
  GET_FLIGHT_SCHEDULE_ERROR: error => ({ error }),
  SET_FLIGHT_SCHEDULE_MODAL_DATA: data => ({ data }),
  RESET_FLIGHT_SCHEDULE_MODAL_DATA: () => {}
})

const getFlightSchedule = (departureDate, origin, destination, requestType) => {
  return async dispatch => {
    dispatch(getFlightScheduleStart())

    try {
      const url = buildURLEncoded(apiRoutes.FLIGHTS_SCHEDULE, {
        origin: origin.toUpperCase(),
        destination: destination.toUpperCase(),
        departureDate,
        requestType
      })

      const data = await api.get(url)
      dispatch(getFlightScheduleSuccess(data))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(getFlightScheduleError(err))
    }
  }
}

export {
  getFlightScheduleStart,
  getFlightScheduleSuccess,
  getFlightScheduleError,
  getFlightSchedule,
  setFlightScheduleModalData,
  resetFlightScheduleModalData
}
