/* eslint-disable import/no-cycle */
import axios from 'axios'

import CONFIG from 'config'
import { LANGUAGE_TAGS } from 'localization/constants/languages'
import { parseToGeneralLanguage } from 'localization/helpers'
import i18n from 'localization/i18n'

import { specialErrorTreatment } from './index'

const cmsApi = axios.create({
  baseURL: CONFIG.CMS_API_BASE_URL
})

const ssrCmsApi = axios.create({
  baseURL: CONFIG.CMS_SSR_BASE_URL,
  timeout: 30000,
  headers: {
    'Cache-Control': 'no-cache'
  }
})

/**
 * Set language params based on current i18next initialized instance.
 * If not initialized config defaults are used.
 * @param {object} config
 */
const setCmsLanguageParams = (config, i18nInstance = i18n) => {
  //This spread order allows an individual caller (a redux-thunk maybe) to override default langcode
  config.params = {
    'langcode[0]': CONFIG.CMS_LANGCODE,
    'langcode[1]': CONFIG.GENERAL_CMS_LANGCODE,
    ...config.params
  }

  if (i18nInstance.isInitialized) {
    config.params['langcode[0]'] = i18nInstance.language
    config.params['langcode[1]'] = parseToGeneralLanguage(i18nInstance.language)
  }

  if (
    config.params['langcode[1]'] === LANGUAGE_TAGS.ITALIAN_ONLY_LANG ||
    config.params['langcode[1]'] === LANGUAGE_TAGS.FRENCH_ONLY_LANG
  )
    delete config.params['langcode[1]']

  return config
}

function setCmsApiInterceptors(initializedStore) {
  cmsApi.interceptors.request.use(config => setCmsLanguageParams(config))
  cmsApi.interceptors.response.use(
    response => response,
    error => {
      error.response && specialErrorTreatment(initializedStore, error)
    }
  )
}

export { cmsApi as default, ssrCmsApi, setCmsApiInterceptors, setCmsLanguageParams }
