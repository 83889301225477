import { createActions } from 'redux-actions'
import axios from 'axios'
import CONFIG from 'config'

import apiRoutes from 'constants/apiRoutes'
import { getLSValue } from 'helpers/localStorage'
import { modalErrors } from 'actions/arplus/errorsArplus'

const {
  accountEvaluationStart,
  accountEvaluationSuccess,
  accountEvaluationError,
  clearMemberInfo
} = createActions({
  ACCOUNT_EVALUATION_START: () => {},
  ACCOUNT_EVALUATION_SUCCESS: data => ({ data }),
  ACCOUNT_EVALUATION_ERROR: error => ({ error }),
  CLEAR_MEMBER_INFO: () => {}
})

const accountEvaluationGet = memberCode => async dispatch => {
  dispatch(accountEvaluationStart())

  try {
    const { API_BASE_URL, DEFAULT_CHANNEL_ID, DEFAULT_LOCALE } = CONFIG
    const params = {
      baseURL: API_BASE_URL,
      headers: {
        Authorization: `Bearer ${getLSValue('access_token')}`,
        'X-Channel-Id': DEFAULT_CHANNEL_ID,
        'Accept-Language': DEFAULT_LOCALE
      }
    }

    const accountEvaluationGet = () =>
      axios.get(apiRoutes.AR_PLUS_ACCOUNT_EVALUATION(memberCode), params)

    const memberInfoGet = () =>
      axios.get(`${apiRoutes.AR_PLUS_GET_MEMBER_INFO}${memberCode}`, params)

    const response = await axios
      .all([accountEvaluationGet(), memberInfoGet()])
      .then(
        await axios.spread((accountEvalRes, memberInfoRes) => ({
          ...accountEvalRes.data,
          ...memberInfoRes.data
        }))
      )
      .catch(error => {
        dispatch(clearMemberInfo())
        throw new Error(error)
      })
    dispatch(accountEvaluationSuccess(response))
  } catch (error) {
    const err = error.response ? error.response.data : error

    if (err?.statusCode === 401) {
      const data = {
        show: true,
        redirection: '/',
        message: 'general.session-expired.description'
      }
      dispatch(modalErrors(data))
      dispatch(clearMemberInfo())
    }
    dispatch(accountEvaluationError(error.response ? error.response.data : error))

    throw new Error(error)
  }
}

export {
  accountEvaluationGet,
  accountEvaluationStart,
  accountEvaluationSuccess,
  accountEvaluationError,
  clearMemberInfo
}
