import { createActions } from 'redux-actions'
import apiRoutes from 'constants/apiRoutes'
import { createARPlusAPI } from 'services/api'

const {
  unsubscribeConfirmationStart,
  unsubscribeConfirmationSuccess,
  unsubscribeConfirmationError,
  clearUnsubscribeConfirmationState
} = createActions({
  UNSUBSCRIBE_CONFIRMATION_START: () => {},
  UNSUBSCRIBE_CONFIRMATION_SUCCESS: data => ({ data }),
  UNSUBSCRIBE_CONFIRMATION_ERROR: error => ({ error }),
  CLEAR_UNSUBSCRIBE_CONFIRMATION_STATE: () => {}
})

const unsubscribeConfirmation = membershipCode => async dispatch => {
  dispatch(unsubscribeConfirmationStart())
  try {
    const apiArPlus = createARPlusAPI()
    const response = await apiArPlus.delete(
      `${apiRoutes.AR_PLUS_UNSUBSCRIBE_CONFIRMATION(membershipCode)}`,
      {
        membershipCode
      }
    )
    dispatch(unsubscribeConfirmationSuccess(response.data))
  } catch (error) {
    dispatch(unsubscribeConfirmationError(error.response ? error.response.data : error))
  }
}

export {
  unsubscribeConfirmation,
  unsubscribeConfirmationStart,
  unsubscribeConfirmationSuccess,
  unsubscribeConfirmationError,
  clearUnsubscribeConfirmationState
}
