const theme = {
  fonts: {
    regular: 'Source Sans Pro Regular',
    semiBold: 'Source Sans Pro Semi Bold',
    bold: 'Source Sans Pro Bold',
    black: 'Source Sans Pro Black',
    italic: 'Source Sans Pro Italic',
    arial_regular: 'Arial Regular',
    arial_medium: 'Arial Medium',
    arial_semibold: 'Arial Semibold',
    arial_black: 'Arial Black',
    arial_extrabold: 'Arial Extrabold',
    tahoma_regular: 'Tahoma Regular',
    tahoma_medium: 'Tahoma Medium',
    tahoma_semibold: 'Tahoma Semibold',
    tahoma_black: 'Tahoma Black',
    tahoma_extrabold: 'Tahoma Extrabold',
    regular_regular: 'Regular Regular',
    regular_medium: 'Regular Medium',
    regular_semibold: 'Regular Semibold',
    regular_black: 'Regular Black',
    regular_extrabold: 'Regular Extrabold'
  },
  colors: {
    white: '#ffffff',
    black: '#000000',
    doveGray: '#696969',
    gray10: '#fafafa',
    gray11: '#808080',
    grayLighter: '#f3f3f3',
    alabaster: '#f7f7f7',
    silver: '#c5c5c5',
    accessibleGrey: '#767676', // this grey facilitates accessibility
    grayLight: '#d6d6d6',
    gainsboro: '#e1e1e1',
    darkGrey: '#707070',
    gallery: '#eaeaea',
    silverChalice: '#a2a2a2',
    gray: '#808080',
    veryLightPink: '#d6d6d6',
    doveGrayer: '#4d4d4d',
    grayScorpion: '#5a5a5a',
    gray55: '#b2b2b2',
    gray60: '#919191',
    warmGray: '#979797',
    blueLighter: '#edf6ff',
    blueLight: '#a9d4ff',
    blue: '#396fa5',
    blueDark: '#34495e',
    blue50: '#0077c2',
    blue51: '#007CC3',
    redLight: '#ffe2e6',
    red: '#d0021b',
    valenciaRed: '#DB3D3D',
    greenLight: '#e7efde',
    green: '#417505',
    deYorkGreen: '#75B97B',
    yellowLight: '#ffec9e',
    transparent: 'transparent',
    alto: '#dddddd',
    dustyGray: '#9b9b9b',
    paleGray: '#e9eff6',
    black15: '#00000015',
    blueLagoon: '#068089',
    lochmara: '#007bc7',
    funBlue: '#1b5eb6',
    fuchsiaBlue: '#7654c9',
    royalBlue: '#454fe3',
    mulledWine: '#4f406a',
    polar: '#eaf9fb',
    foam: '#E5F6FC',
    selago: '#E1ECFB',
    aliceBlue: '#F0F9FF',
    cornflowerBlue: '#E5F0FF',
    magnolia: '#f7f3ff',
    zircon: '#EBF0FF',
    whisper: '#F2EFF6',
    cerulean: '#0B9BD0',
    denim: '#0E65D7',
    pigeonPost: '#a0bfd9',
    gold: '#d0c35d',
    platinum: '#d7d7d8',
    // star colors for ARPlus
    luxorGold: '#A48D29',
    ironPlatinum: '#D1D3D4',
    diamond: '#43525A',
    athensGray: '#FAFBFC',
    // color CMS
    cms_light_blue: '#007ac2',
    cms_gray: '#acabab',
    cms_yellow: '#ffd300',
    cms_blue: '#123274',
    cms_premium: '#50406A',
    cms_plus: '#396fa5',
    cms_flex: '#34495e',
    cms_promo_economy: '#974B22',
    cms_base: '#238192',
    cms_business: '#5b5b5f',
    cms_promo_business: '#2B3041',
    cms_premium_economy_promo: '#185081',
    cms_super_promo: '#97700C'
  },
  shadow: {
    black06: 'rgba(0, 0, 0, 0.06)',
    black09: 'rgba(0, 0, 0, 0.09)',
    black10: 'rgba(0, 0, 0, 0.10)',
    black11: 'rgba(0, 0, 0, 0.11)',
    black12: 'rgba(0, 0, 0, 0.12)',
    black15: 'rgba(0, 0, 0, 0.15)',
    black16: 'rgba(0, 0, 0, 0.16)',
    black20: 'rgba(0, 0, 0, 0.20)',
    black22: 'rgba(0, 0, 0, 0.22)',
    black24: 'rgba(0, 0, 0, 0.24)',
    black26: 'rgba(0, 0, 0, 0.26)',
    black30: 'rgba(0, 0, 0, 0.30)',
    black31: 'rgba(0, 0, 0, 0.31)',
    black50: 'rgba(0, 0, 0, 0.50)',
    black65: 'rgba(0, 0, 0, 0.65)',
    white03: 'rgba(255, 255, 255, 0.03)',
    white05: 'rgba(255, 255, 255, 0.05)',
    white35: 'rgba(255, 255, 255, 0.35)',
    white75: 'rgba(255, 255, 255, 0.75)',
    white90: 'rgba(255, 255, 255, 0.90)',
    white200: 'rgba(255, 255, 255, 2)',
    gray200: 'rgba(216, 216, 216, 2)',
    blue06: 'rgba(57,111,165,0.06)',
    blue26: 'rgba(57,111,165,0.26)',
    darkBlue50: 'rgba(52, 73, 94, 0.5)'
  }
}

export default theme
