import { handleActions } from 'redux-actions'
import {
  fetchSpecialRequestsStart,
  fetchSpecialRequestsSuccess,
  fetchSpecialRequestsError,
  clearSpecialRequestsError,
  saveSpecialRequestsError,
  saveSpecialRequestsStart,
  saveSpecialRequestsSuccess,
  clearSpecialRequestsSaveError
} from 'actions/rules/checkout/specialRequests'

const defaultState = {
  data: null,
  error: null,
  isFetching: false,
  isSubmitting: false
}

const reducer = handleActions(
  {
    [fetchSpecialRequestsStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchSpecialRequestsSuccess]: (state, { payload: { data } }) => {
      return {
        ...state,
        data,
        error: null,
        isFetching: false
      }
    },
    [fetchSpecialRequestsError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearSpecialRequestsError]: state => ({
      ...state,
      error: null
    }),
    [saveSpecialRequestsStart]: state => ({
      ...state,
      error: null,
      isSubmitting: true
    }),
    [saveSpecialRequestsSuccess]: state => ({
      ...state,
      isSubmitting: false
    }),
    [saveSpecialRequestsError]: (state, { payload }) => ({
      ...state,
      error: payload,
      isSubmitting: false
    }),
    [clearSpecialRequestsSaveError]: state => ({
      ...state,
      error: null
    })
  },
  defaultState
)

export default reducer
