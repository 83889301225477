/* eslint-disable import/order */
import { combineReducers } from 'redux'
import { createResponsiveStateReducer } from 'redux-responsive'

import { breakpoints } from 'components/media-queries'

import { ancillaries, ancillariesSelect, ancillariesPurchase } from './ancillaries'
import apiError from './apiError'
import arplus from './arplus'
import accountEvaluation from './arplus/accountEvaluation'
import airlineCompanies from './arplus/airlineCompanies'
import companiesAndServices from './arplus/companiesAndServices'
import countriesArplus from './arplus/countriesArplus'
import creditMilesDestination from './arplus/creditMilesDestination'
import creditMilesOrigin from './arplus/creditMilesOrigin'
import errorsArplus from './arplus/errorsArplus'
import evaluateMemberEligibility from './arplus/evaluateMemberEligibility'
import evaluateMemberEligibilityFirstCall from './arplus/evaluateMemberEligibilityFirstCall'
import firstAccess from './arplus/firstAccess'
import firstAccessSetPassword from './arplus/firstAccessSetPassword'
import forgottenPassword from './arplus/forgottenPassword'
import changePassword from './arplus/changePassword'
import giftMiles from './arplus/giftMiles'
import identificationCodes from './arplus/identificationCodes'
import identificationsType from './arplus/identificationsType'
import lastOperations from './arplus/lastOperations'
import memberInfo from './arplus/memberInfo'
import members from './arplus/members'
import membershipCode from './arplus/membershipCode'
import milesAmount from './arplus/milesAmount'
import offlineAereoConfirmation from './arplus/offlineAereoConfirmation'
import offlinePartnerConfirmation from './arplus/offlinePartnerConfirmation'
import paymentMiles from './arplus/paymentMiles'
import promoMilesAndBonus from './arplus/promoMilesAndBonus'
import provincesArplus from './arplus/provincesArplus'
import recoverEmail from './arplus/recoverEmail'
import recoverEmailConfirmation from './arplus/recoverEmailConfirmation'
import redeemPoints from './arplus/redeemPoints'
import transferMiles from './arplus/transferMiles'
import unsubscribeAccountData from './arplus/unsubscribeAccountData'
import unsubscribeConfirmation from './arplus/unsubscribeConfirmation'
import updateAccount from './arplus/updateAccount'
import updatePassword from './arplus/updatePassword'
import validateEmail from './arplus/validateEmail'
import verifyAccount from './arplus/verifyAccount'
import bnplPaymentOptions from './bnplPaymentOptions'
import { passengersMissingInfo, passengersMissingInfoUpdate } from './booking/passengersMissingInfo'
import aircrafts from './catalog/aircrafts'
import airlines from './catalog/airlines'
import airports from './catalog/airports'
import branches from './catalog/branches'
import brands from './catalog/brands'
import cabins from './catalog/cabins'
import channels from './catalog/channels'
import charges from './catalog/charges'
import cities from './catalog/cities'
import countries from './catalog/countries'
import contests from './catalog/contests'
import documentTypes from './catalog/documentTypes'
import provinces from './catalog/provinces'
import alerts from './cms/alerts'
import elitePartners from './cms/arplus/elite-partners'
import arplusData from './cms/arplus/home-data'
import banks from './cms/banks'
import categories from './cms/categories'
import darkSite from './cms/darkSite'
import directAccesses from './cms/directAccesses'
import eventCategories from './cms/eventCategories'
import footer from './cms/footer'
import header from './cms/header'
import homeInfo from './cms/homeInfo'
import landings from './cms/landings'
import popularDestinations from './cms/popularDestinations'
import pressReleases from './cms/pressReleases'
import questions from './cms/questions'
import sliders from './cms/sliders'
import termsConditions from './cms/termsConditions'
import urlRedirection from './cms/urlRedirection'
import {
  claimContactUs,
  cacClaims,
  claimsContactUsReasonsSuggestions,
  claimsContactUsExperiencePlaces,
  claimsContactUsAbout
} from './common/cacClaims'
import cancelTicketIn24hs from './common/cancellationIn24hs'
import { equipamentClaim, baggageEquipamentClaim } from './common/equipamentClaim'
import getIssuers from './common/getIssuers'
import { claimDocumentType, claimsReservationAndService } from './common/reservationAndService'
import ticketRefund from './common/ticketRefund'
import ticketRefundValidation from './common/ticketRefundValidation'
import tuavtaRefund from './common/tuavtaRefund'
import corporateAgreementRequest from './corporate/agreementRequest'
import corporateCredentialsRecovery from './corporate/credentialsRecovery'
import loginCorporate from './corporate/login'
import corporateAgreementsUserRegistration from './corporate/userRegistration'
import { fareRules } from './fareRules'
import flightData from './flightItinerary'
import flightOffersMatrix from './flightOffersMatrix'
import { flightSchedule, scheduledFlightModalData } from './flightSchedule'
import flightSeats from './flightSeats'
import flightsOffers from './flightsOffers'
import flightsSearchData from './flightsSearchData'
import flightsSelection from './flightsSelection'
import flightStatus from './flightStatus'
import flightStatusSearch from './flightStatusSearch'
import flightSummary from './flightSummary'
import holdBookingOptions from './holdBookingOptions'
import login from './login'
import milesLogin from './milesLogin'
import mobileFullViews from './mobileFullViews'
import modalState from './modalReducer'
import originalFares from './originalFares'
import passengers from './passengers'
import paymentMethodSelection from './paymentMethodSelection'
import paymentOptions from './paymentOptions'
import paymentStatus from './paymentStatus'
import punctualityIndex from './punctualityIndex'
import rangeTooltip from './rangeTooltip'
import reCaptcha from './reCaptcha'
import {
  reservation,
  purchase,
  paymentBooking,
  paymentDataFormik,
  passengersFormikData,
  exchangesPayment,
  emailAddition,
  purchaseTemp,
  purchasingChallenge,
  externalPayment
} from './reservation'
import campaigns from './rules/campaigns'
import documentTypesRules from './rules/checkout/documentTypes'
import passengerDataRules from './rules/checkout/passengerData'
import specialRequestsRules from './rules/checkout/specialRequests'
import notifications from './rules/notifications'
import searchBoxRules from './rules/searchBox'
import seatsSelection from './seatsSelection'
import secondCardPaymentOptions from './secondCardPaymentOptions'
import steps from './stepBar'
import sublosBenefitTypes from './sublos/benefitTypes'
import companiesSublos from './sublos/companies'
import sublosDateValidation from './sublos/dateValidation'
import sublosDestinations from './sublos/destinations'
import documentTypesSublos from './sublos/documentTypes'
import sublosEmployeeReservationsDetails from './sublos/employeeReservationsDetails'
import sublosErrors from './sublos/errors'
import sublosFamilyMember from './sublos/familyMembers'
import sublosFlightInventory from './sublos/flightInventory'
import sublosFlightOffers from './sublos/flightOffers'
import sublosLoginModal from './sublos/loginModal'
import sublosNoNameConditions from './sublos/noNameConditions'
import sublosPassengersDocumentation from './sublos/passengersDocumentation'
import sublosBookingValidation, {
  sublosReservation,
  sublosCancelReservation
} from './sublos/reservation'
import { suggestions, scheduledFlightsInputData } from './suggestions'
import tabs from './tabs'
import ticketRefundDiscard from './ticketRefundDiscard'
import ticketRefundStatus from './ticketRefundStatus'
// For the sake of tolerable (and happy) debugging
// please try to follow alphabetic or almost alphabetic order here.
const reducers = combineReducers({
  accountEvaluation,
  aircrafts,
  airlineCompanies,
  airlines,
  airports,
  alerts,
  ancillaries,
  ancillariesPurchase,
  ancillariesSelect,
  apiError,
  arplus,
  arplusData,
  banks,
  bnplPaymentOptions,
  brands,
  branches,
  browser: createResponsiveStateReducer(breakpoints, { infinity: 'widescreen' }),
  cabins,
  campaigns,
  categories,
  channels,
  charges,
  cities,
  companiesAndServices,
  corporateAgreementRequest,
  corporateAgreementsUserRegistration,
  corporateCredentialsRecovery,
  countries,
  contests,
  countriesArplus,
  changePassword,
  creditMilesDestination,
  creditMilesOrigin,
  darkSite,
  directAccesses,
  documentTypes,
  documentTypesRules,
  elitePartners,
  emailAddition,
  evaluateMemberEligibility,
  evaluateMemberEligibilityFirstCall,
  eventCategories,
  exchangesPayment,
  errorsArplus,
  fareRules,
  firstAccess,
  firstAccessSetPassword,
  flightData,
  flightOffersMatrix,
  flightSchedule,
  flightSeats,
  flightStatus,
  flightStatusSearch,
  flightSummary,
  flightsOffers,
  flightsSearchData,
  flightsSelection,
  footer,
  forgottenPassword,
  header,
  holdBookingOptions,
  homeInfo,
  identificationsType,
  identificationCodes,
  landings,
  lastOperations,
  login,
  loginCorporate,
  milesAmount,
  milesLogin,
  memberInfo,
  members,
  membershipCode,
  mobileFullViews,
  modalState,
  notifications,
  offlineAereoConfirmation,
  offlinePartnerConfirmation,
  originalFares,
  passengerDataRules,
  passengers,
  passengersFormikData,
  passengersMissingInfo,
  passengersMissingInfoUpdate,
  paymentBooking,
  paymentDataFormik,
  paymentMethodSelection,
  paymentMiles,
  getIssuers,
  paymentOptions,
  paymentStatus,
  popularDestinations,
  pressReleases,
  promoMilesAndBonus,
  provinces,
  provincesArplus,
  punctualityIndex,
  purchase,
  purchaseTemp,
  purchasingChallenge,
  externalPayment,
  questions,
  rangeTooltip,
  reCaptcha,
  recoverEmail,
  recoverEmailConfirmation,
  redeemPoints,
  reservation,
  scheduledFlightModalData,
  scheduledFlightsInputData,
  searchBoxRules,
  seatsSelection,
  secondCardPaymentOptions,
  sliders,
  specialRequestsRules,
  steps,
  suggestions,
  tabs,
  termsConditions,
  ticketRefund,
  ticketRefundStatus,
  ticketRefundValidation,
  tuavtaRefund,
  ticketRefundDiscard,
  transferMiles,
  giftMiles,
  unsubscribeAccountData,
  cancelTicketIn24hs,
  claimContactUs,
  claimsContactUsReasonsSuggestions,
  claimsContactUsExperiencePlaces,
  claimsContactUsAbout,
  cacClaims,
  claimDocumentType,
  claimsReservationAndService,
  unsubscribeConfirmation,
  updateAccount,
  updatePassword,
  validateEmail,
  verifyAccount,
  urlRedirection,
  equipamentClaim,
  baggageEquipamentClaim,
  companiesSublos,
  sublosDateValidation,
  sublosDestinations,
  documentTypesSublos,
  sublosBenefitTypes,
  sublosFamilyMember,
  sublosEmployeeReservationsDetails,
  sublosFlightOffers,
  sublosFlightInventory,
  sublosBookingValidation,
  sublosReservation,
  sublosCancelReservation,
  sublosErrors,
  sublosPassengersDocumentation,
  sublosLoginModal,
  sublosNoNameConditions
})

export default reducers
