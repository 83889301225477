import { createActions } from 'redux-actions'

const { resetPurchasingChallengeStatus } = createActions({
  RESET_PURCHASING_CHALLENGE_STATUS: () => {}
})

const clearPurchasingChallengeStatus = () => {
  return dispatch => {
    dispatch(resetPurchasingChallengeStatus())
  }
}

export { clearPurchasingChallengeStatus, resetPurchasingChallengeStatus }
