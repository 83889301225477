import { createSelector } from 'reselect'

export const getDocumentTypesSubstate = state => state.documentTypes

export const isFetchingDocumentTypes = createSelector(
  [getDocumentTypesSubstate],
  documentTypes => documentTypes.isFetching || false
)

export const getDocumentTypesError = createSelector(
  [getDocumentTypesSubstate],
  documentTypes => documentTypes?.error || null
)

export const getDocumentTypesData = createSelector([getDocumentTypesSubstate], documentTypes =>
  documentTypes ? documentTypes.data : null
)

export const getDocumentTypes = createSelector([getDocumentTypesData], documentTypes =>
  documentTypes ? documentTypes.resources : []
)
