import { handleActions } from 'redux-actions'

import {
  validateSublosPassengersDocumentationStart,
  validateSublosPassengersDocumentationSuccess,
  validateSublosPassengersDocumentationError,
  validateSublosPassengersDocumentationClear
} from 'actions/sublos/passengersDocumentation'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const passengersDocumentation = handleActions(
  {
    [validateSublosPassengersDocumentationStart]: () => ({
      data: null,
      error: null,
      isFetching: true
    }),
    [validateSublosPassengersDocumentationSuccess]: (state, { payload: { data } }) => ({
      data,
      error: null,
      isFetching: false
    }),
    [validateSublosPassengersDocumentationError]: (state, { payload: { error } }) => ({
      data: null,
      error,
      isFetching: false
    }),
    [validateSublosPassengersDocumentationClear]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default passengersDocumentation
