import { handleActions } from 'redux-actions'

import {
  verifyAccountStart,
  verifyAccountSuccess,
  verifyAccountError,
  clearVerifyAccountState
} from 'actions/arplus/verifyAccount'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const verifyAccount = handleActions(
  {
    [verifyAccountStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [verifyAccountSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [verifyAccountError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    }),
    [clearVerifyAccountState]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default verifyAccount
