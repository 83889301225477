import { handleActions } from 'redux-actions'
import {
  toggleFlightSummary,
  toggleFlightItinerary,
  toggleOffersFilters
} from 'actions/mobileViewsAction'

const defaultState = {
  visible: false,
  compareConditions: false,
  flightSummary: false,
  flightItinerary: false,
  offersFilters: false,
  data: null
}

const reducer = handleActions(
  {
    [toggleFlightSummary]: (state, { payload: { visible } }) => ({
      ...state,
      visible,
      flightSummary: visible,
      data: null
    }),
    [toggleFlightItinerary]: (state, { payload: { visible } }) => ({
      ...state,
      visible,
      flightItinerary: visible,
      data: null
    }),
    [toggleOffersFilters]: (state, { payload: { visible, filter } }) => ({
      ...state,
      visible,
      offersFilters: visible,
      data: filter
    })
  },
  defaultState
)

export default reducer
