import { createActions } from 'redux-actions'
import axios from 'axios'
import CONFIG from 'config'

import apiRoutes from 'constants/apiRoutes'
import { getLSValue } from 'helpers/localStorage'
import { LogOut } from 'helpers/flightOffers'

const {
  redeemPointsStart,
  redeemPointsSuccess,
  redeemPointsError,
  clearRedeemPoints
} = createActions({
  REDEEM_POINTS_START: () => {},
  REDEEM_POINTS_SUCCESS: data => ({ data }),
  REDEEM_POINTS_ERROR: error => ({ error }),
  CLEAR_REDEEM_POINTS: () => {}
})

const postRedeemPoints = (memberShipCode, payload) => async dispatch => {
  dispatch(redeemPointsStart())
  try {
    const response = await axios.post(
      `${apiRoutes.AR_PLUS_REDEEM_POINTS(memberShipCode)}`,
      payload,
      {
        baseURL: CONFIG.API_BASE_URL,
        headers: {
          Authorization: `Bearer ${getLSValue('access_token')}`,
          'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID,
          'Accept-Language': CONFIG.DEFAULT_LOCALE
        }
      }
    )

    dispatch(redeemPointsSuccess(response.data))
  } catch (error) {
    const err = error.response ? error.response.data : error

    if (err?.statusCode === 401) {
      LogOut()
      dispatch(clearRedeemPoints())
    }
    dispatch(redeemPointsError(err))
  }
}

export {
  postRedeemPoints,
  redeemPointsStart,
  redeemPointsSuccess,
  redeemPointsError,
  clearRedeemPoints
}
