import { createActions } from 'redux-actions'

import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import moment from 'moment'
import FORMATS from 'constants/formats'

const {
  getClaimDocumentTypeStart,
  getClaimDocumentTypeSuccess,
  getClaimDocumentTypeError,
  getClaimDocumentTypeClear,
  postReservationAndServiceStart,
  postReservationAndServiceSuccess,
  postReservationAndServiceError,
  postReservationAndServiceClear,
} = createActions({
  GET_CLAIM_DOCUMENT_TYPE_START: () => {},
  GET_CLAIM_DOCUMENT_TYPE_SUCCESS: data => ({data}),
  GET_CLAIM_DOCUMENT_TYPE_ERROR: error => ({error}),
  GET_CLAIM_DOCUMENT_TYPE_CLEAR: () => {},
  POST_RESERVATION_AND_SERVICE_START: () => {},
  POST_RESERVATION_AND_SERVICE_SUCCESS: data => ({data}),
  POST_RESERVATION_AND_SERVICE_ERROR: error => ({error}),
  POST_RESERVATION_AND_SERVICE_CLEAR: () => {},
})

const postReservationAndService = (route, formikFields) => async dispatch => {
  dispatch(postReservationAndServiceStart())
  try {
    formikFields.flightDate = moment(formikFields.flightDate).format(FORMATS.DAY_MONTH_YEAR_DASH)
    const response = await api.post(route, formikFields)
    dispatch(postReservationAndServiceSuccess(response.data || true))
  } catch (error) {
    dispatch(postReservationAndServiceError(error.response ? error.response.data : error))
  }
}

const getDocumentType = (route) => async dispatch => {
  dispatch(getClaimDocumentTypeStart())
  try {
    const response = await api.get(route)
    dispatch(getClaimDocumentTypeSuccess(response.data))
  } catch (error) {
    dispatch(getClaimDocumentTypeError(error.response ? error.response.data : error))
  }
}

const requestReservationAndService = formikFields => async dispatch => {
  dispatch(postReservationAndService(apiRoutes.CLAIM_RESERVATION, formikFields))
}

const fetchDocumentType = () => async dispatch => {
  dispatch(getDocumentType(apiRoutes.CLAIM_RESERVATION))
}

const clearFormik = () => async dispatch => {
  dispatch(getClaimDocumentTypeClear())
  dispatch(postReservationAndServiceClear())
}

export {
  requestReservationAndService,
  fetchDocumentType,
  clearFormik,
  getClaimDocumentTypeStart,
  getClaimDocumentTypeSuccess,
  getClaimDocumentTypeError,
  getClaimDocumentTypeClear,
  postReservationAndServiceStart,
  postReservationAndServiceSuccess,
  postReservationAndServiceError,
  postReservationAndServiceClear,
}
