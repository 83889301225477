/* eslint-disable quotes */
import moment from 'moment'

import FORMATS from 'constants/formats'

export const mapCacForm = ({
  suggestion,
  experiencePlace,
  lastName,
  name,
  documentType,
  documentNumber,
  email,
  phoneNumber,
  remarks,
  about,
  partnerTypeArPlus,
  partnerNumberArPlus,
  partnerTypeSkyTeam,
  partnerNumberSkyTeam,
  conflictScale,
  flightNumber,
  companySkyTeam,
  company,
  flightType,
  flightDate,
  origin,
  destiny,
  cabin,
  ticketNumber,
  hyperVulnerablePassenger,
  passengers,
  attachmentsB64,
  whatsappContact
}) => ({
  claimClassId: parseInt(suggestion),
  experiencePlaceId: parseInt(experiencePlace),
  claimReasonId: about ? parseInt(about) : 1,
  passengerLastName: lastName,
  passengerName: name,
  documentTypeId: parseInt(documentType),
  documentNumber: documentNumber,
  passengerEmail: email,
  passengerPhone: phoneNumber,
  remarks: remarks,
  arPlusMemberTypeId: partnerTypeArPlus ? parseInt(partnerTypeArPlus) : 0,
  arPlusMemberId: partnerNumberArPlus,
  skyteamMemberTypeId: partnerTypeSkyTeam ? parseInt(partnerTypeSkyTeam) : 0,
  skyteamMemberId: partnerNumberSkyTeam,
  flightNumber: parseInt(flightNumber),
  flightDate: moment(flightDate).format(FORMATS.DAY_MONTH_YEAR_DASH),
  conflictScaleId: parseInt(conflictScale),
  companySkyteamMemberId: companySkyTeam ? parseInt(companySkyTeam) : 0,
  companyId: company ? parseInt(company) : 0,
  flightTypeId: flightType ? parseInt(flightType) : 0,
  originId: origin ? parseInt(origin) : 0,
  destinationId: destiny ? parseInt(destiny) : 0,
  cabinId: cabin ? parseInt(cabin) : 0,
  hyperVulnerablePassengerId: hyperVulnerablePassenger
    ? parseInt(hyperVulnerablePassenger)
    : hyperVulnerablePassenger,
  ticketNumber: ticketNumber,
  passengerWhatsapp: whatsappContact,
  passengers: (passengers || []).map(
    ({
      lastName,
      name,
      ticketNumber,
      documentType,
      documentNumber,
      partnerTypeArPlus,
      partnerNumberArPlus,
      partnerTypeSkyTeam,
      partnerNumberSkyTeam,
      companySkyTeam,
      hyperVulnerablePassenger
    }) => ({
      keyEncrypted: null,
      claimId: 0,
      key: '',
      passengerName: name,
      passengerLastName: lastName,
      ticketNumber: ticketNumber,
      documentTypeId: parseInt(documentType),
      documentNumber: documentNumber,
      arPlusMemberTypeId: partnerTypeArPlus ? parseInt(partnerTypeArPlus) : 0,
      arPlusMemberId: partnerNumberArPlus,
      skyteamMemberTypeId: partnerTypeSkyTeam ? parseInt(partnerTypeSkyTeam) : 0,
      skyteamMemberId: partnerNumberSkyTeam,
      companySkyteamMemberId: companySkyTeam ? parseInt(companySkyTeam) : 0,
      hyperVulnerablePassengerId: hyperVulnerablePassenger
        ? parseInt(hyperVulnerablePassenger)
        : hyperVulnerablePassenger,
      number: 0
    })
  ),
  attachments: parseAttachment(attachmentsB64)
})

export const mapEquipamentClaim = ({
  origin,
  destiny,
  flightNumber,
  flightDate,
  company,
  lastName,
  name,
  documentType,
  documentNumber,
  email,
  phoneNumber,
  partnerTypeArPlus,
  partnerNumberArPlus,
  partnerTypeSkyTeam,
  partnerNumberSkyTeam,
  remarks,
  baggage,
  attachmentsB64
}) => ({
  keyEncrypted: null,
  claimId: null,
  claimReasonId: null,
  arPlusMemberTypeId: partnerTypeArPlus ? parseInt(partnerTypeArPlus) : 0,
  arPlusMemberId: partnerNumberArPlus,
  skyteamMemberTypeId: partnerTypeSkyTeam ? parseInt(partnerTypeSkyTeam) : 0,
  skyteamMemberId: partnerNumberSkyTeam,
  originId: parseInt(origin),
  destinationId: parseInt(destiny),
  flightNumber: parseInt(flightNumber),
  flightDate: moment(flightDate).format(FORMATS.DAY_MONTH_YEAR_DASH),
  companySkyteamMemberId: company ? parseInt(company) : 0,
  passengerLastName: name,
  passengerName: lastName,
  documentTypeId: parseInt(documentType),
  documentNumber: documentNumber,
  passengerEmail: email,
  passengerPhone: phoneNumber,
  remarks: remarks,
  baggage: parseBaggage(baggage),
  attachments: parseAttachment(attachmentsB64)
})

const parseAttachment = attachments =>
  (attachments || []).map(({ file: { name } }) => ({
    keyEncrypted: 0,
    attachmentClaimId: 0,
    claimId: 0,
    description: '',
    documentName: name
  }))

const parseBaggage = baggages =>
  baggages.map(({ irregularity, luggageType, claimNumber, amountLuggage, baggageCheckNumber }) => ({
    keyEncrypted: null,
    claimedBaggageId: 0,
    claimId: 0,
    damageDescription: '',
    damageTypeId: parseInt(irregularity),
    baggageType: parseInt(luggageType),
    pir: claimNumber,
    baggageQuantity: parseInt(amountLuggage),
    baggageVoucherNumber: baggageCheckNumber
  }))
