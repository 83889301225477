export const notificationsPayload = ({ adt, chd, inf, flightType, cabinClass, legs }) => {
  const routes = (legs || []).map(({ origin, destination }) => ({ origin, destination }))

  return {
    flightType,
    cabinClass,
    adtCount: parseInt(adt, 10),
    chdCount: parseInt(chd, 10),
    infCount: parseInt(inf, 10),
    routes
  }
}
