import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { mapMediaType } from 'selectors/mediaType'

import { isDesktopOrWide } from 'helpers/utils'

import Link from 'components/hybrid-link'

import {
  Container,
  Column,
  Icon,
  StyledSection,
  StyledLabel,
  LabelText,
  StyledLink,
  StyledAnchor,
  Separator,
  LinkWrapper
} from './styled'

const HeaderMenu = props => {
  const [expanded, setExpanded] = useState(null)

  const handleExpandItem = id => {
    setExpanded(prevState => (prevState === id ? false : id))
  }

  return (
    <Container>
      {(props.columns || []).map((item, index) => (
        <Column key={index}>
          {index % 4 !== 0 && <Separator />}
          <StyledSection>
            <StyledLabel onClick={() => handleExpandItem(item.id)}>
              <Icon src={item.icon} />
              <LabelText>{item.header}</LabelText>
            </StyledLabel>
            {(expanded === item.id || props.isDesktop) && (
              <LinkWrapper>
                {(item.items || []).map(
                  ({ name, link, newTab }, i) =>
                    (props.isDesktop || expanded === item.id) && (
                      <Link
                        key={`${link}--${i}`}
                        pathTo={link || ''}
                        target={newTab ? '_blank' : '_self'}
                        AnchorComponent={StyledAnchor}
                        LinkComponent={StyledLink}
                      >
                        {name}
                      </Link>
                    )
                )}
              </LinkWrapper>
            )}
          </StyledSection>
        </Column>
      ))}
    </Container>
  )
}

const mapStateToProps = state => ({
  isDesktop: isDesktopOrWide(mapMediaType(state))
})

HeaderMenu.propTypes = {
  columns: PropTypes.array.isRequired,
  isDesktop: PropTypes.bool
}

export default React.memo(connect(mapStateToProps)(HeaderMenu))
