import { handleActions } from 'redux-actions'
import {
  corporateCredentialRecoveryStart,
  corporateCredentialRecoverySuccess,
  corporateCredentialRecoveryError,
  corporateCredentialRecoveryClear
} from 'actions/corporate/credentialsRecovery'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const corporateRecoveryReducer = handleActions(
  {
    [corporateCredentialRecoveryStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [corporateCredentialRecoverySuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data || {}, // Response from api is empty, and could always be that way.
      error: null,
      isFetching: false
    }),
    [corporateCredentialRecoveryError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [corporateCredentialRecoveryClear]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default corporateRecoveryReducer
