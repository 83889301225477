import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { apiSublos as api } from 'services/api'

const {
  sublosBenefitTypesStart,
  sublosBenefitTypesSuccess,
  sublosBenefitTypesError,
  sublosBenefitTypesClear
} = createActions({
  SUBLOS_BENEFIT_TYPES_START: () => {},
  SUBLOS_BENEFIT_TYPES_SUCCESS: data => ({ data }),
  SUBLOS_BENEFIT_TYPES_ERROR: error => ({ error }),
  SUBLOS_BENEFIT_TYPES_CLEAR: () => {}
})

const fetchSublosBenefitTypes = ({
  companyCode,
  employeeCode,
  passengerType
}) => async dispatch => {
  dispatch(sublosBenefitTypesStart())
  try {
    const response = await api.get(
      apiRoutes.SUBLOS_GET_BENEFIT_TYPES({ companyCode, employeeCode, passengerType })
    )
    dispatch(sublosBenefitTypesSuccess({ benefitTypes: response.data, type: passengerType }))
  } catch (error) {
    dispatch(sublosBenefitTypesError(error.response ? error.response.data : error))
  }
}

export {
  fetchSublosBenefitTypes,
  sublosBenefitTypesClear,
  sublosBenefitTypesSuccess,
  sublosBenefitTypesError,
  sublosBenefitTypesStart
}
