import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { apiSublos as api } from 'services/api'

const {
  sublosNoNameConditionsStart,
  sublosNoNameConditionsSuccess,
  sublosNoNameConditionsError,
  sublosNoNameConditionsClear
} = createActions({
  SUBLOS_NO_NAME_CONDITIONS_START: () => {},
  SUBLOS_NO_NAME_CONDITIONS_SUCCESS: data => ({ data }),
  SUBLOS_NO_NAME_CONDITIONS_ERROR: error => ({ error }),
  SUBLOS_NO_NAME_CONDITIONS_CLEAR: () => {}
})

const fetchSublosNoNameConditions = ({
  companyCode,
  employeeCode,
  passengerType,
  benefitType,
  ticketType
}) => async dispatch => {
  dispatch(sublosNoNameConditionsStart())
  try {
    const response = await api.get(
      apiRoutes.SUBLOS_GET_NO_NAME_CONDITIONS({
        companyCode,
        employeeCode,
        passengerType,
        benefitType,
        ticketType
      })
    )
    dispatch(sublosNoNameConditionsSuccess(response.data))
  } catch (error) {
    dispatch(sublosNoNameConditionsError(error.response ? error.response.data : error))
  }
}

export {
  fetchSublosNoNameConditions,
  sublosNoNameConditionsClear,
  sublosNoNameConditionsSuccess,
  sublosNoNameConditionsError,
  sublosNoNameConditionsStart
}
