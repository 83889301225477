/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect'

import GDS_MESSAGES from 'constants/gdsMessages'
import { isEmpty } from 'helpers/utils'
import { isFetchingAirlines } from 'selectors/airlines'
import { isFetchingBrands } from 'selectors/brands'
import { isFetchingCabins } from 'selectors/cabins'
import { isFetchingCountries } from 'selectors/countries'
import { isFetchingDocumentTypesRules } from 'selectors/documentTypesRules'
import { isFetchingPaymentOptions, getPaymentOptionsData } from 'selectors/paymentOptions'

const getExchangesPaymentState = state => state.exchangesPayment

export const getExchangesPaymentError = createSelector(
  [getExchangesPaymentState],
  exchangesPayment => (exchangesPayment ? exchangesPayment.error : null)
)

export const isFetchingExchangesPayment = createSelector(
  [getExchangesPaymentState],
  exchangesPayment => (exchangesPayment ? exchangesPayment.isFetching : false)
)

export const getExchangesPaymentData = createSelector(
  [getExchangesPaymentState],
  exchangesPayment => (exchangesPayment ? exchangesPayment.data : null)
)

export const getExchangesValidationDetails = createSelector([getExchangesPaymentError], error =>
  error && !isEmpty(error.validationDetails) ? error.validationDetails : null
)

export const isFetchingData = createSelector(
  [
    isFetchingPaymentOptions,
    getPaymentOptionsData,
    isFetchingBrands,
    isFetchingCabins,
    isFetchingAirlines,
    isFetchingCountries,
    isFetchingDocumentTypesRules
  ],
  (
    fetchingPaymentOptions,
    paymentOptions,
    fetchingBrands,
    fetchingCabins,
    fetchingAirlines,
    fetchingCountries,
    isFetchingDocumentTypesRules
  ) => {
    const conditions = [
      fetchingBrands,
      isEmpty(paymentOptions),
      fetchingCabins,
      fetchingAirlines,
      fetchingCountries,
      fetchingPaymentOptions,
      isFetchingDocumentTypesRules
    ]
    return conditions.some(condition => condition)
  }
)

export const getExchangeConfirmation = createSelector(
  [getExchangesPaymentData],
  exchangesPaymentData => {
    if (!isEmpty(exchangesPaymentData)) {
      const { passengersData, legs, bookingMetadata, payments } = exchangesPaymentData
      return {
        passengersData,
        legs,
        bookingMetadata,
        payments
      }
    }
  }
)

export const getExchangesPayment = createSelector([getExchangesPaymentData], data =>
  !isEmpty(data) ? data.payments : null
)

export const getPurchasePayment = createSelector([getExchangesPayment], payments =>
  payments ? payments[0] : null
)

export const getPurchaseGlobalDetails = createSelector([getPurchasePayment], payment =>
  payment ? payment.globalInformation : null
)

export const getRedirectForm = createSelector(
  [getPurchaseGlobalDetails],
  globalDetails => globalDetails && globalDetails.redirectForm
)

export const getRedirectType = createSelector(
  [getPurchaseGlobalDetails],
  globalDetails => globalDetails?.redirectType
)

export const getExchangesPaymentAmount = createSelector([getExchangesPayment], payment =>
  !isEmpty(payment) ? payment.amount : ''
)

export const getExchangesPaymentCurrency = createSelector([getExchangesPayment], payment =>
  !isEmpty(payment) ? payment.currency : ''
)

export const getExchangesPaymentErrorCode = createSelector([getExchangesPaymentError], error =>
  error ? error.statusCode : null
)

export const isExchanges500Error = createSelector(
  [getExchangesPaymentErrorCode],
  exchangesPaymentErrorCode => exchangesPaymentErrorCode === 500
)

export const getExchangesPaymentGeneralRequestError = createSelector(
  [getExchangesValidationDetails, getExchangesPaymentError],
  (errorDetail, paymentError) =>
    errorDetail && !isEmpty(errorDetail?.GENERAL_REQUEST)
      ? errorDetail?.GENERAL_REQUEST
      : paymentError?.errorMessage
)

const getExchangesPaymentAuthorization = createSelector([getExchangesPaymentError], error =>
  error ? error.authorization : null
)

export const getExchangesPaymentAuthorizationDetails = createSelector(
  [getExchangesPaymentAuthorization],
  authorization => (authorization ? authorization.details : null)
)

export const getExchangesPaymentAuthorizationErrorMessage = createSelector(
  [getExchangesPaymentError],
  error =>
    error &&
    error.statusCode === 400 &&
    error.errorMessage === GDS_MESSAGES.PAYMENT_ERROR_AUTH_FAILURE &&
    error.errorMessage
)

const getExchangesBookingMetadata = createSelector(
  [getExchangesPaymentData],
  data => data && data.bookingMetadata
)

const getExchangesBookingMetadataWarnMessages = createSelector(
  [getExchangesBookingMetadata],
  bookingMetadata => bookingMetadata && bookingMetadata.warnMessages
)

export const isExchangesAncillariesWarning = createSelector(
  [getExchangesBookingMetadataWarnMessages],
  warnMessages => warnMessages && warnMessages.includes(GDS_MESSAGES.EXCHANGES_ANCILLARIES_WARNING)
)

export const getIsEvenFlow = createSelector([getExchangesPaymentData], exchangesPaymentData =>
  exchangesPaymentData ? exchangesPaymentData.evenFlow : null
)
