import styled from 'styled-components'

import mediaQueries from 'components/media-queries'

export const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  outline: none;
  height: 40px;

  ${mediaQueries.mobile`
    height: 40px;
  `};
`
