import styled from 'styled-components'

import mediaQueries from 'components/media-queries'
import e2eClasses from 'helpers/automation'

export const Container = styled.div.attrs(() => ({
  className: e2eClasses('footer.social.box')
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em;

  ${({ showFooterCollapsible }) =>
    showFooterCollapsible
      ? `
        max-width: 30%;
      `
      : /*old footer*/ `
        flex-direction: column;
        background-color: ${({ theme }) => theme.colors.grayLight};
        width: 100%;
      `}
  ${mediaQueries.mobile`
    max-width: 100%;
    width: 70%;
  `};
`

export const Title = styled.div.attrs(() => ({
  className: e2eClasses('footer.social.itemTitle')
}))`
  color: ${({ theme }) => theme.colors.doveGrayer};

  ${({ showFooterCollapsible, theme }) =>
    showFooterCollapsible
      ? /*new footer*/ `
        font-size: 14px;
        font-family: ${theme.fonts.regular};
        `
      : /*old footer*/ `
        font-family: ${theme.fonts.semiBold};
        text-align: center;
        margin-bottom: 1em;
        font-size: 1em;
  `};
`

export const LinksContainer = styled.div.attrs(() => ({
  className: e2eClasses('footer.social.itemsBox')
}))`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ showFooterCollapsible }) =>
    showFooterCollapsible
      ? /*new footer*/ `
      justify-content: space-around;
      `
      : /*old footer*/ `
      justify-content: center;
  `};
`

export const Link = styled.a.attrs(() => ({
  className: e2eClasses('footer.social.itemOption')
}))`
  filter: invert(47%) sepia(1%) saturate(2894%) hue-rotate(23deg) brightness(85%) contrast(91%);
  ${({ showFooterCollapsible }) =>
    showFooterCollapsible
      ? /*new footer*/ `
      margin: 0px 3px;
      `
      : /*old footer*/ `
      margin: 0px 20px;
  `};
`
export const Image = styled.img`
  max-height: 22px;
  max-width: 25px;
  filter: saturate(0%);
  height: 24px;
`
