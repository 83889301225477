import styled from 'styled-components'

export const AnimationContainer = styled.div`
  pointer-events: none;
  display: flex;
  justify-content: center;
  position: relative;
`

export const LogoContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 60px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.blue50};

  display: flex;
  justify-content: center;
  align-items: center;
`

export const Img = styled.img`
  width: 50px;
  height: 50px;
`
