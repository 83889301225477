import { createSelector } from 'reselect'

const getState = state => state.mobileFullViews

export const getMobileFullViewsFlightSummary = createSelector(
  [getState],
  state => (state ? state.flightSummary : false)
)

export const getMobileFullViewsFlightItinerary = createSelector(
  [getState],
  state => (state ? state.flightItinerary : false)
)

export const getMobileFullViewsCompareConditions = createSelector(
  [getState],
  state => (state ? state.compareConditions : false)
)

export const getMobileFullViewsOffersFilters = createSelector(
  [getState],
  state => (state ? state.offersFilters : false)
)

export const getMobileFullViewsData = createSelector(
  [getState],
  state => state.data
)

export const isMobileFullViewsVisible = createSelector(
  [getState],
  state => (state ? state.visible : false)
)
