import { handleActions } from 'redux-actions'

import { fetchCitiesStart, fetchCitiesSuccess, fetchCitiesError } from 'actions/catalog/cities'

const stateDefault = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchCitiesStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchCitiesSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [fetchCitiesError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    })
  },
  stateDefault
)

export default reducer
