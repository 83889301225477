import { createSelector } from 'reselect'

import { isEmpty } from 'helpers/utils'

const getCabinsState = state => state.cabins

export const getCabinsData = createSelector(
  [getCabinsState],
  cabins => (cabins ? cabins.data : null)
)

export const isFetchingCabins = createSelector(
  [getCabinsState, getCabinsData],
  (cabins, cabinsData) => cabins.isFetching || isEmpty(cabinsData)
)

export const getCabins = createSelector([getCabinsData], cabins => (cabins ? cabins.resources : []))
