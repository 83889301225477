import { handleActions } from 'redux-actions'
import {
  fetchPunctualityIndexStart,
  fetchPunctualityIndexSuccess,
  fetchPunctualityIndexError
} from 'actions/flightSummary'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchPunctualityIndexStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchPunctualityIndexSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [fetchPunctualityIndexError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    })
  },
  defaultState
)

export default reducer
