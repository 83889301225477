import styled from 'styled-components'

import mediaQueries from 'components/media-queries'
export const headerHeightDesktop = `height: 80px;`
export const headerHeightMobile = `height: 60px;`

export const FixedContainer = styled.div`
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow.black30};
  background-color: ${({ theme }) => theme.colors.gray10};
  z-index: ${props => (props.homePage ? '4' : '0')};
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const MainContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.black30};
  max-width: 1200px;
  width: 100%;
  ${headerHeightDesktop}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;

  ${mediaQueries.tablet`
    padding: 0 65px;
  `};

  ${mediaQueries.mobile`
    ${headerHeightMobile}
    padding: 0 15px;
  `};
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  ${headerHeightDesktop}

  ${mediaQueries.mobile`
    ${headerHeightMobile}
  `};
`

export const UserContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);

  ${mediaQueries.tablet`
    box-shadow: none;
    background-color: transparent;
  `};
  ${mediaQueries.mobile`
    box-shadow: none;
    background-color: transparent;
  `};
`

export const InfoContainer = styled.div`
  padding-left: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${mediaQueries.tablet`
    display: none;
  `};
  ${mediaQueries.mobile`
    display: none;
  `};
`

export const GreetingText = styled.p`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.blue50};
`

export const NameText = styled.p`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  color: ${({ theme }) => theme.colors.blue50};
  font-size: ${({ pointsFontSize }) => `${pointsFontSize / 2.5}px`};
  text-transform: capitalize;
`

export const ExpandableMenu = styled.div`
  width: 160px;
  z-index: 20;
  background-color: white;
  display: block;
  position: absolute;
  overflow: hidden;
  right: '10%';
  top: 75px;
  border: 0px solid;
  border-top-width: 5px;
  border-radius: 3px;
  border-color: ${({ theme }) => theme.colors.blue50};
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  elevation: 1;
  cursor: default;

  ${mediaQueries.mobile`
    top: 55px;
  `};
`

export const CloseContainer = styled.div`
  padding: 15px;
  cursor: pointer;
`

export const VersionContainer = styled.div`
  padding: 15px;
`

export const CloseText = styled.p`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.doveGray};
`

export const VersionText = styled.p`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.doveGray};
`
export const Divider = styled.hr`
  margin: 0 15px;
  border: 1px solid ${({ theme }) => theme.colors.alto};
`
