export const searchBoxFlights = {
  from: 'sbf-from',
  fromTo: 'sbf-from-to',
  multidestiny: 'sbf-multidestiny',
  flexDates: 'sbf-flex-dates',
  awardBooking: 'sbf-award-booking'
}

export const stickyFooter = {
  box: 'sf-box',
  title: 'sf-title',
  showDetails: 'sf-show-details',
  passengersBox: 'sf-passengers-box',
  passengers: 'sf-passengers',
  passengersDesc: 'sf-passengers-description',
  fromtoBox: 'sf-fromto-box',
  fromtoTitle: 'sf-fromto-title',
  fromto: 'sf-fromto',
  expensesBox: 'sf-expenses-box',
  faresTitle: 'sf-fare-title',
  fares: 'sf-fare',
  icon: 'sf-fare-icon',
  base: 'sf-base',
  taxes: 'sf-taxes',
  ancillary: 'sf-ancillary',
  charges: 'sf-charges',
  totalBox: 'sf-total-box',
  itemFare: 'sf-item',
  totalTitle: 'sf-total-title',
  total: 'sf-total',
  loader: 'sf-img-loader',
  buyButton: 'sf-buy',
  closeButton: 'sf-close'
}

export const calendar = {
  message: 'fdc-message',
  fromBox: 'fdc-from-box',
  toBox: 'fdc-to-box',
  month: 'fdc-month',
  dayOfWeek: 'fdc-day-of-Week',
  unavailableDayButton: 'fdc-unavailable-day',
  availableDayButton: 'fdc-available-day',
  emptyDayButton: 'fdc-empty-day',
  iconTicketStar: 'fdc-icon-ticket-star',
  iconTicketPercent: 'fdc-icon-ticket-percent',
  buttonDay: 'fdc-button-day',
  buttonPrice: 'fdc-button-price',
  buttonCurrency: 'fdc-button-currency',
  buttonText: 'fdc-button-text',
  calendarReference: 'fdc-reference',
  referenceTitle: 'fdc-reference-title',
  referenceBestPrice: 'fdc-reference-best-price',
  referenceBestDiscount: 'fdc-reference-best-discount',
  referenceBestInstallments: 'fdc-reference-best-installments',
  referenceText: 'fdc-reference-text'
}

export const stickyFooterFlexCalendar = {
  box: 'sffc-box',
  legBox: 'sffc-',
  type: 'sffc-type',
  fromTo: 'sffc-from-to',
  date: 'sffc-date',
  price: 'sffc-price',
  totalTitle: 'sffc-total-by-passenger',
  total: 'sffc-total',
  viewFlightsButton: 'sffc-view-flights'
}

export const header = {
  header: '-box',
  icon: '-icon',
  type: '-type',
  title: '-title'
}

export const sFDetails = {
  detailsBox: 'sf-details-box',
  itineraryTitle: 'sf-itinerary-title',
  legBox: 'sf-leg-box-',
  legIcon: 'sf-leg-icon',
  legType: 'sf-leg-type',
  legDate: 'sf-leg-date',
  reservation: 'sf-rs',
  reservationDetail: 'sf-rsd',
  itineraryBox: 'sf-itinerary-box',
  socialBox: 'sf-social-box'
}

export const itinerary = {
  box: 'itinerary-box',
  titleBox: 'itinerary-title-box',
  type: 'itinerary-type',
  date: 'itinerary-date',
  reservation: 'itinerary-reservation',
  reservationDetails: 'itinerary-reservation-details',
  detailsBox: 'itinerary-details-box',
  fromToBox: 'itinerary-from-to-box',
  when: 'itinerary-when',
  hour: 'itinerary-hour',
  airport: 'itinerary-airport',
  airportCity: 'itinerary-city',
  duration: 'itinerary-duration',
  airline: 'itinerary-airline',
  info: 'itinerary-info',
  scaleDetailsBox: 'itinerary-details-scale',
  verticalLine: 'itinerary-scale-vertical-line',
  horizontalLine: 'itinerary-scale-horizontal-line',
  dot: 'itinerary-dot',
  graphBox: 'itinerary-graph-box',
  scaleBox: 'itinerary-scale-box',
  scaleInfo: 'itinerary-scale-info',
  scaleDuration: 'itinerary-scale-duration',
  scaleHour: 'itinerary-scale-Hour',
  scaleAirport: 'itinerary-scale-airport'
}

export const alternateOffersMatrix = {
  box: 'aom-box',
  outboundTitle: 'aom-outbound-title',
  inboundTitle: 'aom-inbound-title',
  inbound: 'aom-inbound',
  outbound: 'aom-outbound',
  buttonsBox: 'aom-buttons-box',
  button: 'aom-button',
  voidButton: 'aom-void-button',
  buttonIcon: 'aom-button-icon',
  buttonValue: 'aom-button-value',
  buttonCurrency: 'aom-button-currency'
}

export const oops = {
  box: 'oops-box',
  img: 'oops-img',
  title: 'oops-title',
  subTitle: 'oops-sub-title',
  messageTitle: 'oops-message-title',
  message: 'oops-message',
  button: 'oops-back',
  secondaryButton: 'oops-secondary-back'
}

export const sublosErrors = {
  box: 'sublos-error-box',
  img: 'sublos-error-img',
  title: 'sublos-error-title',
  subTitle: 'sublos-error-sub-title',
  messageTitle: 'sublos-error-message-title',
  message: 'sublos-error-message',
  button: 'sublos-error-back',
  secondaryButton: 'sublos-error-secondary-back'
}

export const searchBox = {
  origin: 'sb-origin',
  destiny: 'sb-destination',
  tOrigin: 't-origin',
  tDestiny: 't-destination',
  container: 'sb-container-box',
  reserveBox: 'sb-reserve-box',
  reserveCodeBox: 'sb-reserve-code-box',
  reserveCode: 'sb-reserve-code',
  reserveCodeTitle: 'sb-reserve-code-title',
  reserveCodeError: 'sb-reserve-code-error',
  reserveErrorBox: 'sb-reserve-error-box',
  reserveErrorImage: 'sb-reserve-error-image',
  reserveErrorMessage: 'sb-reserve-error-message',
  reserveErrorTitle: 'sb-reserve-error-title',
  reservePassengerBox: 'sb-reserve-passenger-box',
  reservePassenger: 'sb-reserve-passenger',
  reservePassengerTitle: 'sb-reserve-passenger-title',
  reservePassengerError: 'sb-reserve-passenger-error',
  reserveSearchButton: 'sb-reserve-search',
  reserveFiscalCreditLink: 'sb-reserve-fiscal-credit-link',
  reserveReturnsLink: 'sb-reserve-returns-link',
  reserveSuggestionsLink: 'sb-reserve-suggestions-link',
  passenger: 'passenger',
  checkInBox: 'sb-check-in-box',
  checkInCodeBox: 'sb-check-in-code-box',
  checkInCodeTitle: 'sb-check-in-code-title',
  checkInCode: 'sb-check-in-code',
  checkInPassengerBox: 'sb-check-in-passenger-box',
  checkInPassengerTitle: 'sb-check-in-passenger-title',
  checkInPassenger: 'sb-check-in-passenger',
  checkInSearchButton: 'sb-check-in-search',
  statusBox: 'sb-status-box',
  statusOptionNumber: 'sb-status-number',
  statusOptionRoute: 'sb-status-route',
  statusFlightRouteOriginTitle: 'sb-status-flight-route-origin-title',
  statusFlightRouteDestinyTitle: 'sb-status-flight-route-destiny-title',
  statusConsultButton: 'sb-status-consult',
  statusFlightNumber: 'sb-status-flight-number',
  statusFlightNumberTitle: 'sb-status-flight-number-title',
  conditionsButton: 'sb-conditions-button',
  code: 'sb-code',
  events: 'sb-events'
}

export const datePicker = {
  container: 'date-picker-Container',
  title: 'date-picker-title',
  error: 'dates-error'
}

export const fareConditions = {
  box: 'fc-box',
  title: 'fc-title',
  headerBox: 'fc-header-box',
  type: 'fc-type',
  fromTo: 'fc-from-to',
  separator: 'fc-line-separator',
  referenceBox: 'fc-reference-box',
  referenceItem: 'fc-reference-item',
  referenceTitle: 'fc-reference-brand',
  referenceTextItem: 'fc-reference-text',
  referenceIconItem: 'fc-reference-icon',
  extraInfoBox: 'fc-extra-info-box',
  extraInfoTitle: 'fc-extra-info-title',
  extraInfoList: 'fc-extra-info-ul'
}

export const compareConditions = {
  box: 'cc-box',
  rowTitle: 'cc-row-title',
  rowBox: 'cc-row-box',
  rowContentBox: 'cc-row-content-box',
  rowItem: 'cc-row-item'
}

export const payments = {
  title: 'p-collapsible',
  paymentBox: 'p-box',
  paymentMethodHeader: 'p-header-payment-method',
  paymentMethodType: 'p-type-payment-method',
  paymentMethodNote: 'p-note-payment-method',

  paymentMethodSection: 'p-section-payment-method',
  paymentMethodListBox: 'p-payments-methods-box',
  dues: 'p-dues-method',
  paymentMethodMoreOption: 'p-more-option-payment-method',
  paymentMethod: 'p-payment-method',

  purchaseReserveBox: 'p-purchase-reserve-box',
  purchaseReserveTitle: 'p-purchase-reserve-title',
  purchaseReserveTotal: 'p-purchase-reserve-total',
  purchaseReserveText: 'p-purchase-reserve-text',

  purchaseBox: 'p-purchase',
  purchaseTitle: 'p-purchase-info-title',
  purchaseTotalTitle: 'p-purchase-total-title',
  purchaseTotal: 'p-purchase-total',
  purchaseViewDetails: 'p-purchase-view-details',
  purchaseDetailsBox: 'p-purchase-details-box',

  purchaseItineraryBox: 'p-purchase-itinerary',
  purchaseItineraryTitle: 'p-purchase-itinerary-title',
  purchaseViewItinerary: 'p-purchase-view-itinerary',
  purchaseItineraryItem: 'p-purchase-item',
  purchaseItineraryType: 'p-purchase-item-type',
  purchaseItineraryAirport: 'p-purchase-item-airport',
  purchaseItineraryDate: 'p-purchase-item-date',

  purchaseViewOthersFares: 'p-purchase-view-fares',

  paymentMethodSideBarTitle: 'pm-title',
  paymentMethodSideBarMethod: 'pm-method',
  paymentMethodSideBarCard: 'pm-card-number',
  paymentMethodSideBarCashIcon: 'pm-cash-number-icon',
  paymentMethodSideBarCashMessage: 'pm-cash-message',
  paymentMethodSideBarCashSpan: 'pm-cash-number-span',
  paymentMethodSideBarCashTotalLabel: 'pm-cash-total-label',
  paymentMethodSideBarCashTotalPrice: 'pm-cash-total-price',
  paymentMethodSideBarImg: 'pm-img',
  paymentMethodSideBarInstallmentsBox: 'pm-installments-box',
  paymentMethodSideBarInstallments: 'pm-installments',
  paymentMethodSideBarTotal: 'pm-total',
  paymentMethodSideBarChangeMethod: 'pm-change-method',

  pendingPaymentBox: 'pm-pending-payment-box',
  pendingPaymentTitle: 'pm-pending-payment-title',
  pendingPaymentCloseButton: 'pm-pending-payment-close-button',

  tabBar: 'p-tab-bar-box',
  tabBarItem: 'p-tab-bar-item'
}

export const paymentDetails = {
  box: 'pd-box',
  selectedType: 'pd-type-selected',
  selectedDueType: 'pd-due-type-selected',
  summary: 'pd-summary',
  summaryMethod: 'pd-method-summary',
  changeMethod: 'pd-change-method',
  formBox: 'pd-form-box',
  formTitle: 'pd-form-title',

  label: 'input-title-',
  input: 'input-',

  card: 'card',
  cardNumber: 'pd-card-number',
  cardExpiredTitle: 'pd-card-expired-title',
  cardExpiredMM: 'pd-expired-mm',
  cardExpiredYY: 'pd-expired-yy',
  cardExpiredBar: 'pd-expired-bar',
  cardExpired: 'pd-card-expired',
  cardCode: 'pd-code',
  cardName: 'pd-name',
  cardDocType: 'pd-doc-type',
  cardDoc: 'pd-doc',
  cardFiscal: 'pd-fiscal',
  cardCUIL: 'pd-cuil',
  cardCountry: 'pd-country',
  cardProvince: 'pd-province',
  cardCity: 'pd-city',
  cardAddress: 'pd-address',
  cardAddressNumber: 'pd-address-number',
  cardFloor: 'pd-floor',
  cardApartment: 'pd-apartment',
  cardPostal: 'pd-postal',
  cashFirstName: 'pdc-first-name',
  cashLastName: 'pdc-last-name',
  cashDocumentType: 'pdc-document-type',
  cashDocumentNumber: 'pdc-document-number',
  cashEmail: 'pdc-email',
  nextButton: 'pd-next-button',
  cancelButton: 'pd-cancel-button'
}

export const thankYou = {
  message: 'ty-message',
  tab: 'ty-tab',
  passenger: 'ty-passenger',
  passengerBox: 'ty-passenger-box',
  passengerTitle: 'ty-passenger-title',
  passengerTypeBox: 'ty-passenger-type-box',
  passengerType: 'ty-passenger-type',
  passengerName: 'ty-passenger-name',
  passengerDoc: 'ty-passenger-doc',
  passengerMsg: 'ty-passenger-msg',
  passengerLink: 'ty-passenger-link',
  itinerary: 'ty-itinerary',
  helpMsg: 'ty-help-message',
  helpBox: 'ty-help-box',
  helpIcon: 'ty-help-icon',
  helpTitle: 'ty-help-title',
  helpItem: 'ty-help-item'
}

export const helpCenter = {
  helpBox: 'hc-help-box',
  helpColumn: 'hc-help-column',
  helpIcon: 'hc-help-icon',
  helpItem: 'hc-help-item',
  helpSubItem: 'hc-help-sub-item',
  helpSubtitle: 'hc-help-subtitle',
  helpTitle: 'hc-help-title'
}

export const flightStatus = {
  banner: 'fs-header',
  headerBox: 'fs-header-box',
  contentBox: 'fs-content-box',
  resultBox: 'fs-result-box',
  logo: 'fs-airline-logo',
  name: 'fs-airline-name',
  flightNum: 'fs-airline-flight-number',
  timeFlight: 'fs-airline-time-flight',
  fromAirport: 'fs-from-airport',
  fromCity: 'fs-from-city',
  headerCode: 'fs-header-code',
  headerAirport: 'fs-header-airport',
  toAirport: 'fs-to-airport',
  toCity: 'fs-to-city',
  line: 'fs-from-to-line',
  plane: 'fs-plane-icon',
  hiddenStatus: 'fs-hidden-status',
  inTime: 'fs-in-time',
  statusBoxFrom: 'fs-status-from-box',
  statusBoxTo: 'fs-status-to-box',
  statusPlane: 'fs-status-plane',
  statusTitle: 'fs-status-title',
  statusHour: 'fs-status-hour',
  statusNewHour: 'fs-status-new-hour',
  statusDate: 'fs-status-date',
  statusNewDate: 'fs-status-new-date',
  statusSeparator: 'fs-status-separator',
  terminalTitle: 'fs-terminal-title',
  terminal: 'fs-terminal',
  doorTitle: 'fs-door-title',
  door: 'fs-door'
}

export const common = {
  logo: 'arsa-logo',
  button: 'button-',
  inputBox: 'input-box-',
  input: 'input-',
  inputTitle: 'input-title-',
  inputError: 'input-error-',
  inputIconError: 'input-icon-error-',
  checkbox: 'checkbox-',
  checkboxInput: 'checkbox-input-',
  checkboxTitle: 'checkbox-title-',
  radio: 'radio-',
  radioTitle: 'radio-title-',
  bannerImg: 'banner-img-',
  bannerTitle: 'banner-title-',
  bannerSubTitle: 'banner-sub-title-',
  label: 'label-',
  loader: 'transition-loader',
  option: 'option-',
  link: 'link-',
  alternateOfferSwitch: 'ao-show-switch',
  tabBox: 'tab-container-box',
  tabBar: 'tab-bar',
  tabPanel: 'tab-panel',
  tab: 'tab-item',
  stepBarBox: 'step-bar-box',
  step: 'step-bar',
  stepIcon: 'step-bar-icon',
  stepLine: 'step-bar-line',
  stepText: 'step-bar-text',
  sectionTitle: 'section-title-',
  addRoute: 'add-route',
  addRouteBox: 'add-route-box'
}

export const scheduledFlights = {
  banner: 'sfs-header',
  box: 'sfs-box',
  title: 'sfs-title',
  origin: 'sfs-origin',
  destiny: 'sfs-destiny',
  searchButton: 'sfs-search',

  gridBox: 'sfs-grid-box',
  flights: 'sfs-flights-header',
  days: 'sfs-days-header',
  headerDay: 'sfs-day-title',
  day: 'sfs-day',
  checked: 'sfs-checked-icon',

  itineraryBox: 'sfs-itinerary-box',
  airlineIcon: 'sfs-airline-icon',
  airlineCode: 'sfs-airline-code',
  airlineTooltip: 'sfs-tooltip',
  tooltipText: 'sfs-tooltip-text-line',
  fromBox: 'sfs-itinerary-from-box',
  toBox: 'sfs-itinerary-to-box',
  hour: 'sfs-itinerary-hour',
  airport: 'sfs-itinerary-airport',
  city: 'sfs-itinerary-city',
  stops: 'sfs-itinerary-stops',
  line: 'sfs-itinerary-line',
  plane: 'sfs-itinerary-plane'
}

export const reservation = {
  rsv: 'rsv',
  boxTitle: 'rsv-box-title',
  sidebarTitle: 'rsv-sidebar-title',
  title: 'rsv-title',
  boxContent: 'rsv-box-content',
  codeTitle: 'rsv-code-title',
  code: 'rsv-code'
}

export const installments = {
  box: 'ins-box',
  card: 'ins-card',
  description: 'ins-desc',
  total: 'ins-total',
  interestRate: 'ins-interest-rate',
  issuer: 'ins-issuer'
}

export const modal = {
  box: 'modal-box',
  title: 'modal-title',
  titleBox: 'modal-title-box',
  close: 'modal-close',
  text: 'modal-text',
  accept: 'modal-accept'
}

export const seatSelection = {
  referencesBox: 'ss-references-box',
  referencesTitle: 'ss-references-title',
  referencesClose: 'ss-references-close',
  referencesSubTitle: 'ss-references-subtitle',
  referenceItemBox: 'ss-reference-item-box',
  referenceItemIcon: 'ss-reference-item-icon',
  referenceItemName: 'ss-reference-item-name',
  box: 'ss-box',
  sectionHeaderBox: 'ss-section-header-box',
  sectionSubHeaderBox: 'ss-section-sub-header-box',
  sectionBox: 'ss-section-box',
  sectionIcon: 'ss-section-icon',
  sectionLeg: 'ss-section-leg',
  sectionCode: 'ss-section-code',
  sectionArrow: 'ss-section-arrow',
  sectionTitle: 'ss-section-title',
  sectionRow: 'ss-section-row',
  sectionName: 'ss-section-passenger-name',
  sectionSeat: 'ss-section-passenger-seat',
  sectionOption: 'ss-section-passenger-option',
  sectionSeatError: 'ss-section-unavailable',
  saveSelectionButton: 'ss-save-selection',
  linkDoItLater: 'ss-do-it-later',
  seatSlotsBox: 'ss-slot-box',
  seatSlotHeader: 'ss-slot-header',
  seatAvailableSlot: 'ss-available-slot',
  seatCloseSlot: 'ss-close-slot',
  seatSlotCode: 'ss-slot-code',
  seatIcon: 'ss-seat-icon'
}

export const planeLoader = {
  img: 'pwl-img',
  title: 'pwl-title',
  subtitle: 'pwl-subtitle',
  imgLoader: 'pwl-img-loader'
}

export const passengerData = {
  box: 'pd-box',
  bar: 'pd-bar',
  title: 'pd-title',

  headers: 'pd-headers',
  type: 'pd-header-type',
  icon: 'pd-header-icon',

  content: 'pd-content',

  firstname: 'pd-firstname',
  lastname: 'pd-lastname',
  label: 'input-title-',

  birthdate: 'pd-birthdate',
  day: 'pd-birthdate-day',
  month: 'pd-birthdate-month',
  year: 'pd-birthdate-year',

  gender: 'pd-gender',
  female: 'pd-female',
  male: 'pd-male',

  docType: 'pd-doc-type',
  docNumber: 'pd-doc-number',
  docCountry: 'pd-doc-country',
  nationality: 'pd-nationality',

  passport: 'pd-passport',
  dueDay: 'pd-passport-day',
  dueMonth: 'pd-passport-month',
  dueYear: 'pd-passport-year',

  frequentPassenger: 'pd-frequent-passenger',
  airline: 'pd-airline',
  memberNumber: 'pd-member-number',

  specialPreferences: 'pd-special-preferences',
  specialFoods: 'pd-special-foods',
  specialServices: 'pd-special-services',

  marketingEngagement: 'pd-marketing-engagement',

  homeInfo: 'pd-home-info',
  destinyInfo: 'pd-destiny-info',

  country: 'pd-country',
  state: 'pd-state',
  city: 'pd-city',
  address: 'pd-address',
  postalCode: 'pd-postal-code',

  redressText: 'pd-redress-text',
  redressLink: 'pd-redress-link',
  redressCheck: 'pd-redress-check',
  redressNumber: 'pd-redress-number',

  copyInfo: 'pd-copy-info',

  emergencyFullname: 'pd-emergency-fullname',
  emergencyRelationship: 'pd-emergency-relationship',
  emergencyCountry: 'pd-emergency-country',
  emergencyArea: 'pd-emergency-area',
  emergencyNumber: 'pd-emergency-number'
}

export const reservationData = {
  title: 'rd-title',

  email: 'rd-email',
  reEmail: 'rd-re-email',

  phone: 'rd-phone',
  home: 'rd-home',
  mobile: 'rd-mobile',
  country: 'rd-country',
  phoneNumber: 'rd-phone-number',
  area: 'rd-area',

  phoneExtra: 'rd-phone-extra',
  countryExtra: 'rd-country-extra',
  phoneNumberExtra: 'rd-phone-number-extra',
  areaExtra: 'rd-area-extra',

  addPhone: 'rd-add-phone',

  checkConditions: 'rd-conditions',
  fareConditions: 'rd-fare-conditions',
  bookingConditions: 'rd-booking-conditions',
  baggage: 'rd-allowed-baggage',
  termsAndConditionsError: 'input-error-rd-terms-and-conditions',

  buy: 'pd-buy'
}

export const headers = {
  box: 'simple-header-box',
  icon: 'simple-header-icon',
  title: 'simple-header-title',
  button: 'simple-header-button'
}

export const researchbox = {
  leg: 'trip',
  legDate: 'date',
  passenger: 'passenger',
  link: 'research',
  arrowPrev: 'rsb-prev',
  arrowNext: 'rsb-next'
}

export const events = {
  calendarBox: 'event-calendar-box',
  calendarListBox: 'event-calendar-list-box',
  calendarCategory: 'event-calendar-category',
  calendarImage: 'event-calendar-image',
  calendarFiltersBox: 'event-calendar-filters-box',
  calendarFilter: 'event-calendar-filter',
  calendarRowDateBox: 'event-calendar-row-date-box',
  calendarRowDate: 'event-calendar-row-date',
  calendarRowLink: 'event-calendar-row-link',
  calendarRowText: 'event-calendar-row-text',
  calendarRowTitleBox: 'event-calendar-row-title-box',
  calendarRowTitle: 'event-calendar-row-title',
  detailsBox: 'event-details-box',
  detailsText: 'event-details-text'
}

export const booking = {
  box: 'bk-box',
  title: 'bk-title-bar',
  passengers: 'bk-passenger',
  yourFlights: 'bk-your-flights',
  sideBarBox: 'bk-side-box',
  suggestion: 'bk-suggestion',
  suggestionLink: 'bk-suggestion-link',
  itineraryBox: 'bk-itinerary-box',
  itineraryFromToBox: 'bk-itinerary-from-to-box',
  itineraryTitleBox: 'bk-itinerary-title-box',
  itineraryTitleType: 'bk-itinerary-title-type',
  itineraryTitleExtra: 'bk-itinerary-title-extra',
  itineraryExtraBox: 'bk-itinerary-extra-box',
  itineraryExtra: 'bk-itinerary-extra',
  itineraryExtraLeg: 'bk-itinerary-extra-leg',
  itineraryExtraType: 'bk-itinerary-extra-type',
  itineraryExtraFromTo: 'bk-itinerary-extra-from-to',
  terminalTitle: 'foc-itinerary-terminal-title',
  terminalData: 'foc-itinerary-terminal-data',
  doorTitle: 'foc-itinerary-door-title',
  doorData: 'foc-itinerary-door-data',
  passengerBox: 'psg-box',
  passengerListBox: 'psg-list-box',
  passengerName: 'psg-name',
  passengerDoc: 'psg-doc',
  passengerItem: 'psg-item',
  passengerItemTitle: 'psg-item-title',
  passengerItemDesc: 'psg-item-description',
  passengerMoreInfo: 'psg-more-info',
  emailBox: 'm-box',
  emailTitle: 'm-title',
  emailInfo: 'm-info',
  emailItem: 'm-item',
  emailAdd: 'm-add-email',
  emailInput: 'm-add-email-input',
  emailAccept: 'm-add-email-accept',
  manageReservationBox: 'mr-box',
  manageReservationTitle: 'mr-title',
  manageReservationItem: 'mr-item',
  exchanges: 'button-exchanges',
  refund: 'button-refund',
  ancillariesBox: 'booking-ancillaries-box'
}

export const refundForm = {
  fullNames: 'fullnames',
  ticketNumbers: 'ticketnumbers',
  reservationCode: 'reservationcode'
}

const ancillaries = {
  passengerTitle: 'ancillaries-passenger-title',
  passengerDNI: 'ancillaries-passenger-dni',
  passengerName: 'ancillaries-passenger-name',
  passengerIcon: 'ancillaries-passenger-icon',
  passengerBox: 'ancillaries-passenger-box',
  routeTitle: 'ancillaries-route',
  input: 'ancillaries-input',
  inputValue: 'ancillaries-input-value',
  vip: 'ancillaries-vip',
  vipPrice: 'ancillaries-vip-price',
  vipDesc: 'ancillaries-vip-description',
  name: 'ancillaries',
  collapsible: 'ancillaries-collapsible',
  banner: {
    name: 'ancillaries-banner',
    img: 'ancillaries-banner-img',
    title: 'ancillaries-banner-title',
    info: 'ancillaries-banner-info'
  }
}

export const flightOfferCard = {
  box: 'foc-itinerary-box',
  itineraryAirline: 'foc-itinerary-airline',
  itineraryFromBox: 'foc-itinerary-from-box',
  itineraryToBox: 'foc-itinerary-To-box',
  itineraryDay: 'foc-itinerary-day',
  itineraryHour: 'foc-itinerary-hour',
  itineraryAirport: 'foc-itinerary-airport',
  itineraryDuration: 'foc-itinerary-duration',
  itineraryLine: 'foc-itinerary-line',
  itineraryIcon: 'foc-itinerary-icon',
  itineraryStops: 'foc-itinerary-stops'
}

export const welcome = {
  box: 'w-box',
  message: 'w-message',
  image: 'w-image',
  accept: 'w-accept'
}

export const home = {
  banner: {
    box: 'banner-box',
    button: 'banner-button',
    description: 'banner-description',
    subtitle: 'banner-subtitle',
    title: 'banner-title'
  },
  information: {
    button: 'info',
    column: 'column-info',
    icon: 'icon-info',
    image: 'image-info',
    itemBox: 'item-box-info',
    row: 'row-info',
    section: 'section-info',
    title: 'title-info'
  },
  navbar: {
    itemsBox: 'menu-item-box',
    itemIcon: 'menu-item-icon',
    itemLanguageIcon: 'item-language-icon',
    itemLanguageImage: 'item-language-image',
    itemLanguageOption: 'item-language-option',
    itemLanguageTitle: 'item-language-title',
    itemOptionBox: 'menu-item-option-box',
    itemOptionLink: 'menu-item-option-link',
    itemTitle: 'menu-item-title',
    menuHelper: 'menu-helper',
    menuHelperIcon: 'menu-helper-icon',
    menuItem: 'menu-option',
    menuLanguage: 'menu-language',
    menuLanguageIcon: 'menu-language-icon',
    menuLogin: 'menu-login',
    menuLoginIcon: 'menu-login-icon'
  },
  promotion: {
    arrowIcon: 'arrow-icon',
    box: 'promotion-box',
    cardBox: 'card-box',
    cardItem: 'card-item',
    cardTitle: 'card-title',
    linkBank: 'link-more-banks',
    panelBox: 'panel-box',
    tabItemTitle: 'tab-item-title',
    title: 'promotion-title'
  }
}

export const footer = {
  newsLetter: {
    callCenterBox: 'callCenter-box',
    callCenterIcon: 'icon-callCenter',
    callCenterItemsBox: 'callCenter-items-box',
    callCenterItems: 'callCenter-items',
    callCenterLink: 'link-callCenter',
    callCenterNumber: 'number-callCenter',
    callCenterTitle: 'title-callCenter',
    callCenterTitleItem: 'title-callCenter-item',
    callCenterTitleBox: 'title-callCenter-box',
    subscribeButton: 'subscribe',
    subscribeIcon: 'icon-subscribe',
    subscribeMessage: 'message-subscribe'
  },
  institutional: {
    itemsBox: 'items-institutional-box',
    itemOption: 'item-institutional-option',
    itemTitle: 'title-institutional'
  },
  social: {
    box: 'social-box',
    itemsBox: 'items-social-box',
    itemIcon: 'item-social-icon',
    itemOption: 'item-social-option',
    itemTitle: 'title-social'
  },
  legal: {
    boxLegal: 'legal-box',
    brandsBox: 'brands-box',
    brandsOption: 'brands-option',
    copyrightInformation: 'copyright-information',
    fiscalDataImage: 'fiscal-data-image',
    itemTermsAndConditions: 'item-terms-and-conditions'
  }
}

export const specialRequests = {
  box: 'special-requests-box',
  passengerBox: 'passenger-box',
  passengerHeader: 'passenger-header',
  passengerTitle: 'passenger-title',
  disclaimerText: 'disclaimer-text',
  acceptButton: 'accept',
  cancelButton: 'cancel'
}

export const thumbnail = {
  badge: 'thumbnail-badge',
  box: 'thumbnail-box',
  description: 'thumbnail-description',
  fare: 'thumbnail-fare',
  footer: 'thumbnail-footer',
  header: 'thumbnail-header',
  image: 'thumbnail-image',
  primaryLabel: 'thumbnail-primary-label',
  secondaryLabel: 'thumbnail-secondary-label'
}

export const exchange = {
  headerTitle: 'ex-header-title',
  headerButton: 'ex-header-close',

  legBox: 'ex-leg-box',

  itineraryTitleIcon: 'ex-itinerary-title-icon',
  itineraryTitleType: 'ex-itinerary-title-type',
  itineraryTitleDate: 'ex-itinerary-title-date',

  itineraryBoxFrom: 'ex-itinerary-from-box',
  itineraryBoxTo: 'ex-itinerary-to-box',

  itineraryAirport: 'ex-itinerary-airport',
  itineraryCity: 'ex-itinerary-city',

  itinerarySeparatorLine: 'ex-itinerary-separator-line',
  itinerarySeparatorIcon: 'ex-itinerary-separator-icon',

  itinerarySearchButton: 'ex-itinerary-search',
  itineraryCancelButton: 'ex-itinerary-cancel',

  condition: 'ex-condition',
  readCondition: 'ex-read-condition',
  hint: 'ex-hint'
}

export const corporate = {
  agreementsLoginBox: 'corporate-agreements-login-box',
  agreementsLoginTitle: 'corporate-agreements-login-title',
  agreementsLoginIcon: 'corporate-agreements-login-icon',
  agreementsLoginFormBox: 'corporate-agreements-login-form-box',
  agreementsForgetLink: 'corporate-agreements-forget-link',
  agreementsInfoTextBox: 'corporate-agreements-info-text-box',
  agreementsInfoTextTitle: 'corporate-agreements-info-text-title',
  agreementsFormInformationBox: 'corporate-agreements-form-information-box',
  agreementsFormInformationText: 'corporate-agreements-form-information-text',
  agreementsRefundInformation: 'corporate-agreements-refund-information',
  agreementsRefundForm: 'corporate-agreements-refund-form',
  agreementRequestMessage: 'agreement-request-message',
  enterpriseName: 'corporate-enterprise-name',
  enterpriseCuit: 'corporate-enterprise-cuit',
  enterpriseActivity: 'corporate-enterprise-activity',
  enterpriseCountry: 'corporate-enterprise-country',
  enterpriseState: 'corporate-enterprise-state',
  enterpriseCity: 'corporate-enterprise-city',
  enterpriseStreet: 'corporate-enterprise-street',
  enterpriseNumber: 'corporate-enterprise-number',
  enterpriseFloor: 'corporate-enterprise-floor',
  enterpriseApartment: 'corporate-enterprise-apartment',
  enterpriseZipCode: 'corporate-enterprise-zipcode',
  enterpriseUrl: 'corporate-enterprise-url',
  recoverCredentialsBox: 'recover-credentials-box',
  recoverCredentialsCloseIcon: 'recover-credentials-close-icon',
  recoverCredentialsHeader: 'recover-credentials-header',

  refundContainer: 'refund-container',
  refundDescription: 'refund-description',
  refundHomeButton: 'refund-home-button',
  refundInformation: 'refund-information',
  refundImage: 'refund-image',
  refundList: 'refund-list',
  refundMessage: 'refund-message',
  regretRefund: 'regret-refund',
  refundResultBox: 'refund-result-box',
  refundTitle: 'refund-title',
  addPassenger: 'add-passenger',
  removePassenger: 'remove-passenger',

  registerImageError: 'register-image-error',
  registerTitleError: 'register-title-error',
  registerTextError: 'register-text-error',
  registerUserBox: 'corporate-register-user-box',
  registerUserTitle: 'corporate-register-user-title',
  registerUserText: 'corporate-register-user-text',

  acceptButton: 'accept',
  acceptPersonalDataUse: 'accept-personal-data-use',
  acceptPromo: 'accept-promo',
  acceptNewsletter: 'accept-promo-Newsletter',
  addRoute: 'add-route',
  anualSpending: 'anualspending',
  cellPhone: 'cellphone',
  contactAcceptTermsConditions: 'contactAcceptTermsConditions',
  contactTitle: 'contact',
  currentText: 'current-text',
  directFlights: 'directflights',
  document: 'document',
  email: 'email',
  enterepriseTitle: 'enterprise',
  jobtTitle: 'jobttitle',
  firstNameOfPayer: 'firstnameofpayer',
  flightDate: 'flightdate',
  fullNames: 'fullnames',
  labelNo: 'no',
  labelYes: 'yes',
  loginButton: 'login',
  lastName: 'lastname',
  lastNameOfPayer: 'lastnameofpayer',
  market: 'market-',
  marketBox: 'market-box-',
  name: 'name',
  observations: 'observations',
  password: 'password',
  phone: 'phone',
  placeOfResidence: 'placeofresidence',
  reasonForReturn: 'reasonforreturn',
  recoverButton: 'recover',
  reservationCode: 'reservationcode',
  returnButton: 'return',
  sectionReturn: 'sectionreturn',
  sendButton: 'send',
  termsAndConditions: 'termsAndConditions',
  textArea: 'text-area',
  ticketNumbers: 'ticketnumbers',
  travelAgencyName: 'travelagencyname',
  travelTitle: 'travel',
  userDocument: 'user-document',
  username: 'username'
}

export const pressReleases = {
  banner: 'press-releases',
  box: 'press-releases-box',
  boxContainer: 'press-releases-box-container',
  calendar: 'press-releases-calendar',
  calendarIcon: 'press-releases-calendar-icon',
  calendarsSingle: 'press-releases-calendar-single',
  date: 'press-releases-date',
  imageSingle: 'press-releases-image-single',
  message: 'press-releases-message',
  pages: 'press-releases-pages',
  pagesBox: 'press-releases-pages-box',
  pageSelector: 'press-releases-pages-selector',
  pageSelectorLabel: 'press-releases-pages-selector-label',
  readStatement: 'read-statement',
  richText: 'press-releases-rich-text',
  returnLink: 'return-link',
  searchBox: 'press-releases-search-box',
  skyTeamLink: 'sky-team-link',
  title: 'press-releases-title',
  titleSingle: 'press-releases-title-single',
  notFoundText: 'press-releases-not-found-text'
}

const messages = {
  box: 'c-message',
  title: 'c-message-title'
}

const e2eClasses = {
  ancillaries,
  oops,
  booking,
  alternateOffersMatrix,
  common,
  compareConditions,
  corporate,
  exchange,
  events,
  fareConditions,
  footer,
  installments,
  itinerary,
  headers,
  helpCenter,
  home,
  messages,
  modal,
  pressReleases,
  reservationData,
  researchbox,
  searchBox,
  searchBoxFlights,
  sFDetails,
  seatSelection,
  specialRequests,
  stickyFooter,
  passengerData,
  payments,
  paymentDetails,
  thumbnail
}

export default e2eClasses
