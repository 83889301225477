/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect'

import MODAL_NAMES from 'constants/modalNames'
import { isEmpty } from 'helpers/utils'

export const getState = state => state.modalState

const getModals = createSelector([getState], state => (state ? state.modals : []))

const getModalFetchContestError = createSelector([getModals], modals =>
  !isEmpty(modals) ? modals[MODAL_NAMES.CONTESTS_FETCH_ERROR] : null
)

export const isModalFetchContestErrorVisible = createSelector([getModalFetchContestError], modal =>
  modal ? modal.isVisible : false
)

const getModalContestDetails = createSelector([getModals], modals =>
  !isEmpty(modals) ? modals[MODAL_NAMES.CONTESTS_DETAILS] : null
)

export const isModalContestDetailsVisible = createSelector([getModalContestDetails], modal =>
  modal ? modal.isVisible : false
)

const getModalTimeoutCheckout = createSelector([getModals], modals =>
  !isEmpty(modals) ? modals[MODAL_NAMES.TIME_OUT_CHECKOUT] : null
)

export const isCheckoutTimeoutModalVisible = createSelector([getModalTimeoutCheckout], modal =>
  modal ? modal.isVisible : false
)

export const isCVCModalVisible = createSelector(
  [getModals],
  modals =>
    modals[MODAL_NAMES.CREDIT_CARD_CVC_INFO] && modals[MODAL_NAMES.CREDIT_CARD_CVC_INFO].isVisible
)

const getModalFlightItinerary = createSelector([getModals], modals =>
  !isEmpty(modals) ? modals[MODAL_NAMES.FLIGHT_ITINERARY] : null
)

export const isFlightItineraryModalVisible = createSelector([getModalFlightItinerary], modal =>
  modal ? modal.isVisible : false
)

const getModalAncillariesWarning = createSelector([getModals], modals =>
  !isEmpty(modals) ? modals[MODAL_NAMES.ANCILLARIES_NOT_ALL_SUCCESSFUL] : null
)

export const isAncillariesNotAllSuccessfulModalVisible = createSelector(
  [getModalAncillariesWarning],
  modal => (modal ? modal.isVisible : false)
)

export const isSeatsSelectionSuccessfulModalVisible = createSelector([getModals], modals =>
  !isEmpty(modals) ? modals[MODAL_NAMES.SEAT_RESULT_SUCCESS]?.isVisible : null
)

export const isExchangesCallCenterModalVisible = createSelector([getModals], modals =>
  !isEmpty(modals) ? modals[MODAL_NAMES.EXCHANGES_REDIRECT_MY_RESERVATION]?.isVisible : null
)
