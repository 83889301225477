import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { buildURLEncoded } from 'helpers/url'
import api, { apiSublos } from 'services/api'

const { fetchChargesStart, fetchChargesSuccess, fetchChargesError, clearCharges } = createActions({
  FETCH_CHARGES_START: () => {},
  FETCH_CHARGES_SUCCESS: airports => airports,
  FETCH_CHARGES_ERROR: error => error,
  CLEAR_CHARGES: () => {}
})

const fetchCharges = pageConfig => {
  const queryParams = buildURLEncoded('', pageConfig)

  return async dispatch => {
    dispatch(fetchChargesStart())

    try {
      const taxes = await api.get(`${apiRoutes.CHARGES}${queryParams}`)
      dispatch(fetchChargesSuccess(taxes))
    } catch (error) {
      dispatch(fetchChargesError(error))
    }
  }
}

const fetchSublosCharges = pageConfig => {
  const queryParams = buildURLEncoded('', pageConfig)

  return async dispatch => {
    dispatch(fetchChargesStart())

    try {
      const taxes = await apiSublos.get(`${apiRoutes.CHARGES}${queryParams}`)
      dispatch(fetchChargesSuccess(taxes))
    } catch (error) {
      dispatch(fetchChargesError(error))
    }
  }
}

export {
  fetchCharges,
  fetchSublosCharges,
  clearCharges,
  fetchChargesStart,
  fetchChargesSuccess,
  fetchChargesError
}
