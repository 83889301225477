import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  LegalText,
  LegalTextAndLink,
  TermsAndConditionsLink,
  FiscalDataImage,
  CopyrightInformationParagraph,
  BrandsImage,
  Link,
  BrandsContainer
} from './styled'

const FooterLegal = ({
  termsAndConditionsText,
  termsAndConditionsUrl,
  fiscal,
  brandsImage,
  copyrightInformation: {
    description,
    activityPeriod: { from, to }
  },
  showFooterCollapsible
}) => {
  if (showFooterCollapsible) {
    /* new footer */
    return (
      <Container showFooterCollapsible={showFooterCollapsible}>
        {(fiscal || []).map((item, index) => (
          <Link key={index} href={item.url}>
            <FiscalDataImage src={item.logo} alt={item.alt} />
          </Link>
        ))}
        <LegalTextAndLink>
          <TermsAndConditionsLink href={termsAndConditionsUrl} target="_blank">
            {termsAndConditionsText}
          </TermsAndConditionsLink>
          <LegalText>
            <CopyrightInformationParagraph showFooterCollapsible={showFooterCollapsible}>
              {`© ${from} - ${to} |`}
            </CopyrightInformationParagraph>
            <CopyrightInformationParagraph>{description}</CopyrightInformationParagraph>
          </LegalText>
        </LegalTextAndLink>
      </Container>
    )
  }
  /* old footer */
  return (
    <Container>
      <BrandsContainer>
        {(brandsImage || []).map((item, index) => (
          <Link key={index} href={item.url}>
            <BrandsImage src={item.logo} alt={item.alt} />
          </Link>
        ))}
      </BrandsContainer>
      <TermsAndConditionsLink href={termsAndConditionsUrl} target="_blank">
        {termsAndConditionsText}
      </TermsAndConditionsLink>
      {(fiscal || []).map((item, index) => (
        <Link key={index} href={item.url}>
          <FiscalDataImage src={item.logo} alt={item.alt} />
        </Link>
      ))}
      <Fragment>
        <CopyrightInformationParagraph>{description}</CopyrightInformationParagraph>
        <CopyrightInformationParagraph>{`© ${from} - ${to}`}</CopyrightInformationParagraph>
      </Fragment>
    </Container>
  )
}

FooterLegal.propTypes = {
  termsAndConditionsText: PropTypes.string,
  termsAndConditionsUrl: PropTypes.string,
  copyrightInformation: PropTypes.object.isRequired
}

export default FooterLegal
