import { handleActions } from 'redux-actions'

import {
  changePasswordStart,
  changePasswordSuccess,
  changePasswordError,
  clearChangePasswordState
} from 'actions/arplus/change-password'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const changePassword = handleActions(
  {
    [changePasswordStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [changePasswordSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [changePasswordError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    }),
    [clearChangePasswordState]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default changePassword
