import { createActions } from 'redux-actions'
import { cmsApi } from 'services/api'
import cmsApiRoutes from 'constants/cmsApiRoutes'

const {
  fetchElitePartnersDataStart,
  fetchElitePartnersDataSuccess,
  fetchElitePartnersDataError
} = createActions({
  FETCH_ELITE_PARTNERS_DATA_START: () => {},
  FETCH_ELITE_PARTNERS_DATA_SUCCESS: data => ({ data }),
  FETCH_ELITE_PARTNERS_DATA_ERROR: error => ({ error })
})

const fetchElitePartnersData = () => {
  return async dispatch => {
    dispatch(fetchElitePartnersDataStart())

    try {
      const response = await cmsApi.get(cmsApiRoutes.ELITE_PARTNERS)
      dispatch(fetchElitePartnersDataSuccess(response.data))
    } catch (error) {
      dispatch(fetchElitePartnersDataError(error))
    }
  }
}

export {
  fetchElitePartnersDataStart,
  fetchElitePartnersDataSuccess,
  fetchElitePartnersDataError,
  fetchElitePartnersData
}
