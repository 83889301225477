import { handleActions } from 'redux-actions'

import {
  validateEmailStart,
  validateEmailSuccess,
  validateEmailError,
  clearValidateEmailState
} from 'actions/arplus/validateEmail'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const validateEmail = handleActions(
  {
    [validateEmailStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [validateEmailSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [validateEmailError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    }),
    [clearValidateEmailState]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default validateEmail
