import { handleActions } from 'redux-actions'
import {
  getFlightStatusStart,
  getFlightStatusSuccess,
  getFlightStatusError
} from 'actions/flightStatus'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [getFlightStatusStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [getFlightStatusSuccess]: (state, { payload: { data } }) => {
      return {
        ...state,
        data: data.data,
        isFetching: false
      }
    },
    [getFlightStatusError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: {},
      error
    })
  },
  defaultState
)

export default reducer
