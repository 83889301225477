/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect'

import { mapHeader } from 'api-data-io/cms/header'
import { mapSeoData } from 'server/utils/seo'

const getHeaderSubState = state => state.header

export const getHeaderData = createSelector([getHeaderSubState], state =>
  state ? state.data : null
)

export const getHeader = createSelector([getHeaderData], header =>
  header && header[0] ? mapHeader(header[0]) : []
)

export const getDefaultSeoData = createSelector([getHeaderData], header =>
  header && header[0] ? mapSeoData(header[0]?.headerSeoMetadata) : []
)
