import styled from 'styled-components'

export const Title1 = styled.h1`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 39px;
  line-height: 47px;
  margin: 0.5em 0;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-family: normal;
`

export const Title2 = styled.h2`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 33px;
  line-height: 39px;
  margin: 0.5em 0;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-family: normal;
`

export const Title3 = styled.h3`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 24px;
  line-height: 29px;
  margin: 0.5em 0;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-family: normal;
`

export const Title4 = styled.h4.attrs(props => ({
  className: props.e2eClass
}))`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 20px;
  line-height: 24px;
  margin: 0.5em 0;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-family: normal;
`

export const Title5 = styled.h5.attrs(props => ({
  className: props.e2eClass
}))`
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 18px;
  line-height: 22px;
  margin: ${({ margin }) => margin || '0.5em 0'};
`

export const Text = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.regular};
`

export const List = styled.ul`
  counter-reset: item;
  font-size: 16px;
  line-height: 25px;
  list-style-type: none;
  padding-left: 0;
  font-family: ${({ theme }) => theme.fonts.regular};

  ul {
    padding-left: 20px;

    li::before {
      font-weight: normal;
    }
  }
`

export const NumberedItem = styled.li`
  counter-increment: item;
  padding-left: 10px;

  &::before {
    content: counter(item);
    font-weight: bold;
    margin-right: 20px;
  }
`

export const Item = styled.li`
  padding-left: 10px;

  &::before {
    content: '•';
    font-weight: bold;
    margin-right: 20px;
  }
`
