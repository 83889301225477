import SUBLOS_ERRORS, {
  ACTION_TYPE,
  ERROR_TYPE,
  GDS_PASSENGER_SSR_PREFERENCE_NOT_ALLOWED,
  GDS_RESERVATION_ERROR_NOTFOUND,
  RESERVATION_SERVICE_ERROR,
  RESERVATION_SSR_INFO_INVALID_SEGMENTS,
  SUBLOS_BOOKING_DETAILS_REQUEST_MARKET_NOT_BLANK,
  SUBLOS_CONFIRM_RESERVATION_VCR_AMOUNT,
  SUBLOS_CONFIRM_TICKET_ERROR,
  SUBLOS_GENERAL_SERVICE_EXCEPTION,
  SUBLOS_ISSUE_RESERVATION_ERROR,
  SUBLOS_PAYMENT_CONNECTOR_ERROR_UNABLETOCREATECARDDEBT,
  SUBLOS_PRICE_QUOTE_RESERVATION_ERROR,
  SUBLOS_PRICE_QUOTE_RESERVATION_NOT_FOUND,
  SUBLOS_RESERVATION_SERVICE_ADD_REMARK_SERVER_ERROR,
  SUBLOS_RESERVATION_SERVICE_ADD_SSR_SERVER_ERROR,
  SUBLOS_RESERVATION_SERVICE_CREATE_RESERVATION_SERVER_ERROR,
  SUBLOS_RESERVATION_SERVICE_GET_RESERVATION_SERVER_ERROR,
  SUBLOS_RESERVATION_VALIDATION_AGE_MUST_BE_21_25,
  SUBLOS_RESERVATION_VALIDATION_AGE_MUST_BE_25,
  SUBLOS_RESERVATION_VALIDATION_BASIC_QUOTE_SOLD_OUT,
  SUBLOS_RESERVATION_VALIDATION_BENEFICIARY_AGE_MUST_BE_LESS_21,
  SUBLOS_RESERVATION_VALIDATION_BENEFICIARY_INSUFFICIENT_CONDITIONS,
  SUBLOS_RESERVATION_VALIDATION_HOLDER_INSUFFICIENT_ANTIQUITY
} from 'constants/sublos/errors'
import { STEPS } from 'constants/sublos/flow'

const getListErrorMessage = ({ description }) =>
  (Array.isArray(description)
    ? description
    : typeof description === 'string'
    ? [description]
    : []
  ).map(key => (key.indexOf(':') !== -1 ? key.split(':')[0] : key))

const parseDataError = (errorsKey, dataError) => ({
  ...dataError,
  description: errorsKey?.length ? errorsKey : [dataError?.description]
})

export const mapRequestError = (error, errorType) => ({
  ...error,
  errorType
})

export const mapErrorTypeSublos = ({
  error,
  actionType,
  secondaryActionType,
  title,
  description,
  buttonText,
  buttonSecondaryText
}) => ({
  ...error,
  ...(actionType ? { actionType } : {}),
  ...(secondaryActionType ? { secondaryActionType } : {}),
  ...(title ? { title } : {}),
  ...(description ? { description } : {}),
  ...(buttonText ? { buttonText } : {}),
  ...(buttonSecondaryText ? { buttonSecondaryText } : {})
})

export const cancelValidationReservation = () =>
  mapErrorTypeSublos({
    error: SUBLOS_ERRORS.SUBLOS_CANCEL_RESERVATION_REQUEST,
    actionType: ACTION_TYPE.GO_BACK,
    title: 'sublos.modal.cancel-reservation-request.title',
    description: 'sublos.modal.cancel-reservation-request.description',
    buttonText: 'sublos.button.cancel-reservation-request-and-go-back',
    buttonSecondaryText: 'sublos.button.cancel'
  })

export const cancelCreateReservation = () =>
  mapErrorTypeSublos({
    error: SUBLOS_ERRORS.SUBLOS_CANCEL_RESERVATION_REQUEST,
    actionType: ACTION_TYPE.GO_HOME,
    title: 'sublos.modal.cancel-reservation-generated.title',
    description: 'sublos.modal.cancel-reservation-generated.description',
    buttonText: 'sublos.button.cancel-reservation-request-and-go-home',
    buttonSecondaryText: 'sublos.button.cancel'
  })

export const cancelReservation = () =>
  mapErrorTypeSublos({
    error: SUBLOS_ERRORS.SUBLOS_CANCEL_RESERVATION_REQUEST,
    actionType: ACTION_TYPE.CANCEL,
    secondaryActionType: ACTION_TYPE.GO_HOME,
    title: 'sublos.modal.cancel-reservation.title',
    description: 'sublos.modal.cancel-reservation.description',
    buttonText: 'sublos.modal.cancel-reservation.button-no',
    buttonSecondaryText: 'sublos.modal.cancel-reservation.button-yes'
  })

const checkErrorMessage = error => {
  const errorsKey = getListErrorMessage(error)

  if (!errorsKey?.length) return SUBLOS_ERRORS.RETRY_SEND_REQUEST

  switch (errorsKey[0]) {
    case SUBLOS_RESERVATION_VALIDATION_BASIC_QUOTE_SOLD_OUT:
    case SUBLOS_RESERVATION_VALIDATION_BENEFICIARY_INSUFFICIENT_CONDITIONS:
    case SUBLOS_RESERVATION_VALIDATION_BENEFICIARY_AGE_MUST_BE_LESS_21:
    case SUBLOS_RESERVATION_VALIDATION_AGE_MUST_BE_25:
    case SUBLOS_RESERVATION_VALIDATION_AGE_MUST_BE_21_25:
    case SUBLOS_RESERVATION_VALIDATION_HOLDER_INSUFFICIENT_ANTIQUITY:
      return parseDataError(
        errorsKey,
        mapErrorTypeSublos({
          error: SUBLOS_ERRORS.RETRY_SEND_REQUEST,
          actionType: ACTION_TYPE.GO_BACK,
          secondaryActionType: ACTION_TYPE.GO_HOME,
          title: 'sublos.retry-send-passenger-validation.modal.title',
          description: errorsKey[0],
          buttonText: 'sublos.button.return-to-passengers-select',
          buttonSecondaryText: 'sublos.button.go-home'
        })
      )
    case SUBLOS_BOOKING_DETAILS_REQUEST_MARKET_NOT_BLANK:
      return parseDataError(
        errorsKey,
        mapErrorTypeSublos({
          error: SUBLOS_ERRORS.GENERIC_ERROR_FLIGHT,
          title: 'sublos.form.result-error-flight-selection.title',
          description: errorsKey[0]
        })
      )
    case SUBLOS_RESERVATION_SERVICE_CREATE_RESERVATION_SERVER_ERROR:
    case RESERVATION_SERVICE_ERROR:
    case SUBLOS_PRICE_QUOTE_RESERVATION_NOT_FOUND:
    case SUBLOS_PRICE_QUOTE_RESERVATION_ERROR:
    case SUBLOS_GENERAL_SERVICE_EXCEPTION:
    case SUBLOS_RESERVATION_SERVICE_ADD_REMARK_SERVER_ERROR:
    case SUBLOS_RESERVATION_SERVICE_GET_RESERVATION_SERVER_ERROR:
    case SUBLOS_RESERVATION_SERVICE_ADD_SSR_SERVER_ERROR:
    case RESERVATION_SSR_INFO_INVALID_SEGMENTS:
    case SUBLOS_PAYMENT_CONNECTOR_ERROR_UNABLETOCREATECARDDEBT:
    case SUBLOS_ISSUE_RESERVATION_ERROR:
    case SUBLOS_CONFIRM_RESERVATION_VCR_AMOUNT:
    case SUBLOS_CONFIRM_TICKET_ERROR:
    case GDS_RESERVATION_ERROR_NOTFOUND:
    case GDS_PASSENGER_SSR_PREFERENCE_NOT_ALLOWED:
      return parseDataError(
        errorsKey,
        mapErrorTypeSublos({
          error: SUBLOS_ERRORS.RETRY_SEND_REQUEST,
          description: errorsKey[0]
        })
      )
    default:
      return parseDataError(
        errorsKey,
        mapErrorTypeSublos({
          error: SUBLOS_ERRORS.GENERIC_ERROR,
          description: errorsKey[0] || SUBLOS_ERRORS.GENERIC_ERROR.description
        })
      )
  }
}

const userDataMapError = error => {
  if (error) {
    switch (error?.statusCode) {
      default:
        //TODO check if change error type
        // return SUBLOS_ERRORS.GENERIC_ERROR_WITHOUT_ACTIONS
        return SUBLOS_ERRORS.GENERIC_ERROR
    }
  }
}

const legSelectionMapError = error => {
  if (error) {
    switch (error?.statusCode) {
      case 400:
        return checkErrorMessage(error)
      default:
        return SUBLOS_ERRORS.GENERIC_ERROR
    }
  }
}

const selectPassengerMapError = error => {
  if (error) {
    switch (error?.statusCode) {
      case 400:
        return checkErrorMessage(error)
      default:
        return SUBLOS_ERRORS.GENERIC_ERROR
    }
  }
}

const noNameMapError = error => {
  if (error) {
    switch (error?.statusCode) {
      case 400:
        return checkErrorMessage(error)
      default:
        //TODO check if change error type
        //return SUBLOS_ERRORS.GENERIC_ERROR_WITHOUT_ACTIONS
        return SUBLOS_ERRORS.GENERIC_ERROR
    }
  }
}

const validateReservationMapError = error => {
  if (error) {
    const { statusCode } = error || {}

    switch (statusCode) {
      case 400:
        return checkErrorMessage(error)
      default:
        return SUBLOS_ERRORS.GENERIC_ERROR
    }
  }
}

const flightSelectionMapError = error => {
  if (error) {
    if (error.key === SUBLOS_ERRORS.NO_AVAILABLE_FLIGHTS.key)
      return SUBLOS_ERRORS.NO_AVAILABLE_FLIGHTS
    else {
      switch (error.statusCode) {
        case 400:
          return checkErrorMessage(error)
        default:
          return SUBLOS_ERRORS.GENERIC_ERROR_FLIGHT
      }
    }
  }
}

const passengersDocumentacionMapError = error => {
  if (error) {
    switch (error?.statusCode) {
      case 400:
        return checkErrorMessage(error)
      default:
        return SUBLOS_ERRORS.GENERIC_ERROR
    }
  }
}

const reservationConfirmationMapError = error => {
  if (error) {
    switch (error?.statusCode) {
      case 400:
        return checkErrorMessage(error)
      default:
        return SUBLOS_ERRORS.GENERIC_ERROR
    }
  }
}

const continueToPaymentMapError = error => {
  if (error) {
    switch (error?.statusCode) {
      case 400:
        return checkErrorMessage(error)
      default:
        return SUBLOS_ERRORS.GENERIC_ERROR
    }
  }
}

const paymentDataMapError = error => {
  if (error) {
    switch (error?.statusCode) {
      case 400:
        return checkErrorMessage(error)
      default:
        return SUBLOS_ERRORS.GENERIC_ERROR
    }
  }
}

const purchaseConfirmationMapError = error => {
  if (error) {
    switch (error?.statusCode) {
      case 400:
        return checkErrorMessage(error)
      default:
        return SUBLOS_ERRORS.GENERIC_ERROR
    }
  }
}

const loginMapError = error => {
  if (error && error?.statusCode) {
    const { statusCode } = error
    switch (statusCode) {
      case 400:
        return SUBLOS_ERRORS.GENERIC_LOGIN_ERROR

      case 401:
        return SUBLOS_ERRORS.GENERIC_LOGIN_ERROR

      case 404:
        return SUBLOS_ERRORS.GENERIC_LOGIN_ERROR

      default:
        return SUBLOS_ERRORS.GENERIC_LOGIN_ERROR
    }
  }
}

const isGenericError = error => error.statusCode >= 500
const isUnauthorizedError = error => error?.statusCode === 401
const isModalUpdate = ({ errorType }) => errorType === ERROR_TYPE.MODAL

export const mapperError = (error, screen) => {
  if (isModalUpdate(error) && isGenericError(error)) return SUBLOS_ERRORS.RETRY_SEND_REQUEST
  if (isGenericError(error)) return SUBLOS_ERRORS.GENERIC_ERROR
  if (screen !== STEPS.LOGIN && isUnauthorizedError(error)) return SUBLOS_ERRORS.SESSION_EXPIRED

  switch (screen) {
    case STEPS.USER_DATA:
      return userDataMapError(error)

    case STEPS.LEG_SELECTION:
      return legSelectionMapError(error)

    case STEPS.SELECT_PASSENGERS:
      return selectPassengerMapError(error)

    case STEPS.NO_NAME_PASSENGER:
      return noNameMapError(error)

    case STEPS.VALIDATE_RESERVATION:
      return validateReservationMapError(error)

    case STEPS.FLIGHT_SELECTION:
      return flightSelectionMapError(error)

    case STEPS.PASSENGERS_DOCUMENTATION:
      return passengersDocumentacionMapError(error)

    case STEPS.RESERVATION_CONFIRMATION:
      return reservationConfirmationMapError(error)

    case STEPS.CONTINUE_TO_PAYMENT:
      return continueToPaymentMapError(error)

    case STEPS.PAYMENT_DATA:
      return paymentDataMapError(error)

    case STEPS.PURCHASE_CONFIRMATION:
      return purchaseConfirmationMapError(error)

    case STEPS.LOGIN: {
      return loginMapError(error)
    }

    default:
      return SUBLOS_ERRORS.GENERIC_ERROR
  }
}
