import { batch } from 'react-redux'

import { clearPurchase } from 'actions/reservation'
import { setSublosError, setError } from 'actions/sublos/errors'
import {
  clearSublosBookingValidation,
  clearSublosCancelReservation,
  clearSublosReservation
} from 'actions/sublos/reservation'
import { RESET_SERVICES } from 'constants/sublos/errors'
import { STEPS } from 'constants/sublos/flow'
import { mapperError } from 'helpers/sublos/mapper-error'
import { isEmpty } from 'helpers/utils'

export const reservationThunk = error => async (dispatch, getState) => {
  const state = getState()

  const { sublosBookingValidation, sublosReservation } = state

  batch(() => {
    sublosBookingValidation.error &&
      dispatch(setSublosError(mapperError(error, STEPS.VALIDATE_RESERVATION)))

    sublosReservation.error &&
      dispatch(setSublosError(mapperError(error, STEPS.RESERVATION_CONFIRMATION)))
  })
}

export const cancelReservationThunk = ({
  sendBack,
  goHome,
  cancelGenerateReservation,
  cancelValidateReservation,
  cancelReservationData,
  cancelReservationError
}) => async dispatch => {
  batch(() => {
    cancelReservationError &&
      dispatch(setError(mapperError(cancelReservationError, STEPS.VALIDATE_RESERVATION)))

    cancelValidateReservation &&
      !isEmpty(cancelReservationData) &&
      dispatch(clearSublosBookingValidation()) &&
      dispatch(clearSublosCancelReservation()) &&
      sendBack()

    cancelGenerateReservation &&
      !isEmpty(cancelReservationData) &&
      dispatch(clearSublosBookingValidation()) &&
      dispatch(clearSublosReservation()) &&
      dispatch(clearSublosCancelReservation()) &&
      goHome()
  })
}

export const clearReservationStateThunk = resetServices => async (dispatch, getState) => {
  const state = getState()
  const isResetAll =
    resetServices === RESET_SERVICES.RESET_ALL_SERVICES ||
    resetServices === RESET_SERVICES.RESET_CORE_SERVICES
  const { sublosBookingValidation, sublosReservation, sublosCancelReservation } = state

  batch(() => {
    ;(sublosBookingValidation.error || isResetAll) && dispatch(clearSublosBookingValidation())
    ;(sublosCancelReservation.error || isResetAll) && dispatch(clearSublosCancelReservation())
    ;(sublosReservation.error || isResetAll) && dispatch(clearSublosReservation())

    isResetAll && dispatch(clearPurchase())
  })
}
