import styled from 'styled-components'
import loaderMobile from 'assets/loader-de-resultados2---mobile.gif'

const ComponentLoaderMobileSearch = styled.img.attrs(() => ({
  src: loaderMobile,
  alt: 'Loading...'
}))`
  transform: scale(0.8, 0.8);
`

export default ComponentLoaderMobileSearch
