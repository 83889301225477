import styled, { css } from 'styled-components'

import mediaQueries from 'components/media-queries'
import e2eClasses from 'helpers/automation'

const SubtitleStyles = css`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.doveGray};
  letter-spacing: 1px;
  font-size: 20px;
`

export const FooterContactInfoWrapper = styled.div.attrs(() => ({
  className: e2eClasses('footer.newsLetter.callCenterBox')
}))`
  padding: 3em;
  padding-bottom: 0;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;

  ${mediaQueries.tablet`
    padding: 20px;
    margin: 0 auto;
  `}

  ${mediaQueries.mobile`
    padding: initial;
    grid-column-start: 1;
    grid-row-start: 2;
    grid-row-end: 3;
  `}
`

export const ContactDetail = styled.div.attrs(() => ({
  className: e2eClasses('footer.newsLetter.callCenterItems')
}))`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 36px 1fr;
  margin-bottom: 40px;
`

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.label.attrs(() => ({
  className: e2eClasses('footer.newsLetter.callCenterTitle')
}))`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  color: ${({ theme }) => theme.colors.blue};
  font-size: 20px;
`

export const TitleDetail = styled.label.attrs(() => ({
  className: e2eClasses('footer.newsLetter.callCenterTitleItem')
}))`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  color: ${({ theme }) => theme.colors.blue};
  font-size: 18px;
`

export const SubscribeTitle = styled(Title)`
  margin-left: 15px;
  font-size: 22px;
`

export const Subtitle = styled.label.attrs(() => ({
  className: e2eClasses('footer.newsLetter.callCenterNumber')
}))`
  ${SubtitleStyles}

  /**
   * Works around injected __html
   */
  & a {
    ${SubtitleStyles}
    text-decoration: none;
  }
`

export const IconImagen = styled.img.attrs(() => ({
  className: e2eClasses('footer.newsLetter.callCenterIcon')
}))`
  margin-top: 5px;
  width: 36px;
  height: 36px;
`

export const SubscribeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 45px;
`

export const SubscribeMessageWrapper = styled.div.attrs(() => ({
  className: e2eClasses('footer.newsLetter.subscribeMessage')
}))`
  display: flex;
  align-items: center;
`

export const ButtonWrapper = styled.div`
  width: 180px;
  margin-top: 20px;
`

export const FooterContainerContact = styled.div.attrs(() => ({
  className: e2eClasses('footer.newsLetter.callCenterItemsBox')
}))`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 28px;

  ${mediaQueries.mobile`
    grid-template-columns: none;
  `}
`

export const Link = styled.a.attrs(() => ({
  className: e2eClasses('footer.newsLetter.callCenterLink')
}))`
  ${SubtitleStyles}
  text-decoration: none;
`

export const TitleWrapper = styled.div.attrs(() => ({
  className: e2eClasses('footer.newsLetter.callCenterTitleBox')
}))`
  display: flex;
  align-items: center;
  gap: 10px;
`
