import { createActions } from 'redux-actions'

const {
  resetPaymentStatus,
  resetSecondPaymentStatus,
  changePaymentStatus,
  changeSecondPaymentStatus
} = createActions({
  RESET_PAYMENT_STATUS: () => {},
  RESET_SECOND_PAYMENT_STATUS: () => {},
  CHANGE_PAYMENT_STATUS: state => state,
  CHANGE_SECOND_PAYMENT_STATUS: state => state
})

const clearPaymentStatus = () => {
  return dispatch => {
    dispatch(resetPaymentStatus())
  }
}
const clearSecondPaymentStatus = () => {
  return dispatch => {
    dispatch(resetSecondPaymentStatus())
  }
}

const setPaymentStatus = creditCard => {
  return dispatch => {
    dispatch(changePaymentStatus({ creditCard }))
  }
}
const setSecondPaymentStatus = secondCreditCard => {
  return dispatch => {
    dispatch(changeSecondPaymentStatus({ secondCreditCard }))
  }
}

export {
  changePaymentStatus,
  setPaymentStatus,
  clearPaymentStatus,
  clearSecondPaymentStatus,
  resetPaymentStatus,
  resetSecondPaymentStatus,
  setSecondPaymentStatus,
  changeSecondPaymentStatus
}
