export default {
  DATE: 'YYYYMMDD',
  DAY_AND_MONTH_NAME: 'D-MMMM',
  DAY_AND_MONTH_FULLNAME: 'DD MMMM',
  MONTH_AND_YEAR: 'MMMM YYYY',
  YEAR_MONTH_NUMBER_DAY_NUMBER: 'YYYY-MM-DD',
  HOUR_AND_MINUTES: 'HH:mm',
  DAY_MONTH_YEAR: 'DD/MM/YYYY',
  DAY_MONTH_YEAR_DASH: 'DD-MM-YYYY',
  MONTH_DAY_YEAR_DASH: 'MM-DD-YYYY',
  DAY_MONTH: 'DD/MM',
  DAY_PICKER_FORMAT: 'DD/MM/YYYY',
  YEAR_MONTH_DAY_DASH: 'YYYY-MM-DD',
  RE_SEARCH_BOX_HEADER: 'ddd  - DD/MM',
  DAY_NAME: 'dddd DD',
  MONTH_YEAR_NAME: ' MMMM, YYYY',
  DAY_NAME_SHORT: 'ddd DD',
  DAY_MONTH_YEAR_SHORT: 'DD/MM/YY',
  YEAR_MONTH_DAY_SLASH: 'YYYY/MM/DD',
  ISO_WITHOUT_MILLISECONDS: 'YYYY-MM-DDTHH:mm:ss[Z]',
  ISO_WITH_HOURS_SECONDS: 'YYYY-MM-DDTHH:mm',
  LOCALIZED: 'L',
  DAY_JS_HOURS_MINUTES: 'HH[h] mm[m]'
}
