import { handleActions } from 'redux-actions'
import {
  fetchFareRulesStart,
  fetchFareRulesSuccess,
  fetchFareRulesError,
  clearFareRules
} from 'actions/fareRules'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const fareRules = handleActions(
  {
    [fetchFareRulesStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchFareRulesSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [fetchFareRulesError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    }),
    [clearFareRules]: () => defaultState
  },
  defaultState
)

export { fareRules }
