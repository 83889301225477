import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { apiSublos as api } from 'services/api'

const {
  sublosDateValidationStart,
  sublosDateValidationSuccess,
  sublosDateValidationError,
  sublosDateValidationClear
} = createActions({
  SUBLOS_DATE_VALIDATION_START: () => {},
  SUBLOS_DATE_VALIDATION_SUCCESS: data => ({ data }),
  SUBLOS_DATE_VALIDATION_ERROR: error => ({ error }),
  SUBLOS_DATE_VALIDATION_CLEAR: () => {}
})

const fetchDateValidation = ({
  companyCode,
  employeeCode,
  benefitType,
  ticketType
}) => async dispatch => {
  dispatch(sublosDateValidationStart())
  try {
    const response = await api.get(
      apiRoutes.SUBLOS_GET_DATE_VALIDATION({
        companyCode,
        employeeCode,
        benefitType,
        ticketType
      })
    )
    dispatch(sublosDateValidationSuccess(response.data))
  } catch (error) {
    dispatch(sublosDateValidationError(error.response ? error.response.data : error))
  }
}

export {
  fetchDateValidation,
  sublosDateValidationStart,
  sublosDateValidationSuccess,
  sublosDateValidationError,
  sublosDateValidationClear
}
