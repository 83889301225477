import React from 'react'

import styled, { keyframes, css } from 'styled-components'

import animationDesktopAR from 'animations/loader_bar.json'
import animationDesktop from 'animations/results.json'
import animationMobile from 'animations/results_mobile'
import { isMobile } from 'helpers/utils'

import AnimationLoader from '../animation-loader'

const TIME_SLEEP = 5 // second

const transitionStart = keyframes`
    0% { opacity:0;  left: 0;}
    3% { opacity:0}
    5% { opacity:1}
    95% {opacity:1; left:0;}
    97% {opacity:0;}
    100% {opacity:0; left: -500px;}
`

const transitionEnd = keyframes`
    0% { opacity:0;  left: 500px;}
    5% { opacity:0;}
    10% { opacity:1;  left: 0;}
    30% { opacity:1;  left: 0;}
    100% {opacity:1; left:0;}
`

const transitionLeft = keyframes`
    0% { opacity:0;  left: 500px;}
    3% { opacity:0;}
    5% { opacity:1;  left: 0;}
    95% {opacity:1; left:0;}
    97% {opacity:0;}
    100% {opacity:0; left: -500px;}
`

const animation = ({ animation, animationTime, iterationCount }) => css`
    ${animation} ${animationTime ? animationTime : '1s'} linear ${
  iterationCount ? iterationCount : 0
}
  `

const PurchaseLoaderWrapper = styled.div`
  ${({ theme, isEmpty }) => (isEmpty ? `background-color: ${theme.shadow.white90}` : '')};
  z-index: ${({ isEmpty }) => (isEmpty ? '1000' : '0')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ isEmpty }) => (isEmpty ? '' : 'position: relative;')}
  ${({ isEmpty }) => (isEmpty ? 'top:0' : 'top: 10%;')}
  ${({ isEmpty }) => (isEmpty ? '' : `animation: ${animation};`)}
`

const Text = styled.span`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme, isEmpty }) => (isEmpty ? theme.colors.doveGray : theme.colors.gray)};
  font-size: ${({ isEmpty }) => (isEmpty ? '14px' : '18px')};
  text-align: center;
  ${({ isEmpty }) => (isEmpty ? 'width: 60%;' : '')}
  ${({ isEmpty }) => (isEmpty ? '' : 'line-height: 2;')}


  & a {
    font-family: ${({ theme }) => theme.fonts.semiBold};
    color: ${({ theme }) => theme.colors.blue};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  color: ${({ theme }) => theme.colors.blue};
  text-align: center;
  font-size: 25px;
`

const Message = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, 50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  top: -75px;
  position: absolute;
  z-index: 9000;
`
export default class FullScreenPurchaseLoader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      index: 0,
      lastIndex: null,
      animation: null,
      dataSize: 0
    }

    this.stepInterval = null
  }

  componentWillUnmount() {
    if (typeof document !== 'undefined') {
      document.body.style.overflowY = ''
    }
    this.stepInterval && clearInterval(this.stepInterval)
  }

  componentDidMount() {
    if (typeof document !== 'undefined') {
      document.body.style.overflowY = 'hidden'
    }
    this.initialValues()
  }

  initialValues = () => {
    const { data } = this.props
    const dataSize = (data || []).length
    const animation =
      dataSize === 0
        ? isMobile(this.props.mediaType)
          ? animationMobile
          : animationDesktop
        : animationDesktopAR

    this.setState(
      {
        animation,
        dataSize,
        lastIndex: Math.max(dataSize - 1, 0)
      },
      () => dataSize > 0 && this.stepControl()
    )
  }

  stepControl = () => {
    const { sleepTime } = this.props
    if (!this.stepInterval) {
      this.stepInterval = setInterval(() => {
        const { index, lastIndex } = this.state
        this.setState(
          {
            index: this.increaseIndex(index, lastIndex)
          },
          () => {
            const { lastIndex, index } = this.state
            index === lastIndex && clearInterval(this.stepInterval)
          }
        )
      }, (sleepTime ? sleepTime : TIME_SLEEP) * 1000)
    }
  }

  increaseIndex = (index, lastIndex) => (index < lastIndex ? index + 1 : index)

  render() {
    const {
      data,
      sleepTime,
      mediaType,
      defaultTitle,
      defaultDescription,
      shouldShowPurchaseLoader
    } = this.props
    const { index, lastIndex, animation, dataSize } = this.state

    return (
      <PurchaseLoaderWrapper
        isEmpty={dataSize === 0}
        shouldShowPurchaseLoader={shouldShowPurchaseLoader}
      >
        {shouldShowPurchaseLoader && (
          <Column
            isEmpty={dataSize === 0}
            animation={
              index === 0 ? transitionStart : index === lastIndex ? transitionEnd : transitionLeft
            }
            iterationCount={'infinite'}
            animationTime={`${sleepTime ? sleepTime : TIME_SLEEP}s`}
          >
            {dataSize > 0 ? (
              <Image src={data[index].image} height={300} width={'100%'} />
            ) : (
              <>
                <Title>{defaultTitle ? defaultTitle : ''}</Title>
                <Text
                  isEmpty={dataSize === 0}
                  dangerouslySetInnerHTML={{ __html: defaultDescription ? defaultDescription : '' }}
                />
              </>
            )}
            <AnimationLoader animationData={animation} mediaType={mediaType} />
            {dataSize > 0 && (
              <>
                <Message>
                  <Title>{data[index].title}</Title>
                  <Text
                    isEmpty={dataSize === 0}
                    dangerouslySetInnerHTML={{ __html: data[index].description }}
                  />
                </Message>
              </>
            )}
          </Column>
        )}
      </PurchaseLoaderWrapper>
    )
  }
}
