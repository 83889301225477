import { handleActions } from 'redux-actions'

import {
  redeemPointsStart,
  redeemPointsSuccess,
  redeemPointsError,
  clearRedeemPoints
} from 'actions/arplus/redeemPoints'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const redeemPoints = handleActions(
  {
    [redeemPointsStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [redeemPointsSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [redeemPointsError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearRedeemPoints]: () => ({ ...defaultState })
  },
  defaultState
)

export default redeemPoints
