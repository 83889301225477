import { createActions } from 'redux-actions'

import { authBookingPost } from 'actions/authBooking'
import apiRoutes from 'constants/apiRoutes'
import { getLSValue, removeLSValue } from 'helpers/localStorage'
import { getBookingToken, getValue } from 'helpers/sessionStorage'
import { isEmpty } from 'helpers/utils'
import api, { apiBooking } from 'services/api'

const {
  fetchFlightsOffersStart,
  fetchFlightsOffersSuccess,
  fetchFlightsOffersError,
  setFlightsOffersUserSelection,
  resetFlightsOffers,
  fetchExchangeOffersStart,
  fetchExchangeOffersSuccess,
  fetchExchangeOffersError
} = createActions({
  FETCH_FLIGHTS_OFFERS_START: () => {},
  FETCH_FLIGHTS_OFFERS_SUCCESS: data => ({ data }),
  FETCH_FLIGHTS_OFFERS_ERROR: error => ({ error }),
  SET_FLIGHTS_OFFERS_USER_SELECTION: flightOffer => ({ flightOffer }),
  RESET_FLIGHTS_OFFERS: () => {},
  FETCH_EXCHANGE_OFFERS_START: () => {},
  FETCH_EXCHANGE_OFFERS_SUCCESS: data => ({ data }),
  FETCH_EXCHANGE_OFFERS_ERROR: error => ({ error })
})

const fetchExchangesOffers = (params, data) => {
  return async dispatch => {
    dispatch(fetchExchangeOffersStart())
    const { reservationCode, lastName } = data
    try {
      if (!getBookingToken()) {
        await dispatch(authBookingPost({ lastName, reservationCode }))
      }
      const flightOffers = await apiBooking.get(`${apiRoutes.FLIGHTS_OFFERS_EXCHANGES}${params}`)
      dispatch(fetchExchangeOffersSuccess(flightOffers.data))
    } catch (error) {
      dispatch(fetchExchangeOffersError(error.response ? error.response.data : error))
    }
  }
}

const fetchFlightsOffers = (params, corporateToken) => {
  /** @todo Remove this when https://aerolineas-argentinas.atlassian.net/browse/AAW-9119 is
   *  properly resolved*/

  const storedShopping = getLSValue('shoppingId') || getValue('shoppingId')

  if (!isEmpty(storedShopping)) {
    params += `&shoppingId=${storedShopping}`
  }
  /****************/

  return async dispatch => {
    dispatch(fetchFlightsOffersStart())

    try {
      if (corporateToken) {
        api.defaults.headers.common['X-Agreement-Token'] = `Bearer ${corporateToken}`
      }
      const data = await api.get(`${apiRoutes.FLIGHTS_OFFERS}${params}`)
      dispatch(fetchFlightsOffersSuccess(data))
    } catch (error) {
      const err = error.response ? error.response.data : error

      if (err.errorMessage === 'gds.flight.session.expired') {
        removeLSValue('shoppingId')
        params = params.split('&shoppingId')[0]
        try {
          const data = await api.get(`${apiRoutes.FLIGHTS_OFFERS}${params}`)
          dispatch(fetchFlightsOffersSuccess(data))
        } catch (error) {
          const err = error.response ? error.response.data : error
          dispatch(fetchFlightsOffersError(err))
        }
      } else {
        dispatch(fetchFlightsOffersError(err))
      }
    }
  }
}

export {
  fetchFlightsOffersStart,
  fetchFlightsOffersSuccess,
  fetchFlightsOffersError,
  fetchFlightsOffers,
  setFlightsOffersUserSelection,
  resetFlightsOffers,
  fetchExchangeOffersStart,
  fetchExchangeOffersSuccess,
  fetchExchangeOffersError,
  fetchExchangesOffers
}
