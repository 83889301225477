import { handleActions } from 'redux-actions'
import {
  generateSeatsSelection,
  changePassenger,
  seatSelected,
  setActiveLeg,
  setActivePassenger,
  selectSeatsStart,
  selectSeatsSuccess,
  selectSeatsError,
  clearSelectionResult
} from 'actions/seatsSelection'

const defaultState = {
  selection: null,
  activeLeg: null,
  activeSegment: null,
  activePassenger: null,
  currentPassenger: null,
  result: null
}

const generateNextSelection = (state, passengerCount) => {
  const { selection, activeLeg, activeSegment, activePassenger } = state
  const isSegmentFilled = selection[activeLeg][activeSegment].selection.length === passengerCount

  if (!isSegmentFilled) {
    return {
      activePassenger: activePassenger + 1 > passengerCount ? passengerCount : activePassenger + 1
    }
  }

  const nextSegment = selection[activeLeg][activeSegment + 1]
  if (nextSegment) {
    return {
      activeSegment: activeSegment + 1,
      activePassenger: 1
    }
  }

  return {
    activePassenger: activePassenger + 1 > passengerCount ? passengerCount : activePassenger + 1
  }
}

const isSeatAlreadyToken = (selection, seat, passenger) => {
  const seatSelected = selection.find(passenger => passenger.seat === seat)

  return seatSelected && seatSelected.passengerIndex !== passenger
}

const mergeResultWithSelection = (state, result) => {
  const selection = [...state.selection]
  const { operation } = result

  if (operation && operation.selections) {
    const unsuccessfulSelections = operation.selections.filter(seat => !seat.successful)

    unsuccessfulSelections.forEach(withError => {
      const {
        selectedSegment: { origin, destination },
        passengerIndex
      } = withError

      selection.forEach(leg => {
        const segment = leg.find(
          segment => segment.origin === origin && segment.destination === destination
        )

        if (segment) {
          const passenger = segment.selection.find(
            passengerSelection => passengerSelection.passengerIndex === passengerIndex
          )

          passenger.error = true
        }
      })
    })
  }

  return {
    selection
  }
}

const seatsSelection = handleActions(
  {
    [generateSeatsSelection]: (state, { payload: { legs } }) => {
      const selection = []

      legs.forEach(leg => {
        const legInfo = []

        leg.segmentSeatmaps.forEach(({ segment, reservations, selectable }) => {
          const { origin, destination, departure, airline, flightNumber } = segment
          const seatsAlreadyBooked = []
          const seatsSelection = []

          reservations.forEach(({ seatCode, passenger }) => {
            seatsSelection.push({
              passengerIndex: passenger.index,
              seat: seatCode
            })

            seatsAlreadyBooked.push({
              passengerIndex: passenger.index,
              seat: seatCode
            })
          })

          legInfo.push({
            origin,
            destination,
            departure,
            airline,
            flightNumber,
            selectable,
            seatsAlreadyBooked,
            selection: seatsSelection
          })
        })

        selection.push(legInfo)
      })

      return {
        ...state,
        selection,
        activeLeg: 0,
        activeSegment: 0,
        activePassenger: 1
      }
    },
    [setActiveLeg]: (state, { payload: { activeLeg } }) => ({
      ...state,
      activeLeg,
      activeSegment: 0,
      activePassenger: 1
    }),
    [setActivePassenger]: (state, { payload: { activePassenger } }) => ({
      ...state,
      activePassenger
    }),
    [changePassenger]: (state, { payload: { activePassenger, activeSegment } }) => ({
      ...state,
      activePassenger,
      activeSegment
    }),
    [seatSelected]: (state, { payload: { seat, passengerCount } }) => {
      const { activeLeg, activeSegment, activePassenger } = state
      const selection = [...state.selection]
      const segment = selection[activeLeg][activeSegment]

      if (isSeatAlreadyToken(segment.selection, seat, activePassenger)) {
        return {
          ...state
        }
      }

      const passengerInfo = segment.selection.find(
        passenger => passenger.passengerIndex === activePassenger
      )

      if (!passengerInfo) {
        segment.selection.push({
          passengerIndex: activePassenger,
          seat
        })

        return {
          ...state,
          ...generateNextSelection(state, passengerCount),
          selection
        }
      }

      passengerInfo.seat = passengerInfo.seat === seat ? null : seat
      delete passengerInfo.error

      return {
        ...state,
        selection
      }
    },
    [selectSeatsStart]: state => ({
      ...state,
      data: {},
      error: null,
      isSubmitting: true
    }),
    [selectSeatsSuccess]: (state, { payload: { result } }) => ({
      ...state,
      ...mergeResultWithSelection(state, result),
      result,
      error: null,
      isSubmitting: false
    }),
    [selectSeatsError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      data: {},
      error
    }),
    [clearSelectionResult]: state => ({
      ...state,
      result: null,
      selection: null
    })
  },
  defaultState
)

export default seatsSelection
