import { createActions } from 'redux-actions'
import axios from 'axios'
import CONFIG from 'config'

import apiRoutes from 'constants/apiRoutes'
import { getLSValue } from 'helpers/localStorage'
import { modalErrors } from 'actions/arplus/errorsArplus'

const { memberInfoStart, memberInfoSuccess, memberInfoError, clearMemberInfo } = createActions({
  MEMBER_INFO_START: () => {},
  MEMBER_INFO_SUCCESS: data => ({ data }),
  MEMBER_INFO_ERROR: error => ({ error }),
  CLEAR_MEMBER_INFO: () => {}
})

const memberInfoGet = memberCode => async dispatch => {
  dispatch(memberInfoStart())
  try {
    const response = await axios.get(`${apiRoutes.AR_PLUS_GET_MEMBER_INFO}${memberCode}`, {
      baseURL: CONFIG.API_BASE_URL,
      headers: {
        Authorization: `Bearer ${getLSValue('access_token')}`,
        'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID,
        'Accept-Language': CONFIG.DEFAULT_LOCALE
      }
    })
    dispatch(memberInfoSuccess(response.data))
  } catch (error) {
    const err = error.response ? error.response.data : error
    //TODO: Se envia data  de error al modal Logout
    if (err?.statusCode === 401) {
      const data = {
        show: true,
        redirection: '/',
        message: 'general.session-expired.description'
      }
      dispatch(modalErrors(data))
    }

    dispatch(memberInfoError(error.response ? error.response.data : error))
  }
}

export { memberInfoGet, memberInfoStart, memberInfoSuccess, memberInfoError, clearMemberInfo }
