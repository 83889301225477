import { batch } from 'react-redux'

import { resetCountries } from 'actions/catalog/countries'
import { clearFareRules } from 'actions/fareRules'
import { clearPaymentOptionsError } from 'actions/paymentOptions'
import { resetPaymentStatus } from 'actions/paymentStatus'
import { clearDocumentTypesRulesError } from 'actions/rules/checkout/documentTypes'
import { clearPassengerDataError } from 'actions/rules/checkout/passengerData'
import { clearSpecialRequestsError } from 'actions/rules/checkout/specialRequests'
import { documentTypesSublosClear } from 'actions/sublos/documentTypes'
import { setSublosError } from 'actions/sublos/errors'
import { RESET_SERVICES } from 'constants/sublos/errors'
import { STEPS } from 'constants/sublos/flow'
import { mapperError } from 'helpers/sublos/mapper-error'

export const paymentThunk = error => async dispatch => {
  batch(() => {
    error && dispatch(setSublosError(mapperError(error, STEPS.PAYMENT_DATA)))
  })
}

export const clearPaymentStateThunk = resetServices => async (dispatch, getState) => {
  const state = getState()
  const isResetAll = resetServices === RESET_SERVICES.RESET_ALL_SERVICES
  const {
    countries,
    fareRules,
    paymentStatus,
    paymentOptions,
    documentTypesRules,
    passengerDataRules,
    specialRequestsRules,
    documentTypesSublos
  } = state

  batch(() => {
    ;(countries.error || isResetAll) && dispatch(resetCountries())
    ;(fareRules.error || isResetAll) && dispatch(clearFareRules())
    ;(paymentStatus.error || isResetAll) && dispatch(resetPaymentStatus())
    ;(paymentOptions.error || isResetAll) && dispatch(clearPaymentOptionsError())
    ;(documentTypesRules.error || isResetAll) && dispatch(clearDocumentTypesRulesError())
    ;(passengerDataRules.error || isResetAll) && dispatch(clearPassengerDataError())
    ;(specialRequestsRules.error || isResetAll) && dispatch(clearSpecialRequestsError())
    ;(documentTypesSublos.error || isResetAll) && dispatch(documentTypesSublosClear())
  })
}
