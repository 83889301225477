export const IP_API_RESPONSE_FORMAT = {
  JSON: 'JSON',
  TEXT: 'TEXT'
}

export const MAIN_IP_API = {
  url: 'https://api.ipify.org?format=json',
  responseFormat: IP_API_RESPONSE_FORMAT.JSON,
  attrIp: 'ip'
}

export const FREE_IP_API = [
  {
    url: 'https://api64.ipify.org?format=json',
    responseFormat: IP_API_RESPONSE_FORMAT.JSON,
    attrIp: 'ip'
  },
  {
    url: 'https://wtfismyip.com/json',
    responseFormat: IP_API_RESPONSE_FORMAT.JSON,
    attrIp: 'YourFuckingIPAddress'
  },
  {
    url: 'https://icanhazip.com',
    responseFormat: IP_API_RESPONSE_FORMAT.TEXT,
    attrIp: undefined
  },
  {
    url: 'https://ifconfig.me/ip',
    responseFormat: IP_API_RESPONSE_FORMAT.TEXT,
    attrIp: undefined
  }
]

export const PAYMENT_IP_API = [
  {
    url: 'https://ipinfo.io/json',
    responseFormat: IP_API_RESPONSE_FORMAT.JSON,
    attrIp: 'ip'
  },
  {
    url: 'https://ip.tyk.nu',
    responseFormat: IP_API_RESPONSE_FORMAT.TEXT,
    attrIp: undefined
  }
]
