import { handleActions } from 'redux-actions'
import {
  getBaggageEquipamentClaimStart,
  getBaggageEquipamentClaimSuccess,
  getBaggageEquipamentClaimError,
  getBaggageEquipamentClaimClear,
  postEquipamentClaimStart,
  postEquipamentClaimSuccess,
  postEquipamentClaimError,
  postEquipamentClaimClear,

} from 'actions/common/equipamentClaim'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const baggageEquipamentClaim = handleActions(
  {
    [getBaggageEquipamentClaimStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [getBaggageEquipamentClaimSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [getBaggageEquipamentClaimError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [getBaggageEquipamentClaimClear]: () => ({ ...defaultState })
  },
  defaultState
)


const equipamentClaim = handleActions(
  {
    [postEquipamentClaimStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [postEquipamentClaimSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [postEquipamentClaimError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [postEquipamentClaimClear]: () => ({ ...defaultState })
  },
  defaultState
)



export { equipamentClaim, baggageEquipamentClaim }
