/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import {
  flightSummaryToPaymentOptionsPayload,
  getFlightSummaryFromReservation,
  getFlightSummaryFromMissingInfo,
  getFlightSummaryFromSublos
} from 'api-data-io/bo/evaluation'
import apiRoutes from 'constants/apiRoutes'
import PRODUCTS from 'constants/products'
import api, { apiSublos } from 'services/api'

const {
  fetchPaymentOptionsStart,
  fetchPaymentOptionsSuccess,
  fetchPaymentOptionsError,
  clearPaymentOptionsError,
  clearPaymentOptions
} = createActions({
  FETCH_PAYMENT_OPTIONS_START: () => {},
  FETCH_PAYMENT_OPTIONS_SUCCESS: data => data,
  FETCH_PAYMENT_OPTIONS_ERROR: error => ({ error }),
  CLEAR_PAYMENT_OPTIONS_ERROR: () => {},
  CLEAR_PAYMENT_OPTIONS: () => {}
})

const fetchPaymentOptionsFromReservation = reservation => {
  const payload = getFlightSummaryFromReservation(reservation)
  return fetchPaymentOptions(payload)
}

const fetchPaymentOptionsFromMissingInfo = (reservation, missingInfo) => {
  const payload = getFlightSummaryFromMissingInfo(reservation, missingInfo)
  return fetchPaymentOptions(payload, payload.product)
}

// TODO: Could be great to use just this to fetch as many products as needed
// and make them coexist in one paymentOptions redux state.
// i.e, {data: {[PRODUCT.SHOPPING]: shoppingData, [PRODUCT.BNPL]: bnplData, ...}, error, isFetching}
const fetchPaymentOptions = (flightSummary, product = PRODUCTS.SHOPPING, isMercadoPago) => {
  return async dispatch => {
    dispatch(fetchPaymentOptionsStart())

    try {
      const payload =
        isMercadoPago || product === PRODUCTS.MISSING_INFO
          ? flightSummary
          : flightSummaryToPaymentOptionsPayload(flightSummary, product)
      const paymentOptions = await api.post(apiRoutes.PAYMENT_OPTIONS, payload)

      dispatch(fetchPaymentOptionsSuccess(paymentOptions))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(fetchPaymentOptionsError(err))
    }
  }
}

const fetchPaymentOptionsArplusMiles = (payload, product) => {
  return async dispatch => {
    dispatch(fetchPaymentOptionsStart())

    try {
      const paymentOptions = await api.post(apiRoutes.PAYMENT_OPTIONS, { ...payload, product })

      dispatch(fetchPaymentOptionsSuccess(paymentOptions))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(fetchPaymentOptionsError(err))
    }
  }
}

const fetchPaymentOptionsSublos = reservation => {
  return async dispatch => {
    dispatch(fetchPaymentOptionsStart())
    try {
      const payload = getFlightSummaryFromSublos(reservation)

      const paymentOptions = await apiSublos.post(apiRoutes.PAYMENT_OPTIONS, payload)

      dispatch(fetchPaymentOptionsSuccess(paymentOptions))
    } catch (error) {
      dispatch(fetchPaymentOptionsError(error.response ? error.response.data : error))
    }
  }
}

export {
  fetchPaymentOptions,
  fetchPaymentOptionsStart,
  fetchPaymentOptionsSuccess,
  fetchPaymentOptionsError,
  clearPaymentOptionsError,
  clearPaymentOptions,
  fetchPaymentOptionsFromReservation,
  fetchPaymentOptionsFromMissingInfo,
  fetchPaymentOptionsArplusMiles,
  fetchPaymentOptionsSublos
}
