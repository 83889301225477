import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import { buildURLEncoded } from 'helpers/url'

const { fetchCabinsStart, fetchCabinsSuccess, fetchCabinsError } = createActions({
  FETCH_CABINS_START: () => {},
  FETCH_CABINS_SUCCESS: cabins => cabins,
  FETCH_CABINS_ERROR: error => error
})

const fetchCabins = pageConfig => {
  const queryParams = buildURLEncoded('', pageConfig)

  return async dispatch => {
    dispatch(fetchCabinsStart())

    try {
      const cabins = await api.get(`${apiRoutes.CABINS}${queryParams}`)
      dispatch(fetchCabinsSuccess(cabins))
    } catch (error) {
      dispatch(fetchCabinsError(error))
    }
  }
}

export { fetchCabinsStart, fetchCabinsSuccess, fetchCabinsError, fetchCabins }
