import { createActions } from 'redux-actions'
import { cmsApi } from 'services/api'
import cmsApiRoutes from 'constants/cmsApiRoutes'

const {
  fetchPopularDestinationsStart,
  fetchPopularDestinationsSuccess,
  fetchPopularDestinationsError
} = createActions({
  FETCH_POPULAR_DESTINATIONS_START: () => {},
  FETCH_POPULAR_DESTINATIONS_SUCCESS: data => ({ data }),
  FETCH_POPULAR_DESTINATIONS_ERROR: error => ({ error })
})

const fetchPopularDestinations = () => {
  return async dispatch => {
    dispatch(fetchPopularDestinationsStart())

    try {
      const popularDestinations = await cmsApi.get(cmsApiRoutes.POPULAR_DESTINATIONS)
      dispatch(fetchPopularDestinationsSuccess(popularDestinations))
    } catch (error) {
      dispatch(fetchPopularDestinationsError(error))
    }
  }
}

export {
  fetchPopularDestinationsStart,
  fetchPopularDestinationsSuccess,
  fetchPopularDestinationsError,
  fetchPopularDestinations
}
