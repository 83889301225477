import { createActions } from 'redux-actions'
import stepsMock from '../components/step-bar/mock'

const {
  fetchStepsStart,
  fetchStepsSuccess,
  fetchStepsError,
  setSelectedStep,
  setSteps,
  setDisableSteps,
  resetSteps
} = createActions({
  FETCH_STEPS_START: () => {},
  FETCH_STEPS_SUCCESS: data => ({ data }),
  FETCH_STEPS_ERROR: error => ({ error }),
  SET_SELECTED_STEP: stepBarState => stepBarState,
  SET_STEPS: steps => steps,
  SET_DISABLE_STEPS: disable => disable,
  RESET_STEPS: () => {}
})

const fetchSteps = () => {
  return dispatch => {
    dispatch(fetchStepsStart())

    try {
      // Need to change when stepbar service is ready.
      const data = stepsMock
      dispatch(fetchStepsSuccess(data))

      if (data) {
        dispatch(setSelectedStep({ currentStep: { selectedStep: data.steps[0].key, position: 0 } }))
      }
    } catch (error) {
      dispatch(fetchStepsError(error))
    }
  }
}

const changeCurrentStep = (selectedStep, position) => {
  return dispatch => {
    // CurrentStep is the redux state
    // SelectedStep is the value when user click step.
    dispatch(setSelectedStep({ currentStep: { selectedStep, position } }))
  }
}

const disableSteps = (disable = true) => {
  return dispatch => {
    dispatch(setDisableSteps({disable}))
  }
}

export {
  fetchStepsStart,
  fetchStepsSuccess,
  fetchStepsError,
  fetchSteps,
  setSelectedStep,
  changeCurrentStep,
  setSteps,
  setDisableSteps,
  disableSteps,
  resetSteps
}
