import { handleActions } from 'redux-actions'
import {
  offlinePartnerConfirmationStart,
  offlinePartnerConfirmationSuccess,
  offlinePartnerConfirmationError,
  clearOfflinePartnerConfirmationState
} from 'actions/arplus/offlinePartnerConfirmation'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const offlinePartnerConfirmation = handleActions(
  {
    [offlinePartnerConfirmationStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [offlinePartnerConfirmationSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [offlinePartnerConfirmationError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    }),
    [clearOfflinePartnerConfirmationState]: () => ({ ...defaultState })
  },
  defaultState
)

export default offlinePartnerConfirmation
