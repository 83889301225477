import styled from 'styled-components'
import loadingFlightsImage from 'assets/loader-checkout-postventa-izq.gif'

//Implement gif when is ready.
const FormLoader = styled.img.attrs(() => ({
  src: loadingFlightsImage,
  alt: 'Loading...'
}))`
  width: 100%;
`

export default FormLoader
