import { handleActions } from 'redux-actions'

import {
  firstAccessSetPasswordStart,
  firstAccessSetPasswordSuccess,
  firstAccessSetPasswordError,
  clearFirstAccessSetPasswordState
} from 'actions/arplus/firstAccessSetPassword'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const firstAccessSetPassword = handleActions(
  {
    [firstAccessSetPasswordStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [firstAccessSetPasswordSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [firstAccessSetPasswordError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    }),
    [clearFirstAccessSetPasswordState]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default firstAccessSetPassword
