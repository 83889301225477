import { handleActions } from 'redux-actions'
import {
  getFlightScheduleStart,
  getFlightScheduleSuccess,
  getFlightScheduleError,
  setFlightScheduleModalData,
  resetFlightScheduleModalData
} from 'actions/flightSchedule'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const flightSchedule = handleActions(
  {
    [getFlightScheduleStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [getFlightScheduleSuccess]: (state, { payload: { data } }) => {
      return {
        ...state,
        data: data.data,
        isFetching: false
      }
    },
    [getFlightScheduleError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: {},
      error
    })
  },
  defaultState
)

const scheduledFlightDefaultData = null

const scheduledFlightModalData = handleActions(
  {
    [setFlightScheduleModalData]: (state, { payload: { data } }) => ({ ...data }),
    [resetFlightScheduleModalData]: () => scheduledFlightDefaultData
  },
  scheduledFlightDefaultData
)

export { flightSchedule, scheduledFlightModalData }
