/* eslint-disable import/no-cycle */
import { decodeURLParams } from 'helpers/url/cms'

export const mapSlider = slider => ({
  image: slider.slider_image,
  url: decodeURLParams(slider.slider_image_url),
  title: slider.title,
  subtitle: slider.slider_image_subtitle,
  description: slider.slider_image_description,
  buttonName: slider.slider_image_button,
  buttonUrl: decodeURLParams(slider.slider_image_url_button),
  alt: slider.slider_image_alt_text,
  logo: slider.slider_image_logo,
  logoWidth: slider.slider_image_logo_width,
  logoHeigth: slider.slider_image_logo_heigth,
  showTitle: slider.slider_image_show_title,
  transition: slider.slider_image_transition,
  imageResponsive: slider.slider_image_responsive,
  dataDescription: slider.data_description,
  dataSection: slider.data_section
})

export const parseDataSlider = sliders =>
  (sliders || []).map(slider => ({
    ...slider,
    mainSliderReference: (slider.mainSliderReference || []).map(sliderReference => ({
      ...sliderReference,
      showSlider: canShowSlider(sliderReference.validity_range)
    })),
    showSlider: canShowSlider(slider.validityRange)
  }))

export const canShowSlider = validity_range => {
  const INITIAL_SEARCH = 'datetime="'
  const END_SEARCH = '"'
  const CURRENT_DATE = new Date()

  const indexStartDate1 = validity_range?.indexOf(INITIAL_SEARCH)
  const indexEndDate1 = validity_range?.indexOf(END_SEARCH, indexStartDate1 + INITIAL_SEARCH.length)
  const dateStart = new Date(
    validity_range.slice(indexStartDate1 + INITIAL_SEARCH.length, indexEndDate1)
  )

  const indexStartDate2 = validity_range?.indexOf(INITIAL_SEARCH, indexEndDate1)
  const indexEndDate2 = validity_range?.indexOf(END_SEARCH, indexStartDate2 + INITIAL_SEARCH.length)
  const dateEnd = new Date(
    validity_range.slice(indexStartDate2 + INITIAL_SEARCH.length, indexEndDate2)
  )

  return dateStart <= CURRENT_DATE && CURRENT_DATE <= dateEnd
}
