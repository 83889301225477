/* eslint-disable import/no-cycle */

import { PAYMENT_FLOW_TYPE } from 'constants/payment'

import { isExternalPaymentRoute } from '../routeMatchers'

export const isExternalPayment3P = location => {
  const matchPath = isExternalPaymentRoute(location)
  const paymentFlowType = matchPath?.params.paymentFlowType
  return (
    paymentFlowType === PAYMENT_FLOW_TYPE.THIRD_PARTY_CHECKOUT ||
    paymentFlowType === PAYMENT_FLOW_TYPE.THIRD_PARTY_EXCHANGE ||
    paymentFlowType === PAYMENT_FLOW_TYPE.THIRD_PARTY_ANCILLARIES
  )
}

export const isExternalPayment3PAncillaries = location => {
  const matchPath = isExternalPaymentRoute(location)
  const paymentFlowType = matchPath?.params.paymentFlowType
  return paymentFlowType === PAYMENT_FLOW_TYPE.THIRD_PARTY_ANCILLARIES
}

export const isExternalPayment3PCheckout = location => {
  const matchPath = isExternalPaymentRoute(location)
  const paymentFlowType = matchPath?.params.paymentFlowType
  return paymentFlowType === PAYMENT_FLOW_TYPE.THIRD_PARTY_CHECKOUT
}

export const isExternalPayment3PExchange = location => {
  const matchPath = isExternalPaymentRoute(location)
  const paymentFlowType = matchPath?.params.paymentFlowType
  return paymentFlowType === PAYMENT_FLOW_TYPE.THIRD_PARTY_EXCHANGE
}

export const isExternalPaymentMercadoPago = location => {
  const matchPath = isExternalPaymentRoute(location)
  const paymentFlowType = matchPath?.params.paymentFlowType
  return paymentFlowType === PAYMENT_FLOW_TYPE.MERCADO_PAGO
}
