/* eslint-disable import/no-cycle */
import STEPS from 'constants/checkoutStepBar'
import { isCheckoutRoute } from 'helpers/routeMatchers'
import { buildURLEncoded } from 'helpers/url'
import { generateCheckoutRoute, generateCheckoutAncillariesRoute } from 'helpers/url/localization'
import { getUrlParams } from 'helpers/url/parseUrl'

/**
 * @todo check if it could be implemented as route generator of 'helpers/url/localization'
 */
export const generateCheckoutStepURL = (params, step, language) => ({
  pathname: generateCheckoutRoute(language, step.toLowerCase()),
  search: buildURLEncoded('', { ...params })
})

/**
 * @todo check if it could be implemented as route generator of 'helpers/url/localization'
 */
export const generateAncillariesURL = (params, language) => ({
  pathname: generateCheckoutAncillariesRoute(language),
  search: buildURLEncoded('', { ...params })
})

/**
 * @todo check if it could be implemented as route generator of 'helpers/url/localization'
 */
export const generateCheckoutRedirectUrl = (params, step, language) =>
  buildURLEncoded(generateCheckoutRoute(language.toLowerCase(), step.toLowerCase()), { ...params })

/** @deprecated, use getShoppingIdFromLocation instead
 * **/
export const getShoppingId = history => {
  const { shoppingId } = getUrlParams(history.location.search.split('?').pop())

  return shoppingId || ''
}

/** @todo In case of fetching steps from an actual api (right now the action fetches mocked data),
 * this should be changed to use steps names from redux instead of fixed constants
 */
export const isValidCheckoutStep = matchPath => {
  const stepName = matchPath.params.step
  return STEPS[stepName.toUpperCase()] !== undefined
}

/** @todo In case of fetching steps from an actual api (right now the action fetches mocked data),
 * this should be changed to use steps names from redux instead of fixed constants
 */
export const getCurrentStep = matchPath => {
  const stepName = matchPath.params.step
  return STEPS[stepName.toUpperCase()]
}

export const isConfirmationStep = matchPath => {
  const stepName = matchPath.params.step
  return stepName.toUpperCase() === STEPS.CONFIRM
}

export const isPaymentStep = matchPath => {
  const stepName = matchPath.params.step
  return stepName.toUpperCase() === STEPS.PAYMENT
}

/** @todo getShoppingId method from above uses history.location which is not correct because history
 * location is mutable therefore it doesn't always show the actual location.
 * It should be replaced by this
 */
export const getShoppingIdFromLocation = location => {
  const { shoppingId } = getUrlParams(location.search.split('?').pop())
  return shoppingId || ''
}

export const getStepFromURL = location => {
  const checkoutMatch = isCheckoutRoute(location)

  if (!checkoutMatch) {
    throw new Error('The url is not well formed')
  }

  if (!isConfirmationStep(checkoutMatch) && !getShoppingIdFromLocation(location)) {
    throw new Error('There is no shoppingId param')
  }

  if (!isValidCheckoutStep(checkoutMatch)) {
    throw new Error('The step is invalid')
  }

  const currentStep = getCurrentStep(checkoutMatch)

  return currentStep
}

export const isConfirmationStepFromLocation = location => {
  const matchPath = isCheckoutRoute(location)
  const stepName = matchPath.params.step
  return stepName.toUpperCase() === STEPS.CONFIRM
}

export const isMercadoPagoExpired = expirationDate => {
  const currentDate = new Date().getTime()
  return (expirationDate - currentDate) / (1000 * 60) <= 0
}
