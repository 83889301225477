export const SIDEBAR_WIDTH = '300px'

//TODO: check component list
export const COMPONENTS = {
  HEADER: 'HEADER',
  MAIN: 'MAIN',

  TIMER: 'TIMER',
  LEG_SUMMARY: 'LEG_SUMMARY',
  RESERVATION_CODE: 'RESERVATION_CODE',
  EMAIL_ASSOCIATED_CARD: 'EMAIL_ASSOCIATED_CARD',
  TOTAL_FARE: 'TOTAL_FARE',

  TOTAL_PAYMENT: 'TOTAL_PAYMENT',
  PAYMENT_SUMMARY: 'PAYMENT_SUMMARY',
  ALERT_CONFIRMATION_SUCCESSFUL: 'ALERT_CONFIRMATION_SUCCESSFUL',
  ALERT_SUCCESS: 'ALERT_SUCCESS',
  ALERT_ERROR: 'ALERT_ERROR',
  ITINERARY: 'ITINERARY',
  CONTACT_EMAIL: 'CONTACT_EMAIL',
  CARD_DATA: 'CARD_DATA',
  PAYMENT_BOX: 'PAYMENT_BOX',
  PURCHASE_SUCCESS: 'PURCHASE_SUCCESS',
  PURCHASE_ERROR: 'PURCHASE_ERROR',

  SUMMARY_EXTRA_COMPONENT: 'SUMMARY_EXTRA_COMPONENT'
}

export const STEPS = {
  USER_DATA: 'userData',
  LEG_SELECTION: 'legSelection',
  SELECT_PASSENGERS: 'selectPassengers',
  NO_NAME_PASSENGER: 'noNamePassenger',
  VALIDATE_RESERVATION: 'validateReservation',
  FLIGHT_SELECTION: 'flightSelection',
  PASSENGERS_DOCUMENTATION: 'passengersDocumentation',
  RESERVATION_CONFIRMATION: 'reservationConfirmation',
  CONTINUE_TO_PAYMENT: 'continueToPayment',
  PAYMENT_DATA: 'paymentData',
  PURCHASE_CONFIRMATION: 'purchaseConfirmation',
  LOGIN: 'login'
}
