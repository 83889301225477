import ROUTES from 'constants/routes'
import NAMESPACES from 'localization/constants/namespaces'

export const getAllNamespacesToLoadOnInitForEnv = () => {
  const namespacesToLoadOnInit = [NAMESPACES.COMMON]

  Object.values(ROUTES || {}).forEach(route => {
    if (!route.isDisabled && route.namespaces) {
      route.namespaces.forEach(ns => {
        if (ns && !namespacesToLoadOnInit.includes(ns)) {
          namespacesToLoadOnInit.push(ns)
        }
      })
    }
  })

  return namespacesToLoadOnInit
}
