/* eslint-disable import/no-cycle */
import BRANDS from 'constants/brands'
import MARKET_TYPE from 'constants/marketType'
import NAMESPACES from 'localization/constants/namespaces'
import i18n from 'localization/i18n'

export const getKeysWithIndex = (key, max) => {
  let i = 0
  const translations = []
  while (i < max) {
    translations.push(`${key}-${i}`)
    i++
  }
  return translations
}

const getTranslationsWithIndex = (key, max) => {
  return getKeysWithIndex(key, max).map(trKey => i18n.isInitialized && i18n.t(trKey))
}

export const getWeekdaysAsArray = () => getTranslationsWithIndex('label.weekdays', 7)
export const getWeekdaysShortAsArray = () => getTranslationsWithIndex('label.weekdays-short', 7)
export const getMonthsAsArray = () => getTranslationsWithIndex('label.months', 12)
export const getMonthsShortAsArray = () => getTranslationsWithIndex('label.months-short', 12)

export const getScheduledFlightsSubtitles = () =>
  getTranslationsWithIndex(`${NAMESPACES.SCHEDULED_FLIGHTS}:scheduled-flights.banner.subtitle`, 2)

export const getBaggageModalAllLegsLines = (brandId, market) => {
  if (!brandId || !market) return []

  if (market == MARKET_TYPE.CABOTAGE) {
    if (brandId == BRANDS.PROMO)
      return getKeysWithIndex(
        `shopping.flight-offers.baggage-modal.all-legs-promo-national-subline`,
        3
      )
    if (brandId == BRANDS.BASE)
      return getKeysWithIndex(`shopping.flight-offers.baggage-modal.all-legs-base-subline`, 3)
  } else {
    //is regional or international
    if (brandId == BRANDS.BASE)
      return getKeysWithIndex(`shopping.flight-offers.baggage-modal.all-legs-base-subline`, 3)
    if (brandId == BRANDS.PROMO)
      return getKeysWithIndex(
        `shopping.flight-offers.baggage-modal.all-legs-promo-not-national-subline`,
        3
      )
  }

  return []
}

export const getBaggageModalSomeLegsLines = (brandId, market) => {
  if (!brandId || !market) return []

  if (market == MARKET_TYPE.CABOTAGE) {
    if (brandId == BRANDS.PROMO)
      return getKeysWithIndex(
        `shopping.flight-offers.baggage-modal.some-leg-promo-national-subline`,
        3
      )
    if (brandId == BRANDS.BASE)
      return getKeysWithIndex(`shopping.flight-offers.baggage-modal.some-leg-base-subline`, 3)
  } else {
    //is regional or international
    if (brandId == BRANDS.BASE)
      return getKeysWithIndex(`shopping.flight-offers.baggage-modal.some-leg-base-subline`, 3)
    if (brandId == BRANDS.PROMO)
      return getKeysWithIndex(
        `shopping.flight-offers.baggage-modal.some-leg-promo-not-national-subline`,
        3
      )
  }

  return []
}

export const generateTranslationKeysForForms = (formsArray, translationKeys) => {
  const translationMap = {}

  formsArray.forEach(forms => {
    Object.keys(forms).forEach(key => {
      translationMap[forms[key]] = translationKeys[key]
    })
  })

  return translationMap
}
