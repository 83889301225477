import { handleActions } from 'redux-actions'

import {
  lastOperationsStart,
  lastOperationsSuccess,
  lastOperationsError,
  clearLastOperations
} from 'actions/arplus/getLastOperations'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [lastOperationsStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [lastOperationsSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [lastOperationsError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearLastOperations]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
