import { handleActions } from 'redux-actions'

import {
  claimContactUsStart,
  claimContactUsSuccess,
  claimContactUsError,
  claimContactUsClear,
  cacClaimsStart,
  cacClaimsSuccess,
  cacClaimsError,
  cacClaimsClear,
  claimContactUsReasonsSuggestionsStart,
  claimContactUsReasonsSuggestionsSuccess,
  claimContactUsReasonsSuggestionsError,
  claimContactUsExperiencePlacesStart,
  claimContactUsExperiencePlacesSuccess,
  claimContactUsExperiencePlacesError,
  claimContactUsAboutStart,
  claimContactUsAboutSuccess,
  claimContactUsAboutError
} from 'actions/common/cacClaims'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const claimContactUs = handleActions(
  {
    [claimContactUsStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [claimContactUsSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [claimContactUsError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [claimContactUsClear]: () => ({ ...defaultState })
  },
  defaultState
)

const claimsContactUsReasonsSuggestions = handleActions(
  {
    [claimContactUsReasonsSuggestionsStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [claimContactUsReasonsSuggestionsSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [claimContactUsReasonsSuggestionsError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    })
  },
  defaultState
)

const claimsContactUsExperiencePlaces = handleActions(
  {
    [claimContactUsExperiencePlacesStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [claimContactUsExperiencePlacesSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [claimContactUsExperiencePlacesError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    })
  },
  defaultState
)

const claimsContactUsAbout = handleActions(
  {
    [claimContactUsAboutStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [claimContactUsAboutSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [claimContactUsAboutError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    })
  },
  defaultState
)

const cacClaims = handleActions(
  {
    [cacClaimsStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [cacClaimsSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [cacClaimsError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [cacClaimsClear]: () => ({ ...defaultState })
  },
  defaultState
)

export {
  claimContactUs,
  cacClaims,
  claimsContactUsReasonsSuggestions,
  claimsContactUsExperiencePlaces,
  claimsContactUsAbout
}
