import { createActions } from 'redux-actions'
import { cmsApi } from 'services/api'
import cmsApiRoutes from 'constants/cmsApiRoutes'

const { fetchAlertsStart, fetchAlertsSuccess, fetchAlertsError } = createActions({
  FETCH_ALERTS_START: () => {},
  FETCH_ALERTS_SUCCESS: data => ({ data }),
  FETCH_ALERTS_ERROR: error => ({ error })
})

const fetchAlerts = () => {
  return async dispatch => {
    dispatch(fetchAlertsStart())

    try {
      const alerts = await cmsApi.get(cmsApiRoutes.ALERTS)
      dispatch(fetchAlertsSuccess(alerts))
    } catch (error) {
      dispatch(fetchAlertsError(error))
    }
  }
}

export { fetchAlertsStart, fetchAlertsSuccess, fetchAlertsError, fetchAlerts }
