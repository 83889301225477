/* eslint-disable import/no-cycle */
import CONFIG from 'config'
import { getCabinCodeName } from 'helpers/popularDestinations'
import { decodeURLParams } from 'helpers/url/cms'

export const mapPopularDestination = item => ({
  id: item.id,
  tab: item.popular_market,
  image: CONFIG.CONTENT_CDN_BASE_URL + item.popular_image,
  alt: item.popular_alt_text,
  originLabel: item.popular_origin_text,
  destinationLabel: item.popular_destination_text,
  fare: item.popular_fare && item.popular_fare.replace(/,00$/, ``),
  currency: item.popular_currency,
  cabin: getCabinCodeName(item.popular_cabin_class),
  url: decodeURLParams(item.popular_url),
  description: item.popular_description,
  title: item.title,
  highlighted: item.popular_highlighted,
  origin: item.popular_iata_origin,
  destination: item.popular_iata_destinaton,
  departureDate: item.popular_departure_date,
  flexDates: item.popular_flex_dates,
  arrivalDate: item.popular_arrival_date,
  adt: item.popular_adt,
  chd: item.popular_chd,
  inf: item.popular_inf,
  dataDescription: item.data_description,
  dataSection: item.data_section
})
