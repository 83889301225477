/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect'

import { mapLanding } from 'api-data-io/cms/landings'
import { mapPopularDestination } from 'api-data-io/cms/popularDestination'
import { landingWithCardsContainer } from 'helpers/cms'
import { logError } from 'helpers/log'
import { decodeURLParams } from 'helpers/url/cms'
import { isEmpty } from 'helpers/utils'

const getLandingsState = state => state.landings

export const getLandingPageState = createSelector([getLandingsState], landings => {
  return landings
})

export const getLandingPageData = createSelector([getLandingsState], landings => {
  return landings && landings.data
})

export const isFetchingLandings = createSelector(
  [getLandingsState],
  state => state && state.isFetching
)

export const getLanding = createSelector([getLandingPageData], landing => {
  if (!isEmpty(landing)) {
    let result = []
    try {
      result = mapLanding(landing[0])
    } catch (error) {
      const landingInfo = {
        id: landing && landing[0].nid,
        title: landing && landing[0].title,
        url: decodeURLParams(landing[0].landingPageUrl)
      }

      logError('Landing parsing error', landingInfo, error)

      return {}
    }

    return landingWithCardsContainer(result)
  }
  return {}
})

export const getAttachedMenu = createSelector([getLandingPageData], landings => {
  const attachedItems = []
  if (!isEmpty(landings)) {
    landings.forEach(data => {
      data.landingSectionReference.forEach(items => {
        const key = Object.keys(items).find(items => items.includes('attached_menu'))
        items[key] &&
          attachedItems.push({
            landingId: data.nid,
            title: items.title,
            id: items.id,
            attachedMenu: items.header_image_attached_menu
          })

        Object.values(items).forEach(subitems => {
          subitems instanceof Array &&
            subitems.forEach(subitem => {
              const subkey = Object.keys(subitem).find(subitem => subitem.includes('attached_menu'))

              subitem[subkey] &&
                attachedItems.push({
                  landingId: data.nid,
                  title: subitem.title,
                  id: subitem.id,
                  attachedMenu: subitem.header_image_attached_menu
                })
            })
        })
      })
    })
  }
  return attachedItems
})

export const getUrlLandings = createSelector([getLandingPageData], landings => {
  if (!isEmpty(landings)) {
    return landings.map(data => ({
      id: data.nid,
      url: data.landingPageUrl
    }))
  }
  return []
})

export const getPopularDestinations = createSelector([getLandingPageData], landings => {
  if (!isEmpty(landings)) {
    const destinations = landings[0] && landings[0].landingSectionReference
    return destinations
      .filter(({ popular_market }) => popular_market)
      .map(item => mapPopularDestination(item))
  }
  return null
})

export const getLandingCampaignId = createSelector(
  [getLanding],
  // this data has already mapped fields with names from api-data-io/cms/landings
  data => data && data.campaignId
)
