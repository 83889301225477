import styled from 'styled-components'

import { common as e2eClasses } from 'constants/e2eClasses'
import { toTesteableId } from 'helpers/utils'

export const StyledLabel = styled.label.attrs(props => ({
  className: `${e2eClasses.inputTitle}${toTesteableId(props.e2eClass || '')}`,
  htmlFor: props.forId
}))`
  display: block;
  color: ${({ theme }) => theme.colors.doveGray};
  font-weight: 600;
  padding: 15px 0px;
   ${({ light }) => !light && `letter-spacing: 1px;`}
  font-family: ${({ theme }) => theme.fonts.semiBold};
  ${({ fontSize }) =>
    fontSize &&
    `
    font-size: ${fontSize};
  `}
`
