import { LANGUAGE_TAGS } from 'localization/constants/languages'

const DEFAULT = {
  CUIT: 'CUIT',
  CUIL: 'CUIL',
  CDI: 'CDI',
  CUITFACT: 'CUITFACT',
  NONRES: 'NONRES'
}

export const PAYMENT_CATEGORIES_ES_BO = {
  NIT: 'NIT'
}

export const PAYMENT_CATEGORIES_ES_PE = {
  RUC: 'RUC',
  UNDECLARED: 'UNDECLARED'
}

export const getPaymentCategoriesByLanguage = lang => {
  switch ((lang || '').toLocaleLowerCase()) {
    case LANGUAGE_TAGS.BOLIVIA.toLocaleLowerCase():
      return PAYMENT_CATEGORIES_ES_BO
    case LANGUAGE_TAGS.PERU.toLocaleLowerCase():
      return PAYMENT_CATEGORIES_ES_PE
    default:
      return DEFAULT
  }
}

export default { DEFAULT }
