import { createSelector } from 'reselect'
import CONFIG from 'config'
import { isEmpty } from 'helpers/utils'

const getFooterState = state => state.footer

const getFooterData = createSelector([getFooterState], state => (state ? state.data : null))

export const getFooter = createSelector([getFooterData], footer => {
  if (!isEmpty(footer)) {
    return Object.values(
      footer.map(data => ({
        tac: data.termConditionUrl && data.termConditionUrl.replace(/&amp;/g, `&`),
        submenu: (data.menuFooterReference || []).map(submenu => ({
          name: submenu.title,
          alt: submenu.footer_menu_altern_text,
          link: submenu.footer_menu_url && submenu.footer_menu_url.replace(/&amp;/g, `&`)
        })),
        social: (data.socialNetworksReference || []).map(social => ({
          id: social.id,
          name: social.title,
          alt: social.social_networks_alt_text,
          icon: CONFIG.CONTENT_CDN_BASE_URL + social.social_networks_logo,
          url: social.social_networks_url && social.social_networks_url.replace(/&amp;/g, `&`)
        })),
        institutional: (data.institutionalReference || []).map(item => ({
          logo: CONFIG.CONTENT_CDN_BASE_URL + item.institutional_link_logo,
          url: item.institutional_link_url && item.institutional_link_url.replace(/&amp;/g, `&`),
          mobile: item.institutional_mobile,
          alt: item.institutional_alt_text,
          funnel: item.shopping_funnel
        })),
        afip: (data.afipReference || []).map(item => ({
          logo: CONFIG.CONTENT_CDN_BASE_URL + item.footer_afip_logo,
          url: item.footer_afip_url && item.footer_afip_url.replace(/&amp;/g, `&`),
          alt: item.footer_afip_alt_text
        }))
      }))
    )[0]
  }
  return []
})
