/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  verifyAccountStart,
  verifyAccountSuccess,
  verifyAccountError,
  clearVerifyAccountState
} = createActions({
  VERIFY_ACCOUNT_START: () => {},
  VERIFY_ACCOUNT_SUCCESS: data => ({ data }),
  VERIFY_ACCOUNT_ERROR: error => ({ error }),
  CLEAR_VERIFY_ACCOUNT_STATE: () => {}
})

const verifyAccount = token => async dispatch => {
  dispatch(verifyAccountStart())
  try {
    const response = await api.post(apiRoutes.AR_PLUS_VERIFY_ACCOUNT(token))
    dispatch(verifyAccountSuccess(response.status))
  } catch (error) {
    dispatch(verifyAccountError(error.response ? error.response.data : error))
  }
}

export {
  verifyAccount,
  verifyAccountStart,
  verifyAccountSuccess,
  verifyAccountError,
  clearVerifyAccountState
}
