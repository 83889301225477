// eslint-disable-next-line import/no-cycle
import React from 'react'

import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import barAnimation from 'animations/loader_bar.json'
import animationDesktop from 'animations/results.json'
import animationMobile from 'animations/results_mobile'
import { isMobile } from 'helpers/utils'
import { mapMediaType } from 'selectors/mediaType'

import AnimationLoader from '../../animation-loader'
import { BarAnimationWrapper, ImageAnimationWrapper, Text, Title, Message } from './styled'

class ExternalPurchaseLoader extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { mediaType } = this.props

    return (
      <>
        <ImageAnimationWrapper>
          <AnimationLoader
            animationData={isMobile(mediaType) ? animationMobile : animationDesktop}
            mediaType={mediaType}
          />
        </ImageAnimationWrapper>

        <BarAnimationWrapper mediaType={mediaType}>
          <AnimationLoader animationData={barAnimation} mediaType={mediaType} />
        </BarAnimationWrapper>

        <Message>
          <Title>{this.props.t('general.external-purchase-loader.title')}</Title>
          <Text
            dangerouslySetInnerHTML={{
              __html: this.props.t('general.external-purchase-loader.description')
            }}
          />
        </Message>
      </>
    )
  }
}

const mapDispatchToProps = {}

const mapStateToProps = state => ({
  mediaType: mapMediaType(state)
})

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ExternalPurchaseLoader)
)
