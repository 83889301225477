import { createActions } from 'redux-actions'
import { cmsApi } from 'services/api'
import cmsApiRoutes from 'constants/cmsApiRoutes'

const { fetchCategoriesStart, fetchCategoriesSuccess, fetchCategoriesError } = createActions({
  FETCH_CATEGORIES_START: () => {},
  FETCH_CATEGORIES_SUCCESS: data => ({ data }),
  FETCH_CATEGORIES_ERROR: error => ({ error })
})

const fetchCategories = () => {
  return async dispatch => {
    dispatch(fetchCategoriesStart())

    try {
      const categories = await cmsApi.get(cmsApiRoutes.CATEGORIES)
      dispatch(fetchCategoriesSuccess(categories))
    } catch (error) {
      dispatch(fetchCategoriesError(error))
    }
  }
}

export { fetchCategoriesStart, fetchCategoriesSuccess, fetchCategoriesError, fetchCategories }
