import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  sublosLoginModalStart,
  sublosLoginModalSuccess,
  sublosLoginModalError,
  clearSublosLoginModal
} = createActions({
  SUBLOS_LOGIN_MODAL_START: () => {},
  SUBLOS_LOGIN_MODAL_SUCCESS: data => ({ data }),
  SUBLOS_LOGIN_MODAL_ERROR: error => ({ error }),
  CLEAR_SUBLOS_LOGIN_MODAL: () => {}
})

const fetchSublosLoginModal = () => async dispatch => {
  dispatch(sublosLoginModalStart())
  try {
    const response = await api.get(apiRoutes.SUBLOS_LOGIN_MODAL)
    dispatch(sublosLoginModalSuccess(response.data))
  } catch (error) {
    dispatch(sublosLoginModalError(error.response ? error.response.data : error))
  }
}

export {
  fetchSublosLoginModal,
  sublosLoginModalStart,
  sublosLoginModalSuccess,
  sublosLoginModalError,
  clearSublosLoginModal
}
