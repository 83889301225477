import CONFIG from 'config'

import {
  generateMyReservationAncillariesRoute,
  generateMyReservationRoute,
  generateExchangesRoute,
  generateSeatSelectionRoute,
  generateSpecialRequestsRoute
} from 'helpers/url/localization'

const sections = {
  MY_BOOKING: 'MyBooking',
  ANCILLARIES: 'Ancillaries',
  EXCHANGES: 'Exchanges',
  CHECK_IN: 'CheckIn',
  SEAT_SELECTION: 'SeatSelection',
  SPECIAL_REQUESTS: 'SpecialRequests'
}

const generateReservationRoutes = language => ({
  [sections.MY_BOOKING]: generateMyReservationRoute(language),
  [sections.ANCILLARIES]: generateMyReservationAncillariesRoute(language),
  [sections.EXCHANGES]: generateExchangesRoute(language),
  [sections.CHECK_IN]: CONFIG.WEB_CHECKIN_URL,
  [sections.SEAT_SELECTION]: generateSeatSelectionRoute(language),
  [sections.SPECIAL_REQUESTS]: generateSpecialRequestsRoute(language)
})

export { sections, generateReservationRoutes }
