/* eslint-disable max-len */

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import COMPANIES from 'constants/contestCompanies'
import { getRouteByPathname } from 'helpers/routeMatchers'
import NAMESPACES from 'localization/constants/namespaces'
import { getDefaultSeoData } from 'selectors/cms/header'
import { getFormTitleForPathname } from 'server/services/helpers'
import { mapSeoData } from 'server/utils/seo'

export const useConfigMetaTags = () => {
  const { pathname } = useLocation()
  const route = getRouteByPathname(pathname)
  const { t } = useTranslation(NAMESPACES.SEO_METADATA)

  const { defaultSeoData } = useSelector(state => ({
    defaultSeoData: getDefaultSeoData(state)
  }))

  const loadMetaTagData = () => {
    let seoData = mapSeoData()
    if (pathname) {
      const isReservationServicesRoute = route?.name === 'RESERVATION_SERVICES'
      const isHomeRoute = route?.name === 'HOME'

      if (isReservationServicesRoute) {
        const seoFormData = getFormTitleForPathname(pathname)

        seoData.title = t(seoFormData?.title)
        seoData.description = t(seoFormData?.description)
      } else if (route) {
        seoData.title = t(route?.title)
        seoData.description = t(route?.description)

        if (!seoData.title || isHomeRoute) {
          seoData.title = defaultSeoData.title || COMPANIES.AEROLINEAS_ARGENTINAS
          seoData.description = defaultSeoData.description
        }
      }
    }

    return seoData
  }

  return {
    loadMetaTagData
  }
}
