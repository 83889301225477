import { isEmpty, toCamelCase } from 'helpers/utils'
import { getDayJsObjectFromLocal } from 'helpers/dates'
import { getUrlParams } from './url'
import SORT_TYPES from 'constants/sortTypes'
import { getKeysWithIndex } from 'helpers/translations'
import { getLSValue, removeLSValue } from 'helpers/localStorage'

export const getFlightsOffersDatesFromState = flightsSearchData => {
  if (!isEmpty(flightsSearchData)) {
    return (flightsSearchData.legs || []).map(leg => getDayJsObjectFromLocal(leg.departure))
  }

  return []
}

export const getResultType = (searchMetadata, searchParams) =>
  searchMetadata ? searchMetadata.resultType : searchParams ? searchParams.flightType : ''

export const getFlightType = (searchMetadata, searchParams) =>
  searchMetadata ? searchMetadata.flightType : searchParams ? searchParams.flightType : ''

export const getColumnsWidth = length => {
  switch (length) {
    case 1:
      return { detailsWidth: 57, faresWidth: 43 }

    case 2:
      return { detailsWidth: 51, faresWidth: 49 }

    case 3:
      return { detailsWidth: 29, faresWidth: 71 }

    default:
      return { detailsWidth: 35, faresWidth: 65 }
  }
}

const getInternationalizatedSortingOption = (
  optionCode = SORT_TYPES.FLIGHTS_DEFAULT_ORDER,
  translations
) => ({
  value: optionCode,
  label: translations(`gds.flights.sorting.${toCamelCase(optionCode)}`)
})

export const getOrderBySelected = (location, isRoundTrip, translations) => {
  const currentURL = location.search.split('?').pop()
  const { departingOrderBy, returningOrderBy, orderBy } = getUrlParams(currentURL)

  if (isRoundTrip) {
    return {
      departingOrderBy: getInternationalizatedSortingOption(departingOrderBy, translations),
      returningOrderBy: getInternationalizatedSortingOption(returningOrderBy, translations)
    }
  }

  return {
    orderBy: getInternationalizatedSortingOption(orderBy, translations)
  }
}

export const mapRoutes = routes =>
  (routes || [])
    .map(item => `&route=${item}`)
    .join('')
    .replace('&', '?')

export const translateFiltersLabels = (facets, translations) => {
  return (facets || []).map(facet => {
    const items = facet.items.map(item => {
      const text = item.text ? translations(item.text) : item.code
      const withCount = item.count ? `${text} (${item.count})` : text
      return { ...item, text: withCount }
    })

    return {
      ...facet,
      label: translations(facet.label),
      sublabel: facet.sublabel && translations(facet.sublabel),
      items
    }
  })
}

export const translateSortingOptions = (sortingOptions, translations) =>
  (sortingOptions || []).map(({ value, label }) => ({
    value,
    label: translations(label)
  }))

export const getFlightConditionsInfo = translations =>
  getKeysWithIndex('shopping.flight-offers.compare-conditions.info-message', 2).map(
    trKey => translations && translations(trKey)
  )

export const isUserLogged = () =>
  getLSValue('shoppingId') && getLSValue('access_token') ? true : false

export const LogOut = clearReduxState => {
  removeLSValue('shoppingId')
  removeLSValue('access_token')
  clearReduxState && clearReduxState()
}

export const clearShoppingId = () => {
  removeLSValue('shoppingId')
}
