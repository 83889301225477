import { handleActions } from 'redux-actions'
import { setActiveTab } from 'actions/tabsAction'

const defaultState = {
  activeTab: 'undefined'
}

const reducer = handleActions(
  {
    [setActiveTab]: (state, { payload: { activeTab } }) => {
      return {
        ...state,
        activeTab: activeTab
      }
    }
  },
  defaultState
)

export default reducer
