import { handleActions } from 'redux-actions'
import {
  fetchDocumentTypesRulesStart,
  fetchDocumentTypesRulesSuccess,
  fetchDocumentTypesRulesError,
  clearDocumentTypesRulesError
} from 'actions/rules/checkout/documentTypes'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchDocumentTypesRulesStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchDocumentTypesRulesSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [fetchDocumentTypesRulesError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearDocumentTypesRulesError]: state => ({
      ...state,
      error: null
    })
  },
  defaultState
)

export default reducer
