/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const { loginStart, loginSuccess, loginError, clearCorporateLogin } = createActions({
  LOGIN_START: () => {},
  LOGIN_SUCCESS: data => ({ data }),
  LOGIN_ERROR: error => ({ error }),
  CLEAR_CORPORATE_LOGIN: () => {}
})

const loginCorporate = data => {
  return async dispatch => {
    dispatch(loginStart())
    try {
      const loginStatus = await api.post(apiRoutes.CORPORATE_AGREEMENTS_LOGIN, data, {
        skipRetry: true
      })
      dispatch(loginSuccess(loginStatus.data))
    } catch (error) {
      dispatch(loginError(error.response ? error.response.data : error))
    }
  }
}

export { loginStart, loginSuccess, loginError, loginCorporate, clearCorporateLogin }
