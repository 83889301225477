import { handleActions } from 'redux-actions'
import {
  requestAgreementStart,
  requestAgreementSuccess,
  requestAgreementError,
  requestAgreementClear
} from 'actions/corporate/agreementRequest'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [requestAgreementStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [requestAgreementSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [requestAgreementError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [requestAgreementClear]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
