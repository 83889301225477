import { handleActions } from 'redux-actions'
import { catchApiError, clearApiError } from 'actions/apiError'

const defaultState = {
  catchedError: false
}

const reducer = handleActions(
  {
    [catchApiError]: (_, payload) => ({ catchedError: payload }),
    [clearApiError]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
