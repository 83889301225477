import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'

const {
  creditMilesOriginStart,
  creditMilesOriginSuccess,
  creditMilesOriginError,
  clearCreditMilesOrigin
} = createActions({
  CREDIT_MILES_ORIGIN_START: () => {},
  CREDIT_MILES_ORIGIN_SUCCESS: data => ({ data }),
  CREDIT_MILES_ORIGIN_ERROR: error => ({ error }),
  CLEAR_CREDIT_MILES_ORIGIN: () => {}
})

const fetchCreditMilesOrigin = airline => async dispatch => {
  dispatch(creditMilesOriginStart())
  try {
    const response = await api.get(`${apiRoutes.AR_PLUS_CREDIT_MILES_ORIGIN(airline)}`)
    dispatch(creditMilesOriginSuccess(response.data))
  } catch (error) {
    dispatch(creditMilesOriginError(error.response ? error.response.data : error))
  }
}

export {
  fetchCreditMilesOrigin,
  creditMilesOriginStart,
  creditMilesOriginSuccess,
  creditMilesOriginError,
  clearCreditMilesOrigin
}
