import { handleActions } from 'redux-actions'

import {
  getRefundStatusStart,
  getRefundStatusSuccess,
  getRefundStatusError,
  getRefundStatusClear
} from 'actions/ticketRefundStatus'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [getRefundStatusStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [getRefundStatusSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [getRefundStatusError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [getRefundStatusClear]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
