import { handleActions } from 'redux-actions'

import { getContestsStart, getContestsSuccess, getContestsError } from 'actions/catalog/contests'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [getContestsStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [getContestsSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data.data,
      isFetching: false
    }),
    [getContestsError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    })
  },
  defaultState
)

export default reducer
