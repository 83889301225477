import { handleActions } from 'redux-actions'
import {
  fetchPressReleasesStart,
  fetchPressReleasesSuccess,
  fetchPressReleasesError,
  clearPressReleases
} from 'actions/cms/pressReleases'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchPressReleasesStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchPressReleasesSuccess]: (
      state,
      {
        payload: {
          data: { data }
        }
      }
    ) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [fetchPressReleasesError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    }),
    [clearPressReleases]: () => defaultState
  },
  defaultState
)

export default reducer
