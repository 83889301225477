import { handleActions } from 'redux-actions'

import {
  recoverEmailStart,
  recoverEmailSuccess,
  recoverEmailError,
  clearRecoverEmailState
} from 'actions/arplus/recover-email'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const recoverEmail = handleActions(
  {
    [recoverEmailStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [recoverEmailSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [recoverEmailError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    }),
    [clearRecoverEmailState]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default recoverEmail
