import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import { buildURLEncoded } from 'helpers/url'

const { fetchBranchStart, fetchBranchSuccess, fetchBranchError } = createActions({
  FETCH_BRANCH_START: () => {},
  FETCH_BRANCH_SUCCESS: brands => brands,
  FETCH_BRANCH_ERROR: error => error
})

const fetchBranch = privinceCode => {

  return async dispatch => {
    dispatch(fetchBranchStart())

    try {
      const brands = await api.get(`${apiRoutes.BRANCHES(privinceCode)}`)
      dispatch(fetchBranchSuccess(brands))
    } catch (error) {
      dispatch(fetchBranchError(error))
    }
  }
}

export { fetchBranchStart, fetchBranchSuccess, fetchBranchError, fetchBranch }
