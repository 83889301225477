import { createActions } from 'redux-actions'

import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'

const {
  fetchSearchBoxRulesStart,
  fetchSearchBoxRulesSuccess,
  fetchSearchBoxRulesError
} = createActions({
  FETCH_SEARCH_BOX_RULES_START: () => {},
  FETCH_SEARCH_BOX_RULES_SUCCESS: data => ({ data }),
  FETCH_SEARCH_BOX_RULES_ERROR: error => ({ error })
})

const fetchSearchBoxRules = routes => {
  return async dispatch => {
    dispatch(fetchSearchBoxRulesStart())

    const routesParams = (routes || [])
      .map((route, index) => `${index ? '&' : '?'}route=${route}`)
      .join('')

    try {
      const flightOffers = await api.get(`${apiRoutes.SEARCH_BOX_RULES}${routesParams}`)
      dispatch(fetchSearchBoxRulesSuccess(flightOffers))
    } catch (error) {
      dispatch(fetchSearchBoxRulesError(error))
    }
  }
}

export {
  fetchSearchBoxRules,
  fetchSearchBoxRulesStart,
  fetchSearchBoxRulesSuccess,
  fetchSearchBoxRulesError
}
