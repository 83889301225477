import axios from 'axios'

import CONFIG from 'config'
import { getBookingToken } from 'helpers/sessionStorage'
import { getCurrentToken, specialErrorTreatment, setLanguageHeaders } from 'services/api'

const apiBookingV2 = axios.create({
  baseURL: CONFIG.API_BASE_URL.replace('v1', 'v2'),
  headers: {
    common: {
      Authorization: `Bearer ${getCurrentToken()}`
    },
    get: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    },
    post: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    }
  }
})

//APIBOOKINGv2 instance interceptors
const setApiBookingV2Interceptors = initializedStore => {
  apiBookingV2.interceptors.request.use(function(config) {
    const withLangHeaders = setLanguageHeaders(config)
    const token = getBookingToken()

    if (token !== null) {
      withLangHeaders.headers['X-Booking-Session'] = `Bearer ${token}`
    }

    return withLangHeaders
  })

  apiBookingV2.interceptors.response.use(
    response => response,
    error => {
      const { config, response } = error

      error.response && specialErrorTreatment(initializedStore, response && response.status)

      const originalRequest = config

      if (response && response.status === 400) {
        return axios(originalRequest)
      } else {
        return Promise.reject(error)
      }
    }
  )
}

export { apiBookingV2 as default, setApiBookingV2Interceptors }
