import { createSelector } from 'reselect'

import RESULT_TYPES from 'constants/resultTypes'
import { ERROR_TYPE } from 'constants/sublos/errors'
import { mapRequestError } from 'helpers/sublos/mapper-error'
import { isEmpty } from 'helpers/utils'

export const getAirportsSubstate = state => state.airports

export const getAirportsData = createSelector([getAirportsSubstate], airports =>
  airports ? airports.data : null
)

export const getAirports = createSelector([getAirportsData], airports =>
  airports ? airports.resources : []
)

export const getAirportsError = createSelector([getAirportsSubstate], state => state?.error || null)

export const getAirportsSublosError = createSelector(
  [getAirportsSubstate],
  ({ error }) => error && mapRequestError(error, ERROR_TYPE.SCREEN)
)

export const isFetchingAirports = createSelector(
  [getAirportsSubstate, getAirportsData],
  (state, data) => state.isFetching || isEmpty(data)
)

export const getCityCodesFromSegments = createSelector(
  selectorInputData => selectorInputData,
  selectorInputData => {
    const { airports, segments, flightType } = selectorInputData
    const isMultidestiny = flightType === RESULT_TYPES.MULTIDESTINY
    if (segments) {
      const segmentAmount = segments.length
      const destSegmentIndex = isMultidestiny && segmentAmount < 2 ? 0 : segmentAmount - 1
      const oAirportCode = segments[0].origin
      const dAirportCode = segments[destSegmentIndex].destination

      if (airports.data && airports.data.resources) {
        const airportsList = airports.data.resources
        const oAirportData = airportsList.find(item => item.iataCode === oAirportCode)
        const dAirportData = airportsList.find(item => item.iataCode === dAirportCode)
        const cityCodeOrigin = oAirportData && oAirportData.city && oAirportData.city.iataCode
        const cityCodeDestination = dAirportData && dAirportData.city && dAirportData.city.iataCode

        return { origin: cityCodeOrigin, destination: cityCodeDestination }
      }
      return { origin: oAirportCode, destination: dAirportCode }
    }

    return null
  }
)
