import styled from 'styled-components'

import mediaQueries from 'components/media-queries'
import e2eClasses from 'helpers/automation'

export const Container = styled.div.attrs(() => ({
  className: e2eClasses('footer.legal.box')
}))`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ showFooterCollapsible, theme }) =>
    showFooterCollapsible
      ? /*new footer*/ `
        background-color: ${theme.colors.white};
        justify-content: center;
        padding: 7px 1em 7px 1em;
      `
      : /*old footer*/ `
        background-color: ${({ theme: { colors } }) => colors.alabaster};
        flex-direction: column;
        padding: 1em;
  `};
`

export const BrandsImage = styled.img.attrs(() => ({
  className: e2eClasses('footer.legal.brandsOption')
}))`
  margin-bottom: 10px;
  height: 3em;
  max-height: 45px;
`

export const LegalText = styled.div`
  display: flex;
  margin-top: 5px;
`

export const LegalTextAndLink = styled.div``

export const CopyrightInformationParagraph = styled.p.attrs(() => ({
  className: e2eClasses('footer.legal.copyrightInformation')
}))`
  color: ${({ theme }) => theme.colors.doveGray};
  font-size: 12px;
  text-align: center;
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.regular};
  ${({ showFooterCollapsible }) => showFooterCollapsible && 'margin-right: 3px;'};

  ${mediaQueries.mobile`
    font-size: 10px;
  `};
`

export const FiscalDataImage = styled.img.attrs(() => ({
  className: e2eClasses('footer.legal.fiscalDataImage')
}))`
  margin: 10px;
  max-height: 40px;
  max-width: 34px;
  ${mediaQueries.mobile`
    margin: 0;
  `};
`

export const TermsAndConditionsLink = styled.a.attrs(() => ({
  className: e2eClasses('footer.legal.itemTermsAndConditions')
}))`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 12px;
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.semiBold};

  &:hover {
    color: ${({ theme }) => theme.colors.blueDark};
  }
`
export const Link = styled.a`
  height: 100%;
  margin: 3px;

  ${mediaQueries.mobile`
    margin-right: 13px;
  `};
`

export const BrandsContainer = styled.div.attrs(() => ({
  className: e2eClasses('footer.legal.brandsBox')
}))`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`
