import { handleActions } from 'redux-actions'
import {
  getClaimDocumentTypeStart,
  getClaimDocumentTypeSuccess,
  getClaimDocumentTypeError,
  getClaimDocumentTypeClear,
  postReservationAndServiceStart,
  postReservationAndServiceSuccess,
  postReservationAndServiceError,
  postReservationAndServiceClear,
} from 'actions/common/reservationAndService'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const claimDocumentType = handleActions(
  {
    [getClaimDocumentTypeStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [getClaimDocumentTypeSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [getClaimDocumentTypeError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [getClaimDocumentTypeClear]: () => ({ ...defaultState })
  },
  defaultState
)

const claimsReservationAndService = handleActions(
  {
    [postReservationAndServiceStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [postReservationAndServiceSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [postReservationAndServiceError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [postReservationAndServiceClear]: () => ({ ...defaultState })
  },
  defaultState
)


export { claimDocumentType, claimsReservationAndService }
