import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'

const {
  airlineCompaniesArplusStart,
  airlineCompaniesArplusSuccess,
  airlineCompaniesArplusError,
  clearAirlineCompaniesArplus
} = createActions({
  AIRLINE_COMPANIES_ARPLUS_START: () => {},
  AIRLINE_COMPANIES_ARPLUS_SUCCESS: data => ({ data }),
  AIRLINE_COMPANIES_ARPLUS_ERROR: error => ({ error }),
  CLEAR_AIRLINE_COMPANIES_ARPLUS: () => {}
})

const fetchAirlineCompaniesArplus = () => async dispatch => {
  dispatch(airlineCompaniesArplusStart())
  try {
    const response = await api.get(apiRoutes.AR_PLUS_AIRLINE_COMPANIES)
    dispatch(airlineCompaniesArplusSuccess(response.data))
  } catch (error) {
    dispatch(airlineCompaniesArplusError(error.response ? error.response.data : error))
  }
}

export {
  fetchAirlineCompaniesArplus,
  airlineCompaniesArplusStart,
  airlineCompaniesArplusSuccess,
  airlineCompaniesArplusError,
  clearAirlineCompaniesArplus
}
