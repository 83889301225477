import { handleActions } from 'redux-actions'

import {
  sublosGetDestinationsStart,
  sublosGetDestinationsSuccess,
  sublosGetDestinationsError,
  sublosGetDestinationsClear
} from 'actions/sublos/destinations'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const sublosGetDestinations = handleActions(
  {
    [sublosGetDestinationsStart]: state => ({
      ...state,
      error: null,
      isFetching: true
    }),
    [sublosGetDestinationsSuccess]: (state, { payload: { data } }) => ({
      data,
      error: null,
      isFetching: false
    }),
    [sublosGetDestinationsError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [sublosGetDestinationsClear]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default sublosGetDestinations
