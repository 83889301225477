import styled from 'styled-components'
import loaderMobile from 'assets/loader-de-resultados---mobile-responsive.gif'

const ComponentLoaderMobile = styled.img.attrs(() => ({
  src: loaderMobile,
  alt: 'Loading...'
}))`
  width: 100%;
  margin: 15px auto;
`

export default ComponentLoaderMobile
