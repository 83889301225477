import FORMATS from 'constants/formats'
// eslint-disable-next-line import/no-cycle
import { getDayJsObject } from 'helpers/dates'

const parseRoute = ({ origin, destination, departure }) =>
  `${origin}-${destination}-${getDayJsObject(departure).format(FORMATS.DATE)}`

const mapItinerary = data => ({
  key: data.key,
  stage: data.stage,
  titleKey: data.titleKey,
  legNumber: data.legNumber,
  isLastItem: data.isLastItem,
  flightNumber: data.flightNumber,
  duration: data.duration,
  origin: data.origin,
  departure: data.departure,
  departureAirport: data.departureAirport,
  destination: data.destination,
  arrival: data.arrival,
  arrivalAirport: data.arrivalAirport
})

export const RESERVATION_STAGE = {
  VALIDATE: 'VALIDATE',
  CONFIRMED: 'CONFIRMED',
  PURCHASED: 'PURCHASED'
}

export const getOrderIdParams = details =>
  (details || []).map(({ orderId }) => `&orderId=${orderId}`).join('&')

export const mapLegsValidateReservation = legs =>
  (legs || []).map(({ summary, date, origin, destination, id }) =>
    mapItinerary({
      key: id,
      stage: RESERVATION_STAGE.VALIDATE,
      titleKey: summary.legName.key,
      legNumber: summary.legName.number,
      isLastItem: legs?.length === summary.legName.number,
      flightNumber: null,
      duration: null,
      origin: origin?.cityCode?.toUpperCase(),
      departure: date,
      departureAirport: `${origin?.countryName}, ${origin?.cityName}`,
      destination: destination?.cityCode?.toUpperCase(),
      arrival: null,
      arrivalAirport: `${destination?.countryName}, ${destination?.cityName}`
    })
  )

export const mapLegsReservation = selectedFlights =>
  selectedFlights.map(data =>
    mapItinerary({
      key: data.stretchNumber + 1,
      stage: RESERVATION_STAGE.CONFIRMED,
      titleKey: 'sublos.main-page.step.confirmation.stretch',
      legNumber: data.stretchNumber + 1,
      isLastItem: selectedFlights?.length === data.stretchNumber + 1,
      checkIn: data.leg?.checkIn,
      checkOut: data.leg?.checkOut,
      airline: data.leg?.airline,
      flightNumber: data.leg?.flightNumber,
      departure: data.leg?.departure,
      arrival: data.leg?.arrival,
      duration: data.leg?.duration,
      arrivalAirport: data.arrivalAirport,
      departureAirport: data.departureAirport,
      origin: data.iataCodeOrigin.toUpperCase(),
      destination: data.iataCodeDestination.toUpperCase()
    })
  )

export const mapBookingValidation = ({ passengers, legs, shoppingId, passengerSize }) => ({
  passengers: (passengers || []).map(
    ({
      fullName,
      documentNumber,
      relationshipType,
      passengerType,
      beneficiaryId,
      familyCondition,
      ordinalId,
      associated,
      vacationYear,
      familyIdForValidation
    }) => ({
      familyId: familyIdForValidation,
      relationshipType,
      beneficiaryId,
      familyCondition,
      ordinalId,
      passengerType,
      fullName,
      documentNumber,
      originalOrder: associated?.value,
      vacationYear
    })
  ),
  legs: (legs || []).map(leg => parseRoute(leg)),
  shoppingId,
  passengerSize
})

const mapSegmentsforGenerateBooking = flightSelection => {
  let segments = []
  const parsedSegments = Object.values(flightSelection).map(flight => {
    return flight.leg.segments.map(
      ({
        airline,
        arrival,
        departure,
        destination,
        flightNumber,
        origin,
        market,
        bookingClass
      }) => ({
        airline,
        arrivalDateTime: arrival,
        departureDateTime: departure,
        destination,
        flightNumber,
        origin,
        iataCodeOrigin: origin,
        iataCodeDestination: destination,
        market,
        bookingClass
      })
    )
  })
  parsedSegments.forEach(segment => {
    segments = [...segments, ...segment]
  })
  return segments
}

export const mapDataGenerateBooking = ({
  passengers,
  flightSelection,
  userData,
  validateReservation,
  searchMetadata,
  shoppingId
}) => ({
  passengers,
  bookingDetails: {
    market: searchMetadata?.market,
    orderWeb: validateReservation.orderWeb,
    ticketExpirationDate: validateReservation?.details[0]?.ticketExpirationDate,
    accountCode: userData.accountCode,
    cabinClass: userData.cabinClass,
    employeePriority: userData.employeePriority,
    othersPriority: userData.othersPriority,
    retiredEmployeePriority: userData.retiredEmployeePriority,
    retiredOthersPriority: userData.retiredOthersPriority,
    benefitTypeStatus: userData.benefitTypeStatus
  },
  segments: mapSegmentsforGenerateBooking(flightSelection),
  optionalFields: {
    emails: [
      ...(userData.employeeEmail ? [userData.employeeEmail] : []),
      ...(userData.personalEmail ? [userData.personalEmail] : [])
    ],
    phones: [
      ...(userData.phone ? [userData.phone.replace('+', '')] : []),
      ...(userData.cellphone ? [userData.cellphone.replace('+', '')] : []),
      ...(userData.alternativePhone ? [userData.alternativePhone.replace('+', '')] : [])
    ]
  },
  shoppingId
})
