import { handleActions } from 'redux-actions'
import { getFlightInformationSuccess } from 'actions/flightItinerary'

const defaultState = {
  departure: null
}

const reducer = handleActions(
  {
    [getFlightInformationSuccess]: (state, { payload: { flightData } }) => ({
      ...state,
      departure: flightData.departure
    })
  },
  defaultState
)

export default reducer
