import { handleActions } from 'redux-actions'

import {
  firstAccessStart,
  firstAccessSuccess,
  firstAccessError,
  clearFirstAccessState
} from 'actions/arplus/firstAccess'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const firstAccess = handleActions(
  {
    [firstAccessStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [firstAccessSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [firstAccessError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    }),
    [clearFirstAccessState]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default firstAccess
