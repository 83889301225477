import React from 'react'
import { connect } from 'react-redux'
import Lottie from 'react-lottie'

import { mapMediaType } from 'selectors/mediaType'
import { isDesktopOrWide } from 'helpers/utils'
import { AnimationContainer, AnimationText } from './styled'

const AnimationLoader = ({ animationData, text, mediaType, fixedHeight }) => {
  const getHeight = () => {
    if (fixedHeight) {
      return fixedHeight
    }

    return isDesktopOrWide(mediaType) ? 500 : 600
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData
  }

  return (
    <AnimationContainer>
      {text && <AnimationText>{text}</AnimationText>}
      <Lottie options={defaultOptions} height={getHeight()} isStopped={false} />
    </AnimationContainer>
  )
}

const mapStateToProps = state => ({
  mediaType: mapMediaType(state)
})

export default connect(mapStateToProps)(AnimationLoader)
