import { handleActions } from 'redux-actions'
import {
  updateAccountDataStart,
  updateAccountDataSuccess,
  updateAccountDataError,
  clearUpdateAccountDataState
} from 'actions/arplus/account-data-actions/updateAccountData'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const updateAccountData = handleActions(
  {
    [updateAccountDataStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [updateAccountDataSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [updateAccountDataError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    }),
    [clearUpdateAccountDataState]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default updateAccountData
