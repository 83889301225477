import React from 'react'

import animation from 'animations/transition.json'

import AnimationLogoLoader from '../../animation-logo-loader'
import { TransitionLogoLoaderWrapper, Column } from './styled'

export default class FullScreenTransitionLogoLoader extends React.Component {
  componentWillUnmount() {
    if (typeof document !== 'undefined') {
      document.body.style.overflowY = ''
    }
  }

  componentDidMount() {
    if (typeof document !== 'undefined') {
      document.body.style.overflowY = 'hidden'
    }
  }

  render() {
    return (
      <TransitionLogoLoaderWrapper>
        <Column>
          <AnimationLogoLoader animationData={animation} fixedHeight={180} />
        </Column>
      </TransitionLogoLoaderWrapper>
    )
  }
}
