import { createActions } from 'redux-actions'
import { cmsApi } from 'services/api'
import cmsApiRoutes from 'constants/cmsApiRoutes'
import { buildURLEncoded } from 'helpers/url'

const {
  fetchPressReleasesStart,
  fetchPressReleasesSuccess,
  fetchPressReleasesError,
  clearPressReleases
} = createActions({
  FETCH_PRESS_RELEASES_START: () => {},
  FETCH_PRESS_RELEASES_SUCCESS: data => ({ data }),
  FETCH_PRESS_RELEASES_ERROR: error => ({ error }),
  CLEAR_PRESS_RELEASES: () => {}
})

const fetchPressReleases = id => {
  return async dispatch => {
    dispatch(fetchPressReleasesStart())

    try {
      const url = buildURLEncoded(cmsApiRoutes.PRESS_RELEASES, id)
      const pressReleases = await cmsApi.get(url)
      dispatch(fetchPressReleasesSuccess(pressReleases))
    } catch (error) {
      dispatch(fetchPressReleasesError(error))
    }
  }
}

export {
  fetchPressReleasesStart,
  fetchPressReleasesSuccess,
  fetchPressReleasesError,
  fetchPressReleases,
  clearPressReleases
}
