import { createActions } from 'redux-actions'

import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import { notificationsPayload } from 'formatters/notifications'

const {
  fetchNotificationsStart,
  fetchNotificationsSuccess,
  fetchNotificationsError,
  resetNotifications
} = createActions({
  FETCH_NOTIFICATIONS_START: () => {},
  FETCH_NOTIFICATIONS_SUCCESS: data => ({ data }),
  FETCH_NOTIFICATIONS_ERROR: error => ({ error }),
  RESET_NOTIFICATIONS: () => {}
})

const fetchNotifications = (origin, currency) => {
  return async dispatch => {
    dispatch(fetchNotificationsStart())

    try {
      const notifications = await api.post(apiRoutes.NOTIFICATIONS, {
        ...notificationsPayload(origin, currency)
      })

      dispatch(fetchNotificationsSuccess(notifications))
    } catch (error) {
      dispatch(fetchNotificationsError(error))
    }
  }
}

export {
  fetchNotifications,
  fetchNotificationsStart,
  fetchNotificationsSuccess,
  fetchNotificationsError,
  resetNotifications
}
