export const hexEncode = str => {
  let hex, i

  let result = ''
  for (i = 0; i < str.length; i++) {
    hex = str.charCodeAt(i).toString(16)
    result += ('0' + hex).slice(-2)
  }

  return result
}

export const hexDecode = str => {
  let result = ''
  for (let i = 0; i < str.length; i += 2) {
    let hex = str.substr(i, 2)
    result += String.fromCharCode(parseInt(hex, 16))
  }
  return result
}
