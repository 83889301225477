import { handleActions } from 'redux-actions'
import {
  offlineAereoConfirmationStart,
  offlineAereoConfirmationSuccess,
  offlineAereoConfirmationError,
  clearOfflineAereoConfirmationState
} from 'actions/arplus/offlineAereoConfirmation'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const offlineAereoConfirmation = handleActions(
  {
    [offlineAereoConfirmationStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [offlineAereoConfirmationSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [offlineAereoConfirmationError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    }),
    [clearOfflineAereoConfirmationState]: () => ({ ...defaultState })
  },
  defaultState
)

export default offlineAereoConfirmation
