import { createActions } from 'redux-actions'

import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'


const {
  cancelTicketIn24hsStart,
  cancelTicketIn24hsSuccess,
  cancelTicketIn24hsError,
  cancelTicketIn24hsClear
} = createActions({
  CANCEL_TICKET_IN_24HS_START: () => {},
  CANCEL_TICKET_IN_24HS_SUCCESS: data => ({ data }),
  CANCEL_TICKET_IN_24HS_ERROR: error => ({ error }),
  CANCEL_TICKET_IN_24HS_CLEAR: () => {}
})

const postCancelTicketIn24hs = (route, formikFields) => async dispatch => {
  dispatch(cancelTicketIn24hsStart())

  try {
    const response = await api.post(route, formikFields)
    dispatch(cancelTicketIn24hsSuccess(response.data || true))
  } catch (error) {
    dispatch(cancelTicketIn24hsError(error.response ? error.response.data : error))
  }
}

const requestCancelTicketIn24hs = formikFields => async dispatch =>
  dispatch(postCancelTicketIn24hs(apiRoutes.CANCELLATION_IN_24hs, formikFields))


const clearCancelTicketIn24hs = () => cancelTicketIn24hsClear()

export {
  requestCancelTicketIn24hs,
  clearCancelTicketIn24hs,
  cancelTicketIn24hsStart,
  cancelTicketIn24hsSuccess,
  cancelTicketIn24hsError,
  cancelTicketIn24hsClear
}
