import { createActions } from 'redux-actions'
import cmsApiRoutes from 'constants/cmsApiRoutes'
import { cmsApi } from 'services/api'

const { fetchTermsStart, fetchTermsSuccess, fetchTermsError, clearTermsState } = createActions({
  FETCH_TERMS_START: () => {},
  FETCH_TERMS_SUCCESS: data => ({ data }),
  FETCH_TERMS_ERROR: error => ({ error }),
  CLEAR_TERMS_STATE: () => {}
})

/**
 * Should receive url array of all termsConditions needed in the rendered container.
 * In line 24 'status=1' means that the status in the CMS must be 'Published'.
 * @param {Array} urlArray
 */
const fetchTermsConditions = urlArray => {
  return async dispatch => {
    dispatch(fetchTermsStart())

    try {
      const response = await Promise.all(
        urlArray.map(url => {
          const endpoint = `${cmsApiRoutes.TERMS_CONDITIONS}?url=${url}&status=1`
          return cmsApi.get(endpoint)
        })
      )
      const reduxPayload = {}
      response.forEach(({ data }, index) => {
        // Takes only first element of CMS contentType matching url
        reduxPayload[urlArray[index]] = data[0]
      })
      dispatch(fetchTermsSuccess(reduxPayload))
    } catch (error) {
      dispatch(fetchTermsError(error))
    }
  }
}

export {
  fetchTermsConditions,
  fetchTermsStart,
  fetchTermsSuccess,
  fetchTermsError,
  clearTermsState
}
