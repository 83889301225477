import {createActions} from 'redux-actions'

import {toggleFlightSummary} from 'actions/mobileViewsAction'
import {originalFaresGet} from 'actions/originalFares'

import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import {
  isDesktopOrWide,
  isEmpty,
  isMobileOrTablet,
  punctualityIndexFlightsInfoArr
} from 'helpers/utils'
import {getLSValue} from 'helpers/localStorage'
import { flightSummaryInvalidRequest } from '../server/services/helpers'

const {
  fetchFlightSummaryStart,
  fetchFlightSummarySuccess,
  fetchFlightSummaryError,
  selectFlightError,
  clearFlightSummary,
  selectRangeFlight,
  fetchPunctualityIndexStart,
  fetchPunctualityIndexSuccess,
  fetchPunctualityIndexError
} = createActions({
  FETCH_FLIGHT_SUMMARY_START: () => {
  },
  FETCH_FLIGHT_SUMMARY_SUCCESS: data => ({data}),
  FETCH_FLIGHT_SUMMARY_ERROR: error => ({error}),
  SELECT_FLIGHT_ERROR: error => ({error}),
  CLEAR_FLIGHT_SUMMARY: () => {
  },
  SELECT_RANGE_FLIGHT: data => ({data}),
  FETCH_PUNCTUALITY_INDEX_START: () => {
  },
  FETCH_PUNCTUALITY_INDEX_SUCCESS: data => ({data}),
  FETCH_PUNCTUALITY_INDEX_ERROR: error => ({error})
})

const fetchFlightSummary = (shoppingId, offerIds, mediaType, exchanges, hasPromocode = false, isBrazil = false)  => {
  return async dispatch => {
    dispatch(fetchFlightSummaryStart())

    try {
      const summaryURL = exchanges ? apiRoutes.FLIGHTS_OFFERS_EXCHANGES : apiRoutes.FLIGHTS_OFFERS
      const storedShopping = getLSValue('shoppingId')
      const getShoppingId = !isEmpty(storedShopping) ? storedShopping : shoppingId

      const flightSummary = await api.post(summaryURL, {
        shoppingId: getShoppingId,
        selectedFlights: offerIds
      })

      const flightsInfoArr = punctualityIndexFlightsInfoArr(
        flightSummary.data.flightInformation.legs
      )

      dispatch(fetchFlightSummarySuccess(flightSummary))

      hasPromocode && dispatch(originalFaresGet(shoppingId))

      isBrazil && dispatch(fetchPunctualityIndex(flightsInfoArr))

      !isDesktopOrWide(mediaType) && !isMobileOrTablet(mediaType) && dispatch(toggleFlightSummary(true))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(fetchFlightSummaryError(err))
    }
  }
}

const fetchCheckoutFlightSummary = shoppingId => {
  return async dispatch => {
    dispatch(fetchFlightSummaryStart())
    try {
      const response = await api.get(`${apiRoutes.FLIGHTS_SUMMARY}?shoppingId=${shoppingId}`)
      dispatch(fetchFlightSummarySuccess(response))
    } catch (error) {

      const res = error.response
        ? {
            error: error.response.data,
            lan: error.response.config
              && error.response.config.headers
              ? (error.response.config.headers["Accept-Language"] || '')
                  .trim()
                  .toLowerCase()
              : ''
          }
        : {error}

      flightSummaryInvalidRequest(res.error)
        && (window.location = `${ window.location.origin}/${ res.lan}`)

      dispatch(fetchFlightSummaryError(res.error))
    }
  }
}

const fetchPunctualityIndex = flightsInfoArr => dispatch => {
  dispatch(fetchPunctualityIndexStart())

  Promise.all(
    flightsInfoArr.map(flightInfo => new Promise((resolve) =>
      api.get(`${apiRoutes.PUNCTUALITY_INDEX(flightInfo)}`)
        .then(res => resolve(res))
        .catch(() => resolve())
    ))
  ).then(
    res => dispatch(fetchPunctualityIndexSuccess(res.filter(d => !!d).map(({data}) => data))),
    err => dispatch(fetchPunctualityIndexError(err.response ? err.response.data : err))
  ).catch(err =>dispatch(fetchPunctualityIndexError(err.response ? err.response.data : err)))
}

export {
  fetchFlightSummaryStart,
  fetchFlightSummarySuccess,
  fetchFlightSummaryError,
  fetchFlightSummary,
  selectFlightError,
  clearFlightSummary,
  fetchCheckoutFlightSummary,
  selectRangeFlight,
  fetchPunctualityIndexStart,
  fetchPunctualityIndexSuccess,
  fetchPunctualityIndexError
}
