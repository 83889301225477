import { createSelector } from 'reselect'

const getMilesLoginState = state => state.milesLogin

export const getMilesLoginData = createSelector([getMilesLoginState], milesLogin => milesLogin.data)

export const getUserUnsubscribedProcess = createSelector(
  [getMilesLoginState],
  milesLogin => milesLogin.data?.baseMetadata?.warnMessages
)

export const getMilesLoginError = createSelector(
  [getMilesLoginState],
  milesLogin => milesLogin.error
)

export const getProfileData = createSelector([getMilesLoginData], milesLogin => {
  if (milesLogin) {
    return {
      firstName: milesLogin.passengersData[0]?.firstName,
      accountBalance: milesLogin.passengersData[0].frequentFlyerInformation?.accountBalance,
      membershipNumber: milesLogin.passengersData[0].frequentFlyerInformation?.number
    }
  }
  return
})

export const getMembershipNumber = createSelector([getProfileData], profileData =>
  profileData ? profileData.membershipNumber : null
)

export const getMilesLoginIsFetching = createSelector(
  [getMilesLoginState],
  milesLogin => milesLogin && milesLogin.isFetching
)
