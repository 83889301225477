import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { buildURLEncoded } from 'helpers/url'
import api, { apiSublos } from 'services/api'

const {
  fetchAirportsStart,
  fetchAirportsSuccess,
  fetchAirportsError,
  clearAirports
} = createActions({
  FETCH_AIRPORTS_START: () => {},
  FETCH_AIRPORTS_SUCCESS: airports => airports,
  FETCH_AIRPORTS_ERROR: error => error,
  CLEAR_AIRPORTS: () => {}
})

const fetchAirports = pageConfig => {
  const queryParams = buildURLEncoded('', pageConfig)

  return async dispatch => {
    dispatch(fetchAirportsStart())

    try {
      const airports = await api.get(`${apiRoutes.AIRPORTS}${queryParams}`)
      dispatch(fetchAirportsSuccess(airports))
    } catch (error) {
      dispatch(fetchAirportsError(error))
    }
  }
}

const fetchSublosAirports = pageConfig => {
  const queryParams = buildURLEncoded('', pageConfig)

  return async dispatch => {
    dispatch(fetchAirportsStart())

    try {
      const airports = await apiSublos.get(`${apiRoutes.AIRPORTS}${queryParams}`)
      dispatch(fetchAirportsSuccess(airports))
    } catch (error) {
      dispatch(fetchAirportsError(error))
    }
  }
}

export {
  fetchAirports,
  fetchSublosAirports,
  fetchAirportsStart,
  fetchAirportsSuccess,
  fetchAirportsError,
  clearAirports
}
