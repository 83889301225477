import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import { getDateWithFormat } from 'helpers/dates'
import FORMATS from 'constants/formats'

const { membershipCodeStart, membershipCodeSuccess, membershipCodeError, membershipCodeClear } = 
  createActions({
    MEMBERSHIP_CODE_START: () => {},
    MEMBERSHIP_CODE_SUCCESS: data => ({ data }),
    MEMBERSHIP_CODE_ERROR: error => ({ error }),
    MEMBERSHIP_CODE_CLEAR: () => {}
  })

const membershipCodeGet = data => async dispatch => {
  dispatch(membershipCodeStart())

  const parsedData = {
    ...data,
    dateOfBirth: getDateWithFormat(data.dateOfBirth, FORMATS.MONTH_DAY_YEAR_DASH)
  }

  try {
    const response = await api.get(apiRoutes.AR_PLUS_FORGOT_MEMBERSHIP_CODE(parsedData))

    dispatch(membershipCodeSuccess(response.data))
  } catch (error) {
    dispatch(membershipCodeError(error.response ? error.response.data : error))
  }
}

export { 
  membershipCodeGet, 
  membershipCodeStart, 
  membershipCodeSuccess, 
  membershipCodeError, 
  membershipCodeClear 
}
