import { handleActions } from 'redux-actions'

import {
  discardTicketRefundStart,
  discardTicketRefundSuccess,
  discardTicketRefundError,
  discardTicketRefundClear
} from 'actions/ticketRefundStatus'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [discardTicketRefundStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [discardTicketRefundSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [discardTicketRefundError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [discardTicketRefundClear]: () => defaultState
  },
  defaultState
)

export default reducer
