export const setLSValue = (key, value) => {
  const { localStorage } = window

  localStorage.setItem(key, value)
}

export const getLSValue = key => {
  if (typeof window === 'undefined') {
    return ''
  }

  const { localStorage } = window

  return localStorage?.getItem(key)
}

export const removeLSValue = key => {
  const { localStorage } = window

  localStorage.removeItem(key)
}
