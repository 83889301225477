import { handleActions } from 'redux-actions'

import {
  sublosFlightInventorySuccess,
  sublosFlightInventoryError,
  sublosFlightInventoryStart,
  sublosFlightInventoryClear
} from 'actions/sublos/flightInventory'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const sublosFlightInventory = handleActions(
  {
    [sublosFlightInventoryStart]: state => ({
      ...state,
      error: null,
      isFetching: true
    }),
    [sublosFlightInventorySuccess]: (state, { payload: { data } }) => {
      const newData = {
        ...state.data,
        [data.flightId]: data.inventory
      }
      return {
        data: newData,
        error: null,
        isFetching: false
      }
    },
    [sublosFlightInventoryError]: (state, { payload: { error } }) => ({
      ...state,
      error,
      isFetching: false
    }),
    [sublosFlightInventoryClear]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default sublosFlightInventory
