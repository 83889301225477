/** @todo method to extract a portion of formik bag to pass down to form components */
import i18n from 'i18next'
import { PASSENGER_TYPE_VALIDATIONS, PASSENGER_TYPES } from 'constants/passengerTypes'

export const getSectionSpecificProps = (formikBag, formKey) => ({
  initialValues: formikBag.initialValues[formKey],
  values: formikBag.values[formKey],
  touched: formikBag.touched[formKey],
  errors: formikBag.errors[formKey]
})

export const getSectionSpecificPropsWithIndexLast = (formikBag, formKey, index) => {
  if (formikBag) {
    return {
      initialValues:
        formikBag.initialValues &&
        formikBag.initialValues[formKey] &&
        formikBag.initialValues[formKey][index],
      values: formikBag.values && formikBag.values[formKey] && formikBag.values[formKey][index],
      touched: formikBag.touched && formikBag.touched[formKey] && formikBag.touched[formKey][index],
      errors: formikBag.errors && formikBag.errors[formKey] && formikBag.errors[formKey][index]
    }
  }
}

export const getNestedSectionSpecificProps = (sectionProps, formKey) => {
  return {
    initialValues: sectionProps.initialValues && sectionProps.initialValues[formKey],
    values: sectionProps.values && sectionProps.values[formKey],
    touched: sectionProps.touched && sectionProps.touched[formKey],
    errors: sectionProps.errors && sectionProps.errors[formKey]
  }
}

export const getNestedSectionDate = (sectionProps, nameKey) => {
  const parseDate = (sectionProps, key) => {
    return sectionProps[key] ?
        {
          [nameKey]: sectionProps[key][nameKey] ? sectionProps[key][nameKey].toString() : '',
          [`${nameKey}Day`]: sectionProps[key][`${nameKey}Day`] ? sectionProps[key][`${nameKey}Day`].toString() : '',
          [`${nameKey}Month`]: sectionProps[key][`${nameKey}Month`] ? sectionProps[key][`${nameKey}Month`].toString() : '',
          [`${nameKey}Year`]: sectionProps[key][`${nameKey}Year`] ? sectionProps[key][`${nameKey}Year`].toString() : '',
          [`${nameKey}DateInvalid`]: sectionProps[key][`${nameKey}DateInvalid`] ? sectionProps[key][`${nameKey}DateInvalid`].toString() : '',
        }:null
  }

  return {
    initialValues: parseDate(sectionProps, 'initialValues'),
    values: parseDate(sectionProps, 'values'),
    touched:parseDate(sectionProps, 'touched'),
    errors: parseDate(sectionProps, 'errors')
  }
}

export const getDaysOptions = () =>
  [...Array(31)].map((_, index) => ({
    value: index + 1,
    label: index + 1
  }))

export const getMonthsOptions = () =>
  [...Array(12)].map((item, index) => ({
    value: index + 1,
    label: i18n.isInitialized ? i18n.t(`label.months-${index}`) : ''
  }))


export const getYearsOptionsForBirthdate = (passengerType, flightLastDate) => {
  
  const lastDateYear = flightLastDate ? flightLastDate.getFullYear() : (new Date()).getFullYear()

  const ageThreshold = PASSENGER_TYPE_VALIDATIONS[passengerType]

  let max = lastDateYear + 1
  let min = max - 100

  if (ageThreshold) {
    if (ageThreshold.MIN_AGE) {
      max = lastDateYear - ageThreshold.MIN_AGE + 1
    }
    if (ageThreshold.MAX_AGE) {
      min = lastDateYear - ageThreshold.MAX_AGE
    }
  }

  let arrayYears = []
  for (let i = min; i < max; i++) {
    arrayYears.push({
      value: i,
      label: i
    })
  }

  return arrayYears.sort((a, b) => b.value - a.value)
}

export const getYearsOptionsForIdExpiration = expirationThreshold => {
  const maxYearLapse = expirationThreshold || 15
  const max = new Date().getFullYear() + maxYearLapse
  const min = new Date().getFullYear()

  const arrayYears = []
  for (let i = min; i <= max; i++) {
    arrayYears.push({
      value: i,
      label: i
    })
  }
  return arrayYears
}
