import { getDayJsObject } from 'helpers/dates'

// Return flights offers search url params based on flights search data.
export default flightsSearchData => {
  const { legs, cabinClass, awardBooking } = flightsSearchData

  // Create leg URL params
  const legsParams = legs
    .map(leg => {
      const { origin, destination, departure } = leg
      return `&leg=${origin}-${destination}-${getDayJsObject(departure).format('YYYYMMDD')}`
    })
    .join('')
    .replace('&', '?')

  const cabinParams = `&cabinClass=${cabinClass}`
  const dataPrams = `${legsParams}${cabinParams}`
  return awardBooking ? `${dataPrams}&awardBooking=true` : `${dataPrams}`
}
