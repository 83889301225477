import { handleActions } from 'redux-actions'

import {
  identificationsTypeStart,
  identificationsTypeSuccess,
  identificationsTypeError
} from 'actions/arplus/identificationsType'

const defaultState = {
  data: [],
  error: null,
  isFetching: false
}

const identificationsType = handleActions(
  {
    [identificationsTypeStart]: state => ({
      ...state,
      data: [],
      error: null,
      isFetching: true
    }),
    [identificationsTypeSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [identificationsTypeError]: (state, { payload: { error } }) => ({
      ...state,
      data: [],
      error,
      isFetching: false
    })
  },
  defaultState
)

export default identificationsType
