import React from 'react'

import { useTranslation } from 'react-i18next'

import FaceAgent from '../assets/face-agent-contact.svg'
import { getContactData } from '../helpers'
import {
  FooterContactInfoWrapper,
  ContactDetail,
  MessageWrapper,
  Title,
  IconImagen,
  Subtitle,
  FooterContainerContact,
  TitleDetail,
  Link,
  TitleWrapper
} from './styled'

const FooterContactInfo = () => {
  const translation = useTranslation()
  const {
    t,
    i18n: { language }
  } = translation

  return (
    <FooterContactInfoWrapper>
      <TitleWrapper>
        <IconImagen src={FaceAgent} alt="communicate-with-us" />
        <Title>{t('general.footer.contact-info.communicate-with-us')}</Title>
      </TitleWrapper>
      <FooterContainerContact>
        {getContactData(t, language).map((value, index) => (
          <ContactDetail key={index}>
            <IconImagen src={value.icon} alt={value.alt} />
            <MessageWrapper>
              <TitleDetail>{value.title}</TitleDetail>
              {value.__html ? (
                <Subtitle dangerouslySetInnerHTML={{ __html: value.__html }} />
              ) : value.link ? (
                <Link href={value.link} target="_blank">
                  {value.subtitle}
                </Link>
              ) : (
                <Subtitle>{value.subtitle}</Subtitle>
              )}
            </MessageWrapper>
          </ContactDetail>
        ))}
      </FooterContainerContact>
    </FooterContactInfoWrapper>
  )
}

export default FooterContactInfo
