export default {
  EXCHANGE: 'EXCHANGE',
  ANCILLARIES_BOOKING: 'ANCILLARIES',
  MISSING_INFO: 'MISSING_INFO',
  SHOPPING: 'TICKET',
  SHOPPING_2CARDS: 'TICKET_2CARDS',
  ANCILLARIES_SHOPPING: 'ANCILLARIES_SHOPPING',
  HOLD_TICKET: 'HOLD_TICKET',
  MILES: 'MILES',
  ARPLUS_MILES: 'ARPLUS_MILES',
  SUBLOS: 'SUBLOS'
}
