import { createActions } from 'redux-actions'

const {
  setFlightsSearchData,
  resetFlightsSearchData,
  setFlightsOffersSelectedDate,
  setDefinedFilters,
  setRedirectFromFilters
} = createActions({
  SET_FLIGHTS_SEARCH_DATA: data => ({ data }),
  RESET_FLIGHTS_SEARCH_DATA: () => {},
  SET_FLIGHTS_OFFERS_SELECTED_DATE: (legNumber, date) => ({ legNumber, date }),
  SET_DEFINED_FILTERS: definedFilters => ({ definedFilters }),
  SET_REDIRECT_FROM_FILTERS: redirect => ({ redirect })
})

export {
  setFlightsSearchData,
  resetFlightsSearchData,
  setFlightsOffersSelectedDate,
  setDefinedFilters,
  setRedirectFromFilters
}
