import styled from 'styled-components'

import mediaQueries from 'components/media-queries'
import e2eClasses from 'helpers/automation'

export const Title = styled.h3.attrs(() => ({
  className: e2eClasses('footer.institutional.itemTitle')
}))`
  color: ${({ theme }) => theme.colors.blue};
  margin-bottom: 16px;
  font-size: 20px;
  font-family: ${({ theme }) => theme.fonts.semiBold};

  ${mediaQueries.tablet`
    text-align: center;
  `};
`

export const SubscribeMessageWrapper = styled.div.attrs(() => ({
  className: e2eClasses('footer.newsLetter.subscribeMessage')
}))`
  width: 450px;
  height: 82px;
  background-color: ${({ theme }) => theme.colors.blue};
  display: flex;
  flex-direction: ${({ theme }) =>
    theme?.root?.style.getPropertyValue('--show-arsa-keys-is-activated') === 'true'
      ? 'column'
      : 'row'};
  padding: 24px 0;
  justify-content: space-evenly;
  position: relative;
  bottom: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  align-self: end;

  ${mediaQueries.tablet`
    text-align: center;
  `};

  ${mediaQueries.mobile`
    width: auto;
  `};
`

export const ButtonWrapper = styled.div`
  width: 135px;
  height: 35px;

  /**
  * Works around disgusting req of anchor inside translation key.
  */
  & a {
    text-align: center;
    padding: 5px 0;
    width: 100%;
    height: 100%;
    text-decoration: none;
  }

  ${mediaQueries.mobile`
    width: 100px;
  `};
`
export const SubscribeTitle = styled(Title)`
  font-size: 18px;
  line-height: 18px;
  width: 172px;
  color: ${({ theme }) => theme.colors.white};
`
