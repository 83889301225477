import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import { fetchPaymentOptionsArplusMiles } from 'actions/paymentOptions'
import PRODUCTS from 'constants/products'

const {
  milesAmountStart,
  milesAmountSuccess,
  milesAmountError,
  clearMilesAmountState
} = createActions({
  MILES_AMOUNT_START: () => {},
  MILES_AMOUNT_SUCCESS: data => ({ data }),
  MILES_AMOUNT_ERROR: error => ({ error }),
  CLEAR_MILES_AMOUNT_STATE: () => {}
})

const getMilesAmountPayload = response => ({
  channelData: {
    channelId: 'WEB_AR',
    company: 'AEROLINEAS'
  },
  routes: ['BUE-COR'],
  paymentData: {
    currency: 'ARS',
    amount: response.data.totalAmount
  }
})

const fetchMilesAmount = (miles, operationType) => async dispatch => {
  dispatch(milesAmountStart())
  try {
    const response = await api.get(`${apiRoutes.MILES_COST_AR_PLUS(miles, operationType)}`)
    const payload = getMilesAmountPayload(response)

    dispatch(fetchPaymentOptionsArplusMiles(payload, PRODUCTS.ARPLUS_MILES))
    dispatch(milesAmountSuccess(response.data))
  } catch (error) {
    dispatch(milesAmountError(error.response ? error.response.data : error))
  }
}

export {
  fetchMilesAmount,
  milesAmountStart,
  milesAmountSuccess,
  milesAmountError,
  clearMilesAmountState
}
