import axios from 'axios'

import CONFIG from 'config'
import CONFIG_SERVER from 'config-server'
import API_ROUTES from 'constants/apiRoutes'
import { getCookie } from 'helpers/cookies'
import { hexDecode, hexEncode } from 'helpers/encode'
import { logError } from 'helpers/log'
import COOKIE_NAMES from 'server/utils/cookieNames'

const isBrowser = !!(typeof window !== 'undefined' && document?.cookie)

const getCookieToken = cookies => {
  try {
    if (!cookies && !isBrowser) return
    const encodedTokenCookie = getCookie(cookies || document?.cookie, COOKIE_NAMES.TOKEN)
    if (!encodedTokenCookie) return
    const decodedToken = decodeToken(encodedTokenCookie)
    return decodedToken ? JSON.parse(decodedToken) : null
  } catch {
    return null
  }
}

const calculateExpirationDate = secondsUntilExpiration => {
  // Get the current epoch time
  const currentEpochTime = Math.floor(Date.now() / 1000)

  // Calculate the future epoch time
  return currentEpochTime + secondsUntilExpiration
}

const isTokenExpired = expirationEpoch => Math.floor(Date.now() / 1000) > expirationEpoch

const encodeToken = unencodedToken => {
  return hexEncode(unencodedToken)
}

const decodeToken = encodedToken => {
  return hexDecode(encodedToken)
}

const saveTokenToCookies = (res, cookieName, data) => {
  const expirationEpoch = calculateExpirationDate(data.expires_in)

  const stringifiedToken = JSON.stringify({
    access_token: data.access_token,
    expires_on: expirationEpoch
  })

  const encodedToken = encodeToken(stringifiedToken)

  const expirationDate = new Date(expirationEpoch * 1000)
  if (res) return res.cookie(cookieName, encodedToken, { expires: expirationDate })
  else if (isBrowser) {
    const cookieString = `${cookieName}=${encodedToken};expires=${expirationDate};path=/`
    return (document.cookie = cookieString)
  }
}

export const negotiateToken = async (res, cookies) => {
  const authCredentials = {
    client_id: CONFIG_SERVER.CLIENT_ID,
    client_secret: CONFIG_SERVER.CLIENT_SECRET
  }
  const token = CONFIG.ENABLE_TOKEN_IN_COOKIES && getCookieToken(cookies)

  if (token?.expires_on && !isTokenExpired(token.expires_on)) return token.access_token

  try {
    const { data } = await axios.post(`${CONFIG.API_BASE_URL}${API_ROUTES.OAUTH}`, authCredentials)

    CONFIG.ENABLE_TOKEN_IN_COOKIES && saveTokenToCookies(res, COOKIE_NAMES.TOKEN, data)

    return data.access_token
  } catch (error) {
    error.fileName = 'auth.js'
    logError('Token generation error', `${CONFIG.API_BASE_URL}${API_ROUTES.OAUTH}`, error)
    if (typeof window !== 'undefined' && window.__ACCESS_TOKEN__) {
      return window.__ACCESS_TOKEN__
    }
    // app should be rendered anyway so error is not rethrown
  }
}
