import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'

const { getFlightStatusStart, getFlightStatusSuccess, getFlightStatusError } = createActions({
  GET_FLIGHT_STATUS_START: () => {},
  GET_FLIGHT_STATUS_SUCCESS: data => ({ data }),
  GET_FLIGHT_STATUS_ERROR: error => ({ error })
})

const getFlightStatus = params => {
  return async dispatch => {
    dispatch(getFlightStatusStart())

    try {
      const data = await api.get(`${apiRoutes.FLIGHTS_STATUS}${params}`)
      dispatch(getFlightStatusSuccess(data))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(getFlightStatusError(err))
    }
  }
}

export { getFlightStatusStart, getFlightStatusSuccess, getFlightStatusError, getFlightStatus }
