import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  updatePasswordStart,
  updatePasswordSuccess,
  updatePasswordError,
  clearUpdatePasswordState
} = createActions({
  UPDATE_PASSWORD_START: () => {},
  UPDATE_PASSWORD_SUCCESS: data => ({ data }),
  UPDATE_PASSWORD_ERROR: error => ({ error }),
  CLEAR_UPDATE_PASSWORD_STATE: () => {}
})

const updatePassword = ({ membershipCode, currentPassword, newPassword }) => async dispatch => {
  dispatch(updatePasswordStart())
  try {
    const response = await api.post(apiRoutes.AR_PLUST_CHANGE_ACCOUNT_PASSWORD, {
      loginData: {
        membershipCode,
        password: currentPassword
      },
      newPassword
    })

    dispatch(updatePasswordSuccess(response.data))
  } catch (error) {
    dispatch(updatePasswordError(error.response ? error.response.data : error))
  }
}

export {
  updatePassword,
  updatePasswordStart,
  updatePasswordSuccess,
  updatePasswordError,
  clearUpdatePasswordState
}
