import { handleActions } from 'redux-actions'
import {
  fetchStepsStart,
  fetchStepsSuccess,
  fetchStepsError,
  setSelectedStep,
  setSteps,
  resetSteps,
  setDisableSteps
} from 'actions/stepBarAction'

const defaultState = {
  data: null,
  error: null,
  isFetching: false,
  disable:false,
}

const reducer = handleActions(
  {
    [fetchStepsStart]: state => ({
      ...state,
      data: {},
      error: null,
      isFetching: true
    }),
    [fetchStepsSuccess]: (state, { payload: { data } }) => {
      return {
        ...state,
        data: data.steps,
        error: null,
        isFetching: false
      }
    },
    [fetchStepsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: {},
      error
    }),
    [setSelectedStep]: (state, { payload: { currentStep } }) => ({
      ...state,
      currentStep
    }),
    [setSteps]: (state, { payload: { steps } }) => ({
      ...state,
      data: steps,
      currentStep: { selectedStep: steps[0].key, position: 0 }
    }),
    [setDisableSteps]: (state, { payload: { disable } }) => ({
      ...state,
      disable,
    }),
    [resetSteps]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default reducer
