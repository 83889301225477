import { handleActions } from 'redux-actions'

import {
  membersStart,
  membersSuccess,
  membersError,
  clearMembersState
} from 'actions/arplus/members'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const members = handleActions(
  {
    [membersStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [membersSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [membersError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    }),
    [clearMembersState]: () => ({ ...defaultState })
  },
  defaultState
)

export default members
