import { createActions } from 'redux-actions'
import { cmsApi } from 'services/api'
import cmsApiRoutes from 'constants/cmsApiRoutes'

const {
  fetchEventCategoriesStart,
  fetchEventCategoriesSuccess,
  fetchEventCategoriesError
} = createActions({
  FETCH_EVENT_CATEGORIES_START: () => {},
  FETCH_EVENT_CATEGORIES_SUCCESS: data => ({ data }),
  FETCH_EVENT_CATEGORIES_ERROR: error => ({ error })
})

const fetchEventCategories = () => {
  return async dispatch => {
    dispatch(fetchEventCategoriesStart())

    try {
      const eventCategories = await cmsApi.get(cmsApiRoutes.EVENT_CATEGORIES)
      dispatch(fetchEventCategoriesSuccess(eventCategories))
    } catch (error) {
      dispatch(fetchEventCategoriesError(error))
    }
  }
}

export {
  fetchEventCategoriesStart,
  fetchEventCategoriesSuccess,
  fetchEventCategoriesError,
  fetchEventCategories
}
