import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import { buildURLEncoded } from 'helpers/url'

const { fetchAircraftsStart, fetchAircraftsSuccess, fetchAircraftsError } = createActions({
  FETCH_AIRCRAFTS_START: () => {},
  FETCH_AIRCRAFTS_SUCCESS: aircrafts => aircrafts,
  FETCH_AIRCRAFTS_ERROR: error => error
})

const fetchAircrafts = pageConfig => {
  const queryParams = buildURLEncoded('', pageConfig)

  return async dispatch => {
    dispatch(fetchAircraftsStart())

    try {
      const aircrafts = await api.get(`${apiRoutes.AIRCRAFTS}${queryParams}`)
      dispatch(fetchAircraftsSuccess(aircrafts))
    } catch (error) {
      dispatch(fetchAircraftsError(error))
    }
  }
}

export { fetchAircraftsStart, fetchAircraftsSuccess, fetchAircraftsError, fetchAircrafts }
