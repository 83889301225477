import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'

const {
  countriesArplusStart,
  countriesArplusSuccess,
  countriesArplusError
} = createActions({
  COUNTRIES_ARPLUS_START: () => {},
  COUNTRIES_ARPLUS_SUCCESS: data => ({ data }),
  COUNTRIES_ARPLUS_ERROR: error => ({ error })
})

const fetchCountriesArplus = () => async dispatch => {
  dispatch(countriesArplusStart())
  try {
    const response = await api.get(apiRoutes.COUNTRIES_ARPLUS_AR_PLUS)
    dispatch(countriesArplusSuccess(response.data))
  } catch (error) {
    dispatch(countriesArplusError(error.response ? error.response.data : error))
  }
}

export {
  fetchCountriesArplus,
  countriesArplusStart,
  countriesArplusSuccess,
  countriesArplusError
}