import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'

const {
  creditMilesDestinationStart,
  creditMilesDestinationSuccess,
  creditMilesDestinationError,
  clearCreditMilesDestination
} = createActions({
  CREDIT_MILES_DESTINATION_START: () => {},
  CREDIT_MILES_DESTINATION_SUCCESS: data => ({ data }),
  CREDIT_MILES_DESTINATION_ERROR: error => ({ error }),
  CLEAR_CREDIT_MILES_DESTINATION: () => {}
})

const fetchCreditMilesDestination = (airline, origin) => async dispatch => {
  dispatch(creditMilesDestinationStart())
  try {
    const response = await api.get(`${apiRoutes.AR_PLUS_CREDIT_MILES_DESTINATION(airline, origin)}`)
    dispatch(creditMilesDestinationSuccess(response.data))
  } catch (error) {
    dispatch(creditMilesDestinationError(error.response ? error.response.data : error))
  }
}

export {
  fetchCreditMilesDestination,
  creditMilesDestinationStart,
  creditMilesDestinationSuccess,
  creditMilesDestinationError,
  clearCreditMilesDestination
}
