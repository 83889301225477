import { handleActions } from 'redux-actions'
import {
  sendPassengersDataStart,
  sendPassengersDataSuccess,
  sendPassengersDataError,
  fetchPassengersValidationsStart,
  fetchPassengersValidationsSuccess,
  fetchPassengersValidationsError,
  fetchPassengersDataStart,
  fetchPassengersDataSuccess,
  fetchPassengersDataError,
  resetPassengersData
} from 'actions/passengers'

const defaultState = {
  data: null,
  error: null,
  isFetching: false,
  passengerValidation: null
}

const reducer = handleActions(
  {
    [fetchPassengersDataStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchPassengersDataSuccess]: (state, { payload: { response } }) => ({
      ...state,
      data: response,
      error: null,
      isFetching: false
    }),
    [fetchPassengersDataError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [sendPassengersDataStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [sendPassengersDataSuccess]: (state, { payload: { response } }) => ({
      ...state,
      data: response,
      error: null,
      isFetching: false
    }),
    [sendPassengersDataError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [fetchPassengersValidationsStart]: state => ({
      ...state,
      validations: null,
      error: null,
      isFetching: true
    }),
    [fetchPassengersValidationsSuccess]: (state, { payload: { response } }) => ({
      ...state,
      validations: response,
      error: null,
      isFetching: false
    }),
    [fetchPassengersValidationsError]: (state, { payload: { error } }) => ({
      ...state,
      validations: null,
      error,
      isFetching: false
    }),
    [resetPassengersData]: () => defaultState
  },
  defaultState
)

export default reducer
