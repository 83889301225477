import { HOLD_OPTION_MATCH } from 'constants/payment'

/**
 * The only value here that returns boolean true, is explicit 'true' string.
 * All other values will give a boolean false.
 *
 * BTW: Can't import this helper from utils file as it makes config to weirdly crash. (Thx razzle)
 * @param {string} booleanString
 */
const booleanStringToPrimitive = booleanString => booleanString === 'true'

/**
 * Fixes unexpected issues. AAW-9646 for details.
 */
const parseReloadVariable = value => (parseInt(value) > 0 ? parseInt(value) : false)

const checkEnableTabs = enableTabs => {
  const parseString = (enableTabs || '').trim()
  return !enableTabs
    ? [HOLD_OPTION_MATCH.PAYMENT_NOW.CONFIG]
    : parseString.slice(1, parseString.length - 1).split(' ')
}

/*
 * Environment Variables need to start with the prefix "RAZZLE_" to function correctly
 */
export default {
  API_BASE_URL: process.env.RAZZLE_API_BASE_URL || 'http://api.int.testar.com.ar',
  API_SSR_BASE_URL: process.env.RAZZLE_API_SSR_BASE_URL || 'http://api.int.testar.com.ar',
  CMS_API_BASE_URL: process.env.RAZZLE_CMS_API_BASE_URL || 'http://content.int.testar.com.ar',
  CONTENT_CDN_BASE_URL:
    process.env.RAZZLE_CONTENT_CDN_BASE_URL || 'http://content.int.testar.com.ar',
  BUNDLE_CDN_BASE_URL: process.env.RAZZLE_BUNDLE_CDN_BASE_URL || '',
  CMS_SSR_BASE_URL: process.env.RAZZLE_CMS_SSR_BASE_URL || 'http://content.int.testar.com.ar',
  CONTENT_WWW_CDN_BASE_URL:
    process.env.RAZZLE_WWW_CDN_BASE_URL || 'https://www.aerolineas.com.ar',
  WEB_CHECKIN_URL:
    process.env.RAZZLE_WEB_CHECKIN_URL ||
    'https://wl26-int.sabresonicweb.com/SSW2010/ARM0/#checkin',
  PLUSGRADE_BASE_URL:
    process.env.RAZZLE_PLUSGRADE_BASE_URL || 'https://upgrade-stage.plusgrade.com',
  EXTRA_BAG_URL: process.env.RAZZLE_EXTRA_BAG_URL || '/offer/extrabag/aerolineas',
  DEFAULT_LOCALE: 'es-AR',
  DEFAULT_CHANNEL_ID: 'WEB_AR',
  DEFAULT_SUBLOS_CHANNEL_ID: 'SUBLOS_AR',
  MISSION_VISION_VALUES_URL: 'https://www.aerolineas.com.ar/es-ar/laempresa/mision',
  SKY_TEAM_URL: 'https://www.aerolineas.com.ar/es-ar/servicioscliente/codeshare',
  BRANCHES_URL: 'https://www.aerolineas.com.ar/es-ar/contacto/sucursalesaeropuertos',
  HUMAN_RESOURCES_URL: 'https://www.aerolineas.com.ar/es-ar/la_empresa/recursos_humanos',
  OUR_HISTORY_URL: 'https://www.aerolineas.com.ar/es-ar/laempresa/nuestrahistoria',
  SUPPLIERS_URL: 'http://ww1.aerolineas.com.ar/arg/main.asp?idSitio=AR&idPagina=152&idIdioma=es',
  OUR_AIRCRAFTS_URL: 'https://www.aerolineas.com.ar/es-ar/la-empresa/informe-financiero',
  AA_GROUP_COMPANIES_URL: 'https://www.aerolineas.com.ar/es-ar/laempresa/empresasdelgrupo',
  AA_MOBILE_URL: 'https://www.aerolineas.com.ar/es-ar/aerolineas_movil',
  TAXES_INFORMATION_URL:
    'https://www.aerolineas.com.ar/landingsespeciales/landings/322_informacion-financiera',
  AR_PLUS_URL: 'https://www.aerolineas.com.ar/es-ar/aerolineas_plus',
  GA_ID: process.env.RAZZLE_GA_ID || 'UA-137663234-1',
  PAYMENT_METHODS_ASSETS: 'media/paymentmethods',
  AIRLINES_ASSETS: 'media/airlines',
  FINANCING_CONDITIONS_URL:
    'https://www.aerolineas.com.ar/es-ar/reservasservicios/bancos?utm_source=Website&utm_medium=Banners%20fijos%20inferiores&utm_campaign=Hola%20Cuotas&artid=aeswbic008',
  SUGGESTIONS_URL: 'https://www.aerolineas.com.ar/es-ar/contacto/contactoatencioncliente',
  FISCAL_CREDIT_URL: 'https://www.aerolineas.com.ar/es-ar/laempresa/constanciacreditofiscal',
  BANKS_URL: 'https://www.aerolineas.com.ar/es-ar/reservasservicios/bancos',
  FARE_CONDITIONS_URL: 'https://www.aerolineas.com.ar/es-ar/reservasservicios/condiciones',
  BAGGAGE_CONDITIONS_URL: 'https://www.aerolineas.com.ar/es-ar/equipaje/franquicia',
  NEWSLETTER_URL: 'https://www.aerolineas.com.ar/landingsespeciales/landings/646_newsletter',
  CHECKIN_CONDITIONS_URL:
    'https://www.aerolineas.com.ar/landingsespeciales/landings/232_condiciones-checkin-online',
  FRAUD_IP_ADDRESS_URL: 'https://ipapi.co/json/?key=19cbe0cdd48ed77a6750073744207276a840fa11',
  CMS_LANGCODE: process.env.RAZZLE_CMS_LANGCODE || 'es-AR',
  GENERAL_CMS_LANGCODE: process.env.RAZZLE_GENERAL_CMS_LANGCODE || 'es',
  COOKIES_TERMS_CONDITIONS: 'https://www.aerolineas.com.ar/legales/terminos-y-condiciones',
  FOOTER_FACEBOOK: 'https://www.facebook.com/aerolineas.argentinas/',
  FOOTER_WHATSAPP: 'https://api.whatsapp.com/send?phone=541149404798',
  FOOTER_AIRPORTS: 'https://www.aerolineas.com.ar/es-ar/contacto/sucursalesaeropuertos',
  INFORMATION_SECURE_FLIGHT_URL: 'https://www.dhs.gov/dhs-trip',
  WHATSAPP_API_CONTACT: 'https://api.whatsapp.com/send?phone=541149404798',
  EXTERNAL_CHAT_BOT: 'https://api.wcx.cloud/widget/v6?id=f1e92af809cc4af596b8e15009725100',
  SPECIAL_FLIGHTS_URL:
    'https://www.aerolineas.com.ar/es-ar/landingsespeciales/landings/790_vuelos-especiales',
  FARE_CONDITIONS_LANDING_URL:
    'https://www.aerolineas.com.ar/landingsespeciales/landings/480_condiciones-tarifarias',
  SKYTEAM_PRESS_RELEASE_URL: 'https://www.skyteam.com/en/General-information/covid19',
  EXTERNAL_BOOKING_BR_URL: 'https://www.aerolineas.com.ar/pt-br/servicios_adicionales',
  EXTERNAL_BOOKING_CL_URL: 'https://www.aerolineas.com.ar/es-cl/servicios_adicionales',
  EXTERNAL_BOOKING_DEFAULT_URL: 'https://www.aerolineas.com.ar/es-ar/servicios_adicionales',
  ENABLE_CORPORATE_AGREEMENTS: true,
  ENABLE_EVENTS_PAGE: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_EVENTS_PAGE),
  ENABLE_CHECKOUT_TABS: checkEnableTabs(process.env.RAZZLE_ENABLE_CHECKOUT_TABS),
  ENABLE_BO_TRANSLATIONS: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_BO_TRANSLATIONS),
  BO_TRANSLATIONS_SERVER_RELOAD_INTERVAL: parseReloadVariable(
    process.env.RAZZLE_BO_TRANSLATIONS_SERVER_RELOAD_INTERVAL
  ),
  BO_TRANSLATIONS_DEBUG_ON: booleanStringToPrimitive(process.env.RAZZLE_BO_TRANSLATIONS_DEBUG_ON),
  ENABLE_MILES: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_MILES),
  GOOGLE_TAG_MANAGER_ID: process.env.RAZZLE_GOOGLE_TAG_MANAGER_ID || 'KBMB8S6',
  ENABLE_NEWRELIC: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_NEWRELIC),
  ENABLE_PASSENGERS_MISSING_INFO: booleanStringToPrimitive(
    process.env.RAZZLE_ENABLE_PASSENGERS_MISSING_INFO
  ),
  ENABLE_FORGOTTEN_MAIL: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_FORGOTTEN_MAIL),
  ENABLE_UNSUBSCRIBE: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_UNSUBSCRIBE),
  ENABLE_TWO_CARDS_SHOPPING: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_TWO_CARDS_SHOPPING),
  ENABLE_MERCADO_PAGO: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_MERCADO_PAGO),
  ENABLE_PROMO_CODE: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_PROMO_CODE),
  ENABLE_SUBLOS: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_SUBLOS),
  SUBLOS_TOKEN_SECRET_KEY: process.env.RAZZLE_SUBLOS_TOKEN_SECRET_KEY || 'puHUc2yGd5bXbHHR', //TODO: remove data-hardcode
  ARPLUS_DISABLE_TRANSLATIONS_MEMBER_CODE: process.env
    .RAZZLE_ARPLUS_DISABLE_TRANSLATIONS_MEMBER_CODE || ['70000930', '1406693'],
  ENABLE_EXTERNAL_CHAT_BOTS: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_EXTERNAL_CHAT_BOTS),
  ENABLE_TOKEN_IN_COOKIES: booleanStringToPrimitive(process.env.RAZZLE_ENABLE_TOKEN_IN_COOKIES),
  ENABLE_CHILE_REDIRECTION_PAYMENT_GATEWAY: booleanStringToPrimitive(
    process.env.RAZZLE_ENABLE_CHILE_REDIRECTION_PAYMENT_GATEWAY
  )
}
