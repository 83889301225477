import { createActions } from 'redux-actions'

import cmsApiRoutes from 'constants/cmsApiRoutes'
import { logError } from 'helpers/log'
import { cmsApi, ssrCmsApi } from 'services/api'

const { fetchUrlStart, fetchUrlSuccess, fetchUrlError } = createActions({
  FETCH_URL_START: () => {},
  FETCH_URL_SUCCESS: data => ({ data }),
  FETCH_URL_ERROR: error => ({ error })
})

const fetchUrl = () => {
  return async dispatch => {
    dispatch(fetchUrlStart())

    try {
      const urlRedirection = await cmsApi.get(cmsApiRoutes.URL_REDIRECTION)
      dispatch(fetchUrlSuccess(urlRedirection))
    } catch (error) {
      dispatch(fetchUrlError(error))
    }
  }
}

const fetchUrlForServer = async () => {
  try {
    const { data } = await ssrCmsApi.get(cmsApiRoutes.URL_REDIRECTION)

    return {
      data,
      error: null,
      isFetching: false
    }
  } catch (error) {
    error.fileName = 'urlRedirection.js'
    error.lineNumber = '26'
    logError(
      `Error when getting ${ssrCmsApi.defaults.baseURL + cmsApiRoutes.URL_REDIRECTION}`,
      '',
      error
    )
    return {
      error,
      data: null,
      isFetching: false
    }
  }
}

export { fetchUrlStart, fetchUrlSuccess, fetchUrlError, fetchUrl, fetchUrlForServer }
