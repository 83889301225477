import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  recoverEmailStart,
  recoverEmailSuccess,
  recoverEmailError,
  clearRecoverEmailState
} = createActions({
  RECOVER_EMAIL_START: () => {},
  RECOVER_EMAIL_SUCCESS: data => ({ data }),
  RECOVER_EMAIL_ERROR: error => ({ error }),
  CLEAR_RECOVER_EMAIL_STATE: () => {}
})

const recoverEmail = (
  email,
  membershipNumber,
  identificationType,
  numberOfIdentification
) => async dispatch => {
  dispatch(recoverEmailStart())
  try {
    let response
    if (membershipNumber) {
      response = await api.get(
        `${apiRoutes.AR_PLUS_RECOVER_EMAIL_WITH_MEMBERSHIP_CODE(email, membershipNumber)}`
      )
    } else if (identificationType && numberOfIdentification) {
      response = await api.get(
        `${apiRoutes.AR_PLUS_RECOVER_EMAIL_WITH_DOCUMENT(
          email,
          identificationType,
          numberOfIdentification
        )}`
      )
    }
    dispatch(recoverEmailSuccess(response.data))
  } catch (error) {
    dispatch(recoverEmailError(error.response ? error.response.data : error))
  }
}

export {
  recoverEmail,
  recoverEmailStart,
  recoverEmailSuccess,
  recoverEmailError,
  clearRecoverEmailState
}
