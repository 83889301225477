import { createActions } from 'redux-actions'

const { resetFlightStatusSearch, changeFlightStatusSearch } = createActions({
  RESET_FLIGHT_STATUS_SEARCH: () => {},
  CHANGE_FLIGHT_STATUS_SEARCH: state => state
})

const setFlightStatusSearch = selected => {
  return dispatch => {
    dispatch(changeFlightStatusSearch({ selected }))
  }
}

export { resetFlightStatusSearch, setFlightStatusSearch, changeFlightStatusSearch }
