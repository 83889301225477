import styled from 'styled-components'

import mediaQueries from 'components/media-queries'

export const Overlay = styled.div`
  -webkit-backface-visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2000;
  background-color: ${({ theme }) => theme.shadow.black65};
  display: ${({ noOverlay }) => (noOverlay ? 'none' : 'auto')};
`

export const Content = styled.div`
  -webkit-backface-visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: ${({ noOverlay }) => (noOverlay ? 'flex-end' : 'center')};
`

export const Dialog = styled.div`
  width: ${({ autoWidth, width }) => (autoWidth ? 'auto' : width ? width : '60%')};
  max-height: 90%;
  overflow-y: ${({ overflowY }) => overflowY || 'auto'};
  position: relative;
  outline: 0;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  ${({ shadow, theme }) => shadow && `box-shadow: 0 1px 4px ${theme.shadow.black12}`};

  ${mediaQueries.mobile`
    ${({ width, autoWidth, mobileMaxHeight, mobileMaxWidth }) => `
      width: ${autoWidth ? 'auto' : width ? width : '100%'};
      max-height: ${mobileMaxHeight || '100%'};
      ${mobileMaxWidth &&
        `
        max-width: ${mobileMaxWidth};
      `}
    `}
  `}
`
