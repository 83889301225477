import { handleActions } from 'redux-actions'

import {
  transferMilesStart,
  transferMilesSuccess,
  transferMilesError,
  clearTransferMilesState
} from 'actions/arplus/transferMiles'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const transferMiles = handleActions(
  {
    [transferMilesStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [transferMilesSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [transferMilesError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearTransferMilesState]: () => ({ ...defaultState })
  },
  defaultState
)

export default transferMiles
