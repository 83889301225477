import { handleActions } from 'redux-actions'

import {
  changePaymentStatus,
  resetPaymentStatus,
  resetSecondPaymentStatus,
  changeSecondPaymentStatus
} from 'actions/paymentStatus'

const defaultState = {
  creditCard: null,
  secondCreditCard: null
}

const reducer = handleActions(
  {
    [changePaymentStatus]: (state, { payload: { creditCard } }) => ({
      ...state,
      creditCard
    }),
    [changeSecondPaymentStatus]: (state, { payload: { secondCreditCard } }) => ({
      ...state,
      secondCreditCard
    }),
    [resetPaymentStatus]: state => ({
      ...state,
      creditCard: null
    }),
    [resetSecondPaymentStatus]: state => ({
      ...state,
      secondCreditCard: null
    })
  },
  defaultState
)

export default reducer
