/* eslint-disable import/no-cycle */
import GDS_MESSAGES from 'constants/gdsMessages'
import {
  AUTHORIZATION_RESPONSE_CODE,
  PAYMENT_FLOW_TYPE,
  CHANNEL_BOOKING_TYPES,
  FOP_CODE,
  PAYMENT_METHODS,
  SERVICES_3P
} from 'constants/payment'
import { getIpAddressPromise } from 'helpers/arplus'
import { getDeviceFingerprintID } from 'helpers/deviceFingerprint'
import { getLSValue } from 'helpers/localStorage'
import { successLogMyReservation } from 'helpers/successLogMyReservation'
import { buildURLEncoded } from 'helpers/url'
import {
  generateGlobal3pRedirectRoute,
  generateMercadoPagoRedirectRoute,
  generateExternalPaymentRoute
} from 'helpers/url/localization'
import { decodeBuffToB64, getBrowserDetails } from 'helpers/utils'
import i18n from 'localization/i18n'
import COOKIE_NAMES from 'server/utils/cookieNames'
import ROUTES from 'server/utils/routes'

export const mapDocument = (type, number) => ({
  documentType: type,
  documentNumber: number
})

const mapDocumentArray = paymentData => {
  const documents = [mapDocument(paymentData.documentType, paymentData.documentNumber)]
  const { taxPayer_taxPayerCategory, taxPayer_taxPayerNumber } = paymentData

  const taxPayerDocuments =
    taxPayer_taxPayerCategory &&
    taxPayer_taxPayerNumber &&
    mapDocument(taxPayer_taxPayerCategory, taxPayer_taxPayerNumber)

  taxPayerDocuments && documents.push(taxPayerDocuments)

  return documents
}

const mapInstallments = (installments, interestAmount) => {
  return installments > 1
    ? {
        installment: {
          months: installments,
          interestAmount
        }
      }
    : null
}

export const mapCreditCard = (
  paymentData,
  amount,
  installments,
  interestAmount,
  selectedIssuer,
  cardIsoCode,
  auxiliaryCode,
  merchantId,
  pspPlan
) => ({
  number: paymentData.creditCard_number,
  cvc: paymentData.creditCard_cvc,
  holderName: paymentData.creditCard_holderName.trim(),
  expirationDate:
    paymentData.creditCard_expirationDateMonth &&
    paymentData.creditCard_expirationDateYear &&
    `${paymentData.creditCard_expirationDateMonth}-${paymentData.creditCard_expirationDateYear}`,
  cardCode: auxiliaryCode || paymentData.creditCard_cardCode,
  cardIsoCode,
  bank: selectedIssuer, //Named bank as it's how gds expects data based on AAW-9413
  merchantId,
  paymentPlan: pspPlan,
  amount,
  ...mapInstallments(installments, interestAmount)
})
export const mapCreditCardTwo = (
  firstFormValues,
  amount,
  installments,
  interestAmount,
  selectedIssuer,
  cardIsoCode,
  auxiliaryCode,
  merchantId,
  pspPlan
) => ({
  number: firstFormValues.creditCard_number,
  cvc: firstFormValues.creditCard_cvc,
  holderName: firstFormValues.creditCard_holderName.trim(),
  expirationDate:
    firstFormValues.creditCard_expirationDateMonth &&
    firstFormValues.creditCard_expirationDateYear &&
    `${firstFormValues.creditCard_expirationDateMonth}-${firstFormValues.creditCard_expirationDateYear}`,
  cardCode: auxiliaryCode || firstFormValues.creditCard_cardCode,
  cardIsoCode,
  bank: selectedIssuer, //Named bank as it's how gds expects data based on AAW-9413
  merchantId,
  paymentPlan: pspPlan,
  amount,
  ...mapInstallments(installments, interestAmount)
})

export const mapBillingAddress = paymentData => ({
  street: paymentData.billingAddress_street,
  number: paymentData.billingAddress_number,
  city: paymentData.billingAddress_city,
  province: paymentData.billingAddress_province,
  zipCode: paymentData.billingAddress_zipCode,
  country: paymentData.billingAddress_country,
  apartment: paymentData.billingAddress_apartment,
  floor: paymentData.billingAddress_floor
})

export const mapPaymentData = (
  paymentData,
  totalAmount,
  installments,
  interestAmount,
  selectedIssuer,
  cardIsoCode,
  auxiliaryCode,
  merchantId,
  pspPlan,
  isTwoCards,
  secondCardAmount,
  firstCardAmount,
  firstFormValues,
  secondCardIsoCode,
  secondMerchantId,
  secondPspPlan,
  secondAuxiliaryCode
) => ({
  creditCards: [
    {
      ...mapCreditCard(
        paymentData,
        isTwoCards ? secondCardAmount : totalAmount,
        installments,
        interestAmount,
        selectedIssuer,
        isTwoCards ? secondCardIsoCode : cardIsoCode,
        isTwoCards ? secondAuxiliaryCode : auxiliaryCode,
        isTwoCards ? secondMerchantId : merchantId,
        isTwoCards ? secondPspPlan : pspPlan
      )
    },
    ...((isTwoCards && [
      {
        ...mapCreditCardTwo(
          firstFormValues,
          firstCardAmount,
          installments,
          interestAmount,
          selectedIssuer,
          cardIsoCode,
          auxiliaryCode,
          merchantId,
          pspPlan
        )
      }
    ]) ||
      [])
  ],
  billingAddress: {
    ...mapBillingAddress(paymentData)
  },
  documents: mapDocumentArray(paymentData),
  taxPayerCategory: paymentData.taxPayer_taxPayerCategory
})

const mapReservationData = (passengers, amount, currency) => ({
  email: passengers && passengers.contactInformation.emails[0],
  gender: passengers && passengers.passengersData[0].gender,
  shoppingId: passengers && passengers.shoppingId,
  referrer: getLSValue('referrer'),
  deviceFingerprintID: getDeviceFingerprintID(),
  amount,
  currency
})

export const mapGlobalPaymentReturnUrl = (
  {
    is3POnlinePayment,
    is3PConfirmation,
    shoppingId,
    isMercadoPagoSelected,
    channel,
    query,
    payload
  },
  queryObj = {}
) => {
  if (!isMercadoPagoSelected) {
    delete payload?.creditCards

    //TODO: remove when SIA-902 and SIA-846 are resolved
    const pnrOrShoppingId = query?.reservationCode || query?.shoppingId || shoppingId
    if (pnrOrShoppingId && is3PConfirmation) {
      const msg = `3P_ONLINE_PAYMENT_CLIENT-${pnrOrShoppingId}`
      const log = {
        is3POnlinePayment,
        is3PConfirmation,
        channel,
        query,
        payload
      }
      successLogMyReservation(pnrOrShoppingId, msg, log)
    }
  }

  const buildUrl = (query, addParams) =>
    buildURLEncoded(
      `${window.location.origin}${
        isMercadoPagoSelected
          ? generateMercadoPagoRedirectRoute(i18n.language)
          : generateGlobal3pRedirectRoute(i18n.language)
      }${query && `?${query}${addParams ? addParams : ''}`}`
    )

  const returnUrlData = query =>
    isMercadoPagoSelected
      ? {
          externalDetails: {
            fopCode: FOP_CODE.MP,
            returnUrl: {
              success: buildUrl(query, '&status=SUCCESS'),
              pending: '',
              failure: ''
            },
            ...(is3PConfirmation ? getBrowserDetails() : {})
          },
          paymentType: CHANNEL_BOOKING_TYPES.EXTERNAL
        }
      : is3POnlinePayment
      ? {
          globalDetails: {
            returnUrl: buildUrl(query),
            ...(is3PConfirmation ? getBrowserDetails() : {})
          }
        }
      : null

  const generateQuery = (queryObj = {}) =>
    Object.keys(queryObj)
      .filter(item => queryObj[item])
      .map(item => `${item}=${queryObj[item]}`)
      .join('&')

  if (is3PConfirmation) {
    if (shoppingId) queryObj.shoppingId = shoppingId
  }

  queryObj.deviceFingerprintID = getDeviceFingerprintID()

  return returnUrlData(generateQuery(queryObj))
}

export const mapCheckoutPurchaseData = (
  firstFormValues,
  paymentData,
  installments,
  passengers,
  totalAmount,
  currency,
  holdOptionId,
  interestAmount,
  is3POnlinePayment,
  cardIsoCode,
  auxiliaryCode,
  selectedIssuer,
  merchantId,
  pspPlan,
  channel,
  is3PConfirmation,
  isTwoCards,
  secondCardAmount,
  firstCardAmount,
  secondCardIsoCode,
  secondMerchantId,
  secondPspPlan,
  secondAuxiliaryCode,
  isMercadoPagoSelected
) => {
  const parsePaymentData = isMercadoPagoSelected
    ? {}
    : mapPaymentData(
        paymentData,
        totalAmount,
        installments,
        interestAmount,
        selectedIssuer,
        cardIsoCode,
        auxiliaryCode,
        merchantId,
        pspPlan,
        isTwoCards,
        secondCardAmount,
        firstCardAmount,
        firstFormValues,
        secondCardIsoCode,
        secondMerchantId,
        secondPspPlan,
        secondAuxiliaryCode
      )

  const parseBasePayload = {
    documents: mapDocumentArray(paymentData),
    taxPayerCategory: paymentData.taxPayer_taxPayerCategory,
    ...parsePaymentData,
    ...mapReservationData(passengers, totalAmount, currency)
  }

  const basePayload = {
    isMercadoPagoSelected,
    ...parseBasePayload,
    ...mapGlobalPaymentReturnUrl(
      {
        is3POnlinePayment,
        is3PConfirmation,
        shoppingId: passengers?.shoppingId,
        isMercadoPagoSelected,
        channel,
        payload: {
          ...parseBasePayload
        },
        query: {
          localConfirmation: is3PConfirmation
        }
      },
      { localConfirmation: is3PConfirmation }
    )
  }
  if (holdOptionId) {
    return {
      ...basePayload,
      holdOptionId
    }
  }

  return basePayload
}

export const mapMissingInfoPurchaseData = (
  paymentData,
  installments,
  passengers,
  amount,
  currency,
  holdOptionId,
  interestAmount,
  is3POnlinePayment,
  cardIsoCode,
  auxiliaryCode,
  selectedIssuer,
  merchantId,
  pspPlan,
  reservationCode,
  lastName,
  channel,
  is3PConfirmation
) => {
  const basePayload = {
    ...mapPaymentData(
      paymentData,
      amount,
      installments,
      interestAmount,
      selectedIssuer,
      cardIsoCode,
      auxiliaryCode,
      merchantId,
      pspPlan
    ),
    missingInfoFlow: true,
    deviceFingerprintID: getDeviceFingerprintID(),
    amount,
    currency,
    reservationCode,
    lastName,
    ...mapGlobalPaymentReturnUrl(
      { is3POnlinePayment, is3PConfirmation, shoppingId: passengers.shoppingId, channel },
      { localConfirmation: is3PConfirmation }
    )
  }

  if (holdOptionId) {
    return {
      ...basePayload,
      holdOptionId
    }
  }
  return basePayload
}

export const mapBookingPurchase = (
  firstFormValues,
  paymentData,
  installments,
  amount,
  currency,
  email,
  trxId,
  cardIsoCode,
  auxiliaryCode,
  merchantId,
  interestAmount,
  selectedIssuer,
  pspPlan,
  isTwoCards,
  secondCardAmount,
  firstCardAmount,
  secondCardIsoCode,
  secondMerchantId,
  secondPspPlan,
  secondAuxiliaryCode
) => ({
  email,
  deviceFingerprintID: getDeviceFingerprintID(),
  amount,
  currency,
  ...mapPaymentData(
    paymentData,
    amount,
    installments,
    interestAmount,
    selectedIssuer,
    cardIsoCode,
    auxiliaryCode,
    merchantId,
    pspPlan,
    isTwoCards,
    secondCardAmount,
    firstCardAmount,
    firstFormValues,
    secondCardIsoCode,
    secondMerchantId,
    secondPspPlan,
    secondAuxiliaryCode
  ),
  ...trxId
})

export const mapReservationCashPurchaseData = (
  paymentData,
  passengers,
  amount,
  currency,
  fopCode
) => ({
  paymentType: PAYMENT_METHODS.OFFLINE,
  ...mapReservationData(passengers, amount, currency),
  afopDetails: {
    afopClientDetails: {
      firstName: paymentData.name,
      lastName: paymentData.lastname,
      gender: 'MALE',
      email: paymentData.email
    },
    fopCode,
    returnUrl: buildURLEncoded(`${window.location.origin}${ROUTES.PAGO_MIS_CUENTAS.URL}`, {
      shoppingId: passengers.shoppingId
    })
  },
  documents: mapDocumentArray(paymentData),
  phone: passengers && passengers.contactInformation.phones[0]
})

export const mapServerMercadoPagoData = ({ preference_id, external_reference }) => ({
  preferenceId: preference_id,
  reservationCode: external_reference?.split('_')[0],
  lastName: external_reference?.split('_')[1]
})

/** Only for server side and PMC */
export const mapServerPMCData = ({ amount, currency, shoppingId, transactionID, orderNumber }) => ({
  paymentType: 'AFOP',
  shoppingId,
  amount,
  currency,
  gender: 'MALE',
  afopDetails: {
    afopClientDetails: {
      firstName: ' ',
      lastName: ' ',
      birthDate: '',
      email: '',
      street: ' ',
      houseNumber: ' ',
      postCode: '',
      province: ' ',
      city: ' ',
      country: ' ',
      gender: 'MALE'
    },
    fopCode: 'OP-104'
  },
  fopCode: 'OP-104',
  orderNumber,
  transactionId: transactionID,
  verificationResult: 'SUCCESS'
})

/** Only for server side and 3P */
export const mapServer3pData = ({ pnr, lastName, psp_TransactionId, psp_MerchTxRef }) => ({
  reservationCode: pnr,
  lastName,
  transactionId: psp_TransactionId,
  merchantTransactionReference: psp_MerchTxRef
})

export const mapServer3pLocalData = ({
  shoppingId,
  PaymentRef,
  ResultCode,
  ResponseCode,
  MerchantID,
  deviceFingerprintID
}) => ({
  shoppingId,
  paymentReference: PaymentRef,
  resultCode: ResultCode,
  responseCode: ResponseCode,
  merchantId: MerchantID,
  transactionId: PaymentRef,
  deviceFingerprintID: deviceFingerprintID
})

const mapServer3pChallengeData = ({
  reservationCode,
  lastName,
  ResponseCode,
  ResultCode,
  IFRAMETARGET,
  HEIGHT,
  WIDTH,
  clearTextChallengeFormDisabled,
  encodedChallengeForm
}) => ({
  reservationCode,
  lastName,
  responseCode: ResponseCode,
  resultCode: ResultCode,
  iframe: {
    iframeTarget: IFRAMETARGET,
    height: HEIGHT,
    width: WIDTH,
    clearTextChallengeFormDisabled,
    encodedChallengeForm: decodeBuffToB64(encodedChallengeForm, 'utf-8') || ''
  }
})

const validateRedirectionUrl3p = ({ service, language }) =>
  service === SERVICES_3P.ANCILLARIES
    ? generateExternalPaymentRoute(language, PAYMENT_FLOW_TYPE.THIRD_PARTY_ANCILLARIES)
    : service === SERVICES_3P.EXCHANGE
    ? generateExternalPaymentRoute(language, PAYMENT_FLOW_TYPE.THIRD_PARTY_EXCHANGE)
    : service === SERVICES_3P.BNPL
    ? generateExternalPaymentRoute(language, PAYMENT_FLOW_TYPE.THIRD_PARTY_BNPL)
    : generateExternalPaymentRoute(language, PAYMENT_FLOW_TYPE.THIRD_PARTY_CHECKOUT)

export const validateServer3pChallenge = async (req, res) => {
  const { body, i18n } = req
  const { ResponseCode, service } = body || {}
  res?.clearCookie(COOKIE_NAMES.PURCHASING_CHALLENGE)

  switch (ResponseCode) {
    case AUTHORIZATION_RESPONSE_CODE.BEGIN_CHALLENGE:
      return {
        paymentFailureMessage: null,
        cookieData: mapServer3pChallengeData(body),
        redirectionUrl: validateRedirectionUrl3p({
          service,
          language: i18n.language
        })
      }
    case AUTHORIZATION_RESPONSE_CODE.ERROR:
    case AUTHORIZATION_RESPONSE_CODE.DECLINED:
      return {
        paymentFailureMessage: GDS_MESSAGES.PURCHASING_CHALLENGE_FAILURE,
        cookieData: { responseCode: ResponseCode },
        redirectionUrl: validateRedirectionUrl3p({
          service,
          language: i18n.language
        })
      }
    default:
      return null
  }
}

// Arplus payment maps
const mapArplusPaymentData = (paymentData, cardIsoCode, auxiliaryCode) => ({
  creditCard: {
    number: paymentData.creditCard_number,
    cvc: paymentData.creditCard_cvc,
    holderName: paymentData.creditCard_holderName.trim(),
    expirationDate:
      paymentData.creditCard_expirationDateMonth &&
      paymentData.creditCard_expirationDateYear &&
      `${paymentData.creditCard_expirationDateMonth}-${paymentData.creditCard_expirationDateYear}`,
    cardCode: auxiliaryCode || paymentData.creditCard_cardCode,
    cardIsoCode
  },
  billingAddress: {
    street: paymentData.billingAddress_street,
    houseNumber: paymentData.billingAddress_number,
    city: paymentData.billingAddress_city,
    province: paymentData.billingAddress_province,
    zipCode: paymentData.billingAddress_zipCode,
    country: paymentData.billingAddress_country,
    apartment: paymentData.billingAddress_apartment,
    floor: paymentData.billingAddress_floor
  },
  documents: mapDocumentArray(paymentData)
})

const mapMemberInfoData = memberInfoData => {
  const {
    membershipCode,
    firstName,
    lastName,
    identificationType,
    identificationCode,
    gender,
    contactInformation
  } = memberInfoData

  const { mail, phone } = contactInformation

  return {
    membershipCode,
    firstName,
    lastName,
    identificationType,
    identificationCode,
    gender,
    contactInformation: { mail: mail, phone }
  }
}

export const mapArplusPaymentMiles = async (
  formData,
  cardIsoCode,
  auxiliaryCode,
  memberInfoData,
  promoMilesAndBonusData,
  evaluateMemberEligibilityEmdType,
  milesAmountData
) => {
  const memberInfo = mapMemberInfoData(memberInfoData)
  const paymentData = mapArplusPaymentData(formData, cardIsoCode, auxiliaryCode)
  const ipAddress = await getIpAddressPromise()

  const {
    totalAmount,
    usdAmount,
    totalArsCurrency,
    totalTaxes,
    totalUsdAmount,
    totalArsAmount
  } = milesAmountData

  return {
    currency: 'ARS',
    totalAmount,
    usdAmount,
    totalArsCurrency,
    totalBaseAmount: totalUsdAmount,
    totalEquivalentAmount: totalArsAmount,
    totalTaxes,
    memberInfo,
    deviceFingerprintID: getDeviceFingerprintID(),
    ipAddress: ipAddress || '',
    ...paymentData,
    emdType: evaluateMemberEligibilityEmdType,
    promoMilesAmount: promoMilesAndBonusData?.promoMilesAmount,
    milesAmount: promoMilesAndBonusData?.milesAmount
  }
}

export const mapDestinationMemberInfo = destinationMemberInfo => {
  const {
    destinationMember,
    destinationMemberName,
    destinationMemberLastName,
    identificationTypeDestinationMember,
    identificationNumberDestinationMember,
    mailDestinationMember,
    particularPhoneDestinationMember,
    prefLocationDestinationMember,
    prefCountryDestinationMember
  } = destinationMemberInfo

  return {
    membershipCode: destinationMember,
    firstName: destinationMemberName,
    lastName: destinationMemberLastName,
    identificationType: identificationTypeDestinationMember,
    identificationCode: identificationNumberDestinationMember,
    contactInformation: {
      mail: mailDestinationMember,
      phone: {
        number: particularPhoneDestinationMember,
        areaCode: prefLocationDestinationMember,
        countryCode: prefCountryDestinationMember
      }
    }
  }
}

export const formatMilesAmount = milesData => {
  let {
    milesAmount,
    totalAmount,
    usdAmount,
    totalArsCurrency,
    totalTaxes,
    totalArsAmount,
    totalUsdAmount
  } = milesData

  return {
    milesAmount,
    totalAmount: parseFloat(totalAmount),
    usdAmount: parseFloat(usdAmount),
    totalArsCurrency: parseFloat(totalArsCurrency),
    totalTaxes: parseFloat(totalTaxes),
    totalArsAmount: parseFloat(totalArsAmount),
    totalUsdAmount: parseFloat(totalUsdAmount)
  }
}

export const mapArplusTransferMiles = async (
  formData,
  cardIsoCode,
  auxiliaryCode,
  memberInfoData,
  evaluateMemberEligibilityEmdType,
  milesAmountData,
  evaluateMemberEligibility,
  promoMilesAmount
) => {
  const memberInfo = mapMemberInfoData(memberInfoData)
  const paymentData = mapArplusPaymentData(formData, cardIsoCode, auxiliaryCode)
  const milesInfo = formatMilesAmount(milesAmountData)
  const ipAddress = await getIpAddressPromise()

  const {
    milesAmount,
    totalAmount,
    usdAmount,
    totalArsCurrency,
    totalTaxes,
    totalArsAmount,
    totalUsdAmount
  } = milesInfo

  const destinationMemberInfo = mapDestinationMemberInfo(evaluateMemberEligibility)
  return {
    currency: 'ARS',
    totalAmount,
    usdAmount,
    totalArsCurrency,
    totalTaxes,
    totalBaseAmount: totalUsdAmount,
    totalEquivalentAmount: totalArsAmount,
    memberInfo,
    destinationMemberInfo,
    deviceFingerprintID: getDeviceFingerprintID(),
    ipAddress: ipAddress || '',
    ...paymentData,
    emdType: evaluateMemberEligibilityEmdType,
    milesAmount: milesAmount,
    promoMilesAmount
  }
}

export const mapSublosPayment = ({
  paymentData,
  installments,
  interestAmount,
  selectedIssuer,
  cardIsoCode,
  auxiliaryCode,
  merchantId,
  pspPlan,
  paymentData: { amount, currency, emails, orderIds, gender, taxPayer_taxPayerCategory },
  ticketOption
}) => ({
  amount,
  currency,
  email: emails.length ? emails[0] : '',
  orderIds: orderIds,
  gender: gender,
  referrer: getLSValue('referrer'),
  deviceFingerprintID: getDeviceFingerprintID(),
  taxPayerCategory: taxPayer_taxPayerCategory,
  taxPayerNumber: paymentData?.taxPayer_taxPayerNumber,
  creditCards: [
    {
      ...mapCreditCard(
        paymentData,
        amount,
        installments,
        interestAmount,
        selectedIssuer,
        cardIsoCode,
        auxiliaryCode,
        merchantId,
        pspPlan
      )
    }
  ],
  billingAddress: {
    street: paymentData?.billingAddress_street,
    number: paymentData?.billingAddress_number,
    city: paymentData?.billingAddress_city,
    province: paymentData?.billingAddress_province,
    zipCode: paymentData?.billingAddress_zipCode,
    country: paymentData?.billingAddress_country,
    apartment: paymentData?.billingAddress_apartment,
    floor: paymentData?.billingAddress_floor
  },
  documents: [
    {
      documentType: paymentData?.documentType,
      documentNumber: paymentData?.documentNumber
    }
  ],
  ticketOption
})
