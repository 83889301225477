import { createActions } from 'redux-actions'

const { setActiveTab } = createActions({
  SET_ACTIVE_TAB: tabsState => tabsState
})

const changeActiveTab = activeTab => {
  return dispatch => {
    dispatch(setActiveTab({ activeTab }))
  }
}

export { setActiveTab, changeActiveTab }
