import React, { Fragment } from 'react'

import PropTypes from 'prop-types'
import FocusLock from 'react-focus-lock'
import { connect } from 'react-redux'

// commented bodyScroll lock usage to "rollback" changes.
// TODO: Use the lib and check it works on EVERY device.
// import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'

import IconNames from 'components/icons/iconNames'
import MobileView from 'components/mobile-view'
import { isMobile } from 'helpers/utils'
import { mapMediaType } from 'selectors/mediaType'

import { getOneOfSiblingPropType } from './helpers'
import { Overlay, Content, Dialog } from './styled'

/**
 * @todo Uncomment code when enabling bodyscroll lock.
 */
const Modal = ({
  onClose,
  children,
  autoWidth,
  width,
  shadow,
  backgroundColor,
  noOverlay,
  fullHeight,
  forceFullHeight,
  isMobile,
  titleModalFullHeight,
  fontSizeModalFullHeight,
  iconModalFullHeight,
  mobileMaxWidth,
  titleSemiBold
}) => {
  const onOverClick = () => onClose && onClose()

  const onClickDialog = ev => ev.stopPropagation()

  const getMobileComponent = () => (
    <MobileView
      onClose={onClose}
      title={titleModalFullHeight}
      icon={iconModalFullHeight}
      titleFontSize={fontSizeModalFullHeight}
      titleSemiBold={titleSemiBold}
    >
      {children}
    </MobileView>
  )

  const getTabletDesktopComponent = () => (
    <>
      <Overlay noOverlay={noOverlay} />
      <FocusLock>
        <Content onClick={onOverClick} noOverlay={noOverlay}>
          <Dialog
            onClick={onClickDialog}
            autoWidth={autoWidth}
            width={width}
            shadow={shadow}
            backgroundColor={backgroundColor}
            // overflowY={overflowY}
            // mobileMaxHeight={mobileMaxHeight}
            mobileMaxWidth={mobileMaxWidth}
          >
            {children}
          </Dialog>
        </Content>
      </FocusLock>
    </>
  )

  return (
    // <div ref={targetElement}>
    forceFullHeight || (fullHeight && isMobile) ? getMobileComponent() : getTabletDesktopComponent()
    // </div>
  )
}

Modal.propTypes = {
  forceFullHeight: getOneOfSiblingPropType('fullHeight'),
  fullHeight: getOneOfSiblingPropType('forceFullHeight'),
  titleModalFullHeight: PropTypes.string,
  iconModalFullHeight: PropTypes.oneOf(Object.keys(IconNames))
}

const mapStateToProps = state => ({
  isMobile: isMobile(mapMediaType(state))
})
export default connect(mapStateToProps)(Modal)
