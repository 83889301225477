import { negotiateToken } from 'server/services/auth'
import { apiV2, setTokenHeader } from 'services/api'

export const successLogMyReservation = async (shoppingId, message, response) => {
  const accessToken = await negotiateToken()

  setTokenHeader(apiV2.defaults.headers.common, accessToken)

  const buff = new Buffer(JSON.stringify(response))
  const encodedResponse = buff.toString('base64')
  apiV2.post('/v2/booking/client-errors/purchase', {
    shoppingId,
    message,
    response: encodedResponse
  })
}
