import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Modal from './modal'

const ModalContainer = ({ modalState: { modals }, children, name, ...rest }) => {
  const isModalVisible = modals[name] && modals[name].isVisible

  return isModalVisible ? <Modal {...rest}>{children}</Modal> : null
}

const mapStateToProps = ({ modalState }) => ({
  modalState
})

ModalContainer.propTypes = {
  name: PropTypes.string.isRequired
}

export default connect(mapStateToProps)(ModalContainer)
