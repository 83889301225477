import { handleActions } from 'redux-actions'

import {
  giftMilesStart,
  giftMilesSuccess,
  giftMilesError,
  clearGiftMilesState
} from 'actions/arplus/giftMiles'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const giftMiles = handleActions(
  {
    [giftMilesStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [giftMilesSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [giftMilesError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearGiftMilesState]: () => ({ ...defaultState })
  },
  defaultState
)

export default giftMiles
