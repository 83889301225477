import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import { buildURLEncoded } from 'helpers/url'

const { fetchBrandsStart, fetchBrandsSuccess, fetchBrandsError } = createActions({
  FETCH_BRANDS_START: () => {},
  FETCH_BRANDS_SUCCESS: brands => brands,
  FETCH_BRANDS_ERROR: error => error
})

const fetchBrands = pageConfig => {
  const queryParams = buildURLEncoded('', pageConfig)

  return async dispatch => {
    dispatch(fetchBrandsStart())

    try {
      const brands = await api.get(`${apiRoutes.BRANDS}${queryParams}`)
      dispatch(fetchBrandsSuccess(brands))
    } catch (error) {
      dispatch(fetchBrandsError(error))
    }
  }
}

export { fetchBrandsStart, fetchBrandsSuccess, fetchBrandsError, fetchBrands }
