/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import cmsApiRoutes from 'constants/cmsApiRoutes'
import { logError } from 'helpers/log'
import { cmsApi, ssrCmsApi } from 'services/api'

const { fetchDarkSiteStart, fetchDarkSiteSuccess, fetchDarkSiteError } = createActions({
  FETCH_DARK_SITE_START: () => {},
  FETCH_DARK_SITE_SUCCESS: data => ({ data }),
  FETCH_DARK_SITE_ERROR: error => ({ error })
})

const fetchDarkSiteForServer = async i18n => {
  try {
    const { data } = await ssrCmsApi.get(cmsApiRoutes.DARK_SITE, { i18n })

    return {
      data,
      error: null,
      isFetching: false
    }
  } catch (error) {
    error.fileName = 'darkSite.js'
    error.lineNumber = '15'
    logError(`Error when getting ${ssrCmsApi.defaults.baseURL + cmsApiRoutes.DARK_SITE}`, '', error)
    return {
      error,
      data: null,
      isFetching: false
    }
  }
}

const fetchDarkSite = () => {
  return async dispatch => {
    dispatch(fetchDarkSiteStart())

    try {
      const darkSite = await cmsApi.get(cmsApiRoutes.DARK_SITE)
      dispatch(fetchDarkSiteSuccess(darkSite))
    } catch (error) {
      dispatch(fetchDarkSiteError(error))
    }
  }
}

export {
  fetchDarkSiteStart,
  fetchDarkSiteSuccess,
  fetchDarkSiteError,
  fetchDarkSiteForServer,
  fetchDarkSite
}
