import dayjs from 'dayjs'

import FORMATS from 'constants/formats'
import { TRIP_TYPES } from 'constants/tripTypes'
import { buildURLEncoded } from 'helpers/url'

import { parseUrlsInResponse } from './cms'

// Return flights offers search url params based on flights search data.
export const getFlightsSearchDataUrl = (flightsSearchData, includeFilters, isFlexWithCampaign) => {
  const {
    adt,
    inf,
    chd,
    flexDates,
    cabinClass,
    legs,
    flightType,
    promoCode,
    shoppingId,
    campaignData,
    awardBooking
  } = flightsSearchData

  // Create partial URL with single value params
  const baseParams = buildURLEncoded('', {
    adt,
    inf,
    chd,
    flexDates,
    cabinClass,
    flightType,
    promoCode,
    shoppingId,
    ...(awardBooking && { awardBooking })
  })

  let searchResult = baseParams

  // Create leg URL params
  searchResult += legs
    .map(({ origin, destination, departure }) => `&leg=${origin}-${destination}-${departure}`)
    .join('')

  // Create campaign URL params
  if (includeFilters) {
    searchResult += generateFilterParamsUrl(flightsSearchData)
  }

  // Create campaign URL params
  if (campaignData) {
    const { campaignCode, promotionFieldEntries, conferenceCode, conferenceName } = campaignData
    searchResult += generateCampaignParams({
      campaignCode,
      conferenceCode,
      conferenceName,
      promotionFieldEntries
    })
  }

  /* Create campaign URL params when coming from flex calendar from landings otherwise it doesn't
  attach params. If campaignData is destructured above, it breaks conference page behavior*/

  if (isFlexWithCampaign) {
    const { campaignCode, promotionField } = flightsSearchData

    searchResult += generateCampaignParams({
      campaignCode,
      promotionField
    })
  }

  return searchResult
}

const generateCampaignParams = paramsObject =>
  Object.entries(paramsObject)
    .map(([paramKey, paramValue]) =>
      paramValue
        ? paramKey === 'promotionFieldEntries'
          ? mapPromotionFieldEntries(paramValue)
          : // To support any array of values different from promotionFieldEntries
          Array.isArray(paramValue)
          ? mapArrayParam(paramKey, paramValue)
          : `&${paramKey}=${paramValue}`
        : ''
    )
    .join('')

const mapPromotionFieldEntries = promotionFieldEntries =>
  promotionFieldEntries.map(([key, { value }]) => `&promotionField=${key}-${value}`).join('')

const mapArrayParam = (key, valueArray) => valueArray.map(value => `&${key}=${value}`).join('')

const generateFilterParamsUrl = flightsSearchData => {
  // Create a copy of flightsSearchData to safely remove the legs property
  let searchDataCopy = Object.assign({}, flightsSearchData)

  // Remove properties that are not from filters
  delete searchDataCopy.legs
  delete searchDataCopy.adt
  delete searchDataCopy.inf
  delete searchDataCopy.chd
  delete searchDataCopy.flexDates
  delete searchDataCopy.cabinClass
  delete searchDataCopy.flightType
  delete searchDataCopy.promoCode
  delete searchDataCopy.shoppingId
  delete searchDataCopy.awardBooking

  // Iterate it & return it in url parameters form
  return Object.keys(searchDataCopy)
    .map(filter =>
      Array.isArray(searchDataCopy[filter])
        ? searchDataCopy[filter].map(e => `&${filter}=${e}`).join('')
        : searchDataCopy[filter] && `&${filter}=${searchDataCopy[filter]}`
    )
    .join('')
}

export const formatData = (searchBoxData, shoppingId) => ({
  flexDates: searchBoxData.flexDates,
  cabinClass: searchBoxData.passengers.radioGroupValue,
  ...generatePassengers(searchBoxData),
  legs: generateLegs(searchBoxData),
  flightType: searchBoxData.flightTypes,
  promoCode: searchBoxData.promoCode || null,
  campaignData: searchBoxData.campaignData || null,
  shoppingId,
  awardBooking: searchBoxData.awardBooking
})

const generatePassengers = passengersData => ({
  adt: passengersData.passengers.passengers['0'],
  chd: passengersData.passengers.passengers['1'],
  inf: passengersData.passengers.passengers['2']
})

const generateLegs = travelData => {
  const legs = []

  travelData.legs.forEach(({ fromSelected, toSelected, date }) => {
    legs.push({
      origin: fromSelected.cityCode.toUpperCase(),
      destination: toSelected.cityCode.toUpperCase(),
      departure: dayjs(date.from).format(FORMATS.DATE)
    })

    if (travelData.flightTypes === TRIP_TYPES.ROUND_TRIP) {
      legs.push({
        origin: toSelected.cityCode.toUpperCase(),
        destination: fromSelected.cityCode.toUpperCase(),
        departure: dayjs(date.to).format(FORMATS.DATE)
      })
    }
  })

  return legs
}

export const clearUrlSearchParams = urlParams => {
  delete urlParams.adt
  delete urlParams.inf
  delete urlParams.chd
  delete urlParams.flexDates
  delete urlParams.cabinClass
  delete urlParams.flightType
  delete urlParams.promoCode
  delete urlParams.leg
  delete urlParams.legs
  delete urlParams.awardBooking

  return urlParams
}

// Delete all the posible filterParams in an url
export const clearUrlFilterParams = urlParams => {
  delete urlParams.scale
  delete urlParams.airport
  delete urlParams.time
  delete urlParams.durationFrom
  delete urlParams.durationTo
  delete urlParams.priceFrom
  delete urlParams.priceTo
  delete urlParams.departingScale
  delete urlParams.returningScale
  delete urlParams.departingAirport
  delete urlParams.returningAirport
  delete urlParams.departingTime
  delete urlParams.returningTime
  delete urlParams.departingDurationFrom
  delete urlParams.departingDurationTo
  delete urlParams.returningDurationFrom
  delete urlParams.returningDurationTo
  delete urlParams.departingPriceFrom
  delete urlParams.departingPriceTo
  delete urlParams.returningPriceFrom
  delete urlParams.returningPriceTo

  return urlParams
}

// Delete all the posible sortingParams in an url
export const clearSortingParams = urlParams => {
  delete urlParams.orderBy
  delete urlParams.departingOrderBy
  delete urlParams.returningOrderBy
  delete urlParams.shoppingId

  return urlParams
}

export const mapParamsValuesToArray = params => {
  const mappedParams = { ...params }

  Object.keys(mappedParams).forEach(param => {
    if (!Array.isArray(mappedParams[param])) {
      mappedParams[param] = [mappedParams[param]]
    }
  })

  return mappedParams
}

export const enableAwardBooking = (locationSearch, configEnableMiles) => {
  const query = new URLSearchParams(parseUrlsInResponse(locationSearch))
  !configEnableMiles && query.delete('awardBooking')

  return '?' + query.toString()
}
