import { createActions } from 'redux-actions'
import axios from 'axios'
import CONFIG from 'config'
import { getLSValue } from 'helpers/localStorage'
import apiRoutes from 'constants/apiRoutes'
import { LogOut } from 'helpers/flightOffers'

const {
  offlineAereoConfirmationStart,
  offlineAereoConfirmationSuccess,
  offlineAereoConfirmationError,
  clearOfflineAereoConfirmationState
} = createActions({
  OFFLINE_AEREO_CONFIRMATION_START: () => {},
  OFFLINE_AEREO_CONFIRMATION_SUCCESS: data => ({ data }),
  OFFLINE_AEREO_CONFIRMATION_ERROR: error => ({ error }),
  CLEAR_OFFLINE_AEREO_CONFIRMATION_STATE: () => {}
})

const postOfflineAereoConfirmation = payload => async dispatch => {
  dispatch(offlineAereoConfirmationStart())

  try {
    const response = await axios.post(`${apiRoutes.AR_PLUS_OFFLINE_AEREO_CONFIRMATION}`, payload, {
      baseURL: CONFIG.API_BASE_URL,
      headers: {
        Authorization: `Bearer ${getLSValue('access_token')}`,
        'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID,
        'Accept-Language': CONFIG.DEFAULT_LOCALE,
        'Content-Type': 'multipart/form-data'
      }
    })

    dispatch(offlineAereoConfirmationSuccess(response.data))
  } catch (error) {
    const err = error.response ? error.response.data : error

    if (err?.statusCode === 401) {
      LogOut()
      dispatch(clearOfflineAereoConfirmationState())
    }
    dispatch(offlineAereoConfirmationError(err))
  }
}

export {
  postOfflineAereoConfirmation,
  offlineAereoConfirmationStart,
  offlineAereoConfirmationSuccess,
  offlineAereoConfirmationError,
  clearOfflineAereoConfirmationState
}
