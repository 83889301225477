import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'

const {
  provincesArplusStart,
  provincesArplusSuccess,
  provincesArplusError  
} = createActions({
  PROVINCES_ARPLUS_START: () => {},
  PROVINCES_ARPLUS_SUCCESS: data => ({ data }),
  PROVINCES_ARPLUS_ERROR: error => ({ error })
})

const fetchProvincesArplusByAR = () => async dispatch => {
  dispatch(provincesArplusStart())
  try {
    const response = await api.get(apiRoutes.PROVINCES_ARPLUS_AR_PLUS)
    dispatch(provincesArplusSuccess(response.data))
  } catch (error) {
    dispatch(provincesArplusError(error.response ? error.response.data : error))
  }
}

export {
  fetchProvincesArplusByAR,
  provincesArplusStart,
  provincesArplusSuccess,
  provincesArplusError
}