import { handleActions } from 'redux-actions'
import { fetchCabinsStart, fetchCabinsSuccess, fetchCabinsError } from 'actions/catalog/cabins'

const stateDefault = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchCabinsStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchCabinsSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [fetchCabinsError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    })
  },
  stateDefault
)

export default reducer
