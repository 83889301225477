import { handleActions } from 'redux-actions'

import {
  memberInfoStart,
  memberInfoSuccess,
  memberInfoError,
  clearMemberInfo
} from 'actions/arplus/getMemberInfo'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const memberInfo = handleActions(
  {
    [memberInfoStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [memberInfoSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [memberInfoError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearMemberInfo]: () => ({ ...defaultState })
  },
  defaultState
)

export default memberInfo
