import styled from 'styled-components'

import mediaQueries from 'components/media-queries'
import loadingFlightsImage from 'assets/loader-flightStatus-result.svg'

//Implement gif when is ready.
const FlightStatusLoader = styled.img.attrs(() => ({
  src: loadingFlightsImage,
  alt: 'Loading...'
}))`
  width: 100%;
  margin: 56px 0 86px 0;

  ${mediaQueries.mobile`
    width: 90%;
    margin: 10px auto;
  `}
`

export default FlightStatusLoader
