import { createSelector } from 'reselect'

import { isEmpty } from 'helpers/utils'

const getBrandsSubState = state => state.brands

const getBrandsState = createSelector([getBrandsSubState], brands => (brands ? brands.data : null))

export const isFetchingBrands = createSelector(
  [getBrandsSubState, getBrandsState],
  (state, data) => state.isFetching || isEmpty(data)
)

export const getBrands = createSelector([getBrandsState], brands =>
  brands ? brands.resources : []
)
