import { batch } from 'react-redux'

import { clearSublosError } from 'actions/sublos/errors'
import { clearReservationStateThunk } from 'components/sublos/main/steps/bookingConfirmation/thunk'
import { clearFlightSelectionStateThunk } from 'components/sublos/main/steps/flightSelection/thunk'
import { clearPassengerDocumentationStateThunk } from 'components/sublos/main/steps/passengersDocumentation/thunk'
import { clearPaymentStateThunk } from 'components/sublos/main/steps/paymentData/thunk'
import { clearPassengerNoNameStateThunk } from 'components/sublos/main/steps/selectNoNamePassenger/thunk'
import { clearPassengerStateThunk } from 'components/sublos/main/steps/selectPassengers/thunk'
import { clearUserDataStateThunk } from 'components/sublos/main/steps/userData/Form/thunk'
import { RESET_SERVICES } from 'constants/sublos/errors'

export const withErrorHandlerThunk = resetServices => async (dispatch, getState) => {
  const state = getState()
  const { sublosErrors } = state

  batch(() => {
    dispatch(clearUserDataStateThunk(resetServices))
    dispatch(clearPassengerStateThunk(resetServices))
    dispatch(clearPassengerNoNameStateThunk(resetServices))
    dispatch(clearReservationStateThunk(resetServices))
    dispatch(clearPassengerDocumentationStateThunk(resetServices))
    dispatch(clearFlightSelectionStateThunk(resetServices))
    dispatch(clearPaymentStateThunk(resetServices))

    sublosErrors &&
      resetServices !== RESET_SERVICES.RESET_ALL_SERVICES_AND_KEEP_ERROR_SCREEN &&
      dispatch(clearSublosError())
  })
}
