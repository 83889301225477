import moment from 'moment'

import { getCodeGdsByDocumentType } from 'api-data-io/gds/documentType'
import { getYearsOptionsForBirthdate } from 'components/forms-common/helpers'
import FORMATS from 'constants/formats'
import { PASSENGER_TYPES } from 'constants/passengerTypes'
import { getDayJsObject } from 'helpers/dates'

/** @description INPUT MAPPING this data is read in booking, when there is already a reservation but
 * some passenger information is missing */

const mapPassengerMissingInfoToFormikInitialValues = (
  passenger,
  idOptionsList,
  showAddresses,
  country
) => {
  const parseBirthDate = passenger.birthDate ? moment(passenger.birthDate) : null
  const expirationDate = passenger.document?.expirationDate
    ? moment(passenger.document.expirationDate)
    : null
  return {
    passengerIndex: passenger.passengerIndex,
    firstName: passenger.firstName,
    lastName: passenger.lastName,
    passengerType: passenger.passengerType,
    showDateOfBirth: !passenger.hasBirthDate,
    showGender: !passenger.gender,
    gender: passenger.gender,
    passenger_birthdate: passenger.birthDate,
    passenger_birthdateDay: parseBirthDate ? parseBirthDate.date() : '',
    passenger_birthdateMonth: parseBirthDate ? parseBirthDate.month() + 1 : '',
    passenger_birthdateYear: parseBirthDate ? parseBirthDate.year() : '',
    showDocument: !passenger.hasDocument,
    document: {
      documentType:
        passenger?.document?.documentType || idOptionsList?.length === 1
          ? passenger?.document?.documentType || idOptionsList[0]?.value
          : '',
      documentNumber: passenger.document ? passenger.document.documentNumber : '',
      nationality: country.value,
      issuingCountry: country.value,
      passenger_expiration: passenger.document?.expirationDate,
      passenger_expirationDay: expirationDate ? expirationDate.date() : '',
      passenger_expirationMonth: expirationDate ? expirationDate.month() + 1 : '',
      passenger_expirationYear: expirationDate ? expirationDate.year() : ''
    },
    showDOCAAddresses: showAddresses && passenger.passengerType !== PASSENGER_TYPES.INF,
    residentialAddress: {
      country: undefined,
      street: '',
      zipCode: '',
      state: '',
      city: '',
      copyFromFirstPassenger: false
    },
    destinationAddress: {
      country: undefined,
      street: '',
      zipCode: '',
      state: '',
      city: '',
      copyFromFirstPassenger: false
    }
  }
}

/**@param showEmergencyContact should be passed down from PCTC backoffice rules
 * @param showAddresses should be passed down from DOCA backoffice rules
 * */
export const mapMissingInfoToFormikInitialValues = (
  missingInfo,
  formikData,
  flightLastDate,
  idOptionsList,
  showEmergencyContact,
  showAddresses,
  country
) => {
  const passengersData = (missingInfo.passengers || []).map(passenger =>
    mapPassengerMissingInfoToFormikInitialValues(passenger, idOptionsList, showAddresses, country)
  )

  let yearsOptionsForBirthdate = {}

  passengersData?.forEach(passenger => {
    yearsOptionsForBirthdate[passenger.passengerType] = getYearsOptionsForBirthdate(
      passenger.passengerType,
      flightLastDate
    )
  })

  return {
    showContactInformation: !missingInfo.hasEmail,
    contactInformation: formikData?.contactInformation || { email: '', confirmationEmail: '' },
    passengersData,
    showEmergencyContact: showEmergencyContact,
    emergencyContact: formikData?.emergencyContact || {
      name: '',
      country: country.value,
      areaCode: '',
      phone: '',
      relationship: ''
    },
    acceptTermsAndConditions: formikData ? formikData.acceptTermsAndConditions : false,
    yearsOptionsForBirthdate
  }
}

/** @description OUTPUT MAPPING this request body is sent in booking, when there is already a reservation but
 * some passenger information is missing */

const mapDOCAAddresses = formikPassenger => {
  const { residentialAddress, destinationAddress, showDOCAAddresses } = formikPassenger
  if (showDOCAAddresses) {
    return {
      residentialAddress: {
        city: residentialAddress?.city,
        state: residentialAddress?.state,
        street: residentialAddress?.street,
        zipCode: residentialAddress?.zipCode,
        country: residentialAddress.country?.value,
        copyFromFirstPassenger: residentialAddress?.copyFromFirstPassenger
      },
      destinationAddress: {
        city: destinationAddress?.city,
        state: destinationAddress?.state,
        street: destinationAddress?.street,
        zipCode: destinationAddress?.zipCode,
        country: destinationAddress.country?.value,
        copyFromFirstPassenger: destinationAddress?.copyFromFirstPassenger
      }
    }
  }
}

const mapDateToFormattedString = dateToFormat => {
  let date
  if (dateToFormat && typeof dateToFormat === 'string') {
    let parseDate = new Date(dateToFormat)
    date = getDayJsObject(parseDate).format(FORMATS.YEAR_MONTH_DAY_DASH)
  }
  return date
}

const mapDocument = formikPassenger => {
  const {
    passenger_expirationYear,
    passenger_expirationMonth,
    passenger_expirationDay
  } = formikPassenger.document

  const parseDate = moment(
    `${passenger_expirationYear}-${passenger_expirationMonth}-${passenger_expirationDay}`
  ).format(FORMATS.YEAR_MONTH_NUMBER_DAY_NUMBER)

  return {
    ...formikPassenger.document,
    expirationDate: mapDateToFormattedString(parseDate)
  }
}

const mapPassenger = formikPassenger => {
  const {
    passenger_birthdateYear,
    passenger_birthdateMonth,
    passenger_birthdateDay,
    passengerIndex,
    firstName,
    lastName,
    passengerType,
    gender,
    documentsCatalog
  } = formikPassenger
  const parseDate = moment(
    `${passenger_birthdateYear}-${passenger_birthdateMonth}-${passenger_birthdateDay}`
  ).format(FORMATS.YEAR_MONTH_NUMBER_DAY_NUMBER)

  return {
    passengerIndex: passengerIndex,
    firstName: firstName,
    lastName: lastName,
    birthDate: mapDateToFormattedString(parseDate),
    passengerType: getCodeGdsByDocumentType(passengerType, documentsCatalog),
    gender: gender,
    document: mapDocument(formikPassenger),
    ...mapDOCAAddresses(formikPassenger)
  }
}

const mapContactInfo = formikData => {
  return formikData.showContactInformation && formikData.contactInformation
    ? {
        contactInformation: {
          emails: [formikData.contactInformation.email]
        }
      }
    : {}
}

const mapEmergencyContact = formikData => {
  return formikData.showEmergencyContact && formikData.emergencyContact
    ? {
        emergencyContact: {
          name: formikData.emergencyContact.name,
          country: formikData.emergencyContact.country,
          areaCode: formikData.emergencyContact.areaCode,
          phone: formikData.emergencyContact.phone,
          relationship: formikData.emergencyContact.relationship
        }
      }
    : {}
}

export const mapPassengerMissingInfo = formikData => ({
  passengersData: formikData.passengersData.map(passenger =>
    mapPassenger({ ...passenger, documentsCatalog: formikData?.documentsCatalog })
  ),
  ...mapContactInfo(formikData),
  ...mapEmergencyContact(formikData)
})
