import axios from 'axios'
import { createActions } from 'redux-actions'

import { hexEncode } from 'helpers/encode'
// eslint-disable-next-line import/no-cycle
import ROUTES from 'server/utils/routes'

const {
  validateRecaptchaStart,
  validateRecaptchaSuccess,
  validateRecaptchaError,
  clearValidateRecaptcha
} = createActions({
  VALIDATE_RECAPTCHA_START: () => {},
  VALIDATE_RECAPTCHA_SUCCESS: data => ({ data }),
  VALIDATE_RECAPTCHA_ERROR: error => ({ error }),
  CLEAR_VALIDATE_RECAPTCHA: () => {}
})

const validateRecaptcha = (token, onSuccess) => {
  return async dispatch => {
    dispatch(validateRecaptchaStart())
    try {
      const encodedToken = hexEncode(token)
      const params = `?token=${encodedToken}`
      const res = await axios.post(
        `${window.location.origin}${ROUTES.VALIDATE_RECAPTCHA.URL}${params}`
      )
      res?.data?.success && onSuccess && onSuccess()
      return dispatch(validateRecaptchaSuccess(res.data))
    } catch (error) {
      return dispatch(validateRecaptchaError(error))
    }
  }
}

export {
  validateRecaptcha,
  validateRecaptchaStart,
  validateRecaptchaSuccess,
  validateRecaptchaError,
  clearValidateRecaptcha
}
