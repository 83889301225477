import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Anchor from 'components/link'

import { isAbsoluteUrl } from 'helpers/validations'
import { doesMatchAnyStaticRoute } from 'helpers/routeMatchers'

/**
 * This component decides whether to render an anchor or router's Link based on pathTo prop.
 */
const HybridLink = ({ pathTo, LinkComponent, AnchorComponent, children, ...rest }) => {
  const getProps = isCommonAnchor => {
    const props = { ...rest }

    if (props.target === '_blank') {
      const noopener = 'noopener noreferrer'
      props.rel = props.rel ? (props.rel += ` ${noopener}`) : noopener
    }

    if (isCommonAnchor) {
      props.href = pathTo
      props.underline = true
    } else {
      props.to = pathTo
    }

    return props
  }

  //External links or NoMatchPage that could be landing url.
  const isCommonAnchor = isAbsoluteUrl(pathTo) || !doesMatchAnyStaticRoute(pathTo)
  const ComponentToRender = isCommonAnchor ? AnchorComponent || Anchor : LinkComponent || Link

  return <ComponentToRender {...getProps(isCommonAnchor)}>{children}</ComponentToRender>
}

HybridLink.propTypes = {
  pathTo: PropTypes.string.isRequired,
  LinkComponent: PropTypes.elementType,
  AnchorComponent: PropTypes.elementType
}

export default HybridLink
