import { createActions } from 'redux-actions'

import { changeModalState } from 'actions/modalActions'
import apiRoutes from 'constants/apiRoutes'
import cmsApiRoutes from 'constants/cmsApiRoutes'
import MODAL_NAMES from 'constants/modalNames'
// eslint-disable-next-line import/no-cycle
import api, { cmsApi } from 'services/api'

const { getContestsStart, getContestsSuccess, getContestsError } = createActions({
  GET_CONTESTS_START: () => {},
  GET_CONTESTS_SUCCESS: data => ({ data }),
  GET_CONTESTS_ERROR: error => ({ error })
})

const getContests = filters => {
  return async dispatch => {
    dispatch(getContestsStart())

    try {
      const { order, available, offset } = filters
      const res = await api.get(`${apiRoutes.CONTESTS(order.value, available, offset)}`)
      const contestsLegalData = await cmsApi.get(cmsApiRoutes.CONTESTS_CMS_LEGAL_PDF)

      dispatch(getContestsSuccess({ data: { ...res.data, contestsLegalData } }))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(getContestsError(err))
      dispatch(
        changeModalState({
          isVisible: true,
          modalName: MODAL_NAMES.CONTESTS_FETCH_ERROR
        })
      )
    }
  }
}

export { getContestsStart, getContestsSuccess, getContestsError, getContests }
