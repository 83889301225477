import { IconNames } from 'components/icons'
import { TRIP_TYPES } from 'constants/tripTypes'
import i18n from 'localization/i18n'

const icons = [IconNames.AirplaneLandingRight, IconNames.AirplaneLandingLeft, IconNames.Airplane]

const labels = ['label.one-way-trip', 'label.return-trip', 'label.leg-with-number']

export const getHeaderIcon = (isMultidestiny, index) => {
  if (!isMultidestiny) {
    return icons[index]
  } else {
    return icons[2]
  }
}

// This helper is and should always be called when i18next instance is initialized and ready.
export const getLegTypeLabel = (isMultidestiny, index, flightType, legsLength) => {
  if (!isMultidestiny) {
    return i18n.isInitialized &&
      (flightType == TRIP_TYPES.ONE_WAY ||
        (index === 0 && flightType == TRIP_TYPES.ROUND_TRIP && legsLength > 1 ? 
        i18n.t(labels[0]) : i18n.t(labels[1])))
  } else {
    return i18n.isInitialized && i18n.t(labels[2], { legNumber: index + 1 })
  }
}
