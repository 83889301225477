import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import { fetchCheckoutFlightSummary } from './flightSummary'

const {
  rangeTooltipStart,
  rangeTooltipSuccess,
  rangeTooltipError,
  resetRangeTooltip,
  sendRangeTooltipStart,
  sendRangeTooltipSuccess,
  sendRangeTooltipError
} = createActions({
  RANGE_TOOLTIP_START: () => {},
  RANGE_TOOLTIP_SUCCESS: data => ({ data }),
  RANGE_TOOLTIP_ERROR: error => ({ error }),
  RESET_RANGE_TOOLTIP: () => {},
  SEND_RANGE_TOOLTIP_START: () => {},
  SEND_RANGE_TOOLTIP_SUCCESS: data => ({ data }),
  SEND_RANGE_TOOLTIP_ERROR: error => ({ error })
})

const fetchRangeTooltip = shoppingId => async dispatch => {
  dispatch(rangeTooltipStart())
  try {
    const response = await api.get(apiRoutes.MILES_FARE_SLIDER(shoppingId))
    dispatch(rangeTooltipSuccess(response.data))
  } catch (error) {
    dispatch(rangeTooltipError(error.response ? error.response.data : error))
  }
}

const postRangeTooltip = payload => async dispatch => {
  const { shoppingId } = payload

  dispatch(sendRangeTooltipStart())
  try {
    const response = await api.post(apiRoutes.MILES_POST_FARE_SLIDER, payload)
    dispatch(sendRangeTooltipSuccess(response.data))
    dispatch(fetchCheckoutFlightSummary(shoppingId))
  } catch (error) {
    dispatch(sendRangeTooltipError(error.response ? error.response.data : error))
  }
}

export {
  fetchRangeTooltip,
  rangeTooltipStart,
  rangeTooltipSuccess,
  rangeTooltipError,
  postRangeTooltip,
  resetRangeTooltip,
  sendRangeTooltipStart,
  sendRangeTooltipSuccess,
  sendRangeTooltipError
}
