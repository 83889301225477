export default {
  MOBILE_ENTRY_EXCHANGES: '_exchangesMobile',
  CULTURE: '_culture',
  EXTERNAL_PAYMENT: '_checkoutExternal',
  PAYMENT_FAILURE_MESSAGE: '_paymentFailure',
  EXTERNAL_ART_ID: 'strPNRvalue',
  TOKEN: '__TOKEN__',
  SUBLOS_TOKEN: 'SublosJWT',
  SUBLOS_USER_DATA: '__SUBLOS_USER_DATA__',
  SUBLOS_ACCESS_TOKEN: '__ACCESS_TOKEN_SUBLOS__',
  SUBLOS_ERROR: '__SUBLOS_ERROR__'
}
