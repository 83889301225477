/* eslint-disable import/no-cycle */
import Loadable from 'react-loadable'

import { ChunkLoader } from 'components/loaders'

export const HomePage = Loadable({
  loader: () => import('./HomePage'),
  loading: ChunkLoader,
  delay: 300
})

export const FlexDatesCalendarPage = Loadable({
  loader: () => import('./FlexDatesCalendarPage'),
  loading: ChunkLoader,
  delay: 300
})

export const FlightOffersPage = Loadable({
  loader: () => import('./FlightOffersPage'),
  loading: ChunkLoader,
  delay: 300
})

export const ContestsPage = Loadable({
  loader: () => import('./ContestsPage'),
  loading: ChunkLoader,
  delay: 300
})

export const CheckoutPage = Loadable({
  loader: () => import('./CheckoutPage'),
  loading: ChunkLoader,
  delay: 300
})

export const AncillariesPage = Loadable({
  loader: () => import('./AncillariesPage'),
  loading: ChunkLoader,
  delay: 300
})

export const MyReservationPage = Loadable({
  loader: () => import('./MyReservationPage'),
  loading: ChunkLoader,
  delay: 300
})

export const NoMatchPage = Loadable({
  loader: () => import('./NoMatchPage'),
  loading: ChunkLoader,
  delay: 300
})

export const SeatsSelectionPage = Loadable({
  loader: () => import('./SeatsSelectionPage'),
  loading: ChunkLoader,
  delay: 300
})

export const FrequentlyAskedQuestionsPage = Loadable({
  loader: () => import('./FrequentlyAskedQuestionsPage'),
  loading: ChunkLoader,
  delay: 300
})

export const FlightStatusPage = Loadable({
  loader: () => import('./FlightStatusPage'),
  loading: ChunkLoader,
  delay: 300
})

export const ExchangesPage = Loadable({
  loader: () => import('./ExchangesPage'),
  loading: ChunkLoader,
  delay: 300
})

export const FrequentlyAskedQuestionsResultsPage = Loadable({
  loader: () => import('./FrequentlyAskedQuestionsResultsPage'),
  loading: ChunkLoader,
  delay: 300
})

export const LandingPage = Loadable({
  loader: () => import('./LandingPage'),
  loading: ChunkLoader,
  delay: 300
})

export const ExchangesPaymentErrorPage = Loadable({
  loader: () => import('./ExchangesPaymentErrorPage'),
  loading: ChunkLoader,
  delay: 300
})

export const CheckoutErrorPage = Loadable({
  loader: () => import('./CheckoutErrorPage'),
  loading: ChunkLoader,
  delay: 300
})

export const ScheduledFlights = Loadable({
  loader: () => import('./ScheduledFlights'),
  loading: ChunkLoader,
  delay: 300
})

export const SpecialRequestsPage = Loadable({
  loader: () => import('./SpecialRequestsPage'),
  loading: ChunkLoader,
  delay: 300
})

export const CommonErrorPage = Loadable({
  loader: () => import('./CommonErrorPage'),
  loading: ChunkLoader,
  delay: 300
})

export const FlightsOffersErrorPage = Loadable({
  loader: () => import('./FlightsOffersErrorPage'),
  loading: ChunkLoader,
  delay: 300
})

export const PressCommunicationPage = Loadable({
  loader: () => import('./PressCommunicationPage'),
  loading: ChunkLoader,
  delay: 300
})

export const MyReservationErrorPage = Loadable({
  loader: () => import('./MyReservationErrorPage'),
  loading: ChunkLoader,
  delay: 300
})

export const CorporateAgreementsPage = Loadable({
  loader: () => import('./CorporateAgreementsPage'),
  loading: ChunkLoader,
  delay: 300
})

export const EventsPage = Loadable({
  loader: () => import('./EventsPage'),
  loading: ChunkLoader,
  delay: 300
})

export const CorporateAgreementsRegisterUserPage = Loadable({
  loader: () => import('./CorporateAgreementsRegisterUserPage'),
  loading: ChunkLoader,
  delay: 300
})

export const BookingPaymentPage = Loadable({
  loader: () => import('./BookingPaymentPage'),
  loading: ChunkLoader,
  delay: 300
})

export const PressReleasesPage = Loadable({
  loader: () => import('./PressReleasesPage'),
  loading: ChunkLoader,
  delay: 300
})

export const CheckoutGlobalRetry = Loadable({
  loader: () => import('./Checkout3PGlobalPaymentRetry'),
  loading: ChunkLoader,
  delay: 300
})

export const RefundFormPage = Loadable({
  loader: () => import('./RefundFormPage'),
  loading: ChunkLoader,
  delay: 300
})

export const RefundStatusPage = Loadable({
  loader: () => import('./RefundStatusPage'),
  loading: ChunkLoader,
  delay: 300
})

export const RefundRequestFormPage = Loadable({
  loader: () => import('./RefundRequestFormPage'),
  loading: ChunkLoader,
  delay: 300
})

export const PassengersMissingInfo = Loadable({
  loader: () => import('./PassengersMissingInfo'),
  loading: ChunkLoader,
  delay: 300
})

export const ReservationServicePage = Loadable({
  loader: () => import('./ReservationServicePage'),
  loading: ChunkLoader,
  delay: 300
})

export const ExternalPayment = Loadable({
  loader: () => import('./ExternalPayment'),
  loading: ChunkLoader,
  delay: 300
})

export const AppLinkRedirect = Loadable({
  loader: () => import('./AppLinkRedirect'),
  loading: ChunkLoader,
  delay: 300
})
