import React from 'react'

import styled, { css } from 'styled-components'

import mediaQueries from 'components/media-queries'
import { common as e2eClasses } from 'constants/e2eClasses'
import { toTesteableId } from 'helpers/utils'

const ButtonWrapper = styled.div`
  position: relative;
`

const getDisabledBackgroundColor = (theme, secondary, gray) => {
  if (secondary) {
    return theme.colors.white
  }

  if (gray) {
    return theme.colors.silverChalice
  }

  return theme.colors.accessibleGrey
}

const StyledButton = styled.button.attrs(props => ({
  className: `${e2eClasses.button}${toTesteableId(props.id || props.e2eClass || props.name || '')}`
}))`
    height: 100%;
    display: flex;
    outline: none;
    align-items: center;
    justify-content: center;
    color: ${({ secondary, white, theme }) =>
      white ? theme.colors.blue : secondary ? theme.colors.blue : theme.colors.white};
    border-radius: 3px;
    width: 100%;
    min-width: max-content;
    border: none;
    padding: ${({ secondary, padding }) => padding || (secondary ? '5px 0' : '15px 0')};
    text-align: ${({ textAlign }) => textAlign || 'left'};
    font-family: ${({ secondary, theme }) =>
      secondary ? theme.fonts.regular : theme.fonts.semiBold};

    ${({ secondary, withBorder, theme }) =>
      (secondary || withBorder) && `border: 1px solid ${theme.colors.blue};`}

    ${({ disabled, secondary, white, theme, hoverColor, hoverTextColor, darkGrey, gray }) =>
      !disabled
        ? css`
            cursor: pointer;
            transition: background-color 0.3s ease;
            background-color: ${white
              ? theme.colors.white
              : secondary
              ? 'transparent'
              : gray
              ? theme.colors.gray
              : darkGrey
              ? theme.colors.darkGrey
              : theme.colors.blue};

            &:hover {
              background-color: ${hoverColor
                ? hoverColor
                : secondary
                ? theme.colors.grayLight
                : gray
                ? theme.colors.doveGrayer
                : theme.colors.blueDark};

              ${mediaQueries.tablet`
                background-color: ${theme.colors.blue};
              `};

              ${white && `color: ${theme.colors.white};`} ${secondary &&
                `
                border: 1px solid ${theme.colors.grayLight};
                color: ${theme.colors.white};
              `};

              ${hoverTextColor && `color: ${hoverTextColor};`};
            }
          `
        : `background-color: ${getDisabledBackgroundColor(theme, secondary, gray)};`}

    ${({ gray, theme }) =>
      gray &&
      `
        background-color: ${theme.colors.gray};

        &:hover {
          background-color: ${theme.colors.doveGrayer};
        }
      `}

    ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px` : `font-size: 14px`)};

    ${({ extraLarge }) =>
      extraLarge &&
      `
        padding: 20px 10px;
        border-radius: 4px;
      `}

    ${({ large }) =>
      large &&
      css`
        padding: 10px 0;

        ${mediaQueries.mobile`
          padding: 10px 5px;
          text-align: center;
        `};
      `}

    ${({ small }) =>
      small &&
      `
        padding: 7.5px 0;
      `}

    ${({ uppercase }) =>
      uppercase &&
      `
        text-transform: uppercase;
      `}
`

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15%;
  display: flex;
  align-items: center;
`

const Button = props => (
  <ButtonWrapper>
    {props.leftIcon && <IconWrapper>{props.leftIcon}</IconWrapper>}
    <StyledButton {...props} tabIndex={props.disableIndex ? -1 : 0}>
      {props.children}
    </StyledButton>
  </ButtonWrapper>
)

export default Button
