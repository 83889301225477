import { createActions } from 'redux-actions'
import { cmsApi } from 'services/api'
import cmsApiRoutes from 'constants/cmsApiRoutes'

const { fetchBanksStart, fetchBanksSuccess, fetchBanksError } = createActions({
  FETCH_BANKS_START: () => {},
  FETCH_BANKS_SUCCESS: data => ({ data }),
  FETCH_BANKS_ERROR: error => ({ error })
})

const fetchBanks = () => {
  return async dispatch => {
    dispatch(fetchBanksStart())

    try {
      const banks = await cmsApi.get(cmsApiRoutes.BANKS)
      dispatch(fetchBanksSuccess(banks))
    } catch (error) {
      dispatch(fetchBanksError(error))
    }
  }
}

export { fetchBanksStart, fetchBanksSuccess, fetchBanksError, fetchBanks }
