import { handleActions } from 'redux-actions'
import {
  fetchAirlinesStart,
  fetchAirlinesSuccess,
  fetchAirlinesError
} from 'actions/catalog/airlines'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchAirlinesStart]: state => ({
      ...state,
      data: {},
      error: null,
      isFetching: true
    }),
    [fetchAirlinesSuccess]: (state, { payload: { data } }) => {
      return {
        ...state,
        data,
        error: null,
        isFetching: false
      }
    },
    [fetchAirlinesError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    })
  },
  defaultState
)

export default reducer
