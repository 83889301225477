import { handleActions } from 'redux-actions'
import {
  originalFaresStart,
  originalFaresSuccess,
  originalFaresError,
  resetOriginalFares
} from 'actions/originalFares'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [originalFaresStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [originalFaresSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [originalFaresError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    }),
    [resetOriginalFares]: state => ({
      ...state,
      data: null,
      isFetching: false,
      error: null
    })
  },
  defaultState
)

export default reducer
