/* eslint-disable react/display-name */
import React from 'react'
import { StickyContainer } from './styled'

const Sticky = React.forwardRef(({ children, ...otherProps }, ref) => {
  return (
    <StickyContainer {...otherProps} ref={ref}>
      {children}
    </StickyContainer>
  )
})

export default Sticky
