import { getFormattedDate } from 'helpers/dates'
import { splitString } from 'helpers/string'

/**
 * Fullnames are expected to be separated with a forward slash "/"
 */
const parseFullNames = fullNames => splitString(fullNames, '/')

/**
 * Ticket numbers are expected to be separated with a forward slash "/"
 */
const parseTicketNumbers = ticketNumbers => splitString(ticketNumbers, '/')

export const mapTicketRefund = ({ fullNames, ticketNumbers, flightDate, ...rest }) => ({
  ...rest,
  fullNames: parseFullNames(fullNames),
  ticketNumbers: parseTicketNumbers(ticketNumbers),
  flightDate: getFormattedDate(flightDate)
})

export const mapTicketRefundValidation = ({ fullName, ticketNumber, reservationCode }) => ({
  fullName: fullName,
  ticketNumber: ticketNumber,
  reservationCode
})
