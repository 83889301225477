import { createActions } from 'redux-actions'

const { setError, clearSublosError } = createActions({
  SET_ERROR: data => ({ data }),
  CLEAR_SUBLOS_ERROR: () => {}
})

const setSublosError = error => async dispatch => dispatch(setError(error))

export { setSublosError, clearSublosError, setError }
