import { createActions } from 'redux-actions'
import apiRoutes from 'constants/apiRoutes'
import { createARPlusAPI } from 'services/api'

const {
  unsubscribeAccountDataStart,
  unsubscribeAccountDataSuccess,
  unsubscribeAccountDataError,
  clearUnsubscribeAccountDataState
} = createActions({
  UNSUBSCRIBE_ACCOUNT_DATA_START: () => {},
  UNSUBSCRIBE_ACCOUNT_DATA_SUCCESS: data => ({ data }),
  UNSUBSCRIBE_ACCOUNT_DATA_ERROR: error => ({ error }),
  CLEAR_UNSUBSCRIBE_ACCOUNT_DATA_STATE: () => {}
})

const unsubscribeAccountData = membershipNumber => async dispatch => {
  dispatch(unsubscribeAccountDataStart())
  try {
    const apiArPlus = createARPlusAPI()
    const response = await apiArPlus.post(
      `${apiRoutes.AR_PLUS_UNSUBSCRIBE_FIRST_CALL(membershipNumber)}`,
      membershipNumber
    )

    dispatch(unsubscribeAccountDataSuccess(response.data))
  } catch (error) {
    dispatch(unsubscribeAccountDataError(error.response ? error.response.data : error))
  }
}

export {
  unsubscribeAccountData,
  unsubscribeAccountDataStart,
  unsubscribeAccountDataSuccess,
  unsubscribeAccountDataError,
  clearUnsubscribeAccountDataState
}
