/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import {
  setPaymentStatus,
  clearPaymentStatus,
  setSecondPaymentStatus,
  clearSecondPaymentStatus
} from 'actions/paymentStatus'
import {
  resetPaymentData,
  resetPaymentBookingError,
  resetSecondPaymentBookingError,
  resetSecondPaymentData,
  clearPurchaseError
} from 'actions/reservation'
import { getMasksArray } from 'components/forms/helpers/mask'
import apiRoutes from 'constants/apiRoutes'
import { getDigitCount, getSecurityCodeDigitCount } from 'helpers/creditCard'
import api, { apiSublos } from 'services/api'

const {
  resetPaymentMethodState,
  resetSecondPaymentMethodState,
  changeFirstCardPaymentMethodState,
  changeSecondCardPaymentMethodState,
  fetchPaymentOptionsValidationsStart,
  fetchPaymentOptionsValidationsError,
  fetchPaymentOptionsValidationsSuccess,
  setPaymentMethod,
  setFirstCardPaymentMethodMasks,
  setSecondCardPaymentMethodMasks,
  clearPaymentMethodSelection
} = createActions({
  RESET_PAYMENT_METHOD_STATE: () => {},
  RESET_SECOND_PAYMENT_METHOD_STATE: () => {},
  CHANGE_FIRST_CARD_PAYMENT_METHOD_STATE: state => state,
  CHANGE_SECOND_CARD_PAYMENT_METHOD_STATE: state => state,
  FETCH_PAYMENT_OPTIONS_VALIDATIONS_START: () => {},
  FETCH_PAYMENT_OPTIONS_VALIDATIONS_ERROR: error => ({ error }),
  FETCH_PAYMENT_OPTIONS_VALIDATIONS_SUCCESS: response => ({ response }),
  SET_PAYMENT_METHOD: paymentMethod => ({ paymentMethod }),
  SET_FIRST_CARD_PAYMENT_METHOD_MASKS: masks => ({ masks }),
  SET_SECOND_CARD_PAYMENT_METHOD_MASKS: masks => ({ masks }),
  CLEAR_PAYMENT_METHOD_SELECTION: () => {}
})

const clearPaymentStates = () => {
  return dispatch => {
    dispatch(resetPaymentMethodState())
    dispatch(clearPurchaseError())
    dispatch(clearPaymentStatus())
    dispatch(resetPaymentBookingError())
    dispatch(resetPaymentData())
  }
}
const clearSecondPaymentStates = () => {
  return dispatch => {
    dispatch(resetSecondPaymentMethodState())
    dispatch(clearSecondPaymentStatus())
    dispatch(resetSecondPaymentBookingError())
    dispatch(resetSecondPaymentData())
  }
}

const selectPaymentMethod = (
  type,
  method,
  issuer = null,
  installment = null,
  rate = null,
  totalInterest = null,
  isForSecondCard
) => {
  return (dispatch, getState) => {
    const creditCardMasks = getCreditCardMasks(getState, method)
    const payload = {
      type,
      method,
      issuer,
      installment,
      rate,
      totalInterest,
      masks: creditCardMasks
    }
    if (isForSecondCard) {
      dispatch(changeSecondCardPaymentMethodState(payload))
      dispatch(setSecondPaymentStatus(null))
    } else dispatch(changeFirstCardPaymentMethodState(payload))

    dispatch(setPaymentStatus(null))
    dispatch(resetPaymentData())
  }
}

export const setCardOfSelectedMethod = cardMethod => {
  return (dispatch, getState, isForSecondCard) => {
    const creditCardMasks = getCreditCardMasks(getState, cardMethod)
    if (isForSecondCard) dispatch(setSecondCardPaymentMethodMasks(creditCardMasks))
    else dispatch(setFirstCardPaymentMethodMasks(creditCardMasks))
    dispatch(setPaymentMethod(cardMethod))
  }
}

const getCreditCardMasks = (getState, cardMethod) => {
  const digitInterval = 4
  const intervalSeparator = ' '
  const { paymentValidations } = getState().paymentMethodSelection
  const digitCount = getDigitCount(paymentValidations, cardMethod)
  const securityCodeDigitCount = getSecurityCodeDigitCount(paymentValidations, cardMethod)

  const creditCardMasks = getMasksArray('9', digitCount, digitInterval, intervalSeparator)
  const securityCodeMasks = getMasksArray('9', securityCodeDigitCount)
  return { creditCard: creditCardMasks, securityCode: securityCodeMasks }
}

const fetchPaymentOptionsValidations = () => {
  return async dispatch => {
    dispatch(fetchPaymentOptionsValidationsStart())

    try {
      const data = await api.get(apiRoutes.PAYMENT_METHODS)
      dispatch(fetchPaymentOptionsValidationsSuccess(data.data.resources))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(fetchPaymentOptionsValidationsError(err))
    }
  }
}

const fetchSublosPaymentOptionsValidations = () => {
  return async dispatch => {
    dispatch(fetchPaymentOptionsValidationsStart())

    try {
      const data = await apiSublos.get(apiRoutes.PAYMENT_METHODS)
      dispatch(fetchPaymentOptionsValidationsSuccess(data.data.resources))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(fetchPaymentOptionsValidationsError(err))
    }
  }
}

export {
  changeFirstCardPaymentMethodState,
  changeSecondCardPaymentMethodState,
  selectPaymentMethod,
  clearPaymentStates,
  clearSecondPaymentStates,
  resetPaymentMethodState,
  resetSecondPaymentMethodState,
  fetchPaymentOptionsValidationsStart,
  fetchPaymentOptionsValidationsError,
  fetchPaymentOptionsValidationsSuccess,
  fetchPaymentOptionsValidations,
  setPaymentMethod,
  setFirstCardPaymentMethodMasks,
  setSecondCardPaymentMethodMasks,
  clearPaymentMethodSelection,
  fetchSublosPaymentOptionsValidations
}
