import { createActions } from 'redux-actions'
import axios from 'axios'
import apiRoutes from 'constants/apiRoutes'
import CONFIG from 'config'
import { getLSValue } from 'helpers/localStorage'
import { LogOut } from 'helpers/flightOffers'

const {
  offlinePartnerConfirmationStart,
  offlinePartnerConfirmationSuccess,
  offlinePartnerConfirmationError,
  clearOfflinePartnerConfirmationState
} = createActions({
  OFFLINE_PARTNER_CONFIRMATION_START: () => {},
  OFFLINE_PARTNER_CONFIRMATION_SUCCESS: data => ({ data }),
  OFFLINE_PARTNER_CONFIRMATION_ERROR: error => ({ error }),
  CLEAR_OFFLINE_PARTNER_CONFIRMATION_STATE: () => {}
})

const postOfflinePartnerConfirmation = payload => async dispatch => {
  dispatch(offlinePartnerConfirmationStart())

  try {
    const response = await axios.post(
      `${apiRoutes.AR_PLUS_OFFLINE_PARTNER_CONFIRMATION}`,
      payload,
      {
        baseURL: CONFIG.API_BASE_URL,
        headers: {
          Authorization: `Bearer ${getLSValue('access_token')}`,
          'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID,
          'Accept-Language': CONFIG.DEFAULT_LOCALE,
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    dispatch(offlinePartnerConfirmationSuccess(response.data))
  } catch (error) {
    const err = error.response ? error.response.data : error

    if (err?.statusCode === 401) {
      LogOut()
      dispatch(clearOfflinePartnerConfirmationState())
    }
    dispatch(offlinePartnerConfirmationError(err))
  }
}

export {
  postOfflinePartnerConfirmation,
  offlinePartnerConfirmationStart,
  offlinePartnerConfirmationSuccess,
  offlinePartnerConfirmationError,
  clearOfflinePartnerConfirmationState
}
