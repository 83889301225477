import React from 'react'

import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { fetchFooter } from 'actions/cms/footer'
import Icon, { IconNames } from 'components/icons'
import { PageBlock } from 'components/layout'
import { TRIP_TYPES } from 'constants/tripTypes'
import { isSameDate, getDayJsObject } from 'helpers/dates'
import {
  isRouteWithFooterCollapsible,
  isFlexCalendarRoute,
  isFlightOffersRoute
} from 'helpers/routeMatchers'
import { isEmpty, isMobileOrTablet } from 'helpers/utils'
import { getFooter } from 'selectors/cms/footer'
import { mapFlightsOffers, getFlightsCalendarsOffers } from 'selectors/flightsOffers'
import { getFlightsOffersDatesFromState } from 'selectors/flightsSearchData'
import { getFlightSummaryData, isFetchingState } from 'selectors/flightSummary'
import { mapMediaType } from 'selectors/mediaType'
import { isMobileFullViewsVisible } from 'selectors/mobileFullViews'
import { getState } from 'selectors/modal'
import theme from 'styles/theme'

import FooterContactInfo from './footer-contact-info'
import FooterInstitutional from './footer-institutional'
import LegalFooter from './footer-legal'
import FooterNewsletter from './footer-newsletter'
import FooterSocial from './footer-social'
import {
  StyledPageBlock,
  WithoutPaddingStyledPageBlock,
  Row,
  Container,
  ExpandButton,
  WhiteBox,
  FooterSpace,
  MoreInfoText,
  FooterDetailContainer,
  BrandsImage,
  BrandsImageContainer,
  CallCenterText,
  CallCenterContainer,
  Link
} from './styled'

class Footer extends React.Component {
  state = {
    footerExpanded: false
  }

  componentDidMount = () => {
    this.props.fetchFooter()
  }

  componentDidUpdate = () =>
    !isEmpty(this.props.calendarOffers) && this.checkAvailabilitySelectedDays()

  onOpenCloseFooter = () => {
    this.setState(prevState => ({
      footerExpanded: !prevState.footerExpanded
    }))

    if (typeof window !== 'undefined') {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight)
      }, 200)
    }
  }

  checkAvailabilitySelectedDays = () => {
    const [outboundDate, inboundDate] = this.props.selectedDates

    const mappedFlightOffers = this.props.mappedFlightOffers
    const outboundOffers = mappedFlightOffers[TRIP_TYPES.ONE_WAY] || []
    const inboundOffers = mappedFlightOffers[TRIP_TYPES.RETURN] || []

    return {
      ONE_WAY: outboundOffers.some(day => isSameDate(day.date, outboundDate) && !day.emptyOffer),
      RETURN: inboundOffers.some(day => isSameDate(day.date, inboundDate) && !day.emptyOffer)
    }
  }

  checkFlightFooterOpen = () =>
    !isEmpty(this.props.calendarOffers) &&
    this.checkAvailabilitySelectedDays().ONE_WAY &&
    this.checkAvailabilitySelectedDays().RETURN

  checkFlightSummaryFooterOpen = () =>
    this.props.flightSummary && isFlightOffersRoute(this.props.location)

  isFlexDateCalendarScreen = () => isFlexCalendarRoute(this.props.location)

  getLogos = isShoppingFunnel => {
    const { footer } = this.props
    let filteredLogos = footer && footer.institutional

    if (filteredLogos) {
      if (isMobileOrTablet(this.props.mediaType)) {
        filteredLogos = filteredLogos.filter(({ mobile }) => mobile)
      }

      if (isShoppingFunnel) {
        filteredLogos = filteredLogos.filter(({ funnel }) => funnel)
      }
    }

    return filteredLogos
  }

  render = () => {
    const { mobileFullViews, footer, isLandingPage } = this.props
    if (mobileFullViews || this.checkFlightSummaryFooterOpen()) {
      return null
    }

    const showFooterCollapsible = isRouteWithFooterCollapsible(this.props.location)

    return (
      <StyledPageBlock
        modalOpen={!isLandingPage && this.props.modalState.isVisible}
        isFlexDateCalendarScreen={this.isFlexDateCalendarScreen()}
        as="footer"
      >
        {this.checkFlightFooterOpen() || this.checkFlightSummaryFooterOpen() ? (
          <FooterSpace />
        ) : showFooterCollapsible /* new footer */ ? (
          <PageBlock>
            <Container>
              <ExpandButton
                footerExpanded={this.state.footerExpanded}
                aria-expanded={this.state.footerExpanded}
                aria-controls={this.state.footerExpanded ? 'footer-detail' : undefined}
                onClick={this.onOpenCloseFooter}
              >
                <MoreInfoText>{this.props.t('label.more-information')}</MoreInfoText>
                <Icon
                  name={this.state.footerExpanded ? IconNames.ChevronDown : IconNames.ChevronUp}
                  color={theme.colors.doveGray}
                />
              </ExpandButton>
              <WhiteBox footerExpanded={this.state.footerExpanded} />
            </Container>
            {this.state.footerExpanded && (
              <FooterDetailContainer id="footer-detail" footerExpanded={this.state.footerExpanded}>
                <CallCenterContainer>
                  <CallCenterText>{this.props.t('general.footer.call-center')}</CallCenterText>
                </CallCenterContainer>
                <BrandsImageContainer>
                  {(this.getLogos(true) || []).map((item, index) => (
                    <Link key={index} href={item.url}>
                      <BrandsImage src={item.logo} alt={item.alt} />
                    </Link>
                  ))}
                </BrandsImageContainer>
                <FooterSocial
                  showFooterCollapsible={showFooterCollapsible}
                  socialItems={footer && footer.social}
                  label={
                    showFooterCollapsible
                      ? this.props.t('general.footer.find-us')
                      : this.props.t('general.footer.find-us').toUpperCase()
                  }
                />
              </FooterDetailContainer>
            )}
            <LegalFooter
              showFooterCollapsible={showFooterCollapsible}
              fiscal={footer && footer.afip}
              termsAndConditionsText={this.props.t('general.footer.legal-and-conditions')}
              termsAndConditionsUrl={footer && footer.tac}
              copyrightInformation={{
                description: this.props.t('general.footer.official-site'),
                activityPeriod: { from: 1996, to: getDayJsObject().year() }
              }}
            />
          </PageBlock>
        ) : (
          /* old footer */
          <PageBlock>
            <WithoutPaddingStyledPageBlock>
              <Row>
                <FooterInstitutional
                  links={footer && footer.submenu}
                  label={this.props.t('general.footer.institutional')}
                />
                <FooterContactInfo />
                <FooterNewsletter />
              </Row>
            </WithoutPaddingStyledPageBlock>
            <PageBlock fullWidth backgroundColor={theme.colors.grayLight}>
              <FooterSocial
                showFooterCollapsible={showFooterCollapsible}
                socialItems={footer && footer.social}
                label={
                  showFooterCollapsible
                    ? this.props.t('general.footer.find-us')
                    : this.props.t('general.footer.find-us').toUpperCase()
                }
              />
            </PageBlock>
            <PageBlock>
              <LegalFooter
                showFooterCollapsible={showFooterCollapsible}
                fiscal={footer && footer.afip}
                brandsImage={this.getLogos()}
                termsAndConditionsText={this.props.t('general.footer.legal-and-conditions')}
                termsAndConditionsUrl={footer && footer.tac}
                copyrightInformation={{
                  description: this.props.t('general.footer.official-site'),
                  activityPeriod: { from: 1996, to: getDayJsObject().year() }
                }}
              />
            </PageBlock>
          </PageBlock>
        )}
      </StyledPageBlock>
    )
  }
}

const mapDispatchToProps = {
  fetchFooter
}

const mapStateToProps = state => ({
  mobileFullViews: isMobileFullViewsVisible(state),
  modalState: getState(state),
  mediaType: mapMediaType(state),
  flightSummary: getFlightSummaryData(state) || isFetchingState(state),
  selectedDates: getFlightsOffersDatesFromState(state),
  calendarOffers: getFlightsCalendarsOffers(state),
  footer: getFooter(state),
  mappedFlightOffers: mapFlightsOffers(state)
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Footer)))
