import { createActions } from 'redux-actions'
import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  generateSeatsSelection,
  setActiveLeg,
  setActivePassenger,
  changePassenger,
  seatSelected,
  selectSeatsStart,
  selectSeatsSuccess,
  selectSeatsError,
  clearSelectionResult
} = createActions({
  GENERATE_SEATS_SELECTION: legs => ({ legs }),
  SET_ACTIVE_LEG: activeLeg => ({ activeLeg }),
  SET_ACTIVE_PASSENGER: activePassenger => ({ activePassenger }),
  CHANGE_PASSENGER: (activePassenger, activeSegment) => ({ activePassenger, activeSegment }),
  SEAT_SELECTED: (seat, passengerCount) => ({ seat, passengerCount }),
  SELECT_SEATS_START: () => {},
  SELECT_SEATS_SUCCESS: result => ({ result }),
  SELECT_SEATS_ERROR: error => ({ error }),
  CLEAR_SELECTION_RESULT: () => {}
})

const selectSeats = selection => {
  return async dispatch => {
    dispatch(selectSeatsStart())

    try {
      const response = await api.post(apiRoutes.SEATS, selection)

      dispatch(selectSeatsSuccess(response.data))
    } catch (error) {
      dispatch(selectSeatsError(error))
    }
  }
}

export {
  generateSeatsSelection,
  setActiveLeg,
  setActivePassenger,
  changePassenger,
  seatSelected,
  selectSeats,
  selectSeatsStart,
  selectSeatsSuccess,
  selectSeatsError,
  clearSelectionResult
}
