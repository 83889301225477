import { handleActions } from 'redux-actions'
import {
  fetchPassengerDataStart,
  fetchPassengerDataSuccess,
  fetchPassengerDataError,
  clearPassengerDataError
} from 'actions/rules/checkout/passengerData'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchPassengerDataStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchPassengerDataSuccess]: (state, { payload: { data } }) => {
      return {
        ...state,
        data,
        error: null,
        isFetching: false
      }
    },
    [fetchPassengerDataError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearPassengerDataError]: state => ({
      ...state,
      error: null
    })
  },
  defaultState
)

export default reducer
