import { uniqBy } from 'lodash'

import { getPaymentCategoriesByLanguage } from 'constants/paymentCategories'
import PAYMENT_METHODS, { FIELD_OPTIONS } from 'constants/paymentMethods'

// eslint-disable-next-line import/no-cycle
import { isEmpty } from './utils'

export const getPaymentMethodsDistinctByIssuer = paymentMethods => {
  const withIssuer = (paymentMethods || []).filter(({ issuer }) => !isEmpty(issuer))

  return uniqBy(withIssuer, 'issuer')
}

export const getDistinctByPaymentMethod = paymentMethods => {
  const filteredMethods = (paymentMethods || []).filter(({ issuer }) => isEmpty(issuer))

  return uniqBy(filteredMethods, 'paymentMethod')
}

export const isOfflinePayment = paymentCode =>
  paymentCode === 'OP-102' || paymentCode === 'OP-104' || paymentCode === 'OP-106'

export const getInstallmentObject = (installment, paymentType, price) => {
  if (installment) {
    return {
      installments: installment.numberOfInstallments,
      installmentAmount: installment.installmentAmount,
      totalInterest: installment.interestAmount,
      interestRate: installment.interestRate
    }
  } else if (paymentType !== PAYMENT_METHODS.CASH && paymentType !== PAYMENT_METHODS.DEBIT_CARD) {
    // if the payment type of the reservation is credit card and only 1 installment,
    // the API won't return the installment object, so we're adding it
    return {
      installments: 1,
      installmentAmount: price
    }
  }

  return null
}

export const isCardPayment = paymentMethod =>
  paymentMethod === PAYMENT_METHODS.INTEREST_FREE_INSTALLMENTS ||
  paymentMethod === PAYMENT_METHODS.FIXED_INSTALLMENTS ||
  paymentMethod === PAYMENT_METHODS.DEBIT_CARD

export const isFixedInstallments = paymentMethod =>
  paymentMethod === PAYMENT_METHODS.FIXED_INSTALLMENTS

export const taxPayerCategoryOptions = (translations, language) =>
  Object.values(getPaymentCategoriesByLanguage(language)).map(item => ({
    value: item,
    label: translations(`label.${item.toLowerCase()}`)
  }))

//If debit card has paymentMethod in noInstallments, it should use that instead
export const getDebitInstallmentOptions = (
  paymentOptions,
  selectedPaymentMethod,
  installmentOptions
) => {
  const noInstallmentsOptions = paymentOptions[FIELD_OPTIONS.NO_INSTALLMENTS]['1']
  const foundInNoInstallments = noInstallmentsOptions?.find(
    card => card.paymentMethod === selectedPaymentMethod
  )

  return foundInNoInstallments ? noInstallmentsOptions : installmentOptions
}
