import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'

const {
  firstAccessSetPasswordStart,
  firstAccessSetPasswordSuccess,
  firstAccessSetPasswordError,
  clearFirstAccessSetPasswordState
} = createActions({
  FIRST_ACCESS_SET_PASSWORD_START: () => {},
  FIRST_ACCESS_SET_PASSWORD_SUCCESS: data => ({ data }),
  FIRST_ACCESS_SET_PASSWORD_ERROR: error => ({ error }),
  CLEAR_FIRST_ACCESS_SET_PASSWORD_STATE: () => {}
})

const firstAccessSetPassword = values => async dispatch => {
  dispatch(firstAccessSetPasswordStart())
  try {
    const response = await api.post(apiRoutes.FIRST_ACCESS_SET_PASSWORD_AR_PLUS, {
      ...values
    })
    dispatch(firstAccessSetPasswordSuccess(response.data))
  } catch (error) {
    dispatch(firstAccessSetPasswordError(error.response ? error.response.data : error))
  }
}

export {
  firstAccessSetPassword,
  firstAccessSetPasswordStart,
  firstAccessSetPasswordSuccess,
  firstAccessSetPasswordError,
  clearFirstAccessSetPasswordState
}
