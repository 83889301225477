import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api, { apiSublos } from 'services/api'

const {
  fetchSpecialRequestsStart,
  fetchSpecialRequestsSuccess,
  fetchSpecialRequestsError,
  clearSpecialRequestsError,
  saveSpecialRequestsStart,
  saveSpecialRequestsSuccess,
  saveSpecialRequestsError,
  clearSpecialRequestsSaveError
} = createActions({
  FETCH_SPECIAL_REQUESTS_START: () => {},
  FETCH_SPECIAL_REQUESTS_SUCCESS: specialRequests => specialRequests,
  FETCH_SPECIAL_REQUESTS_ERROR: error => error,
  CLEAR_SPECIAL_REQUESTS_ERROR: () => {},
  SAVE_SPECIAL_REQUESTS_START: () => {},
  SAVE_SPECIAL_REQUESTS_SUCCESS: () => {},
  SAVE_SPECIAL_REQUESTS_ERROR: error => error,
  CLEAR_SPECIAL_REQUESTS_SAVE_ERROR: () => {}
})

const fetchSpecialRequests = params => {
  return async dispatch => {
    dispatch(fetchSpecialRequestsStart())

    try {
      const specialRequests = await api.get(`${apiRoutes.SPECIAL_REQUESTS_RULES}${params}`)
      dispatch(fetchSpecialRequestsSuccess(specialRequests))
    } catch (error) {
      dispatch(fetchSpecialRequestsError(error))
    }
  }
}

const fetchSublosSpecialRequests = params => {
  return async dispatch => {
    dispatch(fetchSpecialRequestsStart())

    try {
      const specialRequests = await apiSublos.get(`${apiRoutes.SPECIAL_REQUESTS_RULES}${params}`)
      dispatch(fetchSpecialRequestsSuccess(specialRequests))
    } catch (error) {
      dispatch(fetchSpecialRequestsError(error))
    }
  }
}

const saveSpecialRequests = (payload, reservationCode) => {
  return async dispatch => {
    dispatch(saveSpecialRequestsStart())

    try {
      await api.put(apiRoutes.SPECIAL_SERVICES(reservationCode), payload)

      dispatch(saveSpecialRequestsSuccess())
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(saveSpecialRequestsError(err))
    }
  }
}

export {
  fetchSpecialRequestsStart,
  fetchSpecialRequestsSuccess,
  fetchSpecialRequestsError,
  clearSpecialRequestsError,
  saveSpecialRequestsStart,
  saveSpecialRequestsSuccess,
  saveSpecialRequestsError,
  clearSpecialRequestsSaveError,
  fetchSpecialRequests,
  saveSpecialRequests,
  fetchSublosSpecialRequests
}
