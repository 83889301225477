import React from 'react'

import Lottie from 'react-lottie'
import { connect } from 'react-redux'

import AerolineasIcon from 'assets/icon-white-aerolineas.svg'
import { isDesktopOrWide } from 'helpers/utils'
import { mapMediaType } from 'selectors/mediaType'

import { AnimationContainer, LogoContainer, Img } from './styled'

const AnimationLogoLoader = ({ animationData, mediaType, fixedHeight }) => {
  const getHeight = () => {
    if (fixedHeight) {
      return fixedHeight
    }

    return isDesktopOrWide(mediaType) ? 500 : 600
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData
  }

  return (
    <AnimationContainer>
      <LogoContainer>
        <Img src={AerolineasIcon} />
      </LogoContainer>
      <Lottie options={defaultOptions} height={getHeight()} isStopped={false} />
    </AnimationContainer>
  )
}

const mapStateToProps = state => ({
  mediaType: mapMediaType(state)
})

export default connect(mapStateToProps)(AnimationLogoLoader)
