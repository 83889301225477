import styled from 'styled-components'

import e2eClass from 'helpers/automation'

export const TransitionLogoLoaderWrapper = styled.div.attrs(() => ({
  className: e2eClass('common.logoLoader')
}))`
  background-color: ${({ theme }) => theme.shadow.white75};
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`
