import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { apiSublos, apiV2 } from 'services/api'

import CONFIG from '../config'

const {
  fetchFareRulesStart,
  fetchFareRulesSuccess,
  fetchFareRulesError,
  clearFareRules
} = createActions({
  FETCH_FARE_RULES_START: () => {},
  FETCH_FARE_RULES_SUCCESS: data => ({ data }),
  FETCH_FARE_RULES_ERROR: error => ({ error }),
  CLEAR_FARE_RULES: () => {}
})

const fetchFareRules = pnr => {
  return async dispatch => {
    dispatch(fetchFareRulesStart())

    try {
      const response = await apiV2.get(apiRoutes.FARE_RULES(pnr))
      dispatch(fetchFareRulesSuccess(response.data))
    } catch (error) {
      dispatch(fetchFareRulesError(error.response ? error.response.data : error))
    }
  }
}

const fetchSublosFareRules = pnr => {
  return async dispatch => {
    dispatch(fetchFareRulesStart())

    try {
      const response = await apiSublos.get(apiRoutes.FARE_RULES(pnr), {
        baseURL: CONFIG.API_BASE_URL.replace('v1', 'v2')
      })
      dispatch(fetchFareRulesSuccess(response.data))
    } catch (error) {
      dispatch(fetchFareRulesError(error.response ? error.response.data : error))
    }
  }
}

export {
  fetchFareRulesStart,
  fetchFareRulesSuccess,
  fetchFareRulesError,
  clearFareRules,
  fetchFareRules,
  fetchSublosFareRules
}
