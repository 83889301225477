import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { apiSublos as api } from 'services/api'

const {
  companiesSublosStart,
  companiesSublosSuccess,
  companiesSublosError,
  companiesSublosClear
} = createActions({
  COMPANIES_SUBLOS_START: () => {},
  COMPANIES_SUBLOS_SUCCESS: data => ({ data }),
  COMPANIES_SUBLOS_ERROR: error => ({ error }),
  COMPANIES_SUBLOS_CLEAR: () => {}
})

const fetchCompaniesSublos = () => async dispatch => {
  dispatch(companiesSublosStart())
  try {
    const response = await api.get(apiRoutes.SUBLOS_COMPANIES)
    dispatch(companiesSublosSuccess(response.data))
  } catch (error) {
    dispatch(companiesSublosError(error.response ? error.response.data : error))
  }
}

export {
  fetchCompaniesSublos,
  companiesSublosStart,
  companiesSublosSuccess,
  companiesSublosError,
  companiesSublosClear
}
