import { createActions } from 'redux-actions'

import cmsApiRoutes from 'constants/cmsApiRoutes'
import { logError } from 'helpers/log'
import { buildURLEncoded } from 'helpers/url'
import { cmsApi, ssrCmsApi } from 'services/api'

const { fetchLandingsStart, fetchLandingsSuccess, fetchLandingsError } = createActions({
  FETCH_LANDINGS_START: () => {},
  FETCH_LANDINGS_SUCCESS: data => ({ data }),
  FETCH_LANDINGS_ERROR: error => ({ error })
})

const fetchLandings = id => {
  return async dispatch => {
    dispatch(fetchLandingsStart())

    try {
      const url = buildURLEncoded(cmsApiRoutes.LANDINGS, id)

      const landings = await cmsApi.get(url)

      dispatch(fetchLandingsSuccess(landings))
    } catch (error) {
      dispatch(fetchLandingsError(error))
    }
  }
}

const fetchLandingsForServer = async (pageConfig, i18n) => {
  const queryParams = buildURLEncoded('', pageConfig)
  try {
    const response = await ssrCmsApi.get(`${cmsApiRoutes.LANDINGS}${queryParams}`, { i18n })
    return {
      data: response.data,
      error: null,
      isFetching: false
    }
  } catch (error) {
    error.fileName = 'landings.js'
    error.lineNumber = '34'
    logError(
      `Error when getting ${ssrCmsApi.defaults.baseURL + cmsApiRoutes.LANDINGS + queryParams}`,
      '',
      error
    )
    return {
      error,
      data: null,
      isFetching: false
    }
  }
}

export {
  fetchLandingsStart,
  fetchLandingsSuccess,
  fetchLandingsError,
  fetchLandings,
  fetchLandingsForServer
}
