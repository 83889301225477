export default {
  REFUND: 'REFUND',
  EXCHANGE: 'EXCHANGE',
  CHECK_IN: 'CHECK_IN',
  SEAT_SELECTION: 'SEAT_SELECTION',
  PASSENGER: 'PASSENGER',
  ADDITIONAL_BAGS: 'ADDITIONAL_BAGS',
  CARRY_ON_BAGS: 'CARRY_ON_BAGS',
  SPECIAL_BAGS: 'SPECIAL_BAGS',
  VIP_LOUNGE: 'VIP_LOUNGE'
}
