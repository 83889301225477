import styled from 'styled-components'

import { isMobile, isTablet } from 'helpers/utils'

export const ImageAnimationWrapper = styled.div`
  height: 350px;
`

export const BarAnimationWrapper = styled.div`
  position: relative;
  ${({ mediaType }) =>
    isMobile(mediaType)
      ? `
    bottom: 65px;
    height: 290px;
  `
      : isTablet(mediaType)
      ? `
     bottom: 160px;
     height: 195px;
    `
      : `
     bottom: 135px;
     height: 165px;
    `}
`

export const Text = styled.span`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.gray};
  font-size: 18px;
  text-align: center;
  line-height: 2;

  & a {
    font-family: ${({ theme }) => theme.fonts.semiBold};
    color: ${({ theme }) => theme.colors.blue};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  color: ${({ theme }) => theme.colors.blue};
  text-align: center;
  font-size: 25px;
`

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
