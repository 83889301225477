import React from 'react'

import { withTranslation } from 'react-i18next'

import { generateHomeRoute } from 'helpers/url/localization'

import { LogoContainer, StyledHybridLink } from './styled'

const HybridLinkWithImage = ({ src, alt, ...rest }) => (
  <StyledHybridLink {...rest} replace>
    <img src={src} alt={alt} />
  </StyledHybridLink>
)

const HeaderLogo = ({ header, session, noPadding, i18n }) => (
  <LogoContainer tabIndex={-1} session={session || undefined} noPadding={noPadding}>
    <HybridLinkWithImage
      pathTo={generateHomeRoute(i18n.language)}
      src={header && header.logo}
      alt={header && header.alt}
      flex={6}
    />
    {header.secondaryLogo && (
      <HybridLinkWithImage
        pathTo={header.secondaryUrl}
        src={header && header.secondaryLogo}
        alt={header && header.secondaryAlt}
        target="_blank"
        flex={1}
      />
    )}
  </LogoContainer>
)

export default withTranslation()(HeaderLogo)
