const PASSENGER_TYPES = {
  ADT: 'ADULT',
  CHD: 'CHILD',
  INF: 'INFANT',
  ADT_SHORT: 'ADT',
  CHD_SHORT: 'CHD',
  CNN_SHORT: 'CNN',
  INF_SHORT: 'INF',
  NEG: 'NEG'
}

const PASSENGER_FARE = {
  ADT: ['ADT', 'NEG'],
  CHD: ['CHD', 'CNN', 'CNE'],
  INF: ['INF', 'INE']
}

export const FARE_PASSENGER_TYPES = {
  'general.fares.adt': PASSENGER_FARE.ADT,
  'general.fares.chd': PASSENGER_FARE.CHD,
  'general.fares.inf': PASSENGER_FARE.INF
}

export const FARE_PASSENGER_TYPES_SUBLOS = {
  'sublos.main-page.step.confirmation.details.adult-fare': PASSENGER_FARE.ADT,
  'sublos.main-page.step.confirmation.details.child-fare': PASSENGER_FARE.CHD,
  'sublos.main-page.step.confirmation.details.inf-fare': PASSENGER_FARE.INF
}

const PASSENGER_TYPE_VALIDATIONS = {
  ADT: {
    MIN_AGE: 12,
    MAX_AGE: false
  },
  CHD: {
    MAX_AGE: 12,
    MIN_AGE: 2
  },
  CNN: {
    MAX_AGE: 12,
    MIN_AGE: 2
  },
  INF: {
    MIN_AGE: false,
    MAX_AGE: 2
  },
  undefined: {
    MIN_AGE: false,
    MAX_AGE: false
  }
}

export { PASSENGER_TYPES, PASSENGER_TYPE_VALIDATIONS }
