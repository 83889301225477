import { createActions } from 'redux-actions'

import ROUTES from 'constants/apiRoutes'
import PRODUCTS from 'constants/products'
import { getChannelFromTag, getSublosChannelFromTag } from 'localization/helpers'
import api from 'services/api'

const { fetchChannelStart, fetchChannelSuccess, fetchChannelError } = createActions({
  FETCH_CHANNEL_START: () => {},
  FETCH_CHANNEL_SUCCESS: data => data,
  FETCH_CHANNEL_ERROR: error => error
})

const fetchChannel = (lang, product) => {
  /* Temporary param i18n channel implementation is ready, lang param shouldn't be necessary and
  this could be extracted from api headers */
  const channelId =
    product === PRODUCTS.SUBLOS ? getSublosChannelFromTag(lang) : getChannelFromTag(lang)

  return async dispatch => {
    dispatch(fetchChannelStart())

    try {
      const response = await api.get(`${ROUTES.CHANNELS}/${channelId}`)
      dispatch(fetchChannelSuccess(response))
    } catch (error) {
      dispatch(fetchChannelError(error))
    }
  }
}

export { fetchChannelStart, fetchChannelSuccess, fetchChannelError, fetchChannel }
