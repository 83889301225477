import { handleActions } from 'redux-actions'
import {
  fetchSuggestionsStart,
  fetchSuggestionsSuccess,
  fetchSuggestionsError,
  clearSuggestions,
  swipeSuggestions,
  fetchIataCodeSuggestionsStart,
  fetchIataCodeSuggestionsSuccess,
  saveSelectedSuggestions,
  resetSelectedSuggestions,
  clearAllSuggestions
} from 'actions/suggestions'

const defaultState = {
  suggestionsFrom: null,
  suggestionsTo: null,
  error: null,
  isFetching: false,
  isFetchingIataCode: false
}

const setLegSuggestions = (state, response, target, field, leg) => {
  if (target !== field) {
    return state[field]
  }

  if (!state[field]) {
    return {
      [leg]: response
    }
  }

  return {
    ...state[field],
    [leg]: response
  }
}

const suggestions = handleActions(
  {
    [fetchSuggestionsStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchSuggestionsSuccess]: (
      state,
      {
        payload: {
          data: { response, target, leg }
        }
      }
    ) => ({
      ...state,
      suggestionsFrom: setLegSuggestions(state, response, target, 'suggestionsFrom', leg),
      suggestionsTo: setLegSuggestions(state, response, target, 'suggestionsTo', leg),
      isFetching: false,
      error: null
    }),
    [fetchSuggestionsError]: (state, { payload: { error } }) => ({
      ...state,
      suggestionsFrom: [],
      suggestionsTo: [],
      isFetching: false,
      isFetchingIataCode: false,
      error
    }),
    [clearSuggestions]: (state, { payload: { target, leg } }) => ({
      ...state,
      [target]: {
        ...state[target],
        [leg]: []
      }
    }),
    [swipeSuggestions]: (state, { payload: { leg } }) => {
      const { suggestionsFrom, suggestionsTo } = state

      if (!suggestionsFrom || !suggestionsTo || !suggestionsFrom[leg] || !suggestionsTo[leg]) {
        return state
      }

      return {
        ...state,
        suggestionsFrom: {
          ...suggestionsFrom,
          [leg]: suggestionsTo[leg]
        },
        suggestionsTo: {
          ...suggestionsTo,
          [leg]: suggestionsFrom[leg]
        }
      }
    },
    [fetchIataCodeSuggestionsStart]: state => ({
      ...state,
      isFetchingIataCode: true,
      error: null
    }),
    [fetchIataCodeSuggestionsSuccess]: state => ({
      ...state,
      isFetchingIataCode: false,
      error: null
    }),
    [clearAllSuggestions]: () => defaultState
  },
  defaultState
)

const scheduledFlightDefaultData = null

const scheduledFlightsInputData = handleActions(
  {
    [saveSelectedSuggestions]: (state, { payload: { data } }) => ({ ...data }),
    [resetSelectedSuggestions]: () => scheduledFlightDefaultData
  },
  scheduledFlightDefaultData
)

export { suggestions, scheduledFlightsInputData }
