import { createActions } from 'redux-actions'
import { debounce } from 'lodash'
import api from 'services/api'
import API_ROUTES from 'constants/apiRoutes'

const {
  fetchSuggestionsStart,
  fetchSuggestionsSuccess,
  fetchSuggestionsError,
  clearSuggestions,
  swipeSuggestions,
  fetchIataCodeSuggestionsStart,
  fetchIataCodeSuggestionsSuccess,
  saveSelectedSuggestions,
  resetSelectedSuggestions,
  clearAllSuggestions
} = createActions({
  FETCH_SUGGESTIONS_START: () => {},
  FETCH_SUGGESTIONS_SUCCESS: data => ({ data }),
  FETCH_SUGGESTIONS_ERROR: error => ({ error }),
  CLEAR_SUGGESTIONS: (target, leg) => ({ target, leg }),
  SWIPE_SUGGESTIONS: leg => ({ leg }),
  FETCH_IATA_CODE_SUGGESTIONS_START: () => {},
  FETCH_IATA_CODE_SUGGESTIONS_SUCCESS: () => {},
  SAVE_SELECTED_SUGGESTIONS: data => ({ data }),
  RESET_SELECTED_SUGGESTIONS: () => {},
  CLEAR_ALL_SUGGESTIONS: () => {}
})

const debounceFetchSuggestions = debounce(async (dispatch, leg, target, route) => {
  try {
    const response = await api.get(route)
    dispatch(fetchSuggestionsSuccess({ response: response.data, target, leg }))
  } catch (error) {
    dispatch(fetchSuggestionsError({ error }))
  }
}, 300)

function fetchSuggestions(term, leg, target, countries, regions, cities) {
  return dispatch => {
    dispatch(fetchSuggestionsStart())

    const route = `${API_ROUTES.SUGGESTIONS}?term=${term}${
      countries && countries.length ? `&country=${countries.join('&country=')}` : ''
    }${regions && regions.length ? `&region=${regions.join('&region=')}` : ''}${
      cities && cities.length ? `&city=${cities.join('&city=')}` : ''
    }`

    debounceFetchSuggestions(dispatch, leg, target, route)
  }
}

function suggestionFetched(dispatch, data, field, target, leg) {
  const suggestion = data.find(({ id }) => id.toUpperCase() === field)
  dispatch(
    fetchSuggestionsSuccess({
      response: [suggestion],
      target,
      leg
    })
  )
}

function fetchSuggestionsByIataCodes(legs, iataCodes) {
  return async dispatch => {
    dispatch(fetchIataCodeSuggestionsStart())

    try {
      const { data } = await api.get(`${API_ROUTES.SUGGESTIONS_IATA_CODES}${iataCodes}`)

      legs.forEach(({ origin, destination }, legNumber) => {
        suggestionFetched(dispatch, data, origin, 'suggestionsFrom', legNumber)
        suggestionFetched(dispatch, data, destination, 'suggestionsTo', legNumber)
      })

      dispatch(fetchIataCodeSuggestionsSuccess())
    } catch (error) {
      dispatch(fetchSuggestionsError({ error }))
    }
  }
}

export {
  fetchSuggestions,
  fetchSuggestionsByIataCodes,
  fetchSuggestionsStart,
  fetchSuggestionsSuccess,
  fetchSuggestionsError,
  clearSuggestions,
  swipeSuggestions,
  fetchIataCodeSuggestionsStart,
  fetchIataCodeSuggestionsSuccess,
  saveSelectedSuggestions,
  resetSelectedSuggestions,
  clearAllSuggestions
}
