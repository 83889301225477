const getErrorDetails = error => ({
  message: error.message,
  fileName: error.fileName || '',
  lineNumber: error.lineNumber || '',
  stack: (error.stack && error.stack.toString()) || ''
})

export const logError = (msg, details, error) => {
  const info = {
    timestamp: new Date(),
    level: 'ERROR',
    msg,
    details,
    error: getErrorDetails(error)
  }

  try {
    console.error(JSON.stringify(info))
  } catch (err) {
    console.error(info)
  }
}

export const logInfo = (msg, details) => {
  const info = {
    timestamp: new Date(),
    level: 'INFO',
    msg,
    details
  }

  try {
    console.error(JSON.stringify(info))
  } catch (err) {
    console.error(info)
  }
}
