import React from 'react'

import styled from 'styled-components'

import animation from 'animations/transition.json'
import e2eClass from 'helpers/automation'

import AnimationLoader from '../animation-loader'

export const TransitionLoaderWrapper = styled.div.attrs(() => ({
  className: e2eClass('common.loader')
}))`
  background-color: ${({ theme }) => theme.shadow.white75};
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Text = styled.span`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.doveGray};
  font-size: 14px;
  width: 60%;

  & a {
    font-family: ${({ theme }) => theme.fonts.semiBold};
    color: ${({ theme }) => theme.colors.blue};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`
export default class FullScreenTransitionLoader extends React.Component {
  componentWillUnmount() {
    if (typeof document !== 'undefined') {
      document.body.style.overflowY = ''
    }
  }

  componentDidMount() {
    if (typeof document !== 'undefined') {
      document.body.style.overflowY = 'hidden'
    }
  }

  render() {
    return (
      <TransitionLoaderWrapper>
        <Column>
          <AnimationLoader animationData={animation} fixedHeight={150} />
          {this.props.withText && (
            <Text dangerouslySetInnerHTML={{ __html: this.props.withText }} />
          )}
        </Column>
      </TransitionLoaderWrapper>
    )
  }
}
