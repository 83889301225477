import { createActions } from 'redux-actions'

import { flightSummaryToPaymentOptionsPayload } from 'api-data-io/bo/evaluation'
import apiRoutes from 'constants/apiRoutes'
import PRODUCTS from 'constants/products'
import api from 'services/api'

const {
  fetchSecondCardPaymentOptionsStart,
  fetchSecondCardPaymentOptionsSuccess,
  fetchSecondCardPaymentOptionsError,
  clearSecondCardPaymentOptionsError,
  clearSecondCardPaymentOptionsState
} = createActions({
  FETCH_SECOND_CARD_PAYMENT_OPTIONS_START: () => {},
  FETCH_SECOND_CARD_PAYMENT_OPTIONS_SUCCESS: data => data,
  FETCH_SECOND_CARD_PAYMENT_OPTIONS_ERROR: error => ({ error }),
  CLEAR_SECOND_CARD_PAYMENT_OPTIONS_ERROR: () => {},
  CLEAR_SECOND_CARD_PAYMENT_OPTIONS_STATE: () => {}
})

//If unifying paymentOptions, this would be moved/deleted

const fetchSecondCardPaymentOptions = (flightSummary, product = PRODUCTS.SHOPPING_2CARDS) => {
  return async dispatch => {
    dispatch(fetchSecondCardPaymentOptionsStart())

    try {
      const payload = flightSummaryToPaymentOptionsPayload(flightSummary, product)
      const paymentOptions = await api.post(apiRoutes.PAYMENT_OPTIONS, payload)

      dispatch(fetchSecondCardPaymentOptionsSuccess(paymentOptions))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(fetchSecondCardPaymentOptionsError(err))
    }
  }
}

export {
  fetchSecondCardPaymentOptions,
  fetchSecondCardPaymentOptionsStart,
  fetchSecondCardPaymentOptionsSuccess,
  fetchSecondCardPaymentOptionsError,
  clearSecondCardPaymentOptionsError,
  clearSecondCardPaymentOptionsState
}
