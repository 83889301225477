import { createSelector } from 'reselect'

import { FOP_CODE } from '../constants/payment'

const getPaymentOptionsState = state => state.paymentOptions

export const getPaymentOptionsData = createSelector([getPaymentOptionsState], state =>
  state ? state.data : null
)

export const isFetchingPaymentOptions = createSelector([getPaymentOptionsState], state =>
  state ? state.isFetching : false
)

export const getPaymentOptionsError = createSelector([getPaymentOptionsState], state =>
  state ? state.error : null
)

export const getPaymentOptionsPaymentData = createSelector([getPaymentOptionsData], data =>
  data ? data.paymentData : null
)

export const getPaymentOptionsCurrency = createSelector([getPaymentOptionsPaymentData], data =>
  data ? data.currency : null
)

export const getPaymentOptionsAmount = createSelector([getPaymentOptionsPaymentData], data =>
  data ? data.amount : null
)

export const getInterestFreeBINS = createSelector([getPaymentOptionsData], paymentOptions => {
  const { interestFreeInstallments } = paymentOptions

  const BINs = Object.keys(interestFreeInstallments).reduce((memo, installment) => {
    const paymentMethods = interestFreeInstallments[installment]

    paymentMethods.forEach(paymentMethod => {
      if (paymentMethod.bins) {
        memo.push({ installment, bins: paymentMethod.bins })
      }
    })

    return memo
  }, [])

  //Replace Array.prototype.flat call to support edge browser
  return BINs.reduce((accumulator, currentValue) => accumulator.concat(currentValue), [])
})

export const getAlternativeFormOfPayments = createSelector(
  [getPaymentOptionsData],
  state => state?.alternativeFormOfPayments
)

export const showMercadoPagoMethod = createSelector([getAlternativeFormOfPayments], state =>
  state?.EXTERNAL?.some(value => value === FOP_CODE.MP)
)
