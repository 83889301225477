import { css } from 'styled-components'

export const CustomScrollMenuGlobalCSS = css`
  .component-slider {
    position: relative;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    align-self: center;
  }
  .component-slider .slider-container {
    margin: 0 20px 0 0;
    overflow: hidden;
  }
  .component-slider .slider-content {
    display: inline-block;
    transition: margin-left 0.3s ease-in;
  }

  .component-slider .caret {
    width: 70px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    color: #797979;
    padding-top: 5px;
  }
  .component-slider .caret-right {
    text-align: right;
    right: 0;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 35%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 35%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(
startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
  }
  .component-slider .caret-left {
    text-align: left;
    left: 0;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 35%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 35%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(
startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
  }
`
