/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  forgottenPasswordStart,
  forgottenPasswordSuccess,
  forgottenPasswordError,
  clearForgottenPasswordState
} = createActions({
  FORGOTTEN_PASSWORD_START: () => {},
  FORGOTTEN_PASSWORD_SUCCESS: data => ({ data }),
  FORGOTTEN_PASSWORD_ERROR: error => ({ error }),
  CLEAR_FORGOTTEN_PASSWORD_STATE: () => {}
})

const resetPassword = ({ membershipNumber }) => async dispatch => {
  dispatch(forgottenPasswordStart())
  try {
    const response = await api.post(`${apiRoutes.FORGOTTEN_PASSWORD_AR_PLUS(membershipNumber)}`, {
      membershipNumber
    })
    dispatch(forgottenPasswordSuccess(response.data))
  } catch (error) {
    dispatch(forgottenPasswordError(error.response ? error.response.data : error))
  }
}

const newPassword = ({ authCode, password }, memberShipCode) => async dispatch => {
  dispatch(forgottenPasswordStart())
  try {
    const response = await api.post(`${apiRoutes.AR_PLUS_LOGIN(memberShipCode)}`, {
      authorizationCode: authCode,
      membershipCode: memberShipCode,
      password
    })
    dispatch(forgottenPasswordSuccess(response.data))
  } catch (error) {
    dispatch(forgottenPasswordError(error.response ? error.response.data : error))
  }
}

export {
  resetPassword,
  newPassword,
  forgottenPasswordStart,
  forgottenPasswordSuccess,
  forgottenPasswordError,
  clearForgottenPasswordState
}
