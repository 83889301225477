import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api, { apiSublos } from 'services/api'

const {
  fetchDocumentTypesRulesStart,
  fetchDocumentTypesRulesSuccess,
  fetchDocumentTypesRulesError,
  clearDocumentTypesRulesError
} = createActions({
  FETCH_DOCUMENT_TYPES_RULES_START: () => {},
  FETCH_DOCUMENT_TYPES_RULES_SUCCESS: documentTypes => documentTypes,
  FETCH_DOCUMENT_TYPES_RULES_ERROR: error => error,
  CLEAR_DOCUMENT_TYPES_RULES_ERROR: () => {}
})

const fetchDocumentTypesRules = params => {
  return async dispatch => {
    dispatch(fetchDocumentTypesRulesStart())

    try {
      const documentTypes = await api.get(`${apiRoutes.DOCUMENT_TYPE_RULES}${params}`)
      dispatch(fetchDocumentTypesRulesSuccess(documentTypes))
    } catch (error) {
      dispatch(fetchDocumentTypesRulesError(error))
    }
  }
}

const fetchSublosDocumentTypesRules = params => {
  return async dispatch => {
    dispatch(fetchDocumentTypesRulesStart())

    try {
      const documentTypes = await apiSublos.get(`${apiRoutes.DOCUMENT_TYPE_RULES}${params}`)
      dispatch(fetchDocumentTypesRulesSuccess(documentTypes))
    } catch (error) {
      dispatch(fetchDocumentTypesRulesError(error))
    }
  }
}

export {
  fetchDocumentTypesRulesStart,
  fetchDocumentTypesRulesSuccess,
  fetchDocumentTypesRulesError,
  clearDocumentTypesRulesError,
  fetchDocumentTypesRules,
  fetchSublosDocumentTypesRules
}
