import modulesTheme from 'components/theme'

// Import common theme from ARSA's modules.
const theme = {
  ...modulesTheme,
  dimensions: {
    container: {
      maxWidth: '1200px'
    }
  }
}

export default theme
