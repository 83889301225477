import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import animationDesktop from 'animations/results.json'
import animationMobile from 'animations/results_mobile'

import { useTranslation } from 'react-i18next'
import { mapMediaType } from 'selectors/mediaType'
import { isMobile } from 'helpers/utils'

import NAMESPACES from 'localization/constants/namespaces'

import AnimationLoader from 'components/animation-loader'

const ResultsLoaderWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  z-index: 3000;
`

const ResultsLoader = ({ mediaType }) => {
  const { t } = useTranslation(NAMESPACES.FLIGHT_OFFERS)
  const animationData = isMobile(mediaType) ? animationMobile : animationDesktop

  return (
    <ResultsLoaderWrapper>
      <AnimationLoader
        animationData={animationData}
        text={t('shopping.flight-offers.loading-text')}
      />
    </ResultsLoaderWrapper>
  )
}

const mapStateToProps = state => ({
  mediaType: mapMediaType(state)
})

export default connect(mapStateToProps)(ResultsLoader)
