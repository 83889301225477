import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'

const {
  firstAccessStart,
  firstAccessSuccess,
  firstAccessError,
  clearFirstAccessState
} = createActions({
  FIRST_ACCESS_START: () => {},
  FIRST_ACCESS_SUCCESS: data => ({ data }),
  FIRST_ACCESS_ERROR: error => ({ error }),
  CLEAR_FIRST_ACCESS_STATE: () => {}
})

const firstAccess = values => async dispatch => {
  dispatch(firstAccessStart())
  try {
    const response = await api.post(apiRoutes.FIRST_ACCESS_AR_PLUS, {
      ...values
    })
    dispatch(firstAccessSuccess(response.data))
  } catch (error) {
    dispatch(firstAccessError(error.response ? error.response.data : error))
  }
}

export {
  firstAccess,
  firstAccessStart,
  firstAccessSuccess,
  firstAccessError,
  clearFirstAccessState
}
