/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  tuavtaRefundStart,
  tuavtaRefundSuccess,
  tuavtaRefundError,
  tuavtaRefundClear
} = createActions({
  TUAVTA_REFUND_START: () => {},
  TUAVTA_REFUND_SUCCESS: data => ({ data }),
  TUAVTA_REFUND_ERROR: error => ({ error }),
  TUAVTA_REFUND_CLEAR: () => {}
})

const postTuavtaRefund = formikFields => async dispatch => {
  dispatch(tuavtaRefundStart())
  try {
    const response = await api.post(apiRoutes.REQUEST_TUAVTA_REFUND, formikFields)
    dispatch(tuavtaRefundSuccess(response.data))
  } catch (error) {
    dispatch(tuavtaRefundError(error.response ? error.response.data : error))
  }
}

const clearTuavtaRefund = () => tuavtaRefundClear()

export {
  postTuavtaRefund,
  clearTuavtaRefund,
  tuavtaRefundStart,
  tuavtaRefundSuccess,
  tuavtaRefundError,
  tuavtaRefundClear
}
