export const getCabinClass = (searchedFlight, resources) => {
  if (searchedFlight && searchedFlight.cabinClass) {
    return searchedFlight.cabinClass
  }

  if (resources && resources[0]) {
    return resources[0].code
  }

  return ''
}
