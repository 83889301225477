import { createSelector } from 'reselect'
import { isEmpty } from 'helpers/utils'
import { isSSRErrorMessage } from 'helpers/reservation'

const getSpecialRequestsRulesState = state => state.specialRequestsRules

export const getSpecialRequestsRules = createSelector([getSpecialRequestsRulesState], state =>
  state ? state.data : null
)

export const isFetchingSpecialRequestsRules = createSelector(
  [getSpecialRequestsRulesState, getSpecialRequestsRules],
  (state, data) => state.isFetching || isEmpty(data)
)

export const isFetchingSpecialRequestsRulesWithEmptyData = createSelector(
  [getSpecialRequestsRulesState],
  state => state.isFetching || false
)

export const isSubmittingSpecialRequestsRules = createSelector(
  [getSpecialRequestsRulesState],
  state => state.isSubmitting
)

export const getSpecialRequestsRulesError = createSelector([getSpecialRequestsRulesState], state =>
  state ? state.error : null
)

export const getSpecialRequestsNamesByCode = createSelector([getSpecialRequestsRules], rules => {
  if (rules) {
    const rulesByCode = {}
    const [ssrTypes] = Object.values(rules)

    ssrTypes.forEach(ssrType => {
      ;(ssrType.availableSSRs || []).forEach(ssr => {
        rulesByCode[ssr.code] = ssr.name
      })
    })

    return rulesByCode
  }

  return {}
})

export const getSpecialRequestsRulesErrorDescription = createSelector(
  [getSpecialRequestsRulesError],
  error => (error ? error.description : null)
)

export const arePassengersSSRErrors = createSelector(
  [getSpecialRequestsRulesErrorDescription],
  errorDetails => (errorDetails ? isSSRErrorMessage(errorDetails) : false)
)
