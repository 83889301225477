import { buildURLEncoded } from 'helpers/url'

import { TRIP_TYPES as FLIGHT_TYPES } from 'constants/tripTypes'

import { generateFlexCalendarRoute, generateFlightOffersRoute } from './url/localization'
import { CABIN_NAMES } from 'constants/popularDestinations'

export const buildPopularDestinationUrl = (item, language) => {
  if (item.url) {
    return item.url
  }
  const baseRoute = item.flexDates
    ? generateFlexCalendarRoute(language)
    : generateFlightOffersRoute(language)
  const flightType = item.arrivalDate ? FLIGHT_TYPES.ROUND_TRIP : FLIGHT_TYPES.ONE_WAY
  const legs = buildLegsParams(
    item.origin && item.origin.toUpperCase(),
    item.destination && item.destination.toUpperCase(),
    item.departureDate,
    item.arrivalDate
  )
  const url = buildURLEncoded(baseRoute, {
    adt: item.adt || '1',
    chd: item.chd || '0',
    inf: item.inf || '0',
    cabinClass: item.cabin,
    flexDates: item.flexDates,
    flightType
  })
  return `${url}${legs}`
}

const buildLegsParams = (origin, destination, departureDate, arrivalDate) => {
  const legParams = `&leg=${origin}-${destination}-${departureDate}`
  if (arrivalDate) {
    return `${legParams}&leg=${destination}-${origin}-${arrivalDate}`
  }
  return legParams
}

export const getCabinCodeName = code => {
  if (code && code.split('-')[0]) {
    return CABIN_NAMES[code.split('-')[0]]
  }
  return code
}
