import styled from 'styled-components'
import loadingFlightsImage from 'assets/loader-resultados.gif'
import mediaQueries from 'components/media-queries'

const FlightsLoader = styled.img.attrs(() => ({
  src: loadingFlightsImage,
  alt: 'Loading...'
}))`
  ${mediaQueries.tablet`
     transform: scale(0.7, 0.7);
  `};
`

export default FlightsLoader
