/* eslint-disable import/no-cycle */
import { matchPath } from 'react-router-dom'

import ROUTES from 'constants/routes'
import SUBLOS_ROUTES, { SUBLOS_INITIAL_PATH } from 'constants/sublos/routes'
import { getLangsUrlByAppRoutes } from 'helpers/sublos/routes'
import { getUrlParams } from 'helpers/url/parseUrl'
import { isEmpty } from 'helpers/utils'

import { SHOW_ARSA_KEYS_IS_ACTIVATED } from '../components/header'
import { isExternalPaymentMercadoPago } from './url/externalPayment'

const PAGES_WITHOUT_HEADER = SHOW_ARSA_KEYS_IS_ACTIVATED
  ? []
  : [
      ROUTES.SEATS_SELECTION.LANG_URL,
      ROUTES.SPECIAL_REQUESTS.LANG_URL,
      ROUTES.MY_RESERVATION_ANCILLARIES.LANG_URL,
      ROUTES.CHECKOUT_ANCILLARIES.LANG_URL,
      ROUTES.EXCHANGES.LANG_URL,
      ...getLangsUrlByAppRoutes(SUBLOS_ROUTES)
    ]

const PAGES_WITHOUT_FOOTER = [
  ROUTES.SEATS_SELECTION.LANG_URL,
  ROUTES.EXCHANGES.LANG_URL,
  ROUTES.SPECIAL_REQUESTS.LANG_URL,
  ROUTES.COMMON_ERROR.LANG_URL,
  ROUTES.CHECKOUT_ERROR.LANG_URL,
  ROUTES.EXCHANGES_ERROR.LANG_URL,
  ROUTES.FLIGHTS_OFFERS_ERROR.LANG_URL,
  ROUTES.MY_RESERVATION_ANCILLARIES.LANG_URL,
  ROUTES.CHECKOUT.LANG_URL,
  ROUTES.CHECKOUT_ANCILLARIES.LANG_URL,
  ROUTES.PASSENGERS_MISSING_INFO.LANG_URL,
  ROUTES.EXTERNAL_PAYMENT.LANG_URL,
  ...getLangsUrlByAppRoutes(SUBLOS_ROUTES)
]

const PAGES_WITH_FULL_MENU = [
  ROUTES.BOOKING_PAYMENT_PAGE.LANG_URL,
  ROUTES.HOME.LANG_URL,
  ROUTES.PRESS_COMMUNICATION.LANG_URL,
  ROUTES.FREQUENTLY_ASKED_QUESTIONS.LANG_URL,
  ROUTES.FREQUENTLY_ASKED_QUESTIONS_RESULTS.LANG_URL,
  ROUTES.MY_RESERVATION.LANG_URL,
  ROUTES.COMMON_ERROR.LANG_URL,
  ROUTES.CHECKOUT_ERROR.LANG_URL,
  ROUTES.EXCHANGES_ERROR.LANG_URL,
  ROUTES.SCHEDULED_FLIGHTS.LANG_URL,
  ROUTES.FLIGHTS_OFFERS_ERROR.LANG_URL,
  ROUTES.MY_RESERVATION_ERROR.LANG_URL,
  ROUTES.CORPORATE_AGREEMENTS.LANG_URL,
  ROUTES.CORPORATE_AGREEMENTS_REGISTER_USER.LANG_URL,
  ROUTES.EVENTS.LANG_URL,
  ROUTES.PRESS_RELEASES_PAGE.LANG_URL,
  ROUTES.REFUND_FORM.LANG_URL,
  ROUTES.RESERVATION_SERVICE_EN.LANG_URL,
  ROUTES.RESERVATION_SERVICE_ES.LANG_URL,
  ROUTES.RESERVATION_SERVICE_IT.LANG_URL,
  ROUTES.RESERVATION_SERVICE_FR.LANG_URL,
  ROUTES.RESERVATION_SERVICE_PT.LANG_URL,
  ROUTES.REFUND_FORM_STATUS.LANG_URL,
  ROUTES.REFUND_REQUEST_FORM.LANG_URL
]

const HEADER_SUBLOS = [
  ROUTES.SUBLOS_HOME.LANG_URL,
  ROUTES.SUBLOS_ERROR.LANG_URL,
  ROUTES.SUBLOS_WEBSITE_IN_PROGRESS.LANG_URL
]

// const PAGES_WITH_MENU_AND_LENGUAGE = [ROUTES.FLIGHT_STATUS.LANG_URL, ROUTES.ARPLUS.LANG_URL]
const PAGES_WITH_MENU_AND_LENGUAGE = [ROUTES.FLIGHT_STATUS.LANG_URL]

const CAMPAIGN_DATA_ROUTES = [ROUTES.CHECKOUT.LANG_URL, ROUTES.CHECKOUT_ERROR.LANG_URL]

const BOOKING_ROUTES = [
  ROUTES.MY_RESERVATION.LANG_URL,
  ROUTES.SEATS_SELECTION.LANG_URL,
  ROUTES.BOOKING_PAYMENT_PAGE.LANG_URL
]

const DARK_SITE_ROUTES = [ROUTES.HOME.LANG_URL, ROUTES.PRESS_COMMUNICATION.LANG_URL]

const COLLAPSIBLE_FOOTER_ROUTES = [
  ROUTES.FLIGHTS_OFFERS.LANG_URL,
  ROUTES.CHECKOUT.LANG_URL,
  ROUTES.PASSENGERS_MISSING_INFO.LANG_URL
]

export const doesMatchAnyStaticRoute = pathTo =>
  Object.values(ROUTES)
    .filter(({ isDisabled }) => !isDisabled)
    .some(({ LANG_URL, exact }) => matchPath(pathTo, { exact, path: LANG_URL }))

/**
 * @param {Object} location - location object taken from react-router-dom props
 * @param {(string|Array)} path - path or array of paths to match.
 */
const matchAnyRoute = (location, path) => matchPath(location.pathname, { path, exact: true })

/** All LANG_URL routes includes a regex expression to support matches for lang param,
 * supporting ARG routes as well (without lang param) supported by
 * https://github.com/pillarjs/path-to-regexp/tree/v1.7.0
 **/
export const isHomeRouteFromMatch = match =>
  matchPath(match.url, { path: ROUTES.HOME.LANG_URL, exact: true })

export const isHomeRoute = location => matchAnyRoute(location, ROUTES.HOME.LANG_URL)

export const isFlightOffersRoute = location =>
  matchAnyRoute(location, ROUTES.FLIGHTS_OFFERS.LANG_URL)

export const isFlexCalendarRoute = location =>
  matchAnyRoute(location, ROUTES.FLEX_DATES_CALENDAR.LANG_URL)

export const isCheckoutRoute = location => matchAnyRoute(location, ROUTES.CHECKOUT.LANG_URL)

export const isExternalPaymentRoute = location =>
  matchAnyRoute(location, ROUTES.EXTERNAL_PAYMENT.LANG_URL)

export const isReservationServiceEnRoute = location =>
  matchAnyRoute(location, ROUTES.RESERVATION_SERVICE_EN.LANG_URL)

export const isReservationServiceEsRoute = location =>
  matchAnyRoute(location, ROUTES.RESERVATION_SERVICE_ES.LANG_URL)

export const isReservationServiceItRoute = location =>
  matchAnyRoute(location, ROUTES.RESERVATION_SERVICE_IT.LANG_URL)

export const isReservationServicePtRoute = location =>
  matchAnyRoute(location, ROUTES.RESERVATION_SERVICE_PT.LANG_URL)

export const isReservationServiceFrRoute = location =>
  matchAnyRoute(location, ROUTES.RESERVATION_SERVICE_FR.LANG_URL)

export const isCheckoutAncillariesRoute = location =>
  matchAnyRoute(location, ROUTES.CHECKOUT_ANCILLARIES.LANG_URL)

export const isCheckoutErrorRoute = location =>
  matchAnyRoute(location, ROUTES.CHECKOUT_ERROR.LANG_URL)

export const isMyReservationRoute = location =>
  matchAnyRoute(location, ROUTES.MY_RESERVATION.LANG_URL)

export const isMyReservationErrorRoute = location =>
  matchAnyRoute(location, ROUTES.MY_RESERVATION_ERROR.LANG_URL)

export const isExchangesRoute = location => matchAnyRoute(location, ROUTES.EXCHANGES.LANG_URL)

export const isExchangesErrorRoute = location =>
  matchAnyRoute(location, ROUTES.EXCHANGES_ERROR.LANG_URL)

export const isAncillariesRoute = location =>
  matchAnyRoute(location, ROUTES.MY_RESERVATION_ANCILLARIES.LANG_URL)

export const isBookingPaymentRoute = location =>
  matchAnyRoute(location, ROUTES.BOOKING_PAYMENT_PAGE.LANG_URL)

export const isFAQResultsRoute = location =>
  matchAnyRoute(location, ROUTES.FREQUENTLY_ASKED_QUESTIONS_RESULTS.LANG_URL)

export const isFlightStatusRoute = location =>
  matchAnyRoute(location, ROUTES.FLIGHT_STATUS.LANG_URL)

export const isCheckoutRetryGlobalRoute = location =>
  matchAnyRoute(location, ROUTES.CHECKOUT_RETRY.LANG_URL)

export const isPassengersMissingInfoRoute = location =>
  matchAnyRoute(location, ROUTES.PASSENGERS_MISSING_INFO.LANG_URL)

export const isArplusPaymentMilesRoute = location =>
  matchAnyRoute(location, ROUTES.ARPLUS_PAYMENT_MILES.LANG_URL)

export const isArplusTransferMilesRoute = location =>
  matchAnyRoute(location, ROUTES.ARPLUS_TRANSFER_MILES.LANG_URL)

export const isArplusGiftMilesRoute = location =>
  matchAnyRoute(location, ROUTES.ARPLUS_GIFT_MILES.LANG_URL)

export const isSublosHomeRoute = location =>
  matchAnyRoute(location, SUBLOS_ROUTES.SUBLOS_HOME.LANG_URL)

export const isSublosLoginRoute = location =>
  matchAnyRoute(location, SUBLOS_ROUTES.SUBLOS_LOGIN.LANG_URL)

export const isSublosRoute = location => (location.pathname || '').includes(SUBLOS_INITIAL_PATH)

/********************************* /
 /** Matches for array of routes **/

export const isRouteWithDarkSiteStyle = location => matchAnyRoute(location, DARK_SITE_ROUTES)

export const isRouteWithFooterCollapsible = location =>
  matchAnyRoute(location, COLLAPSIBLE_FOOTER_ROUTES)

export const isRouteWithSublosHeader = location => matchAnyRoute(location, HEADER_SUBLOS)

export const isRouteWithFullMenu = location => matchAnyRoute(location, PAGES_WITH_FULL_MENU)

export const isRouteWithMenuAndLanguage = location =>
  matchAnyRoute(location, PAGES_WITH_MENU_AND_LENGUAGE)

export const isRouteWithStepBar = location => {
  const params = isExchangesRoute(location) && getUrlParams(location.search.split('?').pop())
  const isExchangesWithParams = params && (params.departingLeg || params.returningLeg)

  const checkoutRoute = isCheckoutRoute(location)
  const externalPaymentRoute = isExternalPaymentMercadoPago(location)

  const isAncillariesStep =
    checkoutRoute && checkoutRoute.params && checkoutRoute.params.step === 'ancillaries'
  const passengerMissingInfo = isPassengersMissingInfoRoute(location)

  return (
    (checkoutRoute && !isAncillariesStep) ||
    !isEmpty(isExchangesWithParams) ||
    passengerMissingInfo ||
    externalPaymentRoute
  )
}

export const isRouteWithoutFooter = location => matchAnyRoute(location, PAGES_WITHOUT_FOOTER, true)

export const isRouteWithoutHeader = location => matchAnyRoute(location, PAGES_WITHOUT_HEADER, true)

export const isCampaignDataRoute = location => matchAnyRoute(location, CAMPAIGN_DATA_ROUTES)

export const isBookingRoute = location => matchAnyRoute(location, BOOKING_ROUTES)

export const getLandingsRelativePath = path => {
  let patt = new RegExp('([/]\\w\\S)-(\\w\\S[/])')
  let result = patt.test(path)
  return result ? path.substring(6) : path
}

export const getRouteByPathname = pathname => {
  const routes = Object.values(ROUTES)
  return routes.find(({ LANG_URL }) => matchPath(pathname, { exact: true, path: LANG_URL }))
}
