/* eslint-disable import/no-cycle */
import { generateTranslationKeysForForms } from 'helpers/translations'

export const ES_FORMS = {
  CANCELATION_IN_24HS: 'anulacion-de-pasajes',
  CAC: 'contacto-atencion-al-cliente',
  FAQ_AEROLINEAS: 'contacto-reservas-y-servicios',
  BAGGAGE_CLAIM: 'contacto-reclamos-de-equipaje',
  REFUND_FORM: 'devolucion-de-pasajes'
}
export const EN_FORMS = {
  CANCELATION_IN_24HS: 'ticket-cancelation',
  CAC: 'customer-service-contact',
  FAQ_AEROLINEAS: 'bookings-and-services-contact',
  BAGGAGE_CLAIM: 'baggage-complaints-contact',
  REFUND_FORM: 'ticket-refund'
}
export const IT_FORMS = {
  CAC: 'contatto-servizio-clienti',
  FAQ_AEROLINEAS: 'contatto-prenotazioni-e-servizi',
  BAGGAGE_CLAIM: 'contatto-ritiro-bagagli'
}
export const PT_FORMS = {
  CAC: 'contato-atendimento-cliente',
  FAQ_AEROLINEAS: 'contato-reservas-e-servicos',
  BAGGAGE_CLAIM: 'contato-retirada-bagagem'
}
export const FR_FORMS = {
  CAC: 'contact-service-client',
  FAQ_AEROLINEAS: 'contact-réservations-et-services',
  BAGGAGE_CLAIM: 'contact-reclamation-bagages'
}

export const TRANSLATION_KEYS = {
  CANCELATION_IN_24HS: {
    title: 'metadata.title.form.ticket-cancelation',
    description: 'metadata.description.form.ticket-cancelation'
  },
  CAC: {
    title: 'metadata.title.form.customer-service-contact',
    description: 'metadata.description.form.customer-service-contact'
  },
  FAQ_AEROLINEAS: {
    title: 'metadata.title.form.bookings-and-services-contact',
    description: 'metadata.description.form.bookings-and-services-contact'
  },
  BRANCH_OFFICES: {
    title: 'metadata.title.form.sales-offices',
    description: 'metadata.description.form.sales-offices'
  },
  BAGGAGE_CLAIM: {
    title: 'metadata.title.form.baggage-complaints-contact',
    description: 'metadata.description.form.baggage-complaints-contact'
  },
  REFUND_FORM: {
    title: 'metadata.title.form.ticket-refund',
    description: 'metadata.description.form.ticket-refund'
  }
}

export const TRANSLATION_KEYS_ALTERNATIVE = {
  CANCELATION_IN_24HS: 'form.ticket-cancelation',
  CAC: 'form.customer-service-contact',
  FAQ_AEROLINEAS: 'form.bookings-and-services-contact',
  BRANCH_OFFICES: 'form.sales-offices',
  BAGGAGE_CLAIM: 'form.baggage-complaints-contact',
  REFUND_FORM: 'form.ticket-refund'
}

export const FORMTRANSLATION_KEYS_FOR_FORMS = generateTranslationKeysForForms(
  [ES_FORMS, EN_FORMS, IT_FORMS, PT_FORMS, FR_FORMS],
  TRANSLATION_KEYS
)

export const FORMTRANSLATION_KEYS_ALTERNATIVE = generateTranslationKeysForForms(
  [ES_FORMS, EN_FORMS, IT_FORMS, PT_FORMS, FR_FORMS],
  TRANSLATION_KEYS_ALTERNATIVE
)

export const CANCELATION_FORMS = [
  ES_FORMS.CANCELATION_IN_24HS,
  EN_FORMS.CANCELATION_IN_24HS,
  IT_FORMS.CANCELATION_IN_24HS,
  PT_FORMS.CANCELATION_IN_24HS,
  FR_FORMS.CANCELATION_IN_24HS
]

export const FAQ_FORMS = [
  ES_FORMS.FAQ_AEROLINEAS,
  EN_FORMS.FAQ_AEROLINEAS,
  IT_FORMS.FAQ_AEROLINEAS,
  PT_FORMS.FAQ_AEROLINEAS,
  FR_FORMS.FAQ_AEROLINEAS
]

export const BRANCH_FORMS = [
  ES_FORMS.BRANCH_OFFICES,
  EN_FORMS.BRANCH_OFFICES,
  IT_FORMS.BRANCH_OFFICES,
  PT_FORMS.BRANCH_OFFICES,
  FR_FORMS.BRANCH_OFFICES
]

export const CAC_FORMS = [ES_FORMS.CAC, EN_FORMS.CAC, IT_FORMS.CAC, PT_FORMS.CAC, FR_FORMS.CAC]

export const BAGGAGE_CLAIM_FORMS = [
  ES_FORMS.BAGGAGE_CLAIM,
  EN_FORMS.BAGGAGE_CLAIM,
  IT_FORMS.BAGGAGE_CLAIM,
  PT_FORMS.BAGGAGE_CLAIM,
  FR_FORMS.BAGGAGE_CLAIM
]

export const REFUND_FORMS = [
  ES_FORMS.REFUND_FORM,
  EN_FORMS.REFUND_FORM,
  IT_FORMS.REFUND_FORM,
  PT_FORMS.REFUND_FORM,
  FR_FORMS.REFUND_FORM
]

export const FAQ_CAC_BAGGAGE_FORMS = [...FAQ_FORMS, ...CAC_FORMS, ...BAGGAGE_CLAIM_FORMS]

export const CONTACT_US_BANNER_FORMS = [
  ...FAQ_CAC_BAGGAGE_FORMS,
  ...BRANCH_FORMS,
  ...CANCELATION_FORMS
]

export const FORMS_ONLY_US = [
  EN_FORMS.CANCELATION_IN_24HS,
  EN_FORMS.REFUND_FORM,
  ES_FORMS.CANCELATION_IN_24HS,
  ES_FORMS.REFUND_FORM
]
