import { buildURLEncoded } from 'helpers/url'

import { sections, generateReservationRoutes } from 'constants/reservationSections'
import postBookingOptions from 'constants/postBookingOptions'
import GDS_MESSAGES from 'constants/gdsMessages'
import CURRENCY from 'constants/currencies'

const isSectionAvailable = (section, options) => {
  switch (section) {
    case sections.ANCILLARIES:
      return options
        .filter(
          option =>
            option.option === postBookingOptions.ADDITIONAL_BAGS ||
            option.option === postBookingOptions.SPECIAL_BAGS ||
            option.option === postBookingOptions.VIP_LOUNGE
        )
        .some(option => option.enabled)
    case sections.EXCHANGES:
      return options.find(option => option.option === postBookingOptions.EXCHANGE).enabled
    case sections.CHECK_IN:
      return options.find(option => option.option === postBookingOptions.CHECK_IN).enabled
    case sections.SEAT_SELECTION:
      return options.find(option => option.option === postBookingOptions.SEAT_SELECTION).enabled
    default:
      return true
  }
}

export const getReservationSectionURL = (code, lastName, section, options, language) => {
  const isAvailable = isSectionAvailable(section, options)

  const params =
    isAvailable && section === sections.CHECK_IN
      ? {
          deepLink: true,
          locale: language,
          recordLocator: code,
          lastName: lastName
        }
      : {
          reservationCode: code,
          lastName: lastName
        }

  const reservationRoutes = generateReservationRoutes(language)
  const urlParams = buildURLEncoded('', params)

  const route = isAvailable
    ? reservationRoutes[section] || reservationRoutes[sections.MY_BOOKING]
    : reservationRoutes[sections.MY_BOOKING]

  return `${route}${urlParams}`
}

export const isSSRErrorMessage = errorDetails =>
  errorDetails.includes(GDS_MESSAGES.PASSENGER_ERROR_SSR_NOT_ALLOWED) ||
  errorDetails.includes(GDS_MESSAGES.PASSENGER_ERROR_MEAL_NOT_ALLOWED)

export const getExternalBookingUrl = currency => {
  return (
    (currency === CURRENCY.BRL && 'external-booking.br.url') ||
    (currency === CURRENCY.CLP && 'external-booking.cl.url') ||
    'external-booking.default.url'
  )
}
