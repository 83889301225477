import PRODUCTS from 'constants/products'
import { countFromReservation, segmentsFromReservation } from 'helpers/evaluation'

export const getFlightSummaryFromReservation = ({ bookingMetadata, ...reservationData }) => ({
  ...reservationData,
  summaryMetadata: {
    ...bookingMetadata,
    summaryType: bookingMetadata.flightType
  },
  passengers: countFromReservation(reservationData.passengersData)
})

export const getFlightSummaryFromMissingInfo = (reservation, missingInfo) => ({
  paymentData: {
    currency: missingInfo.currency ? missingInfo.currency : '',
    amount: missingInfo.totalAmount
  },
  product: PRODUCTS.MISSING_INFO,
  flightType: reservation.bookingMetadata.flightType,
  segments: segmentsFromReservation(reservation.legs),
  ...countFromReservation(missingInfo.passengers)
})

const formatAncillaries = (ancillariesSummary, product) => {
  const {
    ancillarySummary: {
      ancillariesMetadata: { currency, citySegments },
      total,
      passengers: { adtCount, chdCount, infCount }
    }
  } = ancillariesSummary

  return {
    paymentData: {
      currency: currency,
      amount: total
    },
    segments: citySegments,
    product: product === PRODUCTS.ANCILLARIES_BOOKING ? product : PRODUCTS.SHOPPING,
    adtCount,
    chdCount,
    infCount
  }
}

/**
 * Method to parse from flightSummaryData to paymentOptions endpoing payload
 * @param {Object} flightSummary - Summary data taken from redux store
 * @param {string} product - Product type (TICKET, BNPL, ...)
 * @param {Object} holdBookingOptions - Needed if product is BNPL type
 */
export const flightSummaryToPaymentOptionsPayload = (flightSummary, product, holdBookingPrice) => {
  // ANCILLARIES_SHOPPING is "TICKET" product but data is taken from ancillariesSummary structure
  if (product === PRODUCTS.ANCILLARIES_BOOKING || product === PRODUCTS.ANCILLARIES_SHOPPING) {
    return formatAncillaries(flightSummary, product)
  }

  const { summaryMetadata, passengers, total, totalCurrency } = flightSummary
  const { summaryType, campaignCode, citySegments, routes, currency } = summaryMetadata
  const { adtCount, chdCount, infCount } = passengers

  const payload = {
    paymentData: {
      currency: currency,
      amount: totalCurrency || total //totalCurrency is the milesTotal
    },
    flightType: summaryType,
    routes,
    product,
    adtCount,
    chdCount,
    infCount,
    campaignId: campaignCode
  }

  //BNPL was change for HOLD_TICKET to matched with BO service
  if (product === PRODUCTS.HOLD_TICKET) {
    payload.paymentData.currency = holdBookingPrice.currency
    payload.paymentData.amount = holdBookingPrice.amount
  }

  // This is to support reservation final payment. In case routes are no longer accepted
  // we need gds to send us this data or at least provide us with iotaCodes at origin and destination
  // legs -> segments reservation response.
  if (citySegments) {
    payload.segments = citySegments
    delete payload.routes
  }

  return payload
}

export const getFlightSummaryFromSublos = ({ bookingMetadata, total, passengersData, legs }) => ({
  paymentData: {
    currency: bookingMetadata.currency ? bookingMetadata.currency : '',
    amount: total
  },
  routes: bookingMetadata.routes,
  product: PRODUCTS.SUBLOS,
  flightType: bookingMetadata.flightType,
  segments: (legs || [])
    .map(({ segments }) =>
      segments.map(
        ({
          iataCodeDestination,
          iataCodeOrigin,
          bookingClass,
          operatingAirline,
          flightNumber,
          departure,
          arrival
        }) => ({
          origin: iataCodeOrigin,
          destination: iataCodeDestination,
          bookingClass,
          operatingAirline,
          flightNumber,
          departureDate: departure,
          arrivalDate: arrival
        })
      )
    )
    .flat(),
  ...countFromReservation(passengersData)
})
