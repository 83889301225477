import { handleActions } from 'redux-actions'

import {
  fetchSecondCardPaymentOptionsStart,
  fetchSecondCardPaymentOptionsSuccess,
  fetchSecondCardPaymentOptionsError,
  clearSecondCardPaymentOptionsError,
  clearSecondCardPaymentOptionsState
} from 'actions/secondCardPaymentOptions'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchSecondCardPaymentOptionsStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchSecondCardPaymentOptionsSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [fetchSecondCardPaymentOptionsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    }),
    [clearSecondCardPaymentOptionsError]: state => ({
      ...state,
      error: null
    }),
    [clearSecondCardPaymentOptionsState]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
