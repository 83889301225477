import { handleActions } from 'redux-actions'

import {
  companiesSublosStart,
  companiesSublosSuccess,
  companiesSublosError,
  companiesSublosClear
} from 'actions/sublos/companies'

const defaultState = {
  data: [],
  error: null,
  isFetching: false
}

const companiesSublos = handleActions(
  {
    [companiesSublosStart]: state => ({
      ...state,
      data: [],
      error: null,
      isFetching: true
    }),
    [companiesSublosSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [companiesSublosError]: (state, { payload: { error } }) => ({
      ...state,
      data: [],
      error,
      isFetching: false
    }),
    [companiesSublosClear]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default companiesSublos
