import { createSelector } from 'reselect'

import { isEmpty } from 'helpers/utils'

export const getAirlinesSubstate = state => state.airlines

export const getAirlinesData = createSelector(
  [getAirlinesSubstate],
  state => (state ? state.data : null)
)

export const isFetchingAirlines = createSelector(
  [getAirlinesSubstate, getAirlinesData],
  (state, data) => state.isFetching || isEmpty(data)
)

export const getAirlines = createSelector(
  [getAirlinesData],
  airlines => (airlines ? airlines.resources : [])
)
