import styled, { css } from 'styled-components'

import mediaQueries from 'components/media-queries'
import e2eClasses from 'helpers/automation'

export const headerHeightDesktop = `height: 80px;`
export const headerHeightMobile = `height: 60px;`
export const SkipContent = styled.a`
  position: absolute;
  top: 5rem;
  left: -1000px;
  z-index: 999;
  padding: 1em;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.fonts.semiBold};
  border-radius: 0.1875rem;
  text-decoration: none;

  &:focus {
    left: 0;
  }
`

export const BoldText = styled.label`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`
export const MainContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.gray10};
  max-width: 1200px;
  width: 100%;
  ${headerHeightDesktop}
  grid-template-columns: 300px 1fr 400px;
  display: grid;

  ${({ stepBar }) =>
    stepBar &&
    `
    grid-template-columns: 250px 1fr 200px;
  `}

  ${({ milesLogin, headerWithLoginOnly }) =>
    milesLogin &&
    headerWithLoginOnly &&
    `
      grid-template-columns: 300px 200px;
      justify-content: space-between;
    `}

  ${mediaQueries.tablet`
    grid-template-columns: ${({ stepBar, darkSiteEnabled }) =>
      stepBar ? '1fr 1fr' : darkSiteEnabled ? '30px 1fr 40px' : '30px 1fr 30px'};

    padding: 0 65px;
  `};

  ${mediaQueries.mobile`
    ${headerHeightMobile}
    padding: 0 15px;
  `};
`

export const MenuItem = styled.button`
  display: ${({ noDesktop }) => (noDesktop ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray10};
  position: relative;
  z-index: 6;
  margin: 0 15px;
  font-size: inherit;

  ${mediaQueries.tablet`
    margin: 17px 0;
    display: flex;
    align-items: flex-start;
    background: none;
  `};
`

export const MenuItemLogin = styled.button`
  display: ${({ noDesktop }) => (noDesktop ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray10};
  position: relative;
  z-index: 6;

  ${mediaQueries.tablet`
    display: none;
  `};
`

export const MenuText = styled.label.attrs(() => ({
  className: e2eClasses('home.navbar.menuItem')
}))`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.blue50};
  letter-spacing: 1.5px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.regular};
  text-align: center;
  svg {
    display: none;
  }

  ${mediaQueries.tablet`
    justify-content: space-between;
    width: 100%;

    svg {
      display: inline;
    }
  `}

  &:hover {
    padding-top: 4px;
    border-bottom: 4px solid ${({ theme }) => theme.colors.blue50};

    ${mediaQueries.tablet`
      border-bottom: none;
      padding-top: 8px;
    `};
  }

  ${mediaQueries.mobile`
    padding: 0;
  `}
`
export const HelpMenu = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.regular};
  z-index: 6;

  svg {
    margin-right: 5px;
    vertical-align: bottom;

    path {
      fill: ${({ theme }) => theme.colors.doveGray};
    }
  }

  ${mediaQueries.tablet`
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 17px;
  `};
`
export const LoginMenu = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  color: ${({ theme }) => theme.colors.blue50};
  cursor: pointer;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.regular};

  svg {
    margin-right: 5px;
    vertical-align: bottom;

    path {
      fill: ${({ theme }) => theme.colors.blue50};
    }
  }

  & + span {
    display: none;
  }

  &:hover + span {
    display: block;
    background-color: ${({ theme }) => theme.colors.blue50};
    margin-top: -4px;
    height: 4px;
    width: 100%;
  }

  ${({ opened }) =>
    opened &&
    css`
      & + span {
        display: block;
        background-color: ${({ theme }) => theme.colors.blue50};
        margin-top: -4px;
        height: 4px;
        width: 90%;
      }
    `};
`

export const LanguageMenu = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${mediaQueries.tablet`
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 17px;
  `};

  ${mediaQueries.mobile`
    width: 100%;
  `}
`

export const FlagImage = styled.img.attrs(() => ({
  className: e2eClasses('home.navbar.menuLanguageIcon')
}))`
  width: 1em;
  height: 1em;
  margin-right: 5px;
`

export const MenuPanel = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  margin: 0 auto;
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow.black09};
  z-index: 7;
  max-width: 1200px;

  ${mediaQueries.tablet`
    padding: 20px 65px;
    height: 90vh;
    top: 0;
    overflow: auto;
  `};

  ${mediaQueries.mobile`
    padding: 10px 20px;
  `}
`

export const MenuPanelSeparator = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 20px;
`

export const FixedContainer = styled.div`
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow.black30};
  background-color: ${({ theme }) => theme.colors.gray10};
  z-index: ${({ homePage, theme }) =>
    theme?.root?.style.getPropertyValue('--is-arsa-keys') === 'true'
      ? '1000'
      : homePage
      ? '4'
      : '0'};
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`
export const LoginText = styled.div`
  display: block;
  font-size: 14px;
`

export const LoginLabel = styled.p.attrs(() => ({
  className: e2eClasses('home.navbar.menuLogin')
}))`
  font-family: ${({ theme }) => theme.fonts.regular};
`

export const SelectedLanguage = styled.div`
  display: flex;
  align-items: center;
`

export const PointerCursor = styled.button.attrs(() => ({
  className: e2eClasses('home.navbar.menuHelper')
}))`
  color: ${({ theme }) => theme.colors.doveGray};
  text-decoration: none;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.regular};
  cursor: pointer;
`

export const LabelText = styled.label`
  color: ${({ theme }) => theme.colors.blue50};
  font-size: 1em;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  margin-left: 5px;
`

export const Row = styled.div`
  display: ${({ responsive }) => (responsive ? 'none' : 'flex')};

  ${mediaQueries.tablet`
    align-items: flex-end;
  `};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const MenuDisplayer = styled.button`
  display: none;

  ${mediaQueries.tablet`
    display: flex;
    align-items: center;
  `};
`

export const ComponentsContainer = styled.div`
  display: flex;
  ${({ justify }) => justify && `justify-content: ${justify};`}
   ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft};`}

  ${mediaQueries.tablet`
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow.black09};

    z-index: 4;
    display: inline;
    padding: 20px 65px;
    min-height: calc(100vh - 80px);
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    margin: 0 auto;

    ${({ hideOnMobile }) => hideOnMobile && 'display: none;'}
  `};

  ${mediaQueries.mobile`
    top: 60px;
    padding: 10px 20px;
    min-height: calc(100vh - 60px);
  `}
`

export const ItemsContainer = styled.nav`
  width: 100%;
  display: flex;

  ${mediaQueries.tablet`
    flex-direction: column;
  `};
`

export const RibbonImage = styled.img`
  max-height: 42px;
  max-width: 40px;
  display: flex;
  align-self: center;
  ${mediaQueries.tablet`
    width: 13px;
  `}
`

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.alto};
  display: flex;
  margin-bottom: 17px;
`

export const LoginMenuDesktopContainer = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  min-width: 137px;

  &:hover > #loginOptions {
    visibility: visible;
  }
`

export const LoginOptions = styled.div`
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 80px;
  z-index: 500;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.white};
`

export const Options = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 14px;
  line-height: 2.43;
  color: ${({ theme }) => theme.colors.doveGray};
  cursor: pointer;

  ${mediaQueries.mobile`
    font-size: 12px;
  `}
`
