import { handleActions } from 'redux-actions'
import {
  fetchBnplPaymentOptionsStart,
  fetchBnplPaymentOptionsSuccess,
  fetchBnplPaymentOptionsError,
  clearBnplPaymentOptionsError,
  clearBnplPaymentOptionsState
} from 'actions/bnplPaymentOptions'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchBnplPaymentOptionsStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchBnplPaymentOptionsSuccess]: (state, { payload }) => ({
      ...state,
      data: payload,
      error: null,
      isFetching: false
    }),
    [fetchBnplPaymentOptionsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    }),
    [clearBnplPaymentOptionsError]: state => ({
      ...state,
      error: null
    }),
    [clearBnplPaymentOptionsState]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
