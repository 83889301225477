import { IconNames } from 'components/icons'

export default [
  {
    key: 'seatsSelection',
    title: 'general.flight-offers.fare-conditions.seat-selection',
    icon: IconNames.Seat,
    defaultIncludedText: 'general.flight-offers.fare-conditions.with-seat-selection',
    defaultNonIncludedText: 'general.flight-offers.fare-conditions.without-seat-selection'
  },
  {
    key: 'checkedBaggage',
    title: 'general.flight-offers.fare-conditions.checked-baggage',
    icon: IconNames.Luggage,
    defaultIncludedText: 'general.flight-offers.fare-conditions.with-checked-baggage',
    defaultNonIncludedText: 'general.flight-offers.fare-conditions.without-checked-baggage'
  },
  {
    key: 'carryOnBaggage',
    title: 'general.flight-offers.fare-conditions.carry-on-baggage',
    icon: IconNames.Briefcase,
    defaultIncludedText: 'general.flight-offers.fare-conditions.with-carry-on-baggage',
    defaultNonIncludedText: 'general.flight-offers.fare-conditions.without-carry-on-baggage'
  },
  {
    key: 'exchanges',
    title: 'general.flight-offers.fare-conditions.exchanges',
    icon: IconNames.Change,
    defaultIncludedText: 'general.flight-offers.fare-conditions.with-exchanges',
    defaultNonIncludedText: 'general.flight-offers.fare-conditions.without-exchanges'
  },
  {
    key: 'returns',
    title: 'general.flight-offers.fare-conditions.ticket-returns',
    icon: IconNames.Reload,
    defaultIncludedText: 'general.flight-offers.fare-conditions.with-ticket-return',
    defaultNonIncludedText: 'general.flight-offers.fare-conditions.without-ticket-return'
  },
  {
    key: 'miles',
    title: 'general.flight-offers.fare-conditions.miles',
    icon: IconNames.TagPlus,
    defaultIncludedText: 'general.flight-offers.fare-conditions.with-miles',
    defaultNonIncludedText: 'general.flight-offers.fare-conditions.without-miles'
  }
]
