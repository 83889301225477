const NAMESPACES = {
  AR_PLUS: 'arPlus',
  CHECKOUT: 'checkout',
  COMMON: 'common',
  CORPORATE_AGREEMENTS: 'corporateAgreements',
  EVENTS: 'events',
  EXCHANGES: 'exchanges',
  FLIGHT_OFFERS: 'flightOffers',
  HOME: 'home',
  MY_RESERVATION: 'myReservation',
  PAYMENT: 'payment',
  SCHEDULED_FLIGHTS: 'scheduledFlights',
  PASSENGERS: 'passengers',
  SEARCH_BOX: 'searchBox',
  USA_FORM: 'usaForms',
  SUBLOS: 'sublos',
  REFUND: 'refund',
  CONTESTS: 'contests',
  SEO_METADATA: 'seoMetadata',
  APP_LINK: 'appLink'
}

export default NAMESPACES
