import styled from 'styled-components'

import mediaQueries from 'components/media-queries'
import e2eClasses from 'helpers/automation'

export const Container = styled.div`
  padding: 3em;
  padding-bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.alabaster};
  display: flex;
  flex-direction: column;
  ${mediaQueries.tablet`
    padding: 7px;
  `}
`

export const Title = styled.button.attrs(() => ({
  className: e2eClasses('footer.institutional.itemTitle')
}))`
  text-align: left;
  color: ${({ theme }) => theme.colors.blue};
  margin-bottom: 27px;
  font-size: 20px;
  font-family: ${({ theme }) => theme.fonts.semiBold};

  ${mediaQueries.tablet`
    text-align: center;
  `};
`

export const LinksContainer = styled.div.attrs(() => ({
  className: e2eClasses('footer.institutional.itemsBox')
}))`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  min-width: 500px;
  width: 100%;

  ${mediaQueries.tablet`
    max-height: 0;
    opacity: 0;
    transition: all 500ms ease-in-out;
    pointer-events: none;

    ${mediaQueries.mobile`
      grid-template-columns: auto;
      min-width: 0;
    `};

    ${({ isExpanded }) =>
      isExpanded &&
      `
      pointer-events: all;
      max-height: 100%;
      opacity: 1;
    `};
  `};
`

export const Link = styled.button.attrs(() => ({
  className: e2eClasses('footer.institutional.itemOption')
}))`
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.doveGray};
  text-align: left;
  cursor: pointer;
  margin-bottom: 16px;
  font-size: 14px;
  text-decoration: none;
`
export const IconContainer = styled.div`
  display: none;

  ${mediaQueries.tablet`
    display: inline;
    margin-left: 7px;
    bottom: -5px;
    position: relative;
  `};
`

export const IconImagen = styled.img`
  margin-top: 5px;
  width: 36px;
  height: 17px;
  margin-right: 5px;
`
