const DEFAULT_DIGIT_COUNT = 16
const DEFAULT_SECURITY_CODE_DIGIT_COUNT = 3

export const getDigitCount = (paymentValidations, selectedPaymentMethod) => {
  //const { paymentValidations, selectedPaymentMethod } = this.props.paymentMethodSelection
  const validation = (paymentValidations || []).find(({ code }) => code === selectedPaymentMethod)

  if (validation && validation.digitCount) {
    return validation.digitCount
  }

  return [DEFAULT_DIGIT_COUNT]
}

export const getSecurityCodeDigitCount = (paymentValidations, selectedPaymentMethod) => {
  //const { paymentValidations, selectedPaymentMethod } = this.props.paymentMethodSelection
  const validation = (paymentValidations || []).find(({ code }) => code === selectedPaymentMethod)

  if (validation && validation.securityCodeDigitCount) {
    return validation.securityCodeDigitCount
  }

  return [DEFAULT_SECURITY_CODE_DIGIT_COUNT]
}
