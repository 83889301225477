import { createActions } from 'redux-actions'
import axios from 'axios'
import CONFIG from 'config'

import apiRoutes from 'constants/apiRoutes'
import { getLSValue } from 'helpers/localStorage'
import { LogOut } from 'helpers/flightOffers'

const {
  lastOperationsStart,
  lastOperationsSuccess,
  lastOperationsError,
  clearLastOperations
} = createActions({
  LAST_OPERATIONS_START: () => {},
  LAST_OPERATIONS_SUCCESS: data => ({ data }),
  LAST_OPERATIONS_ERROR: error => ({ error }),
  CLEAR_LAST_OPERATIONS: () => {}
})

const fetchLastOperations = (memberShipCode, selectedDate, lastDayOfMonthDate) => async dispatch => {
  dispatch(lastOperationsStart())
  try {
    const response = await axios.get(
      `${apiRoutes.AR_PLUS_LAST_OPERATIONS(memberShipCode, selectedDate, lastDayOfMonthDate)}`,
      {
        baseURL: CONFIG.API_BASE_URL,
        headers: {
          Authorization: `Bearer ${getLSValue('access_token')}`,
          'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID,
          'Accept-Language': CONFIG.DEFAULT_LOCALE
        }
      }
    )
    dispatch(lastOperationsSuccess(response.data))
  } catch (error) {
    const err = error.response ? error.response.data : error

    if (err?.statusCode === 401) {
      LogOut()
      dispatch(clearLastOperations())
    }
    dispatch(lastOperationsError(err))
  }
}

export {
  fetchLastOperations,
  lastOperationsStart,
  lastOperationsSuccess,
  lastOperationsError,
  clearLastOperations
}
