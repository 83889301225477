import { handleActions } from 'redux-actions'
import {
  rangeTooltipStart,
  rangeTooltipSuccess,
  rangeTooltipError,
  resetRangeTooltip,
  sendRangeTooltipStart,
  sendRangeTooltipSuccess,
  sendRangeTooltipError
} from 'actions/rangeTooltip'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [rangeTooltipStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [rangeTooltipSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [rangeTooltipError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    }),
    [sendRangeTooltipStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [sendRangeTooltipSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [sendRangeTooltipError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    }),
    [resetRangeTooltip]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
