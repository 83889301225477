import { handleActions } from 'redux-actions'
import {
  unsubscribeConfirmationStart,
  unsubscribeConfirmationSuccess,
  unsubscribeConfirmationError,
  clearUnsubscribeConfirmationState
} from 'actions/arplus/unsubscribeConfirmation'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const unsubscribeConfirmation = handleActions(
  {
    [unsubscribeConfirmationStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [unsubscribeConfirmationSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [unsubscribeConfirmationError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    }),
    [clearUnsubscribeConfirmationState]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default unsubscribeConfirmation
