import { handleActions } from 'redux-actions'

import {
  sublosNoNameConditionsSuccess,
  sublosNoNameConditionsError,
  sublosNoNameConditionsStart,
  sublosNoNameConditionsClear
} from 'actions/sublos/noNameConditions'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const sublosNoNameConditions = handleActions(
  {
    [sublosNoNameConditionsStart]: state => ({
      ...state,
      error: null,
      isFetching: true
    }),
    [sublosNoNameConditionsSuccess]: (state, { payload: { data } }) => ({
      data,
      error: null,
      isFetching: false
    }),
    [sublosNoNameConditionsError]: (state, { payload: { error } }) => ({
      ...state,
      error,
      isFetching: false
    }),
    [sublosNoNameConditionsClear]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default sublosNoNameConditions
