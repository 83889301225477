import React from 'react'

import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { changeModalState } from 'actions/modalActions'
import Button from 'components/button'
import Icon, { IconNames } from 'components/icons'
import Modal from 'components/modal'
import theme from 'components/theme'
import MODAL_NAMES from 'constants/modalNames'
import { generateArPlusLoginRoute } from 'helpers/url/localization'
import { LANGUAGE_TAGS } from 'localization/constants/languages'
import NAMESPACES from 'localization/constants/namespaces'
import { getMembershipNumber } from 'selectors/milesLogin'

import {
  ModalContainer,
  HeaderWrapper,
  TitleBlue,
  IconWrapper,
  Content,
  Subtitle,
  Text,
  ButtonsContainer
} from './styled'

const LoginModal = props => {
  const {
    t,
    i18n: { language }
  } = useTranslation(NAMESPACES.AR_PLUS)
  const history = useHistory()

  const hideModal = () =>
    props.changeModalState({
      isVisible: false,
      modalName: MODAL_NAMES.ARPLUS_LOGIN_DISCLAIMER
    })

  const acceptHandler = () => {
    hideModal(MODAL_NAMES.ARPLUS_LOGIN_DISCLAIMER)
    if (language == LANGUAGE_TAGS.ESPAÑA_EN)
      history.push(generateArPlusLoginRoute(LANGUAGE_TAGS.ENGLISH))
    else history.push(generateArPlusLoginRoute(LANGUAGE_TAGS.DEFAULT))
    window.location.reload()
  }

  return (
    <Modal
      name={MODAL_NAMES.ARPLUS_LOGIN_DISCLAIMER}
      onClose={() => hideModal(MODAL_NAMES.ARPLUS_LOGIN_DISCLAIMER)}
      width={'60%'}
    >
      <ModalContainer>
        <HeaderWrapper>
          <TitleBlue>{t('arplus.login-disclaimer.title')}</TitleBlue>
          <IconWrapper
            aria-label={t('alt.close')}
            onClick={() => hideModal(MODAL_NAMES.ARPLUS_LOGIN_DISCLAIMER)}
          >
            <Icon name={IconNames.Close} color={theme.colors.blue} />
          </IconWrapper>
        </HeaderWrapper>
        <Subtitle>{t('arplus.login-disclaimer.subtitle')}</Subtitle>
        <Content>
          <Text>{t('arplus.login-disclaimer.text')}</Text>
        </Content>
        <ButtonsContainer>
          <Button
            large
            type="submit"
            name="accountData-unsubscribe"
            onClick={() => {
              acceptHandler()
            }}
          >
            {t('arplus.login-disclaimer.button')}
          </Button>
        </ButtonsContainer>
      </ModalContainer>
    </Modal>
  )
}

const mapStateToProps = state => ({
  membershipNumber: getMembershipNumber(state)
})

const mapDispatchToProps = {
  changeModalState
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal)
