/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect'

import { isEmpty } from 'helpers/utils'
import {
  isFetchingDocumentTypesRules,
  getDocumentTypesRulesData
} from 'selectors/documentTypesRules'
import { isFetchingPassengerDataRules } from 'selectors/passengerDataRules'
import { isFetchingPaymentOptions, getPaymentOptionsData } from 'selectors/paymentOptions'
import { isFetchingEmptyReservation, getReservationData } from 'selectors/reservation'
import {
  isFetchingSpecialRequestsRules,
  getSpecialRequestsRules
} from 'selectors/specialRequestRules'

import { isFetchingDocumentTypes, getDocumentTypesData } from './documentTypes'
import { isFetchingFareRules, getFareRulesData } from './fareRules'
import {
  isFetchingPaymentOptionsValidations,
  getPaymentMethodsValidations
} from './paymentMethodSelection'

const getPaymentBooking = state => state.paymentBooking

export const getPaymentBookingData = createSelector([getPaymentBooking], state =>
  state ? state.data : null
)

export const getPaymentBookingError = createSelector([getPaymentBooking], state =>
  state ? state.error : null
)

export const isPostingPaymentBooking = createSelector([getPaymentBooking], state =>
  state ? state.isFetching : null
)

export const getPaymentBookingValidationDetails = createSelector([getPaymentBookingError], error =>
  error && !isEmpty(error.validationDetails) ? error.validationDetails : null
)

export const getPaymentBookingGeneralRequestError = createSelector(
  [getPaymentBookingValidationDetails],
  errorDetail =>
    errorDetail && !isEmpty(errorDetail.GENERAL_REQUEST) ? errorDetail.GENERAL_REQUEST : null
)

export const isFetchingPaymentBookingData = createSelector(
  [
    isFetchingEmptyReservation,
    isFetchingPaymentOptions,
    isFetchingPaymentOptionsValidations,
    isFetchingDocumentTypesRules,
    isFetchingDocumentTypes,
    isFetchingSpecialRequestsRules,
    isFetchingPassengerDataRules,
    isFetchingFareRules
  ],
  (...rest) => rest.some(value => value)
)

export const isAnyPaymentBookingDataEmpty = createSelector(
  [
    getReservationData,
    getPaymentOptionsData,
    getPaymentMethodsValidations,
    getDocumentTypesRulesData,
    getDocumentTypesData,
    getSpecialRequestsRules,
    getFareRulesData
  ],
  (...rest) => rest.some(data => isEmpty(data))
)

export const getRedirectForm = createSelector(
  [getPaymentBookingData],
  paymentData => paymentData && paymentData?.payments[0]?.globalInformation?.redirectForm
)

export const getRedirectType = createSelector(
  [getPaymentBookingData],
  paymentData => paymentData && paymentData?.payments[0]?.globalInformation?.redirectType
)
