import { handleActions } from 'redux-actions'

import {
  sublosBookingValidationStart,
  sublosBookingValidationSuccess,
  sublosBookingValidationError,
  sublosReservationStart,
  sublosReservationSuccess,
  sublosReservationError,
  sublosCancelReservationStart,
  sublosCancelReservationSuccess,
  sublosCancelReservationError,
  clearSublosBookingValidation,
  clearSublosCancelReservation,
  clearSublosReservation
} from 'actions/sublos/reservation'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const sublosBookingValidation = handleActions(
  {
    [sublosBookingValidationStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [sublosBookingValidationSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [sublosBookingValidationError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearSublosBookingValidation]: () => ({
      ...defaultState
    })
  },
  defaultState
)

const sublosReservation = handleActions(
  {
    [sublosReservationStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [sublosReservationSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [sublosReservationError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearSublosReservation]: () => ({
      ...defaultState
    })
  },
  defaultState
)

const sublosCancelReservation = handleActions(
  {
    [sublosCancelReservationStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [sublosCancelReservationSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [sublosCancelReservationError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearSublosCancelReservation]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default sublosBookingValidation
export { sublosReservation, sublosCancelReservation }
