import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import { getFlightOffersMatrixSearchDataUrl } from 'helpers/url'

const {
  fetchFlightOffersMatrixStart,
  fetchFlightOffersMatrixSuccess,
  fetchFlightOffersMatrixError,
  setRedirectFromFlightOffersMatrix,
  resetFlightOffersMatrix
} = createActions({
  FETCH_FLIGHT_OFFERS_MATRIX_START: () => {},
  FETCH_FLIGHT_OFFERS_MATRIX_SUCCESS: data => ({ data }),
  FETCH_FLIGHT_OFFERS_MATRIX_ERROR: error => ({ error }),
  SET_REDIRECT_FROM_FLIGHT_OFFERS_MATRIX: redirect => ({ redirect }),
  RESET_FLIGHT_OFFERS_MATRIX: () => {}
})

const fetchFlightOffersMatrix = flightsSearchData => {
  return async dispatch => {
    dispatch(fetchFlightOffersMatrixStart())

    try {
      const params = getFlightOffersMatrixSearchDataUrl(flightsSearchData)
      const data = await api.get(`${apiRoutes.FLIGHT_OFFERS_MATRIX}${params}`)
      dispatch(fetchFlightOffersMatrixSuccess(data))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(fetchFlightOffersMatrixError(err))
    }
  }
}

export {
  fetchFlightOffersMatrixStart,
  fetchFlightOffersMatrixSuccess,
  fetchFlightOffersMatrixError,
  fetchFlightOffersMatrix,
  setRedirectFromFlightOffersMatrix,
  resetFlightOffersMatrix
}
