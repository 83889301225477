import { handleActions } from 'redux-actions'
import {
  fetchCampaignsStart,
  fetchCampaignsSuccess,
  fetchCampaignsError,
  clearCampaigns
} from 'actions/rules/campaigns'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchCampaignsStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchCampaignsSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [fetchCampaignsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    }),
    [clearCampaigns]: state => ({ ...state, data: null })
  },
  defaultState
)

export default reducer
