import { createActions } from 'redux-actions'
import { cmsApi } from 'services/api'
import cmsApiRoutes from 'constants/cmsApiRoutes'

const {
  fetchDirectAccessesStart,
  fetchDirectAccessesSuccess,
  fetchDirectAccessesError
} = createActions({
  FETCH_DIRECT_ACCESSES_START: () => {},
  FETCH_DIRECT_ACCESSES_SUCCESS: data => ({ data }),
  FETCH_DIRECT_ACCESSES_ERROR: error => ({ error })
})

const fetchDirectAccesses = () => {
  return async dispatch => {
    dispatch(fetchDirectAccessesStart())

    try {
      const directAccesses = await cmsApi.get(cmsApiRoutes.DIRECT_ACCESSES)
      dispatch(fetchDirectAccessesSuccess(directAccesses))
    } catch (error) {
      dispatch(fetchDirectAccessesError(error))
    }
  }
}

export {
  fetchDirectAccessesStart,
  fetchDirectAccessesSuccess,
  fetchDirectAccessesError,
  fetchDirectAccesses
}
