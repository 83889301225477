// import axios from 'axios'
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  getRefundStatusStart,
  getRefundStatusSuccess,
  getRefundStatusError,
  getRefundStatusClear,
  discardTicketRefundStart,
  discardTicketRefundSuccess,
  discardTicketRefundError,
  discardTicketRefundClear
} = createActions({
  GET_REFUND_STATUS_START: () => {},
  GET_REFUND_STATUS_SUCCESS: data => ({ data }),
  GET_REFUND_STATUS_ERROR: error => ({ error }),
  GET_REFUND_STATUS_CLEAR: () => {},
  DISCARD_TICKET_REFUND_START: () => {},
  DISCARD_TICKET_REFUND_SUCCESS: data => ({ data }),
  DISCARD_TICKET_REFUND_ERROR: error => ({ error }),
  DISCARD_TICKET_REFUND_CLEAR: () => {}
})

const getRefundStatus = ({ reservationCode, ticketNumber }) => async dispatch => {
  dispatch(getRefundStatusStart())
  try {
    const response = await api.get(apiRoutes.REFUND(reservationCode, ticketNumber))
    dispatch(getRefundStatusSuccess(response.data || true))
  } catch (error) {
    dispatch(getRefundStatusError(error.response ? error.response.data : error))
  }
}

const discardTicketRefund = ({ reservationCode, ticketNumber }) => async dispatch => {
  dispatch(discardTicketRefundStart())
  try {
    const response = await api.delete(apiRoutes.REFUND(reservationCode, ticketNumber))
    dispatch(discardTicketRefundSuccess(response.data || true))
  } catch (error) {
    dispatch(discardTicketRefundError(error.response ? error.response.data : error))
  }
}

const clearGetRefundStatus = () => getRefundStatusClear()

export {
  getRefundStatus,
  clearGetRefundStatus,
  getRefundStatusStart,
  getRefundStatusSuccess,
  getRefundStatusError,
  getRefundStatusClear,
  discardTicketRefund,
  discardTicketRefundStart,
  discardTicketRefundSuccess,
  discardTicketRefundError,
  discardTicketRefundClear
}
