import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'

const {
  corporateCredentialRecoveryStart,
  corporateCredentialRecoverySuccess,
  corporateCredentialRecoveryError,
  corporateCredentialRecoveryClear
} = createActions({
  CORPORATE_CREDENTIAL_RECOVERY_START: () => {},
  CORPORATE_CREDENTIAL_RECOVERY_SUCCESS: data => ({ data }),
  CORPORATE_CREDENTIAL_RECOVERY_ERROR: error => ({ error }),
  CORPORATE_CREDENTIAL_RECOVERY_CLEAR: () => {}
})

const recoveryCorporateCredentials = (data, toRecoverType) => async dispatch => {
  dispatch(corporateCredentialRecoveryStart())
  const endpoint =
    toRecoverType === 'password'
      ? apiRoutes.CORPORATE_PASSWORD_RESET(data)
      : apiRoutes.CORPORATE_USER_EMAIL_RESET(data)
  try {
    const response = await api.post(endpoint)
    dispatch(corporateCredentialRecoverySuccess(response.data))
  } catch (error) {
    dispatch(corporateCredentialRecoveryError(error.response ? error.response.data : error))
  }
}

export {
  corporateCredentialRecoveryStart,
  corporateCredentialRecoverySuccess,
  corporateCredentialRecoveryError,
  corporateCredentialRecoveryClear,
  recoveryCorporateCredentials
}
