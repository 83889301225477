/* eslint-disable */
import { setLSValue } from './localStorage'

export const MERCHANT_ID = 'aa_traveler'

export const isLiveEnvironnment = () => {
  return /^[A-Za-z.]*aerolineas.com.ar$/.test(window.location.hostname)
}

export const cybs_dfprofiler = merchantID => {
  if (isLiveEnvironnment()) {
    var org_id = 'k8vif92e'
  } else {
    var org_id = '1snn5n9w'
  }

  var sessionID = new Date().getTime()

  //One-Pixel Image Code
  var paragraphTM = document.createElement('p')
  let str = ''
  str =
    'background:url(https://h.online-metrix.net/fp/clear.png?org_id=' +
    org_id +
    '&session_id=' +
    merchantID +
    sessionID +
    '&m=1)'

  paragraphTM.styleSheets = str

  document.body.appendChild(paragraphTM)

  var img = document.createElement('img')

  str =
    'https://h.online-metrix.net/fp/clear.png?org_id=' +
    org_id +
    '&session_id=' +
    merchantID +
    sessionID +
    '&m=2'

  img.src = str
  img.alt = ''

  document.body.appendChild(img)

  //Flash Code
  var objectTM = document.createElement('object')

  objectTM.data =
    'https://h.online-metrix.net/fp/fp.swf?org_id=' +
    org_id +
    '&session_id=' +
    merchantID +
    sessionID

  objectTM.type = 'application/x-shockwave-flash'
  objectTM.width = '1'
  objectTM.height = '1'
  objectTM.classList.add('thm_fp')
  objectTM.style.display = 'none'
  objectTM.innerHTML = 'device fingerprint'

  var param = document.createElement('param')
  param.name = 'movie'

  param.value =
    'https://h.online-metrix.net/fp/fp.swf?org_id=' +
    org_id +
    '&session_id=' +
    merchantID +
    sessionID

  objectTM.appendChild(param)

  document.body.appendChild(objectTM)

  //JavaScript Code
  var tmscript = document.createElement('script')

  tmscript.src =
    'https://h.online-metrix.net/fp/tags.js?org_id=' +
    org_id +
    '&session_id=' +
    merchantID +
    sessionID

  tmscript.type = 'text/javascript'

  document.body.appendChild(tmscript)

  return sessionID
}

export const getDeviceFingerprintID = () => {
  const deviceFingerprintID = cybs_dfprofiler(MERCHANT_ID)
  setLSValue('deviceFingerprintID', deviceFingerprintID)
  return deviceFingerprintID
}
