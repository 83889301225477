import styled from 'styled-components'

import mediaQueries from 'components/media-queries'

export const IconContainer = styled.div`
  margin-right: 10px;
  align-items: center;
  display: flex;

  ${mediaQueries.mobile`
    align-items: flex-start;
  `} svg {
    vertical-align: bottom;

    path {
      fill: ${({ theme }) => theme.colors.blue};
    }
  }
`

export const LinkContainer = styled.span`
  /**
   * Works around injected __html
   */

  & a {
    ${({ underline }) => (underline ? 'text-decoration: underline' : 'text-decoration: none')};
  }

  & a:hover {
    color: ${({ theme, color }) => color || theme.colors.blueDark};
  }
`

export const LabelsContainer = styled.div``

export const MessageContainer = styled.div`
  display: flex;
  padding: 8px;

  ${mediaQueries.tablet`
    font-size: 12px;
  `}
`
