/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api, { apiSublos } from 'services/api'

const {
  fetchPassengerDataStart,
  fetchPassengerDataSuccess,
  fetchPassengerDataError,
  clearPassengerDataError
} = createActions({
  FETCH_PASSENGER_DATA_START: () => {},
  FETCH_PASSENGER_DATA_SUCCESS: passengerData => passengerData,
  FETCH_PASSENGER_DATA_ERROR: error => error,
  CLEAR_PASSENGER_DATA_ERROR: () => {}
})

const fetchPassengerData = params => {
  return async dispatch => {
    dispatch(fetchPassengerDataStart())

    try {
      const passengerData = await api.get(`${apiRoutes.PASSENGER_DATA_RULES}${params}`)
      dispatch(fetchPassengerDataSuccess(passengerData))
    } catch (error) {
      dispatch(fetchPassengerDataError(error))
    }
  }
}

const fetchSublosPassengerData = params => {
  return async dispatch => {
    dispatch(fetchPassengerDataStart())

    try {
      const passengerData = await apiSublos.get(`${apiRoutes.PASSENGER_DATA_RULES}${params}`)
      dispatch(fetchPassengerDataSuccess(passengerData))
    } catch (error) {
      dispatch(fetchPassengerDataError(error))
    }
  }
}

export {
  fetchPassengerDataStart,
  fetchPassengerDataSuccess,
  fetchPassengerDataError,
  clearPassengerDataError,
  fetchPassengerData,
  fetchSublosPassengerData
}
