import axios from 'axios'

import CONFIG from 'config'
import { sublosProtectedRoutes } from 'constants/sublos/routes'
import { getSublosToken } from 'helpers/sublos/auth'
import { getShoppingIdFromLogin } from 'helpers/sublos/userData'
import { negotiateToken } from 'server/services/auth'

import { specialErrorTreatment, setLanguageHeaders, retryAdapterEnhancer } from './index'

const apiSublos = axios.create({
  baseURL: CONFIG.API_BASE_URL,
  adapter: retryAdapterEnhancer(axios.defaults.adapter, { delay: 2000, times: 3 }),
  headers: {
    common: {
      Authorization: `Bearer ${getSublosToken()}`,
      'X-Channel-Id': CONFIG.DEFAULT_SUBLOS_CHANNEL_ID
    },
    get: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    },
    post: {
      'Accept-Language': CONFIG.DEFAULT_LOCALE
    }
  }
})

const getToken = async input => {
  if (Object.values(sublosProtectedRoutes).includes(input)) {
    return await negotiateToken()
  }
  return getSublosToken()
}

//apiSublos instance interceptors
const setApiSublosInterceptors = initializedStore => {
  apiSublos.interceptors.request.use(async config => {
    const TOKEN = await getToken(config?.url)
    setLanguageHeaders(config)
    config.headers.Authorization = `Bearer ${TOKEN}`
    if (config.method === 'get') {
      config.params = { ...config.params, shoppingId: getShoppingIdFromLogin() }
    }
    return config
  })
  apiSublos.interceptors.response.use(
    response => response,
    async error => {
      specialErrorTreatment(initializedStore, error)

      return Promise.reject(error)
    }
  )
}

export { apiSublos as default, setApiSublosInterceptors }
