import React from 'react'

import styled, { css } from 'styled-components'

import { common as e2eClasses } from 'constants/e2eClasses'
import { toTesteableId } from 'helpers/utils'

const commonStyles = css`
  font-size: 14px;
  cursor: pointer;
  color: ${({ theme, color }) => color || theme.colors.blue};
  font-family: ${({ theme, font }) => font || theme.fonts.regular};

  ${({ underline }) => underline && 'text-decoration: underline;'}

  &:hover {
    color: ${({ theme, color }) => color || theme.colors.blueDark};
  }
`

const StyledLink = styled.a.attrs(props => ({
  className: `${e2eClasses.link}${toTesteableId(props.name || props.e2eClass || '')}`
}))`
  ${commonStyles}
`
const StyledTextButton = styled.button.attrs(props => ({
  className: `${e2eClasses.link}${toTesteableId(props.name || props.e2eClass || '')}`
}))`
  border: none;
  background: none;
  ${commonStyles}
`

const Link = props =>
  props.href ? (
    <StyledLink {...props}>{props.children}</StyledLink>
  ) : (
    <StyledTextButton {...props}>{props.children}</StyledTextButton>
  )

export default Link
