import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import { modalErrors } from 'actions/arplus/errorsArplus'

const {
  evaluateMemberEligibilityStart,
  evaluateMemberEligibilitySuccess,
  evaluateMemberEligibilityError,
  clearEvaluateMemberEligibilityState
} = createActions({
  EVALUATE_MEMBER_ELIGIBILITY_START: () => {},
  EVALUATE_MEMBER_ELIGIBILITY_SUCCESS: data => ({ data }),
  EVALUATE_MEMBER_ELIGIBILITY_ERROR: error => ({ error }),
  CLEAR_EVALUATE_MEMBER_ELIGIBILITY_STATE: () => {}
})

const fetchEvaluateMemberEligibility = (values, operationType) => async dispatch => {
  dispatch(evaluateMemberEligibilityStart())
  try {
    const response = await api.post(`${apiRoutes.EVALUATE_MEMBER_ELIGIBILITY_AR_PLUS}`, {
      ...values,
      operationType: operationType
    })
    dispatch(evaluateMemberEligibilitySuccess(response.data))
  } catch (error) {
    const err = error.response ? error.response.data : error

    if (err?.statusCode === 401) {
      const data = {
        show: true,
        redirection: '/',
        message: 'general.session-expired.description'
      }
      dispatch(modalErrors(data))
      dispatch(clearEvaluateMemberEligibilityState())
    }

    dispatch(evaluateMemberEligibilityError(err))
  }
}

export {
  fetchEvaluateMemberEligibility,
  evaluateMemberEligibilityStart,
  evaluateMemberEligibilitySuccess,
  evaluateMemberEligibilityError,
  clearEvaluateMemberEligibilityState
}
