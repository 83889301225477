import { createSelector } from 'reselect'

const getState = state => state.fareRules

export const getFareRulesData = createSelector([getState], state => (state ? state.data : null))

export const getFareRulesError = createSelector([getState], state => state.error)

export const isFetchingFareRules = createSelector([getState], state =>
  state ? state.isFetching : false
)
