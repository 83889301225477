import { createSelector } from 'reselect'

import { ERROR_TYPE } from 'constants/sublos/errors'
import { mapRequestError } from 'helpers/sublos/mapper-error'
import { isEmpty } from 'helpers/utils'

export const getChargesSubstate = state => state.charges

export const getChargesData = state => state.charges.data

export const isFetchingCharges = createSelector(
  [getChargesSubstate, getChargesData],
  (state, data) => state.isFetching || isEmpty(data)
)

export const getCharges = createSelector([getChargesData], charges =>
  charges ? charges.resources : []
)

export const getTaxesDescription = createSelector([getChargesData], chargesData => {
  if (chargesData && chargesData.resources) {
    return chargesData.resources.map(tax => {
      return { code: tax.code, description: tax.description }
    })
  }

  return []
})

export const isErrorSublosChargesFlightSelection = createSelector(
  [getChargesSubstate],
  ({ error }) => error && mapRequestError(error, ERROR_TYPE.SCREEN)
)
