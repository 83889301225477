import { handleActions } from 'redux-actions'

import {
  fetchPaymentOptionsStart,
  fetchPaymentOptionsSuccess,
  fetchPaymentOptionsError,
  clearPaymentOptionsError,
  clearPaymentOptions
} from 'actions/paymentOptions'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchPaymentOptionsStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchPaymentOptionsSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [fetchPaymentOptionsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    }),
    [clearPaymentOptionsError]: state => ({
      ...state,
      error: null
    }),
    [clearPaymentOptions]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
