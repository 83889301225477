import { handleActions } from 'redux-actions'
import {
  milesAmountStart,
  milesAmountSuccess,
  milesAmountError,
  clearMilesAmountState
} from 'actions/arplus/milesAmount'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const milesAmount = handleActions(
  {
    [milesAmountStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [milesAmountSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [milesAmountError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    }),
    [clearMilesAmountState]: () => ({ ...defaultState })
  },
  defaultState
)

export default milesAmount
