import { handleActions } from 'redux-actions'
import {
  fetchHoldBookingOptionsStart,
  fetchHoldBookingOptionsSuccess,
  fetchHoldBookingOptionsError,
  setSelectedHoldBookingId,
  resetSelectedHoldBookingId,
  clearHoldBookingOptions
} from 'actions/holdBookingOptions'
import { HOLD_OPTION_IDS } from '../constants/payment'

const defaultState = {
  data: null,
  error: null,
  isFetching: false,
  selectedHoldBookingId: HOLD_OPTION_IDS.BOOK_NOW_PAY_NOW
}

const reducer = handleActions(
  {
    [fetchHoldBookingOptionsStart]: () => ({
      ...defaultState,
      isFetching: true
    }),
    [fetchHoldBookingOptionsSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data.data,
      error: null,
      isFetching: false
    }),
    [fetchHoldBookingOptionsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    }),
    [clearHoldBookingOptions]: () => ({ ...defaultState }),
    [setSelectedHoldBookingId]: (state, { payload: { id } }) => ({
      ...state,
      selectedHoldBookingId: id
    }),
    [resetSelectedHoldBookingId]: state => ({
      ...state,
      selectedHoldBookingId: defaultState.selectedHoldBookingId
    })
  },
  defaultState
)

export default reducer
