import { mapGlobalPaymentReturnUrl } from './payment'

const mapDocumentArrayWithPossiblyEmptyDocType = documents =>
  documents.map(doc => ({
    documentType: doc.documentType || 'P',
    documentNumber: doc.documentNumber
  }))

export const mapIssueReservation = (data, reservationCode) => {
  const { holderName, documents, ...rest } = data
  const payload = {
    ...rest,
    documents: mapDocumentArrayWithPossiblyEmptyDocType(documents),
    reservationCode,
    creditCard: { holderName },
    ...mapGlobalPaymentReturnUrl({ is3POnlinePayment: true })
  }
  return payload
}
