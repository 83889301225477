import { createSelector } from 'reselect'
import COMPLEMENTARY_DATA from '../constants/complementaryData'

const getPassengerDataRulesState = state => state.passengerDataRules

export const getPassengerDataRules = createSelector([getPassengerDataRulesState], state =>
  state ? state.data : null
)

export const isFetchingPassengerDataRules = createSelector([getPassengerDataRulesState], state =>
  state ? state.isFetching : false
)

export const getPassengerDataRulesError = createSelector([getPassengerDataRulesState], state =>
  state ? state.error : null
)

export const getAdvancedPassengerInformations = createSelector([getPassengerDataRules], dataRules =>
  dataRules ? dataRules.advancedPassengerInformation : []
)

export const askAdvancedPassengerInformationPCTC = createSelector(
  [getAdvancedPassengerInformations],
  advancedPassengerInformation =>
    (advancedPassengerInformation || []).some(item => item.code === COMPLEMENTARY_DATA.PCTC)
)

export const askAdvancedPassengerInformationDOCA = createSelector(
  [getAdvancedPassengerInformations],
  advancedPassengerInformation =>
    (advancedPassengerInformation || []).some(item => item.code === COMPLEMENTARY_DATA.DOCA)
)
