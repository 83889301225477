import { css } from 'styled-components'

export const ReactPhoneNumberInputCss = css`
  :root {
    --PhoneInputCountryFlag-borderWidth: 0px !important;
    --PhoneInputCountrySelect-marginRight: 0.35em !important;
    --PhoneInputCountrySelectArrow-borderWidth: 2px !important;
    --PhoneInputCountrySelectArrow-width: 0.5em !important;
  }
`
