/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { fetchSteps, changeCurrentStep, setSteps } from 'actions/stepBarAction'
import Icon, { IconNames } from 'components/icons'
import STEP_BAR from 'constants/checkoutStepBar'
import {
  isCheckoutRetryGlobalRoute,
  isCheckoutRoute,
  isExternalPaymentRoute,
  isExchangesRoute,
  isPassengersMissingInfoRoute
} from 'helpers/routeMatchers'
import { generateCheckoutStepURL, getShoppingId } from 'helpers/url/checkout'
import { isDesktopOrWide, isEmpty } from 'helpers/utils'
import NAMESPACES from 'localization/constants/namespaces'
import { getTotalAmount } from 'selectors/flightSummary'
import { mapMediaType } from 'selectors/mediaType'
import { getPreferenceId, isSuccessfulPurchase } from 'selectors/purchase'

// eslint-disable-next-line import/no-unresolved
import exchangesSteps from './mock_exchanges'
import { Wrapper, Step, IconContainer, Label, Line, StepWrapper } from './styled'

const StepBar = props => {
  const { i18n, t } = useTranslation(NAMESPACES.CHECKOUT)

  const isCheckout = () => {
    // needs muted location
    return isCheckoutRoute(props.history.location)
  }

  const isExternalPayment = () => {
    // needs muted location
    return isExternalPaymentRoute(props.history.location)
  }

  const isPassengersMissingInfo = () => isPassengersMissingInfoRoute(props.history.location)

  useEffect(() => {
    if (isEmpty(props.steps)) {
      const nextLocation = props.history.location
      if (isCheckout() || isPassengersMissingInfo()) {
        props.fetchSteps()
      } else if (isExchangesRoute(nextLocation)) {
        props.setSteps(exchangesSteps)
      } else if (isExternalPayment()) {
        //TODO: VALIDATE PAYMENT-FLOW
        props.fetchSteps()
      }
    }
  }, [props.steps])

  const getSteps = () => {
    let active = true

    let steps = props.steps

    if (!props.purchaseFailed) {
      steps = props.steps.filter(step => step.key !== STEP_BAR.PASSENGERS)
    }

    if (props.totalAmount === 0) {
      steps = props.steps.filter(step => step.key !== STEP_BAR.PAYMENT)
    } else {
      steps = props.steps.filter(step => step.key !== STEP_BAR.NEW_ITINERARY)
    }

    return steps.map((item, index) => {
      if (steps[index - 1] && steps[index - 1].key === props.currentStep.selectedStep) {
        active = false
      }

      return (
        <StepWrapper disabled={props.disable || props.preferenceId} key={index}>
          <Step
            disabled={props.disable || props.preferenceId}
            onClick={() => changeSelectedStep(item.key, index)}
          >
            <IconContainer isActive={active}>
              <Icon name={IconNames.Airplane} size={24} />
            </IconContainer>
            {isDesktopOrWide(props.mediaType) && <Label isActive={active}>{t(item.label)}</Label>}
            {index !== 0 && <Line isActive={active} />}
          </Step>
        </StepWrapper>
      )
    })
  }

  const isLastStep = position => position + 1 === (props.steps || []).length

  const changeSelectedStep = (key, position) => {
    if (isCheckoutRetryGlobalRoute(props.location)) {
      // dont allow stepbar click while on this route
      return
    }

    const { currentStep } = props

    const clickedOnLastStep = isLastStep(position)
    const currentlyOnLastStep = isLastStep(currentStep.position)

    if (position < currentStep.position && !clickedOnLastStep && !currentlyOnLastStep) {
      if (isCheckout()) {
        props.history.replace(
          generateCheckoutStepURL({ shoppingId: getShoppingId(props.history) }, key, i18n.language)
        )
      } else {
        props.changeCurrentStep(key, position)
      }
    }
  }

  if (!props.isFetching && props.steps) {
    return <Wrapper>{getSteps()}</Wrapper>
  }

  return null
}

const mapStateToProps = state => {
  const {
    steps: { data, isFetching, currentStep, disable }
  } = state

  return {
    isFetching: isFetching,
    steps: data,
    disable: disable,
    currentStep,
    mediaType: mapMediaType(state),
    purchaseFailed: !isSuccessfulPurchase(state),
    totalAmount: getTotalAmount(state),
    preferenceId: getPreferenceId(state)
  }
}

const mapDispatchToProps = {
  fetchSteps,
  changeCurrentStep,
  setSteps
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StepBar))
