import React from 'react'
import styled from 'styled-components'

import animation from 'animations/transition.json'
import AnimationLoader from '../animation-loader'

export const ChunkLoaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height || '500px'};
`

const ChunkLoader = props => (
  <ChunkLoaderWrapper {...props}>
    <AnimationLoader animationData={animation} fixedHeight={150} />
  </ChunkLoaderWrapper>
)

export default ChunkLoader
