import { handleActions } from 'redux-actions'
import { fetchHeaderStart, fetchHeaderSuccess, fetchHeaderError } from 'actions/cms/header'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchHeaderStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchHeaderSuccess]: (
      state,
      {
        payload: {
          data: { data }
        }
      }
    ) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [fetchHeaderError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    })
  },
  defaultState
)

export default reducer
