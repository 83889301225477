import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'

const {
  sendPassengersDataStart,
  sendPassengersDataSuccess,
  sendPassengersDataError,
  resetPassengersDataError,
  fetchPassengersValidationsStart,
  fetchPassengersValidationsSuccess,
  fetchPassengersValidationsError,
  fetchPassengersDataStart,
  fetchPassengersDataSuccess,
  fetchPassengersDataError,
  resetPassengersData
} = createActions({
  SEND_PASSENGERS_DATA_START: () => {},
  SEND_PASSENGERS_DATA_SUCCESS: response => ({ response }),
  SEND_PASSENGERS_DATA_ERROR: error => ({ error }),
  RESET_PASSENGERS_DATA_ERROR: error => ({ error }),
  FETCH_PASSENGERS_VALIDATIONS_START: () => {},
  FETCH_PASSENGERS_VALIDATIONS_SUCCESS: response => ({ response }),
  FETCH_PASSENGERS_VALIDATIONS_ERROR: error => ({ error }),
  FETCH_PASSENGERS_DATA_START: () => {},
  FETCH_PASSENGERS_DATA_SUCCESS: response => ({ response }),
  FETCH_PASSENGERS_DATA_ERROR: error => ({ error }),
  RESET_PASSENGERS_DATA: () => {}
})

const fetchPassengersData = shoppingId => {
  return async dispatch => {
    dispatch(fetchPassengersDataStart())

    try {
      const response = await api.get(`${apiRoutes.PASSENGERS}?shoppingId=${shoppingId}`)
      dispatch(fetchPassengersDataSuccess(response.data))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(fetchPassengersDataError(err))
    }
  }
}

const sendPassengersData = passengersData => {
  return async dispatch => {
    dispatch(sendPassengersDataStart())

    try {
      const response = await api.post(apiRoutes.PASSENGERS, passengersData)

      dispatch(sendPassengersDataSuccess(response.data))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(sendPassengersDataError(err))
    }
  }
}

const fetchPassengersValidations = () => {
  return async dispatch => {
    dispatch(fetchPassengersValidationsStart())

    try {
      const response = await api.get(`${apiRoutes.PASSENGERS_TYPE}`)
      dispatch(fetchPassengersValidationsSuccess(response.data.resources))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(fetchPassengersValidationsError(err))
    }
  }
}

export {
  sendPassengersData,
  sendPassengersDataStart,
  sendPassengersDataSuccess,
  sendPassengersDataError,
  resetPassengersDataError,
  fetchPassengersValidationsStart,
  fetchPassengersValidationsSuccess,
  fetchPassengersValidationsError,
  fetchPassengersValidations,
  fetchPassengersData,
  fetchPassengersDataStart,
  fetchPassengersDataSuccess,
  fetchPassengersDataError,
  resetPassengersData
}
