import { createActions } from 'redux-actions'
import apiRoutes from 'constants/apiRoutes'
import axios from 'axios'
import CONFIG from 'config'
import { getLSValue } from 'helpers/localStorage'

const {
  updateAccountDataStart,
  updateAccountDataSuccess,
  updateAccountDataError,
  clearUpdateAccountDataState
} = createActions({
  UPDATE_ACCOUNT_DATA_START: () => {},
  UPDATE_ACCOUNT_DATA_SUCCESS: data => ({ data }),
  UPDATE_ACCOUNT_DATA_ERROR: error => ({ error }),
  CLEAR_UPDATE_ACCOUNT_DATA_STATE: () => {}
})

const updateAccountData = ({ membershipNumber, newMemberData }) => async dispatch => {
  dispatch(updateAccountDataStart())
  try {
    const { MEMBERS_AR_PLUS, CONTACT_INFORMATION } = apiRoutes

    const response = await axios.put(
      `${MEMBERS_AR_PLUS}/${membershipNumber}${CONTACT_INFORMATION}`,
      newMemberData,
      {
        baseURL: CONFIG.API_BASE_URL,
        headers: {
          Authorization: `Bearer ${getLSValue('access_token')}`,
          'X-Channel-Id': CONFIG.DEFAULT_CHANNEL_ID,
          'Accept-Language': CONFIG.DEFAULT_LOCALE
        }
      }
    )

    dispatch(updateAccountDataSuccess(response.data))
  } catch (error) {
    dispatch(updateAccountDataError(error.response ? error.response.data : error))
  }
}

export {
  updateAccountData,
  updateAccountDataStart,
  updateAccountDataSuccess,
  updateAccountDataError,
  clearUpdateAccountDataState
}
