/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect'

import GDS_MESSAGES from 'constants/gdsMessages'
import { isEmpty } from 'helpers/utils'

const getAncillariesPurchaseSubstate = state => state.ancillariesPurchase

export const getAncillariesPurchaseData = createSelector([getAncillariesPurchaseSubstate], state =>
  state ? state.data : null
)

export const getAncillariesPurchaseError = createSelector([getAncillariesPurchaseSubstate], state =>
  state ? state.error : null
)

export const isPostingAncillariesPayment = createSelector([getAncillariesPurchaseSubstate], state =>
  state ? state.isFetching : false
)

export const isPaymentSuccess = createSelector([getAncillariesPurchaseData], state =>
  state ? state.success : false
)

export const getAncillariesPurchaseValidationDetails = createSelector(
  [getAncillariesPurchaseError],
  error => (error && !isEmpty(error.validationDetails) ? error.validationDetails : null)
)

export const getAncillariesPurchaseGeneralRequestError = createSelector(
  [getAncillariesPurchaseValidationDetails],
  errorDetail =>
    errorDetail && !isEmpty(errorDetail.GENERAL_REQUEST) ? errorDetail.GENERAL_REQUEST : null
)

export const getAncillariesPurchaseErrorCode = createSelector(
  [getAncillariesPurchaseError],
  error => error && error.statusCode
)

export const getAncillariesPurchaseErrorMessage = createSelector(
  [getAncillariesPurchaseError],
  error => error && error.errorMessage
)

const getAncillariesPurchaseAuthorizationFields = createSelector(
  [getAncillariesPurchaseData],
  data => data && data.authorization
)

export const isAncillariesFailedAuthorization = createSelector(
  [getAncillariesPurchaseAuthorizationFields],
  data => data && data.details === GDS_MESSAGES.PAYMENT_ERROR_AUTH_FAILURE
)

export const isAncillariesInvalidCardNumberError = createSelector(
  [getAncillariesPurchaseErrorMessage],
  errorMessage => errorMessage && errorMessage === GDS_MESSAGES.PAYMENT_ERROR_INVALID_CARD
)

export const isAncillariesPurchaseSessionExpired = createSelector(
  [getAncillariesPurchaseErrorMessage],
  errorMessage => errorMessage && errorMessage === GDS_MESSAGES.BOOKING_SESSION_EXPIRED
)

export const isAncillariesWrongAmountError = createSelector(
  [getAncillariesPurchaseErrorMessage],
  errorMessage => errorMessage && errorMessage === GDS_MESSAGES.PAYMENT_ERROR_WRONG_AMOUNT
)

export const getAncillariesCreditCardAuthError = createSelector(
  [
    isAncillariesFailedAuthorization,
    getAncillariesPurchaseErrorMessage,
    isAncillariesInvalidCardNumberError,
    isAncillariesWrongAmountError
  ],
  (
    isAncillariesFailedAuthorization,
    ancillariesPurchaseErrorMessage,
    ancillariesInvalidCardError,
    isAncillariesWrongAmountError
  ) => {
    if (isAncillariesFailedAuthorization || isAncillariesWrongAmountError) {
      return 'general.payment-data.authorization-failure'
    }

    if (ancillariesInvalidCardError) {
      return ancillariesPurchaseErrorMessage
    }
  }
)

const getPurchasePayments = createSelector([getAncillariesPurchaseData], data =>
  data ? data.payments : null
)

export const getPurchasePayment = createSelector([getPurchasePayments], payments =>
  payments ? payments[0] : null
)

export const getPurchaseGlobalDetails = createSelector([getPurchasePayment], payment =>
  payment ? payment.globalInformation : null
)

export const getRedirectForm = createSelector(
  [getPurchaseGlobalDetails],
  globalDetails => globalDetails && globalDetails.redirectForm
)

export const getRedirectType = createSelector(
  [getPurchaseGlobalDetails],
  globalDetails => globalDetails && globalDetails.redirectType
)
