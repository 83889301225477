import React, { useState } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Icon, { IconNames } from 'components/icons'
import theme from 'components/theme'
import { isDesktopOrWide } from 'helpers/utils'
import { mapMediaType } from 'selectors/mediaType'

import AerolineasIcon from '../assets/icon-aerolineas.svg'
import { Container, Title, LinksContainer, Link, IconContainer, IconImagen } from './styled'

const FooterInstitutional = ({ label, links, mediaType }) => {
  const [isExpanded, toggleExpanded] = useState(false)

  const onLinkClick = link => window.open(link, '_blank')

  return (
    <Container>
      <Title
        onClick={() => toggleExpanded(isExp => !isExp)}
        as={isDesktopOrWide(mediaType) ? 'div' : 'button'}
      >
        <IconImagen src={AerolineasIcon} alt="" />
        {label}
        <IconContainer>
          <Icon
            name={isExpanded ? IconNames.ChevronUp : IconNames.ChevronDown}
            size={23}
            color={theme.colors.blue}
          />
        </IconContainer>
      </Title>
      <LinksContainer isExpanded={isExpanded}>
        {(links || []).map(({ name, link }, index) => (
          <Link
            key={index}
            onClick={() => onLinkClick(link)}
            tabIndex={isExpanded || isDesktopOrWide(mediaType) ? 0 : -1}
          >
            {name}
          </Link>
        ))}
      </LinksContainer>
    </Container>
  )
}

const mapStateToProps = state => ({
  mediaType: mapMediaType(state)
})

export default withRouter(connect(mapStateToProps)(FooterInstitutional))
