import React from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'components/button'
import Icon, { IconNames } from 'components/icons'
import theme from 'components/theme'
import e2eClasses from 'helpers/automation'

import { SubscribeTitle, SubscribeMessageWrapper, ButtonWrapper } from './styled'

const FooterNewsletter = () => {
  const { t } = useTranslation()

  return (
    <SubscribeMessageWrapper>
      <Icon
        name={IconNames.Email}
        size={28}
        color={theme.colors.white}
        e2eClass={e2eClasses('footer.newsLetter.subscribeIcon')}
      />
      <SubscribeTitle>{t('general.footer.newsletter.suscribe-to-get-our-promos')}</SubscribeTitle>
      <ButtonWrapper>
        <Button
          e2eClass={e2eClasses('footer.newsLetter.subscribeButton')}
          white
          secondary
          padding="0"
          as="a"
          href={t('general.footer.newsletter.suscribe-me.url')}
          target="_blank"
        >
          {t('general.footer.newsletter.suscribe-me.text')}
        </Button>
      </ButtonWrapper>
    </SubscribeMessageWrapper>
  )
}

export default FooterNewsletter
