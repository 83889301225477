import { batch } from 'react-redux'

import { resetCountries } from 'actions/catalog/countries'
import { documentTypesClear } from 'actions/catalog/documentTypes'
import { clearDocumentTypesRulesError } from 'actions/rules/checkout/documentTypes'
import { clearPassengerDataError } from 'actions/rules/checkout/passengerData'
import { setSublosError } from 'actions/sublos/errors'
import { validateSublosPassengersDocumentationClear } from 'actions/sublos/passengersDocumentation'
import { RESET_SERVICES } from 'constants/sublos/errors'
import { STEPS } from 'constants/sublos/flow'
import { mapperError } from 'helpers/sublos/mapper-error'

export const passengersDocumentationThunk = error => async dispatch => {
  batch(() => {
    error && dispatch(setSublosError(mapperError(error, STEPS.PASSENGERS_DOCUMENTATION)))
  })
}

export const clearPassengerDocumentationStateThunk = resetServices => async (
  dispatch,
  getState
) => {
  const state = getState()
  const isResetAll = resetServices === RESET_SERVICES.RESET_ALL_SERVICES
  const isResetCore = resetServices === RESET_SERVICES.RESET_CORE_SERVICES
  const {
    countries,
    documentTypes,
    documentTypesRules,
    passengerDataRules,
    sublosPassengersDocumentation
  } = state

  batch(() => {
    ;(countries.error || isResetAll) && dispatch(resetCountries())
    ;(documentTypes.error || isResetAll) && dispatch(documentTypesClear())
    ;(documentTypesRules.error || isResetAll) && dispatch(clearDocumentTypesRulesError())
    ;(passengerDataRules.error || isResetAll) && dispatch(clearPassengerDataError())
    ;(sublosPassengersDocumentation.error || isResetAll || isResetCore) &&
      dispatch(validateSublosPassengersDocumentationClear())
  })
}
