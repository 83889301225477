import styled from 'styled-components'

import { PageBlock } from 'components/layout'
import mediaQueries from 'components/media-queries'

export const StyledPageBlock = styled(PageBlock)`
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.alabaster};

  ${({ modalOpen }) =>
    modalOpen &&
    `
    height: 0;
    overflow: hidden;
  `}

  ${({ isFlexDateCalendarScreen }) =>
    isFlexDateCalendarScreen &&
    `
    display: none;
  `}

  ${mediaQueries.tablet`
    margin-top: 50px;
    margin-bottom: ${({ isFlexDateCalendarScreen }) => (isFlexDateCalendarScreen ? `110px` : `0`)};
  `};

  margin-bottom: 0};
`

export const WithoutPaddingStyledPageBlock = styled(PageBlock)`
  overflow: hidden;
`

export const Row = styled.div`
  display: grid;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  grid-template-columns: repeat(2, 1fr);

  ${mediaQueries.tablet`
    flex-direction: column;
  `};
  ${mediaQueries.mobile`
    grid-template-columns: repeat(1,1fr);
  `};
`

export const Column = styled.div`
  flex: 1;
  display: flex;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 100%;
  flex-direction: column;
  position: relative;
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.veryLightPink}`};
  z-index: 1;
`

export const ExpandButton = styled.button`
  display: flex;
  background: none;
  border: none;
  align-items: center;
  justify-content: center;
  border-top: ${({ theme }) => `2px solid ${theme.colors.veryLightPink}`};
  border-left: ${({ theme }) => `2px solid ${theme.colors.veryLightPink}`};
  border-right: ${({ theme }) => `2px solid ${theme.colors.veryLightPink}`};
  border-radius: 3px;
  padding: 4.8px 32px 4.8px 32px;
  background-color: ${({ theme, footerExpanded }) =>
    footerExpanded ? theme.colors.alabaster : theme.colors.white};
  position: absolute;
  cursor: pointer;
  bottom: -5px;
`

export const WhiteBox = styled.div`
  background-color: ${({ theme, footerExpanded }) =>
    footerExpanded ? theme.colors.alabaster : theme.colors.white};
  position: absolute;
  width: 100%;
  padding-top: 6.4px;
  bottom: -8px;
`

export const MoreInfoText = styled.div`
  color: ${({ theme }) => theme.colors.doveGray};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 14px;
  margin-right: 5px;
`

export const FooterDetailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.alabaster};
  height: 100px;
  ${({ theme, footerExpanded }) =>
    footerExpanded
      ? `border-bottom: 2px solid ${theme.colors.veryLightPink};`
      : null} display: flex;

  ${mediaQueries.tablet`
    flex-direction: column;
    height: auto;
  `};
`

export const BrandsImage = styled.img`
  width: 100%;
  margin-bottom: 10px;
  max-height: 55px;
  max-width: 55px;
  margin: 3px;
`

export const BrandsImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;

  ${mediaQueries.tablet`
    width: 100%;
    height: 80px;
  `};

  ${mediaQueries.tablet`
    padding-top: 24px;
    height: auto;
  `};
`

export const CallCenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 30%;

  ${mediaQueries.tablet`
    max-width: 100%;
    width: 100%;
    height: 80px;
    margin-top: 20px;
  `};

  ${mediaQueries.tablet`
    height: auto;
  `};
`

export const CallCenterText = styled.div`
  color: ${({ theme }) => theme.colors.doveGray};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 14px;
`
export const FooterSpace = styled.div`
  height: 72px;

  ${mediaQueries.tablet`
    height: 0;
  `};
`

export const Link = styled.a`
  height: 100%;
`
