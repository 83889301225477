import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'

import Icon, { IconNames } from 'components/icons'
import Message from 'components/message'

import { LabelsContainer, IconContainer, MessageContainer, LinkContainer } from './styled'

const MessageCookies = ({ onButtonClick, theme, acceptEssentialCookies, showCookiesMessage }) => {
  const { t } = useTranslation()
  const [hasAcceptedEssentialCookies, setHasAcceptedEssentialCookies] = useState(false)

  return (
    <Message
      type="cookies"
      messageButtonText={t('label.accept')}
      onMessageButtonClick={onButtonClick}
      acceptEssentialButtonText={t('label.accept-only-essential-cookies')}
      closeText={t('alt.close')}
      acceptEssentialCookies={acceptEssentialCookies}
      setHasAcceptedEssentialCookies={setHasAcceptedEssentialCookies}
      hasAcceptedEssentialCookies={hasAcceptedEssentialCookies}
      showCookiesMessage={showCookiesMessage}
      noIcon
    >
      <MessageContainer>
        {!hasAcceptedEssentialCookies ? (
          <>
            <IconContainer>
              <Icon name={IconNames.Alert} size={18} color={theme.colors.white} />
            </IconContainer>
            <LabelsContainer>
              {t('general.cookies-message.this-site')}
              <LinkContainer
                underline
                dangerouslySetInnerHTML={{ __html: t('general.cookies-message.click-here.url') }}
              />
              {t('general.cookies-message.policy')}
            </LabelsContainer>
          </>
        ) : (
          <>
            <IconContainer>
              <Icon name={IconNames.Check} size={18} color={theme.colors.white} />
            </IconContainer>
            <LabelsContainer>{t('general.cookies-message.accepted')}</LabelsContainer>
          </>
        )}
      </MessageContainer>
    </Message>
  )
}

export default withTheme(MessageCookies)
