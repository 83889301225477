import { createActions } from 'redux-actions'

import { flightSummaryToPaymentOptionsPayload } from 'api-data-io/bo/evaluation'
import apiRoutes from 'constants/apiRoutes'
import PRODUCTS from 'constants/products'
import api from 'services/api'

const {
  fetchBnplPaymentOptionsStart,
  fetchBnplPaymentOptionsSuccess,
  fetchBnplPaymentOptionsError,
  clearBnplPaymentOptionsError,
  clearBnplPaymentOptionsState
} = createActions({
  FETCH_BNPL_PAYMENT_OPTIONS_START: () => {},
  FETCH_BNPL_PAYMENT_OPTIONS_SUCCESS: data => data,
  FETCH_BNPL_PAYMENT_OPTIONS_ERROR: error => ({ error }),
  CLEAR_BNPL_PAYMENT_OPTIONS_ERROR: () => {},
  CLEAR_BNPL_PAYMENT_OPTIONS_STATE: () => {}
})

//If unifying paymentOptions, this would be moved/deleted
//BNPL was change for HOLD_TICKET to matched with BO service

const fetchBnplPaymentOptions = (flightSummaryData, holdBookingOptions) => {
  return async dispatch => {
    dispatch(fetchBnplPaymentOptionsStart())
    try {
      const paymentOptions = await Promise.all(
        holdBookingOptions.map(({ price }) => {
          const apiPayload = flightSummaryToPaymentOptionsPayload(
            flightSummaryData,
            PRODUCTS.HOLD_TICKET,
            price
          )
          return api.post(apiRoutes.PAYMENT_OPTIONS, apiPayload)
        })
      )

      const reduxPayload = {}
      paymentOptions.forEach(({ data }, index) => {
        reduxPayload[holdBookingOptions[index].optionId] = data
      })

      dispatch(fetchBnplPaymentOptionsSuccess(reduxPayload))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(fetchBnplPaymentOptionsError(err))
    }
  }
}

export {
  fetchBnplPaymentOptions,
  fetchBnplPaymentOptionsStart,
  fetchBnplPaymentOptionsSuccess,
  fetchBnplPaymentOptionsError,
  clearBnplPaymentOptionsError,
  clearBnplPaymentOptionsState
}
