import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  recoverEmailConfirmationStart,
  recoverEmailConfirmationSuccess,
  recoverEmailConfirmationError,
  clearRecoverEmailConfirmationState
} = createActions({
  RECOVER_EMAIL_CONFIRMATION_START: () => {},
  RECOVER_EMAIL_CONFIRMATION_SUCCESS: data => ({ data }),
  RECOVER_EMAIL_CONFIRMATION_ERROR: error => ({ error }),
  CLEAR_RECOVER_EMAIL_CONFIRMATION_STATE: () => {}
})

const recoverEmailConfirmation = data => async dispatch => {
  dispatch(recoverEmailConfirmationStart())
  try {
    const response = await api.post(`${apiRoutes.AR_PLUS_RECOVER_EMAIL_CONFIRMATION}`, data)
    dispatch(recoverEmailConfirmationSuccess(response.data))
  } catch (error) {
    dispatch(recoverEmailConfirmationError(error.response ? error.response.data : error))
  }
}

export {
  recoverEmailConfirmation,
  recoverEmailConfirmationStart,
  recoverEmailConfirmationSuccess,
  recoverEmailConfirmationError,
  clearRecoverEmailConfirmationState
}
