/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect'

/* eslint-disable import/no-cycle */
import GDS_MESSAGES from 'constants/gdsMessages'
import { getFlightInformationData } from 'helpers/flightConditions'
import { capitalize, isEmpty } from 'helpers/utils'
import { getAncillariesPurchaseGeneralRequestError } from 'selectors/ancillariesPurchase'
import {
  getExchangesPaymentGeneralRequestError,
  getExchangesPaymentAuthorizationErrorMessage
} from 'selectors/exchangesPayment'
import { getFareRulesData } from 'selectors/fareRules'
import { getPaymentBookingGeneralRequestError } from 'selectors/paymentBooking'

import { getPaymentStatusCreditCard } from './paymentStatus'
import { getReservation, getReservationPreferenceId } from './reservation'

const getPurchaseState = state => state.purchase

export const getPurchaseData = createSelector([getPurchaseState], state => {
  return state ? state.data : null
})
export const getPurchaseDataAuthorization = createSelector([getPurchaseData], state =>
  state ? state.authorization : null
)
export const getPurchaseDataAuthorizationDetails = createSelector(
  [getPurchaseDataAuthorization],
  state => (state ? state.details : null)
)

export const isPostingPurchase = createSelector([getPurchaseState], state =>
  state ? state.isFetching : false
)

export const getPurchaseError = createSelector([getPurchaseState], state =>
  state ? state.error : null
)

export const getPurchaseErrorCode = createSelector(
  [getPurchaseError],
  error => error && error.statusCode
)

export const getPurchaseErrorDetails = createSelector([getPurchaseError], error =>
  error ? error.validationDetails : null
)

const getPurchasePayments = createSelector([getPurchaseData], data => (data ? data.payments : null))

export const getPurchasePayment = createSelector([getPurchasePayments], payments =>
  payments ? payments[0] : null
)

export const getPurchasePaymentType = createSelector([getPurchasePayment], payment =>
  payment ? payment.paymentType : null
)

export const getPurchasePaymentCode = createSelector([getPurchasePayment], payment =>
  payment ? payment.paymentCode : null
)

export const getPurchaseAuthorizationCode = createSelector([getPurchasePayment], payment =>
  payment ? payment.authorizationCode : null
)

export const getPurchaseGlobalDetails = createSelector([getPurchasePayment], payment =>
  payment ? payment.globalInformation : null
)

export const getPurchaseExternalInformation = createSelector([getPurchasePayment], payment =>
  payment ? payment.externalInformation : null
)

const getPurchasePaymentAfopInformation = createSelector([getPurchasePayment], payment =>
  payment ? payment.afopInformation : null
)

export const gePurchasePaymentStatus = createSelector(
  [getPurchasePaymentAfopInformation],
  afopInformation => (afopInformation ? afopInformation.status : null)
)

export const getRedirectUrl = createSelector(
  [getPurchasePaymentAfopInformation, getPurchaseGlobalDetails],
  (afopInformation, globalDetails) =>
    (afopInformation && afopInformation.redirectUrl) || (globalDetails && globalDetails.redirectUrl)
)

export const getMpRedirectUrl = createSelector(
  [getPurchaseExternalInformation],
  externalInformation => externalInformation?.redirectUrl
)

export const getRedirectForm = createSelector(
  [getPurchasePaymentAfopInformation, getPurchaseGlobalDetails],
  (afopInformation, globalDetails) =>
    (afopInformation && afopInformation.redirectForm) ||
    (globalDetails && globalDetails.redirectForm)
)

export const getRedirectType = createSelector(
  [getPurchasePaymentAfopInformation, getPurchaseGlobalDetails],
  (afopInformation, globalDetails) =>
    (afopInformation && afopInformation.redirectType) ||
    (globalDetails && globalDetails.redirectType)
)

export const getPreferenceId = createSelector(
  [getPurchaseExternalInformation, getReservationPreferenceId],
  (externalInformation, reservationPreferenceId) =>
    externalInformation?.preferenceId || reservationPreferenceId
)

export const isUnticketedPurchase = createSelector([getPurchaseData], data =>
  data ? data.isUnticketed : false
)

const getPurchaseBaseMetadata = createSelector([getPurchaseData], data =>
  data ? data.baseMetadata : null
)

export const getPurchaseWarningMessages = createSelector([getPurchaseBaseMetadata], data =>
  data ? data.warnMessages : []
)

export const getPurchaseInfoMessages = createSelector([getPurchaseBaseMetadata], data =>
  data ? data.infoMessages : []
)

export const getPurchaseCode = createSelector([getPurchaseData], data =>
  data ? data.reservationCode : null
)

export const getPurchaseLastname = createSelector([getPurchaseData], data =>
  data ? data.lastName : null
)

export const getPurchaseTotalMiles = createSelector([getPurchaseData], data =>
  data ? data.totalMiles : null
)

export const getPurchaseErrorMessage = createSelector(
  [getPurchaseError],
  error => error && error.errorMessage
)

export const isPurchaseSessionExpired = createSelector(
  [getPurchaseErrorMessage],
  errorMessage => errorMessage && errorMessage === GDS_MESSAGES.BOOKING_SESSION_EXPIRED
)

export const getPurchaseDocuments = createSelector([getPurchaseData], data =>
  data && !isEmpty(data.documents) ? data.documents : null
)

export const getPurchaseBookingMetadata = createSelector([getPurchaseData], data =>
  data ? data.bookingMetadata : null
)

export const getPurchaseBookingStatus = createSelector(
  [getPurchaseBookingMetadata],
  bookingMetadata => (bookingMetadata ? bookingMetadata.bookingStatus : null)
)

export const getPurchasePcc = createSelector([getPurchaseBookingMetadata], bookingMetadata =>
  bookingMetadata ? bookingMetadata.pcc : null
)

export const getPurchaseRoutes = createSelector([getPurchaseBookingMetadata], bookingMetadata =>
  bookingMetadata ? bookingMetadata.routes : null
)

export const getPurchaseExpirationDate = createSelector(
  [getPurchaseBookingMetadata],
  bookingMetadata => (bookingMetadata ? bookingMetadata.expirationDate : null)
)

export const getPurchaseEpochExpirationDate = createSelector([getPurchasePayments], payments =>
  payments ? payments[0]?.externalInformation?.epochExpirationDate : null
)

export const isPurchaseIssued = createSelector([getPurchaseBookingMetadata], bookingMetadata =>
  bookingMetadata ? bookingMetadata.issued : false
)

export const getPurchaseFlightType = createSelector([getPurchaseBookingMetadata], bookingMetadata =>
  bookingMetadata ? bookingMetadata.flightType : null
)

export const getPurchasePNRCode = createSelector([getPurchaseBookingMetadata], bookingMetadata =>
  bookingMetadata ? bookingMetadata.reservationCode : null
)

export const getPurchaseCurrency = createSelector([getPurchaseBookingMetadata], bookingMetadata =>
  bookingMetadata ? bookingMetadata.currency : null
)

const gePurchasePriceBreakdown = createSelector([getPurchaseData], data =>
  data ? data.priceBreakdown : null
)

export const getPurchaseTotalAmount = createSelector([gePurchasePriceBreakdown], priceBreakdown =>
  priceBreakdown ? priceBreakdown.total : null
)

const getPurchaseAncillaryInformation = createSelector([getPurchaseData], data =>
  data ? data.ancillaryInformation : null
)

export const getPurchaseAncillaryPassengers = createSelector(
  [getPurchaseAncillaryInformation],
  ancillaryInfo => (ancillaryInfo ? ancillaryInfo.ancillaryPassengers : [])
)

const getPurchasePassengers = createSelector([getPurchaseData], data =>
  data ? data.passengersData : null
)

const getPurchasePassengersSegments = createSelector([getPurchaseData], data =>
  data ? data.passengerSegments : null
)

export const getPurchasePassengersWithSegments = createSelector(
  [getPurchasePassengers, getPurchasePassengersSegments],
  (passengers, passengersSegments) => {
    if (!isEmpty(passengers) && !isEmpty(passengersSegments)) {
      return passengers.map(passengerData => {
        const segments = (passengersSegments || []).map(segmentDetails => ({
          segment: segmentDetails.segment,
          details: (segmentDetails.passengerDetails || []).filter(
            passengerDetails => passengerDetails.passengerIndex === passengerData.passengerIndex
          )
        }))
        const { firstName, lastName } = passengerData

        return {
          ...passengerData,
          firstName: capitalize(firstName),
          lastName: capitalize(lastName),
          segments
        }
      })
    }

    return null
  }
)

export const getPurchaseLegsData = createSelector([getPurchaseData], data =>
  data ? data.legs : null
)

const getPurchaseContactInformation = createSelector([getPurchaseData], data =>
  data ? data.contactInformation : null
)

export const getPurchaseEmails = createSelector(
  [getPurchaseContactInformation],
  contactInformation => (contactInformation ? contactInformation.emails : null)
)

export const getPurchaseRevenue = createSelector([getPurchaseData], data =>
  data ? data.revenueData : null
)

export const getPurchaseEmail = createSelector([getPurchaseEmails], emails =>
  !isEmpty(emails) && emails[0] ? emails[0] : null
)

export const getPurchaseLastName = createSelector([getPurchasePassengers], passengers =>
  passengers && passengers[0] ? passengers[0].lastName : null
)

export const getPurchaseFaresRulesForBrand = createSelector(
  [getPurchaseLegsData, getFareRulesData, getPurchaseRoutes, getPurchaseFlightType],
  (legs, fares, routes, flightType) => getFlightInformationData(legs, routes, fares, flightType)
)

export const getPurchaseTransactionId = createSelector(
  [getPurchasePaymentAfopInformation],
  afopInformation => (afopInformation ? afopInformation.transactionId : null)
)

export const isPurchaseSegmentDelayed = createSelector(
  [getPurchaseBookingMetadata],
  bookingMetadata => (bookingMetadata ? bookingMetadata.delayed : false)
)

export const isSuccessfulPurchase = createSelector([getPurchaseData], data => data && data.success)

export const getPurchaseErrorDescription = createSelector(
  [getPurchaseError],
  error => error && error.description
)

export const isGlobalPaymentError = createSelector(
  [getPurchaseErrorDescription, getPurchaseErrorCode],
  (errorDescription, errorCode) =>
    errorCode === 400 &&
    errorDescription &&
    (errorDescription.includes(GDS_MESSAGES.GLOBAL_PAYMENT_ERROR_INVALID_CARD) ||
      errorDescription.includes(GDS_MESSAGES.ISSUE_INVALID_CARD))
)

export const getGeneralRequestError = createSelector([getPurchaseErrorDetails], errorDetail =>
  errorDetail && !isEmpty(errorDetail.GENERAL_REQUEST) ? errorDetail.GENERAL_REQUEST : null
)

export const getPaymentErrorMessages = createSelector(
  [
    getPurchaseError,
    getPaymentStatusCreditCard,
    getAncillariesPurchaseGeneralRequestError,
    getPaymentBookingGeneralRequestError,
    getExchangesPaymentGeneralRequestError,
    getExchangesPaymentAuthorizationErrorMessage,
    getGeneralRequestError,
    getPurchaseDataAuthorizationDetails
  ],
  (
    purchase,
    creditCard,
    ancilliaries,
    paymentBooking,
    exchanges,
    exchangesAuthError,
    generalError,
    authorizationDetails
  ) => {
    if (purchase && purchase.cardNumber) {
      return purchase.cardNumber // Returns purchase.cardNumber if it exists
    } else if ((purchase?.description || '').length > 0) {
      return typeof purchase.description === 'object'
        ? purchase.description[0]
        : purchase.description
    } else if ((purchase?.errorMessage || '').length > 0) {
      return purchase.errorMessage
    } else if (authorizationDetails) {
      return authorizationDetails
    } else {
      return (
        creditCard ||
        ancilliaries ||
        paymentBooking ||
        exchanges ||
        generalError ||
        exchangesAuthError
      )
    }
  }
)

export const shouldShowPurchaseLoader = createSelector(
  [isPostingPurchase, getPurchaseData, getPaymentErrorMessages, getReservation, getPreferenceId],
  (isFetching, data, paymentErrorMessages, reservation, preferenceId) =>
    isFetching ||
    reservation.isFetching ||
    (!paymentErrorMessages &&
      !preferenceId &&
      data &&
      Object.entries(data).length > 0 &&
      data.authorization?.messageCode === 'SUCCESS')
      ? true
      : false
)

const getGlobalPaymentReservationCode = createSelector(
  [getPurchaseError],
  error => error && error.reservationCode
)

export const getGlobalPNRPaymentRetrial = createSelector(
  [isGlobalPaymentError, getGlobalPaymentReservationCode],
  (globalPaymentError, retrialReservationCode) => globalPaymentError && retrialReservationCode
)

export const isInvalidCardNumberError = createSelector(
  [getPurchaseErrorMessage],
  errorMessage => errorMessage && errorMessage === GDS_MESSAGES.PAYMENT_ERROR_INVALID_CARD
)

export const isValidationError = createSelector(
  [getPurchaseErrorMessage],
  errorMessage => errorMessage && errorMessage === GDS_MESSAGES.VALIDATION_ERROR
)

const getPurchaseAuthorizationFields = createSelector(
  [getPurchaseData],
  data => data && data.authorization
)

export const isFailedAuthorization = createSelector(
  [getPurchaseAuthorizationFields],
  data => data && data.details === GDS_MESSAGES.PAYMENT_ERROR_AUTH_FAILURE
)

export const getPurchaseValidationError = createSelector(
  [isValidationError, getPurchaseErrorDescription],
  (validationError, errorDescription) => validationError && errorDescription && errorDescription[0]
)

export const isWrongAmountError = createSelector(
  [getPurchaseErrorMessage],
  errorMessage => errorMessage && errorMessage === GDS_MESSAGES.PAYMENT_ERROR_WRONG_AMOUNT
)

export const getCreditCardAuthError = createSelector(
  [
    isFailedAuthorization,
    getPurchaseErrorMessage,
    isGlobalPaymentError,
    isInvalidCardNumberError,
    isWrongAmountError
  ],
  (
    isFailedAuthorization,
    purchaseErrorMessage,
    globalPaymentError,
    invalidCardError,
    isWrongAmountError
  ) => {
    if (globalPaymentError || isFailedAuthorization || isWrongAmountError) {
      return 'general.payment-data.authorization-failure'
    }

    if (invalidCardError) {
      return purchaseErrorMessage
    }
  }
)

export const getSuccessfulPurchaseData = createSelector(
  [isSuccessfulPurchase, getPurchaseData],
  (success, data) => success && data
)

export const getPurchaseGlobal3PPaymentReservationCode = createSelector([getPurchaseData], data =>
  data ? data.reservationCode : ''
)
