import { handleActions } from 'redux-actions'
import {
  creditMilesDestinationStart,
  creditMilesDestinationSuccess,
  creditMilesDestinationError,
  clearCreditMilesDestination
} from 'actions/arplus/creditMilesDestination'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const creditMilesDestination = handleActions(
  {
    [creditMilesDestinationStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [creditMilesDestinationSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [creditMilesDestinationError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    }),
    [clearCreditMilesDestination]: () => ({ ...defaultState })
  },
  defaultState
)

export default creditMilesDestination
