export default {
  ALERTS: '/api/alerts',
  AR_PLUS_DATA: '/api/ar_plus',
  BANKS: '/api/bank_financing',
  BEST_OFFERS: '/api/best_offers',
  CATEGORIES: '/api/help_categories',
  CONTESTS_CMS_LEGAL_PDF: `/api/contests`,
  DARK_SITE: '/api/dark_site',
  DIRECT_ACCESSES: '/api/direct_accesses',
  FOOTER: '/api/footer_content',
  HEADER: '/api/header',
  HOME_INFO: '/api/home_info_sections',
  LANDINGS: '/api/landing_pages',
  LOCALES: '/api/locales',
  MAIN_BANNER: '/api/main_banner',
  POPULAR_DESTINATIONS: '/api/home_page_container',
  PRESS_RELEASES: '/api/press_releases',
  QUESTIONS: '/api/frequently_asked_questions',
  SECTIONS: '/api/help_sections',
  SLIDERS: '/api/main_slider',
  URL_REDIRECTION: '/api/url_redirection',
  EVENT_CATEGORIES: '/api/event_categories',
  TERMS_CONDITIONS: '/api/terms_conditions',
  ELITE_PARTNERS: '/api/elite_partners'
}
