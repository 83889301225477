import { handleActions } from 'redux-actions'
import {
  milesLoginStart,
  milesLoginSuccess,
  milesLoginError,
  clearMilesLogin
} from 'actions/milesLogin'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [milesLoginStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [milesLoginSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [milesLoginError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearMilesLogin]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
