import styled from 'styled-components'
import componentLoader from 'assets/loader-componente-resultados-nuevo.gif'

export const ComponentLoader = styled.img.attrs(() => ({
  src: componentLoader,
  alt: 'Loading...'
}))`
  margin: 56px 0 86px 0;
  width: 100%;
`

export default ComponentLoader
