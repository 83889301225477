import { splitString } from 'helpers/string'

const parseRouteData = route => ({
  origin: route.origin.id.toUpperCase(),
  destination: route.destination.id.toUpperCase(),
  amount: route.anualSpending
})

const getLocationCode = locationObject => locationObject.value
const getLabelCode = locationObject => locationObject.label
const routesParse = routes => {
  const withoutEmptyRoutes = []
  routes.forEach(route => {
    if (route.origin && route.destination && route.anualSpending) {
      withoutEmptyRoutes.push(parseRouteData(route))
    }
  })
  return withoutEmptyRoutes
}

/**
 * Agencies are expected to be separated with a comma
 */
const getAgencyArray = agencyString => splitString(agencyString, ',')

export const getAgreementRequestBody = formikValues => {
  const parsed = {
    company: {
      companyName: formikValues.enterpriseFields.enterpriseName,
      cuit: formikValues.enterpriseFields.enterpriseCuit,
      activity: formikValues.enterpriseFields.enterpriseActivity,
      webPage: formikValues.enterpriseFields.enterpriseUrl,
      country: getLocationCode(formikValues.enterpriseFields.enterpriseAddress.country),
      province: getLabelCode(formikValues.enterpriseFields.enterpriseAddress.state),
      city: formikValues.enterpriseFields.enterpriseAddress.city,
      address: formikValues.enterpriseFields.enterpriseAddress.street,
      addressNumber: formikValues.enterpriseFields.enterpriseAddress.number,
      floor: formikValues.enterpriseFields.enterpriseAddress.floor,
      dptoNumber: formikValues.enterpriseFields.enterpriseAddress.apartment,
      postalCode: formikValues.enterpriseFields.enterpriseAddress.zipCode,
      contactDetail: {
        name: formikValues.contactFields.contactName,
        lastName: formikValues.contactFields.contactLastname,
        positionRole: formikValues.contactFields.jobTitle,
        email: formikValues.contactFields.email,
        cellphone: formikValues.contactFields.phoneNumber
      }
    },
    flightARAU: formikValues.contactFields.directTravelsManagement,
    cabotageDetails: routesParse(formikValues.travelsFields.CABOTAGE),
    regionalDetails: routesParse(formikValues.travelsFields.REGIONAL),
    internationalDetails: routesParse(formikValues.travelsFields.INTERNATIONAL)
  }
  if (!formikValues.contactFields.directTravelsManagement) {
    parsed.travelAgencies = getAgencyArray(formikValues.contactFields.travelAgencyName)
  }
  return parsed
}
