import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { responsiveStoreEnhancer } from 'redux-responsive'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import expireIn from 'redux-persist-transform-expire-in'

import rootReducer from '../reducers'

const getPersistConfig = clientSide => {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
      'aircrafts',
      'airlines',
      'airports',
      'brands',
      'cabins',
      'charges',
      'cities',
      'countries',
      'documentTypes',
      'milesLogin',
      'provinces'
    ]
  }

  if (clientSide) {
    const expireTTL = 60 * 1000
    const expirationKey = 'expirationTimestamp'

    persistConfig.transforms = [expireIn(expireTTL, expirationKey)]
  }

  return persistConfig
}

const configureStore = preloadedState => {
  const clientSide = typeof localStorage !== 'undefined'
  const persistedReducer = persistReducer(getPersistConfig(clientSide), rootReducer)
  const enhancers = composeWithDevTools(responsiveStoreEnhancer, applyMiddleware(thunk))
  const store = createStore(persistedReducer, preloadedState, enhancers)
  const persistor = clientSide ? persistStore(store) : null

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').default
      store.replaceReducer(nextRootReducer)
    })
  }

  return { store, persistor }
}

export default configureStore
