export const setValue = (key, value) => {
  const { sessionStorage } = window

  sessionStorage.setItem(key, value)
}

export const getValue = key => {
  const { sessionStorage } = window

  return sessionStorage.getItem(key)
}

export const removeValue = key => {
  const { sessionStorage } = window

  sessionStorage.removeItem(key)
}

export const setBookingToken = value => {
  if (window) {
    return setValue('authToken', value)
  }

  return ''
}

export const getBookingToken = () => {
  if (window) {
    return getValue('authToken')
  }

  return ''
}

export const setBookingSessionLastname = value => {
  if (window) {
    return setValue('lastName', value)
  }

  return ''
}
export const setBookingSessionReservationCode = value => {
  if (window) {
    return setValue('reservationCode', value)
  }

  return ''
}
export const setBookingSessionPreferenceId = value => {
  if (window) {
    return setValue('preferenceId', value)
  }

  return ''
}

export const getBookingSessionLastname = () => {
  if (window) {
    return getValue('lastName')
  }

  return ''
}
export const getBookingSessionReservationCode = () => {
  if (window) {
    return getValue('reservationCode')
  }

  return ''
}
export const getBookingSessionPreferenceId = () => {
  if (window) {
    return getValue('preferenceId')
  }

  return ''
}

export const removeBookingSessionInfo = () => {
  if (typeof window !== 'undefined') {
    removeValue('authToken')
    removeValue('lastName')
    removeValue('reservationCode')
    removeValue('preferenceId')
  }
}
