/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { apiV2 } from 'services/api'

const { fetchLoginStart, fetchLoginSuccess, fetchLoginError } = createActions({
  FETCH_LOGIN_START: () => {},
  FETCH_LOGIN_SUCCESS: data => ({ data }),
  FETCH_LOGIN_ERROR: error => ({ error })
})

const fetchLoginForm = data => {
  return async dispatch => {
    dispatch(fetchLoginStart())
    try {
      const loginStatus = await apiV2.post(`${apiRoutes.LOGIN}${data.user}/auth`, data)
      loginStatus.data.user = data.user
      dispatch(fetchLoginSuccess(loginStatus))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(fetchLoginError(err))
    }
  }
}

export { fetchLoginForm, fetchLoginStart, fetchLoginSuccess, fetchLoginError }
