import styled from 'styled-components'
import { Title3, Title5 } from 'components/fonts'
import mediaQueries from 'components/media-queries'

export const RegisterButton = styled.div`
  height: 34px;
  margin-top: 14%;
  border-color: ${({ theme }) => theme.colors.blue};
  border-style: solid;
  border-width: 1px;
`

export const Title = styled.div``

export const FormContainer = styled.div`
  ${mediaQueries.tablet`
    display: flex;
    flex-direction: row;
`};
  ${mediaQueries.mobile`
    display: flex;
    flex-direction: column;
`};
`

export const UserContainer = styled.div`
  ${mediaQueries.tablet`
    order: 1;
    margin-right: 40px;
  `};

  ${mediaQueries.mobile`
    margin-right: 0px;
  `};
`

export const PasswordContainer = styled.div`
  ${mediaQueries.tablet`
  order: 2;
`};
`

export const LoginBottom = styled.div`
  width: 280px;
  height: 40px;
  margin: 0 auto;
`

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;

  @media only screen and (max-width: 767px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: stretch;
    padding: 7% 10%;
  }

  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    align-items: center;
    padding: 10px;
  }

  ${mediaQueries.tablet`
    flex-direction: column;
  `};
`

export const RegisterContainer = styled.div`
  flex: 0.3;

  & > img {
    width: 100%;
    border-radius: 3px;
  }

  ${mediaQueries.mobile`
    display: none;
  `};
`

export const RegisterMobile = styled.div`
  display: none;

  ${mediaQueries.mobile`
    display: flex;
    margin-top: 10px;
    justify-content: space-between;

    & > ${Title} {
      width: 60%;
    }

    & > ${RegisterButton} {
      width: 40%;

      div {
        height: 100%;

        button {
          width: 100%;
        }
      }
    }

    & > div > ${Title3} {
      font-family: ${({ theme }) => theme.fonts.semiBold};
    }

    & > div > p {
      margin: 10px 0px;
      color: ${({ theme }) => theme.colors.gray};
      font-family: ${({ theme }) => theme.fonts.regular};
    }
  `}
   
  ${mediaQueries.mobile`
    display: flex;
    margin-top: 10px;

    & > div > h4 {
      font-size: 20pt;
      margin: 0px;
      color: ${({ theme }) => theme.colors.blue};
      font-family: ${({ theme }) => theme.fonts.semiBold};
    }

    & > div > p {
      margin: 10px 0px;
      color: ${({ theme }) => theme.colors.gray};
      font-family: ${({ theme }) => theme.fonts.regular};
    }
  `}
  
  ${mediaQueries.tablet`
    order: 3;
  `};
`

export const StyledSeparator = styled.div`
  width: 1px;
  margin: 5% 0px;
  border-left: 1px solid ${({ theme }) => theme.colors.grayLight};

  ${mediaQueries.mobile`
    width: 95%;
    height: 1px;
    border-bottom: 1px solid #d6d6d6;
    order: 2;
  `};
`

export const StyledForm = styled.div`
  flex: 0.35;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  ${mediaQueries.mobile`
    flex: 1;
  `} ${mediaQueries.tablet`
    order: 1;
  `};
`

export const StyledLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > ${Title5} {
    font-family: ${({ theme }) => theme.fonts.bold};
  }
`
export const IconContainer = styled.div`
  margin-right: 10px;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.blue};
    }
  }
`

export const StyledLink = styled.a`
  font-size: 14px;
  text-decoration: none;
  text-align: ${({ firstEntry }) => firstEntry && 'center'};
  margin: 5px 0px;
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.fonts.regular};
`

export const MessageContainter = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  top: 0;
`

export const StyledLinkMessage = styled.a`
  font-size: 14px;
  text-decoration: underline;
  text-align: ${({ firstEntry }) => firstEntry && 'center'};
  margin: 5px 0px;
  color: ${({ theme }) => theme.colors.doveGrayer};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-weight: bold;
`
