import dayjs from 'dayjs'
import { createSelector } from 'reselect'

import FORMATS from 'constants/formats'
import { getCabinClass } from 'helpers/cabin'
import { isEmpty } from 'helpers/utils'
import { getCabins } from 'selectors/cabins'
import { getFlightSummaryData } from 'selectors/flightSummary'


export const getFlightsSearchDataSubState = state => state.flightsSearchData

export const getFlightsSearchDataLegs = createSelector(
  [getFlightsSearchDataSubState],
  state => (state ? state.legs : null)
)

export const getRedirectFromFilters = createSelector(
  [getFlightsSearchDataSubState],
  flightSearchData =>
    flightSearchData && flightSearchData.redirectFromFilters
      ? flightSearchData.redirectFromFilters
      : false
)

export const getPassengersData = createSelector(
  [getFlightSummaryData],
  flightSummaryData => (flightSummaryData ? flightSummaryData.passengers : null)
)

export const getSearchFiltersPassengersCount = createSelector(
  [getFlightsSearchDataSubState],
  searchData => {
    if (searchData) {
      const { adt = 1, chd = 0, inf = 0 } = searchData

      return parseInt(adt, 10) + parseInt(chd, 10) + parseInt(inf, 10)
    }

    return 0
  }
)

export const getFlightsOffersDates = createSelector(
  flightsSearchData => flightsSearchData,
  flightsSearchData => {
    if (!isEmpty(flightsSearchData)) {
      return (flightsSearchData.legs || []).map(leg => dayjs(leg.departure))
    }

    return []
  }
)

export const getFlightsOffersDatesFromState = createSelector(
  [getFlightsSearchDataSubState],
  flightsSearchData => {
    if (!isEmpty(flightsSearchData)) {
      return (flightsSearchData.legs || []).map(leg => dayjs(leg.departure))
    }

    return []
  }
)

export const hasFlightData = createSelector(
  [getFlightsSearchDataSubState, getFlightSummaryData, getCabins],
  (searchedFlight, flightSummary, cabins) => searchedFlight && flightSummary && cabins
)

export const getFlightDataFromSummary = createSelector(
  [getPassengersData, getFlightsSearchDataSubState, getFlightSummaryData, getCabins],
  (passengers, searchedFlight, flightSummary, cabins) => {
    if (flightSummary && flightSummary.summaryMetadata && flightSummary.flightInformation) {
      const departureOrigin = flightSummary.flightInformation.legs.map(
        leg => leg.segments[0].departure
      )

      const legs = flightSummary.summaryMetadata.routes.map((routes, index) => {
        const [origin, destination] = routes.split('-')
        const departure = departureOrigin[index]

        return {
          origin: origin.toUpperCase(),
          destination: destination.toUpperCase(),
          departure: dayjs(departure).format(FORMATS.DATE)
        }
      })

      const { adtCount, chdCount, infCount } = passengers
      const { flightType } = flightSummary.summaryMetadata

      return {
        cabinClass: getCabinClass(searchedFlight, cabins),
        flexDates: false,
        adt: adtCount,
        chd: chdCount,
        inf: infCount,
        flightType,
        legs,
        ...flightSummary.totalMiles && { awardBooking: true }
      }
    }

    return null
  }
)

export const getDefinedFilters = createSelector(
  [getFlightsSearchDataSubState],
  searchData => (searchData && searchData.definedFilters ? searchData.definedFilters : {})
)

export const getPromoCode = createSelector(
  [getFlightsSearchDataSubState],
  searchedFlight => (searchedFlight ? searchedFlight.promoCode : null)
)
