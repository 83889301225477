import { handleActions } from 'redux-actions'

import {
  tuavtaRefundStart,
  tuavtaRefundSuccess,
  tuavtaRefundError,
  tuavtaRefundClear
} from 'actions/common/tuavtaRefund'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [tuavtaRefundStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [tuavtaRefundSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [tuavtaRefundError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [tuavtaRefundClear]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
