/* eslint-disable import/no-cycle */
import i18next from 'i18next'
import { matchPath } from 'react-router-dom'

import ROUTES from 'constants/routes'
import { isMobileOrTablet } from 'helpers/utils'
import { getLocale } from 'services/i18n'

import { LANGUAGE_TAGS } from '../../../localization/constants/languages'

const defaultLanguage = {
  id: '6',
  country: 'Argentina',
  flag: '/media/2019-01/Argentina.svg',
  language: 'Español',
  link: '/'
}

export const shouldRenderEmptyDiv = (pathname, mediaType) =>
  !matchPath(pathname, [
    ROUTES.CHECKOUT.LANG_URL,
    ROUTES.EXCHANGES.LANG_URL,
    ROUTES.PASSENGERS_MISSING_INFO.LANG_URL
  ]) && isMobileOrTablet(mediaType)

export const getSelectedLang = (languages, language) =>
  (languages && languages.find(lang => lang.link.slice(1) === language.toLowerCase())) ||
  defaultLanguage

export const setDeactivateTranslations = condition => {
  if (condition) {
    i18next.changeLanguage('cimode')
  } else {
    i18next.changeLanguage(getLocale())
  }
}

export const showComponentHelpCenterByLanguage = language =>
  ![
    LANGUAGE_TAGS.ENGLISH_USA,
    LANGUAGE_TAGS.SPANISH_USA,
    LANGUAGE_TAGS.FRANCE,
    LANGUAGE_TAGS.GREAT_BRITAIN
  ].includes(language)
