import { handleActions } from 'redux-actions'
import {
  fetchProfileError,
  fetchProfileStart,
  fetchProfileSuccess,
  showSideBar,
  updateProfileStart,
  updateProfileSuccess,
  updateProfileError
} from 'actions/arplusActions'

const defaultState = {
  profile: null,
  isHidden: true,
  updatedData: null,
  isUpdating: false
}

const reducer = handleActions(
  {
    [showSideBar]: (state, { payload: { flag } }) => ({
      ...state,
      isHidden: flag
    }),
    [fetchProfileStart]: state => ({
      ...state,
      profile: null,
      error: null,
      isFetching: true
    }),
    [fetchProfileSuccess]: (state, { payload: { data } }) => ({
      ...state,
      profile: data,
      isFetching: false
    }),
    [fetchProfileError]: (state, { payload: { error } }) => ({
      ...state,
      profile: null,
      error,
      isFetching: false
    }),
    [updateProfileStart]: state => ({
      ...state,
      updateError: null,
      updatedData: null,
      isUpdating: true
    }),
    [updateProfileSuccess]: (state, { payload: { data } }) => ({
      ...state,
      profile: null,
      updatedData: data,
      isUpdating: false
    }),
    [updateProfileError]: (state, { payload: { error } }) => ({
      ...state,
      updateError: error,
      updatedData: null,
      isUpdating: false
    })
  },
  defaultState
)

export default reducer
