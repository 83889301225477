import styled from 'styled-components'

export const ModalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 32px 40px;
  height: 400px;
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TitleBlue = styled.p`
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.blue};
  font-family: ${({ theme }) => theme.fonts.semiBold};
  margin-bottom: 15px;
`

export const IconWrapper = styled.div`
  cursor: pointer;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 300px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.blue};
    border-radius: 6px;
    box-shadow: 0 3px 6px -2px rgba(51, 0, 51, 0.5);
  }
`
export const Subtitle = styled.h3`
  font-size: 1rem;
  margin-bottom: 1rem;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  color: ${({ theme }) => theme.colors.doveGray};
`

export const Text = styled.p`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.doveGray};
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 16px;
  margin-top: 32px;
  width: 100%;

  button {
    font-family: ${({ theme }) => theme.fonts.semiBold};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.white};
    min-width: 220px;
  }
`
