import {createActions} from 'redux-actions';

const {
  getFlightInformationSuccess
} = createActions({
  GET_FLIGHT_INFORMATION_SUCCESS: ScalesMock => ScalesMock
});


const getFlightInformation = (flightData) => {

  return dispatch => {
    dispatch(getFlightInformationSuccess({flightData}))
  }
}

export {
  getFlightInformationSuccess,
  getFlightInformation
}

