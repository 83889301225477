import { handleActions } from 'redux-actions'
import {
  updatePasswordStart,
  updatePasswordSuccess,
  updatePasswordError,
  clearUpdatePasswordState
} from 'actions/arplus/account-data-actions/updatePassword'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const updatePassword = handleActions(
  {
    [updatePasswordStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [updatePasswordSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [updatePasswordError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    }),
    [clearUpdatePasswordState]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default updatePassword
