import { batch } from 'react-redux'

import { clearAirports } from 'actions/catalog/airports'
import { clearCharges } from 'actions/catalog/charges.js'
import { setSublosError } from 'actions/sublos/errors'
import { sublosGetReservationOffersClear } from 'actions/sublos/reservationOffers'
import { RESET_SERVICES } from 'constants/sublos/errors'
import { STEPS } from 'constants/sublos/flow'
import { mapperError } from 'helpers/sublos/mapper-error'

export const flightSelectionThunk = error => async dispatch => {
  batch(() => {
    error && dispatch(setSublosError(mapperError(error, STEPS.FLIGHT_SELECTION)))
  })
}

export const clearFlightSelectionStateThunk = resetServices => async (dispatch, getState) => {
  const state = getState()
  const isResetAll = resetServices === RESET_SERVICES.RESET_ALL_SERVICES
  const isResetCore = resetServices === RESET_SERVICES.RESET_CORE_SERVICES
  const { sublosFlightOffers, charges, airports } = state

  batch(() => {
    ;(sublosFlightOffers.error || isResetAll || isResetCore) &&
      dispatch(sublosGetReservationOffersClear())
    ;(charges.error || isResetAll) && dispatch(clearCharges())
    ;(airports.error || isResetAll) && dispatch(clearAirports())
  })
}
