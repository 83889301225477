import { handleActions } from 'redux-actions'

import {
  fetchFlightsOffersStart,
  fetchFlightsOffersSuccess,
  fetchFlightsOffersError,
  setFlightsOffersUserSelection,
  resetFlightsOffers,
  fetchExchangeOffersStart,
  fetchExchangeOffersSuccess,
  fetchExchangeOffersError
} from 'actions/flightsOffers'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchFlightsOffersStart]: state => ({
      ...state,
      data: {},
      error: null,
      isFetching: true
    }),
    [fetchFlightsOffersSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data.data,
      error: null,
      isFetching: false
    }),
    [fetchFlightsOffersError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: {},
      error
    }),
    [setFlightsOffersUserSelection]: (state, { payload: { flightOffer } }) => ({
      ...state,
      userSelection: flightOffer
    }),
    [resetFlightsOffers]: () => ({ ...defaultState }),
    [fetchExchangeOffersStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchExchangeOffersSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [fetchExchangeOffersError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    })
  },
  defaultState
)

export default reducer
