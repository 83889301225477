import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'

const {
  companiesAndServicesArplusStart,
  companiesAndServicesArplusSuccess,
  companiesAndServicesArplusError,
  clearCompaniesAndServicesArplus
} = createActions({
  COMPANIES_AND_SERVICES_ARPLUS_START: () => {},
  COMPANIES_AND_SERVICES_ARPLUS_SUCCESS: data => ({ data }),
  COMPANIES_AND_SERVICES_ARPLUS_ERROR: error => ({ error }),
  CLEAR_COMPANIES_AND_SERVICES_ARPLUS: () => {}
})

const fetchCompaniesAndServicesArplus = () => async dispatch => {
  dispatch(companiesAndServicesArplusStart())
  try {
    const response = await api.get(apiRoutes.AR_PLUS_COMPANIES_AND_SERVICES)
    dispatch(companiesAndServicesArplusSuccess(response.data))
  } catch (error) {
    dispatch(companiesAndServicesArplusError(error.response ? error.response.data : error))
  }
}

export {
  fetchCompaniesAndServicesArplus,
  companiesAndServicesArplusStart,
  companiesAndServicesArplusSuccess,
  companiesAndServicesArplusError,
  clearCompaniesAndServicesArplus
}
