import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import api, { apiLanguageDefaultAR } from 'services/api'

const {
  identificationsTypeStart,
  identificationsTypeSuccess,
  identificationsTypeError
} = createActions({
  IDENTIFICATIONS_TYPE_START: () => {},
  IDENTIFICATIONS_TYPE_SUCCESS: data => ({ data }),
  IDENTIFICATIONS_TYPE_ERROR: error => ({ error })
})

const fetchIdentificationsType = () => async dispatch => {
  dispatch(identificationsTypeStart())
  try {
    const response = await api.get(apiRoutes.IDENTIFICATIONS_TYPE)
    dispatch(identificationsTypeSuccess(response.data))
  } catch (error) {
    dispatch(identificationsTypeError(error.response ? error.response.data : error))
  }
}
const fetchIdentificationsTypeDefaultAR = () => async dispatch => {
  dispatch(identificationsTypeStart())
  try {
    const response = await apiLanguageDefaultAR.get(apiRoutes.IDENTIFICATIONS_TYPE)
    dispatch(identificationsTypeSuccess(response.data))
  } catch (error) {
    dispatch(identificationsTypeError(error.response ? error.response.data : error))
  }
}

export {
  fetchIdentificationsType,
  identificationsTypeStart,
  identificationsTypeSuccess,
  identificationsTypeError,
  fetchIdentificationsTypeDefaultAR
}
