const getValue = (ob, property, value) => (ob?.hasOwnProperty(property) ? ob[property] : value)

export const TRAVELER = {
  FAMILY: 'F',
  NO_NAME: 'NN'
}

export const HREF_MAIL_LINK = 'mailto:sublos@aerolineas.com.ar'

export const AEROLINEAS_DOMAIN = '@aerolineas.com.ar'

export const EDITABLE_FIELD_NAME = {
  PERSONAL_EMAIL: 'personalEmail',
  PHONE: 'phone',
  CELLPHONE: 'cellphone',
  ALTERNATIVE_PHONE: 'alternativePhone',
  PASSENGER_TYPE: 'passengerType',
  TICKET_TYPE: 'ticketType'
}

export const FIELD_NAME = {
  FULL_NAME: 'fullName',
  AGREEMENT: 'agreement',
  EMPLOYEE_CODE: 'employeeCode',
  MARITAL_STATUS: 'maritalStatus',
  SENIORITY: 'seniority',
  EMPLOYEE_EMAIL: 'employeeEmail',
  ...EDITABLE_FIELD_NAME
}

export const getInitialValuesTouched = user => {
  let touched = {}

  Object.values(EDITABLE_FIELD_NAME).forEach(key =>
    user[key] ? (touched = { ...touched, [key]: true }) : (touched = { ...touched, [key]: false })
  )

  return touched
}

export const mapUserData = (user = {}) => ({
  ...user,
  [FIELD_NAME.FULL_NAME]: getValue(user, FIELD_NAME.FULL_NAME, ''),
  [FIELD_NAME.AGREEMENT]: getValue(user, FIELD_NAME.AGREEMENT, ''),
  [FIELD_NAME.EMPLOYEE_CODE]: getValue(user, FIELD_NAME.EMPLOYEE_CODE, ''),
  [FIELD_NAME.EMPLOYEE_EMAIL]: getValue(user, FIELD_NAME.EMPLOYEE_EMAIL, ''),
  [FIELD_NAME.MARITAL_STATUS]: getValue(user, FIELD_NAME.MARITAL_STATUS, ''),
  [FIELD_NAME.PERSONAL_EMAIL]: getValue(user, FIELD_NAME.PERSONAL_EMAIL, ''),
  [FIELD_NAME.SENIORITY]: getValue(user, FIELD_NAME.SENIORITY, 0),
  [FIELD_NAME.PHONE]: getValue(user, FIELD_NAME.PHONE, ''),
  [FIELD_NAME.CELLPHONE]: getValue(user, FIELD_NAME.CELLPHONE, ''),
  [FIELD_NAME.ALTERNATIVE_PHONE]: getValue(user, FIELD_NAME.ALTERNATIVE_PHONE, ''),
  [FIELD_NAME.PASSENGER_TYPE]: getValue(user, FIELD_NAME.PASSENGER_TYPE, null),
  [FIELD_NAME.TICKET_TYPE]: getValue(user, FIELD_NAME.TICKET_TYPE, '')
})

export const mapUserDataCombinedWithBenefitsTypeData = (
  user,
  {
    employeePriority,
    othersPriority,
    retiredEmployeePriority,
    retiredOthersPriority,
    accountCode,
    cabinClass,
    confirmPlaced,
    status,
    code,
    benefitType,
    vacationType,
    ticketOption
  }
) => ({
  ...user,
  employeePriority,
  othersPriority,
  retiredEmployeePriority,
  retiredOthersPriority,
  accountCode,
  cabinClass,
  confirmPlaced,
  benefitTypeStatus: status,
  benefitType,
  ticketTypeCode: code,
  ticketOption,
  vacationType
})
