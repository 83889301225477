import { handleActions } from 'redux-actions'

import { resetExternalPaymentStatus } from 'actions/externalPayment'
import { resetPurchasingChallengeStatus } from 'actions/purchasingChallenge'
import {
  fetchReservationStart,
  fetchReservationSuccess,
  fetchReservationError,
  clearReservation,
  clearReservationError,
  purchaseStart,
  purchaseDataTemp,
  clearPurchaseDataTemp,
  purchaseSuccess,
  purchaseError,
  clearPurchase,
  clearPurchaseError,
  paymentBookingStart,
  paymentBookingSuccess,
  paymentBookingError,
  resetPaymentBookingError,
  setPaymentData,
  resetPaymentData,
  setPassengersFormikData,
  resetPassengersFormikData,
  exchangesPaymentStart,
  exchangesPaymentSuccess,
  exchangesPaymentError,
  clearExchangesPayment,
  clearExchangesPurchaseError,
  addReservationEmailStart,
  addReservationEmailSuccess,
  addReservationEmailError,
  clearEmailAddition,
  setIsUnticketedPurchase
} from 'actions/reservation'

const defaultState = {
  data: {},
  seatsSelection: null,
  error: null,
  isFetching: false
}

const defaultStatePurchaseTemp = {
  data: null
}

const purchaseTemp = handleActions(
  {
    [purchaseDataTemp]: (state, { payload: { data } }) => ({
      ...state,
      data
    }),
    [clearPurchaseDataTemp]: () => ({
      data: null
    })
  },
  defaultStatePurchaseTemp
)

const reservation = handleActions(
  {
    [fetchReservationStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchReservationSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [fetchReservationError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: {},
      error
    }),
    [exchangesPaymentSuccess]: (state, { payload: { data } }) => ({
      data: data,
      error: null,
      isFetching: false
    }),
    [clearReservation]: state => ({
      ...state,
      data: null
    }),
    [clearReservationError]: state => ({
      ...state,
      error: null
    })
  },
  defaultState
)

const purchaseDefault = {
  data: null,
  error: null,
  status: null,
  isFetching: false
}

const purchase = handleActions(
  {
    [purchaseStart]: state => ({
      ...state,
      error: null,
      data: null,
      status: null,
      isFetching: true
    }),
    [purchaseSuccess]: (state, { payload: { data } }) => ({
      data: data,
      status: data.status,
      error: null,
      isFetching: false
    }),
    [purchaseError]: (state, { payload: { error } }) => ({
      data: null,
      error,
      status: error?.status,
      isFetching: false,
      isRequestError: error?.isRequestError ? error?.isRequestError : null
    }),
    [setIsUnticketedPurchase]: (state, { payload: { warnings } }) => ({
      ...state,
      data: {
        ...state.data,
        isUnticketed: warnings.includes('gds.booking.warn.unticketedWarning')
      }
    }),
    [clearPurchaseError]: state => ({
      ...state,
      error: null
    }),
    [clearPurchase]: () => defaultState
  },
  purchaseDefault
)

const externalPaymentDefault = {
  data: null,
  error: null
}

const externalPayment = handleActions(
  {
    [resetExternalPaymentStatus]: () => externalPaymentDefault
  },
  externalPaymentDefault
)

const purchaseChallengeDefault = {
  data: null,
  error: null
}

const purchasingChallenge = handleActions(
  {
    [resetPurchasingChallengeStatus]: () => purchaseChallengeDefault
  },
  purchaseChallengeDefault
)

const paymentBookingDefault = {
  data: null,
  error: null,
  isFetching: false
}

const paymentBooking = handleActions(
  {
    [paymentBookingStart]: state => ({
      ...state,
      error: null,
      data: null,
      isFetching: true
    }),
    [paymentBookingSuccess]: (state, { payload: { data } }) => ({
      data: data,
      error: null,
      isFetching: false
    }),
    [paymentBookingError]: (state, { payload: { error } }) => ({
      data: null,
      error,
      isFetching: false
    }),
    [resetPaymentBookingError]: state => ({
      ...state,
      error: null
    })
  },
  paymentBookingDefault
)

const paymentDataFormikDefault = null

const paymentDataFormik = handleActions(
  {
    [setPaymentData]: (state, { payload: { data } }) => ({ ...data }),
    [resetPaymentData]: () => paymentDataFormikDefault
  },
  paymentDataFormikDefault
)

const passengersDataDefault = null

const passengersFormikData = handleActions(
  {
    [setPassengersFormikData]: (state, { payload: { data } }) => ({ ...data }),
    [resetPassengersFormikData]: () => passengersDataDefault
  },
  passengersDataDefault
)

const exchangesPaymentDefault = {
  data: null,
  error: null,
  isFetching: false
}

const exchangesPayment = handleActions(
  {
    [exchangesPaymentStart]: state => ({
      ...state,
      error: null,
      isFetching: true
    }),
    [exchangesPaymentSuccess]: (state, { payload: { data } }) => ({
      data,
      error: null,
      isFetching: false
    }),
    [exchangesPaymentError]: (state, { payload: { error } }) => ({
      data: null,
      error,
      isFetching: false
    }),
    [clearExchangesPurchaseError]: state => ({
      ...state,
      error: null
    }),
    [clearExchangesPayment]: () => ({
      ...defaultState
    })
  },
  exchangesPaymentDefault
)

const emailAdditionDefault = {
  data: null,
  error: null,
  isFetching: false
}

const emailAddition = handleActions(
  {
    [addReservationEmailStart]: state => ({
      ...state,
      error: null,
      data: null,
      isFetching: true
    }),
    [addReservationEmailSuccess]: (state, { payload: { data } }) => ({
      data: data,
      error: null,
      isFetching: false
    }),
    [addReservationEmailError]: (state, { payload: { error } }) => ({
      data: null,
      error,
      isFetching: false
    }),
    [clearEmailAddition]: () => emailAdditionDefault
  },
  emailAdditionDefault
)

export {
  reservation,
  purchase,
  paymentBooking,
  paymentDataFormik,
  passengersFormikData,
  exchangesPayment,
  emailAddition,
  purchaseTemp,
  purchasingChallenge,
  externalPayment
}
