import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'

const {
  validateEmailStart,
  validateEmailSuccess,
  validateEmailError,
  clearValidateEmailState
} = createActions({
  VALIDATE_EMAIL_START: () => {},
  VALIDATE_EMAIL_SUCCESS: data => ({ data }),
  VALIDATE_EMAIL_ERROR: error => ({ error }),
  CLEAR_VALIDATE_EMAIL_STATE: () => {}
})

const fetchValidateEmail = email => async dispatch => {
  dispatch(validateEmailStart())
  try {
    const response = await api.get(`${apiRoutes.VALIDATE_EMAIL(email)}`)
    dispatch(validateEmailSuccess(response.data))
  } catch (error) {
    dispatch(validateEmailError(error.response ? error.response.data : error))
  }
}

export {
  fetchValidateEmail,
  validateEmailStart,
  validateEmailSuccess,
  validateEmailError,
  clearValidateEmailState
}