import { handleActions } from 'redux-actions'
import { loginStart, loginSuccess, loginError, clearCorporateLogin } from 'actions/corporate/login'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const loginCorporate = handleActions(
  {
    [loginStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [loginSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [loginError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    }),
    [clearCorporateLogin]: () => defaultState
  },
  defaultState
)

export default loginCorporate
