/* eslint-disable import/no-cycle */
/* eslint-disable import/order */
import NAMESPACES from 'localization/constants/namespaces'
import {
  SublosLoginPage,
  SublosMainPage,
  SublosErrorPage,
  SublosWebSiteInProgressPage
} from 'pages/sublos'
import apiRoutes from 'constants/apiRoutes'

export const langPathRegex = `/:langId([a-zA-Z]{2}-[a-zA-Z]{2})?`

export const SUBLOS_INITIAL_PATH = '/sublos'

export const sublosProtectedRoutes = {
  SUBLOS_COMPANIES: apiRoutes.SUBLOS_COMPANIES,
  SUBLOS_DOCUMENT_TYPES: apiRoutes.SUBLOS_DOCUMENT_TYPES,
  SUBLOS_LOGIN: apiRoutes.SUBLOS_LOGIN,
  SUBLOS_LOGIN_WITH_TOKEN: apiRoutes.SUBLOS_LOGIN_WITH_TOKEN
}

export default {
  SUBLOS_LOGIN: {
    LANG_URL: `${langPathRegex}${SUBLOS_INITIAL_PATH}/login`,
    component: SublosLoginPage,
    title: 'metadata.title.sublos.login',
    description: 'metadata.description.sublos.login',
    disallowIndex: true,
    header: false,
    exact: true,
    namespaces: [NAMESPACES.SUBLOS],
    name: 'SUBLOS_LOGIN'
  },
  SUBLOS_HOME: {
    LANG_URL: `${langPathRegex}${SUBLOS_INITIAL_PATH}/home`,
    component: SublosMainPage,
    title: 'metadata.title.sublos.main-page',
    description: 'metadata.description.sublos.main-page',
    disallowIndex: true,
    header: false,
    exact: true,
    namespaces: [NAMESPACES.SUBLOS],
    privateUrl: true,
    name: 'SUBLOS_HOME'
  },
  SUBLOS_ERROR: {
    LANG_URL: `${langPathRegex}/sublos-error`,
    component: SublosErrorPage,
    title: 'metadata.title.sublos.error',
    description: 'metadata.description.sublos.error',
    disallowIndex: true,
    exact: true,
    header: true,
    banner: true,
    name: 'SUBLOS_ERROR'
  },
  SUBLOS_WEBSITE_IN_PROGRESS: {
    LANG_URL: `${langPathRegex}/sublos-website-in-progress`,
    component: SublosWebSiteInProgressPage,
    title: 'metadata.title.sublos.site-in-progress',
    description: 'metadata.description.sublos.site-in-progress',
    disallowIndex: true,
    exact: true,
    header: true,
    banner: true,
    name: 'SUBLOS_WEBSITE_IN_PROGRESS'
  }
}
