import { handleActions } from 'redux-actions'
import { changeModalState, resetAllModals } from 'actions/modalActions'

const defaultState = {
  modals: {}
}

const reducer = handleActions(
  {
    [changeModalState]: (state, { payload: { modalName, isVisible } }) => ({
      ...state,
      modals: { ...state.modals, [modalName]: { isVisible } }
    }),
    [resetAllModals]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default reducer
