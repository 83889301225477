import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { mapMediaType } from 'selectors/mediaType'
import { isDesktopOrWide } from 'helpers/utils'

import loader from 'assets/filtros-resultados-loader.gif'
import loaderMobile from 'assets/filtros-resultados-loader-mobile.gif'

const ComponentLoader = styled.img.attrs(() => ({
  src: loader,
  alt: 'Loading...'
}))`
  width: 100%;
  margin: 30px auto;
`

const ComponentLoaderMobile = styled.img.attrs(() => ({
  src: loaderMobile,
  alt: 'Loading...'
}))`
  width: 80%;
  margin: 15px auto;
`

const FiltersResultsLoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const FiltersResultsLoader = ({ mediaType }) => (
  <FiltersResultsLoaderWrapper>
    {isDesktopOrWide(mediaType) ? <ComponentLoader /> : <ComponentLoaderMobile />}
  </FiltersResultsLoaderWrapper>
)

const mapStateToProps = state => ({
  mediaType: mapMediaType(state)
})

export default connect(mapStateToProps)(FiltersResultsLoader)
