import { handleActions } from 'redux-actions'

import {
  documentTypesSublosStart,
  documentTypesSublosSuccess,
  documentTypesSublosError,
  documentTypesSublosClear
} from 'actions/sublos/documentTypes'

const defaultState = {
  data: [],
  error: null,
  isFetching: false
}

const documentTypesSublos = handleActions(
  {
    [documentTypesSublosStart]: state => ({
      ...state,
      data: [],
      error: null,
      isFetching: true
    }),
    [documentTypesSublosSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [documentTypesSublosError]: (state, { payload: { error } }) => ({
      ...state,
      data: [],
      error,
      isFetching: false
    }),
    [documentTypesSublosClear]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default documentTypesSublos
