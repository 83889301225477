import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import { buildURLEncoded } from 'helpers/url'

const { fetchAirlinesStart, fetchAirlinesSuccess, fetchAirlinesError } = createActions({
  FETCH_AIRLINES_START: () => {},
  FETCH_AIRLINES_SUCCESS: airlines => airlines,
  FETCH_AIRLINES_ERROR: error => error
})

const fetchAirlines = pageConfig => {
  const queryParams = buildURLEncoded('', pageConfig)

  return async dispatch => {
    dispatch(fetchAirlinesStart())

    try {
      const airlines = await api.get(`${apiRoutes.AIRLINES}${queryParams}`)
      dispatch(fetchAirlinesSuccess(airlines))
    } catch (error) {
      dispatch(fetchAirlinesError(error))
    }
  }
}

export { fetchAirlinesStart, fetchAirlinesSuccess, fetchAirlinesError, fetchAirlines }
