import Loadable from 'react-loadable'

import { ChunkLoader } from 'components/loaders'

export const SublosLoginPage = Loadable({
  loader: () => import('./LoginPage'),
  loading: ChunkLoader,
  delay: 300
})

export const SublosMainPage = Loadable({
  loader: () => import('./MainPage'),
  loading: ChunkLoader,
  delay: 300
})

export const SublosErrorPage = Loadable({
  loader: () => import('./ErrorPage'),
  loading: ChunkLoader,
  delay: 300
})

export const SublosWebSiteInProgressPage = Loadable({
  loader: () => import('./WebSiteInProgress'),
  loading: ChunkLoader,
  delay: 300
})
