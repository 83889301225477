import styled from 'styled-components'

import stickyFooterLoader from 'assets/sticky-footer-loader.gif'
import e2eClass from 'helpers/automation'

export const StickyFooterLoader = styled.img.attrs(() => ({
  className: e2eClass('stickyFooter.loader'),
  src: stickyFooterLoader,
  alt: 'Loading...'
}))`
  height: ${props => props.height || '100%'};
  width: 100%;
  margin: 0 auto;
`

export default StickyFooterLoader
