import { createSelector } from 'reselect'

import { ERROR_TYPE } from 'constants/sublos/errors'
import { mapRequestError } from 'helpers/sublos/mapper-error'
import { isEmpty } from 'helpers/utils'

const getCountriesState = state => state.countries

export const getCountriesData = createSelector([getCountriesState], countries =>
  countries ? countries.data : null
)

export const getCountriesError = createSelector([getCountriesState], state => state?.error || null)

export const isErrorSublosCountriesPayment = createSelector(
  [getCountriesState],
  ({ error }) => error && mapRequestError(error, ERROR_TYPE.SCREEN)
)

export const getCountries = createSelector([getCountriesData], countries =>
  countries ? countries.resources : []
)

export const isFetchingCountries = createSelector(
  [getCountriesState, getCountriesData],
  (state, data) => state.isFetching || isEmpty(data)
)

export const isFetchingCountriesWithEmptyData = createSelector(
  [getCountriesState],
  state => state.isFetching || false
)

export const getCountriesOptionsList = createSelector([getCountries], countries =>
  (countries || []).map(item => ({
    value: item.code,
    label: item.name
  }))
)

export const getCountriesAreaCodeOptionsList = createSelector([getCountries], countries =>
  (countries || []).map(item => ({
    value: item.code,
    label: `${item.name} (${item.areaCode})`
  }))
)
