import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'

const { membersStart, membersSuccess, membersError, clearMembersState } = createActions({
  MEMBERS_START: () => {},
  MEMBERS_SUCCESS: data => ({ data }),
  MEMBERS_ERROR: error => ({ error }),
  CLEAR_MEMBERS_STATE: () => {}
})

const register = values => async dispatch => {
  dispatch(membersStart())
  try {
    const response = await api.post(apiRoutes.MEMBERS_AR_PLUS, {
      ...values
    })
    dispatch(membersSuccess(response.data))
  } catch (error) {
    dispatch(membersError(error.response ? error.response.data : error))
  }
}

const preRegister = values => async dispatch => {
  dispatch(membersStart())
  try {
    const response = await api.post(apiRoutes.PRE_REGISTRATION_AR_PLUS, {
      ...values
    })
    dispatch(membersSuccess(response.data))
  } catch (error) {
    dispatch(membersError(error.response ? error.response.data : error))
  }
}

export { register, preRegister, membersStart, membersSuccess, membersError, clearMembersState }
