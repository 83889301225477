import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { buildURLEncoded } from 'helpers/url'
import api, { apiSublos } from 'services/api'

const {
  fetchDocumentTypesStart,
  fetchDocumentTypesSuccess,
  fetchDocumentTypesError,
  documentTypesClear
} = createActions({
  FETCH_DOCUMENT_TYPES_START: () => {},
  FETCH_DOCUMENT_TYPES_SUCCESS: documentTypes => documentTypes,
  FETCH_DOCUMENT_TYPES_ERROR: error => error,
  DOCUMENT_TYPES_CLEAR: () => {}
})

const fetchDocumentTypes = pageConfig => {
  const queryParams = buildURLEncoded('', pageConfig)

  return async dispatch => {
    dispatch(fetchDocumentTypesStart())

    try {
      const documentTypes = await api.get(`${apiRoutes.DOCUMENT_TYPES}${queryParams}`)
      dispatch(fetchDocumentTypesSuccess(documentTypes))
    } catch (error) {
      dispatch(fetchDocumentTypesError(error))
    }
  }
}

const fetchSublosDocumentTypes = pageConfig => {
  const queryParams = buildURLEncoded('', pageConfig)

  return async dispatch => {
    dispatch(fetchDocumentTypesStart())

    try {
      const documentTypes = await apiSublos.get(`${apiRoutes.DOCUMENT_TYPES}${queryParams}`)
      dispatch(fetchDocumentTypesSuccess(documentTypes))
    } catch (error) {
      dispatch(fetchDocumentTypesError(error))
    }
  }
}

export {
  fetchDocumentTypesStart,
  fetchDocumentTypesSuccess,
  fetchDocumentTypesError,
  documentTypesClear,
  fetchDocumentTypes,
  fetchSublosDocumentTypes
}
