/* eslint-disable import/no-cycle */
import Loadable from 'react-loadable'

import { ChunkLoader } from 'components/loaders'

export const ArPlusPage = Loadable({
  loader: () => import('./ArPlusPage'),
  loading: ChunkLoader,
  delay: 300
})

export const ArPlusDashboardPage = Loadable({
  loader: () => import('./DashboardPage'),
  loading: ChunkLoader,
  delay: 300
})

export const ArPlusLoginPage = Loadable({
  loader: () => import('./LoginPage'),
  loading: ChunkLoader,
  delay: 300
})

export const ForgottenPasswordPage = Loadable({
  loader: () => import('./ForgottenPasswordPage'),
  loading: ChunkLoader,
  delay: 300
})

export const RecoverMembershipCode = Loadable({
  loader: () => import('./RecoverMembershipCode'),
  loading: ChunkLoader,
  delay: 300
})

export const ArplusLastOperationsPage = Loadable({
  loader: () => import('./LastOperationsPage'),
  loading: ChunkLoader,
  delay: 300
})

export const RegisterArPlusPage = Loadable({
  loader: () => import('./RegisterArPlusPage'),
  loading: ChunkLoader,
  delay: 300
})

export const PreRegisterArPlusPage = Loadable({
  loader: () => import('./PreRegisterArPlusPage'),
  loading: ChunkLoader,
  delay: 300
})

export const PaymentMilesPage = Loadable({
  loader: () => import('./PaymentMilesPage'),
  loading: ChunkLoader,
  delay: 300
})

export const AccountEvaluationPage = Loadable({
  loader: () => import('./AccountEvaluationPage'),
  loading: ChunkLoader,
  delay: 300
})

export const TransferMilesPage = Loadable({
  loader: () => import('./TransferMilesPage'),
  loading: ChunkLoader,
  delay: 300
})

export const ElitePartnersPage = Loadable({
  loader: () => import('./ElitePartnersPage'),
  loading: ChunkLoader,
  delay: 300
})

export const AccountDataPage = Loadable({
  loader: () => import('./AccountDataPage'),
  loading: ChunkLoader,
  delay: 300
})

export const FirstAccessPage = Loadable({
  loader: () => import('./FirstAccessPage'),
  loading: ChunkLoader,
  delay: 300
})

export const SetFirstAccessPasswordPage = Loadable({
  loader: () => import('./SetFirstAccessPasswordPage'),
  loading: ChunkLoader,
  delay: 300
})

export const RecoverMailPage = Loadable({
  loader: () => import('./RecoverMailPage'),
  loading: ChunkLoader,
  delay: 300
})

export const ArplusCreditMilesPage = Loadable({
  loader: () => import('./CreditMilesPage'),
  loading: ChunkLoader,
  delay: 300
})

export const ArplusGiftMilesPage = Loadable({
  loader: () => import('./GiftMilesPage'),
  loading: ChunkLoader,
  delay: 300
})

export const UnsubscribeArplusPage = Loadable({
  loader: () => import('./UnsubscribeConfirmationPage'),
  loading: ChunkLoader,
  delay: 300
})

export const ArplusRecoverEmailPage = Loadable({
  loader: () => import('./RecoverEmailPage'),
  loading: ChunkLoader,
  delay: 300
})

export const ArplusRecoverEmailConfirmationPage = Loadable({
  loader: () => import('./RecoverEmailConfirmationPage'),
  loading: ChunkLoader,
  delay: 300
})

export const ChangePasswordPage = Loadable({
  loader: () => import('./ChangePasswordPage'),
  loading: ChunkLoader,
  delay: 300
})

export const VerifyYourAccountPage = Loadable({
  loader: () => import('./VerifyYourAccountPage'),
  loading: ChunkLoader,
  delay: 300
})
