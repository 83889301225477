import { handleActions } from 'redux-actions'

import {
  validateRecaptchaStart,
  validateRecaptchaSuccess,
  validateRecaptchaError,
  clearValidateRecaptcha
} from 'actions/reCaptcha'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [validateRecaptchaStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [validateRecaptchaSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [validateRecaptchaError]: (state, { error }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearValidateRecaptcha]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
