import { handleActions } from 'redux-actions'

import {
  evaluateMemberEligibilityStart,
  evaluateMemberEligibilitySuccess,
  evaluateMemberEligibilityError,
  clearEvaluateMemberEligibilityState
} from 'actions/arplus/evaluateMemberEligibility'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const evaluateMemberEligibility = handleActions(
  {
    [evaluateMemberEligibilityStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [evaluateMemberEligibilitySuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [evaluateMemberEligibilityError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearEvaluateMemberEligibilityState]: () => ({ ...defaultState })
  },
  defaultState
)

export default evaluateMemberEligibility
