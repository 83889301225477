import { createSelector } from 'reselect'

import { isEmpty } from 'helpers/utils'

import { getDocumentTypes } from './documentTypes'

const getDocumentTypesRulesSubstate = state => state.documentTypesRules

export const getDocumentTypesRulesData = createSelector(
  [getDocumentTypesRulesSubstate],
  documentTypes => (documentTypes ? documentTypes.data : null)
)

export const isFetchingDocumentTypesRules = createSelector(
  [getDocumentTypesRulesSubstate, getDocumentTypesRulesData],
  (state, data) => state.isFetching || isEmpty(data)
)

export const getDocumentTypesRulesError = createSelector(
  [getDocumentTypesRulesSubstate],
  state => state?.error || null
)

export const isFetchingDocumentTypesRulesWithEmptyData = createSelector(
  [getDocumentTypesRulesSubstate],
  state => state.isFetching || false
)

export const getDocumentTypesError = createSelector(
  [getDocumentTypesRulesSubstate],
  getDocumentTypesRules => (getDocumentTypesRules ? getDocumentTypesRules.error : null)
)

export const getDocumentTypesRules = createSelector(
  [getDocumentTypesRulesData],
  getDocumentTypesRules => (getDocumentTypesRules ? getDocumentTypesRules.documentTypes : [])
)

export const getDocumentTypesRulesWithCatalog = createSelector(
  [getDocumentTypesRules, getDocumentTypes],
  (documentTypesRules, documentTypes) =>
    documentTypes && documentTypesRules
      ? documentTypesRules
          .map(documentTypeRule =>
            documentTypes.find(documentType => documentType.code === documentTypeRule.code)
          )
          .filter(i => i)
      : []
)

export const getIdOptionsListInPassengerData = createSelector(
  [getDocumentTypesRulesWithCatalog],
  documentTypes =>
    (documentTypes || [])
      .filter(documentType => documentType?.active && documentType?.showInPassengerData)
      .map(item => ({
        value: item.code,
        label: item.name
      }))
)

export const getIdOptionsListInCheckout = createSelector(
  [getDocumentTypesRulesWithCatalog],
  documentTypes =>
    (documentTypes || [])
      .filter(documentType => documentType?.active && documentType?.showInCheckout)
      .map(item => ({
        value: item.code,
        label: item.name
      }))
)

export const getExpirationThreshold = createSelector(
  [getDocumentTypesRulesWithCatalog],
  documentTypes => {
    const documentType = documentTypes.filter(({ expirationThreshold }) => expirationThreshold)
    const currentYear = new Date().getFullYear()
    const dateOffset = documentType && documentType[0] && documentType[0].expirationThreshold
    return dateOffset ? currentYear + dateOffset : null
  }
)
