import theme from 'components/theme'
import CONFIG from 'config'
import CHECKOUT_STEPS from 'constants/checkoutStepBar'
import {
  generateCheckoutRoute,
  generateFlexCalendarRoute,
  generateFlightOffersRoute,
  generateHomeRoute
} from 'helpers/url/localization'
import { parseLanguageTagFromUrl } from 'localization/helpers'

const CHATBOT_ID = 'wcx-chat'

const HIDDEN_CLASS = 'styles_window_hidden__3uTF0'

const showChatbotButton = button => button.classList?.remove(HIDDEN_CLASS)

const hideChatbotButton = button => button.classList?.add(HIDDEN_CLASS)

const toggleChatbutton = show => {
  if (typeof window === 'undefined') return

  const button = window.document.getElementById(CHATBOT_ID)

  if (button) {
    show ? showChatbotButton(button) : hideChatbotButton(button)
  }
}

const generatePurchaseBookingRoutes = lan => [
  generateFlexCalendarRoute(lan),
  generateFlightOffersRoute(lan),
  generateCheckoutRoute(lan, CHECKOUT_STEPS.PASSENGERS),
  generateCheckoutRoute(lan, CHECKOUT_STEPS.PAYMENT)
]

const generateRoutesAllowedByChatBot = lan => [
  generateHomeRoute(lan),
  ...generatePurchaseBookingRoutes(lan)
]

const getChatbotByNodesList = addedNodes => {
  let chatbot
  ;(addedNodes || []).forEach(node => {
    if (node.id === CHATBOT_ID) chatbot = node
  })

  return chatbot
}

const executeWithDelay = (callback, delay = 1) => setTimeout(() => callback(), delay)

const observeTargetNode = ({ targetNode, config, callback }) => {
  if (typeof window === 'undefined' || !targetNode || !callback) return

  // create a new instance of MutationObserver named observer
  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => callback(mutation, observer))
  })

  // configuration of the observer:
  const configObserve = config || { childList: true }

  // pass in the target targetNode, as well as the observer options
  observer.observe(targetNode, configObserve)
}

const observeChatbotBox = targetNode => {
  if (!targetNode) return

  const callback = async (mutation, observer) => {
    const iframe = mutation?.addedNodes[0]

    // add delay for the load box with the new styles
    executeWithDelay(() => {
      const document = iframe?.contentDocument

      if (document) {
        const btn = document.getElementsByClassName('btn')
        const titleBar = document.getElementById('titlebar')

        if (btn?.style) btn.style.backgroundColor = theme.colors.blue

        if (titleBar?.style) {
          titleBar.style.backgroundColor = theme.colors.blue
          titleBar.style.borderColor = theme.colors.blue
        }
      }
    })

    observer.disconnect()
  }

  observeTargetNode({
    targetNode,
    callback
  })
}

const runChatbotScript = () => {
  const script = document.createElement('script')
  const targetNode = document.body

  script.setAttribute('src', CONFIG.EXTERNAL_CHAT_BOT)
  document.head.appendChild(script)

  const callback = (mutation, observer) => {
    const chatBot = getChatbotByNodesList(mutation?.addedNodes)

    if (chatBot) {
      executeWithDelay(() => {
        const button = chatBot?.children[0]
        const boxContainer = chatBot?.children[1]

        showChatbotButton(button)

        //observe when chatbot-box is mounted
        if (boxContainer)
          button.addEventListener('click', observeChatbotBox(boxContainer.children[0]))

        observer.disconnect()
      })
    }
  }

  executeWithDelay(() =>
    observeTargetNode({
      targetNode,
      callback
    })
  )
}

export const validateRoute = (lan, location) =>
  generateRoutesAllowedByChatBot(lan).some(
    pathname =>
      pathname.toLowerCase() === location.pathname.toLowerCase() ||
      pathname.toLowerCase() === location.pathname.toLowerCase() + '/'
  )

export const showChatbotByRoutes = (prevLocation, currentLocation) => {
  const lan = parseLanguageTagFromUrl(currentLocation?.pathname)
  const isEsAR = !lan

  if (!isEsAR || !CONFIG.ENABLE_EXTERNAL_CHAT_BOTS) return

  if (!prevLocation && validateRoute(lan, currentLocation)) runChatbotScript()
  else if (prevLocation && prevLocation.pathname !== currentLocation.pathname)
    toggleChatbutton(validateRoute(lan, currentLocation))
}
