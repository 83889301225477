import { negotiateToken } from 'server/services/auth'
import { apiLog, setTokenHeader } from 'services/api'

export const handleError = (error, errorAction, dispatch, isPurchase, shoppingId) => {
  let stateChange
  const errorMessage = getErrorMessage(isPurchase)
  let data = { errorMessage }

  if (error.response && error.response.status !== 0) {
    stateChange = errorAction(error.response.data)
    const errorResponse = error.response
    const loggedError = {
      errorData: errorResponse.data,
      errorStatus: errorResponse.status,
      errorStatusText: errorResponse.statusText,
      headers: errorResponse.config.headers
    }
    errorLogMyReservation(shoppingId, 'reservationsPostServerErrorCatch', loggedError)
  } else if (error.response?.status === 0) {
    let requestError = {
      status: error.response.status,
      requestError: true
    }
    stateChange = errorAction(requestError)
    errorLogMyReservation(shoppingId, 'reservationsPostClientError', error.response.data)
  } else {
    stateChange = errorAction(data)
    errorLogMyReservation(shoppingId, 'reservationsPostErrorCatch', {})
  }
  dispatch(stateChange)
}

function getErrorMessage(isPurchase) {
  if (isPurchase) {
    return 'There was an error processing your purchase. Please try again.'
  } else {
    return 'There was an error processing your request. Please try again.'
  }
}

const errorLogMyReservation = async (shoppingId, message, error, res, cookies) => {
  const accessToken = await negotiateToken(res, cookies)

  setTokenHeader(apiLog.defaults.headers.common, accessToken)
  const buff = new Buffer(JSON.stringify(error))
  const base64error = buff.toString('base64')
  apiLog.post('/v2/booking/client-errors/channel-error', {
    pnr: shoppingId,
    message,
    error: base64error
  })
}
