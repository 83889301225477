export const CATEGORIES = {
  GOLD: 'GOLD',
  PLATINUM: 'PLATINUM',
  DIAMOND: 'DIAMOND',
  CLASSIC: 'CLASSIC',
  O: 'GOLD',
  P: 'PLATINUM',
  D: 'DIAMOND',
  G: 'CLASSIC'
}
