import { isEmpty } from 'helpers/utils'
import { validateField as isValid, absoluteUrl } from 'helpers/validations'

/**
 * @deprecated Check if this could be replaced with hybridlink component.
 * Maybe with some tweak :)
 */
export const getLinkFromCmsPath = url => {
  const defaultValue = ''

  if (!url) {
    return defaultValue
  }
  const trimmedUrl = url.trim()

  if (isValid(absoluteUrl, trimmedUrl)) {
    return trimmedUrl
  }

  const charPatterns = [
    { char: '/', offset: 1 },
    { char: '?', offset: 0 },
    { char: '#', offset: 0 }
  ]

  const value = charPatterns
    .map(({ char, offset }) => getPathValue(trimmedUrl, char, offset))
    .filter(value => value)

  return isEmpty(value) ? defaultValue : value[0].replace(/&amp;/g, `&`)
}

const getPathValue = (url, char, offset) => {
  const pos = url.indexOf(char)

  if (pos !== -1) {
    const value = url.substr(pos + offset)
    return `/${value}`
  }

  return undefined
}

export const decodeURLParams = url => url && url.replace(/&amp;/g, `&`)

export const removeSlash = url => (url && url.indexOf('/') === 0 && url.replace('/', '')) || url

export const parseUrlsInResponse = (response) =>{
  if(typeof response === 'string'){
    return parseParams(response)
  }

  const urlRegx =  /"http((?:[^"\\]|\\.)*)"/g
  let matchUrls
  let dataString = JSON.stringify(response)

  while ((matchUrls = urlRegx.exec(dataString)) !== null){
    const newUrl = parseParams(matchUrls[0])

    const segment1 = dataString.slice(0 , matchUrls.index)
    const segment2 = dataString.slice( dataString.indexOf(',', matchUrls.index + newUrl.length), dataString.length)

    dataString = segment1 + newUrl + segment2
  }

  return JSON.parse(dataString)
}

const parseParams = (originalUrl) => `${(originalUrl || '')
  .replace(/\\*_*[0-9]=/g, '=')}`
