import { createActions } from 'redux-actions'
import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'

const {
  originalFaresStart,
  originalFaresSuccess,
  originalFaresError,
  resetOriginalFares
} = createActions({
  ORIGINAL_FARES_START: () => {},
  ORIGINAL_FARES_SUCCESS: data => ({ data }),
  ORIGINAL_FARES_ERROR: error => ({ error }),
  RESET_ORIGINAL_FARES: () => {}
})

const originalFaresGet = shoppingId => async dispatch => {
  dispatch(originalFaresStart())
  try {
    const response = await api.get(apiRoutes.FLIGHTS_ORIGINAL_FARES(shoppingId))
    dispatch(originalFaresSuccess(response.data))
  } catch (error) {
    dispatch(originalFaresError(error.response ? error.response.data : error))
  }
}

export {
  originalFaresGet,
  originalFaresStart,
  originalFaresSuccess,
  originalFaresError,
  resetOriginalFares
}
