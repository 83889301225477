/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import { authBookingPost } from 'actions/authBooking'
import { setPaymentStatus } from 'actions/paymentStatus'
import { changeCurrentStep } from 'actions/stepBarAction'
import { mapIssueReservation } from 'api-data-io/gds/reservation'
import apiRoutes from 'constants/apiRoutes'
import CHECKOUT_STEPS from 'constants/checkoutStepBar'
import GDS_MESSAGES from 'constants/gdsMessages'
import MISSING_INFO_STEPS from 'constants/missingInfoStepBar'
import { PAYMENT_METHODS } from 'constants/payment'
import PAYMENT_STATUS from 'constants/paymentStatus'
import { getDigitCount } from 'helpers/creditCard'
import { handleError } from 'helpers/handleActionErrors'
import { getBookingToken, removeBookingSessionInfo } from 'helpers/sessionStorage'
import { successLogMyReservation } from 'helpers/successLogMyReservation'
import {
  getBrowserMetadata,
  resetCreditCardData,
  removeSublosPersistValidation
} from 'helpers/utils'
import { getSecondPaymentMethodSelectionSubState } from 'selectors/paymentMethodSelection'
import api, { apiBookingV2, apiSublos, apiV2 } from 'services/api'

const {
  fetchReservationStart,
  fetchReservationSuccess,
  fetchReservationError,
  clearReservation,
  clearReservationError,
  purchaseStart,
  purchaseSuccess,
  purchaseDataTemp,
  clearPurchaseDataTemp,
  purchaseError,
  clearPurchase,
  clearPurchaseError,
  paymentBookingStart,
  paymentBookingSuccess,
  paymentBookingError,
  resetPaymentBookingError,
  resetSecondPaymentBookingError,
  setPaymentData,
  resetPaymentData,
  resetSecondPaymentData,
  setPassengersFormikData,
  resetPassengersFormikData,
  exchangesPaymentStart,
  exchangesPaymentSuccess,
  exchangesPaymentError,
  clearExchangesPayment,
  clearExchangesPurchaseError,
  addReservationEmailStart,
  addReservationEmailSuccess,
  addReservationEmailError,
  clearEmailAddition,
  setIsUnticketedPurchase
} = createActions({
  FETCH_RESERVATION_START: () => {},
  FETCH_RESERVATION_SUCCESS: data => ({ data }),
  FETCH_RESERVATION_ERROR: error => ({ error }),
  CLEAR_RESERVATION: () => {},
  CLEAR_RESERVATION_ERROR: () => {},
  PURCHASE_START: () => {},
  PURCHASE_SUCCESS: data => ({ data }),
  PURCHASE_DATA_TEMP: data => ({ data }),
  CLEAR_PURCHASE_DATA_TEMP: () => ({}),
  PURCHASE_ERROR: error => ({ error }),
  CLEAR_PURCHASE: () => {},
  CLEAR_PURCHASE_ERROR: () => {},
  PAYMENT_BOOKING_START: () => {},
  PAYMENT_BOOKING_SUCCESS: data => ({ data }),
  PAYMENT_BOOKING_ERROR: error => ({ error }),
  RESET_PAYMENT_BOOKING_ERROR: () => {},
  RESET_SECOND_PAYMENT_BOOKING_ERROR: () => {},
  SET_PAYMENT_DATA: data => ({ data }),
  RESET_PAYMENT_DATA: () => {},
  RESET_SECOND_PAYMENT_DATA: () => {},
  SET_PASSENGERS_FORMIK_DATA: data => ({ data }),
  RESET_PASSENGERS_FORMIK_DATA: () => {},
  EXCHANGES_PAYMENT_START: () => {},
  EXCHANGES_PAYMENT_SUCCESS: data => ({ data }),
  EXCHANGES_PAYMENT_ERROR: error => ({ error }),
  CLEAR_EXCHANGES_PAYMENT: () => {},
  CLEAR_EXCHANGES_PURCHASE_ERROR: () => ({}),
  ADD_RESERVATION_EMAIL_START: () => {},
  ADD_RESERVATION_EMAIL_SUCCESS: data => ({ data }),
  ADD_RESERVATION_EMAIL_ERROR: error => ({ error }),
  CLEAR_EMAIL_ADDITION: () => {},
  SET_IS_UNTICKETED_PURCHASE: warnings => ({ warnings })
})

const fetchReservation = (reservationCode, lastName, b64) => {
  return async dispatch => {
    dispatch(fetchReservationStart())

    try {
      const currentToken = getBookingToken()

      if (!currentToken) {
        if (b64) {
          const data = JSON.parse(window.atob(window.atob(b64 || '') || ''))

          data &&
            (await dispatch(
              authBookingPost({ lastName: data['LAST_NAME'], reservationCode: data['PNR'] })
            ))
        } else if (reservationCode && lastName) {
          await dispatch(authBookingPost({ lastName, reservationCode }))
        }
      }

      const response = await apiBookingV2.get(apiRoutes.RESERVATIONS)

      dispatch(fetchReservationSuccess(response.data))
    } catch (error) {
      removeBookingSessionInfo()

      dispatch(fetchReservationError(error.response ? error.response.data : error.message))
    }
  }
}

const reservationExternal = (reservationCode, lastName, preferenceId) => {
  return async dispatch => {
    dispatch(fetchReservationStart())

    try {
      const currentToken = getBookingToken()

      if (!currentToken) {
        reservationCode &&
          lastName &&
          preferenceId &&
          (await dispatch(authBookingPost({ lastName, reservationCode, preferenceId })))
      }

      const response = await apiBookingV2.get(
        apiRoutes.RESERVATION_EXTERNAL(reservationCode, lastName, preferenceId)
      )

      dispatch(fetchReservationSuccess(response.data))
      return response.data
    } catch (error) {
      dispatch(fetchReservationError(error.response ? error.response.data : error.message))
    }
  }
}

const fetch3pReservation = (shoppingId, cancelToken) => {
  return async dispatch => {
    dispatch(purchaseStart())

    try {
      const response = await apiBookingV2.get(apiRoutes.GLOBAL_RESERVATIONS(shoppingId), {
        ...(cancelToken ? { cancelToken: cancelToken.token } : {})
      })
      const currentToken = getBookingToken()

      if (!currentToken && response.data) {
        const { data } = response
        const reservationCode = data?.reservationCode || data?.bookingMetadata?.reservationCode
        const lastName =
          data?.lastName || (data?.passengersData?.length && data?.passengersData[0].lastName)

        if (reservationCode && lastName) {
          await dispatch(authBookingPost({ lastName, reservationCode }))
        }
      }

      dispatch(purchaseSuccess(response.data))
      return { data: response.data }
    } catch (error) {
      let parseError = error.response ? error.response.data : error.message

      if (parseError?.statusCode === 404) {
        if (parseError?.errorMessage === 'PENDING') return

        if (parseError?.errorMessage === GDS_MESSAGES.PURCHASE_RESPONSE_NOT_FOUND) {
          parseError = {
            ...parseError,
            description: GDS_MESSAGES.PURCHASE_RESPONSE_NOT_FOUND
          }
        }
      }

      dispatch(purchaseError(parseError))
      return { error: parseError }
    }
  }
}

const fetchMercadoPagoReservation = (reservationCode, lastName, cancelToken) => {
  return async dispatch => {
    dispatch(purchaseStart())

    try {
      const currentToken = getBookingToken()

      if (!currentToken) {
        await dispatch(authBookingPost({ lastName, reservationCode }))
      }

      const response = await apiBookingV2.get(
        apiRoutes.BOOKING_EXTERNAL_MERCADO_PAGO(reservationCode, lastName),
        { ...(cancelToken ? { cancelToken: cancelToken.token } : {}) }
      )

      dispatch(purchaseSuccess(response.data))
      return { data: response.data }
    } catch (error) {
      const parseError = error.response ? error.response.data : error.message

      dispatch(purchaseError(parseError))
      return { error: parseError }
    }
  }
}

export const maskCardData = paymentData => {
  return (dispatch, getState) => {
    const { selectedType } =
      getState().paymentMethodSelection || getSecondPaymentMethodSelectionSubState()

    if (selectedType !== PAYMENT_METHODS.OFFLINE) {
      const {
        paymentValidations,
        selectedPaymentMethod,
        masks: { creditCard }
      } = getState().paymentMethodSelection || getSecondPaymentMethodSelectionSubState()

      const digitCount = getDigitCount(paymentValidations, selectedPaymentMethod)

      dispatch(setPaymentData(resetCreditCardData(paymentData, creditCard, digitCount)))
    }
  }
}

/** @todo Abstract purchase steps (specially for booking purchases) in one method, to include:
 *
 * exchangesPurchase
 * ancillariesPurchase
 * and future booking purchases
 *
 * generateReservation (maybe?)
 * */

/** @param formData is stored in Redux to show in case of error,
 * is separate from payload because of different field names among form and payload field
 * @param payload of reservation post
 * */
const generateReservation = (formData, payload) => {
  return async dispatch => dispatch(purchase(formData, payload))
}

/** @param formData is mapped to payload and is not stored in Redux as other methods
 * @param pnr of previously generated reservation for global payment flows
 * */
const retryGlobalExternalPayment = (formData, pnr) => {
  const payload = mapIssueReservation(formData, pnr)
  return async dispatch => dispatch(purchase(null, payload, pnr))
}

/** @param formData is stored in Redux to show in case of error,
 * is separate from payload because of different field names among form and payload field
 * @param pnr of previously generated reservation for global payment flows
 * */
const retryGlobalPayment = (formData, payload, pnr) => {
  return async dispatch => dispatch(purchase(formData, payload, pnr))
}

// TODO: remove reserPurchaseDataTemp when the ticket SOP-616 is resolved
const reserPurchaseDataTemp = () => {
  return dispatch => {
    dispatch(clearPurchaseDataTemp())
  }
}

const sublosPurchase = ({
  companyCode,
  reservationCode,
  employeeCode,
  shoppingId,
  payload
}) => async dispatch => {
  dispatch(purchaseStart())
  removeSublosPersistValidation()

  try {
    payload && maskCardData(payload)

    Object.assign(payload, getBrowserMetadata())

    successLogMyReservation(shoppingId, `SUBLOS_CONFIRMATION_${reservationCode}_REQUEST`, payload)

    const response = await apiSublos.post(
      apiRoutes.SUBLOS_RESERVATIONS_CONFIRMATION({ companyCode, reservationCode, employeeCode }),
      payload
    )
    successLogMyReservation(shoppingId, `SUBLOS_CONFIRMATION_${reservationCode}_RESPONSE_SUCCESS`, response)

    dispatch(purchaseSuccess(response.data))
  } catch (error) {
    successLogMyReservation(shoppingId, `SUBLOS_CONFIRMATION_${reservationCode}_RESPONSE_ERROR`, error.response ? error.response.data : error)
    dispatch(purchaseError(error.response ? error.response.data : error))
  }
}

const purchase = (formData, payload, pnr) => {
  return async dispatch => {
    formData && maskCardData(formData)
    let parsePayload = { ...payload }
    if (parsePayload.hasOwnProperty('creditCard')) {
      delete parsePayload.creditCard
    }
    if (parsePayload.hasOwnProperty('documents')) {
      delete parsePayload.documents
    }

    dispatch(purchaseStart())
    // Reflect.has(payload, 'globalDetails')
    //   && successLogMyReservation(parsePayload.shoppingId, '3P_REDIRECT', payload)

    successLogMyReservation(parsePayload.shoppingId, 'purchaseStart', { date: Date.now() })
    dispatch(setPaymentStatus(null))

    dispatch(purchaseDataTemp(parsePayload))

    try {
      let response
      Object.assign(payload, getBrowserMetadata())
      if (pnr) {
        response = await apiV2.put(apiRoutes.GLOBAL_PAYMENT(pnr), payload)
      } else {
        const route = payload.holdOptionId ? apiRoutes.HOLD_OPTIONS : apiRoutes.RESERVATIONS
        response = await apiV2.post(route, payload)
      }

      if (payload.isMercadoPagoSelected) {
        const currentToken = getBookingToken()

        if (!currentToken && response.data) {
          const { data } = response
          const reservationCode = data?.reservationCode || data?.bookingMetadata?.reservationCode
          const lastName =
            data?.lastName || (data?.passengersData?.length && data?.passengersData[0].lastName)
          const preferenceId =
            data.payments && data.payments[0] && data.payments[0].externalInformation?.preferenceId
          if (reservationCode && lastName && preferenceId) {
            await dispatch(authBookingPost({ lastName, reservationCode, preferenceId }))
          }
        }
      }

      const loggedResponse = {
        status: response.status,
        statusText: response.statusText,
        data: response.data,
        url: response.config.url
      }

      successLogMyReservation(parsePayload.shoppingId, 'purchaseEnd', loggedResponse)
      dispatch(purchaseSuccess(response.data))
    } catch (error) {
      handleError(error, purchaseError, dispatch, true, parsePayload.shoppingId)
    }
  }
}

/** @FormData is stored in Redux to show in case of error,
 * is separate from payload because of different field names among form and payload field
 * */
const generatePaymentBooking = (payload, formData) => {
  return async dispatch => {
    maskCardData(formData)

    dispatch(paymentBookingStart())
    try {
      Object.assign(payload, getBrowserMetadata())
      const response = await apiV2.post(apiRoutes.BOOKING_PAYMENT, payload)
      if (
        response.data?.authorization &&
        response.data?.authorization?.messageCode === PAYMENT_STATUS.FAILURE
      ) {
        dispatch(
          paymentBookingError({
            statusCode: 400,
            errorMessage: response.data?.authorization?.details
          })
        )
      } else {
        dispatch(paymentBookingSuccess(response.data))
      }
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(paymentBookingError(err))
    }
  }
}

/** @FormData is stored in Redux to show in case of error,
 * is separate from payload because of different field names among form and payload field
 * */
const generateExchangesPayment = (payload, formData) => {
  return async dispatch => {
    maskCardData(formData)
    dispatch(exchangesPaymentStart())

    try {
      Object.assign(payload, getBrowserMetadata())
      const response = await apiV2.post(apiRoutes.EXCHANGES, {
        ...payload
      })

      if (
        response.data?.authorization &&
        response.data?.authorization?.messageCode === PAYMENT_STATUS.FAILURE
      ) {
        dispatch(
          exchangesPaymentError({
            statusCode: 400,
            errorMessage: response.data?.authorization?.details
          })
        )
      } else {
        dispatch(exchangesPaymentSuccess(response.data))
      }
    } catch (error) {
      const err = error.response ? error.response.data : error

      dispatch(exchangesPaymentError(err))
    }
  }
}

const addReservationEmail = (pnr, email) => {
  return async dispatch => {
    dispatch(addReservationEmailStart())

    try {
      const response = await api.put(apiRoutes.RESERVATION_PNR(pnr), { email })

      dispatch(addReservationEmailSuccess(response.data))
    } catch (error) {
      const err = error.response ? error.response.data : error

      dispatch(addReservationEmailError(err))
    }
  }
}

const setReservationSuccessfulPayment = warningMessages => {
  return dispatch => {
    dispatch(setIsUnticketedPurchase(warningMessages))
    dispatch(resetPaymentData())
    dispatch(changeCurrentStep(CHECKOUT_STEPS.CONFIRM, 2))
  }
}

const setReservationSuccessfulPaymentMissingInfo = () => {
  return dispatch => {
    dispatch(resetPaymentData())
    dispatch(changeCurrentStep(MISSING_INFO_STEPS.CONFIRM, 2))
  }
}

export {
  fetchReservationStart,
  fetchReservationSuccess,
  fetchReservationError,
  fetchReservation,
  reservationExternal,
  sublosPurchase,
  clearReservation,
  clearReservationError,
  purchaseStart,
  purchaseSuccess,
  purchaseDataTemp,
  purchaseError,
  resetPaymentBookingError,
  resetSecondPaymentBookingError,
  generateReservation,
  paymentBookingStart,
  paymentBookingError,
  paymentBookingSuccess,
  generatePaymentBooking,
  setPaymentData,
  resetPaymentData,
  resetSecondPaymentData,
  setPassengersFormikData,
  resetPassengersFormikData,
  exchangesPaymentStart,
  exchangesPaymentError,
  exchangesPaymentSuccess,
  generateExchangesPayment,
  clearExchangesPayment,
  clearExchangesPurchaseError,
  addReservationEmailStart,
  addReservationEmailSuccess,
  addReservationEmailError,
  addReservationEmail,
  setIsUnticketedPurchase,
  setReservationSuccessfulPayment,
  setReservationSuccessfulPaymentMissingInfo,
  clearPurchase,
  clearPurchaseError,
  clearEmailAddition,
  retryGlobalPayment,
  retryGlobalExternalPayment,
  clearPurchaseDataTemp,
  reserPurchaseDataTemp,
  fetch3pReservation,
  fetchMercadoPagoReservation
}
