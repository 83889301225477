import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import mediaQueries from 'components/media-queries'
import e2eClasses from 'helpers/automation'

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  margin-left: 86px;
  margin-top: 36px;

  ${mediaQueries.tablet`
    flex-direction: column;
    max-height: 0;
    pointer-events: none;
    margin: 0;
    transition: all 500ms ease-in-out;
    opacity: 0;
    pointer-events: all;
    opacity: 1;
    max-height: unset;
  `};
`

export const StyledLabel = styled.div`
  margin-bottom: 14px;
  width: 100%;

  ${mediaQueries.tablet`
    z-index: 4;
    margin: 0;
  `};
`

export const LabelText = styled.label.attrs(() => ({
  className: e2eClasses('home.navbar.itemTitle')
}))`
  color: ${({ theme }) => theme.colors.blue50};
  font-size: 1em;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  margin-left: 20px;
  font-size: 14px;
`

export const Column = styled.div`
  display: flex;

  ${mediaQueries.tablet`
    margin: 17px 0;
  `}
`

export const StyledSection = styled.div.attrs(() => ({
  className: e2eClasses('home.navbar.itemsBox')
}))`
  margin-bottom: 24px;

  ${mediaQueries.tablet`
    margin-bottom: 0;
  `};
`

const itemOptionLinkCSS = css`
  display: flex;
  width: 18em;
  color: ${({ theme }) => theme.colors.doveGray};
  flex-direction: row;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.25;
  align-items: center;
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.regular};
  z-index: 1;

  &:hover {
    font-family: ${({ theme }) => theme.fonts.semiBold};
  }
`

export const StyledLink = styled(Link).attrs(() => ({
  className: e2eClasses('home.navbar.itemOptionLink')
}))`
  ${itemOptionLinkCSS}
`
export const StyledAnchor = styled.a.attrs(() => ({
  className: e2eClasses('home.navbar.itemOptionLink')
}))`
  ${itemOptionLinkCSS}
`

export const Separator = styled.span`
  height: 60%;
  padding-right: 2em;
  border-left: 1px solid ${({ theme }) => theme.colors.gallery};
  margin-bottom: 20%;

  ${mediaQueries.tablet`
    display: none;
  `};
`

export const Icon = styled.img.attrs(() => ({
  className: e2eClasses('home.navbar.itemIcon')
}))`
  max-height: 16px;
  max-width: 16px;
  position: absolute;
`

export const LinkWrapper = styled.div.attrs(() => ({
  className: e2eClasses('home.navbar.itemOptionBox')
}))`
  margin-top: 15px;
`
