import { handleActions } from 'redux-actions'

import {
  fetchAirportsStart,
  fetchAirportsSuccess,
  fetchAirportsError,
  clearAirports
} from 'actions/catalog/airports'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchAirportsStart]: state => ({
      ...state,
      data: {},
      error: null,
      isFetching: true
    }),
    [fetchAirportsSuccess]: (state, { payload: { data } }) => {
      return {
        ...state,
        data,
        error: null,
        isFetching: false
      }
    },
    [fetchAirportsError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    }),
    [clearAirports]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default reducer
