export default {
  CASH: 'AFOP',
  DEBIT_CARD: 'DEBIT_CARD',
  INTEREST_FREE_INSTALLMENTS: 'INTEREST_FREE_INSTALLMENTS',
  FIXED_INSTALLMENTS: 'FIXED_INSTALLMENTS',
  CREDIT_CARD: 'CREDIT_CARD'
}

export const FIELD_OPTIONS = {
  INTEREST_FREE_INSTALLMENTS: 'interestFreeInstallments',
  FIXED_INSTALLMENTS: 'fixedInstallments',
  DEBIT_CARD: 'debitCardDetails',
  NO_INSTALLMENTS: 'noInstallments'
}
