/* eslint-disable import/no-cycle */
import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'

import { IconNames } from 'components/icons'
import CONFIG from 'config'
import e2eClasses from 'helpers/automation'
import { isUserLogged, LogOut } from 'helpers/flightOffers'
import { removeLastLangCode } from 'helpers/utils'

import {
  Container,
  StyledLabel,
  LabelText,
  ColumnsContainer,
  StyledImage,
  IconWithMargin,
  StyledAnchor
} from './styled'

const LanguageSelector = props => {
  const { t } = useTranslation()

  const isLoggedInArplus = () => {
    if (isUserLogged()) {
      return LogOut(props.clearMilesLogin)
    }
    return
  }

  const onClickToFlag = () => {
    isLoggedInArplus()
    removeLastLangCode()
  }

  return (
    <Container>
      <StyledLabel>
        <IconWithMargin
          e2eClass={e2eClasses('home.navbar.itemLanguageIcon')}
          name={IconNames.Language}
          size={23}
          color={props.theme.colors.blue50}
        />
        <LabelText>{t('general.header.language-selector.title')}</LabelText>
      </StyledLabel>
      <ColumnsContainer>
        {props.languages.map(({ country, link, flag, language, id }, index) => (
          <StyledAnchor
            key={`${country}--${index}`}
            href={link}
            selected={props.selectedLang && props.selectedLang.id === id}
            onClick={onClickToFlag}
          >
            <StyledImage src={`${CONFIG.CONTENT_CDN_BASE_URL}${flag}`} alt="" />
            {language ? `${country} (${language})` : `${country}`}
          </StyledAnchor>
        ))}
      </ColumnsContainer>
    </Container>
  )
}

LanguageSelector.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.object),
  onClickLanguage: PropTypes.func
}

export default withTheme(LanguageSelector)
