import { PASSENGER_TYPES } from 'constants/passengerTypes'

export const countFromReservation = passengersData => {
  const counts = { adtCount: 0, chdCount: 0, infCount: 0 }

  passengersData?.forEach(({ passengerType }) => {
    switch (passengerType) {
      case PASSENGER_TYPES.ADT_SHORT:
        counts.adtCount++
        break
      case PASSENGER_TYPES.CHD_SHORT:
        counts.chdCount++
        break
      case PASSENGER_TYPES.INF_SHORT:
        counts.infCount++
        break
      default:
        break
    }
  })

  return counts
}

export const segmentsFromReservation = legs => {
  const legMapping = (legs || []).map(
    ({
      segments: [
        { origin, destination, bookingClass, operatingAirline, flightNumber, departure, arrival }
      ]
    }) => ({
      origin,
      destination,
      bookingClass,
      operatingAirline,
      flightNumber,
      departureDate: departure,
      arrivalDate: arrival
    })
  )

  return legMapping
}
