const LANGUAGE_TAGS = {
  AUSTRALIA: 'en-AU',
  BOLIVIA: 'es-BO',
  BRAZIL: 'pt-BR',
  CHILE: 'es-CL',
  COLOMBIA: 'es-CO',
  DEFAULT_ONLY_LANG: 'es',
  ENGLISH_ONLY_LANG: 'en',
  FRENCH_ONLY_LANG: 'fr',
  ITALIAN_ONLY_LANG: 'it',
  PORTUGUESE_ONLY_LANG: 'pt',
  DEFAULT: 'es-AR',
  ENGLISH_USA: 'en-US',
  ENGLISH: 'en-AR',
  ESPAÑA_EN: 'en-EU',
  ESPAÑA: 'es-EU',
  FRANCE: 'fr-FR',
  GREAT_BRITAIN: 'en-GB',
  ITALIAN_LOCALE: 'it-IT', //Used in select places. it-EU is the normal culture we use.
  ITALY: 'it-EU',
  MEXICO: 'es-MX',
  NEW_ZEALAND: 'en-NZ',
  ONLY_ARG_LANG: 'AR',
  PARAGUAY: 'es-PY',
  PERU: 'es-PE',
  SPANISH_USA: 'es-US',
  SPANISH: 'es-ES',
  URUGUAY: 'es-UY',
  ENGLISH_EUROPE: 'en-150'
}

const LANGCODE_TO_CHANNEL = {
  'EN-AR': 'WEB_AR',
  'EN-AU': 'WEB_AU',
  'EN-EU': 'WEB_EU',
  'EN-GB': 'WEB_GB',
  'EN-NZ': 'WEB_NZ',
  'EN-US': 'WEB_US',
  'ES-AR': 'WEB_AR',
  'ES-BO': 'WEB_BO',
  'ES-CL': 'WEB_CL',
  'ES-CO': 'WEB_CO',
  'ES-EU': 'WEB_ES',
  'ES-MX': 'WEB_MX',
  'ES-PE': 'WEB_PE',
  'ES-PY': 'WEB_PY',
  'ES-US': 'WEB_US',
  'ES-UY': 'WEB_UY',
  'FR-FR': 'WEB_FR',
  'IT-EU': 'WEB_IT',
  'PT-BR': 'WEB_BR'
}

const SUBLOS_LANGCODE_TO_CHANNEL = {
  'ES-AR': 'SUBLOS_AR',
  'EN-AR': 'SUBLOS_AR'
}

const SUPPORTED_ES = [
  LANGUAGE_TAGS.BOLIVIA,
  LANGUAGE_TAGS.CHILE,
  LANGUAGE_TAGS.COLOMBIA,
  LANGUAGE_TAGS.DEFAULT,
  LANGUAGE_TAGS.ESPAÑA,
  LANGUAGE_TAGS.MEXICO,
  LANGUAGE_TAGS.PARAGUAY,
  LANGUAGE_TAGS.PERU,
  LANGUAGE_TAGS.SPANISH_USA,
  LANGUAGE_TAGS.URUGUAY
]

const SUPPORTED_EN = [
  LANGUAGE_TAGS.AUSTRALIA,
  LANGUAGE_TAGS.ENGLISH_USA,
  LANGUAGE_TAGS.ENGLISH,
  LANGUAGE_TAGS.ESPAÑA_EN,
  LANGUAGE_TAGS.GREAT_BRITAIN,
  LANGUAGE_TAGS.NEW_ZEALAND
]

const SUPPORTED_LNGS = [
  ...SUPPORTED_ES,
  ...SUPPORTED_EN,
  LANGUAGE_TAGS.BRAZIL,
  LANGUAGE_TAGS.ITALY,
  LANGUAGE_TAGS.FRANCE
]

const EU_LANGS = [
  LANGUAGE_TAGS.GREAT_BRITAIN,
  LANGUAGE_TAGS.ESPAÑA,
  LANGUAGE_TAGS.ITALY,
  LANGUAGE_TAGS.FRANCE
]

const IS_GLOBAL_3P = [LANGUAGE_TAGS.CHILE]

const getChannelsFromLangs = langs => langs.map(lang => LANGCODE_TO_CHANNEL[lang.toUpperCase()])

const EU_CHANNELS = getChannelsFromLangs(EU_LANGS)

export {
  LANGUAGE_TAGS,
  LANGCODE_TO_CHANNEL,
  SUBLOS_LANGCODE_TO_CHANNEL,
  SUPPORTED_LNGS,
  EU_LANGS,
  EU_CHANNELS,
  IS_GLOBAL_3P,
  SUPPORTED_EN,
  SUPPORTED_ES
}
