import { handleActions } from 'redux-actions'
import {
  fetchFlightOffersMatrixStart,
  fetchFlightOffersMatrixSuccess,
  fetchFlightOffersMatrixError,
  setRedirectFromFlightOffersMatrix,
  resetFlightOffersMatrix
} from 'actions/flightOffersMatrix'

const defaultState = {
  data: {},
  error: null,
  isFetching: false,
  redirect: false
}

const reducer = handleActions(
  {
    [fetchFlightOffersMatrixStart]: state => ({
      ...state,
      data: {},
      error: null,
      isFetching: true
    }),
    [fetchFlightOffersMatrixSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data.data,
      error: null,
      isFetching: false
    }),
    [fetchFlightOffersMatrixError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: {},
      error
    }),
    [setRedirectFromFlightOffersMatrix]: (state, { payload: { redirect } }) => ({
      ...state,
      redirect
    }),
    [resetFlightOffersMatrix]: state => ({
      ...state,
      data: {},
      error: null,
      isFetching: false
    })
  },
  defaultState
)

export default reducer
