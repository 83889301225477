/* eslint-disable import/no-cycle */
export default {
  ANCILLARIES_NOT_ALL_SUCCESSFUL: 'ANCILLARIES_NOT_ALL_SUCCESSFUL',
  ARPLUS_ACCOUNT_DATA_UNSUBSCRIBE: 'ARPLUS_ACCOUNT_DATA_UNSUBSCRIBE',
  ARPLUS_ALERT_MILES: 'ARPLUS_ALERT_MILES',
  ARPLUS_CREDIT_MILES_FORM_FLIGHT_DATE_DATE_PICKER_MOBILE: 'ARPLUS_CREDIT_MILES_FORM_FLIGHT_DATE_DATE_PICKER_MOBILE',
  ARPLUS_CREDIT_MILES_FORM_PURCHASE_DATE_DATE_PICKER_MOBILE: 'ARPLUS_CREDIT_MILES_FORM_PURCHASE_DATE_DATE_PICKER_MOBILE',
  ARPLUS_CREDIT_MILES: 'ARPLUS_CREDIT_MILES',
  ARPLUS_GIFT_MILES: 'ARPLUS_GIFT_MILES',
  ARPLUS_LOGIN_DISCLAIMER: 'ARPLUS_LOGIN_DISCLAIMER',
  ARPLUS_LOGOUT: 'ARPLUS_LOGOUT',
  ARPLUS_PAYMENT_MILES: 'ARPLUS_PAYMENT_MILES',
  ARPLUS_RECOVER_MEMBERSHIP_CODE: 'ARPLUS_RECOVER_MEMBERSHIP_CODE',
  ARPLUS_RESET_PASSWORD: 'ARPLUS_RESET_PASSWORD',
  ARPLUS_TRANSFER_MILES: 'ARPLUS_TRANSFER_MILES',
  ARPLUS_UNSUBSCRIBE_DISCLAIMER: 'ARPLUS_UNSUBSCRIBE_DISCLAIMER',
  ARPLUS_VALIDATE_LOGIN: 'ARPLUS_VALIDATE_LOGIN',
  ASSIST_CARD_EXCHANGE: 'ASSIST_CARD_EXCHANGE',
  BIRTHDAY: 'BIRTHDAY',
  COMPARE_FARES: 'COMPARE_FARES',
  CONTESTS_DETAILS: 'CONTESTS_DETAILS',
  CONTESTS_FETCH_ERROR: 'CONTESTS_FETCH_ERROR',
  CORPORATE_CREDENTIALS_RECOVERY: 'CORPORATE_CREDENTIALS_RECOVERY',
  CREDIT_CARD_CVC_INFO: 'CREDIT_CARD_CVC_INFO',
  EQUIPAMENT_CLAIM_DATE_PICKER_MOBILE: 'EQUIPAMENT_CLAIM_DATE_PICKER_MOBILE',
  ERROR_SEAT_RESULT: 'ERROR_SEAT_RESULT',
  EVENTS_TERMS_CONDITIONS: 'EVENTS_TERMS_CONDITIONS',
  EXCHANGES_DATE_PICKER_MOBILE: index => `EXCHANGES_DATE_PICKER_MOBILE_${index}`,
  EXCHANGES_INVALID_DATES: 'EXCHANGES_INVALID_DATES',
  EXCHANGES_REDIRECT_MY_RESERVATION: 'EXCHANGES_REDIRECT_MY_RESERVATION',
  FLIGHT_ITINERARY: 'FLIGHT_ITINERARY',
  FLIGHT_NUMBER_DATE_PICKER_MOBILE: 'FLIGHT_NUMBER_DATE_PICKER_MOBILE',
  FLIGHT_ROUTE_DATE_PICKER_MOBILE: 'FLIGHT_ROUTE_DATE_PICKER_MOBILE',
  KEEP_CURRENT_CHANGES: 'KEEP_CURRENT_CHANGES',
  KEEP_CURRENT_RESERVATION: 'KEEP_CURRENT_RESERVATION',
  MILES_LOGIN: 'MILES_LOGIN',
  MORE_PASSENGER_INFO: 'MORE_PASSENGER_INFO',
  NO_BAGGAGE_INCLUDED: 'NO_BAGGAGE_INCLUDED',
  PAIS_TAXES_INFO: 'PAIS_TAXES_INFO',
  PASSENGER_AGE_WARNING: 'PASSENGER_AGE_WARNING',
  PAYMENT_BOOKING: 'PAYMENT_BOOKING',
  PUNCTUALITY_INDEX: 'PUNCTUALITY_INDEX',
  RATE_DETAILS: 'RATE_DETAILS',
  REDIRECTION_PAYMENT_GATEWAY: 'REDIRECTION_PAYMENT_GATEWAY',
  REFUND_DISCARD: 'REFUND_DISCARD',
  REFUND_FORM_DATE_PICKER_MOBILE: 'REFUND_FORM_DATE_PICKER_MOBILE',
  REFUND_FORM_ERROR: 'REFUND_FORM_ERROR',
  REFUND_FORM_VALIDATE_ERROR: 'REFUND_FORM_VALIDATE_ERROR',
  SCHEDULED_FLIGHTS_DATE_PICKER_MOBILE: 'SCHEDULED_FLIGHTS_DATE_PICKER_MOBILE',
  SCHEDULED_FLIGHTS_VIEW_ITINERARY: 'SCHEDULED_FLIGHTS_VIEW_ITINERARY',
  SEARCH_BOX_DATE_PICKER_MOBILE: index => `SEARCH_BOX_DATE_PICKER_MOBILE_${index}`,
  SEAT_RESULT_SUCCESS: 'SEAT_RESULT_SUCCESS',
  SEAT_SELECTION_ABOUT_TO_LEAVE: 'SEAT_SELECTION_ABOUT_TO_LEAVE',
  SSR_ABOUT_TO_LEAVE: 'SSR_ABOUT_TO_LEAVE',
  SUBLOS_AEROWEB_lOGIN: 'SUBLOS_AEROWEB_lOGIN',
  SUBLOS_CANCEL_RESERVATION_REQUEST: 'SUBLOS_CANCEL_RESERVATION_REQUEST',
  SUBLOS_EXPIRATION_DATE: 'SUBLOS_EXPIRATION_DATE',
  SUBLOS_FLIGHT_SELECTION_CAPACITY: 'SUBLOS_FLIGHT_SELECTION_CAPACITY',
  SUBLOS_FLIGHT_SELECTION_ITINERARY: 'SUBLOS_FLIGHT_SELECTION_ITINERARY',
  SUBLOS_NO_AVAILABLE_FLIGHTS: 'SUBLOS_NO_AVAILABLE_FLIGHTS',
  SUBLOS_NO_NAME_WARNING: 'SUBLOS_NO_NAME_WARNING',
  SUBLOS_RETRY_SEND_REQUEST: 'SUBLOS_RETRY_SEND_REQUEST',
  THIRD_PARTY_REDIRECT_PAYMENT: 'THIRD_PARTY_REDIRECT_PAYMENT',
  TIME_OUT_CHECKOUT: 'TIME_OUT_CHECKOUT',
  TIME_OUT_FLIGHT: 'TIME_OUT_FLIGHT',
  TIME_OUT_MP: 'TIME_OUT_MP',
  TIME_OUT_RESERVATION: 'TIME_OUT_RESERVATION',
  TIME_OUT_SHOPPING_ID: 'TIME_OUT_SHOPPING_ID',
  TRANSFER_BOOKING: 'TRANSFER_BOOKING'
}
