/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import { mapCacForm } from 'api-data-io/bo/contactUs'
import apiRoutes from 'constants/apiRoutes'
import api, { formDataApi } from 'services/api'

const {
  claimContactUsStart,
  claimContactUsSuccess,
  claimContactUsError,
  claimContactUsClear,
  cacClaimsStart,
  cacClaimsSuccess,
  cacClaimsError,
  cacClaimsClear,
  claimContactUsReasonsSuggestionsStart,
  claimContactUsReasonsSuggestionsSuccess,
  claimContactUsReasonsSuggestionsError,
  claimContactUsExperiencePlacesStart,
  claimContactUsExperiencePlacesSuccess,
  claimContactUsExperiencePlacesError,
  claimContactUsAboutStart,
  claimContactUsAboutSuccess,
  claimContactUsAboutError
} = createActions({
  CLAIM_SUGGESTION_DATA_START: () => {},
  CLAIM_SUGGESTION_DATA_SUCCESS: data => ({ data }),
  CLAIM_SUGGESTION_DATA_ERROR: error => ({ error }),
  CLAIM_SUGGESTION_DATA_CLEAR: () => {},
  CLAIM_CONTACT_US_START: () => {},
  CLAIM_CONTACT_US_SUCCESS: data => ({ data }),
  CLAIM_CONTACT_US_ERROR: error => ({ error }),
  CLAIM_CONTACT_US_CLEAR: () => {},
  CAC_CLAIMS_START: () => {},
  CAC_CLAIMS_SUCCESS: data => ({ data }),
  CAC_CLAIMS_ERROR: error => ({ error }),
  CAC_CLAIMS_CLEAR: () => {},
  CLAIM_CONTACT_US_REASONS_SUGGESTIONS_START: () => {},
  CLAIM_CONTACT_US_REASONS_SUGGESTIONS_SUCCESS: data => ({ data }),
  CLAIM_CONTACT_US_REASONS_SUGGESTIONS_ERROR: error => ({ error }),
  CLAIM_CONTACT_US_EXPERIENCE_PLACES_START: () => {},
  CLAIM_CONTACT_US_EXPERIENCE_PLACES_SUCCESS: data => ({ data }),
  CLAIM_CONTACT_US_EXPERIENCE_PLACES_ERROR: error => ({ error }),
  CLAIM_CONTACT_US_ABOUT_START: () => {},
  CLAIM_CONTACT_US_ABOUT_SUCCESS: data => ({ data }),
  CLAIM_CONTACT_US_ABOUT_ERROR: error => ({ error })
})

const postClamisContactUs = (route, formikFields) => async dispatch => {
  dispatch(cacClaimsStart())
  try {
    const contactUsFormData = new FormData()

    const contactUsClaimBody = JSON.stringify(mapCacForm(formikFields))

    contactUsFormData.append(
      'claimForm',
      new Blob([contactUsClaimBody], {
        type: 'application/json'
      })
    )

    if (formikFields.attachments) {
      formikFields.attachments.forEach(attachment =>
        contactUsFormData.append('attachments', attachment)
      )
    } else {
      contactUsFormData.append('attachments', null)
    }

    const response = await formDataApi.post(route, contactUsFormData)
    dispatch(
      cacClaimsSuccess((response.data && { data: response.data, form: formikFields.form }) || true)
    )
  } catch (error) {
    dispatch(cacClaimsError(error.response ? error.response.data : error))
  }
}

//Endpoint to get most of the combo boxes of page
const getClaimsContactUs = route => async dispatch => {
  dispatch(claimContactUsStart())
  try {
    const response = await api.get(route)
    dispatch(claimContactUsSuccess(response.data))
  } catch (error) {
    dispatch(claimContactUsError(error.response ? error.response.data : error))
  }
}

const getClaimsContactUsReasonsSuggestions = route => async dispatch => {
  dispatch(claimContactUsReasonsSuggestionsStart())
  try {
    const response = await api.get(route)
    dispatch(claimContactUsReasonsSuggestionsSuccess(response.data))
  } catch (error) {
    dispatch(claimContactUsReasonsSuggestionsError(error.response ? error.response.data : error))
  }
}

const getClaimsContactUsExperiencePlaces = route => async dispatch => {
  dispatch(claimContactUsExperiencePlacesStart())
  try {
    const response = await api.get(route)
    dispatch(claimContactUsExperiencePlacesSuccess(response.data))
  } catch (error) {
    dispatch(claimContactUsExperiencePlacesError(error.response ? error.response.data : error))
  }
}

const getClaimsContactUsAbout = route => async dispatch => {
  dispatch(claimContactUsAboutStart())
  try {
    const response = await api.get(route)
    dispatch(claimContactUsAboutSuccess(response.data))
  } catch (error) {
    dispatch(claimContactUsAboutError(error.response ? error.response.data : error))
  }
}

const requestClamisContactUs = formikFields => async dispatch =>
  dispatch(postClamisContactUs(apiRoutes.CLAIM_CONTACT_US, formikFields))

const fetchClamisContactUs = () => async dispatch =>
  dispatch(getClaimsContactUs(apiRoutes.CLAIM_CONTACT_US))

const fetchClaimsContactUsReasonsSuggestions = () => async dispatch =>
  dispatch(getClaimsContactUsReasonsSuggestions(apiRoutes.CLAIM_CONTACT_US_REASONS_SUGGESTIONS))

const fetchClaimsContactUsExperiencePlace = reasonId => async dispatch =>
  dispatch(
    getClaimsContactUsExperiencePlaces(apiRoutes.CLAIM_CONTACT_US_EXPERIENCE_PLACES(reasonId))
  )

const fetchClaimsContactUsAbout = experiencePlaceId => async dispatch =>
  dispatch(getClaimsContactUsAbout(apiRoutes.CLAIM_CONTACT_US_ABOUT(experiencePlaceId)))

const clearFormik = () => async dispatch => {
  dispatch(cacClaimsClear())
  dispatch(claimContactUsClear())
}

export {
  fetchClamisContactUs,
  fetchClaimsContactUsReasonsSuggestions,
  fetchClaimsContactUsExperiencePlace,
  fetchClaimsContactUsAbout,
  requestClamisContactUs,
  clearFormik,
  claimContactUsStart,
  claimContactUsSuccess,
  claimContactUsError,
  claimContactUsClear,
  cacClaimsStart,
  cacClaimsSuccess,
  cacClaimsError,
  cacClaimsClear,
  claimContactUsReasonsSuggestionsStart,
  claimContactUsReasonsSuggestionsSuccess,
  claimContactUsReasonsSuggestionsError,
  claimContactUsExperiencePlacesStart,
  claimContactUsExperiencePlacesSuccess,
  claimContactUsExperiencePlacesError,
  claimContactUsAboutStart,
  claimContactUsAboutSuccess,
  claimContactUsAboutError
}
