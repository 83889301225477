export default {
  //common routes
  'metadata.title.flex-dates-calendar': 'Calendario de vuelos',
  'metadata.description.flex-dates-calendar': 'Conseguí las mejores frecuencias para tu vuelo',
  'metadata.title.flights-offers': 'Ofertas de vuelos',
  'metadata.description.flights-offers': 'Conseguí las mejores tarifas para tu vuelo',
  'metadata.title.flights-offers-error': 'Error',
  'metadata.description.flights-offers-error': 'Error en la oferta de vuelos',
  'metadata.title.booking': 'Mi reserva',
  'metadata.description.booking': 'Consulta tu reserva en Aerolíneas Argentinas',
  'metadata.title.booking.error': 'Error de reserva',
  'metadata.description.booking.error': 'Página de error en reserva',
  'metadata.title.booking.ancillaries': 'Servicios adicionales',
  'metadata.description.booking.ancillaries': 'Compra servicios adicionales para tu vuelo ',
  'metadata.title.booking.special-requests': 'Pedidos especiales',
  'metadata.description.booking.special-requests': 'Realiza pedidos especiales para tu vuelo',
  'metadata.title.contests-page': 'Proveedores',
  'metadata.description.contests-page': 'Información sobre concursos para proveedores vigentes',
  'metadata.title.booking.payment': 'Pago de reserva',
  'metadata.description.booking.payment': 'Paga tu reserva',
  'metadata.title.booking.missing-info': 'Mi reserva',
  'metadata.description.booking.missing-info': 'Falta información para completar tu reserva',
  'metadata.title.checkout-ancillaries': 'Comprar servicios adicionales',
  'metadata.description.checkout-ancillaries': 'Compra servicios adicionales para tu vuelo',
  'metadata.title.checkout-retry': 'Checkout - Reintento',
  'metadata.description.checkout-retry':
    'Página de checkout, comprá tus pasajes en Aerolíneas Argentinas',
  'metadata.title.checkout-error': 'Checkout - Error',
  'metadata.description.checkout-error': 'Error en la compra de pasajes',
  'metadata.title.seats-selection': 'Selección de asientos',
  'metadata.description.seats-selection': 'Selecciona tu asiento',
  'metadata.title.frequently-asked-questions': 'Preguntas frecuentes',
  'metadata.description.frequently-asked-questions': 'Página de preguntas frecuentes',
  'metadata.title.flight-status': 'Estado de vuelos',
  'metadata.description.flight-status': 'Consulta el estado de tu vuelo',
  'metadata.title.exchanges': 'Cambio de vuelos',
  'metadata.description.exchanges': 'Cambia tu pasaje',
  'metadata.title.exchanges-error': 'Error de cambio de reserva',
  'metadata.description.exchanges-error': 'Página de error en cambio de reserva',
  'metadata.title.common-error': 'Error',
  'metadata.description.common-error': 'Página de error',
  'metadata.title.scheduled-flights': 'Vuelos programados',
  'metadata.description.scheduled-flights':
    'Consulta los vuelos programados por Aerolíneas Argentinas',
  'metadata.title.press-communication': 'Comunicados de prensa',
  'metadata.description.press-communication':
    'Consulta los comunicados de prensa de Aerolíneas Argentinas',
  'metadata.title.press-releases': 'Comunicados de prensa',
  'metadata.description.press-releases':
    'Consulta los comunicados de prensa de Aerolíneas Argentinas',
  'metadata.title.corporate-agreements': 'Acuerdos corporativos',
  'metadata.description.corporate-agreements':
    'Consulta los acuerdos corporativos disponibles para tu empresa',
  'metadata.title.corporate-agreements-register': 'Acuerdos corporativos',
  'metadata.description.corporate-agreements-register':
    'Genera tu cuenta para acceder a la sección de Acuerdos Corporativos',
  'metadata.title.events': 'Congresos',
  'metadata.description.events':
    'Consulta los próximos congresos a los que podés viajar con Aerolíneas Argentinas',
  'metadata.title.refund-form': 'Devolución de tickets',
  'metadata.description.refund-form': 'Devolvé tu pasaje ',
  'metadata.title.refund-status-page': 'Devolución de tickets - Estado',
  'metadata.description.refund-status-page': 'Consultá el estado de la devolución de tu ticket',
  'metadata.title.refund-request-tuavta': 'Devolución tasa aeropuertaria',
  'metadata.description.refund-request-tuavta':
    'Formulario para solicitar la devolución de la tasa aeropuertaria',
  'metadata.title.refund': 'Devoluciones',
  'metadata.description.refund': 'Página de devoluciones',
  'metadata.title.external-payment': 'Aerolineas - Pagos externos',
  'metadata.description.external-payment':
    'Realice su pago con un servicio externo a Aerolíneas Argentinas',

  //arplus
  'metadata.title.arplus.login': 'Arplus - Ingreso',
  'metadata.description.arplus.login': 'Ingresa a tu cuenta de arplus ',
  'metadata.title.arplus.dashboard': 'Arplus - Mi cuenta',
  'metadata.description.arplus.dashboard': 'Consulta la información de tu cuenta arplus',
  'metadata.title.arplus.last-operations': 'Arplus - Últimas operaciones',
  'metadata.description.arplus.last-operations':
    'Consulta las últimas operaciones de tu cuenta arplus',
  'metadata.title.arplus.forgotten-password': 'Arplus - Olvidé mi contraseña',
  'metadata.description.arplus.forgotten-password': 'Recupera tu contraseña de socio arpus',
  'metadata.title.arplus.recover-membership-code': 'Arplus - Recuperar número de socio ',
  'metadata.description.arplus.recover-membership-code': 'Recupera tu número de socio arplus',
  'metadata.title.arplus.register': 'Arplus - Registro',
  'metadata.description.arplus.register':
    'Registrate para obtener los beneficios exclusivos de Arplus',
  'metadata.title.arplus.pre-register': 'Arplus - Asociate',
  'metadata.description.arplus.pre-register':
    'Asociate a arplus para acceder a beneficios exclusivos de Aerolíneas Argentinas',
  'metadata.title.arplus.add-miles': 'Arplus - Acreditar millas',
  'metadata.description.arplus.add-miles': 'Acredita millas para tu próximo viaje con Arplus',
  'metadata.title.arplus.unsubscribe': 'Arplus - Baja de cuenta',
  'metadata.description.arplus.unsubscribe': 'Dar de baja tu cuenta de Arplus',
  'metadata.title.arplus.buy-miles': 'Arplus - Comprar millas',
  'metadata.description.arplus.buy-miles': 'Compra millas para tu próximo viaje con Arplus',
  'metadata.title.arplus.account-evaluation': 'Arplus - Mi cuenta',
  'metadata.description.arplus.account-evaluation': 'Consulta la información de tu cuenta Arplus',
  'metadata.title.arplus.transfer-miles': 'Arplus - Transferir millas',
  'metadata.description.arplus.transfer-miles': 'Transferí millas a otro usuario de Arplus',
  'metadata.title.arplus.elite-partners': 'Arplus - Socios elite',
  'metadata.description.arplus.elite-partners':
    'Consulta las características de los socios elite de Arplus',
  'metadata.title.arplus.account-data': 'Arplus - Mi cuenta',
  'metadata.description.arplus.account-data': 'Consulta los datos de tu cuenta Arplus',
  'metadata.title.arplus.first-access': 'Arplus - Acceso',
  'metadata.description.arplus.first-access':
    'Accede por primera vez a tu cuenta de Arplus para conseguir beneficios exclusivos ',
  'metadata.title.arplus.first-access-password': 'Arplus - Acceso',
  'metadata.description.arplus.first-access-password':
    'Ingresa tu contraseña por primera vez en Arplus',
  'metadata.title.arplus.email-recovery': 'Arplus - Recuperar mail ',
  'metadata.description.arplus.email-recovery': 'Recupera tu email de socio Arplus',
  'metadata.title.arplus.credit-miles': 'Arplus - Acreditar millas',
  'metadata.description.arplus.credit-miles':
    'Acredita las millas que compraste a través de Arplus ',
  'metadata.title.arplus.gift-miles': 'Arplus - Regalar millas',
  'metadata.description.arplus.gift-miles': 'Regala millas a otro usuario de Arplus',
  'metadata.title.arplus.email-confirmation': 'Arplus - Confirma tu mail',
  'metadata.description.arplus.email-confirmation':
    'Confirma tu mail de registro para el programa Arplus',
  'metadata.title.arplus.change-password': 'Arplus - Cambiar contraseña',
  'metadata.description.arplus.change-password': 'Cambia la contraseña de tu cuenta Arplus',
  'metadata.title.arplus.verify-account': 'Arplus - Mi cuenta',
  'metadata.description.arplus.verify-account': 'Proceso de verificación de tu cuenta Arplus',
  'metadata.title.arplus.maintenance': 'Página en mantenimiento',
  'metadata.description.arplus.maintenance':
    'Arplus se encuentra en mantenimiento, por favor vuelva a intentarlo más tarde',

  //sublos
  'metadata.title.sublos.login': 'Sublos - Ingreso',
  'metadata.description.sublos.login': 'Ingresa a tu cuenta de Sublos',
  'metadata.title.sublos.main-page': 'Sublos',
  'metadata.description.sublos.main-page':
    'Sacá pasajes exclusivos para empleados de Aerolíneas Argentinas',
  'metadata.title.sublos.error': 'Sublos - Error',
  'metadata.description.sublos.error': 'Error en página de Sublos',
  'metadata.title.sublos.site-in-progress': 'Sublos - Sitio en progreso',
  'metadata.description.sublos.site-in-progress': 'Sitio en progreso, vuelva luego',

  //reservation service form (USA-FORM)
  'metadata.title.form.ticket-cancelation': 'Anulación de pasajes',
  'metadata.description.form.ticket-cancelation':
    'Cancelá la compra de tu pasaje dentro de las 24 horas siguientes a realizar la reserva',
  'metadata.title.form.customer-service-contact': 'Atención al cliente',
  'metadata.description.form.customer-service-contact':
    'Contáctanos para sugerencias, felicitaciones o elevar un reclamo hacia nuestro servicio',
  'metadata.title.form.bookings-and-services-contact': 'Reservas y servicios',
  'metadata.description.form.bookings-and-services-contact':
    'Realice consultas sobre su reserva, compra  u otros servicios de Aerolíneas Argentinas',
  'metadata.title.form.sales-offices': 'Sucursales',
  'metadata.description.form.sales-offices':
    'Encuentre todas las sucursales de Aerolíneas Argentinas alrededor del mundo',
  'metadata.title.form.baggage-complaints-contact': 'Reclamos de equipaje',
  'metadata.description.form.baggage-complaints-contact':
    'Realice un reclamo por equipaje perdido, extraviado, dañado o demorado para la entrega',
  'metadata.title.form.ticket-refund': 'Devolución de pasajes',
  'metadata.description.form.ticket-refund':
    'Solicite una devolución por arrepentimiento de compra, consulte los casos permitidos'
}
