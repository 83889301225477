/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect'

import { IconNames } from 'components/icons'
import FARE_TYPES from 'constants/fareTypes'
import FORMATS from 'constants/formats'
import RESULT_TYPES from 'constants/resultTypes'
import { getDayJsObject } from 'helpers/dates'
import { getFlightInformationData, getBaggageEnabledModalMessage } from 'helpers/flightConditions'
import { isEmpty, numberWithDec } from 'helpers/utils'
import NAMESPACES from 'localization/constants/namespaces'
import { getAirports } from 'selectors/airports'
import { getBrands } from 'selectors/brands'
import { getCharges } from 'selectors/charges'
import { getIsEvenFlow } from 'selectors/exchangesPayment'
import { getExchangesOffersEmptyLeg } from 'selectors/flightsOffers'
import { getIsFetchingOriginalFares, getOriginalFares } from 'selectors/originalFares'
import { getReservationLegsData } from 'selectors/reservation'

const getFlightSummaryState = state => state.flightSummary

export const getFlightSummaryError = createSelector(
  [getFlightSummaryState],
  flightSummary => flightSummary.error
)

export const getFlightSummaryErrorMessage = createSelector([getFlightSummaryError], error =>
  !isEmpty(error) ? error.errorMessage : ''
)

export const getFlightSummaryData = createSelector([getFlightSummaryState], flightSummary =>
  flightSummary ? flightSummary?.data : null
)
export const getFlightSummaryMinimumPayment = createSelector(
  [getFlightSummaryState],
  flightSummary => (flightSummary ? flightSummary.data?.minimumFirstCardPayment : null)
)
export const getFlightSummaryCurrency = createSelector([getFlightSummaryState], flightSummary =>
  flightSummary ? flightSummary.data?.revenueData?.currency : null
)

export const getLastFlightDate = createSelector([getFlightSummaryData], flightSummary => {
  if (!flightSummary) {
    return null
  }

  const legs = flightSummary.flightInformation.legs
  const segments = legs[legs.length - 1].segments
  const lastDate = getDayJsObject(segments[segments.length - 1].departure)
  return new Date(lastDate.year(), lastDate.month(), lastDate.date())
})

export const isFetchingFlightSummary = createSelector(
  [getFlightSummaryState, getFlightSummaryData],
  (state, data) => state.isFetching || isEmpty(data)
)

export const isFetchingState = createSelector(
  [getFlightSummaryState, getIsFetchingOriginalFares],
  (state, isFetchingOriginalFares) => isFetchingOriginalFares || state.isFetching
)

export const getFlightSummary = createSelector(
  [getFlightSummaryData, isFetchingState, getAirports, getCharges, getOriginalFares],
  (flightSummary, isFetchingState, airports, charges, originalFares) => {
    if (isEmpty(flightSummary)) {
      return {
        isFetching: isFetchingState
      }
    }

    const { passengers, flightInformation, summaryMetadata } = flightSummary
    const { adtCount, chdCount, infCount } = passengers

    const passengerCount = adtCount + chdCount + infCount
    const isMultidestiny = summaryMetadata.flightType === RESULT_TYPES.MULTIDESTINY
    const legsDescription = formatLegsDescription(flightInformation, airports, isMultidestiny)
    const flightData = flightInformation.legs
    const offersSelected = flightInformation.offersSelected

    return {
      shoppingId: summaryMetadata.shoppingId,
      isFetching: isFetchingState,
      flightData,
      offersSelected,
      detail: {
        passengerCount,
        passengers,
        path: {
          legs: legsDescription
        },
        fares: {
          total: flightSummary.total,
          currency: summaryMetadata.currency,
          ...getFaresDetails(
            flightSummary.productBreakdown || flightSummary.productBreakdownMiles,
            flightSummary.originalProductBreakdown,
            originalFares
          ),
          charges
        }
      },
      fullDetail: {
        social: [
          { icon: IconNames.Facebook },
          { icon: IconNames.Twitter },
          { icon: IconNames.Instagram },
          { icon: IconNames.Google },
          { icon: IconNames.Linkedin }
        ]
      },
      summaryMetadata
    }
  }
)

export const getFlightSummaryDetails = createSelector([getFlightSummary], flightSummaryData =>
  flightSummaryData ? flightSummaryData.detail : null
)

const getFaresDetails = (productBreakdown, originalProductBreakdown, originalFares) => {
  if (originalProductBreakdown && productBreakdown) {
    return getExchangesFares(productBreakdown)
  }

  return productBreakdown ? getFlightsOffersFares(productBreakdown, originalFares) : null
}

export const getExchangesFares = productBreakdown => {
  const verifiedProductBreakdown =
    productBreakdown.total !== undefined || productBreakdown.changeFeeTotal !== undefined
      ? productBreakdown
      : productBreakdown.flightSummary &&
        productBreakdown.flightSummary.data &&
        productBreakdown.flightSummary.data.productBreakdown

  if (verifiedProductBreakdown) {
    return {
      items: [
        {
          type: FARE_TYPES.FARES_DIFFERENCE,
          description: `${NAMESPACES.EXCHANGES}:booking.exchanges.fares.difference`,
          amount: verifiedProductBreakdown.total,
          fareDetails: []
        },
        {
          type: FARE_TYPES.OPERATION_CHARGES,
          description: `${NAMESPACES.EXCHANGES}:booking.exchanges.fares.change-fee`,
          amount: verifiedProductBreakdown.changeFeeTotal || 0,
          fareDetails: []
        }
      ]
    }
  }
  return {}
}

const getFlightsOffersFares = ({ airBreakdown }, originalFares) => {
  return {
    items: [
      {
        type: FARE_TYPES.BASE,
        description: 'general.fares.base-fare',
        amount: Array.isArray(airBreakdown.baseFare.price)
          ? airBreakdown.baseFare.price[0].amount
          : airBreakdown.baseFare.price,
        fareDetails: airBreakdown.baseFare.details,
        icon: IconNames.HelpCircle
      },
      {
        type: FARE_TYPES.TAXES,
        description: 'general.fares.taxes',
        amount:
          (airBreakdown.taxes.price &&
            (Array.isArray(airBreakdown.taxes.price)
              ? airBreakdown.taxes.price[0].amount
              : airBreakdown.taxes.price)) ||
          0,
        fareDetails: airBreakdown.taxes.details,
        icon: IconNames.HelpCircle
      },
      {
        type: FARE_TYPES.CHARGES,
        description: 'general.fares.surcharges',
        amount: Array.isArray(airBreakdown.surcharges.price)
          ? airBreakdown.surcharges.price[0].amount
          : airBreakdown.surcharges.price || 0,
        fareDetails: airBreakdown.surcharges.details,
        icon: IconNames.HelpCircle
      },
      {
        type: FARE_TYPES.DISCOUNT,
        description: 'general.fares.discount',
        amount:
          originalFares && originalFares >= airBreakdown.total
            ? originalFares - airBreakdown.total
            : 0
      }
    ]
  }
}

const formatLegsDescription = (flightInformation, airports, isMultidestiny) => {
  if (isMultidestiny) {
    return formatMultidestiny(flightInformation, airports)
  }
  if (flightInformation.legs.length === 1) {
    return formatLegOneWay(flightInformation, airports)
  }
  return formatLegRoundTrip(flightInformation, airports)
}

const formatLegOneWay = (flightInformation, airports) => {
  return flightInformation.legs.map(leg => {
    const [first, second] = leg.segments
    const airportDeparture = (airports || []).find(({ iataCode }) => iataCode === first.origin)
    const airportArrival = (airports || []).find(({ iataCode }) =>
      second ? iataCode === second.destination : iataCode === first.destination
    )

    const cityDeparture = airportDeparture ? airportDeparture.city : { name: '' }
    const cityArrival = airportArrival ? airportArrival.city : { name: '' }

    return {
      description: `${cityDeparture ? cityDeparture.name : ''}-${
        cityArrival ? cityArrival.name : ''
      }/${getDayJsObject(first.departure).format(FORMATS.DAY_AND_MONTH_NAME)}`
    }
  })
}

const formatLegRoundTrip = (flightInformation, airports) => {
  return flightInformation.legs.map(leg => {
    const [departure] = leg.segments
    const airport = (airports || []).find(item => item.iataCode === departure.origin)
    const city = airport ? airport.city : { name: '' }

    return {
      description: `${city ? city.name : ''}/${getDayJsObject(departure.departure).format(
        FORMATS.DAY_AND_MONTH_NAME
      )}`
    }
  })
}

const formatMultidestiny = (flightInformation, airports) => {
  return flightInformation.legs.map((leg, index) => {
    const [first, second] = leg.segments
    const airportDeparture = (airports || []).find(({ iataCode }) => iataCode === first.origin)
    const airportArrival = (airports || []).find(({ iataCode }) =>
      second ? iataCode === second.destination : iataCode === first.destination
    )

    const cityDeparture = airportDeparture ? airportDeparture.city : { name: '' }
    const cityArrival = airportArrival ? airportArrival.city : { name: '' }

    return {
      description: `TRAMO ${index + 1} | ${cityDeparture.name}-${cityArrival.name}/${getDayJsObject(
        first.departure
      ).format(FORMATS.DAY_AND_MONTH_NAME)}`
    }
  })
}

export const getSummaryMetadata = createSelector([getFlightSummaryData], data =>
  data ? data.summaryMetadata : {}
)

export const getTotalAmount = createSelector([getFlightSummaryData], data =>
  data ? data.total : null
)

export const getTotalCurrency = createSelector([getFlightSummaryData], data =>
  data ? data.totalCurrency : null
)

export const getTotalMiles = createSelector([getFlightSummaryData], data =>
  data ? data.totalMiles : null
)

export const getFlightInformation = createSelector([getFlightSummaryData], data =>
  data ? data.flightInformation : null
)

export const getFlightSummaryRevenue = createSelector([getFlightSummaryData], data =>
  data ? data.revenueData : null
)

export const getOriginalProductBreakdown = createSelector([getFlightSummaryData], data =>
  data ? data.originalProductBreakdown : null
)

export const getLocalCurrencyProductBreakdown = createSelector([getFlightSummaryData], data =>
  data ? data.productBreakdown : null
)

export const getProductBreakdownMiles = createSelector([getFlightSummaryData], data =>
  data ? data.productBreakdownMiles : null
)

export const getProductBreakdown = createSelector(
  [getLocalCurrencyProductBreakdown, getProductBreakdownMiles],
  (productBreakdown, productBreakdownMiles) => productBreakdown || productBreakdownMiles || null
)

export const getFlightSummaryPassengers = createSelector([getFlightSummaryData], data =>
  data ? data.passengers : null
)

export const getFlightType = createSelector([getSummaryMetadata], metadata =>
  metadata ? metadata.flightType : null
)

export const getSummaryProgramId = createSelector([getSummaryMetadata], metadata =>
  metadata ? metadata.programId : null
)

export const getOffersSelected = createSelector([getFlightInformation], flightInformation =>
  flightInformation ? flightInformation.offersSelected : []
)

export const getFlightLegs = createSelector(
  [getFlightInformation, getOffersSelected],
  (flightInformation, offersSelected) =>
    flightInformation
      ? (offersSelected || []).map((offers, i) => {
          flightInformation.legs[i].segments[0].bookingClass = offersSelected[i].bookingClass
          flightInformation.legs[i].segments[0].cabinClass = offersSelected[i].cabinClass
        }) && flightInformation.legs
      : null
)

export const getOriginalLegs = createSelector([getFlightInformation], flightInformation =>
  flightInformation ? flightInformation.originalLegs : null
)

export const getExchangesFaresDetails = createSelector(
  [getOriginalProductBreakdown, getProductBreakdown],
  (original, productBreakdown) =>
    original && productBreakdown ? getExchangesFares(productBreakdown) : null
)

export const getCurrency = createSelector([getSummaryMetadata], metadata =>
  metadata ? metadata.currency : null
)

export const getFlightSummaryShoppingId = createSelector([getSummaryMetadata], metadata =>
  metadata ? metadata.shoppingId : null
)

export const getAirBreakdown = createSelector([getProductBreakdown], productBreakdown =>
  productBreakdown ? productBreakdown.airBreakdown : null
)

export const getAirBreakdownTotal = createSelector([getAirBreakdown], airBreakdownTotal =>
  airBreakdownTotal ? airBreakdownTotal.total : null
)

export const getDiscountPromocode = createSelector(
  [getAirBreakdownTotal, getOriginalFares],
  (airBreakdownTotal, originalFares) => {
    let result =
      originalFares && originalFares >= airBreakdownTotal ? originalFares - airBreakdownTotal : null

    return numberWithDec(result)
  }
)

export const getProductBreakdownTotal = createSelector([getProductBreakdown], productBreakdown =>
  productBreakdown ? productBreakdown.total : null
)

export const getFlightSummaryRoutes = createSelector([getSummaryMetadata], metadata =>
  metadata ? metadata.routes : null
)

export const isInterlineFlight = createSelector([getSummaryMetadata], metadata =>
  metadata ? metadata.summaryType === RESULT_TYPES.INTERLINE : null
)

export const getFirstLegDepartureDate = createSelector([getFlightLegs], legs =>
  legs && legs[0] && legs[0].segments && legs[0].segments[0] ? legs[0].segments[0].departure : null
)

export const getFlightSummaryFareRules = createSelector([getFlightSummaryData], data =>
  !isEmpty(data) ? data.fareRules : null
)

export const getExchangesOriginalFareRules = createSelector([getFlightSummaryData], flightSummary =>
  flightSummary ? flightSummary.originalFareRules : null
)

export const getExchangesOriginalFareRulesFirstBrandConfig = createSelector(
  [getExchangesOriginalFareRules],
  fareRules => (fareRules ? fareRules[0] && fareRules[0].brandConfigurations : null)
)

export const getFlightSummaryFaresRulesForBrand = createSelector(
  [
    getFlightLegs,
    getFlightSummaryFareRules,
    getFlightSummaryRoutes,
    getFlightType,
    getOffersSelected,
    getExchangesOriginalFareRulesFirstBrandConfig,
    getExchangesOffersEmptyLeg
  ],
  (legs, fares, routes, flightType, offersSelected, originalFares, exchangesOfferEmptyLeg) =>
    getFlightInformationData(
      legs,
      routes,
      fares,
      flightType,
      offersSelected,
      originalFares,
      exchangesOfferEmptyLeg
    )
)

export const getFlightSummaryMarket = createSelector([getSummaryMetadata], data =>
  data ? data.market : null
)

const getFareConditionsFromBrand = createSelector(
  [getFlightSummaryFaresRulesForBrand],
  flightConditionsBrands => {
    return flightConditionsBrands ? flightConditionsBrands.fareConditions : null
  }
)

export const isBaggageNotAllowed = createSelector([getFareConditionsFromBrand], fareConditions => {
  const baggageEnabled = (fareConditions || []).map(
    ({ conditions }) => conditions.checkedBaggage.enabled
  )

  return baggageEnabled && baggageEnabled.some(value => !value)
})

export const getAllowedBaggageMessage = createSelector(
  [getFareConditionsFromBrand, getFlightSummaryMarket],
  (fareConditions, flightSummaryMarket) => {
    const baggageEnabled = (fareConditions || []).map(
      ({ conditions }) => conditions.checkedBaggage.enabled
    )

    if (baggageEnabled) {
      const families = (fareConditions || []).map(({ conditions }) => conditions.id)

      const textNoBaggageIncluded = getBaggageEnabledModalMessage(
        families,
        flightSummaryMarket,
        baggageEnabled
      )
      return textNoBaggageIncluded
    }
    return []
  }
)

export const getAncillariesBreakdown = createSelector([getProductBreakdown], productBreakdown =>
  productBreakdown ? productBreakdown.ancillaryBreakdown : null
)

export const getExchangesFaresInfo = createSelector([getProductBreakdown], productBreakdown =>
  productBreakdown ? getExchangesFares(productBreakdown) : null
)

const getItineraryProps = (_, props) =>
  props
    ? {
        multidestiny: props.isMultidestiny,
        legNumber: props.legNumber,
        flightData: props.flightData,
        exchanges: props.isExchanges
      }
    : null

export const getItineraryBrandCabinClassTypes = createSelector(
  [
    getOffersSelected,
    getItineraryProps,
    getBrands,
    getExchangesOffersEmptyLeg,
    getExchangesOriginalFareRulesFirstBrandConfig,
    getReservationLegsData
  ],
  (offersSelected, itineraryProps, brands, originalLeg, originalFares, legs) => {
    let offerSelected = ''
    let offerSelectedBrandId = ''
    let offerSelectedCabinClass = ''
    let offerSelectedCabinType = ''

    if (itineraryProps) {
      const { legNumber, multidestiny, exchanges, flightData } = itineraryProps

      if (!originalLeg && flightData?.segments[0]?.bookingClass) {
        offerSelectedCabinClass = flightData.segments[0].cabinClass
        offerSelectedCabinType = flightData.segments[0].bookingClass
      } else if (offersSelected) {
        if (exchanges && originalLeg !== null) {
          /* If is exchanges of only one leg of a round trip, cabin and class information of
            the leg that is not being changed must be retrieved from reservation
            (only brandId available in originalFareRules) */
          if (legNumber === originalLeg + 1) {
            offerSelectedBrandId = originalFares && Object.keys(originalFares)[0]

            const segment = legs && legs[originalLeg].segments && legs[originalLeg].segments[0]

            offerSelectedCabinClass = segment && segment.cabinClass
            offerSelectedCabinType = segment && segment.bookingClass
          } else {
            offerSelected = offersSelected[0]
          }
        } else {
          offerSelected = offersSelected[legNumber - 1 || 0] || (multidestiny && offersSelected[0])
        }
      }
    }

    const brandId =
      (itineraryProps.flightData && itineraryProps.flightData.brandId) ||
      (offerSelected && offerSelected.brandId) ||
      offerSelectedBrandId

    let brandName = ''
    if (!isEmpty(brands) && brandId) {
      const brand = brands.filter(brand => brand.code === brandId)[0]
      if (brand) {
        brandName = brand.name
      }
    }

    const cabinClass =
      (itineraryProps && itineraryProps.flightData && itineraryProps.flightData.cabinClass) ||
      (offerSelected && offerSelected.cabinClass) ||
      offerSelectedCabinClass

    const classType =
      (itineraryProps && itineraryProps.flightData && itineraryProps.flightData.bookingClass) ||
      (offerSelected && offerSelected.bookingClass) ||
      offerSelectedCabinType

    return { brandName, cabinClass, classType }
  }
)

export const isEvenExchange = createSelector(
  [getFlightSummary, getIsEvenFlow],
  (flightSummary, isEvenFlow) =>
    (flightSummary && flightSummary.detail && flightSummary.detail.fares.total === 0) ||
    isEvenFlow ||
    false
)
