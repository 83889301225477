/* eslint-disable import/no-cycle */
import { getRouteByPathname } from 'helpers/routeMatchers'
import { isEmpty } from 'helpers/utils'
import { getLandingForPathName } from 'server/services/helpers'

export const mapSeoData = data => ({
  title: data?.title,
  description: data?.meta_description,
  og_description: data?.og_description,
  og_image: data?.og_image,
  og_url: data?.og_url
})

export const getHeaderSeoMetadataForStore = (store, pathname, language) => {
  let seoDataResult = mapSeoData({})

  if (!isEmpty(store)) {
    const route = getRouteByPathname(pathname)
    const isReservationServicesRoute = route?.name === 'RESERVATION_SERVICES'
    const isHomeRoute = route?.name === 'HOME'

    if (!(isReservationServicesRoute || isHomeRoute)) {
      const landing = getLandingForPathName(pathname, store.landings, language)
      const seoMetadata = landing ? landing.seoMetadataReference : null

      seoDataResult = mapSeoData(seoMetadata)
    }
  }

  return seoDataResult
}
