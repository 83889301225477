import { handleActions } from 'redux-actions'
import {
  fetchTermsStart,
  fetchTermsSuccess,
  fetchTermsError,
  clearTermsState
} from 'actions/cms/termsConditions'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

// Model to follow is -> {data: {[url]: {...}}, error, isFetching}
const reducer = handleActions(
  {
    [fetchTermsStart]: () => ({
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchTermsSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [fetchTermsError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearTermsState]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
