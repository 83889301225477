import styled, { css } from 'styled-components'

import mediaQueries from 'components/media-queries'
import theme from 'components/theme'
import e2eClasses from 'helpers/automation'

const backgroundColorsMap = {
  primary: theme.colors.blueLight,
  secondary: theme.colors.blueLighter,
  success: theme.colors.greenLight,
  error: theme.colors.redLight,
  warning: theme.colors.yellowLight,
  formInputError: theme.colors.transparent,
  formInputSuccess: theme.colors.transparent,
  reserveRate: theme.colors.blueLighter,
  onTime: theme.colors.greenLight,
  cancelled: theme.colors.redLight,
  arrived: theme.colors.greenLight,
  delayed: theme.colors.yellowLight,
  departed: theme.colors.greenLight,
  discount: theme.colors.blueLighter,
  deflected: theme.colors.yellowLight,
  rescheduled: theme.colors.yellowLight,
  cookies: theme.colors.blueLighter,
  paymentRetrial: theme.colors.redLight
}

export const fontColorMap = {
  primary: theme.colors.blue,
  secondary: theme.colors.blue,
  success: theme.colors.green,
  error: theme.colors.red,
  warning: theme.colors.doveGrayer,
  formInputError: theme.colors.red,
  formInputSuccess: theme.colors.green,
  reserveRate: theme.colors.blue,
  onTime: theme.colors.green,
  cancelled: theme.colors.red,
  arrived: theme.colors.green,
  delayed: theme.colors.doveGrayer,
  departed: theme.colors.green,
  discount: theme.colors.blue,
  deflected: theme.colors.doveGrayer,
  rescheduled: theme.colors.doveGrayer,
  cookies: theme.colors.blue,
  paymentRetrial: theme.colors.red
}

const padding = '15px 25px 14px 25px'

const paddingReduced = '5px 0'

const paddingReducedHeight = '10px 15px'

const paddingCookies = '16px 25px 16px 25px'

const paddingMap = {
  primary: padding,
  secondary: padding,
  success: padding,
  error: padding,
  warning: padding,
  formInputError: paddingReduced,
  formInputSuccess: paddingReduced,
  reserveRate: padding,
  onTime: paddingReducedHeight,
  cancelled: paddingReducedHeight,
  arrived: paddingReducedHeight,
  delayed: paddingReducedHeight,
  departed: paddingReducedHeight,
  discount: padding,
  deflected: paddingReducedHeight,
  rescheduled: paddingReducedHeight,
  cookies: paddingCookies,
  paymentRetrial: padding
}

export const StyledMessage = styled.div.attrs(() => ({
  className: e2eClasses('messages.box')
}))`
  display: flex;
  padding: ${({ type }) => paddingMap[type]};
  border-radius: 3px;
  width: ${({ isCircular }) => (isCircular ? '133px' : '100%')};
  background-color: ${({ type }) => backgroundColorsMap[type]};
  font-family: ${({ theme }) => theme.fonts.regular};
  ${({ type }) =>
    type === 'cookies' &&
    `position: fixed; 
   bottom: 0; 
   z-index: 99999;
   transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
   overflow: hidden;`}
   opacity: ${({ showCookiesMessage }) => (showCookiesMessage !== false ? '1' : '0')};

  ${({ center }) => center && 'justify-content: center;'}
  b {
    font-family: ${({ theme }) => theme.fonts.semiBold};
  }

  ${({ isCircular }) =>
    isCircular &&
    css`
      height: 26px;
    `};

  ${mediaQueries.mobile`
    border-radius: 0;
    font-size: ${({ type }) => (type === 'cookies' ? '16px' : '14px')};
    padding: ${({ type }) => (type === 'cookies' ? '17px 26px' : paddingMap[type])};
  `};
`
export const IconContainer = styled.div.attrs(props => ({
  className: props.e2eClass && `${e2eClasses('common.inputIconError')}${props.e2eClass}`
}))`
  margin-right: 10px;
  align-items: center;
  display: flex;

  ${mediaQueries.mobile`
    align-items: flex-start;
  `} svg {
    vertical-align: bottom;

    path {
      fill: ${({ type }) => fontColorMap[type]};
    }
  }

  ${({ noIcon }) => noIcon && `display: none;`};

  ${({ alignIconTop }) => alignIconTop && `align-items: flex-start;`};
`

export const MaxWidthContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  ${({ type }) =>
    type === 'cookies' &&
    css`
      height: 40px;

      /** Fixes broken mobile layout  */
      ${mediaQueries.mobile`
        height: auto;
      `}
    `};

  ${({ center }) => center && 'justify-content: center'};
  ${mediaQueries.mobile`
    align-items: flex-start;
  `};
`

export const MessageContainer = styled.div.attrs(props => ({
  className: props.e2eClass && `${e2eClasses('common.inputError')}${props.e2eClass}`
}))`
  display: flex;
  color: ${({ type }) => fontColorMap[type]};
  font-size: ${({ type }) => (type === 'cookies' || type === 'paymentRetrial' ? '16px' : '14px')};
  ${({ noGrow }) => !noGrow && 'width: 100%;'}
  justify-content: space-between;
  align-items: center;

  a {
    font-family: ${({ theme }) => theme.fonts.semiBold};
    color: ${({ type }) => fontColorMap[type]};
  }

  ${mediaQueries.mobile`
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
  `};
`

export const MessageButton = styled.button.attrs(props => ({
  className: props.e2eClass && `${e2eClasses('common.button')}${props.e2eClass}`
}))`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  border: solid 1px ${({ type }) => fontColorMap[type]};
  border-radius: 3px;
  padding: ${({ type }) => (type === 'cookies' ? '10px 60px' : '5px')};
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  svg {
    margin-right: 3px;
  }

  && span {
    color: ${({ type }) => fontColorMap[type]};
    ${({ type, theme }) => type === 'paymentRetrial' && `font-family: ${theme.fonts.semiBold}`}
  }

  & :hover {
    background-color: ${({ theme, color, type }) =>
      (type === 'cookies' && color) || theme.colors.blueDark};
    color: ${({ theme, color, type }) => (type === 'cookies' && color) || theme.colors.white};

    && span {
      color: ${({ theme, color, type }) => (type === 'cookies' && color) || theme.colors.white};
    }
  }

  ${mediaQueries.mobile`
    padding: ${({ type }) => (type === 'cookies' ? '8px 6px' : '5px 15px')};
  `};
`

export const MessageButtonContainer = styled.div`
  ${({ type }) =>
    (type === 'cookies' || type === 'paymentRetrial') &&
    `
      letter-spacing: 1.3px;
      display: flex;
      flex: 1;
      padding: 2px;
    `};

  ${mediaQueries.mobile`
    margin-top: 10px;
    width: 75%;
  `};
`

export const ContentSpan = styled.span.attrs(() => ({
  className: e2eClasses('messages.title')
}))`
  display: flex;
  flex: 2;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;

  ${({ type }) =>
    type === 'paymentRetrial' &&
    `
      flex: 3;
    `};
  ${mediaQueries.mobile`
    flex-direction: column;
  `};
`
