import { handleActions } from 'redux-actions'
import {
  fetchDirectAccessesStart,
  fetchDirectAccessesSuccess,
  fetchDirectAccessesError
} from 'actions/cms/directAccesses'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [fetchDirectAccessesStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchDirectAccessesSuccess]: (
      state,
      {
        payload: {
          data: { data }
        }
      }
    ) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [fetchDirectAccessesError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      data: null,
      error
    })
  },
  defaultState
)

export default reducer
