import { createActions } from 'redux-actions'
import apiRoutes from 'constants/apiRoutes'
import createARPlusAPI from 'services/ARPlusApi'

const {
  showSideBar,
  fetchProfileStart,
  fetchProfileSuccess,
  fetchProfileError,
  updateProfileStart,
  updateProfileSuccess,
  updateProfileError
} = createActions({
  SHOW_SIDE_BAR: flag => flag,
  FETCH_PROFILE_START: () => {},
  FETCH_PROFILE_SUCCESS: data => ({ data }),
  FETCH_PROFILE_ERROR: error => ({ error }),
  UPDATE_PROFILE_START: () => {},
  UPDATE_PROFILE_SUCCESS: data => ({ data }),
  UPDATE_PROFILE_ERROR: error => ({ error })
})

const fetchProfile = data => {
  return async dispatch => {
    dispatch(fetchProfileStart())
    try {
      const apiArPlus = createARPlusAPI(data.access_token)
      const profileStatus = await apiArPlus.get(`${apiRoutes.PROFILE}${data.user}`)
      dispatch(fetchProfileSuccess(profileStatus.data))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(fetchProfileError(err))
    }
  }
}

const buildBody = (values, countries) => {
  return {
    mail: values.email,
    address: {
      country: values.country,
      province: values.province || 'CP',
      streetNumber: values.street_number,
      streetName: values.street
    },
    phone: {
      number: values.contactInformation_phoneNumber,
      areaCode: values.contactInformation_areaCode,
      countryCode: countries.find(item => item.code === values.contactInformation_countryCode)
        .areaCode
    }
  }
}

const updateProfile = (data, values, countries) => {
  return async dispatch => {
    dispatch(updateProfileStart())
    try {
      const body = buildBody(values, countries)
      const apiArPlus = createARPlusAPI(data.access_token)
      const profileStatus = await apiArPlus.put(
        `${apiRoutes.PROFILE}${data.user}${apiRoutes.CONTACT_INFORMATION}`,
        body
      )
      dispatch(updateProfileSuccess(profileStatus.data))
    } catch (error) {
      const err = error.response ? error.response.data : error
      dispatch(updateProfileError(err))
    }
  }
}

export {
  showSideBar,
  fetchProfile,
  updateProfile,
  fetchProfileStart,
  fetchProfileSuccess,
  fetchProfileError,
  updateProfileStart,
  updateProfileSuccess,
  updateProfileError
}
