import { handleActions } from 'redux-actions'

import {
  clearSublosFamilyMember,
  sublosFamilyMemberSuccess,
  sublosFamilyMemberError,
  sublosFamilyMemberStart
} from 'actions/sublos/familyMembers'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const sublosFamilyMember = handleActions(
  {
    [sublosFamilyMemberStart]: state => ({
      ...state,
      error: null,
      isFetching: true
    }),
    [sublosFamilyMemberSuccess]: (state, { payload: { data } }) => ({
      data,
      error: null,
      isFetching: false
    }),
    [sublosFamilyMemberError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearSublosFamilyMember]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default sublosFamilyMember
