import { css } from 'styled-components'

import chatBootIcon from 'assets/boton-click-to-chat.svg'

export const chatBox = () => css`
  #wcx-chat {
    button {
      bottom: 125px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      padding: 5px;
      right: 0;

      span.styles_button_icon__PYERy {
        width: 60px;
        height: 62px;
        transform: translate(0px, 0px);
        display: block;
        background: url(${chatBootIcon});
        background-position: center;
      }

      span::before {
        content: '';
        display: block;
        border-radius: 50%;
        opacity: 0.1;
        height: none;
      }

      svg {
        display: none;
      }

      span.styles_button_text__2fM2J {
        display: none;
      }
    }
  }
`
