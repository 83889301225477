/* eslint-disable import/no-cycle */
import { createActions } from 'redux-actions'

import { mapTicketRefundValidation } from 'api-data-io/bo/ticketRefund'
import apiRoutes from 'constants/apiRoutes'
import api from 'services/api'

const {
  ticketRefundStart,
  ticketRefundSuccess,
  ticketRefundError,
  ticketRefundClear,
  validateTicketRefundStart,
  validateTicketRefundSuccess,
  validateTicketRefundError,
  validateTicketRefundClear,
  getIssuersStart,
  getIssuersSuccess,
  getIssuersError
} = createActions({
  TICKET_REFUND_START: () => {},
  TICKET_REFUND_SUCCESS: data => ({ data }),
  TICKET_REFUND_ERROR: error => ({ error }),
  TICKET_REFUND_CLEAR: () => {},
  VALIDATE_TICKET_REFUND_START: () => {},
  VALIDATE_TICKET_REFUND_SUCCESS: data => ({ data }),
  VALIDATE_TICKET_REFUND_ERROR: error => ({ error }),
  VALIDATE_TICKET_REFUND_CLEAR: () => {},
  GET_ISSUERS_START: () => {},
  GET_ISSUERS_SUCCESS: data => ({ data }),
  GET_ISSUERS_ERROR: error => ({ error })
})

const validateTicketRefund = formikFields => async dispatch => {
  dispatch(validateTicketRefundStart())
  const body = mapTicketRefundValidation(formikFields)
  try {
    const response = await api.post(apiRoutes.VALIDATE_REFUND_TICKET, body)
    dispatch(validateTicketRefundSuccess(response.data))
  } catch (error) {
    dispatch(validateTicketRefundError(error.response ? error.response.data : error))
    return error
  }
}

const postTicketRefund = (route, formikFields) => async dispatch => {
  dispatch(ticketRefundStart())
  try {
    const response = await api.post(route, formikFields)
    dispatch(ticketRefundSuccess(response.data))
  } catch (error) {
    dispatch(ticketRefundError(error.response ? error.response.data : error))
  }
}

const fetchIssuers = () => async dispatch => {
  dispatch(getIssuersStart())
  try {
    const response = await api.get(apiRoutes.GET_ISSUERS)
    dispatch(getIssuersSuccess(response.data.resources))
  } catch (error) {
    dispatch(getIssuersError(error.response ? error.response.data : error))
  }
}

const requestCorporateTicketRefund = formikFields => async dispatch => {
  dispatch(postTicketRefund(apiRoutes.CORPORATE_TICKET_REFUND, formikFields))
}

const requestRefundTicket = formikFields => async dispatch => {
  dispatch(postTicketRefund(apiRoutes.REQUEST_REFUND_TICKET, formikFields))
}

const repentanceTicketRefund = formikFields => async dispatch => {
  dispatch(postTicketRefund(apiRoutes.REPENTANCE_TICKET_REFUND, formikFields))
}

const clearTicketRefund = () => ticketRefundClear()
const clearValidateRefund = () => validateTicketRefundClear()

export {
  requestCorporateTicketRefund,
  requestRefundTicket,
  clearTicketRefund,
  repentanceTicketRefund,
  ticketRefundStart,
  ticketRefundSuccess,
  ticketRefundError,
  ticketRefundClear,
  clearValidateRefund,
  validateTicketRefundStart,
  validateTicketRefundSuccess,
  validateTicketRefundError,
  validateTicketRefund,
  validateTicketRefundClear,
  fetchIssuers,
  getIssuersStart,
  getIssuersSuccess,
  getIssuersError,
  postTicketRefund
}
