import { handleActions } from 'redux-actions'

import {
  sublosLoginModalStart,
  sublosLoginModalSuccess,
  sublosLoginModalError,
  clearSublosLoginModal
} from 'actions/sublos/loginModal'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const sublosLoginModal = handleActions(
  {
    [sublosLoginModalStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [sublosLoginModalSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [sublosLoginModalError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearSublosLoginModal]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default sublosLoginModal
