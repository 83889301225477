import CONFIG from 'config'
import { decodeURLParams } from 'helpers/url/cms'

const mapOption = opt => ({
  id: opt.id,
  name: opt.title,
  newTab: opt.subcategory_options_newtab,
  link: decodeURLParams(opt.subcategory_options_url)
})

const mapSubitem = subitem => ({
  id: subitem.id,
  header: subitem.title,
  icon: CONFIG.CONTENT_CDN_BASE_URL + subitem.subcategory_icon,
  requirePassword: subitem.subcategory_need_password,
  items: (
    (Array.isArray(subitem.subcategory_options) && subitem.subcategory_options) ||
    Object.values(subitem.subcategory_options)
  ).map(opt => mapOption(opt))
})

const mapMenuItem = item => {
  const subcategories =
    item.menu_subcategory_reference && Array.isArray(item.menu_subcategory_reference)
      ? item.menu_subcategory_reference
      : Object.values(item.menu_subcategory_reference)

  return {
    id: item.id,
    title: item.title,
    sections: (subcategories || []).map(subitem => mapSubitem(subitem))
  }
}

const getLangLink = lang => {
  if (lang.locales_url) {
    return lang.locales_url
  }

  if (lang.locales_tag_language) {
    return `/${lang.locales_tag_language}`
  }

  return undefined
}

const mapLanguages = lang => ({
  id: lang.id,
  country: lang.locales_country,
  flag: lang.locales_flag,
  language: lang.locales_language,
  link: getLangLink(lang)
})

export const mapHeader = data => ({
  menuItems: (data.mainMenuReference || []).map(item => mapMenuItem(item)),
  languages: (data.localesReference || []).map(language => mapLanguages(language)),
  id: data.nid,
  logo: CONFIG.CONTENT_CDN_BASE_URL + data.headerLogo,
  alt: data.headerAltText,
  secondaryLogo: data.headerLogoSecondary
    ? CONFIG.CONTENT_CDN_BASE_URL + data.headerLogoSecondary
    : undefined,
  secondaryAlt: data.headerAltTextSecondary,
  secondaryUrl: data.headerLogoSecondaryUrl
})
