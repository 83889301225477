import { handleActions } from 'redux-actions'

import {
  recoverEmailConfirmationStart,
  recoverEmailConfirmationSuccess,
  recoverEmailConfirmationError,
  clearRecoverEmailConfirmationState
} from 'actions/arplus/recover-email-confirmation'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const recoverEmailConfirmation = handleActions(
  {
    [recoverEmailConfirmationStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [recoverEmailConfirmationSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [recoverEmailConfirmationError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    }),
    [clearRecoverEmailConfirmationState]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export default recoverEmailConfirmation
