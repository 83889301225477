import { handleActions } from 'redux-actions'

import { clearSublosError, setError } from 'actions/sublos/errors'

const defaultState = {
  error: null
}

const sublosError = handleActions(
  {
    [setError]: (state, { payload: { data } }) => ({
      ...state,
      error: data
    }),
    [clearSublosError]: () => {
      return { ...defaultState }
    }
  },
  defaultState
)

export default sublosError
