import { createActions } from 'redux-actions'

import api from 'services/api'
import apiRoutes from 'constants/apiRoutes'
import { mapUserRegistration } from 'api-data-io/bo/corporate'

const {
  registerCorporateUserStart,
  registerCorporateUserSuccess,
  registerCorporateUserError,
  clearCorporateUserRegistration
} = createActions({
  REGISTER_CORPORATE_USER_START: () => {},
  REGISTER_CORPORATE_USER_SUCCESS: data => ({ data }),
  REGISTER_CORPORATE_USER_ERROR: error => ({ error }),
  CLEAR_CORPORATE_USER_REGISTRATION: () => {}
})

const registerCorporateUser = formFields => async dispatch => {
  dispatch(registerCorporateUserStart())

  try {
    const response = await api.post(
      apiRoutes.CORPORATE_AGREEMENTS_REGISTER,
      mapUserRegistration(formFields)
    )
    dispatch(registerCorporateUserSuccess(response.data))
  } catch (error) {
    dispatch(registerCorporateUserError(error.response ? error.response.data : error))
  }
}
export {
  registerCorporateUserStart,
  registerCorporateUserSuccess,
  registerCorporateUserError,
  clearCorporateUserRegistration,
  registerCorporateUser
}
