import { handleActions } from 'redux-actions'
import { setFlightSelectionSuccess, resetFlightSelection } from 'actions/flightsSelectionAction'

const defaultState = {
  data: null
}

const reducer = handleActions(
  {
    [setFlightSelectionSuccess]: (
      state,
      {
        payload: {
          selectionData: { offer, isReturn = false }
        }
      }
    ) => ({
      ...state,
      data: { offer, isReturn }
    }),
    [resetFlightSelection]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
