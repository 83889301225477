import { createActions } from 'redux-actions'

import apiRoutes from 'constants/apiRoutes'
import { apiSublos as api } from 'services/api'

const {
  sublosFamilyMemberStart,
  sublosFamilyMemberSuccess,
  sublosFamilyMemberError,
  clearSublosFamilyMember
} = createActions({
  SUBLOS_FAMILY_MEMBER_START: () => {},
  SUBLOS_FAMILY_MEMBER_SUCCESS: data => ({ data }),
  SUBLOS_FAMILY_MEMBER_ERROR: error => ({ error }),
  CLEAR_SUBLOS_FAMILY_MEMBER: () => {}
})

const fetchSublosFamilyMember = ({
  companyCode,
  employeeCode,
  benefitType,
  ticketType,
  shoppingId
}) => async dispatch => {
  dispatch(sublosFamilyMemberStart())
  try {
    const response = await api.get(
      apiRoutes.SUBLOS_GET_FAMILY_MEMBER({
        companyCode,
        employeeCode,
        benefitType,
        ticketType,
        shoppingId
      })
    )
    dispatch(sublosFamilyMemberSuccess(response.data))
  } catch (error) {
    dispatch(sublosFamilyMemberError(error.response ? error.response.data : error))
  }
}

export {
  fetchSublosFamilyMember,
  clearSublosFamilyMember,
  sublosFamilyMemberSuccess,
  sublosFamilyMemberError,
  sublosFamilyMemberStart
}
