import styled, { css } from 'styled-components'

export const rangeSliderGlobalCSS = css`
  .input-range {
    .input-range__track {
      background-color: ${({ theme }) => theme.colors.grayLight};

      .input-range__track--active {
        background-color: ${({ theme }) => theme.colors.blue};
      }

      .input-range__slider-container {
        .input-range__slider {
          background-color: ${({ theme }) => theme.colors.blue};
          width: 1em;
          height: 1em;
          border: solid 3px ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
`

export const sliderLabelCSS = css`
  letter-spacing: 0.8px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.doveGray};
  padding-bottom: 10px;
  font-family: ${({ theme }) => theme.fonts.regular};
`

export const RangeValue = styled.span`
  ${sliderLabelCSS};
  width: 50%;
  font-size: 14px;

  & + span {
    text-align: right;
  }
`
