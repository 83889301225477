import { handleActions } from 'redux-actions'
import {
  registerCorporateUserStart,
  registerCorporateUserSuccess,
  registerCorporateUserError,
  clearCorporateUserRegistration
} from 'actions/corporate/userRegistration'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const reducer = handleActions(
  {
    [registerCorporateUserStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [registerCorporateUserSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data: data,
      error: null,
      isFetching: false
    }),
    [registerCorporateUserError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearCorporateUserRegistration]: () => ({ ...defaultState })
  },
  defaultState
)

export default reducer
