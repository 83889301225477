import { handleActions } from 'redux-actions'

import {
  fetchAncillariesStart,
  fetchAncillariesSuccess,
  fetchAncillariesError,
  selectAncillariesStart,
  selectAncillariesSuccess,
  selectAncillariesError,
  purchaseAncillariesStart,
  purchaseAncillariesSuccess,
  purchaseAncillariesError,
  updateAncillaries,
  clearAncillariesSelect,
  clearAncillaries,
  clearAncillariesPurchase,
  clearAncillariesPurchaseError
} from 'actions/ancillaries'

const defaultState = {
  data: {},
  error: null,
  isFetching: false
}

const ancillaries = handleActions(
  {
    [fetchAncillariesStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [fetchAncillariesSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [fetchAncillariesError]: (state, { payload: { error } }) => ({
      ...state,
      data: {},
      error,
      isFetching: false
    }),
    [updateAncillaries]: (state, { payload: { data } }) => {
      return {
        ...state,
        data
      }
    },
    [clearAncillaries]: () => ({
      ...defaultState
    })
  },
  defaultState
)

const ancillariesSelect = handleActions(
  {
    [selectAncillariesStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [selectAncillariesSuccess]: (state, { payload: { data } }) => ({
      data,
      error: null,
      isFetching: false
    }),
    [selectAncillariesError]: (state, { payload: { error } }) => ({
      data: null,
      error,
      isFetching: false
    }),
    [clearAncillariesSelect]: () => ({
      ...defaultState
    })
  },
  defaultState
)

const ancillariesPurchase = handleActions(
  {
    [purchaseAncillariesStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [purchaseAncillariesSuccess]: (state, { payload: { data } }) => ({
      data,
      error: null,
      isFetching: false
    }),
    [purchaseAncillariesError]: (state, { payload: { error } }) => ({
      data: null,
      error,
      isFetching: false
    }),
    [clearAncillariesPurchaseError]: state => ({
      ...state,
      error: null
    }),
    [clearAncillariesPurchase]: () => ({
      ...defaultState
    })
  },
  defaultState
)

export { ancillaries, ancillariesSelect, ancillariesPurchase }
