const TRIP_TYPES = {
  ONE_WAY: 'ONE_WAY',
  ROUND_TRIP: 'ROUND_TRIP',
  MULTIDESTINY: 'MULTIDESTINY',
  RETURN: 'RETURN'
}

const TRIP_TYPE_LABELS = {
  ONE_WAY: 'label.one-way-trip',
  ROUND_TRIP: 'label.round-trip',
  MULTIDESTINY: 'label.multidestiny-trip',
  RETURN: 'label.return-trip'
}

export { TRIP_TYPES, TRIP_TYPE_LABELS }
