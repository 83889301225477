import { batch } from 'react-redux'

import { sublosEmployeeReservationsDetailsClear } from 'actions/sublos/employeeReservationsDetails'
import { setSublosError } from 'actions/sublos/errors'
import { sublosNoNameConditionsClear } from 'actions/sublos/noNameConditions'
import SUBLOS_ERRORS, { RESET_SERVICES } from 'constants/sublos/errors'
import { STEPS } from 'constants/sublos/flow'
import { mapperError } from 'helpers/sublos/mapper-error'

export const passengerNoNameThunk = error => async dispatch => {
  batch(() => {
    if (error && error?.statusCode === 404)
      dispatch(setSublosError(SUBLOS_ERRORS.NO_ASSOCIATED_FLIGHT))
    else {
      error && dispatch(setSublosError(mapperError(error, STEPS.NO_NAME_PASSENGER)))
    }
  })
}

export const clearPassengerNoNameStateThunk = resetServices => async (dispatch, getState) => {
  const state = getState()
  const isResetAll = resetServices === RESET_SERVICES.RESET_ALL_SERVICES
  const { sublosNoNameConditions, sublosEmployeeReservationsDetails } = state

  batch(() => {
    ;(sublosNoNameConditions.error || isResetAll) && dispatch(sublosNoNameConditionsClear())
    ;(sublosEmployeeReservationsDetails.error || isResetAll) &&
      dispatch(sublosEmployeeReservationsDetailsClear())
  })
}
