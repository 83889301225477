import { handleActions } from 'redux-actions'

import {
  unsubscribeAccountDataStart,
  unsubscribeAccountDataSuccess,
  unsubscribeAccountDataError,
  clearUnsubscribeAccountDataState
} from 'actions/arplus/account-data-actions/unsubscribeAccountData'

const defaultState = {
  data: null,
  error: null,
  isFetching: false
}

const unsubscribeAccountData = handleActions(
  {
    [unsubscribeAccountDataStart]: state => ({
      ...state,
      data: null,
      error: null,
      isFetching: true
    }),
    [unsubscribeAccountDataSuccess]: (state, { payload: { data } }) => ({
      ...state,
      data,
      error: null,
      isFetching: false
    }),
    [unsubscribeAccountDataError]: (state, { payload: { error } }) => ({
      ...state,
      data: null,
      error,
      isFetching: false
    }),
    [clearUnsubscribeAccountDataState]: () => ({ ...defaultState })
  },
  defaultState
)

export default unsubscribeAccountData
