import { handleActions } from 'redux-actions'

import {
  setFlightsSearchData,
  resetFlightsSearchData,
  setFlightsOffersSelectedDate,
  setDefinedFilters,
  setRedirectFromFilters
} from 'actions/flightsSearchData'
import { getDateWithFormat } from 'helpers/dates'

const defaultState = null

const reducer = handleActions(
  {
    [setFlightsSearchData]: (state, { payload: { data } }) => ({ ...data }),
    [setFlightsOffersSelectedDate]: (state, { payload: { legNumber, date } }) => {
      const legs = [...state.legs]
      legs[legNumber].departure = getDateWithFormat(date)

      return {
        ...state,
        legs
      }
    },
    [resetFlightsSearchData]: () => ({ ...defaultState }),
    [setDefinedFilters]: (state, { payload: { definedFilters } }) => ({
      ...state,
      definedFilters
    }),
    [setRedirectFromFilters]: (state, { payload: { redirect } }) => ({
      ...state,
      redirectFromFilters: redirect
    })
  },
  defaultState
)

export default reducer
