import { createActions } from 'redux-actions'
import { cmsApi } from 'services/api'
import cmsApiRoutes from 'constants/cmsApiRoutes'
import { convertPropertyTypeObjectToTypeArray } from 'helpers/cms'

const { fetchQuestionsStart, fetchQuestionsSuccess, fetchQuestionsError } = createActions({
  FETCH_QUESTIONS_START: () => {},
  FETCH_QUESTIONS_SUCCESS: data => ({ data }),
  FETCH_QUESTIONS_ERROR: error => ({ error })
})

const fetchQuestions = () => {
  return async dispatch => {
    dispatch(fetchQuestionsStart())

    try {
      const questions = await cmsApi.get(cmsApiRoutes.QUESTIONS)
      const parseQuestion = (questions.data && questions.data[0])
      ? {data:{
          ...questions.data[0],
          faqCategoryReference: (questions.data[0].faqCategoryReference || [])
            .map(convertPropertyTypeObjectToTypeArray('faq_subcategory_reference'))
            .map(field => ({
              ...field,
              faq_subcategory_reference: (field.faq_subcategory_reference || [])
                .map(convertPropertyTypeObjectToTypeArray('faq_question_reference'))
            }))
        }}
        : questions

      dispatch(fetchQuestionsSuccess(parseQuestion))
    } catch (error) {
      dispatch(fetchQuestionsError(error))
    }
  }
}

export { fetchQuestionsStart, fetchQuestionsSuccess, fetchQuestionsError, fetchQuestions }
