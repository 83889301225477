import React from 'react'

import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import MainHeader from 'components/header'
import { PageBlock } from 'components/layout'
import Sticky from 'components/sticky'
import { isHomeRoute } from 'helpers/routeMatchers'

const ShadowContainer = styled(PageBlock)`
  background-color: ${({ theme }) => theme.colors.gray10};
`

const HeaderContainer = ({ components, location, milesLogin }) => {
  const isHomepage = isHomeRoute(location)

  return (
    <Sticky top={0} zIndex={100} mobile={isHomepage} desktop={isHomepage} as="header">
      <ShadowContainer>
        <MainHeader components={components} milesLogin={milesLogin} />
      </ShadowContainer>
    </Sticky>
  )
}

export default withRouter(HeaderContainer)
